import React, { Component } from "react";
import moment from "moment";
import Paginations from "../../pagination";
import { getUserDetails } from "../../../services/actions/userAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getKycStatus } from "../../../services/actions/kycStatusAction";
import { USER_TYPE } from "../../../config/string_constants";

export class ClientTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userDetails: [],
      kycstatus: [],
      paginationUserItem: "",
    };
  }
  pageChangeUser = async (index) => {
    this.props
      .getUserDetails(index)
      .then((result) => {
        this.setState({
          userDetails: result.data,
          paginationUserItem: result,
        });
      })
      .catch((err) => {});
  };
  componentDidMount() {
    //Get All Borrowers/Directors./Sharehplders
    let currentPageUser = sessionStorage.getItem("current_page_user");
    if (!currentPageUser) {
      currentPageUser = 1;
    }
    this.props
      .getUserDetails(currentPageUser)
      .then((result) => {
        this.setState({
          userDetails: result.data,
          paginationUserItem: result,
        });
      })
      .catch((err) => {});

    this.props
      .getKycStatus()
      .then((result) => {
        this.setState({
          kycstatus: result,
        });
      })
      .catch((err) => {});
  }

  onUserView = (item) => {
    let id = item.id;
    let uId = null;
    if (item.user.user_type === USER_TYPE.CLIENT) {
      uId = item.user._id;
    } else {
      uId = item.user.createdById;
    }
    sessionStorage.setItem(
      "current_page_user",
      this.state.paginationUserItem.paginator.page
    );
    this.props.history.replace(`/borrower-details/${id}/${uId}`);
  };

  render() {
    return (
      <div className="card-plain table-plain-bg">
        <div className="table-full-width table-responsive px-0">
          <table className="border-0">
            <thead className="disp_non_sm">
              <tr className="text_tra1 ">
                <th>Client Name</th>
                <th>Contact Person</th>
                <th>Designation</th>
                <th>Contact Number</th>
                <th>Contact Person Email</th>
                <th>Date Of Registration</th>
                <th>KYC Status</th>
              </tr>
            </thead>

            <tbody>
              {this.state.userDetails === undefined ? (
                <tr>
                  <td>No Users</td>
                </tr>
              ) : (
                this.state.userDetails.map((item, i) => (
                  <tr key={i} className="sec1">
                    <td>{item.company ? item.company.company_name : ""}</td>
                    <td>
                      {item.user
                        ? item.user.full_name ||
                          item.user.director_full_name ||
                          item.user.shareholder_full_name
                        : ""}
                    </td>
                    <td> {item.user ? item.user.user_type : ""}</td>
                    {item.user ? (
                      item.user.phone_number ? (
                        <td>
                          {" "}
                          +{item.user.user_dialCode} {item.user.phone_number}
                        </td>
                      ) : (
                        <td></td>
                      )
                    ) : (
                      ""
                    )}
                    <td>{item.user ? item.user.email : ""}</td>
                    <td>{moment(item.user.createdAt).format("DD MMM YYYY")}</td>
                    <td>
                      {this.state.kycstatus.length !== undefined
                        ? this.state.kycstatus.map((status) =>
                            status._id === item.user.kycStatus ? (
                              <span
                                style={{
                                  color: status.textColor,
                                  padding: "10px",
                                  backgroundColor: status.backgroundColor,
                                  borderRadius: "4px",
                                }}
                              >
                                {status.kycStatus}
                              </span>
                            ) : (
                              ""
                            )
                          )
                        : ""}{" "}
                    </td>

                    <td>
                      <label
                        className="uploadb"
                        onClick={() => this.onUserView(item)}
                      >
                        View More
                      </label>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end mx-4 mt-2">
          <Paginations
            item={this.state.paginationUserItem}
            pageClick={this.pageChangeUser}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  kycStatus: state.kycStatus,
});
const mapDispatchToProps = (dispatch) => ({
  getUserDetails: (data) => dispatch(getUserDetails(data)),
  getKycStatus: (data) => dispatch(getKycStatus(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClientTable));

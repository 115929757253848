import React from "react";
import Sidebar from "../Dashboard/Sidebar";
import "../../../assets/css/BuyerProfile.css";
import arrow from "../../../assets/images/arrow.png";
import { getCountry } from "../../../services/actions/countryActions";
import {
  postCompany,
  updateCompany,
} from "../../../services/actions/companyActions";
import { postUser, updateUser } from "../../../services/actions/userAction";
import { connect } from "react-redux";
import check from "../../../assets/images/check1.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import MainHeader from "../Dashboard/mainHeader";
import { postActivity } from "../../../services/actions/activityActions";
import { USER_TYPE } from "../../../config/string_constants";
import { createBuyerCompany } from "../../../api/companyService";
import BuyerCompanyForm from "../../Borrower/BuyerProfile/buyerCompanyForm";
import BuyerUserForm from "../../Borrower/BuyerProfile/buyerUserForm";
import { URLS } from "../../../config/frontendUrls";

class newbuyer extends React.Component {
  updateStart = 0;
  constructor(props) {
    super(props);

    this.state = {
      loadbuyer: true,
      isLoader: false,
      company: {
        company_name: "",
        company_email_id: "",
        company_dialCode: "",
        company_phone_no: "",
        website: "",
        company_landline: "",
        address_line1: "",
        city: "",
        zipcode: "",
        createdById: sessionStorage.getItem("userId"),
        company_type: USER_TYPE.BUYER,
      },

      country: [],
      city: [],
      contact_details: false,
      addSucess: false,

      activity: {
        activity_name: "Created A Buyer",
        service_name: "createBuyer",
        createdBy: {
          name: sessionStorage.getItem("uName"),
          email: sessionStorage.getItem("uEmail"),
          id: sessionStorage.getItem("userId"),
        },
      },
    };
  }
  saveBuyer = (user) => {
    const data = this.state.company;
    data.contact_details = user;

    this.setState(
      {
        isLoader: true,
      },
      async () => {
        let response = await createBuyerCompany(data);
        if (response) {
          this.setState(
            {
              isLoader: false,
              addSucess: true,
            },
            () => {
              toast.info("Buyer Added Successfully");
              this.props
                .postActivity(this.state.activity)
                .then((response) => {
                  this.setState({
                    addSucess: false,
                    isLoader: false,
                  });
                  this.props.history.push(URLS.CLIENT.BUYER_LIST);
                })
                .catch((err) => {});
            }
          );
        } else {
          this.setState({
            isLoader: false,
          });
        }
      }
    );
  };

  componentDidMount() {
    this.props
      .getCountry()
      .then((result) => {
        this.setState({
          country: result,
        });
      })
      .catch((err) => {});
  }

  handleBack = () => {
    if (this.state.contact_details === true) {
      // alert("s1")
      this.setState({ contact_details: false });
    } else {
      this.props.history.goBack();
    }
  };
  render() {
    return (
      <div>
        <Sidebar />
        <div className="main-content newb">
          <header className="header1">
            <div className="header__title">
              <h2 className="heading">Buyers</h2>
            </div>

            <MainHeader />
          </header>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <div className="mob-sm-heading">
            <p style={{ padding: "12px", color: "white" }}>
              <img
                className="curserP"
                src={arrow}
                alt='arrow'
                style={{ marginRight: "4px", marginLeft: "15px" }}
                width="18px"
                height="18x"
                onClick={this.handleBack}
              />{" "}
              <span className="newbuyer ">Previous Screen</span>{" "}
            </p>
            <hr style={{ backgroundColor: "#4d4d4d", margin: "auto" }} />
          </div>
          {/* mobile view progress bar  */}
          <div className="d-block d-sm-none ">
            {!this.state.contact_details ? (
              <div>
                <ul
                  className="newb_progress nav nav-pills d-flex justify-content-center  pt-2"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <span className="nav-link p-7px active StatusItem">
                      01 <div className="ntc txt_b">Company Details</div>
                    </span>
                  </li>
                  <div className="line_right mt-3"></div>
                  <li className="nav-item ">
                    <span className="nav-link p-6px StatusItem text_act">
                      02 <div className="ntc text_act">Contact Details</div>
                    </span>
                  </li>
                </ul>
              </div>
            ) : (
              <div>
                <ul
                  className="newb_progress nav nav-pills d-flex justify-content-center pt-2"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <span className="nav-link active p-1 StatusItem">
                      <img src={check} />{" "}
                      <div className="ntc mt-3 text_tra1">Company Details</div>
                    </span>
                  </li>
                  <div className="Cline_left mt-3"></div>
                  <li className="nav-item ">
                    <span className="nav-link active p-2 StatusItem text_act">
                      02 <div className=" ntc txt_b">Contact Details</div>
                    </span>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="borderw mt-5 disp-lg-none"></div>
          {/* mobile view progress bar end */}
          {!this.state.contact_details ? (
            <BuyerCompanyForm
              country={this.state.country}
              company={this.state.company}
              goToNextPage={(company) =>
                this.setState({ contact_details: true, company })
              }
            />
          ) : (
            <BuyerUserForm
              country={this.state.country}
              contactDetails={() => this.setState({ contact_details: false })}
              saveBuyerData={this.saveBuyer}
              isLoader={this.state.isLoader}
            />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  company: state.company,
  country: state.country,
  activity: state.activity,
});

const mapDispatchToProps = (dispatch) => ({
  postUser: (data) => dispatch(postUser(data)),
  postCompany: (data) => dispatch(postCompany(data)),
  updateUser: (data) => dispatch(updateUser(data)),
  updateCompany: (data) => dispatch(updateCompany(data)),
  getCountry: (data) => dispatch(getCountry(data)),
  postActivity: (data) => dispatch(postActivity(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(newbuyer));

import * as SC_CONSTANTS from "../constants/smartContract";
export const scReducer = (state = {
    type: '',
    error: '',
    isFetching: false,
   smartToken:undefined,
   pricingData:undefined,
   newOffer:undefined,
   txnStatus:undefined
}, action) => {
   
    switch (action.type) {

                case SC_CONSTANTS.HOME_POST_SMART_TOKEN_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    smartToken:undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case SC_CONSTANTS.HOME_POST_SMART_TOKEN_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    smartToken:undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case SC_CONSTANTS.HOME_POST_SMART_TOKEN_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });

                case SC_CONSTANTS.HOME_GET_PRICING_DATA_CONSTANTS.REQUEST:
                    return Object.assign({}, state, {
                        isFetching: true,
                        type: action.type,
                        pricingData:undefined,
                        lastUpdated: action.receivedAt,
                    });
        
                case SC_CONSTANTS.HOME_GET_PRICING_DATA_CONSTANTS.RECEIVED:
                    return Object.assign({}, state, {
                        isFetching: false,
                        type: action.type,
                        pricingData:action.data,
                        lastUpdated: action.receivedAt,
                    });
        
                case SC_CONSTANTS.HOME_GET_PRICING_DATA_CONSTANTS.RECEIVED_ERROR:
                    return Object.assign({}, state, {
                        isFetching: false,
                        type: action.type,
                        error: action.error,
                    });
                    case SC_CONSTANTS.HOME_CREATE_NEW_OFFER_DATA_CONSTANTS.REQUEST:
                        return Object.assign({}, state, {
                            isFetching: true,
                            type: action.type,
                            newOffer:undefined,
                            lastUpdated: action.receivedAt,
                        });
            
                    case SC_CONSTANTS.HOME_CREATE_NEW_OFFER_DATA_CONSTANTS.RECEIVED:
                        return Object.assign({}, state, {
                            isFetching: false,
                            type: action.type,
                            newOffer:action.data,
                            lastUpdated: action.receivedAt,
                        });
            
                    case SC_CONSTANTS.HOME_CREATE_NEW_OFFER_DATA_CONSTANTS.RECEIVED_ERROR:
                        return Object.assign({}, state, {
                            isFetching: false,
                            type: action.type,
                            error: action.error,
                        });
                        case SC_CONSTANTS.HOME_CREATE_NEW_OFFER_DATA_CONSTANTS.REQUEST:
                            return Object.assign({}, state, {
                                isFetching: true,
                                type: action.type,
                                txnStatus:undefined
,                                lastUpdated: action.receivedAt,
                            });
                
                        case SC_CONSTANTS.HOME_CREATE_NEW_OFFER_DATA_CONSTANTS.RECEIVED:
                            return Object.assign({}, state, {
                                isFetching: false,
                                type: action.type,
                                txnStatus:action.data,
                                lastUpdated: action.receivedAt,
                            });
                
                        case SC_CONSTANTS.HOME_CREATE_NEW_OFFER_DATA_CONSTANTS.RECEIVED_ERROR:
                            return Object.assign({}, state, {
                                isFetching: false,
                                type: action.type,
                                error: action.error,
                            });
                


        default:
            return state;
    }
};


export default scReducer;

import axios from "axios";
import * as INVOICE_STATUS_CONSTANTS from "../constants/invoiceStatusConstant";
import ajax from "../../utils/ajax";

// Get All Invoice status
export const requestGetInvoiceStatus = () => ({
  type: INVOICE_STATUS_CONSTANTS.HOME_GET_INVOICE_STATUS_CONSTANTS.REQUEST,
});

export const recievedGetInvoiceStatusResponse = (data) => ({
  type: INVOICE_STATUS_CONSTANTS.HOME_GET_INVOICE_STATUS_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetInvoiceStatusError = (err) => ({
  type: INVOICE_STATUS_CONSTANTS.HOME_GET_INVOICE_STATUS_CONSTANTS
    .RECEIVED_ERROR,
  errorCode: err,
});

export const getInvoiceStatus = () => async (dispatch) => {
  dispatch(requestGetInvoiceStatus());
  try {
    let url = INVOICE_STATUS_CONSTANTS.HOME_GET_INVOICE_STATUS_CONSTANTS.URL;
    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetInvoiceStatusResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetInvoiceStatusError(err));
    return Promise.reject(err);
  }
};

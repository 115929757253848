import {URI} from '../../constant'

export const HOME_GET_ALERT_CONSTANTS = ({
    URL: URI+"/alert/",
    REQUEST: 'REQUEST_GET_ALERT_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_GET_ALERT_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_GET_ALERT_DATA_CONSTANTS',
  });

  export const HOME_POST_ALERT_CONSTANTS = ({
    URL: URI+"/alert",
    REQUEST: 'REQUEST_POST_ALERT_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_POST_ALERT_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_POST_ALERT_DATA_CONSTANTS',
  });

  export const HOME_UPDATE_ALERT_CONSTANTS = ({
    URL: URI+"/alert/",
    REQUEST: 'REQUEST_UPDATE_ALERT_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_UPDATE_ALERT_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_UPDATE_ALERT_DATA_CONSTANTS',
  });
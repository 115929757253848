import axios from "axios";
import { URI } from "../constant";
import ajax from "../utils/ajax";

export let getUserBankAccountsById = (id) => {
  let url = URI + "/user-account/" + id;
  const res = ajax("GET", url, {});
  return res;
};

export let postUserAccountData = (data) => {
  let url = URI + "/user-account/";
  const res = ajax("POST", url, {}, {}, data);
  return res;
};

export let updateUserAccountdata = (data) => {
  let url = URI + "/user-account/" + data._id;
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};

import React, { Component } from "react";
import helper from "../../../utils/helper";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  getInvoiceDetailsById,
  getInvoiceCreatedByUser,
  getInvoiceChanges,
} from "../../../services/actions/invoiceAction";
import { getCompanyByCompanyId } from "../../../services/actions/companyActions";
import { updateInvoice } from "../../../services/actions/invoiceAction";
import { getInvoiceStatus } from "../../../services/actions/invoiceStatusAction";
import { getFinalOfferById } from "../../../services/actions/invoiceFinalOfferAction";
import { postOffer } from "../../../services/actions/invoiceOfferAction";
import { postAlert } from "../../../services/actions/alertActions";
import { getOfferById } from "../../../services/actions/invoiceOfferAction";

import {
  getUserAccountsById,
  UpdateUserAccount,
} from "../../../services/actions/userAccountAction";
import { postSmartToken } from "../../../services/actions/smartContractAction";
import Modal from "react-bootstrap/Modal";
import InvoiceMoreDetail from "./invoiceMoreDetail";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { URI } from "../../../constant";
import ajax from "../../../utils/ajax";
import Progressbar from "./progressBar";
import loader from "../../../assets/images/loader.svg";
import InvoicesListId from "./invoicesListId";
import FinalOfferDetails from "./finalOfferDetails";
import Header from "./header";
import { ACCOUNT_TYPE } from "../../../config/string_constants";
import {
  INVOICE_STATUSES_KEY,
  ADMIN_ALERT_TYPES,
} from "../../../config/string_constants";
import { createDisburse, updateAdvanceDate } from "../../../api/invoiceService";
import {
  approveInvoiceByAdmin,
  getInvoiceForAdmin,
  approveFundAllocation,
} from "../../../api/invoiceService";
import InvoiceData from "./invoiceData";
import SoftOffer from "./softOfferDetails";
import PricingDetails from "./pricingDetails";
import { HELPERS } from "../../../helpers/index";
import DeclineInvoice from "./declineInvoice";
import { CLIENT_ALERT_TYPES } from "../../../config/string_constants";
import UnlockCard from "./sideCard/unlockCard";

class AdvancedFundAllocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lock: false,
      loader: false,
      isAdvanceLocked: true,
      fileError: "",
      invoiceId: this.props.match.params.id,
      invoicedetails: "",
      invoices: [],
      showDisbused: false,
      invoiceOffer: [],
      invoiceCompany: [],
      advanceDate: null,
      invoiceStatusArray: [],
      showPdf: false,
      pdfUrl: "",
      selected: false,
      articleId: "",
      locked: true,
      alertData: {
        invoice_id: "",
        invoice_ref_no: "",
        invoice_status_id: "",
        invoice_substatus_id: "",
        client_Id: "",
        key: "",
      },
      alertSuccess: false,
      amount_due: 0,
      screen: INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION,
      disbursed: false,
      disbursedDetails: false,
      Account: false,
      advanceDisbursed: false,
      finaloffer: [],
      clientAccounts: [],
      selectedAccount: [],
      advanceDisbursedData: [],
      document: "",
      approve: false,
      userPrimaryAccount: {},
      currentStatus: "",
    };
  }

  calculateDisbursedData = (result) => {
    this.setState({
      disbursedDetails: result.offer_id ? true : false,
      showDisbused:
        result.final_offer && result.final_offer.created_date ? true : false,
    });
  };
  componentDidMount() {
    this.props
      .getInvoiceDetailsById(this.state.invoiceId)
      .then((result) => {
        this.calculateDisbursedData(result);
        this.setState({
          invoicedetails: result,
          buyerCompany: result.buyer_company,
          selected: true,
          articleId: result._id,
          finaloffer: result.final_offer,
          invoiceCompany: result.client_company,
        });
        this.props.getInvoiceChanges(result);
        getInvoiceForAdmin(result.created_by_id)
          .then((invoices) => {
            this.setState({
              invoices: invoices.data,
            });
          })
          .catch((err) => {});
        this.getAccountDetails(result.created_by_id);
        this.props
          .getOfferById(result._id)
          .then((offer) => {
            this.setState({
              invoiceOffer: offer,
              tenure: offer.tenure,
            });
          })
          .catch((err) => {});
        //calling Helper function to getPricingDetails
        HELPERS.getPricingDetails(this.state.finaloffer.pricing_id)
          .then((pricing) => {
            this.setState({ pricingData: pricing });
          })
          .catch((err) => {});
      })
      .catch((err) => {});

    //Get Invoice Status

    this.setState({
      invoiceStatusArray: this.props.invoiceStatus,
    });
    let status = this.props.invoiceStatus.find(
      (o) => o.key === INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION
    );
    this.setState({
      currentStatus: status._id,
    });
  }

  getAccountDetails = (created_by_id) => {
    this.props
      .getUserAccountsById(created_by_id)
      .then((accounts) => {
        this.setState(
          {
            clientAccounts: accounts,
          },
          () => {
            const userPrimaryAccount = accounts.filter(
              (item) => item.account_type === ACCOUNT_TYPE.PRIMARY
            );

            this.setState({
              userPrimaryAccount: userPrimaryAccount[0],
              selectedAccount: userPrimaryAccount[0],
            });
          }
        );
      })
      .catch((err) => {});
  };
  showData = (item) => {
    this.setState({
      invoiceId: item._id,
      invoiceCompany: item.client_company,
      selected: true,
      articleId: item._id,
      finaloffer: item.final_offer,
      showDisbused: item.advance_disburse !== null ? true : false,
    });
  };

  amountchange = (e) => {
    this.setState({
      amount_due: e.target.value,
    });
  };

  onPdfShow = (url) => {
    this.setState({
      showPdf: true,
      pdfUrl: url,
    });
  };
  showAccount = (account) => {
    this.setState({
      selectedAccount: account,
      Account: true,
    });
  };
  makePrimary = () => {
    const data = this.state.selectedAccount;
    data.account_type = ACCOUNT_TYPE.PRIMARY;

    ajax("PUT", `/user-account/change-type/${data._id}`, {}, {}, data)
      .then((response) => {
        this.getAccountDetails(data.createdById);
      })
      .catch((err) => {});
  };

  handleClose = () => {
    this.setState({ showPdf: false });
  };

  disbursed = async () => {
    if (this.state.invoicedetails.offer_id) {
    } else {
      this.setState({ disbursed: true });
      let response = await createDisburse(
        this.state.invoicedetails._id,
        this.state.invoicedetails.created_by_id
      );
      if (response) {
        if (response.data.success) {
          this.props
            .getInvoiceDetailsById(this.state.invoiceId)
            .then((invoice) => {
              this.setState({
                invoicedetails: invoice,
              });
              this.calculateDisbursedData(invoice);
              this.props.getInvoiceChanges(invoice);
            })
            .catch((err) => {});
          this.setState({
            disbursed: false,
          });
        }
      } else {
        this.setState({
          disbursed: false,
        });
      }
    }
  };
  approveFundAllocation = async () => {
    const data = {
      _id: this.state.invoicedetails._id,
      is_advance_approved: true,
    };
    let response = await approveFundAllocation(data);
    if (response) {
      if (response.data.success) {
        toast.info("Advance Fund Approved successfully");
        this.props
          .getInvoiceDetailsById(this.state.invoiceId)
          .then((invoice) => {
            this.setState({
              invoicedetails: invoice,
            });
          })
          .catch((err) => {});
      }
    }
  };
  updatedisbursed = async () => {
    if (this.state.advanceDate === null) {
      toast.error("Please add advance disbursed date");
    } else {
      this.setState({ disbursed: false });
      this.setState({ approve: true });
      let data = {
        advance_date: this.state.advanceDate,
        days_aged: moment(new Date()).diff(
          moment(this.state.invoicedetails.invoice_date),
          "days"
        ),
        document: this.state.document,
        invoice_id: this.state.invoicedetails._id,
      };

      let response = await updateAdvanceDate(data);
      if (response) {
        if (response.data.success) {
          toast.info("Advance Fund Details Updated");
          this.props
            .getInvoiceDetailsById(this.state.invoiceId)
            .then((invoice) => {
              this.setState({
                invoicedetails: invoice,
                showDisbused: true,
                advanceDisbursed: false,
              });
              this.props.getInvoiceChanges(invoice);
            })
            .catch((err) => {});
        }
      }
    }
  };

  updateAdvanceStatus = () => {
    let obj = this.state.invoiceStatusArray.find(
      (o) => o.key === INVOICE_STATUSES_KEY.COLLECTION
    );
    const finalSubstatus = obj
      ? obj.subStatus.find((o) => o.statusName === "Due")
      : {};
    const data = {
      _id: this.state.invoicedetails._id,
      invoiceSubStatus_id: finalSubstatus._id,
      invoiceStatus_id: obj._id,
    };
    if (this.state.invoicedetails.final_offer.advance_date) {
      approveInvoiceByAdmin(data)
        .then((result) => {
          this.props.history.push(`/collection/${this.state.invoiceId}`);
          this.setState({
            invoicedetails: data,
          });
        })
        .catch((err) => {});
    } else {
      toast.error("Please add advance disbursed date");
    }
  };

  onChange = (e) => {
    const { value } = e.target;

    this.setState({
      advanceDate: value,
    });
  };

  fileChange = (e) => {
    if (helper.validateFileType(e.target.files)) {
      this.uploadFile(e.target.files[0], e.target.name);
    } else {
      this.setState({
        loader: false,
        fileError: "Please Upload file in JPG, PNG, PDF and max size 5mb.",
      });
    }
  };
  uploadFile(file, name) {
    this.setState({ loader: true });
    const formData = new FormData();
    formData.append("image", file);
    if (file !== undefined) {
      ajax(
        "POST",
        `${URI}/api/v1/image-upload`,
        {},
        { "content-type": "multipart/form-data" },
        formData
      )
        .then((data) => {
          toast.info("File Updated Successfully");
          const reverser_document_url = data.data.imageUrl;
          this.setState({
            loader: false,
            fileError: "",
            document: reverser_document_url,
          });
        })
        .catch((err) => {});
    }
  }

  updateDetails = () => {
    const primaryAccounts = this.state.clientAccounts.filter((item) => {
      return item.account_type === ACCOUNT_TYPE.PRIMARY;
    });
    if (primaryAccounts.length > 0) {
      this.setState({ advanceDisbursed: true });
    } else {
      toast.error("Please Select An Account as Primary");
    }
    //
  };

  sendAlert = () => {
    this.setState({
      alertData: {
        ...this.state.alertData,
        invoice_id: this.state.invoicedetails._id,
        invoice_ref_no: this.state.invoicedetails.invoice_id,
        client_Id: this.state.invoicedetails.created_by_id,
        invoice_status_id: this.state.invoicedetails.invoice_status_id._id,
        invoice_substatus_id: this.state.invoicedetails.invoice_substatus_id,
        request_document_name: "Please Fill your Bank Account Details",
        request_document_message:
          "Following Action is required to complete Advance Fund Allocation ",
        key: ADMIN_ALERT_TYPES.BANK_ACCOUNT,
      },
    });

    this.setState(
      {
        alertSuccess: true,
      },
      () => {
        this.props
          .postAlert(this.state.alertData)
          .then((result) => {
            toast.info("Alert Sent");

            this.setState({ alertSuccess: false });
          })
          .catch((err) => {});
      }
    );
  };

  render() {
    return (
      <div>
        <Header />
        <div className="row m-4">
          <div className="col-3">
            <InvoiceMoreDetail
              invoicedetails={this.state.invoicedetails}
              invoiceCompany={this.state.invoiceCompany}
            />
          </div>
          <div className="col-9 overflow-auto">
            <Progressbar
              invoiceId={this.state.invoiceId}
              screen={this.state.screen}
            />
          </div>
        </div>
        <div className="row m-2 my-4">
          <div className="col-6">
            <div className="info-one h-100">
              <div className="tab-content">
                <div className="tab-pane active" id="tabs-1" role="tabpanel">
                  {!this.state.advanceDisbursed ? (
                    <div>
                      {this.state.invoices === undefined ? (
                        <div>No data Available </div>
                      ) : (
                        this.state.invoices
                          .filter((item) => {
                            return (
                              item.invoice_id ===
                              this.state.invoicedetails.invoice_id
                            );
                          })
                          .map((item, index) => (
                            <div key={index}>
                              <InvoiceData
                                invoices={item}
                                tenure={this.state.tenure}
                                onPdfShow={this.onPdfShow}
                              />
                              <p className="text-white mt-4">
                                BANK ACCOUNTS DETAILS
                                {this.state.clientAccounts.length === 0 ? (
                                  <span
                                    className="uploadb"
                                    onClick={this.sendAlert}
                                  >
                                    Send Alert
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>

                              {this.state.clientAccounts !== undefined ? (
                                this.state.clientAccounts.map(
                                  (account, index) => (
                                    <div
                                      key={index}
                                      className="bgc-dark p-2 mt-3  d-flex justify-content-between"
                                    >
                                      <div>
                                        <span className="text-white">
                                          {account.bank_name}
                                        </span>
                                        <span className="txt_b mx-3">
                                          {account.account_type}
                                        </span>
                                      </div>
                                      <div>
                                        <i
                                          className="uil-angle-right txt_b"
                                          onClick={() =>
                                            this.showAccount(account)
                                          }
                                        />
                                      </div>
                                    </div>
                                  )
                                )
                              ) : (
                                <div className="bgc-dark p-2 mt-3  d-flex justify-content-between">
                                  <div>
                                    <span className="text-white">
                                      No Accounts added yet
                                    </span>
                                    <span className="txt_b mx-3">Primary</span>
                                  </div>
                                </div>
                              )}
                              {this.state.invoicedetails
                                .is_advance_approved && (
                                <div>
                                  <div className="bgc-dark p-2 mt-3  d-flex justify-content-between">
                                    <div>
                                      <span className="text-white">
                                        Update advanced disbursed details
                                      </span>
                                    </div>
                                    <div>
                                      <i
                                        className="uil-angle-right txt_b"
                                        onClick={this.updateDetails}
                                      />
                                    </div>
                                  </div>
                                  <p className="text_tra1">
                                    Update your advanced disbursed details, then
                                    only you can update the status
                                  </p>
                                </div>
                              )}

                              <div></div>

                              <div className="mt-5 text-center">
                                {this.state.clientAccounts.length > 0 ? (
                                  this.state.invoicedetails
                                    .is_advance_approved ? (
                                    this.state.invoicedetails.offer_id ? (
                                      <span className="uploadb ">
                                        Disbursed
                                      </span>
                                    ) : (
                                      this.state.invoicedetails.invoiceStatus_id
                                        .key === "advance-fund-allocation" && (
                                        <button
                                          className="btn-blue mx-2 "
                                          onClick={() =>
                                            this.state.isAdvanceLocked
                                              ? this.setState({ lock: true })
                                              : this.disbursed()
                                          }
                                        >
                                          Advance Disburse
                                        </button>
                                      )
                                    )
                                  ) : (
                                    <div className="mt-5 text-center">
                                      <button
                                        className="btn-blue "
                                        onClick={this.approveFundAllocation}
                                      >
                                        Approve Advance
                                      </button>
                                      <div className="mt-4">
                                        <DeclineInvoice
                                          invoice={this.state.invoicedetails}
                                          toastMessage="Advance Fund Allocation Declined"
                                          updateType={
                                            CLIENT_ALERT_TYPES.ADVANCED_FUND_ALLOCATION
                                          }
                                          invoiceStatus={
                                            this.props.invoiceStatus
                                          }
                                          currentStatusKey={
                                            INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION
                                          }
                                        />
                                      </div>
                                    </div>
                                  )
                                ) : (
                                  ""
                                )}
                              </div>

                              {this.state.invoicedetails.is_advance_approved &&
                              this.state.invoicedetails.invoiceStatus_id.key ===
                                "advance-fund-allocation" ? (
                                <div className="mt-5 text-center">
                                  <button
                                    className="btn-blue p-2"
                                    onClick={this.updateAdvanceStatus}
                                  >
                                    Update Advance Status
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          ))
                      )}
                    </div>
                  ) : (
                    <div>
                      <h6 className="text-white">
                        Update advanced disbursed details
                      </h6>
                      <div className="row mt-2">
                        <div className="col-6">
                          <label>Buyer Name</label>
                          <input
                            className="input"
                            value={
                              this.state.invoicedetails.buyer_company
                                .company_name
                            }
                          ></input>
                        </div>
                        <div className="col-6">
                          <label>Invoice Grade</label>
                          <input
                            value={
                              this.state.finaloffer
                                ? this.state.finaloffer.grade
                                : ""
                            }
                            className="input"
                          ></input>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-6">
                          <label>Invoice Ref. No</label>
                          <input
                            className="input"
                            value={this.state.invoicedetails.invoice_id}
                          ></input>
                        </div>
                        <div className="col-6">
                          <label>Supplier Invoice Number</label>
                          <label></label>
                          <input
                            className="input"
                            value={
                              this.state.invoicedetails.supplier_invoice_number
                            }
                          ></input>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-6">
                          <label>Invoice Amount</label>
                          <input
                            className="input"
                            value={this.state.invoicedetails.invoice_amount}
                          ></input>
                        </div>
                        <div className="col-6">
                          <label>Invoice Limit</label>
                          <input
                            className="input"
                            value={this.state.invoicedetails.invoice_limit}
                          ></input>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-6">
                          <label>Invoice Date</label>
                          <input
                            className="input"
                            value={moment(
                              this.state.invoicedetails.invoice_date
                            ).format("DD MMM YYYY")}
                          ></input>
                        </div>
                        <div className="col-6">
                          <label>Advanced Date</label>
                          <input
                            className="input"
                            type={
                              this.state.finaloffer.advance_date ? "" : "date"
                            }
                            value={
                              this.state.finaloffer.advance_date
                                ? moment(
                                    this.state.finaloffer.advance_date
                                  ).format("DD MMM YYYY")
                                : this.state.advanceDate
                            }
                            onChange={this.onChange}
                          ></input>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-6">
                          <label>Advanced Amount</label>
                          <input
                            className="input"
                            value={this.state.finaloffer.amount_to_be_funded}
                          ></input>
                        </div>
                        <div className="col-6">
                          <label>Reserve Held</label>
                          <input
                            className="input"
                            value={
                              this.state.invoicedetails.invoice_amount -
                              this.state.finaloffer.amount_to_be_funded
                            }
                          ></input>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-6">
                          <label>Invoice Due Date</label>
                          <input
                            className="input"
                            value={moment(
                              this.state.invoicedetails.invoice_due_date
                            ).format("DD MMM YYYY")}
                          ></input>
                        </div>
                        <div className="col-6">
                          <label>Grace period</label>
                          <input
                            className="input"
                            value={this.state.finaloffer.grace_period}
                          ></input>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-6">
                          <label>Bank Name</label>
                          <input
                            className="input"
                            value={
                              this.state.selectedAccount
                                ? this.state.selectedAccount.bank_name
                                : ""
                            }
                          ></input>
                        </div>
                        <div className="col-6">
                          <label>IFSC Code</label>
                          <input
                            className="input"
                            value={
                              this.state.selectedAccount
                                ? this.state.selectedAccount.ifsc_code
                                : ""
                            }
                          ></input>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-6">
                          <label>Account Number</label>
                          <input
                            className="input"
                            value={
                              this.state.selectedAccount
                                ? this.state.selectedAccount.account_number
                                : ""
                            }
                          ></input>
                        </div>
                        <div className="col-6 ">
                          <label>Upload If Any Document</label>
                          <div className="bgc-dark d-flex p-2 justify-content-between">
                            <div className="text_tra1">
                              <i className="uil-upload" /> Upload here
                            </div>
                            {/* <div className='txt_b'>Upload</div> */}
                            <span>
                              <label className="uploadb header-input mx-3">
                                {this.state.loader ? (
                                  <div className="spinner-border btn--icon"></div>
                                ) : (
                                  "Upload"
                                )}
                                <input
                                  type="file"
                                  style={{ display: "btn  col_erone" }}
                                  name="document"
                                  onChange={this.fileChange}
                                />
                              </label>
                              {this.state.fileError && (
                                <div className="text-danger">
                                  {this.state.fileError}
                                </div>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 text-center">
                        {this.state.invoicedetails &&
                        this.state.invoicedetails.invoiceStatus_id._id ===
                          this.state.currentStatus ? (
                          <button
                            className="btn-blue"
                            onClick={this.updatedisbursed}
                          >
                            Update
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="info-one h-100">
              <p className="text_tra1 mb-2">INVOICES</p>
              <InvoicesListId
                invoices={this.state.invoices}
                selected={this.state.selected}
                showData={this.showData}
                itemId={this.state.articleId}
              />
              <UnlockCard
                lock={this.state.lock}
                unlock={() => this.setState({ lock: false })}
                triggerFunction={() =>
                  this.setState({ isAdvanceLocked: false })
                }
              />
              <PricingDetails
                pricingData={this.state.pricingData}
                show={false}
              />
              {this.state.invoicedetails ? (
                <FinalOfferDetails
                  invoiceId={this.state.invoiceId}
                  invoicedetails={this.state.invoicedetails}
                  tenure={this.state.tenure}
                />
              ) : (
                ""
              )}
              <SoftOffer invoiceOffer={this.state.invoiceOffer} show={false} />
            </div>
          </div>
          <div className={`menuNav ${this.state.Account ? "showMenu1" : ""}`}>
            {this.state.selectedAccount !== null ? (
              <div>
                <div
                  style={{ borderBottom: "1px solid gray" }}
                  className="d-flex justify-content-between text-white p-3"
                >
                  <h5>Account Details</h5>
                  <h5
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setState({ Account: false })}
                  >
                    <i className="uil-multiply"></i>
                  </h5>
                </div>

                <div className="m-3 ">
                  <label className="text_tra1">Name</label>
                  <h5 className="text-white">
                    {this.state.selectedAccount
                      ? this.state.selectedAccount.user_name
                      : ""}
                  </h5>
                </div>
                <div className="m-3">
                  <label className="text_tra1">Account Number</label>
                  <h5 className="text-white">
                    {this.state.selectedAccount
                      ? this.state.selectedAccount.account_number
                      : ""}
                  </h5>
                </div>
                <div className="m-3">
                  <label className="text_tra1">IFSC Code</label>
                  <h5 className="text-white">
                    {this.state.selectedAccount
                      ? this.state.selectedAccount.ifsc_code
                      : ""}
                  </h5>
                </div>
                <div className="m-3">
                  <label className="text_tra1">Beneficiary Name</label>
                  <h5 className="text-white">
                    {this.state.selectedAccount
                      ? this.state.selectedAccount.beneficiary_name
                      : ""}
                  </h5>
                </div>
                <div className="m-3">
                  <label className="text_tra1">Beneficiary Address</label>
                  <h5 className="text-white">
                    {this.state.selectedAccount
                      ? this.state.selectedAccount.beneficiary_address
                      : ""}
                  </h5>
                </div>
                <div className="m-3">
                  <label className="text_tra1">Swift code</label>
                  <h5 className="text-white">
                    {this.state.selectedAccount
                      ? this.state.selectedAccount.swift_code
                      : ""}
                  </h5>
                </div>
                <div className="m-3">
                  <label className="text_tra1">Currency</label>
                  <h5 className="text-white">
                    {this.state.selectedAccount
                      ? this.state.selectedAccount.currency
                      : ""}
                  </h5>
                </div>
                <div className="m-3">
                  <label className="text_tra1">Bank Name</label>
                  <h5 className="text-white">
                    {this.state.selectedAccount
                      ? this.state.selectedAccount.bank_name
                      : ""}
                  </h5>
                </div>

                <div className="text-center">
                  <button className="btn-blue" onClick={this.makePrimary}>
                    Make Primary
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <Modal
          show={this.state.disbursed}
          onHide={() => this.setState({ disbursed: false })}
          centered
        >
          <div
            style={{ backgroundColor: "#333333", color: "white" }}
            className="p-4"
          >
            <div className=" text-center">
              <img alt="loader" className="rotate" src={loader} />
              <div className="w-50 mx-auto mt-2">
                The amount disbursed from smart contract request has been sent
              </div>
            </div>
          </div>
        </Modal>

        <Modal show={this.state.showPdf} onHide={this.handleClose}>
          <div style={{ backgroundColor: "#333333", color: "white" }}>
            {this.state.pdfUrl !== null ? (
              this.state.pdfUrl.split(".").pop() === "pdf" ||
              this.state.pdfUrl.split(".").pop() === "PDF" ? (
                <iframe
                  src={`https://docs.google.com/gview?url=${this.state.pdfUrl}&embedded=true`}
                  style={{ width: "50rem", height: "50rem" }}
                  frameBorder="0"
                ></iframe>
              ) : (
                <>
                  <img
                    alt="pdf url"
                    src={this.state.pdfUrl}
                    style={{ width: "30rem", height: "30rem" }}
                  />
                </>
              )
            ) : (
              <div></div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  invoiceStatus: state.invoiceStatus.status,
  alert: state.alert,
  company: state.company,
  invoiceOffer: state.invoiceOffer,
  invoiceFinalOffer: state.invoiceFinalOffer,
  userAccount: state.userAccount,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceChanges: (data) => dispatch(getInvoiceChanges(data)),
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  postAlert: (data) => dispatch(postAlert(data)),
  getCompanyByCompanyId: (data) => dispatch(getCompanyByCompanyId(data)),
  postOffer: (data) => dispatch(postOffer(data)),
  getFinalOfferById: (data) => dispatch(getFinalOfferById(data)),
  getUserAccountsById: (data) => dispatch(getUserAccountsById(data)),
  UpdateUserAccount: (data) => dispatch(UpdateUserAccount(data)),
  getInvoiceCreatedByUser: (data) => dispatch(getInvoiceCreatedByUser(data)),
  postSmartToken: (data) => dispatch(postSmartToken(data)),
  getOfferById: (data) => dispatch(getOfferById(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdvancedFundAllocation));

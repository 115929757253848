import axios from "axios";
import { URI } from "../constant";
import ajax from "../utils/ajax";

export let updateCompanyDoc = (data) => {
  let url = URI + "/company/company-doc/" + data._id;
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};

export let createBuyerCompany = (data) => {
  let url = URI + "/company/buyer/";
  const res = ajax("POST", url, {}, {}, data);
  return res;
};

export let updateBorrowerCompany = (data) => {
  let url = URI + "/company/borrower-company/" + data._id;
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};
export let companyDocVerified = (data) => {
  let url = URI + "/company/company-doc-verify/" + data._id;
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};

import * as REFERRAL_CONSTANTS from "../constants/referralConstants";
export const referralReducer = (state = {
    type: '',
    error: '',
    isFetching: false,
    postReferrals: undefined,
   referrals:undefined,
}, action) => {
   
    switch (action.type) {
                case REFERRAL_CONSTANTS.HOME_GET_REFERRAL_BY_ID_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    referrals: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case REFERRAL_CONSTANTS.HOME_GET_REFERRAL_BY_ID_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    referrals: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case REFERRAL_CONSTANTS.HOME_GET_REFERRAL_BY_ID_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });

                case REFERRAL_CONSTANTS.HOME_POST_REFERRAL_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    postReferrals: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case REFERRAL_CONSTANTS.HOME_POST_REFERRAL_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    postReferrals: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case REFERRAL_CONSTANTS.HOME_POST_REFERRAL_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });


        default:
            return state;
    }
};


export default referralReducer;

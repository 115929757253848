import axios from "axios";
import * as INVOICE_OFFER_CONSTANTS from "../constants/invoiceOfferConstant";
import ajax from "../../utils/ajax";

export const requestGetOffer = () => ({
  type: INVOICE_OFFER_CONSTANTS.HOME_GET_INVOICE_OFFER_CONSTANTS.REQUEST,
});

export const recievedGetOfferResponse = (data) => ({
  type: INVOICE_OFFER_CONSTANTS.HOME_GET_INVOICE_OFFER_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetOfferError = (err) => ({
  type: INVOICE_OFFER_CONSTANTS.HOME_GET_INVOICE_OFFER_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getOfferById = (id) => async (dispatch) => {
  dispatch(requestGetOffer());
  try {
    let url = INVOICE_OFFER_CONSTANTS.HOME_GET_INVOICE_OFFER_CONSTANTS.URL + id;

    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetOfferResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetOfferError(err));
    return Promise.reject(err);
  }
};

export const requestPostOffer = () => ({
  type: INVOICE_OFFER_CONSTANTS.HOME_POST_INVOICE_OFFER_CONSTANTS.REQUEST,
});

export const recievedPostOfferResponse = (data) => ({
  type: INVOICE_OFFER_CONSTANTS.HOME_POST_INVOICE_OFFER_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedPostOfferError = (err) => ({
  type: INVOICE_OFFER_CONSTANTS.HOME_POST_INVOICE_OFFER_CONSTANTS
    .RECEIVED_ERROR,
  errorCode: err,
});
export const postOffer = (data) => async (dispatch) => {
  dispatch(requestPostOffer());
  try {
    let url = INVOICE_OFFER_CONSTANTS.HOME_POST_INVOICE_OFFER_CONSTANTS.URL;
    const res = await ajax("POST", url, {}, {}, data);

    dispatch(recievedPostOfferResponse(res.data));

    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(recievedPostOfferError(err));
    return Promise.reject(err);
  }
};

export const requestUpdateOffer = () => ({
  type: INVOICE_OFFER_CONSTANTS.HOME_UPDATE_INVOICE_OFFER_CONSTANTS.REQUEST,
});

export const recievedUpdateOfferResponse = (data) => ({
  type: INVOICE_OFFER_CONSTANTS.HOME_UPDATE_INVOICE_OFFER_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedUpdateOfferError = (err) => ({
  type: INVOICE_OFFER_CONSTANTS.HOME_UPDATE_INVOICE_OFFER_CONSTANTS
    .RECEIVED_ERROR,
  errorCode: err,
});
export const updateOffer = (data) => async (dispatch) => {
  dispatch(requestUpdateOffer());
  try {
    let url = INVOICE_OFFER_CONSTANTS.HOME_POST_INVOICE_OFFER_CONSTANTS.URL;

    const res = await ajax("PUT", url, {}, {}, data);

    dispatch(recievedUpdateOfferResponse(res.data));

    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(recievedUpdateOfferError(err));
    return Promise.reject(err);
  }
};

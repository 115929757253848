import {URI} from '../../constant'

export const HOME_GET_REFERRAL_BY_ID_CONSTANTS = ({
    URL: URI+"/referral/",
    REQUEST: 'REQUEST_GET_REFERRAL_BY_ID_CONSTANTS',
    RECEIVED: 'RECEIVED_GET_REFERRAL_BY_ID_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_GET_REFERRAL_BY_ID_CONSTANTS',
  });

  export const HOME_POST_REFERRAL_CONSTANTS = ({
    URL: URI+"/referral",
    REQUEST: 'REQUEST_POST_REFERRAL_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_POST_REFERRAL_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_POST_REFERRAL_DATA_CONSTANTS',
  });
import React from "react";
import ajax from "../../../utils/ajax";
import { URI } from "../../../constant";
import { ToastContainer, toast } from "react-toastify";
import {
  USER_TYPE,
  STATUSES,
  ADMIN_ALERT_TYPES,
} from "../../../config/string_constants";
import helper from "../../../utils/helper";
import {
  getCompanyUsersAPI,
  createCompanyUser,
} from "../../../api/userService";
export default class ShareHolders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareholder: {
        shareholder_full_name: "",
        company_id: "",
        poi_url: "",
        poa_url: "",
        pan_url: "",
        user_type: USER_TYPE.SHAREHOLDER,
        createdById: sessionStorage.getItem("userId"),
      },
      showShareHolderForm:false,
      ShareHolders: [],
      shareholderLoader: [],
      shareholder_error: [],
      dirty: {
        full_name: false,
        email: false,
        phone_number: false,
        company_name: false,
        company_email_id: false,
        company_phone_no: false,
        website: false,
        about: false,
        company_landline: false,
        company_iec_number: false,
        address_line1: false,
        address_line2: false,
        city: false,
        zipcode: false,
        country_id: false,
        user_dialCode: false,
        company_dialCode: false,
        full_name: false,
        shareholder_full_name: false,
      },
      errors: {
        full_name: [],
        email: [],
        phone_number: [],
        company_name: [],
        company_email_id: [],
        company_phone_no: [],
        website: [],
        about: [],
        company_landline: [],
        address_line1: [],
        address_line2: [],
        city: [],
        zipcode: [],
        company_iec_number: [],
        country_id: [],
        user_dialCode: [],
        company_dialCode: [],
        full_name: [],
        shareholder_full_name: [],
      },
    };
  }
  handleShareHolderChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;

    let shareholder = this.state.shareholder;

    shareholder[name] = value;

    this.setState(
      {
        shareholder: shareholder,
        dirty: dirty,
      },
      this.validate
    );
  };
  shareholderFiles = (e) => {
    if (helper.validateFileType(e.target.files)) {
      this.uploadShareholderFile(e.target.files[0], e.target.name);
    } else {
      let loader = {};
      let error = {};
      loader[e.target.name] = false;
      error[e.target.name] =
        "Please Upload file in JPG, PNG, PDF and max size 5mb.";
      this.setState({
        shareholderLoader: loader,
        shareholder_error: error,
      });
    }
  };

  uploadShareholderFile(file, name) {
    let loader = {};
    let error = {};
    loader[name] = true;
    error[name] = "";
    this.setState({ shareholderLoader: loader });
    const formData = new FormData();
    formData.append("image", file);
    if (file !== undefined) {
      ajax(
        "POST",
        `${URI}/api/v1/image-upload`,
        {},
        { "content-type": "multipart/form-data" },
        formData
      )
        .then((data) => {
          let shareholder = this.state.shareholder;
          shareholder[name] = data.data.imageUrl;
          loader[name] = false;
          this.setState({
            shareholder: shareholder,
            shareholderLoader: loader,
            shareholder_error: error,
          });
        })
        .catch((err) => {});
    }
  }
  addShareholder = async () => {
    var validName = /^[a-zA-Z ]*$/;
    if (
      !this.state.shareholder.shareholder_full_name ||
      !this.state.shareholder.poa_url ||
      !this.state.shareholder.poi_url ||
      !validName.test(this.state.shareholder.full_name)
    ) {
      toast.error("Please Add Required Fields");
    } else {
      let userObject = {
        user_type: USER_TYPE.SHAREHOLDER,
        full_name: this.state.shareholder.shareholder_full_name,
        poa_url: this.state.shareholder.poa_url,
        poi_url: this.state.shareholder.poi_url,
        company_id: this.props.company_id,
        createdById: sessionStorage.getItem("userId"),
      };
      userObject.update_type = ADMIN_ALERT_TYPES.KYC;

      let res = await createCompanyUser(userObject);

      if (res.status === 200 || res.status === 201) {
        this.props.getCompanyUsers(this.props.company_id);
        this.setState({
          shareholder: {
            ...this.state.shareholder,
            shareholder_full_name: "",
            poa_url: "",
            poi_url: "",
            pan_url: "",
          },
        });
        toast.info("Shareholder Added Successfully");
      } else {
        toast.error("Shareholder Not Added");
      }
    }
  };
  showMoreShareHolder = () => {
    this.setState({
      showShareHolderForm: !this.state.showShareHolderForm,
    });
  };
  render() {
    return (
      <>
        <div className=" m-3 text-white">
          Share Holders
          <span className="ml-1">(Shareholding Above 10%)</span>
        </div>
        <div className=" m-3 mo_cont">
          <table className="table table-dark table-hover bor_line">
            <thead>
              <tr className="crd_brd">
                <th>Full Name</th>
                <th>Passport/National ID </th>
                <th> <span className="mt-1 link "
                          data-tooltip="Utility bills dated for less than 3 months">
                          POA (Proof of Address){" "}
                          <i className="uil-info-circle text_tra1" />
                        </span></th>
                {/* <th>PAN(Optional)</th> */}
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.props.ShareHoldersData.map((share) => (
                <tr key={share._id} className="bg_Table">
                  <td>{share.full_name}</td>
                  <td>
                    {" "}
                    <br />
                    {share.poi_url ? (
                      <a href={share.poi_url}>Download</a>
                    ) : (
                      <h6>--</h6>
                    )}
                  </td>
                  <td>
                    <br />
                    {share.poa_url ? (
                      <a href={share.poa_url}>Download</a>
                    ) : (
                      <h6>--</h6>
                    )}
                  </td>

                  <td></td>
                  <td></td>
                </tr>
              ))}
             {this.state.showShareHolderForm?<tr className="bg_Table">
                <td>
                  <form className="form-inline my-2 my-md-0">
                    <input
                      className="form-control input_bg"
                      type="text"
                      name="shareholder_full_name"
                      placeholder="Shareholder Fullname"
                      value={this.state.shareholder.shareholder_full_name}
                      onChange={this.handleShareHolderChange}
                      maxLength="20"
                    />
                    <div className="text-danger">
                      {this.state.errors.shareholder_full_name[0] &&
                      this.state.dirty.shareholder_full_name
                        ? this.state.errors.shareholder_full_name
                        : ""}
                    </div>
                  </form>
                </td>
                <td>
                  <label className="td-w mr-1">
                    {this.state.shareholderLoader.poa_url ? (
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                        }}
                        className="spinner-border btn--icon"
                      ></div>
                    ) : (
                      "Upload"
                    )}
                    <input
                      type="file"
                      name="poa_url"
                      onChange={this.shareholderFiles}
                    ></input>
                  </label>
                  {this.state.shareholder.poa_url ? (
                    <a href={this.state.shareholder.poa_url}>Download</a>
                  ) : (
                    <h6></h6>
                  )}
                  {this.state.shareholder_error.poa_url && (
                    <div className="text-danger">
                      {this.state.shareholder_error.poa_url}
                    </div>
                  )}
                </td>
                <td>
                  <label className="td-w mr-1">
                    {this.state.shareholderLoader.poi_url ? (
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                        }}
                        className="spinner-border btn--icon"
                      ></div>
                    ) : (
                      "Upload"
                    )}
                    <input
                      type="file"
                      name="poi_url"
                      onChange={this.shareholderFiles}
                    ></input>
                  </label>
                  {this.state.shareholder.poi_url ? (
                    <a href={this.state.shareholder.poi_url}>Download</a>
                  ) : (
                    <h6></h6>
                  )}
                  {this.state.shareholder_error.poi_url && (
                    <div className="text-danger">
                      {this.state.shareholder_error.poi_url}
                    </div>
                  )}
                </td>
                <td>
                  <label className="td-w">
                    <label className="td-w mr-1">
                      <button
                        className="btn-circle"
                        onClick={this.addShareholder}
                      >
                        +
                      </button>
                    </label>
                  </label>
                </td>
                <td></td>
              </tr>:"" } 
            </tbody>
          </table>
          <div className="my-3">
            <span
              className="txt_b ml-4 underline curserP"
              onClick={this.showMoreShareHolder}
            >
              + Add More Share Holders
            </span>
          </div>
        </div>
      </>
    );
  }
}

import {URI} from '../../constant'

export const HOME_GET_BUYER_CONSTANTS = ({
    URL: URI+"/buyer",
    REQUEST: 'REQUEST_GET_BUYER_CONSTANTS',
    RECEIVED: 'RECEIVED_GET_BUYER_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_GET_BUYER_CONSTANTS',
  });

  export const HOME_POST_BUYER_CONSTANTS = ({
    URL: URI+"/buyer",
    REQUEST: 'REQUEST_POST_BUYER_CONSTANTS',
    RECEIVED: 'RECEIVED_POST_BUYER_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_POST_BUYER_CONSTANTS',
  });

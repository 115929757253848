import * as INVOICE_OFFER_CONSTANTS from "../constants/invoiceOfferConstant";
export const invoiceOfferReducer = (state = {
    type: '',
    error: '',
    isFetching: false,
   offer:undefined,
   postOffer:undefined,
}, action) => {
   
    switch (action.type) {
                case INVOICE_OFFER_CONSTANTS.HOME_GET_INVOICE_OFFER_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    offer: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case INVOICE_OFFER_CONSTANTS.HOME_GET_INVOICE_OFFER_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    offer: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case INVOICE_OFFER_CONSTANTS.HOME_GET_INVOICE_OFFER_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });

                case INVOICE_OFFER_CONSTANTS.HOME_POST_INVOICE_OFFER_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    postOffer: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case INVOICE_OFFER_CONSTANTS.HOME_POST_INVOICE_OFFER_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    postOffer: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case INVOICE_OFFER_CONSTANTS.HOME_POST_INVOICE_OFFER_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });


        default:
            return state;
    }
};


export default invoiceOfferReducer;

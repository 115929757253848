import React, { Component } from "react";
import styles from "../../../assets/css/OnboardingPersonalDetails.module.css";
import Logo from "../../../assets/images/Logo.svg";
import MainHeader from "../mainHeader";
import Check from "../../../assets/images/check1.png";
import _isEmpty from "lodash/isEmpty";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import preview from "../../../assets/images/preview.png";
import download from "../../../assets/images/download1.png";
import moment from "moment";
import ajax from "../../../utils/ajax";
import {
  getInvoiceDetailsById,
  getAllInvoices,
  getInvoiceChanges,
} from "../../../services/actions/invoiceAction";
import { updateCompany } from "../../../services/actions/companyActions";
import { updateInvoice } from "../../../services/actions/invoiceAction";
import { getInvoiceStatus } from "../../../services/actions/invoiceStatusAction";
import { postAlert } from "../../../services/actions/alertActions";
import { getCountry } from "../../../services/actions/countryActions";
import Modal from "react-bootstrap/Modal";
import { URI } from "../../../constant";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InvoiceMoreDetail from "./invoiceMoreDetail";
import Progressbar from "./progressBar";
import InvoicesListId from "./invoicesListId";
import defaultlogo from "../../../assets/images/defaultLogo.png";
import Header from "./header";
import {
  INVOICE_STATUSES_KEY,
  CLIENT_ALERT_TYPES,
} from "../../../config/string_constants";
import { getAllInvoiceStatusByKey } from "../../../api/invoiceStatus";
import { companyDocVerified } from "../../../api/companyService";
import {
  approveInvoiceByAdmin,
  rejectInvoiceByAdmin,
} from "../../../api/invoiceService";
class CompanyDocumentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceId: this.props.match.params.id,
      companyId: "",
      companyDetails: false,
      invoiceCompany: [],
      country: [],
      showPdf: false,
      pdfUrl: null,
      updateSuccess: false,
      alertSuccess: false,
      approvestatus: "",
      approveSubStatus: "",
      declineStatus: "",
      declineSubStatus: "",
      screen: INVOICE_STATUSES_KEY.COMPANY_DOCUMENT,
      clientCompanyId: "",
      invoicedetails: [],
      alertData: {
        invoice_id: "",
        invoice_ref_no: "",
        invoice_status_id: "",
        invoice_substatus_id: "",
        client_Id: "",
      },
      currentStatus: "",
    };
  }

  componentDidMount() {
    this.props
      .getInvoiceDetailsById(this.state.invoiceId)
      .then((result) => {
        this.props.getInvoiceChanges(result);
        this.setState({
          invoicedetails: result,
          invoiceCompany: result.client_company,
        });
      })
      .catch((err) => {});

    this.props
      .getCountry()
      .then((result) => {
        this.setState({
          country: result,
        });
      })
      .catch((err) => {});

    //Get Invoice Status

    for (let j = 0; j < this.props.invoiceStatus.length; j++) {
      if (
        this.props.invoiceStatus[j].key === INVOICE_STATUSES_KEY.KYC_PROCESS
      ) {
        for (let i = 0; i < this.props.invoiceStatus[j].subStatus.length; i++) {
          if (
            this.props.invoiceStatus[j].subStatus[i].statusName ===
            "In Progress"
          ) {
            this.setState({
              approvestatus: this.props.invoiceStatus[j]._id,
              approveSubStatus: this.props.invoiceStatus[j].subStatus[i]._id,
            });
            break;
          }
        }
      }
      if (
        this.props.invoiceStatus[j].key ===
        INVOICE_STATUSES_KEY.COMPANY_DOCUMENT
      ) {
        for (let i = 0; i < this.props.invoiceStatus[j].subStatus.length; i++) {
          if (
            this.props.invoiceStatus[j].subStatus[i].statusName === "Declined"
          ) {
            this.setState({
              declineStatus: this.props.invoiceStatus[j]._id,
              declineSubStatus: this.props.invoiceStatus[j].subStatus[i]._id,
              currentStatus: this.props.invoiceStatus[j]._id,
            });
            break;
          }
        }
      }
    }
  }

  onPdfShow = (url) => {
    this.setState({
      showPdf: true,
      pdfUrl: url,
    });
  };
  handleClose = () => {
    this.setState({ showPdf: false });
  };

  fileChange = (e) => {
    this.uploadFile(e.target.files[0], e.target.name);
    let files = e.target.files;

    let reader = new FileReader();
    if (e.target.files[0] !== undefined) {
      reader.readAsDataURL(files[0]);
      reader.onLoad = (e) => {};
    }
  };
  uploadFile(file, name) {
    const formData = new FormData();
    formData.append("image", file);
    if (file !== undefined) {
      ajax(
        "POST",
        `${URI}/api/v1/image-upload`,
        {},
        { "content-type": "multipart/form-data" },
        formData
      )
        .then((data) => {
          const company = this.state.invoiceCompany;

          company[name] = data.data.imageUrl;

          this.setState({
            invoiceCompany: company,
          });
          this.setState(
            {
              updateSuccess: true,
            },
            () => {
              this.props
                .updateCompany(this.state.invoiceCompany)
                .then((result) => {
                  toast.info("Company Document Updated");
                  this.setState({
                    updateSuccess: false,
                  });
                })
                .catch((err) => {});
            }
          );
        })
        .catch((err) => {});
    }
  }
  onSubmit = () => {
    let compData = {
      _id: this.state.invoiceCompany._id,
      company_doc_verified: "Verified",
    };
    companyDocVerified(compData).then(
      (res) => {
        toast.info("Company Document Verified");
        let data = {
          invoiceStatus_id: this.state.approvestatus,
          invoiceSubStatus_id: this.state.approveSubStatus,
          update_type: CLIENT_ALERT_TYPES.COMPANY_DOCUMENTATION_APPROVED,
          _id: this.state.invoicedetails._id,
        };

        this.setState(
          {
            updateSuccess: true,
          },
          () => {
            approveInvoiceByAdmin(data)
              .then((result) => {
                this.setState(
                  {
                    updateSuccess: false,
                    alertSuccess: true,
                  },
                  () => {
                    this.setState({ alertSuccess: false });
                    this.props.history.push(
                      `/kyc-process/${this.state.invoiceId}`
                    );
                  }
                );
              })
              .catch((err) => {});
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };
  onDecline = () => {
    let compData = {
      _id: this.state.invoiceCompany._id,
      company_doc_verified: "Declined",
    };
    companyDocVerified(compData).then(
      (res) => {
        toast.error("Company Document Declined");
        let data = {
          invoiceStatus_id: this.state.declineStatus,
          invoiceSubStatus_id: this.state.declineSubStatus,
          update_type: CLIENT_ALERT_TYPES.COMPANY_DOCUMENTATION_APPROVED,
          _id: this.state.invoicedetails._id,
        };

        this.setState(
          {
            updateSuccess: true,
          },
          () => {
            rejectInvoiceByAdmin(data)
              .then((result) => {
                this.setState(
                  {
                    updateSuccess: false,
                    alertSuccess: true,
                  },
                  () => {
                    this.setState({ alertSuccess: false });
                  }
                );
              })
              .catch((err) => {});
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };

  render() {
    return (
      <div>
        <Header />
        <div className="row m-4">
          <div className="col-3">
            <InvoiceMoreDetail
              invoicedetails={this.state.invoicedetails}
              invoiceCompany={this.state.invoiceCompany}
            />
          </div>
          <div className="col-9 overflow-auto">
            <Progressbar
              invoiceId={this.state.invoiceId}
              screen={this.state.screen}
            />
          </div>
        </div>
        <div className="row m-2 my-4">
          <div className="col-6">
            <div className="info-one h-100">
              <div className="tab-content">
                <div className="tab-pane active" id="tabs-1" role="tabpanel">
                  <p className="text_tra1 mb-2">Documents to upload</p>
                  <p className="text_tra1">
                    Please upload documents in PDF format. Max file size should
                    be 25MB.
                  </p>
                  <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                    <h5>Documents</h5>
                    <h5 className="text-white">
                      {this.state.invoiceCompany ? (
                        this.state.invoiceCompany.company_doc_verified ===
                        "Verified" ? (
                          <span className="text_tra1">
                            <span className="mx-3">
                              {moment(
                                this.state.invoiceCompany.updatedAt
                              ).format("DD MMM YYYY HH:MM")}
                            </span>
                            <span className="txt_g">
                              <i className="uil-comment-verify" />
                              {this.state.invoiceCompany.company_doc_verified}
                            </span>{" "}
                          </span>
                        ) : (
                          <span className="txt_y">
                            <i className="uil-info-circle" />
                            {this.state.invoiceCompany.company_doc_verified}
                          </span>
                        )
                      ) : (
                        ""
                      )}
                    </h5>
                  </div>
                  <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                    <h5>
                      GST<span style={{ color: "#5a5c5d" }}>(Optional)</span>
                    </h5>
                    <h5 className="text-white">
                      {this.state.invoiceCompany ? (
                        this.state.invoiceCompany.company_gst_url ? (
                          <span>
                            <a
                              onClick={() =>
                                this.onPdfShow(
                                  this.state.invoiceCompany.company_gst_url
                                )
                              }
                            >
                              <img className="mx-3" src={preview} />
                            </a>
                            <a
                              href={this.state.invoiceCompany.company_gst_url}
                              style={{ color: "white" }}
                            >
                              {" "}
                              <img className="mx-3" src={download} />
                            </a>
                            <label className="uploadb header-input mx-3">
                              Reupload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="company_gst_url"
                                onChange={this.fileChange}
                              />
                            </label>
                          </span>
                        ) : (
                          <span>
                            {" "}
                            <label className="uploadb header-input mx-3">
                              Upload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="company_gst_url"
                                onChange={this.fileChange}
                              />
                            </label>
                            <label className="txt_y mx-3">
                              <i className="uil-info-circle" />
                              Pending
                            </label>
                          </span>
                        )
                      ) : (
                        ""
                      )}
                    </h5>
                  </div>
                  <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                    <h5>
                      MOA/AOA
                      <span style={{ color: "#5a5c5d" }}>(Optional)</span>
                    </h5>
                    <h5 className="text-white">
                      {this.state.invoiceCompany ? (
                        this.state.invoiceCompany.company_moa_url ? (
                          <span>
                            <a
                              onClick={() =>
                                this.onPdfShow(
                                  this.state.invoiceCompany.company_moa_url
                                )
                              }
                            >
                              <img className="mx-3" src={preview} />
                            </a>
                            <a
                              href={this.state.invoiceCompany.company_moa_url}
                              style={{ color: "white" }}
                            >
                              {" "}
                              <img className="mx-3" src={download} />
                            </a>
                            <label className="uploadb header-input mx-3">
                              Reupload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="company_moa_url"
                                onChange={this.fileChange}
                              />
                            </label>
                          </span>
                        ) : (
                          <span>
                            <label className="uploadb header-input mx-3">
                              Upload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="company_moa_url"
                                onChange={this.fileChange}
                              />
                            </label>
                            <label className="txt_y mx-3">
                              <i className="uil-info-circle" />
                              Pending
                            </label>
                          </span>
                        )
                      ) : (
                        ""
                      )}
                    </h5>
                  </div>

                  <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                    <h5>
                      Certificate of Incorporation
                      <span style={{ color: "#5a5c5d" }}>(Optional)</span>
                    </h5>
                    <h5 className="text-white">
                      {this.state.invoiceCompany ? (
                        this.state.invoiceCompany.company_coi_url ? (
                          <span>
                            <a
                              onClick={() =>
                                this.onPdfShow(
                                  this.state.invoiceCompany.company_coi_url
                                )
                              }
                            >
                              <img className="mx-3" src={preview} />
                            </a>
                            <a
                              href={this.state.invoiceCompany.company_coi_url}
                              style={{ color: "white" }}
                            >
                              {" "}
                              <img className="mx-3" src={download} />
                            </a>
                            <label className="uploadb header-input mx-3">
                              Reupload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="company_coi_url"
                                onChange={this.fileChange}
                              />
                            </label>
                          </span>
                        ) : (
                          <span>
                            {" "}
                            <label className="uploadb header-input mx-3">
                              Upload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="company_coi_url"
                                onChange={this.fileChange}
                              />
                            </label>
                            <label className="txt_y mx-3">
                              <i className="uil-info-circle" />
                              Pending
                            </label>
                          </span>
                        )
                      ) : (
                        ""
                      )}
                    </h5>
                  </div>
                  <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                    <h5>
                      Latest Audited Financial Statements
                      <span style={{ color: "#E9556E" }}>*</span>
                    </h5>
                    <h5 className="text-white">
                      {this.state.invoiceCompany ? (
                        this.state.invoiceCompany.company_audit_url ? (
                          <span>
                            <a
                              onClick={() =>
                                this.onPdfShow(
                                  this.state.invoiceCompany.company_audit_url
                                )
                              }
                            >
                              <img className="mx-3" src={preview} />
                            </a>
                            <a
                              href={this.state.invoiceCompany.company_audit_url}
                              style={{ color: "white" }}
                            >
                              {" "}
                              <img className="mx-3" src={download} />
                            </a>
                            <label className="uploadb header-input mx-3">
                              Reupload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="company_audit_url"
                                onChange={this.fileChange}
                              />{" "}
                            </label>
                          </span>
                        ) : (
                          <span>
                            <label className="uploadb header-input mx-3">
                              Upload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="company_audit_url"
                                onChange={this.fileChange}
                              />{" "}
                            </label>
                            <label className="txt_y mx-3">
                              <i className="uil-info-circle" />
                              Pending
                            </label>
                          </span>
                        )
                      ) : (
                        ""
                      )}
                    </h5>
                  </div>
                  <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                    <h5>
                      Previous Audited Financial Statements
                      <span style={{ color: "#E9556E" }}>*</span>
                    </h5>
                    <h5 className="text-white">
                      {this.state.invoiceCompany ? (
                        this.state.invoiceCompany.previous_audit_url ? (
                          <span>
                            <a
                              onClick={() =>
                                this.onPdfShow(
                                  this.state.invoiceCompany.previous_audit_url
                                )
                              }
                            >
                              <img className="mx-3" src={preview} />
                            </a>
                            <a
                              href={
                                this.state.invoiceCompany.previous_audit_url
                              }
                              style={{ color: "white" }}
                            >
                              {" "}
                              <img className="mx-3" src={download} />
                            </a>
                            <label className="uploadb header-input mx-3">
                              Reupload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="previous_audit_url"
                                onChange={this.fileChange}
                              />{" "}
                            </label>
                          </span>
                        ) : (
                          <span>
                            <label className="uploadb header-input mx-3">
                              Upload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="company_audit_url"
                                onChange={this.fileChange}
                              />{" "}
                            </label>
                            <label className="txt_y mx-3">
                              <i className="uil-info-circle" />
                              Pending
                            </label>
                          </span>
                        )
                      ) : (
                        ""
                      )}
                    </h5>
                  </div>
                  <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                    <h5>
                      Last 6 months bank statements
                      <span style={{ color: "#E9556E" }}>*</span>
                    </h5>
                    <h5 className="text-white">
                      {this.state.invoiceCompany ? (
                        this.state.invoiceCompany.bank_statement_url ? (
                          <span>
                            <a
                              onClick={() =>
                                this.onPdfShow(
                                  this.state.invoiceCompany.bank_statement_url
                                )
                              }
                            >
                              <img className="mx-3" src={preview} />
                            </a>
                            <a
                              href={
                                this.state.invoiceCompany.bank_statement_url
                              }
                              style={{ color: "white" }}
                            >
                              {" "}
                              <img className="mx-3" src={download} />
                            </a>
                            <label className="uploadb header-input mx-3">
                              Reupload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="bank_statement_url"
                                onChange={this.fileChange}
                              />{" "}
                            </label>
                          </span>
                        ) : (
                          <span>
                            <label className="uploadb header-input mx-3">
                              Upload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="company_audit_url"
                                onChange={this.fileChange}
                              />{" "}
                            </label>
                            <label className="txt_y mx-3">
                              <i className="uil-info-circle" />
                              Pending
                            </label>
                          </span>
                        )
                      ) : (
                        ""
                      )}
                    </h5>
                  </div>
                  <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                    <h5>
                      Last 6 months Sales ledger/Journal
                      <span style={{ color: "#E9556E" }}>*</span>
                    </h5>
                    <h5 className="text-white">
                      {this.state.invoiceCompany ? (
                        this.state.invoiceCompany.sales_ledger_url ? (
                          <span>
                            <a
                              onClick={() =>
                                this.onPdfShow(
                                  this.state.invoiceCompany.sales_ledger_url
                                )
                              }
                            >
                              <img className="mx-3" src={preview} />
                            </a>
                            <a
                              href={this.state.invoiceCompany.sales_ledger_url}
                              style={{ color: "white" }}
                            >
                              {" "}
                              <img className="mx-3" src={download} />
                            </a>
                            <label className="uploadb header-input mx-3">
                              Reupload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="company_audit_url"
                                onChange={this.fileChange}
                              />{" "}
                            </label>
                          </span>
                        ) : (
                          <span>
                            <label className="uploadb header-input mx-3">
                              Upload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="company_audit_url"
                                onChange={this.fileChange}
                              />{" "}
                            </label>
                            <label className="txt_y mx-3">
                              <i className="uil-info-circle" />
                              Pending
                            </label>
                          </span>
                        )
                      ) : (
                        ""
                      )}
                    </h5>
                  </div>
                  {this.state.invoicedetails &&
                  this.state.invoicedetails.invoiceStatus_id &&
                  this.state.invoicedetails.invoiceStatus_id._id ===
                    this.state.currentStatus ? (
                    <div className="mt-5 text-center">
                      <button
                        style={{ background: "#E9556E" }}
                        className="btn-blue mx-2"
                        onClick={this.onDecline}
                      >
                        Decline
                      </button>
                      <button className="btn-blue mx-2" onClick={this.onSubmit}>
                        Approve
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="info-one h-100">
              <div className="bgc-dark text-white p-3">
                COMPANY DOCUMENTATION{" "}
                <span className="float-right">
                  {this.state.invoiceCompany ? (
                    this.state.invoiceCompany.company_doc_verified ===
                    "Verified" ? (
                      <span className="txt_g">
                        <i className="uil-comment-verify" />
                        {this.state.invoiceCompany.company_doc_verified}
                      </span>
                    ) : (
                      <span className="txt_y">
                        <i className="uil-info-circle" />
                        {this.state.invoiceCompany.company_doc_verified}
                      </span>
                    )
                  ) : (
                    ""
                  )}
                </span>
              </div>

              <div style={{ marginTop: "40%" }} className="p-3  bgc-dark">
                <div className="d-flex ">
                  <h5
                    className={
                      !this.state.companyDetails
                        ? "txt_b pb-1 border_b mx-2"
                        : "text_tra1 mx-2"
                    }
                    onClick={() => this.setState({ companyDetails: false })}
                  >
                    Company Details
                  </h5>
                  <h5
                    className={
                      !this.state.companyDetails
                        ? " text_tra1 mx-2"
                        : "txt_b mx-2 pb-1 border_b"
                    }
                    onClick={() => this.setState({ companyDetails: true })}
                  >
                    Company Address
                  </h5>
                </div>
                {this.state.invoiceCompany ? (
                  !this.state.companyDetails ? (
                    <div>
                      <div className="row mt-4  text_tra1">
                        <div className="col-4">
                          Company Name{" "}
                          <h6 className="text-white">
                            {this.state.invoiceCompany.company_name}
                          </h6>
                        </div>
                        <div className="col-4">
                          Company Email
                          <h6 className="text-white">
                            {this.state.invoiceCompany.company_email_id}
                          </h6>
                        </div>
                        <div className="col-4">
                          Company Website
                          <h6 className="text-white">
                            {this.state.invoiceCompany.website}
                          </h6>
                        </div>
                      </div>
                      <div className="row mt-4  text_tra1">
                        <div className="col-4">
                          Contact Number
                          <h6 className="text-white">
                            {this.state.invoiceCompany.company_dialCode}{" "}
                            {this.state.invoiceCompany.company_phone_no}
                          </h6>
                        </div>
                        <div className="col-4">
                          IEC number
                          <h6 className="text-white">
                            {this.state.invoiceCompany.company_iec_number}
                          </h6>
                        </div>
                        <div className="col-4">
                          Logo
                          <h6 className="text-white">
                            <img
                              width="50px"
                              height="50px"
                              src={
                                this.state.invoiceCompany.company_logo === ""
                                  ? defaultlogo
                                  : this.state.invoiceCompany.company_logo
                              }
                            />
                          </h6>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="p-3  bgc-dark">
                      <div className="row mt-4  text_tra1">
                        <div className="col-4">
                          Company Address{" "}
                          <h6 className="text-white">
                            {this.state.invoiceCompany.address_line1}
                          </h6>
                        </div>
                        <div className="col-4">
                          City
                          <h6 className="text-white">
                            {this.state.invoiceCompany.city}
                          </h6>
                        </div>
                        <div className="col-4">
                          Country
                          <h6 className="text-white">
                            {this.state.country !== undefined
                              ? this.state.country
                                  .filter(
                                    (c) =>
                                      c._id ===
                                      this.state.invoiceCompany.country_id
                                  )
                                  .map((c) => c.name)
                              : ""}
                          </h6>
                        </div>
                      </div>
                      <div className="row mt-4  text_tra1">
                        <div className="col-4">
                          Zip code
                          <h6 className="text-white">
                            {this.state.invoiceCompany.zipcode}
                          </h6>
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showPdf} onHide={this.handleClose}>
          <div style={{ backgroundColor: "#333333", color: "white" }}>
            {this.state.pdfUrl !== null ? (
              this.state.pdfUrl.split(".").pop() === "pdf" ||
              this.state.pdfUrl.split(".").pop() === "PDF" ? (
                <iframe
                  src={`https://docs.google.com/gview?url=${this.state.pdfUrl}&embedded=true`}
                  style={{ width: "50rem", height: "50rem" }}
                  frameBorder="0"
                ></iframe>
              ) : (
                <>
                  <img
                    src={this.state.pdfUrl}
                    style={{ width: "30rem", height: "30rem" }}
                  />
                </>
              )
            ) : (
              <div></div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  invoiceStatus: state.invoiceStatus.status,
  company: state.company,
  country: state.country,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceChanges: (data) => dispatch(getInvoiceChanges(data)),
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  getAllInvoices: (data) => dispatch(getAllInvoices(data)),
  getCountry: (data) => dispatch(getCountry(data)),
  updateCompany: (data) => dispatch(updateCompany(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  postAlert: (data) => dispatch(postAlert(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompanyDocumentation));

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Synaps from "../../../assets/images/Synaps.png";
import Close from "../../../assets/images/close_icon.svg";
import "../../../assets/css/common/fontStyle.css";
import "../../../assets/css/common/button.css";
import Button from "react-bootstrap/Button";
import { ethers } from "ethers";
import { kycMigrationForV2 } from "../../../api/kycService";
import { toast } from "react-toastify";
import { UNEXPECTED_ERROR } from "../../../config/string_constants";
import TcPopup from "../../Borrower/invoices/tcPopup";
import { LENDER_TNC } from "../../../constant";

export default function V2KycPopup(props) {
  const [checked, setChecked] = useState(false);
  const [address, setAddress] = useState("");
  const [validation, setValidation] = useState(false);
  const [response, setResponse] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [showPdf, setShowPdf] = useState(false);

  const handleChange = (value) => {
    setAddress(value);
    handleValidation(value);
  };

  const handleSubmit = async () => {
    setSpinner(true);
    const res = await kycMigrationForV2({ address: address });
    if (res.data.hash) {
      setResponse(true);
      setSpinner(false);
    } else {
      setSpinner(false);
      toast.error(UNEXPECTED_ERROR);
    }
  };

  const handleValidation = (value) => {
    if (validatWalletAddress(value)) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  const validatWalletAddress = (address) => {
    return ethers.utils.isAddress(address);
  };

  return (
    <div>
      <div style={{ fontFamily: "Averta-Semibold,sans-serif" }}>
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ backgroundColor: "transparent" }}
        >
          <div
            className="alert-popup"
            style={{ backgroundColor: "#21272A", color: "white", border: "0" }}
          >
            <div className="mt-3 disp-lg-none">
              <div className="line mx-auto"></div>
            </div>

            <Modal.Body style={{ textAlign: "center" }}>
              <div className="float-right mb-2 ">
                <img
                  src={Close}
                  onClick={props.onHide}
                  className="  user-icon"
                  alt=""
                />
              </div>
              <div
                style={{
                  margin: "0 auto",
                  width: "100%",
                  height: "100%",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                <div>
                  <img
                    src={Synaps}
                    className="user-icon ml-4 mt-4 mb-4"
                    alt=""
                  />
                </div>
                {response ? (
                  <div>
                    <h3 className="text-white mt-3">
                      Your wallet address has been submitted.
                    </h3>
                    <h5 className="text-white mt-3">
                      Synaps migration process will take 5-10 minutes to reflect
                      on your v2 account
                    </h5>
                    <h2 className="text-white mt-4">Thank You!</h2>
                  </div>
                ) : (
                  <div>
                    <h6 className="text-white">
                      Enter your v2 lender pool wallet address to migrate your
                      KYC from v1 lender pool
                    </h6>
                    <div className="mt-3">
                      <div className=" d-flex justify-content-end">
                        <div
                          className="font-12"
                          style={{ position: "absolute", margin: "9px" }}
                        >
                          Wallet ID
                        </div>
                        <input
                          className="inputV2"
                          type="text"
                          name="walletAddress"
                          value={address}
                          onChange={(e) => handleChange(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="text-white mt-3">
                      <input
                        className="form-check-input mr-5"
                        type="checkbox"
                        value={checked}
                        onClick={() => setChecked(!checked)}
                        style={{ height: "15px", width: "60px" }}
                      />
                      <span className="ml-4">I agree to the</span>{" "}
                      <span
                        className="txt_b curserP"
                        onClick={() => setShowPdf(true)}
                      >
                        {" "}
                        Terms & Conditions
                      </span>
                    </div>
                    <TcPopup
                      showPdf={showPdf}
                      handleClose={() => setShowPdf(false)}
                      lenderTNC={LENDER_TNC}
                    />
                    <div className="my-4">
                      <Button
                        className="w-25"
                        disabled={!checked || !validation}
                        onClick={() => handleSubmit()}
                        style={{ backgroundColor: "#2DA3B4" }}
                      >
                        {spinner ? (
                          <div className="spinner-border btn--icon" />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </div>
  );
}

import React, { Component } from "react";
import Sidebar from "./sidebar";
import MainHeader from "../mainHeader";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getLenders } from "../../../services/actions/userAction";
import { getKycStatus } from "../../../services/actions/kycStatusAction";
import { getCoins } from "../../../services/actions/coinActions";
import {
  getLenderById,
  updateUser,
} from "../../../services/actions/userAction";
import { getReward } from "../../../services/actions/userReward";
import _isEmpty from "lodash/isEmpty";
import ajax from "../../../utils/ajax";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { URI } from "../../../constant";
import axios from "axios";
import { REWARD_STATUS } from "../../../config/string_constants";

class Rewards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activate: false,
      rewardData: [],
      lenderDetails: [],
      searchLender: "",
      viewWallet: false,
      updateSucess: false,
    };
  }
  handleClose = () => {
    this.setState({
      viewWallet: false,
      activate: false,
    });
  };
  viewMore = (id) => {
    this.props
      .getLenderById(id)
      .then((result) => {
        this.setState({
          activate: true,
          viewWallet: true,
          lenderDetails: result,
        });
      })
      .catch((err) => {});
  };
  activateWallet = (id) => {
    this.props
      .getLenderById(id)
      .then((result) => {
        this.setState({
          activate: true,
          lenderDetails: result,
        });
      })
      .catch((err) => {});
  };
  handleChange = (e) => {
    this.setState({
      searchLender: e.target.value,
    });
  };
  Activate = () => {
    const { lenderDetails } = this.state;
    lenderDetails.wallet_activation = "Yes";
    this.setState({
      lenderDetails: lenderDetails,
      updateSucess: true,
    });

    this.props.updateUser(this.state.lenderDetails);
  };
  Deactivate = () => {
    const { lenderDetails } = this.state;
    lenderDetails.wallet_activation = "No";
    this.setState({
      lenderDetails: lenderDetails,
      updateSucess: true,
    });

    this.props.updateUser(this.state.lenderDetails);
  };

  sendRewards = (data) => {
    const id = data.tax_id;

    ajax("GET", `${URI}/rewards/tax/${id}`, {})
      .then((response) => {
        toast.info("rewards sent successfully");
      })
      .catch((err) => {});
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_isEmpty(nextProps.user.updateSucess) && this.state.updateSucess) {
      toast.info(" updated Successfully");

      this.setState({
        updateSucess: false,
        activate: false,
      });
    }
  }
  componentDidMount() {
    this.props
      .getReward()
      .then((result) => {
        // let finalData = result.filter((data)=>{if(data.wallet_details!=='{}'){return data}});
        this.setState({
          rewardData: result,
        });
      })
      .catch((err) => {});
  }

  render() {
    return (
      <div>
        <Sidebar />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="main-content">
          <header>
            <div className="dropdown show m-4">
              <a
                className="admin_button dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="pr-4">LENDER DASHBOARD</span>
              </a>

              <div
                className="admin_button dropdown-menu"
                aria-labelledby="dropdownMenuLink"
              >
                <Link to="/dashboard" className="text-decoration-none ">
                  <a className="text-white dropdown-item" href="#">
                    CLIENT DASHBOARD
                  </a>
                </Link>
              </div>
            </div>
            <MainHeader />
          </header>
          {/*main content*/}

          <main>
            <div className="d-flex w-100 justify-content-between ">
              <span className="mr-auto mb-4 mr-4 search d-flex justify-content-end">
                <i
                  style={{
                    position: "absolute",
                    margin: "8px 5px 7px auto",
                    color: "#fff",
                  }}
                  className="uil-search-alt"
                ></i>

                <input
                  className="homesearchbox"
                  style={{ padding: "7px", width: "53vh" }}
                  type="text"
                  placeholder="Search..."
                  name="searchPool"
                  value={this.state.searchPool}
                  onChange={this.handleSearch}
                />
              </span>
            </div>
            <div className="table-full-width mt-3 table-responsive px-0">
              <table className="border-0">
                <thead className="disp_non_sm">
                  <tr className="text_tra1 ">
                    <th>Lender Email</th>
                    <th>Lender ID</th>

                    <th>Total Claimed Rewards</th>
                    <th className>
                      Pending Rewards
                      {/* <i className="uil-info-circle"  data-toggle="tooltip" data-placement="right" title="User minimum deposit must be $100 to activate the  wallet"></i> */}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.rewardData === undefined ? (
                    <tr>No reward </tr>
                  ) : (
                    this.state.rewardData
                      .filter((data) => {
                        return data.user_id.email
                          .toLowerCase()
                          .includes(this.state.searchLender.toLowerCase());
                      })
                      .map((reward, i) => (
                        <tr key={i} className="sec1 ">
                          <td>
                            {reward.user_id
                              ? reward.user_id.email
                                ? reward.user_id.email
                                : ""
                              : ""}
                          </td>
                          <td className="font-style-3">
                            {reward.user_id
                              ? reward.user_id._id
                                ? reward.user_id._id
                                : ""
                              : ""}
                          </td>

                          {reward.reward_amount ? (
                            <td>{reward.reward_amount}</td>
                          ) : (
                            <td>-</td>
                          )}
                          {reward.reward_amount ? (
                            <td>{reward.reward_amount}</td>
                          ) : (
                            <td>-</td>
                          )}
                          <td>
                            <button
                              className="lendFinish"
                              //  className={reward.reward_status != "claim"? "lendDisabled":"lendFinish" }
                              disabled={
                                reward.reward_status === REWARD_STATUS.COMPLETED
                                  ? true
                                  : false
                              }
                              onClick={() => this.sendRewards(reward)}
                            >
                              {reward.reward_status === REWARD_STATUS.COMPLETED
                                ? REWARD_STATUS.SENT
                                : REWARD_STATUS.SEND_REWARDS}
                            </button>
                          </td>
                        </tr>
                      ))
                  )}
                </tbody>
              </table>
            </div>
          </main>
          <div
            style={{ overflowY: "auto" }}
            className={`menuNav ${this.state.activate ? "showMenu1" : ""}`}
          >
            <div
              style={{ borderBottom: "1px solid gray" }}
              className="d-flex justify-content-between text-white p-3 pl-1"
            >
              <div>
                <h5>{this.state.lenderDetails.email}</h5>
              </div>
              <h5 style={{ cursor: "pointer" }} onClick={this.handleClose}>
                x
              </h5>
            </div>
            <div className="row m-2">
              <div className="col-6">
                <div className="text_tra1">Total Deposit</div>
                <h5 className="text-white">
                  {this.state.lenderDetails.lending_data !== undefined
                    ? this.state.lenderDetails.lending_data.reduce(
                        (total, listValue) =>
                          total + parseInt(listValue.total_amount),
                        0
                      )
                    : ""}
                </h5>
              </div>
              <div className="col-6">
                <div className="text_tra1">Total Lending</div>
                <h5 className="text-white">
                  {this.state.lenderDetails.lending_data !== undefined
                    ? this.state.lenderDetails.lending_data.length
                    : ""}
                </h5>
              </div>
              <div className="col-6">
                <div className="text_tra1">Active Lending</div>
                <h5 className="text-white">
                  {this.state.lenderDetails.lending_data !== undefined
                    ? this.state.lenderDetails.lending_data.length
                    : ""}
                </h5>
              </div>
            </div>
            <div className="mx-3 mb-2" style={{ marginTop: "20rem" }}>
              {this.state.viewWallet ? (
                <button className="btn-blue w-100 " onClick={this.Deactivate}>
                  Deactivate Wallet{" "}
                </button>
              ) : (
                <button
                  className="btn-blue w-100 d-none "
                  onClick={this.Activate}
                >
                  Activate Confirm
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  kycStatus: state.kycStatus,
  coin: state.coin,
});

const mapDispatchToProps = (dispatch) => ({
  getKycStatus: (data) => dispatch(getKycStatus(data)),
  getLenders: (data) => dispatch(getLenders(data)),
  getReward: (data) => dispatch(getReward(data)),
  getCoins: (data) => dispatch(getCoins(data)),
  getLenderById: (data) => dispatch(getLenderById(data)),
  updateUser: (data) => dispatch(updateUser(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Rewards));

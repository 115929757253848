import React from "react";

import DetailsExpander from "./detailsExpander";
import SoftOfferDetailsCard from "./detailsCard/softOfferDetailsCard";
import { DETAILS } from "../../../config/string_constants";

/**
 * This component displays soft offer details in final-offer page
 */
export default function SoftOfferDetails(props) {
  return (
    <DetailsExpander label={DETAILS.SOFT_OFFER_DETAILS}>
      <SoftOfferDetailsCard {...props} />
    </DetailsExpander>
  );
}

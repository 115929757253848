import axios from "axios";
import * as USER_ACCOUNT_CONSTANTS from "../constants/userAccountConstant";
import ajax from "../../utils/ajax";

export const requestGetUserAccountsById = () => ({
  type: USER_ACCOUNT_CONSTANTS.HOME_GET_USER_ACCOUNT_BY_ID_CONSTANTS.REQUEST,
});

export const recievedGetUserAccountsByIdResponse = (data) => ({
  type: USER_ACCOUNT_CONSTANTS.HOME_GET_USER_ACCOUNT_BY_ID_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetUserAccountsByIdError = (err) => ({
  type: USER_ACCOUNT_CONSTANTS.HOME_GET_USER_ACCOUNT_BY_ID_CONSTANTS
    .RECEIVED_ERROR,
  errorCode: err,
});

export const getUserAccountsById = (id) => async (dispatch) => {
  dispatch(requestGetUserAccountsById());
  try {
    let url =
      USER_ACCOUNT_CONSTANTS.HOME_GET_USER_ACCOUNT_BY_ID_CONSTANTS.URL + id;
    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetUserAccountsByIdResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetUserAccountsByIdError(err));
    return Promise.reject(err);
  }
};

export const requestPostUserAccount = () => ({
  type: USER_ACCOUNT_CONSTANTS.HOME_POST_USER_ACCOUNT_CONSTANTS.REQUEST,
});

export const recievedPostUserAccountResponse = (data) => ({
  type: USER_ACCOUNT_CONSTANTS.HOME_POST_USER_ACCOUNT_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedPostUserAccountError = (err) => ({
  type: USER_ACCOUNT_CONSTANTS.HOME_POST_USER_ACCOUNT_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const postUserAccount = (data) => {
  let url = USER_ACCOUNT_CONSTANTS.HOME_POST_USER_ACCOUNT_CONSTANTS.URL;

  return (dispatch) => {
    dispatch(requestPostUserAccount());

    ajax("POST", url, {}, {}, data)
      .then((res) => dispatch(recievedPostUserAccountResponse(res.data)))
      .catch((err) => dispatch(recievedPostUserAccountError(err)));
  };
};

export const requestUpdateUserAccount = () => ({
  type: USER_ACCOUNT_CONSTANTS.HOME_UPDATE_USER_ACCOUNT_CONSTANTS.REQUEST,
});

export const recievedUpdateUserAccountResponse = (data) => ({
  type: USER_ACCOUNT_CONSTANTS.HOME_UPDATE_USER_ACCOUNT_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedUpdateUserAccountError = (err) => ({
  type: USER_ACCOUNT_CONSTANTS.HOME_UPDATE_USER_ACCOUNT_CONSTANTS
    .RECEIVED_ERROR,
  errorCode: err,
});

export const UpdateUserAccount = (data) => async (dispatch) => {
  dispatch(requestUpdateUserAccount());
  try {
    let url =
      USER_ACCOUNT_CONSTANTS.HOME_UPDATE_USER_ACCOUNT_CONSTANTS.URL + data._id;
    const res = await ajax("PUT", url, {}, {}, data);
    if (res) {
      dispatch(recievedUpdateUserAccountResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedUpdateUserAccountError(err));
    return Promise.reject(err);
  }
};

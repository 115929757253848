import React, { Component, useEffect, useState } from "react";
import Sidebar from "./Dashboard/Sidebar";
import walletMainHeader from "./walletMainHeader";
import BottomNav from "./Dashboard/bottomnav";
import "../../assets/css/wallet.css";
import BUSD from "../../assets/images/BUSD.png";
import Info from "../../assets/images/info.png";
import Copy from "../../assets/images/Copy.png";
import Address from "../../assets/images/Address.png";
import Deposit from "../../assets/images/Deposit.png";
import MobHeader from "./Dashboard/MobHeader";
import Bottomnav from "./Dashboard/bottomnav";
import axios from "axios";
import { URI } from "../../constant";
import { useDispatch, connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../../assets/css/calculator.css";
import address from "../../assets/images/Address.png";
import copy from "../../assets/images/Copy.png";
import QrPopup from "./Common/qr_popup";
import QRCode from "qrcode.react";
import ajax from "../../utils/ajax";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { postPool } from "../../services/actions/poolAction";
import { getCoins } from "../../services/actions/coinActions";

class DepositCrypto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coin: [],
      coindata: { current_price: "", name: "", id: "", image: "", symbol: "" },
      coinG: [],
      defaultCoin:'',
      pool: {
        minimum_locked_amount: "",
        day_apy: [],
        lending_users: [],
        logo_url: null,
      },
      pools: [],
      copyId:false,
      reward: "",
      apy_array: { apy_month: "", percentage: "", is_active: false },
      coindata: [],
      values: "",
      amount: 0,
      est_intrest: "",
      est_reward: "",
      total_reward: "",
      setcoindata: {},
      settotalReward: "",
      setvalue: "",
      setCoins: [],
      selectedCoin: "",
      walletData: {},
      QRShow: false,
      selectedCoin: {},
      faq: [
        {
          title: "How do I deposit funds ?",
          answer:[
            {
             step: `Step 1: Log in to your account and go to "Wallet" - "Deposit".
             `
            },
            {
             step: `Step 2: Make sure the coin you want to deposit is selected. Choose your preferred chain. Then you can either scan the QR code for the address or just copy the address. Paste the address on the platform or wallet that you plan to transfer the funds from.`, 
            },
            {
             step: `Step 3: Once the deposit is completed, the deposit transaction will be dispalyed in "History".`
            }
             ]
        },
        {
          title: "Raise your query to Polytrade team",
          answer:[
            {
              step: `Step 1: Select "Support" on the lower left corner.`, 
            },
            {
              step: `Step 2: Add your Name , Email Address and Problem Statement. You can attach any document or sheet shot for reference.` ,
            },
            {
              step: `Step 3: Once the ticket has been raised you can keep track of the same from your email. `,
              
            }
                ]
        },
      ],
    };
  }
  //Get wallet details
  getWalletDetails = async () => {
    try {
      const userData = await ajax(
        "GET",
        `${URI}/user/wallet/${sessionStorage.getItem("userId")}`,
        {}
      );
      const data = userData.data.result ? userData.data.result : {};
      this.setState({
        walletData: data,
      });
    } catch (e) {}
  };

  //Creat wallet
  createWallet = async () => {
    const userData = await ajax(
      "POST",
      `${URI}/user/wallet/create/${sessionStorage.getItem("userId")}`,
      {}
    );
    sessionStorage.setItem(
      "wallet_Id",
      userData.data.wallet_address ? userData.data.wallet_address : ""
    );
    this.setState({
      walletData: userData.data.wallet_details,
    });
  };
  //For copying the deposit address
  copyWalletId = () => {
    navigator.clipboard.writeText(
      this.state.walletData ? this.state.walletData.address : ""
    );
    this.setState({ copyId: true });
    setTimeout(() => {
      this.setState({ copyId: false });
    }, 2000);
  };

  handleCoinChange = (e) => {
    const currentPrice = this.state.coinG.filter(
      (item) => item.name === e.target.value
    )[0];

    const current_price = currentPrice ? currentPrice.current_price : "";
    const image = currentPrice ? currentPrice.image : "";
    const name = currentPrice ? currentPrice.name : "";
    this.setState({
      coin: {
        ...this.state.coin,
        current_price: current_price,
        image: image,
        name: name,
      },
      selectedCoin: currentPrice,
    });
  };

  handleonLoadCoinChange = (e) => {
    const currentPrice = this.state.coinG.filter((item) => item.name === e)[0];

    const current_price = currentPrice ? currentPrice.current_price : "";
    const image = currentPrice ? currentPrice.image : "";
    const name = currentPrice ? currentPrice.name : "";
    this.setState({
      coin: {
        ...this.state.coin,
        current_price: current_price,
        image: image,
        name: name,
      },
      selectedCoin: currentPrice,
    });
  };
  gcoindata = async () => {
    const res = await fetch(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false"
    );
    const realdata = await res.json();
    this.setState({
      coin: realdata,
      setcoindata: realdata,
    });
    //  this.setCoins(realdata);
    //  this.setcoindata(realdata[0]);
  };
  getdata = (data) => {
    this.setState({
      setcoindata: data,
    });
  };
  goback = () => {
    this.props.history.push("/lender/wallet");
  };

  componentDidMount() {
    let Selectedelement =
      document.getElementsByClassName(`invoices-menu`)[0].children[0];
    Selectedelement.classList.add("act");
    // this.fetchBalnce()

    const wallet_Id = sessionStorage.getItem("wallet_Id");

    if (wallet_Id === "") {
      this.createWallet();
    }
    this.gcoindata();
    this.getWalletDetails();
    this.props
      .getCoins()
      .then((result) => {
        this.setState({
          pools: result,
          defaultCoin:result[0].symbol
        });
      })
      .catch((err) => {});

    axios
      .get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false"
      )
      .then((result) => {
        this.setState(
          {
            coinG: result.data,
          },
          () => {
            const { selectd } = this.props.history.location.state;
            if (this.props.history.location.state.selectd) {
              this.handleonLoadCoinChange(
                this.props.history.location.state.selectd
              );
            }
          }
        );
      });
    // this.handleOnloadChange(selectd)
  }

  render() {
    return (
      <div>
        <div className="main-content main-content-lender Container fluid">
          <Sidebar />
          <QrPopup
            address={this.state.walletData ? this.state.walletData.address : ""}
            show={this.state.QRShow}
            onHide={() => this.setState({ QRShow: false })}
          />
          {/* <Bottomnav/> */}
          {/* <MobHeader/> */}
          <header className="disp-sm-none">
            {/* <h2 className='heading' style={{marginLeft:'10px'}}>
        </h2> */}
            <div className="mt-4 " style={{ marginLeft: "11px" }}>
              <i
                onClick={this.goback}
                style={{ fontSize: "20px", cursor: "pointer" }}
                className="ml uil-arrow-left"
              >
                {" "}
              </i>
              <span>Wallet</span>
            </div>
          </header>
          <h2 className="disp-sm-none deposit-crypto">Deposit Crypto</h2>

          <div
            className="text-white disp-lg-none borderw p-2 mb-4"
            style={{ background: "#262626" }}
          >
            <i
              onClick={this.goback}
              style={{ fontSize: "20px", cursor: "pointer" }}
              className="ml uil-arrow-left"
            >
              {" "}
            </i>
            <span>Deposit Crypto</span>
          </div>

          <div className="row fluid mb-3 pl-4 pr-4">
            <div className="col-sm-7">
              <div className="container-deposit pl-2 fluid">
                <div className="row">
                  <div className="col-md-10   mb-3  col-sm-11 ml-3 mt-3 text-left">
                    <label className="select-coin">Select the coin</label>

                    {/* {this.state.coin.image ?
                      <span className='busd'><img style={{ height: "18px", width: "25px" }} className="mt-2" src={this.state.coin.image} /> </span>
                      // {this.state.coin.name}  
                      : ""} */}
                    <div className="w-100 pr-4 pl-5 mx-auto ">
                      <div className="mt-3 d-flex justify-content-start">
                        <img
                          style={{
                            position: "absolute",
                            margin: "13px 0px 21px  -40px",
                            color: "#fff",
                          }}
                          height="20px"
                          src={
                            this.state.coin.image
                              ? this.state.coin.image
                              : "https://assets.coingecko.com/coins/images/325/large/Tether-logo.png?1598003707"
                          }
                        ></img>
                      </div>
                    </div>

                    {this.state.pools === undefined ? (
                      <select
                        style={{
                          overflow: "auto",
                          paddingLeft: "40px",
                          background: "#333333",
                        }}
                        className="form-select wit_th"
                        name="select_coins"
                      >
                        <option>loading</option>
                      </select>
                    ) : (
                      <select
                        style={{
                          overflow: "auto",
                          paddingLeft: "40px",
                          background: "#333333",
                        }}
                        value={this.state.pool.name}
                        onChange={this.handleCoinChange}
                        className="form-select wit_th"
                      >
                        {this.state.pools.map((pool, i) => (
                          <option
                            data-thumbnail="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2000px-Google_%22G%22_Logo.svg.png"
                            key={i}
                            value={pool.name}
                          >
                            {pool.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 mb-3  col-sm-11 ml-3 mt-1 text-left">
                    <label className="select-coin">
                      Select the Network
                      
                    </label>
                    <div className="row">
                      <div
                        className="col-sm-3 m-3  pl-2 d-none select-net"
                        style={{ background: "#404040", borderRadius: "8px" }}
                      >
                        <h5 className="font-style-8"> BNB (BEP2)</h5>
                      </div>
                      <div
                        className="col-sm-3 m-3 pl-2 select-net text-center"
                        style={{
                          background: "rgba(77, 181, 196, 0.12)",
                          border: "1px solid #4DB5C4",
                          "box-sizing": "border-box",
                          "border-radius": " 8px",
                        }}
                      >
                        <h5
                          className="font-style-8 mt-3 mb-2"
                          style={{
                            fontFamily: "Averta-Semibold",
                            fontSize: "14px",
                            lineHeight: "24px",
                            color: "#4DB5C4",
                          }}
                        >
                          {" "}
                          Matic Mainnet(Polygon){" "}
                        </h5>
                      </div>
                      <div
                        className="col-sm-3 m-3 pl-2 select-net text-center"
                        style={{ background: "#404040", borderRadius: "8px" }}
                      >
                        <h5
                          className="font-style-8 mt-3 pl-2"
                          style={{
                            fontFamily: "Averta-Semibold",
                            fontSize: "14px",
                            lineHeight: "24px",
                            color: "#FFFFFF",
                          }}
                        >
                          ETH{" "}
                          <lable style={{ color: "#2DA3B4" }}>
                            {" "}
                            (coming soon)
                          </lable>{" "}
                        </h5>
                      </div>
                      <div
                        className="col-sm-2 m-3 pl-2 select-net d-none"
                        style={{ background: "#404040", borderRadius: "8px" }}
                      >
                        <h5
                          className="font-style-8"
                          style={{
                            fontFamily: "Averta-Semibold",
                            fontSize: "14px",
                            lineHeight: "24px",
                            color: "#FFFFFF",
                          }}
                        >
                          TRX (TRX20)
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="disp-lg-none text-center">
                  <QRCode
                    value={`${
                      this.state.walletData ? this.state.walletData.address : ""
                    }`}
                  />
                  <div className="bgc-dark1 my-3 text-left p-3">
                    <div className="text_tra1">Address</div>
                    {this.state.copyId?<span className="txt_b mx-3">Copied</span>:""}
                    <div className="row">
                      <div className="text-white word-wrap col-10">
                        
                        {this.state.walletData
                          ? this.state.walletData.address
                          : ""}
                      </div>
                      <div className="col-2">
                        {" "}
                        <img
                          style={{
                            position: "absolute",
                            margin: "5px 60px 48px auto",
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            this.copyWalletId();
                          }}
                          src={copy}
                        ></img>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row fluid disp-sm-none">
                  <div className="col-md-10   mb-3  col-sm-11 ml-3 mt-3 ">
                    <label className="select-coin">Address</label>
                    {this.state.copyId?<span className="txt_b mx-3">Copied</span>:""}
                    <span className="search d-flex justify-content-end">
                      <img
                        style={{
                          position: "absolute",
                          margin: "5px 60px 48px auto",
                        }}
                        onClick={() => {
                          this.copyWalletId();
                        }}
                        src={copy}
                      ></img>
                      {/* <img style={{position:"absolute",margin:"12px 5px 12px auto"}} src={address}></img> */}

                      <span
                        style={{
                          position: "absolute",
                          margin: "5px 5px 12px auto",
                        }}
                        className="mytooltip tooltip-effect-1"
                      >

                        <img src={address} className="tooltip-item"></img>

                        <span className="tooltip-content clearfix p-5">
                          <QRCode
                            value={`${
                              this.state.walletData
                                ? this.state.walletData.address
                                : ""
                            }`}
                          />
                        </span>{" "}
                        
                      </span>

                      <input
                        className="col-md-10 w-100  pl-2 mb-3  col-sm-11 ml-3 mt-3 text-left"
                        value={
                          this.state.walletData
                            ? this.state.walletData.address
                            : ""
                        }
                        type="text"
                        style={{
                          height: "40px",
                          background: "#262626",
                          color: "#FFFFFF",
                        }}
                        className="form-select wit_th pl-2"
                        disabled
                        placeholder="Wallet address"
                      />
                      <i className="uil-uil copy" />
                    </span>
                  </div>
                </div>

                <div className="row d-none">
                  <div className="col-md-5 mb-2   col-sm-11 ml-3 mt-2 text-left">
                    <div className="expected-text">Expected arrival</div>
                    <h4 className="h4-text">15 network confirmations</h4>
                  </div>
                  <div className="col-md-5 mb-2 col-sm-11 ml-3 mt-2 text-left">
                    <div className="expected-text">Expected unlock</div>
                    <h4 className="h4-text">15 network confirmations</h4>
                  </div>
                </div>

           
                <div className="row">
                  <div className="col-md-10 mb-1 sm-12 ml-3 mt-2">
                    <div className="expected-text ">
                      Send only{" "}
                      <span style={{ color: "#a84b52" }}>
                        {" "}
                        <lable className="text-uppercase">
                          {this.state.selectedCoin.symbol?
                          this.state.selectedCoin.symbol
                            : this.state.defaultCoin}
                        </lable>
                      </span>{" "}
                      to this deposit address.
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 mb-1  col-sm-11 ml-3  text-left">
                    <label className="expected-text">
                      Ensure network should be{" "}
                      <span style={{ color: "#a84b52" }}>
                        {" "}
                        Matic Mainnet(Polygon)
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-5 ">
              <div className="container-deposit  pl-2 pr-3">
                <div className="row  mb-2">
                  <h2 className="deposit-faq mt-3"> FAQ</h2>
                </div>

                {this.state.faq.map((data, i) => (
                  <div className="row w-100 ml-1 mt-2">
                    <div className="container-faq-box  pr-2 pl-2">
                      <lable
                        className="font-style-15"
                        href={`#${i}`}
                        data-toggle="collapse"
                      >
                        {data.title}
                      </lable>
                      <div
                        id={`${i}`}
                        className="font-style-2 mt-3 mb-3 collapse"
                      >
                         {data.answer.map((data)=>(
                        <p >{data.step}</p>
                      ))}
                      </div>
                    </div>
                  </div>
                ))}

                <div className="pl-5 d-none" style={{ color: "#4DB5C4" }}>
                  View All
                </div>
              </div>
            </div>
          </div>

          <Container fluid>
            <Row>
              <h2 className="deposit-crypto d-none"> Recent Deposit</h2>
              <Col sm="12" md="12">
                <Card className="card-plain table-plain-bg">
                  <Card.Body className="table-full-width table-responsive px-0 d-none">
                    <table className="border-0">
                      <thead className="disp_non_sm">
                        <tr className="text_tra1 ">
                          <th>Date and Time</th>
                          <th>Actions</th>
                          <th> Description</th>
                          <th>Transaction ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="sec1 mb-2 ">
                          <td>May 24 11.30 AM EST</td>
                          <td>
                            <img img className="mx-1" src={Deposit}></img>{" "}
                            Deposit
                          </td>
                          <td>
                            <img className="mx-1" src={BUSD}></img>
                            <h7 className="text_tra1 mx-1">BUSD 4,365</h7>
                            <h7> </h7>
                          </td>
                          <td className="trans_id text-wrap disp_non_sm">
                            <h7 style={{ color: "#4DB5C4" }}>
                            </h7>
                          </td>
                        </tr>
                        <tr className="sec1 mb-2 ">
                          <td>May 24 11.30 AM EST</td>
                          <td>
                            <img img className="mx-1" src={Deposit}></img>{" "}
                            Deposit
                          </td>
                          <td>
                            <img className="mx-1" src={BUSD}></img>
                            <h7 className="text_tra1 mx-1">BUSD 4,365</h7>
                            <h7> </h7>
                          </td>
                          <td className="trans_id text-wrap disp_non_sm">
                            <h7 style={{ color: "#4DB5C4" }}>
                            </h7>
                          </td>
                        </tr>
                        <tr className="sec1 mb-2 ">
                          <td>May 24 11.30 AM EST</td>
                          <td>
                            <img img className="mx-1" src={Deposit}></img>{" "}
                            Deposit
                          </td>
                          <td>
                            <img className="mx-1" src={BUSD}></img>
                            <h7 className="text_tra1 mx-1">BUSD 4,365</h7>
                            <h7> </h7>
                          </td>
                          <td className="trans_id text-wrap disp_non_sm">
                            <h7 style={{ color: "#4DB5C4" }}>
                            </h7>
                          </td>
                        </tr>
                        <tr className="sec1 mb-2 ">
                          <td>May 24 11.30 AM EST</td>
                          <td>
                            <img img className="mx-1" src={Deposit}></img>{" "}
                            Deposit
                          </td>
                          <td>
                            <img className="mx-1" src={BUSD}></img>
                            <h7 className="text_tra1 mx-1">BUSD 4,365</h7>
                            <h7> </h7>
                          </td>
                          <td className="trans_id text-wrap disp_non_sm">
                            <h7 style={{ color: "#4DB5C4" }}>
                            </h7>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  pool: state.pool,
  coin: state.coin,
});
const mapDispatchToProps = (dispatch) => ({
  postPool: (data) => dispatch(postPool(data)),
  getCoins: (data) => dispatch(getCoins(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DepositCrypto));

import React from "react";
import Modal from "react-bootstrap/Modal";
import Invoicepopup from "./invoicepopup";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import { getCompany } from "../../../services/actions/companyActions";

class ChooseBuyerpopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: [],
      modalShow: false,
    };
  }

  componentDidMount() {
    this.props.getCompany();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_isEmpty(nextProps.company.company)) {
      this.setState({ company: nextProps.company.company });
    }
  }

  render() {
    return (
      <div>
        <div style={{ fontFamily: "Averta-Semibold,sans-serif" }}>
          <Invoicepopup
            show={this.state.modalShow}
            onHide={() => this.setState({ modalShow: false })}
          />
          <Modal
            {...this.props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ backgroundColor: "black" }}
          >
            <div
              style={{
                backgroundColor: "#262626",
                color: "white",
                border: "0",
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title
                  id="contained-modal-title-vcenter"
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  Choose buyer
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: "center" }}>
                <p style={{ color: "#999999" }}>
                  The invoice you are going to upload belong to any existing
                  buyer or it's a new buyer?{" "}
                </p>
                <div style={{ marginTop: "12px" }} className="row">
                  <div className="col-sm-6">
                    <div
                      style={{
                        padding: "15px",
                        color: "white",
                        border: "1px solid #626262",
                        borderRadius: "4px",
                      }}
                    >
                      <p style={{ paddingBottom: "15px", fontSize: "16px" }}>
                        Choose from your Buyer
                      </p>
                      <span style={{ width: "44px", color: "#999999" }}>
                        <div className="dropdown">
                          <button
                            style={{
                              background: "#262626",
                              border: "1px solid gray !important",
                              color: "gray",
                            }}
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Choose Buyer
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {this.state.company.map((company, key) => (
                              <a key={key} className="dropdown-item" href="#">
                                {company.company_name}
                              </a>
                            ))}
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <Link style={{ textDecoration: "none" }} to="/newbuyer">
                      {" "}
                      <div
                        style={{
                          padding: "12px",
                          height: "107px",
                          color: "white",
                          border: "1px solid #626262",
                          borderRadius: "4px",
                        }}
                      >
                        <p style={{ paddingTop: "25px" }}> + New Buyer </p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div style={{ margin: "20px", alignItems: "center" }}>
                  <Button
                    onClick={() => this.setState({ modalShow: true })}
                    style={{ backgroundColor: "#2DA3B4" }}
                  >
                    Continue to Upload Invoice
                  </Button>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  company: state.company,
});

const mapDispatchToProps = (dispatch) => ({
  getCompany: (data) => dispatch(getCompany(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChooseBuyerpopup);

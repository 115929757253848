import * as INVOICE_CONSTANTS from "../constants/actionConstant";

export const invoiceReducer = (
  state = {
    type: "",
    error: "",
    isFetching: false,
    invoicePost: undefined,
    allInvoices: undefined,
    invoiceUpdate: undefined,
    invoiceDelete: undefined,
    invoiceByUser: undefined,
    invoiceDetails: undefined,
    termsAndConditionsUpdate: undefined,
    selctedInvoiceData: {},
    invoiceData: {},
  },
  action
) => {
  switch (action.type) {
    case INVOICE_CONSTANTS.GET_DASHBOARD_CONSTANTS.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        invoiceChecked: undefined,
        lastUpdated: action.receivedAt,
      });

    case INVOICE_CONSTANTS.GET_DASHBOARD_CONSTANTS.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        invoiceChecked: action.data,
        lastUpdated: action.receivedAt,
      });

    case INVOICE_CONSTANTS.GET_DASHBOARD_CONSTANTS.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        error: action.error,
      });

    case INVOICE_CONSTANTS.HOME_POST_INVOICE_CONSTANTS.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        invoicePost: undefined,
        lastUpdated: action.receivedAt,
      });

    case INVOICE_CONSTANTS.HOME_POST_INVOICE_CONSTANTS.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        invoicePost: action.data,
        lastUpdated: action.receivedAt,
      });

    case INVOICE_CONSTANTS.HOME_POST_INVOICE_CONSTANTS.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        error: action.error,
      });
    case INVOICE_CONSTANTS.GET_ALL_INVOICE_CONSTANTS.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        allInvoices: undefined,
        lastUpdated: action.receivedAt,
      });

    case INVOICE_CONSTANTS.GET_ALL_INVOICE_CONSTANTS.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        allInvoices: action.data,
        lastUpdated: action.receivedAt,
      });

    case INVOICE_CONSTANTS.GET_ALL_INVOICE_CONSTANTS.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        error: action.error,
      });

    case INVOICE_CONSTANTS.HOME_UPDATE_INVOICE_CONSTANTS.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        invoiceUpdate: undefined,
        lastUpdated: action.receivedAt,
      });

    case INVOICE_CONSTANTS.HOME_UPDATE_INVOICE_CONSTANTS.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        invoiceUpdate: action.data,
        lastUpdated: action.receivedAt,
      });

    case INVOICE_CONSTANTS.HOME_UPDATE_INVOICE_CONSTANTS.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        error: action.error,
      });

    case INVOICE_CONSTANTS.HOME_DELETE_INVOICE_CONSTANTS.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        invoiceDelete: undefined,
        lastUpdated: action.receivedAt,
      });

    case INVOICE_CONSTANTS.HOME_DELETE_INVOICE_CONSTANTS.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        invoiceDelete: action.data,
        lastUpdated: action.receivedAt,
      });

    case INVOICE_CONSTANTS.HOME_DELETE_INVOICE_CONSTANTS.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        error: action.error,
      });
    case INVOICE_CONSTANTS.GET_CREATED_BY_USER_INVOICE_CONSTANTS.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        invoiceByUser: undefined,
        lastUpdated: action.receivedAt,
      });

    case INVOICE_CONSTANTS.GET_CREATED_BY_USER_INVOICE_CONSTANTS.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        invoiceByUser: action.data,
        lastUpdated: action.receivedAt,
      });

    case INVOICE_CONSTANTS.GET_CREATED_BY_USER_INVOICE_CONSTANTS.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        error: action.error,
      });
    case INVOICE_CONSTANTS.GET_INVOICE_DETAILS_BY_ID_CONSTANTS.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        invoiceDetails: undefined,
        lastUpdated: action.receivedAt,
      });

    case INVOICE_CONSTANTS.GET_INVOICE_DETAILS_BY_ID_CONSTANTS.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        invoiceDetails: action.data,
        lastUpdated: action.receivedAt,
      });

    case INVOICE_CONSTANTS.GET_INVOICE_DETAILS_BY_ID_CONSTANTS.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        error: action.error,
      });
    case INVOICE_CONSTANTS.INVOICE_ID_LOCATION:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        selctedInvoiceData: action.data,
      });
    case INVOICE_CONSTANTS.INVOICE_ID_DATA:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        invoiceData: action.data,
      });

    default:
      return state;
  }
};

export default invoiceReducer;

import {React, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Close from '../../../assets/images/close_icon.svg'
import '../../../assets/css/common/fontStyle.css'
import '../../../assets/css/common/button.css'
import PinInput from "react-pin-input";
export default function SecretPinPopup(props) {
  const [pin, setpin] = useState("");
  const [secret, setSecret] = useState(true);
 let closePopUp = () => {
   setpin("");
   props.hideReward();
 };
 return (
   <div>
     <div style={{ fontFamily: "Averta-Semibold,sans-serif" }}>
       <Modal
         {...props}
         aria-labelledby="contained-modal-title-vcenter"
         centered
         style={{ backgroundColor: "transparent" }}
       >
         <div className="alert-popup">
           <div className="mt-3 disp-lg-none">
             <div className="line mx-auto"></div>
           </div>

           <Modal.Body className="text-center">
             {!props.transaction ? (
               <div>
                 <div className="d-flex justify-content-end mb-2 ">
                   <img
                     src={Close}
                     className="   user-icon curserP"
                     onClick={closePopUp}
                     alt=""
                   />
                 </div>
                 <div className="kyc-pending mt-4 w-75 ">
                   <h5 className="text-white text-left ml-5">
                     Enter your secret PIN
                   </h5>
                 </div>
                 <div className="d-flex w-100 justify-content-center my-3">
                   <PinInput
                     length={4}
                     initialValue={pin}
                     secret={secret}
                     type="numeric"
                     inputMode="number"
                     style={{ padding: "10px" }}
                     inputStyle={{
                       color: "#ffffff",
                       background: "rgb(51 51 51)",
                       border: "none",
                       borderBottom: "3px solid #595959",
                     }}
                     inputFocusStyle={{ borderColor: "rgb(68 172 197)" }}
                     onChange={(value, index) => {
                       setpin(value);
                     }}
                     autoSelect={true}
                   />
                   <div className="mt-4 curserP"  onClick={() => { setSecret(!secret) }}>
                     <i
                       className={
                         secret
                           ? "ml-3 mr-2 uil-eye text_tra1 curserP "
                           : "text_tra1 ml-3 mr-2 uil-eye-slash curserP "
                       }
                      
                     />
                     <span className="txt_b">{secret ? "Show" : "Hide"}</span>
                   </div>
                 </div>
                 <div>
                   <button
                     className="btn-blue my-3 w-75"
                     onClick={() => props.initiateWithDraw(pin)} //setTransaction(true)}
                   >
                     Withdraw
                   </button>
                 </div>
                 <div>
                   <p className="txt_b mb-4 curserP" onClick={props.forgotPinPopUp}>
                     Forgot PIN?
                   </p>
                 </div>
               </div>
             ) : (
               <div>
                 <div className="spinner-border text-info" role="status"></div>{" "}
                 <h4 className="text-white">Transaction inprogress</h4>
               </div>
             )}
           </Modal.Body>
         </div>
       </Modal>
     </div>
   </div>
 );
}

import React from "react";
import {
  getUserBankAccountsById,
  postUserAccountData,
  updateUserAccountdata,
} from "../../../api/bankDetailsService";
import {
  USER_TYPE,
  STATUSES,
  ADMIN_ALERT_TYPES,
} from "../../../config/string_constants";
import { ToastContainer, toast } from "react-toastify";
export default class BankDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountDetails: [],
      account: {
        user_name: "",
        bank_name: "",
        account_number: "",
        ifsc_code: "",
        beneficiary_name: "",
        beneficiary_address: "",
        currency: "",
        swift_code: "",
        createdById: sessionStorage.getItem("userId"),
      },
      WalletUpdateActivity: {
        activity_name: "Updated An Account",
        service_name: "updateAccount",
        createdBy: {
          name: sessionStorage.getItem("uName"),
          email: sessionStorage.getItem("uEmail"),
          id: sessionStorage.getItem("userId"),
        },
      },
      walletActivity: {
        activity_name: "Added An Account",
        service_name: "addAccount",
        createdBy: {
          name: sessionStorage.getItem("uName"),
          email: sessionStorage.getItem("uEmail"),
          id: sessionStorage.getItem("userId"),
        },
      },
      dirty: {
        user_name: false,
        bank_name: false,
        account_number: false,
        ifsc_code: false,
        beneficiary_name: false,
        beneficiary_address: false,
        currency: false,
        swift_code: false,
      },

      errors: {
        user_name: [],
        bank_name: [],
        account_number: [],
        ifsc_code: [],
        beneficiary_name: [],
        beneficiary_address: [],
        currency: [],
        swift_code: [],
      },
    };
  }
  accountValidate = () => {
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      errors[control] = [];
      var validAccount = /^[0-9]*$/;
      var validIfsc = /^[A-Za-z]{4}\d{7}$/;
      var validName = /^[a-zA-Z ]*$/;
      var validSwiftCode = /[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?/i;
      switch (control) {
        case "user_name":
          if (!this.state.account[control]) {
            errors[control].push("Name can't be blank");
          }
          if (this.state.account[control]) {
            if (!validName.test(this.state.account[control])) {
              errors[control].push("Enter valid Name");
            }
          }
          break;
        case "bank_name":
          if (!this.state.account[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "swift_code":
          if (!this.state.account[control]) {
            errors[control].push("This field is required");
          }
          if (this.state.account[control]) {
            if (!validSwiftCode.test(this.state.account[control])) {
              errors[control].push("Enter valid Swift Code");
            }
          }
          break;
        case "currency":
          if (!this.state.account[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "beneficiary_address":
          if (!this.state.account[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "beneficiary_name":
          if (!this.state.account[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "account_number":
          if (!this.state.account[control]) {
            errors[control].push("This field is required ");
          } else if (this.state.account[control].length < 11) {
            errors[control].push("Account number must have 11 digits ");
          } else if (this.state.account[control]) {
            if (!validAccount.test(this.state.account[control])) {
              errors[control].push("Enter valid Account Number");
            }
          }

          break;
        case "ifsc_code":
          if (!this.state.account[control]) {
            errors[control].push("This field is required");
          }
          if (this.state.account[control]) {
            if (!validIfsc.test(this.state.account[control])) {
              errors[control].push("Not a valid IFSC Code");
            }
          }
          break;

        default:
          break;
      }
    });
    this.setState({ errors: errors });
  };

  isAccountValid = () => {
    let valid = true;

    for (let control in this.state.errors) {
      if (this.state.errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };

  blurChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    let accountDirty = this.state.dirty;
    accountDirty[name] = true;
    let account = this.state.account;
    account[name] = value;

    this.setState(
      { account: account, dirty: accountDirty },
      this.validate,
      this.accountValidate()
    );
  };
  getBankAccountsData = async () => {
    let bkData = await getUserBankAccountsById(
      sessionStorage.getItem("userId")
    );
    this.setState({ accountDetails: bkData.data });
  };
  componentDidMount() {
    this.getBankAccountsData();
  }
  saveAccount = async (e) => {
    e.preventDefault();
    this.accountValidate();
    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });

    if (this.isAccountValid()) {
      let account = { ...this.state.account };
      account.update_type = ADMIN_ALERT_TYPES.BANK_ACCOUNT;
      if (this.state.editData === true) {
        try {
          let res = await updateUserAccountdata(account);
          if (res.status === 201 || res.status === 200) {
            toast.info("Account Updated Successfully");
          }
        } catch (err) {}
        this.props.postActivity(this.state.WalletUpdateActivity);
        this.setState({
          editData: false,
          show: false,
        });
        this.handleCloseAccount();
        this.getBankAccountsData();
      } else {
        this.setState({
          addSucess: true,
          show: false,
        });
        try {
          let res = await postUserAccountData(account);

          if (res.status === 201 || res.status === 200) {
            toast.info("Account Added Successfully");
          }
        } catch (err) {}

        this.props.postActivity(this.state.walletActivity);
        this.setState({
          addSucess: false,
        });
        this.handleCloseAccount();
        this.getBankAccountsData();
      }
    }
  };
  handleCloseAccount = () => {
    this.setState({
      show: false,
      editData: false,
    });

    const { account } = this.state;
    account._id = "";
    account.account_number = "";
    account.bank_name = "";
    account.beneficiary_name = "";
    account.beneficiary_address = "";
    account.swift_code = "";
    account.currency = "";
    account.ifsc_code = "";
    account.user_name = "";
    account.dataStatus = "";
    account.account_type = "";
    account.createdById = sessionStorage.getItem("userId");
    this.setState({
      account,
    });
  };

  editAccount = (data) => {
    const { account, account1 } = this.state;
    account._id = data._id;
    account.account_number = data.account_number;
    account.bank_name = data.bank_name;
    account.ifsc_code = data.ifsc_code;
    account.beneficiary_name = data.beneficiary_name;
    account.beneficiary_address = data.beneficiary_address;
    account.swift_code = data.swift_code;
    account.currency = data.currency;
    account.user_name = data.user_name;
    account.createdById = data.createdById;
    account.dataStatus = data.dataStatus;
    account.account_type = data.account_type;
    this.setState({
      account,
      account1,
      show: true,
      editData: true,
    });
  };
  add_account = () => {
    this.setState({
      show: true,
    });
  };
  render() {
    return (
      <>
        {this.state.accountDetails === null ? (
          <h3>No accounts Added yet</h3>
        ) : (
          this.state.accountDetails.map((account, idx) => (
            <div key={idx} className="sec2">
              <div>
                <h5 className="text-white">
                  {account.bank_name}{" "}
                  <span style={{ fontSize: "small" }} className="txt_b">
                    {account.account_type}
                    <i
                      style={{
                        float: "right",
                        fontSize: "large",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.editAccount(account);
                      }}
                      className="txt_b uil-edit"
                    ></i>
                  </span>
                </h5>
              </div>
              <div className="row">
                <div className="col-md-3 ">
                  <p className="text_tra1">Account holder</p>
                  <h5 className="text-white">{account.user_name}</h5>
                </div>
                <div className="col-md-3 ">
                  <p className="text_tra1">Account Number</p>
                  <h5 className="text-white">{account.account_number}</h5>
                </div>
                <div className="col-md-3">
                  <p className="text_tra1">IFSC Code</p>
                  <h5 className="text-white">{account.ifsc_code}</h5>
                </div>
                <div className="col-md-3">
                  <p className="text_tra1">Bank Name</p>
                  <h5 className="text-white">{account.bank_name}</h5>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <p className="text_tra1">Benificiary Name</p>
                  <h5 className="text-white">{account.beneficiary_name}</h5>
                </div>
                <div className="col-md-3">
                  <p className="text_tra1">Benificiary Address</p>
                  <h5 className="text-white">{account.beneficiary_address}</h5>
                </div>
                <div className="col-md-3">
                  <p className="text_tra1">Swift Code</p>
                  <h5 className="text-white">{account.swift_code}</h5>
                </div>
                <div className="col-md-3">
                  <p className="text_tra1">Currency</p>
                  <h5 className="text-white">{account.currency}</h5>
                </div>
              </div>
            </div>
          ))
        )}
        <div className="text-center">
          <button onClick={this.add_account} className="btn-blue">
            + Add Account
          </button>
        </div>

        <div
          className={`menuNav  ${this.state.show ? "showMenu1" : ""}`}
          style={{ overflowY: "auto" }}
        >
          <div
            style={{
              backgroundColor: "#333333",
              color: "white",
              border: "0",
            }}
          >
            <div
              className="d-flex justify-content-between p-3"
              id="contained-modal-title-vcenter"
              style={{ fontSize: "16px", lineHeight: "24px" }}
            >
              <div>
                {" "}
                {this.state.editData
                  ? "Edit Account Details"
                  : "Add Account Details"}{" "}
              </div>
              <h5
                style={{ cursor: "pointer" }}
                onClick={this.handleCloseAccount}
              >
                <i className="uil-multiply"></i>
              </h5>
            </div>
            <div style={{ textAlign: "left" }}>
              <div style={{ margin: "20px" }}>
                <p className="text_tra1">
                  Please enter your valid bank account details.
                </p>
                <div className="row" style={{ margin: "20px 0px" }}>
                  <div className="col-sm-12 my-2">
                    <label
                      className="label"
                      htmlFor=""
                      placeholder="Enter name"
                    >
                      Name
                    </label>
                    <div>
                      {" "}
                      <input
                        type="text"
                        className="input"
                        placeholder="Name"
                        name="user_name"
                        value={this.state.account.user_name}
                        onChange={this.handleChange}
                        onBlur={this.blurChange}
                        maxLength="20"
                      />
                    </div>
                    <div className="text-danger">
                      {this.state.errors.user_name[0] &&
                      this.state.dirty.user_name
                        ? this.state.errors.user_name
                        : ""}
                    </div>
                  </div>
                  <div className="col-sm-12 my-2">
                    <label
                      className="label"
                      htmlFor=""
                      placeholder="Enter name"
                    >
                      Beneficiary Name
                    </label>
                    <div>
                      {" "}
                      <input
                        type="text"
                        className="input"
                        placeholder="beneficiary Name"
                        name="beneficiary_name"
                        value={this.state.account.beneficiary_name}
                        onChange={this.handleChange}
                        onBlur={this.blurChange}
                        maxLength="20"
                      />
                    </div>
                    <div className="text-danger">
                      {this.state.errors.beneficiary_name[0] &&
                      this.state.dirty.beneficiary_name
                        ? this.state.errors.beneficiary_name
                        : ""}
                    </div>
                  </div>
                  <div className="col-sm-12 my-2">
                    <div>
                      <label className="label" htmlFor="">
                        Currency
                      </label>
                      {this.props.countryList === undefined ? (
                        <select
                          className={
                            this.state.errors.currency
                              ? "form-control12 input-error"
                              : "form-control12"
                          }
                        >
                          <option
                            style={{
                              background: "#333333",
                              color: "grey",
                            }}
                          >
                            Loading
                          </option>
                        </select>
                      ) : (
                        <select
                          style={{ background: "#333333" }}
                          className="form-select wit_th"
                          id="currency"
                          name="currency"
                          value={this.state.account.currency}
                          onChange={this.handleChange}
                        >
                          <option>Select Currency</option>
                          {this.props.countryList
                            .filter((data) => {
                              return data.currency === "USD";
                            })
                            .map((item) => (
                              <option
                                style={{
                                  background: "#212022",
                                  color: "grey",
                                }}
                                key={item._id}
                                value={item.currency}
                              >
                                {item.currency}
                              </option>
                            ))}
                        </select>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-12 my-2">
                    <label className="label" htmlFor="">
                      Beneficiary Address
                    </label>
                    <div>
                      {" "}
                      <input
                        type="text"
                        className="input"
                        placeholder="beneficiary Address"
                        name="beneficiary_address"
                        value={this.state.account.beneficiary_address}
                        onChange={this.handleChange}
                        onBlur={this.blurChange}
                        maxLength="20"
                      />
                    </div>
                    <div className="text-danger">
                      {this.state.errors.beneficiary_address[0] &&
                      this.state.dirty.beneficiary_address
                        ? this.state.errors.beneficiary_address
                        : ""}
                    </div>
                  </div>

                  <div className="col-sm-12 my-2">
                    <label
                      className="label"
                      htmlFor=""
                      placeholder="Enter name"
                    >
                      Swift Code
                    </label>
                    <div>
                      {" "}
                      <input
                        type="text"
                        className="input"
                        placeholder="swift code"
                        name="swift_code"
                        value={this.state.account.swift_code}
                        onChange={this.handleChange}
                        onBlur={this.blurChange}
                        maxLength="20"
                      />
                    </div>
                    <div className="text-danger">
                      {this.state.errors.swift_code[0] &&
                      this.state.dirty.swift_code
                        ? this.state.errors.swift_code
                        : ""}
                    </div>
                  </div>

                  <div className="col-sm-12 my-2">
                    <label className="label" htmlFor="">
                      Bank Account Number
                    </label>
                    <div className="d-flex">
                      <input
                        className="input"
                        type="text"
                        placeholder="Enter Account Number"
                        name="account_number"
                        value={this.state.account.account_number}
                        onChange={this.handleChange}
                        onBlur={this.blurChange}
                        minLength="11"
                        maxLength="16"
                      />
                    </div>
                    <div className="text-danger">
                      {this.state.errors.account_number[0] &&
                      this.state.dirty.account_number
                        ? this.state.errors.account_number
                        : ""}
                    </div>
                  </div>
                  <div className="col-sm-12 my-2">
                    <label className="label" htmlFor="">
                      IFSC Code
                    </label>
                    <div>
                      {" "}
                      <input
                        type="text"
                        className="input"
                        placeholder="Enter IFSC code"
                        name="ifsc_code"
                        value={this.state.account.ifsc_code}
                        onChange={this.handleChange}
                        onBlur={this.blurChange}
                      />
                    </div>
                    <div className="text-danger">
                      {this.state.errors.ifsc_code[0] &&
                      this.state.dirty.ifsc_code
                        ? this.state.errors.ifsc_code
                        : ""}
                    </div>
                  </div>
                  <div className="col-sm-12 my-2">
                    <label className="label" htmlFor="">
                      Bank Name
                    </label>
                    <div>
                      {" "}
                      <input
                        type="text"
                        className="input"
                        placeholder="Bank Name"
                        name="bank_name"
                        value={this.state.account.bank_name}
                        onChange={this.handleChange}
                        onBlur={this.blurChange}
                        maxLength="25"
                      />
                    </div>
                    <div className="text-danger">
                      {this.state.errors.bank_name[0] &&
                      this.state.dirty.bank_name
                        ? this.state.errors.bank_name
                        : ""}
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ textAlign: "center" }}>
                <button
                  style={{
                    backgroundColor: "#4DB5C4",
                    border: "none",
                    color: "white",
                  }}
                  onClick={this.saveAccount}
                  className="cont-btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

import React, { Component } from "react";
import comingSoon from "./../assets/images/ComingSoon.svg";

export default class mobResponsive extends Component {
  render() {
    return (
      <div className="mt-5 text-center">
        <img src={comingSoon} alt="mob-responsive not supporting"/>
        <div style={{ textAlign: "center" }}>
          <h3 className="text-white d-flex justify-content-center w-100 mt-5">
            Coming Soon...
          </h3>
          <p className="text_tra1 d-flex justify-content-center">
            Our mobile version will be launched soon.
          </p>
          <p className="text_tra1"> Please login via web </p>
          <p className="text_tra1">
            {" "}
            For any further inquiries please contact support@polytrade.fiance
          </p>
        </div>
      </div>
    );
  }
}

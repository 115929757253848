import axios from "axios";
import * as INVOICE_FINAL_OFFER_CONSTANTS from "../constants/invoiceFinalOfferConstant";
import ajax from "../../utils/ajax";

export const requestGetFinalOffer = () => ({
  type: INVOICE_FINAL_OFFER_CONSTANTS.HOME_GET_INVOICE_FINAL_OFFER_CONSTANTS
    .REQUEST,
});

export const recievedGetFinalOfferResponse = (data) => ({
  type: INVOICE_FINAL_OFFER_CONSTANTS.HOME_GET_INVOICE_FINAL_OFFER_CONSTANTS
    .RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetFinalOfferError = (err) => ({
  type: INVOICE_FINAL_OFFER_CONSTANTS.HOME_GET_INVOICE_FINAL_OFFER_CONSTANTS
    .RECEIVED_ERROR,
  errorCode: err,
});

export const getFinalOfferById = (id) => async (dispatch) => {
  dispatch(requestGetFinalOffer());
  try {
    let url =
      INVOICE_FINAL_OFFER_CONSTANTS.HOME_GET_INVOICE_FINAL_OFFER_CONSTANTS.URL +
      id;

    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetFinalOfferResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetFinalOfferError(err));
    return Promise.reject(err);
  }
};

export const requestPostFinalOffer = () => ({
  type: INVOICE_FINAL_OFFER_CONSTANTS.HOME_POST_INVOICE_FINAL_OFFER_CONSTANTS
    .REQUEST,
});

export const recievedPostFinalOfferResponse = (data) => ({
  type: INVOICE_FINAL_OFFER_CONSTANTS.HOME_POST_INVOICE_FINAL_OFFER_CONSTANTS
    .RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedPostFinalOfferError = (err) => ({
  type: INVOICE_FINAL_OFFER_CONSTANTS.HOME_POST_INVOICE_FINAL_OFFER_CONSTANTS
    .RECEIVED_ERROR,
  errorCode: err,
});
export const postFinalOffer = (data) => async (dispatch) => {
  dispatch(requestPostFinalOffer());
  try {
    let url =
      INVOICE_FINAL_OFFER_CONSTANTS.HOME_POST_INVOICE_FINAL_OFFER_CONSTANTS.URL;
    const res = await ajax("POST", url, {}, {}, data);

    dispatch(recievedPostFinalOfferResponse(res.data));

    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(recievedPostFinalOfferError(err));
    return Promise.reject(err);
  }
};

import axios from "axios";
import * as KYC_STATUS_CONSTANTS from "../constants/kycStatusConstant";
import ajax from "../../utils/ajax";

export const requestGetKycStatus = () => ({
  type: KYC_STATUS_CONSTANTS.HOME_GET_KYC_STATUS_CONSTANTS.REQUEST,
});

export const recievedGetKycStatusResponse = (data) => ({
  type: KYC_STATUS_CONSTANTS.HOME_GET_KYC_STATUS_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetKycStatusError = (err) => ({
  type: KYC_STATUS_CONSTANTS.HOME_GET_KYC_STATUS_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getKycStatus = () => async (dispatch) => {
  dispatch(requestGetKycStatus());
  try {
    let url = KYC_STATUS_CONSTANTS.HOME_GET_KYC_STATUS_CONSTANTS.URL;
    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetKycStatusResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetKycStatusError(err));
    return Promise.reject(err);
  }
  //    let url= INVOICE_STATUS_CONSTANTS.HOME_GET_INVOICE_STATUS_CONSTANTS.URL;

  //  return dispatch => {
  //    dispatch(requestGetInvoiceStatus());

  //    axios.get(url,
  //    {
  //      headers:
  //      {
  //          'Content-Type': 'application/json'

  //  } }
  //    )
  //      .then(res =>
  //       dispatch(recievedGetInvoiceStatusResponse(res.data))

  //        )
  //      .catch(err => dispatch(recievedGetInvoiceStatusError(err)))
  //  }
};

import * as BUYER_CONSTANTS from "../constants/buyerConstant";
export const buyerReducer = (state = {
    type: '',
    error: '',
    isFetching: false,
   postBuyer:undefined,
   buyerData:undefined,
}, action) => {
   
    switch (action.type) {
                case BUYER_CONSTANTS.HOME_GET_BUYER_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    buyerData:undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case BUYER_CONSTANTS.HOME_GET_BUYER_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    buyerData: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case BUYER_CONSTANTS.HOME_GET_BUYER_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });

                case BUYER_CONSTANTS.HOME_POST_BUYER_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    postBuyer:undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case BUYER_CONSTANTS.HOME_POST_BUYER_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    postBuyer:action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case BUYER_CONSTANTS.HOME_POST_BUYER_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });


        default:
            return state;
    }
};


export default buyerReducer;

import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Close from '../../../assets/images/close_icon.svg'
import '../../../assets/css/common/fontStyle.css'
import '../../../assets/css/common/button.css'

export default function CreatePinPopup(props) {

  return (
    <div>
      <div style={{ fontFamily: "Averta-Semibold,sans-serif" }}>
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ backgroundColor: "transparent" }}
        >
          <div className="alert-popup">
            <div className="mt-3 disp-lg-none">
              <div className="line mx-auto"></div>
            </div>

            <Modal.Body className="text-center">
              <div className="float-right mb-2 ">
                <img
                  src={Close}
                  className="user-icon  curserP"
                  onClick={props.hideReward}
                  alt=""
                />
              </div>
              <div className="kyc-pending mt-5">
                <h3 className="text-white">Create Secret PIN</h3>
                <p className="text_tra1 my-2 mx-auto w-75">
                  A link for pin generation has been sent to your registered
                  email
                </p>
              </div>
              <div>
                <button
                  className="btn-blue w-75 my-3"
                  onClick={props.sendMailLink}
                >
                  Send Link
                </button>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </div>
  );
}

import axios from 'axios';
import * as EXPIRATION_CONSTANTS from "../constants/offerExpirationConstant";


export const requestgetExpiration = () => (
    {
     type: EXPIRATION_CONSTANTS.HOME_GET_OFFER_EXPIRATION_CONSTANTS.REQUEST,
   });
  
  export const recievedgetExpirationResponse = data => (
     {
     type: EXPIRATION_CONSTANTS.HOME_GET_OFFER_EXPIRATION_CONSTANTS.RECEIVED, 
      data,
      receivedAt: Date.now(),
   })  
   export const recievedgetExpirationError = (err) => ({
     type: EXPIRATION_CONSTANTS.HOME_GET_OFFER_EXPIRATION_CONSTANTS.RECEIVED_ERROR,
     errorCode: err,
   })
  
  
  export const getExpiration = (data) => async (dispatch) => {
    dispatch(requestgetExpiration());
    try {
        let url= EXPIRATION_CONSTANTS.HOME_GET_OFFER_EXPIRATION_CONSTANTS.URL;
      const res = await  axios.get(url, data,{  
          headers: 
          {
              'Content-Type': 'application/json'
      
      } } )
  
      dispatch(recievedgetExpirationResponse(res.data));
  
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(recievedgetExpirationError(err))
      return Promise.reject(err);
    }
  };



import React, { Component } from "react";
import Check from "../../../assets/images/check1.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { INVOICE_STATUSES_KEY } from "../../../config/string_constants";

class Progressbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      InvoiceId: this.props.invoiceId,
      screen: "",
    };
  }

  componentDidUpdate() {
    if (document.getElementById(this.props.screen)) {
      document.getElementById(this.props.screen).scrollIntoView({
        behavior: "smooth", // 'auto' or 'smooth'
        block: "center", // 'start', 'center', 'end' or 'nearest'
        inline: "center", // 'start', 'center', 'end' or 'nearest'
      });
      document.getElementById(`${this.props.screen}`).style.backgroundColor =
        "#060819";
      document.getElementById(`${this.props.screen}`).style.color = "#4db5c4";
      // document.getElementById(`${this.props.screen}innerContent`).style.color =
      //   "#4db5c4";
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.invoice.selctedInvoiceData != null) {
      if (nextProps.invoice.selctedInvoiceData.invoiceStatus_id != null) {
        this.setState({
          screen: nextProps.invoice.selctedInvoiceData.invoiceStatus_id.key,
        });
      }
    }
  }

  navigate = (data) => {
    switch (data) {
      case "invoiceDetails":
        this.props.history.push(`/invoice-details/${this.props.invoiceId}`);
        break;

      case "OfferMade":
        this.props.history.push(`/offer-made/${this.props.invoiceId}`);
        break;

      case "companyDocument":
        this.props.history.push(`/company-document/${this.props.invoiceId}`);
        break;

      case "kycProcess":
        this.props.history.push(`/kyc-process/${this.props.invoiceId}`);
        break;

      case "invoiceDocument":
        this.props.history.push(`/invoice-document/${this.props.invoiceId}`);
        break;

      case "poolGovernance":
        this.props.history.push(`/pool-governance/${this.props.invoiceId}`);
        break;

      case "finalOffer":
        this.props.history.push(`/final-offer/${this.props.invoiceId}`);
        break;

      case "arpa":
        this.props.history.push(`/arpa/${this.props.invoiceId}`);
        break;

      case "invoiceVerificationByBuyer":
        this.props.history.push(
          `/invoice-verification-by-buyer/${this.props.invoiceId}`
        );
        break;

      case "advancedFundAllocation":
        this.props.history.push(
          `/advance-fund-allocation/${this.props.invoiceId}`
        );
        break;

      case "collection":
        this.props.history.push(`/collection/${this.props.invoiceId}`);
        break;

      case "reserveFundAllocation":
        this.props.history.push(
          `/reserve-fund-allocation/${this.props.invoiceId}`
        );
        break;

      case "HistoryBook":
        this.props.history.push(`/history-book/${this.props.invoiceId}`);
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div>
        {this.props.invoice.selctedInvoiceData != null ? (
          <div style={{ width: "211rem" }} className="info-two">
            <div className="mb-2">
              <ul className="nav nav-pills  pt-2" id="myTab" role="tablist">
                <li className="nav-item   move2">
                  <span
                    className="nav-link text_act    StatusItem active"
                    id="home-tab"
                  >
                    <img src={Check} className="check user-icon" />
                    <div
                      className="mt-1"
                      style={{ width: "7rem", marginLeft: "-50px" }}
                    >
                      Registration
                    </div>
                  </span>
                </li>

                <div className="Cline_left1 mt-3"></div>
                <li className="nav-item ">
                  <span
                    className="nav-link text_act StatusItem active"
                    id="profile-tab"
                  >
                    <img src={Check} className="check user-icon" />
                    <div
                      className="mt-1"
                      style={{ width: "8rem", marginLeft: "-52px" }}
                    >
                      Buyer Verification
                    </div>
                  </span>
                </li>

                {this.state.screen === INVOICE_STATUSES_KEY.OFFER_MADE ||
                this.state.screen === INVOICE_STATUSES_KEY.COMPANY_DOCUMENT ||
                this.state.screen === INVOICE_STATUSES_KEY.KYC_PROCESS ||
                this.state.screen === INVOICE_STATUSES_KEY.INVOICE_DOCUMENT ||
                this.state.screen === INVOICE_STATUSES_KEY.POOL_GOVERNANCE ||
                this.state.screen === INVOICE_STATUSES_KEY.FINAL_OFFER ||
                this.state.screen === INVOICE_STATUSES_KEY.ARPA ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.COLLECTION ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.HISTORY_BOOK ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.INVOICE_DETAILS}
                        onClick={() => this.navigate("invoiceDetails")}
                      >
                        <i
                          className="fa fa-check-circle  fa-lg check user-icon"
                          aria-hidden="true"
                          id={`${INVOICE_STATUSES_KEY.INVOICE_DETAILS}innerContent`}
                        />

                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-59px" }}
                        >
                          Invoice Submitted
                        </div>
                      </span>
                    </li>
                  </span>
                ) : this.state.screen ===
                  INVOICE_STATUSES_KEY.INVOICE_DETAILS ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.INVOICE_DETAILS}
                      >
                        03
                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-59px" }}
                        >
                          Invoice Submitted
                        </div>
                      </span>
                    </li>
                  </span>
                ) : (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act StatusItem"
                        id="contact-tab"
                      >
                        03
                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-59px" }}
                        >
                          Invoice Submitted
                        </div>
                      </span>
                    </li>
                  </span>
                )}

                {this.state.screen === INVOICE_STATUSES_KEY.COMPANY_DOCUMENT ||
                this.state.screen === INVOICE_STATUSES_KEY.KYC_PROCESS ||
                this.state.screen === INVOICE_STATUSES_KEY.INVOICE_DOCUMENT ||
                this.state.screen === INVOICE_STATUSES_KEY.POOL_GOVERNANCE ||
                this.state.screen === INVOICE_STATUSES_KEY.FINAL_OFFER ||
                this.state.screen === INVOICE_STATUSES_KEY.ARPA ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.COLLECTION ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.HISTORY_BOOK ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.OFFER_MADE}
                        onClick={() => this.navigate("OfferMade")}
                      >
                        <i
                          className="fa fa-check-circle  fa-lg check user-icon"
                          aria-hidden="true"
                          id={`${INVOICE_STATUSES_KEY.OFFER_MADE}innerContent`}
                        />
                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-59px" }}
                        >
                          Soft Offer{" "}
                        </div>
                      </span>
                    </li>
                  </span>
                ) : this.state.screen === INVOICE_STATUSES_KEY.OFFER_MADE ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.OFFER_MADE}
                      >
                        04
                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-59px" }}
                        >
                          Soft Offer{" "}
                        </div>
                      </span>
                    </li>
                  </span>
                ) : (
                  <span>
                    <div className="line_right1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act StatusItem"
                        id="contact-tab"
                      >
                        04
                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-59px" }}
                        >
                          Soft Offer{" "}
                        </div>
                      </span>
                    </li>
                  </span>
                )}

                {this.state.screen === INVOICE_STATUSES_KEY.KYC_PROCESS ||
                this.state.screen === INVOICE_STATUSES_KEY.INVOICE_DOCUMENT ||
                this.state.screen === INVOICE_STATUSES_KEY.POOL_GOVERNANCE ||
                this.state.screen === INVOICE_STATUSES_KEY.FINAL_OFFER ||
                this.state.screen === INVOICE_STATUSES_KEY.ARPA ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.COLLECTION ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.HISTORY_BOOK ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.COMPANY_DOCUMENT}
                        onClick={() => this.navigate("companyDocument")}
                      >
                        <i
                          className="fa fa-check-circle  fa-lg check user-icon"
                          aria-hidden="true"
                          id={`${INVOICE_STATUSES_KEY.COMPANY_DOCUMENT}innerContent`}
                        />
                        <div
                          className="mt-1"
                          style={{ width: "11rem", marginLeft: "-75px" }}
                        >
                          Company Documentation
                        </div>
                      </span>
                    </li>
                  </span>
                ) : this.state.screen ===
                  INVOICE_STATUSES_KEY.COMPANY_DOCUMENT ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.COMPANY_DOCUMENT}
                        onClick={() => this.navigate("companyDocument")}
                      >
                        05
                        <div
                          className="mt-1"
                          style={{ width: "11rem", marginLeft: "-75px" }}
                        >
                          Company Documentation
                        </div>
                      </span>
                    </li>
                  </span>
                ) : (
                  <span>
                    <div className="line_right1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act StatusItem"
                        id="contact-tab"
                      >
                        05
                        <div
                          className="mt-1"
                          style={{ width: "11rem", marginLeft: "-75px" }}
                        >
                          Company Documentation
                        </div>
                      </span>
                    </li>
                  </span>
                )}

                {this.state.screen === INVOICE_STATUSES_KEY.INVOICE_DOCUMENT ||
                this.state.screen === INVOICE_STATUSES_KEY.POOL_GOVERNANCE ||
                this.state.screen === INVOICE_STATUSES_KEY.FINAL_OFFER ||
                this.state.screen === INVOICE_STATUSES_KEY.ARPA ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.COLLECTION ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.HISTORY_BOOK ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.KYC_PROCESS}
                        onClick={() => this.navigate("kycProcess")}
                      >
                        <i
                          className="fa fa-check-circle  fa-lg check user-icon"
                          aria-hidden="true"
                          id={`${INVOICE_STATUSES_KEY.KYC_PROCESS}innerContent`}
                        />
                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-59px" }}
                        >
                          KYC Process
                        </div>
                      </span>
                    </li>
                  </span>
                ) : this.state.screen === INVOICE_STATUSES_KEY.KYC_PROCESS ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.KYC_PROCESS}
                        onClick={() => this.navigate("kycProcess")}
                      >
                        06
                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-59px" }}
                        >
                          KYC Process
                        </div>
                      </span>
                    </li>
                  </span>
                ) : (
                  <span>
                    <div className="line_right1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act StatusItem"
                        id="contact-tab"
                      >
                        06
                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-59px" }}
                        >
                          KYC Process
                        </div>
                      </span>
                    </li>
                  </span>
                )}

                {this.state.screen === INVOICE_STATUSES_KEY.POOL_GOVERNANCE ||
                this.state.screen === INVOICE_STATUSES_KEY.FINAL_OFFER ||
                this.state.screen === INVOICE_STATUSES_KEY.ARPA ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.COLLECTION ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.HISTORY_BOOK ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.INVOICE_DOCUMENT}
                        onClick={() => this.navigate("invoiceDocument")}
                      >
                        <i
                          className="fa fa-check-circle  fa-lg check user-icon"
                          aria-hidden="true"
                          id={`${INVOICE_STATUSES_KEY.INVOICE_DOCUMENT}innerContent`}
                        />
                        <div
                          className="mt-1"
                          style={{ width: "10rem", marginLeft: "-75px" }}
                        >
                          Invoice Documentation
                        </div>
                      </span>
                    </li>
                  </span>
                ) : this.state.screen ===
                  INVOICE_STATUSES_KEY.INVOICE_DOCUMENT ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.INVOICE_DOCUMENT}
                        onClick={() => this.navigate("invoiceDocument")}
                      >
                        07
                        <div
                          className="mt-1"
                          style={{ width: "10rem", marginLeft: "-75px" }}
                        >
                          Invoice Documentation
                        </div>
                      </span>
                    </li>
                  </span>
                ) : (
                  <span>
                    <div className="line_right1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act StatusItem"
                        id="contact-tab"
                      >
                        07
                        <div
                          className="mt-1"
                          style={{ width: "10rem", marginLeft: "-75px" }}
                        >
                          Invoice Documentation
                        </div>
                      </span>
                    </li>
                  </span>
                )}

                {this.state.screen === INVOICE_STATUSES_KEY.FINAL_OFFER ||
                this.state.screen === INVOICE_STATUSES_KEY.ARPA ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.COLLECTION ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.HISTORY_BOOK ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.POOL_GOVERNANCE}
                        onClick={() => this.navigate("poolGovernance")}
                      >
                        <i
                          className="fa fa-check-circle  fa-lg check user-icon"
                          aria-hidden="true"
                          id={`${INVOICE_STATUSES_KEY.POOL_GOVERNANCE}innerContent`}
                        />
                        <div
                          className="mt-1"
                          style={{ width: "10rem", marginLeft: "-70px" }}
                        >
                          Pool Governance
                        </div>
                      </span>
                    </li>
                  </span>
                ) : this.state.screen ===
                  INVOICE_STATUSES_KEY.POOL_GOVERNANCE ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.POOL_GOVERNANCE}
                        onClick={() => this.navigate("poolGovernance")}
                      >
                        08
                        <div
                          className="mt-1"
                          style={{ width: "10rem", marginLeft: "-70px" }}
                        >
                          Pool Governance
                        </div>
                      </span>
                    </li>
                  </span>
                ) : (
                  <span>
                    <div className="line_right1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act StatusItem"
                        id="contact-tab"
                      >
                        08
                        <div
                          className="mt-1"
                          style={{ width: "10rem", marginLeft: "-70px" }}
                        >
                          Pool Governance
                        </div>
                      </span>
                    </li>
                  </span>
                )}

                {this.state.screen === INVOICE_STATUSES_KEY.ARPA ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.COLLECTION ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.HISTORY_BOOK ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.FINAL_OFFER}
                        onClick={() => this.navigate("finalOffer")}
                      >
                        <i
                          className="fa fa-check-circle  fa-lg check user-icon"
                          aria-hidden="true"
                          id={`${INVOICE_STATUSES_KEY.FINAL_OFFER}innerContent`}
                        ></i>

                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-59px" }}
                        >
                          Final Offer
                        </div>
                      </span>
                    </li>
                  </span>
                ) : this.state.screen === INVOICE_STATUSES_KEY.FINAL_OFFER ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.FINAL_OFFER}
                        onClick={() => this.navigate("finalOffer")}
                      >
                        09
                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-59px" }}
                        >
                          Final Offer
                        </div>
                      </span>
                    </li>
                  </span>
                ) : (
                  <span>
                    <div className="line_right1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act StatusItem"
                        id="contact-tab"
                      >
                        09
                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-59px" }}
                        >
                          Final Offer
                        </div>
                      </span>
                    </li>
                  </span>
                )}

                {this.state.screen ===
                  INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.COLLECTION ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.HISTORY_BOOK ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.ARPA}
                        onClick={() => this.navigate("arpa")}
                      >
                        <i
                          className="fa fa-check-circle  fa-lg check user-icon"
                          aria-hidden="true"
                          id={`${INVOICE_STATUSES_KEY.ARPA}innerContent`}
                        />
                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-59px" }}
                        >
                          ARPA
                        </div>
                      </span>
                    </li>
                  </span>
                ) : this.state.screen === INVOICE_STATUSES_KEY.ARPA ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.ARPA}
                        onClick={() => this.navigate("arpa")}
                      >
                        10
                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-59px" }}
                        >
                          ARPA
                        </div>
                      </span>
                    </li>
                  </span>
                ) : (
                  <span>
                    <div className="line_right1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act StatusItem"
                        id="contact-tab"
                      >
                        10
                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-59px" }}
                        >
                          ARPA
                        </div>
                      </span>
                    </li>
                  </span>
                )}

                {this.state.screen ===
                  INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.COLLECTION ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.HISTORY_BOOK ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER}
                        onClick={() =>
                          this.navigate("invoiceVerificationByBuyer")
                        }
                      >
                        <i
                          className="fa fa-check-circle  fa-lg check user-icon"
                          aria-hidden="true"
                          id={`${INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER}innerContent`}
                        />
                        <div
                          className="mt-1"
                          style={{ width: "12rem", marginLeft: "-100px" }}
                        >
                          Invoice Verification By Buyer
                        </div>
                      </span>
                    </li>
                  </span>
                ) : this.state.screen ===
                  INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER}
                        onClick={() =>
                          this.navigate("invoiceVerificationByBuyer")
                        }
                      >
                        11
                        <div
                          className="mt-1"
                          style={{ width: "12rem", marginLeft: "-100px" }}
                        >
                          Invoice Verification By Buyer
                        </div>
                      </span>
                    </li>
                  </span>
                ) : (
                  <span>
                    <div className="line_right1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act StatusItem"
                        id="contact-tab"
                      >
                        11
                        <div
                          className="mt-1"
                          style={{ width: "12rem", marginLeft: "-100px" }}
                        >
                          Invoice Verification By Buyer
                        </div>
                      </span>
                    </li>
                  </span>
                )}

                {this.state.screen === INVOICE_STATUSES_KEY.COLLECTION ||
                this.state.screen ===
                  INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.HISTORY_BOOK ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION}
                        onClick={() => this.navigate("advancedFundAllocation")}
                      >
                        <i
                          className="fa fa-check-circle  fa-lg check user-icon"
                          aria-hidden="true"
                          id={`${INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION}innerContent`}
                        />
                        <div
                          className="mt-1"
                          style={{ width: "11rem", marginLeft: "-66px" }}
                        >
                          Advanced Fund Allocation
                        </div>
                      </span>
                    </li>
                  </span>
                ) : this.state.screen ===
                  INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION}
                        onClick={() => this.navigate("advancedFundAllocation")}
                      >
                        12
                        <div
                          className="mt-1"
                          style={{ width: "11rem", marginLeft: "-66px" }}
                        >
                          Advanced Fund Allocation
                        </div>
                      </span>
                    </li>
                  </span>
                ) : (
                  <span>
                    <div className="line_right1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act StatusItem"
                        id="contact-tab"
                      >
                        12
                        <div
                          className="mt-1"
                          style={{ width: "11rem", marginLeft: "-66px" }}
                        >
                          Advanced Fund Allocation
                        </div>
                      </span>
                    </li>
                  </span>
                )}

                {this.state.screen ===
                  INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION ||
                this.state.screen === INVOICE_STATUSES_KEY.HISTORY_BOOK ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.COLLECTION}
                        onClick={() => this.navigate("collection")}
                      >
                        <i
                          className="fa fa-check-circle  fa-lg check user-icon"
                          aria-hidden="true"
                          id={`${INVOICE_STATUSES_KEY.COLLECTION}innerContent`}
                        />
                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-56px" }}
                        >
                          Collection
                        </div>
                      </span>
                    </li>
                  </span>
                ) : this.state.screen === INVOICE_STATUSES_KEY.COLLECTION ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.COLLECTION}
                        onClick={() => this.navigate("collection")}
                      >
                        13
                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-56px" }}
                        >
                          Collection
                        </div>
                      </span>
                    </li>
                  </span>
                ) : (
                  <span>
                    <div className="line_right1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act StatusItem"
                        id="contact-tab"
                      >
                        13
                        <div
                          className="mt-1"
                          style={{ width: "8rem", marginLeft: "-56px" }}
                        >
                          Collection
                        </div>
                      </span>
                    </li>
                  </span>
                )}

                {this.state.screen === INVOICE_STATUSES_KEY.HISTORY_BOOK ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION}
                        onClick={() => this.navigate("reserveFundAllocation")}
                      >
                        <i
                          className="fa fa-check-circle  fa-lg check user-icon"
                          aria-hidden="true"
                          id={`${INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION}innerContent`}
                        />
                        <div
                          className="mt-1"
                          style={{ width: "11rem", marginLeft: "-66px" }}
                        >
                          Reserve Fund Allocation
                        </div>
                      </span>
                    </li>
                  </span>
                ) : this.state.screen ===
                  INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION}
                        onClick={() => this.navigate("reserveFundAllocation")}
                      >
                        14
                        <div
                          className="mt-1"
                          style={{ width: "11rem", marginLeft: "-66px" }}
                        >
                          Reserve Fund Allocation
                        </div>
                      </span>
                    </li>
                  </span>
                ) : (
                  <span>
                    <div className="line_right1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act StatusItem"
                        id="contact-tab"
                      >
                        14
                        <div
                          className="mt-1"
                          style={{ width: "11rem", marginLeft: "-66px" }}
                        >
                          Reserve Fund Allocation
                        </div>
                      </span>
                    </li>
                  </span>
                )}

                {this.state.screen === INVOICE_STATUSES_KEY.HISTORY_BOOK ? (
                  <span>
                    <div className="Cline_left1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act active  StatusItem"
                        id={INVOICE_STATUSES_KEY.HISTORY_BOOK}
                        onClick={() => this.navigate("HistoryBook")}
                      >
                        15
                        <div
                          className="mt-1"
                          style={{ width: "10rem", marginLeft: "-66px" }}
                        >
                          History Book
                        </div>
                      </span>
                    </li>
                  </span>
                ) : (
                  <span>
                    <div className="line_right1 mt-3"></div>
                    <li className="nav-item mt-16px curserP move2">
                      <span
                        className="nav-link text_act StatusItem"
                        id={INVOICE_STATUSES_KEY.HISTORY_BOOK}
                        onClick={() => this.navigate("HistoryBook")}
                      >
                        15
                        <div
                          className="mt-1"
                          style={{ width: "10rem", marginLeft: "-66px" }}
                        >
                          History Book
                        </div>
                      </span>
                    </li>
                  </span>
                )}
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  invoice: state.invoice,
});
export default connect(mapStateToProps)(withRouter(Progressbar));

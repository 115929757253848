import React, { Component } from "react";
import helper from "../../../utils/helper";
import preview from "../../../assets/images/preview.png";
import download from "../../../assets/images/download1.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getFinalOfferById } from "../../../services/actions/invoiceFinalOfferAction";
import { getOfferById } from "../../../services/actions/invoiceOfferAction";
import {
  getAllInvoices,
  getInvoiceDetailsById,
  getInvoiceChanges,
  getInvoiceCreatedByUser,
} from "../../../services/actions/invoiceAction";
import { updateInvoice } from "../../../services/actions/invoiceAction";
import { getInvoiceStatus } from "../../../services/actions/invoiceStatusAction";
import { postAlert } from "../../../services/actions/alertActions";
import { postSmartToken } from "../../../services/actions/smartContractAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import InvoiceMoreDetail from "./invoiceMoreDetail";
import Progressbar from "./progressBar";
import { URI } from "../../../constant";
import Header from "./header";
import FinalOfferDetails from "./finalOfferDetails";
import InvoicesListId from "./invoicesListId";
import ajax from "../../../utils/ajax";
import { INVOICE_STATUSES_KEY } from "../../../config/string_constants";
import {
  addReserveFundAllocation,
  updateInvoiceStatus,
  getInvoiceForAdmin,
  approveFundAllocation,
} from "../../../api/invoiceService";
import PricingDetails from "./pricingDetails";
import { HELPERS } from "../../../helpers/index";
import UnlockCard from "./sideCard/unlockCard";
import { ACCOUNT_TYPE } from "../../../config/string_constants";
import { getUserAccountsById } from "../../../services/actions/userAccountAction";

class reserveFundAllocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceId: this.props.match.params.id,
      invoicedetails: {},
      reserve_fund_allocation: {},
      fileLoader: false,
      fileError: "",
      isLoader: false,
      showPdf: false,
      invoices: [],
      invoiceStatusArray: [],
      selected: false,
      pdfUrl: null,
      screen: INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION,
      reserveOffer: false,
      errors: {},
      lock: false,
      UnlockSuccess: false,
      bankName: "",
    };
  }
  handleValidation() {
    const errors = {};
    let formIsValid = true;
    if (this.state.reserve_fund_allocation?.transaction_id === "") {
      formIsValid = false;
      errors.transaction_id = "This field is a required";
    }
    if (this.state.invoicedetails?.late_fee === "") {
      formIsValid = false;
      errors.late_fee = "This field is a required";
    } else {
      const floatLateFee = parseFloat(
        this.state.invoicedetails?.final_offer.late_fee
      );
      if (floatLateFee < 0 || floatLateFee > 100) {
        formIsValid = false;

        errors.late_fee = "Late fee should be between 0 to 100";
      }
    }

    this.setState({ errors });

    return formIsValid;
  }
  handleClose = () => {
    this.setState({ showPdf: false });
  };

  onChange = async (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        reserve_fund_allocation: {
          ...this.state.reserve_fund_allocation,
          [name]: value,
        },
      },
      this.handleValidation
    );
  };

  onPdfShow = (url) => {
    this.setState({
      showPdf: true,
      pdfUrl: url,
    });
  };
  goback = () => {
    this.props.history.push("/dashboard");
  };
  validation = () =>
    !(this.state.reserve_fund_allocation?.client_payable === 0);

  /*  Calculation total amount */
  calculateTotalAmountRecieved = () =>
    Number(this.state.invoicedetails.collection_details.amount_received) +
    Number(
      this.state.invoicedetails.collection_details.amount_received_from_supplier
    );

  componentDidMount() {
    this.props
      .getInvoiceDetailsById(this.state.invoiceId)
      .then((result) => {
        this.props.getInvoiceChanges(result);
        this.setState({
          invoicedetails: result,
        });
        this.setState(
          {
            selected: true,
            invoicedetails: {
              ...this.state.invoicedetails,
              late_day: helper.calculateNumberOfLateDays(
                this.state.invoicedetails?.invoice_due_date,
                this.state.invoicedetails?.final_offer.grace_period,
                this.state.invoicedetails?.collection_details.payment_date
              ),
            },
          },
          () => {
            this.setState(
              {
                reserve_fund_allocation: {
                  ...this.state.reserve_fund_allocation,
                  total_amount_received: this.calculateTotalAmountRecieved(),
                  payment_date: moment(
                    this.state.invoicedetails?.collection_details.payment_date
                  ),
                  transaction_id: "",
                  late_fee_value: helper.calculateLateFeesHandler(
                    this.state.invoicedetails?.final_offer?.late_fee,
                    this.state.invoicedetails?.final_offer?.amount_to_be_funded,
                    this.state.invoicedetails?.late_day
                  ),
                  late_fee: this.state.invoicedetails?.final_offer.late_fee,
                  finance_tenure: helper.calculateFinanceTenure(
                    this.state.invoicedetails?.collection_details?.payment_date,
                    this.state.invoicedetails?.final_offer?.advance_date
                  ),
                },
              },
              () => {
                this.setState(
                  {
                    reserve_fund_allocation: {
                      ...this.state.reserve_fund_allocation,
                      discountingFee: helper.calculateDiscountFee(
                        this.state.invoicedetails?.final_offer?.discount,
                        this.state.invoicedetails?.final_offer
                          ?.amount_to_be_funded,
                        this.state.reserve_fund_allocation?.finance_tenure,
                        this.state.invoicedetails?.late_day
                      ),
                      factoringFee: helper.calculateFactoringFee(
                        this.state.invoicedetails?.final_offer?.factoring_fee,
                        this.state.invoicedetails?.invoice_amount
                      ),
                    },
                  },
                  () => {
                    this.setState(
                      {
                        reserve_fund_allocation: {
                          ...this.state.reserve_fund_allocation,
                          fee:
                            this.state.reserve_fund_allocation.discountingFee +
                            this.state.reserve_fund_allocation.factoringFee +
                            this.state.reserve_fund_allocation.late_fee_value +
                            this.state.invoicedetails?.final_offer
                              ?.bank_charges,
                        },
                      },
                      () => {
                        this.setState({
                          reserve_fund_allocation: {
                            ...this.state.reserve_fund_allocation,
                            client_payable:
                              helper.calculateNetPayableToClientHandler(
                                this.state.reserve_fund_allocation
                                  .total_amount_received,
                                this.state.invoicedetails.final_offer
                                  .amount_to_be_funded,
                                this.state.reserve_fund_allocation.fee
                              ),
                          },
                        });
                      }
                    );
                  }
                );
              }
            );
          }
        );
        getInvoiceForAdmin(result.created_by_id)
          .then(({ data }) => {
            this.setState({
              invoices: data,
            });
          })
          .catch((err) => { });
        this.getAccountDetails(result.created_by_id);
        //calling Helper function to getPricingDetails
        HELPERS.getPricingDetails(
          this.state.invoicedetails.final_offer.pricing_id
        )
          .then((pricing) => {
            this.setState({ pricingData: pricing });
          })
          .catch((err) => { });
      })
      .catch((err) => { });
    this.setState({
      invoiceStatusArray: this.props.invoiceStatus,
    });
  }

  showData = (item) => {
    this.setState({
      invoiceId: item._id,
      selected: true,
    });
  };

  onSubmit = () => {
    const reserve_fund_allocation = {
      ...this.state.invoicedetails,
      reverse_fund_allocation: { ...this.state.reserve_fund_allocation },
    };
    this.props
      .updateInvoice(reserve_fund_allocation)
      .then(() => {
        this.approveInvoice();
      })
      .catch((err) => { });
  };

  fileChange = (e) => {
    if (helper.validateFileType(e.target.files)) {
      this.uploadFile(e.target.files[0], e.target.name);
    } else {
      this.setState({
        fileLoader: false,
        fileError: "Please Upload file in JPG, PNG, PDF and max size 5mb.",
      });
    }
  };
  uploadFile(file, name) {
    this.setState({ fileLoader: true });
    const formData = new FormData();
    formData.append("image", file);
    if (file !== undefined) {
      ajax(
        "POST",
        `${URI}/api/v1/image-upload`,
        {},
        { "content-type": "multipart/form-data" },
        formData
      )
        .then(({ data }) => {
          toast.info("File Updated Successfully");
          this.setState({
            reserve_fund_allocation: {
              ...this.state.reserve_fund_allocation,
              document: data.imageUrl,
            },
            fileLoader: false,
            fileError: "",
          });
        })
        .catch((err) => { });
    }
  }
  // fund allocation
  UpdateReverseFundAllocation = () => {
    if (this.handleValidation()) {
      const payload = {
        invoice_id: this.state.invoicedetails._id,
        late_fee: this.state.reserve_fund_allocation.late_fee,
        document: this.state.reserve_fund_allocation.document || "",
        transaction_id: this.state.reserve_fund_allocation.transaction_id,
      };
      this.setState({ isLoader: true }, async () => {
        const response = await addReserveFundAllocation(payload);

        if (response) {
          if (response.data.success) {
            this.approveInvoice();
          } else {
            toast.error(response.data.message);
          }
        }
      });
    }
  };

  approveInvoice = async () => {
    this.setState({ reserveOffer: false });
    toast.info("Data Updated Successfully");
    const obj = this.state.invoiceStatusArray.find(
      (o) => o.key === INVOICE_STATUSES_KEY.HISTORY_BOOK
    );
    const finalSubstatus = obj
      ? obj.subStatus.find((o) => o.statusName === "In Progress")
      : {};
    const statusData = {
      invoice_id: this.state.invoicedetails._id,
      invoiceSubStatus_id: finalSubstatus._id,
      invoiceStatus_id: obj._id,
    };
    const res = await updateInvoiceStatus(statusData);
    this.setState({ isLoader: false });
    if (res) {
      if (res.data.success) {
        this.props.history.push(`/history-book/${this.state.invoiceId}`);
      }
    }
  };

  getAccountDetails = (_id) => {
    this.props.getUserAccountsById(_id).then((accounts) => {
      const userPrimaryAccount = accounts.find(
        (item) => item.account_type === ACCOUNT_TYPE.PRIMARY
      );
      this.setState({ bankName: userPrimaryAccount.bank_name });
    });
  };

  approveFundAllocation = async () => {
    const data = {
      _id: this.state.invoicedetails._id,
      is_reserve_approved: true,
    };
    const response = await approveFundAllocation(data);
    if (response) {
      if (response.data.success) {
        toast.info("Reserve Fund Approved successfully");
        this.props
          .getInvoiceDetailsById(this.state.invoiceId)
          .then((invoice) => {
            this.setState({
              invoicedetails: invoice,
            });
          })
          .catch((err) => { });
      }
    }
  };

  render() {
    return (
      <div>
        <Header />

        <div className="row m-4 ">
          <div className="col-3">
            <InvoiceMoreDetail
              invoicedetails={this.state.invoicedetails}
              invoiceCompany={this.state.invoicedetails.client_company}
            />
          </div>
          <div className="col-9 overflow-auto">
            <Progressbar
              invoiceId={this.state.invoiceId}
              screen={this.state.screen}
            />
          </div>
        </div>

        <div className="row m-2 my-4">
          <div className="col-6">
            <div className="info-one h-100">
              <p className="text-white">REPAYMENT DETAILS</p>
              {Object.keys(this.state.invoicedetails).length === 0 ? (
                <div>No data Available </div>
              ) : (
                <div key={this.state.invoicedetails._id}>
                  <div className="d-flex justify-content-between mt-4  text_tra1">
                    <div>
                      Collection Format{" "}
                      <h5 className="text-white">
                        {this.state.invoicedetails?.collection_details
                          ? this.state.invoicedetails?.collection_details
                            ?.collection_format
                          : ""}
                      </h5>
                    </div>
                    <div>
                      Refernce Txn ID{" "}
                      <h5 className="text-white">
                        {this.state.invoicedetails?.collection_details
                          ? this.state.invoicedetails?.collection_details
                            ?.payment_ref_no
                          : ""}
                      </h5>
                    </div>
                    <div>
                      Bank Name
                      <h5 className="text-white">
                        <h5 className="text-white">
                          {this.state.bankName || ""}
                        </h5>
                      </h5>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-4  text_tra1">
                    <div>
                      Payment Date{" "}
                      <h5 className="text-white">
                        {this.state.invoicedetails?.collection_details
                          ? moment(
                            this.state.invoicedetails?.collection_details
                              ?.payment_date
                          ).format("DD MMM YYYY")
                          : ""}
                      </h5>
                    </div>
                    <div>
                      Amount Received{" "}
                      <h5 className="text-white">
                        {Number(
                          this.state.reserve_fund_allocation
                            ? this.state.reserve_fund_allocation
                              ?.total_amount_received
                            : 0
                        )
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </h5>
                    </div>
                    <div>
                      View Receipt
                      <h5 className="text-white">
                        <p>
                          <a
                            href
                            onClick={() =>
                              this.onPdfShow(
                                this.state.invoicedetails?.collection_details
                                  ? this.state.invoicedetails
                                    ?.collection_details?.doc
                                  : ""
                              )
                            }
                          >
                            <img src={preview} alt="Preview" />
                          </a>
                          <a
                            href={
                              this.state.invoicedetails?.collection_details
                                ? this.state.invoicedetails?.collection_details
                                  ?.doc
                                : ""
                            }
                            style={{ color: "white" }}
                          >
                            {" "}
                            <img src={download} alt="Download" />
                          </a>
                        </p>
                      </h5>
                    </div>
                  </div>
                  {this.state.invoicedetails.is_reserve_approved ? (
                    <div className=" d-flex justify-content-between mt-4 p-2 bgc-dark  text-white">
                      <div>Calculate Reserve Offer</div>
                      <div>
                        <i
                          className="uil-angle-right txt_b"
                          onClick={() => this.setState({ reserveOffer: true })}
                        ></i>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-5 text-center">
                      <button
                        className="btn-blue "
                        onClick={this.approveFundAllocation}
                      >
                        Approve Reserve
                      </button>
                    </div>
                  )}

                  <div className=" mt-4 p-3 bgc-dark  ">
                    <h6 className="text-white">UPLOAD INVOICE IF ANY</h6>
                    <div className="d-flex p-3 bgc-dark1 justify-content-between">
                      <div className="text_tra1">
                        <i className="uil-upload" />
                        Upload invoice
                      </div>
                      <div>
                        <span className="uploadb">Upload</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="info-one h-100">
              <p className="text_tra1 mb-2">INVOICES</p>
              <InvoicesListId
                invoices={this.state.invoices}
                selected={this.state.selected}
                showData={this.showData}
                itemId={this.state.invoicedetails._id}
              />
              <PricingDetails
                pricingData={this.state.pricingData}
                show={false}
              />

              {this.state.invoicedetails?.final_offer?.tenure ? (
                <FinalOfferDetails
                  invoiceId={this.state.invoicedetails._id}
                  invoicedetails={this.state.invoicedetails}
                  tenure={this.state.invoicedetails.final_offer?.tenure}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div
          style={{ overflowY: "auto" }}
          className={`menuNav ${this.state.reserveOffer ? "showMenu2" : ""}`}
        >
          <div
            style={{ borderBottom: "1px solid gray" }}
            className="d-flex justify-content-between text-white p-3"
          >
            <div>
              <h5>Calculate Reserve Offers </h5>
              <p className="text_tra1">
                Invoice {this.state.invoicedetails.invoice_id}
              </p>
            </div>
            <h5
              style={{ cursor: "pointer" }}
              onClick={() => this.setState({ reserveOffer: false })}
            >
              <i className="uil-multiply"></i>
            </h5>
          </div>

          <div className="row p-3">
            <div className="col-4">
              <label>Buyer Name</label>
              <input
                className="input"
                value={this.state.invoicedetails?.buyer_company?.company_name}
                disabled
              ></input>
            </div>
            <div className="col-4">
              <label>Invoice Ref.No</label>
              <input
                className="input"
                value={this.state.invoicedetails?.invoice_id}
                disabled
              ></input>
            </div>
            <div className="col-4">
              <label>Supplier Invoice Number</label>
              <input
                className="input"
                value={this.state.invoicedetails?.supplier_invoice_number}
                disabled
              ></input>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-4">
              <label>Invoice Amount</label>
              <input
                className="input"
                value={`$${Number(
                  this.state.invoicedetails
                    ? this.state.invoicedetails?.invoice_amount
                    : 0
                )
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
                disabled
              ></input>
            </div>
            <div className="col-4">
              <label>Invoice Limit</label>
              <input
                className="input"
                value={`$${Number(
                  this.state.invoicedetails
                    ? this.state.invoicedetails?.invoice_limit
                    : 0
                )
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
                disabled
              ></input>
            </div>
            <div className="col-4">
              <label>Advanced Amount</label>
              <input
                className="input"
                value={`$${Number(
                  this.state.invoicedetails
                    ? this.state.invoicedetails?.final_offer
                      ?.amount_to_be_funded
                    : 0
                )
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
                disabled
              ></input>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-4">
              <label>Reserve Amount</label>
              <input
                className="input"
                value={`$${Number(
                  this.state.invoicedetails
                    ? this.state.invoicedetails?.invoice_amount -
                    this.state.invoicedetails?.final_offer
                      ?.amount_to_be_funded
                    : 0
                )
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
                disabled
              ></input>
            </div>
            <div className="col-4">
              <label>Amount Received </label>
              <input
                className="input"
                value={`$${Number(
                  this.state.reserve_fund_allocation
                    ? this.state.reserve_fund_allocation?.total_amount_received
                    : 0
                )
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
                name="amount_received"
                disabled
              ></input>
            </div>
            <div className="col-4">
              <label>Invoice Due Date</label>
              <input
                className="input"
                value={moment(
                  this.state.invoicedetails?.invoice_due_date
                ).format(" DD/MM/YYYY")}
                disabled
              ></input>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-4">
              <label>Payment Received Date</label>
              <input
                type="date"
                className="input"
                value={
                  this.state.invoicedetails?.collection_details?.payment_date
                }
                name="payment_date"
                // onChange={this.onChange}
                disabled
              ></input>
            </div>
            <div className="col-4">
              <label>Date of Advanced Fund</label>
              <input
                className="input"
                value={moment(this.state.invoicedetails?.final_offer?.advance_date).format(
                  "DD/MM/YYYY"
                )
                }
                name="advance_date"
                disabled
              ></input>
            </div>
            <div className="col-4">
              <label>Number of Late Days</label>
              <input
                className="input"
                value={this.state.invoicedetails?.late_day}
                name="late_day"
                disabled
              ></input>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-4">
              <label>Discounting Fee</label>
              <input
                className="input"
                type="text"
                value={`$${Number(
                  this.state.reserve_fund_allocation?.discountingFee
                ).toFixed(2)}`}
                disabled
                name="client_payable"
              ></input>
            </div>
            <div className="col-4">
              <label>Factoring Fee</label>
              <input
                className="input"
                type="text"
                value={`$${this.state.reserve_fund_allocation?.factoringFee}`}
                disabled
                name="client_payable"
              ></input>
            </div>
            <div className="col-4">
              <label>Banking Charges</label>
              <input
                className="input"
                type="text"
                value={`$${this.state.invoicedetails?.final_offer?.bank_charges}`}
                disabled
                name="client_payable"
              ></input>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-4">
              <label>Total Fee</label>
              <input
                className="input"
                value={`$${Number(this.state.reserve_fund_allocation?.fee)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
                name="fee"
                disabled
              ></input>
            </div>
            <div className="col-4">
              <label>Late Fee (in percentage)</label>
              <input
                className="input"
                type="number"
                value={this.state.reserve_fund_allocation?.late_fee}
                name="late_fee"
                disabled
              ></input>
              <div className="text-danger">
                {this.state.errors.late_fee ? this.state.errors.late_fee : ""}
              </div>
            </div>
            <div className="col-4">
              <label>Late Fee Value</label>
              <input
                className="input"
                value={`$${Number(
                  this.state.reserve_fund_allocation.late_fee_value
                    ? this.state.reserve_fund_allocation?.late_fee_value
                    : 0
                )
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
                name="total_fee"
                disabled
              ></input>
            </div>
          </div>

          <div className="row p-3">
            <div className="col-4">
              <label>Net Amount Payable to Client</label>
              <input
                className="input"
                type="text"
                value={`$${this.state.reserve_fund_allocation?.client_payable}`}
                disabled
                name="client_payable"
              ></input>
            </div>
            <div className="col-4">
              <label>Short Amount</label>
              <input
                className="input"
                type="text"
                value={`$${this.state.invoicedetails.collection_details?.short_amount}`}
                disabled
                name="short_amount"
              ></input>
            </div>
            <div className="col-4">
              <label>Financed Tenure</label>
              <input
                className="input"
                value={this.state.reserve_fund_allocation?.finance_tenure}
                name="late_day"
                disabled
              ></input>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-4">
              <label>
                Please enter transaction hash for funds transferred to lender
                pool
              </label>
              <input
                className="input"
                value={this.state.reserve_fund_allocation?.transaction_id}
                name="transaction_id"
                onChange={this.onChange}
              ></input>
              <div className="text-danger">
                {this.state.errors.transaction_id
                  ? this.state.errors.transaction_id
                  : ""}
              </div>
            </div>
            <div className="col-4">
              <label>Grace Period</label>
              <input
                className="input"
                value={this.state.invoicedetails?.final_offer?.grace_period}
                name="grace_period"
                disabled
              ></input>
            </div>
            <div className="col-4">
              <label>Upload If Any Document </label>
              <div className="bgc-dark d-flex p-2 justify-content-between">
                <div className="text_tra1">
                  <i className="uil-upload" /> Upload here
                </div>
                <span>
                  <label className="uploadb header-input mx-3">
                    {this.state.fileLoader ? (
                      <div className="spinner-border btn--icon"></div>
                    ) : (
                      "Upload"
                    )}
                    <input
                      type="file"
                      style={{ display: "btn  col_erone" }}
                      name="document"
                      onChange={this.fileChange}
                    />
                  </label>
                  {this.state.fileError && (
                    <div className="text-danger">{this.state.fileError}</div>
                  )}
                </span>
              </div>
            </div>
          </div>
          {!this.state.invoicedetails.reserve_fund_allocation && (
            <div style={{ bottom: "0" }} className="my-5 text-center ">
              {this.state.reserve_fund_allocation.client_payable < 0 &&
                !this.state.UnlockSuccess ? (
                <button
                  className="btn-blue w-25 "
                  onClick={() => this.setState({ lock: true })}
                >
                  Unlock to Proceed
                </button>
              ) : (
                <span>
                  {this.state.invoicedetails.offer_id &&
                    !this.state.UnlockSuccess && (
                      <button
                        className="btn-blue w-25 "
                        disabled={this.state.isLoader}
                        onClick={this.UpdateReverseFundAllocation}
                      >
                        {this.state.isLoader === false ? (
                          <span>Disburse Reserve </span>
                        ) : (
                          <div className="spinner-border btn--icon"></div>
                        )}
                      </button>
                    )}
                  <button
                    className="btn-blue w-25 mx-3"
                    onClick={this.onSubmit}
                  >
                    Update Reserve Status
                  </button>
                </span>
              )}
            </div>
          )}
          <UnlockCard
            lock={this.state.lock}
            unlock={() => this.setState({ lock: false })}
            triggerFunction={() => this.setState({ UnlockSuccess: true })}
          />
        </div>

        <Modal show={this.state.showPdf} onHide={this.handleClose}>
          <div style={{ backgroundColor: "#333333", color: "white" }}>
            {this.state.pdfUrl !== null ? (
              this.state.pdfUrl.split(".").pop() === "pdf" ||
                this.state.pdfUrl.split(".").pop() === "PDF" ? (
                <iframe
                  title="PDF Viewer"
                  src={`https://docs.google.com/gview?url=${this.state.pdfUrl}&embedded=true`}
                  style={{ width: "50rem", height: "50rem" }}
                  frameBorder="0"
                ></iframe>
              ) : (
                <>
                  <img
                    src={this.state.pdfUrl}
                    style={{ width: "30rem", height: "30rem" }}
                    alt="pdf"
                  />
                </>
              )
            ) : (
              <div></div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  invoiceStatus: state.invoiceStatus.status,
  alert: state.alert,
  company: state.company,
  pricing: state.pricing,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceChanges: (data) => dispatch(getInvoiceChanges(data)),
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  getUserAccountsById: (data) => dispatch(getUserAccountsById(data)),
  postAlert: (data) => dispatch(postAlert(data)),
  getAllInvoices: (data) => dispatch(getAllInvoices(data)),
  getFinalOfferById: (data) => dispatch(getFinalOfferById(data)),
  getInvoiceCreatedByUser: (data) => dispatch(getInvoiceCreatedByUser(data)),
  postSmartToken: (data) => dispatch(postSmartToken(data)),
  getOfferById: (data) => dispatch(getOfferById(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(reserveFundAllocation));

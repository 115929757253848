import { React, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Close from "../../../assets/images/close_icon.svg";
import "../../../assets/css/common/fontStyle.css";
import "../../../assets/css/common/button.css";

export default function SuccesstPinPopup(props) {
  const [pin, setpin] = useState("");
  const [secret, setSecret] = useState(true);
  let closePopUp = () => {
    setpin("");
    props.hideReward();
  };
  return (
    <div>
      <div style={{ fontFamily: "Averta-Semibold,sans-serif" }}>
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ backgroundColor: "transparent" }}
        >
          <div className="alert-popup">
            <div className="mt-3 disp-lg-none">
              <div className="line mx-auto"></div>
            </div>

            <Modal.Body className="text-center">
              <>
                <div className="float-right mb-2 ">
                  <img
                    src={Close}
                    className="user-icon  curserP"
                    onClick={props.hideReward}
                    alt=""
                  />
                </div>
                <div>
                  <i
                    className="uil-check-circle  text-info"
                    style={{ fontSize: "100px" }}
                    role="status"
                  ></i>
                  <h4 className="text-white">Successfully withdrawn</h4>
                </div>
              </>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </div>
  );
}

import React, { Component } from "react";
import styles from "../../../assets/css/OnboardingPersonalDetails.module.css";
import Logo from "../../../assets/images/Logo.svg";
import MainHeader from "../mainHeader";
import Check from "../../../assets/images/check1.png";
import { getCountry } from "../../../services/actions/countryActions";
import {
  getCompany,
  getBuyer,
  verifyCompany,
  getCompanyById,
  getCompaniesByCreatedByAndApproved,
} from "../../../services/actions/companyActions";
import {
  getAllInvoices,
  getInvoiceDetailsById,
  getInvoiceChanges,
  getInvoiceCreatedByUser,
} from "../../../services/actions/invoiceAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import axios from "axios";
import { URI } from "../../../constant";
import e from "cors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InvoiceMoreDetail from "../invoice/invoiceMoreDetail";
import ajax from "../../../utils/ajax";
import {DETAILS} from '../../../config/string_constants';
class BuyerVerification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceId: parseInt(this.props.match.params.id),
      activeId: "",
      userId: this.props.match.params.uId,
      buyers: [],
      uId: "",
      buyerLength: 0,
      showId: 1,
      company: [],
      available_amount: "",
      company_report: null,
      companySuccess: false,
      country: [],
      userCompany: [],
      selected: false,
      load: false,
    };
  }

  showData = (item) => {
    this.setState({
      selected: true,
      showId: item._id,
      company: item,
      userCompany: item,
    });
  };
  handleAmount = (e, item) => {
    item.available_amount = e.target.value;

    this.setState(
      {
        company: item,
        available_amount: e.target.value,
      },
      () => {}
    );
  };

  uploadReport = (e, item) => {
    this.uploadFile(e.target.files[0], item);
    let files = e.target.files;

    let reader = new FileReader();
    if (e.target.files[0] !== undefined) {
      reader.readAsDataURL(files[0]);
      reader.onLoad = (e) => {};
    }
  };

  uploadFile(file, item) {
    const formData = new FormData();

    formData.append("image", file);

    if (file !== undefined) {
      ajax(
        "POST",
        `${URI}/api/v1/image-upload`,
        {},
        { "content-type": "multipart/form-data" },
        formData
      )
        .then((data) => {
          item.company_report = data.data.imageUrl;

          this.setState({
            company: item,
          });
        })
        .catch((err) => {});
    }
  }

  verifyCompany = () => {
    this.setState(
      {
        companySuccess: true,
      },
      () => {
        this.props
          .verifyCompany(this.state.company)
          .then((response) => {
            toast.info("Company Verified");
            this.setState(
              {
                companySuccess: false,
                available_amount: "",
                load: true,
              },
              () => {
                this.props
                  .getCompaniesByCreatedByAndApproved(this.state.userId)
                  .then((response) => {
                    this.setState({
                      selected: true,
                      showId: response[0]._id,
                      buyers: response,
                      buyerLength: response.length,
                      company: response,
                      userCompany: response[0],
                      load: false,
                    });
                  })
                  .catch((err) => {});
              }
            );
          })
          .catch((err) => {});
      }
    );
  };

  componentDidMount() {
    this.props
      .getCompaniesByCreatedByAndApproved(this.state.userId)
      .then((response) => {
        this.setState({
          selected: true,
          showId: response[0]._id,
          buyers: response,
          buyerLength: response.length,
          company: response,
          userCompany: response[0],
        });
      })
      .catch((err) => {});

    this.props
      .getCountry()
      .then((result) => {
        this.setState({
          country: result,
        });
      })
      .catch((err) => {});
  }

  callRegistration = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div>
        <nav className="d-flex  Navbar">
          <img
            onClick={() => this.props.history.push("/dashboard")}
            className={styles.Logo}
            src={Logo}
            alt=""
          />
          <div
            style={{
              height: "91px",
              borderRight: "1px solid gray",
              marginLeft: "227px",
            }}
          ></div>

          <div className="mt-4 text-white">
            <i
              onClick={() => this.props.history.goBack()}
              style={{ fontSize: "20px" }}
              className="ml-4 uil-arrow-left"
            >
              {" "}
            </i>
            <span>Client</span>
          </div>

          <div className="user-wrapper ml-auto mr-4">
            <MainHeader />
          </div>
        </nav>
        <ToastContainer
          position="top-center"
          style={{ height: "30px" }}
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="row m-2 my-4">
          <div className="col-3">
            <InvoiceMoreDetail
              buyer={true}
              invoiceCompany={this.state.userCompany}
            />
          </div>
          <div className="col-9">
            <div className="info-two">
              <div className="mb-2">
                <ul
                  className="nav nav-pills justify-content-center pt-2"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item  move1">
                    <span
                      className="nav-link text_act  StatusItem active"
                      id="home-tab"
                      onClick={this.callRegistration}
                    >
                      <img src={Check} className="check user-icon" />
                      <div
                        className="mt-1"
                        style={{ width: "7rem", marginLeft: "-49px" }}
                      >
                        Registration
                      </div>
                    </span>
                  </li>
                  <div className="Cline_left mt-3"></div>
                  <li className="nav-item ">
                    <span
                      className="nav-link text_act StatusItem active"
                      id="profile-tab"
                    >
                      <img src={Check} className="check user-icon" />
                      <div
                        className="mt-1"
                        style={{ width: "8rem", marginLeft: "-49px" }}
                      >
                        Buyer Verification
                      </div>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-2 my-4">
          <div className="col-6">
            <div className="info-one h-100">
              <div className="tab-content">
                {this.state.buyers == undefined ? (
                  <div>No Buyers added yet</div>
                ) : (
                  this.state.buyers
                    .filter((data) => {
                      return data._id === this.state.showId;
                    })
                    .map((item, index) => (
                      <div className="tab-pane active" id="tabs-1" role="tabpanel">
                        <p className="text_tra1 mb-2">COMPANY DETAILS</p>

                        <div className="row mt-4  text_tra1">
                          <div className="col-4">
                            Company Name{" "}
                            <h5 className="text-white">{item.company_name}</h5>
                          </div>
                          <div className="col-4">
                            Available Credit Limit
                            <h5 className="text-white">
                              {Number(
                                item.available_amount
                                  ? item.available_amount
                                  : 0
                              )
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </h5>
                          </div>
                          <div className="col-4">
                            Company Address{" "}
                            <h5 className="text-white">{item.address_line1}</h5>
                          </div>
                        </div>
                        <div className="row mt-4  text_tra1">
                          <div className="col-4">
                            Country
                            <h5 className="text-white">
                              {item.country_id ? item.country_id.name : item.country_name}
                            </h5>
                          </div>
                          {/* <div className='col-4'>City<h5 className='text-white'>{item.city}</h5></div> */}
                          <div className="col-4">
                            Contact number
                            <h5 className="text-white">
                              {item.company_dialCode} {item.company_phone_no}
                            </h5>
                          </div>
                        </div>
                        <div className="row mt-4  text_tra1">
                          {/* <div className='col-4'>IEC number<h5 className='text-white'>{item.company.company_iec_number}</h5></div> */}
                          <div className="col-4">
                            Website
                            <h5 className="text-white">{item.website}</h5>
                          </div>
                          <div className="col-4"></div>
                          <div className="col-4">
                            Company Email
                            <h5 className="text-white">
                              {item.company_email_id}
                            </h5>
                          </div>
                        </div>

                        <p className="text_tra1 mb-2">PERSONAL DETAILS</p>
                        <div className="row mt-4  text_tra1">
                          <div className="col-4">
                            Full Name{" "}
                            <h5 className="text-white">
                              {item.contact_details.full_name}
                            </h5>
                          </div>
                          <div className="col-4">
                            Designation
                            <h5 className="text-white">
                              {item.contact_details.designation}
                            </h5>
                          </div>
                          <div className="col-4">
                            Contact Number{" "}
                            <h5 className="text-white">
                              {item.contact_details.user_dialCode}{" "}
                              {item.contact_details.phone_number}
                            </h5>
                          </div>
                        </div>
                        <div className="row mt-4  text_tra1">
                          <div className="col-4">
                            Email{" "}
                            <h5 className="text-white">
                              {item.contact_details.email}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))
                )}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="info-one h-100">
              <p className="text_tra1 mb-2">{DETAILS.BUYER_DETAILS}</p>
              <div className="nav info-nav flex-column " role="tablist">
                {this.state.buyers !== undefined
                  ? this.state.buyers.map((item, i) => (
                      <div
                        key={i}
                        className="d-flex p-2 my-1 bgc-dark"
                        id="circle1"
                        onClick={() => {
                          this.showData(item);
                        }}
                      >
                        <div
                          className={
                            this.state.selected &&
                            this.state.showId === item._id
                              ? "circle mt-2 ml-2 active"
                              : "circle mt-2 ml-2"
                          }
                          id="circle1"
                        ></div>
                        <a
                          className="nav-link text-white "
                          data-toggle="tab"
                          href="#tabs-1"
                          id="circle1"
                          role="tab"
                        >
                          {item.company_name}{" "}
                        </a>
                        <span className="ml-auto text-white">
                          {item.verify_company === true ? (
                            <span className="txt_g">Verified</span>
                          ) : (
                            <span className="txt_y">Not Verified</span>
                          )}
                        </span>
                      </div>
                    ))
                  : ""}
              </div>
              {this.state.buyers == undefined ? (
                <tr>No Buyers added yet</tr>
              ) : (
                this.state.buyers
                  .filter((data) => {
                    return data._id === this.state.showId;
                  })
                  .map((item, index) => (
                    <div>
                      <div
                        style={{ marginTop: "8rem" }}
                        className="p-3 d-flex justify-content-between bgc-dark"
                      >
                        <div>
                          <p className="text_tra1">Available Credit Limit</p>
                          <h4 className="text-white">
                            $
                            {Number(
                              item.available_amount ? item.available_amount : 0
                            )
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </h4>
                        </div>
                        <div>
                          <p className="txt_b">
                            {item.company_report ? (
                              <a href={item.company_report}> View Report</a>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </div>
                      {/* ))}
                            {this.state.buyers == undefined ? <tr>No Buyers added yet</tr> :
                                this.state.buyers.filter(data => { return data.id === this.state.showId }).map((item, index) => ( */}
                      <div className="p-3 d-flex my-2 justify-content-between bgc-dark">
                        <div>
                          <p className="text_tra1">Available Credit Limit</p>

                          <input
                            className="input"
                            placeholder="Enter Amount"
                            name="available_amount"
                            value={this.state.available_amount}
                            onChange={(e) => this.handleAmount(e, item)}
                          />
                          {/*(e)=>this.setState({ available_amount : e.target.value}) */}
                        </div>
                        <div className=" mt-4 ">
                          <label className="header-input uploadb p-2">
                            <input
                              type="file"
                              name="myImage"
                              onChange={(e) => this.uploadReport(e, item)}
                            />
                            Upload Report
                          </label>
                        </div>
                        <div style={{ marginTop: "29px" }}>
                          <span
                            className="uploadb "
                            onClick={this.verifyCompany}
                          >
                            Approve
                          </span>
                        </div>
                        <div style={{ marginTop: "24px" }}>
                          <i className="txt_b uil-comment-alt-lock" />
                        </div>
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  country: state.country,
  company: state.company,
});

const mapDispatchToProps = (dispatch) => ({
  getCountry: (data) => dispatch(getCountry(data)),
  getBuyer: (data) => dispatch(getBuyer(data)),
  verifyCompany: (data) => dispatch(verifyCompany(data)),
  getCompanyById: (data) => dispatch(getCompanyById(data)),
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  getCompaniesByCreatedByAndApproved: (data) =>
    dispatch(getCompaniesByCreatedByAndApproved(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BuyerVerification));

import {URI} from '../../constant'

export const HOME_GET_USER_WALLET_BY_ID_CONSTANTS = ({
    URL: URI+"/user-wallet/",
    REQUEST: 'REQUEST_GET_USER_WALLET_BY_ID_CONSTANTS',
    RECEIVED: 'RECEIVED_GET_USER_WALLET_BY_ID_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_GET_USER_WALLET_BY_ID_CONSTANTS',
  });

  export const HOME_POST_USER_WALLET_CONSTANTS = ({
    URL: URI+"/user-wallet/",
    REQUEST: 'REQUEST_POST_USER_WALLET_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_POST_USER_WALLET_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_POST_USER_WALLET_DATA_CONSTANTS',
  });

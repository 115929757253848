import { get, isEmpty, cloneDeep } from "lodash";
import axios from "axios";
import qs from "qs";
import * as REWARD_CONSTANTS from "../constants/rewardConstants";
import ajax from "../../utils/ajax";

export const requestgetReward = () => ({
  type: REWARD_CONSTANTS.HOME_GET_REWARD_CONSTANTS.REQUEST,
});
export const getReward = () => async (dispatch) => {
  dispatch(requestgetReward());
  try {
    let url = REWARD_CONSTANTS.HOME_GET_REWARD_CONSTANTS.URL;
    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedgetRewardResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedgetRewardError(err));
    return Promise.reject(err);
  }
};
export const recievedgetRewardResponse = (data) => ({
  type: REWARD_CONSTANTS.HOME_GET_REWARD_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});

export const recievedgetRewardError = (err) => ({
  type: REWARD_CONSTANTS.HOME_GET_REWARD_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

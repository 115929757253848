import React, { Component } from "react";
import moment from "moment";
import Paginations from "../../pagination";
import ajax from "../../../utils/ajax";
import { URI } from "../../../constant";
import { getAllInvoices } from "../../../services/actions/invoiceAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export class InvoiceTabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paginationItem: "",
      currentStatus: "",
      invoices: [],
    };
  }

  componentDidMount = () => {
    this.invoiceData();
  };

  //get invoice data when search and filter updated
  componentDidUpdate = async (prevProps) => {
    if (
      prevProps.searchInvoice !== this.props.searchInvoice ||
      prevProps.selectedStatus.length !== this.props.selectedStatus.length
    ) {
      this.invoiceData();
    }
  };

  //get invoice data list
  invoiceData = async (curentPageNumber) => {
    let currentPage = curentPageNumber;
    if (!curentPageNumber) {
      currentPage = 1;
    }
    const { selectedStatus, searchInvoice } = this.props;
    const searcData = await ajax(
      "GET",
      `${URI}/invoice/search?query=${searchInvoice}&invoice_status_list=${
        selectedStatus.length > 0 ? selectedStatus : []
      }&page=${currentPage}`,
      {}
    );
    if (searcData?.data?.data) {
      this.setState({
        invoices: searcData.data.data,
        paginationItem: searcData.data,
      });
    }
  };

  //handle page change
  pageChange = async (index) => {
    this.invoiceData(index);
  };

  onView = (item) => {
    this.setState(
      {
        currentStatus: item.invoiceStatus_id.key,
      },
      () => {
        sessionStorage.setItem(
          "current_page",
          this.state.paginationItem.paginator.page
        );
        this.props.callInvoice(this.state.currentStatus, item._id);
      }
    );
  };

  render() {
    return (
      <div className="card-plain table-plain-bg">
        <div className="table-full-width table-responsive px-0">
          <table className="border-0">
            <thead className="disp_non_sm">
              <tr className="text_tra1 ">
                <th>#Invoice</th>
                <th>Client company</th>
                <th>Buyer</th>
                <th>Contact Person</th>
                <th>Invoice Date</th>
                <th>Due Date</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {this.state.invoices !== undefined
                ? this.state.invoices.map((item, i) => (
                    <tr className="sec1" key={i}>
                      <td># {item.invoice_id}</td>
                      <td>
                        {" "}
                        {item.client_company
                          ? item.client_company.company_name
                          : ""}
                      </td>
                      <td>
                        {" "}
                        {item.buyer_company
                          ? item.buyer_company.company_name
                          : ""}
                      </td>
                      <td> {item.full_name}</td>
                      <td>{moment(item.invoice_date).format("DD MMM YYYY")}</td>
                      <td>
                        {moment(item.invoice_due_date).format("DD MMM YYYY")}
                      </td>

                      <td>
                        {item.currency}{" "}
                        {Number(item.invoice_amount)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </td>
                      <td>
                        {item.invoiceStatus_id !== undefined
                          ? item.invoiceStatus_id.subStatus !== undefined
                            ? item.invoiceStatus_id.subStatus.map((sub) =>
                                sub._id === item.invoiceSubStatus_id ? (
                                  <span
                                    style={{
                                      color: sub.textColor,
                                      padding: "10px",
                                      backgroundColor: sub.backgroundColor,
                                      borderRadius: "4px",
                                    }}
                                  >
                                    {sub.statusName === "Declined"
                                      ? "DECLINED"
                                      : item.invoiceStatus_id.displayName.toUpperCase()}
                                  </span>
                                ) : (
                                  ""
                                )
                              )
                            : ""
                          : ""}
                      </td>
                      <td></td>
                      <td>
                        <label
                          className="uploadb"
                          onClick={() => this.onView(item)}
                        >
                          View More
                        </label>
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
          <div className="d-flex justify-content-end mx-4 mt-2">
            <Paginations
              item={this.state.paginationItem}
              pageClick={this.pageChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  invoice: state.invoice,
});

const mapDispatchToProps = (dispatch) => ({
  getAllInvoices: (data) => dispatch(getAllInvoices(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvoiceTabel));

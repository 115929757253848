import React, { Component } from "react";
import preview from "../../../../assets/images/preview.png";
import { authenticateAdmin } from "../../../../api/authenticate";
import CONSTANTS from "../../../../utils/constant";
import { toast } from "react-toastify";
import helper from "../../../../utils/helper";

/**
 * This component returns unlock card UI
 * @param {*} props
 * @returns
 */
class UnlockCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "password",
      unlockData: { email: "", password: "" },
      emailError: "",
      passwordError: "",
    };
  }

  handleShow = () => {
    this.setState({
      type: this.state.type === "text" ? "password" : "text",
    });
  };

  validate = () => {
    if (!helper.isValidEmail(this.state.unlockData.email)) {
      this.setState({
        emailError: "Please Enter valid Email Id",
      });
      return false;
    }

    if (!helper.isValidPassword(this.state.unlockData.password)) {
      this.setState({
        passwordError:
          "Password must contain min 8 letter, with at least a symbol, upper and lower case letters and a number",
      });
      return false;
    }

    this.setState({
      emailError: "",
      passwordError: "",
    });

    return true;
  };

  submitUnlock = async (e) => {
    e.preventDefault();
    if (this.validate()) {
      const userData = await authenticateAdmin(this.state.unlockData);
      if (userData.status === CONSTANTS.STATUS.OK) {
        toast.info("Unlocked Successfully");
        this.props.unlock();
        this.props.triggerFunction();
      } else {
        toast.error("Invalid Credentials");
      }
    }
  };

  handleUnlock = (e) => {
    const { name, value } = e.target;
    const { unlockData } = this.state;
    unlockData[name] = value;
    this.setState({
      unlockData: unlockData,
    });
  };
  render() {
    return (
      <>
        <div className={`menuNav ${this.props.lock ? "showMenu1" : ""}`}>
          <div
            style={{ borderBottom: "1px solid gray" }}
            className="d-flex justify-content-between text-white p-3"
          >
            <h5>Unlock to make changes</h5>
            <h5
              style={{ cursor: "pointer" }}
              onClick={() => this.props.unlock()}
            >
              <i className="uil-multiply"></i>
            </h5>
          </div>
          <div className="text_tra1  p-3">
            <i className="uil-info-circle"></i>
            <p>
              To unlock this feature you need to enter your username and
              password
            </p>
          </div>

          <div className="w-75 m-5 text_tra1">
            <label>Email</label>
            <input
              className="input"
              name="email"
              placeholder="Enter your email"
              value={this.state.unlockData.email}
              onChange={this.handleUnlock}
            />
            <div className="text-danger">
              {this.state.emailError ? this.state.emailError : ""}
            </div>
          </div>
          <div className="m-5 w-75 text_tra1">
            <label>password</label>
            <div className=" d-flex justify-content-end">
              <img
                style={{
                  position: "absolute",
                  margin: "16px 9px 7px auto",
                  color: "#fff",
                  cursor: "pointer",
                }}
                src={preview}
                onClick={this.handleShow}
                alt="preview"
              />
              <input
                className="input"
                name="password"
                type={this.state.type}
                placeholder="Enter your Password"
                value={this.state.unlockData.password}
                onChange={this.handleUnlock}
              />
            </div>

            <div className="text-danger">
              {this.state.passwordError ? this.state.passwordError : ""}
            </div>
          </div>
          <div className="text-center">
            <button className="btn-blue" onClick={this.submitUnlock}>
              Unlock
            </button>
          </div>
        </div>
      </>
    );
  }
}
export default UnlockCard;

import React from "react";
import helper from "../../../../utils/helper";

/**
 * This component returns final offer details card UI
 * @param {*} props
 * @returns
 */
export default function FinalOfferDetailsCard(props) {
  return (
    <>
      <div className="row mt-4  text_tra1">
        <div className="col-4">
          Invoice Limit{" "}
          <h5 className="text-white">
            $ {helper.formatNumber(props.finaloffer.amount)}
          </h5>
        </div>
        <div className="col-4">
          Factoring Fee
          <h5 className="text-white">
            ${" "}
            {props.finaloffer
              ? helper.formatNumber(props.finaloffer.factoring_amount)
              : ""}{" "}
            ({helper.formatNumber(props.finaloffer.factoring_fee)}
            %)
          </h5>
        </div>
        <div className="col-4">
          Discounting Fee
          <h5 className="text-white">
            ${" "}
            {props.finaloffer
              ? helper.formatNumber(props.finaloffer.discount_amount)
              : ""}{" "}
            ({helper.formatNumber(props.finaloffer.discount)}%)
          </h5>
        </div>
      </div>
      <div className="row mt-4  text_tra1">
        <div className="col-4">
          Amount to be Funded{" "}
          <h5 className="text-white">
            $ {helper.formatNumber(props.finaloffer.amount_to_be_funded)}
          </h5>
        </div>
        <div className="col-4">
          Grace Period
          <h5 className="text-white">{props.finaloffer.grace_period} Days</h5>
        </div>
        <div className="col-4">
          Bank charges
          <h5 className="text-white">${props.finaloffer.bank_charges || 0}</h5>
        </div>
      </div>
      <div className="row mt-4  text_tra1">
        <div className="col-4">
          Pricing ID
          <h5 className="text-white">{props.finaloffer.pricing_id}</h5>
        </div>
        <div className="col-4">
          Reserve ratio
          <h5 className="text-white">{props.finaloffer.reserve_percentage}%</h5>
        </div>
        <div className="col-4">
          Final Offer Tenure
          <h5 className="text-white">{props.finaloffer.tenure} Days</h5>
        </div>
      </div>
      <div className="row mt-4  text_tra1">
        <div className="col-4">
          Late Fees
          <h5 className="text-white">{props.finaloffer.late_fee}%</h5>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { rejectInvoiceByAdmin } from "../../../api/invoiceService";
import { toast } from "react-toastify";

/**
 * This component returns decline button.
 * @param {*} props
 * @returns
 */

export default function DeclineInvoice(props) {
  const [declineStatus, setDeclineStatus] = useState("");
  const [declineSubStatus, setDeclineSubStatus] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");

  useEffect(() => {
    for (let j = 0; j < props.invoiceStatus.length; j++) {
      if (props.invoiceStatus[j].key === props.currentStatusKey) {
        for (let i = 0; i < props.invoiceStatus[j].subStatus.length; i++) {
          if (props.invoiceStatus[j].subStatus[i].statusName === "Declined") {
            setDeclineStatus(props.invoiceStatus[j]._id);
            setDeclineSubStatus(props.invoiceStatus[j].subStatus[i]._id);
            setCurrentStatus(props.invoiceStatus[j]._id);
            break;
          }
        }
      }
    }
  }, [props, props.invoiceStatus, props.nextStatusKey]);

  const decline = (invoice) => {
    let data = {
      invoiceStatus_id: declineStatus,
      invoiceSubStatus_id: declineSubStatus,
      update_type: props.updateType,
      _id: invoice._id,
    };
    rejectInvoiceByAdmin(data)
      .then(() => {
        toast.error(props.toastMessage);
        window.location.reload();
      })
      .catch((err) => {});
  };

  return (
    <>
      <span>
        {props.invoice.invoiceStatus_id._id === currentStatus && (
          <button
            style={{ background: "#E9556E" }}
            className="btn-blue mx-2"
            onClick={() => decline(props.invoice)}
          >
            Decline
          </button>
        )}
      </span>
    </>
  );
}

import React, { Component } from "react";
import Sidebar from "./sidebar";
import MainHeader from "../mainHeader";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getLenders } from "../../../services/actions/userAction";
import { getKycStatus } from "../../../services/actions/kycStatusAction";
import { getCoins } from "../../../services/actions/coinActions";
import {
  getLenderById,
  updateUser,
} from "../../../services/actions/userAction";
import _isEmpty from "lodash/isEmpty";
import { USER_TYPE, WALLET_ACTIVATION } from "../../../config/string_constants";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paginations from "../../pagination";
import moment from "moment";
class VenlyWallet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activate: false,
      lenders: [],
      paginationItem: [],
      lenderDetails: [],
      searchLender: "",
      viewWallet: false,
      updateSucess: false,
    };
  }
  handleClose = () => {
    this.setState({
      viewWallet: false,
      activate: false,
    });
  };
  viewMore = (id) => {
    this.props
      .getLenderById(id)
      .then((result) => {
        this.setState({
          activate: true,
          viewWallet: true,
          lenderDetails: result,
        });
      })
      .catch((err) => {});
  };
  activateWallet = (id) => {
    this.props
      .getLenderById(id)
      .then((result) => {
        this.setState({
          activate: true,
          lenderDetails: result,
        });
      })
      .catch((err) => {});
  };
  handleChange = (e) => {
    this.setState({
      searchLender: e.target.value,
    });
  };
  Activate = () => {
    const { lenderDetails } = this.state;
    lenderDetails.wallet_activation = WALLET_ACTIVATION.YES;
    this.setState({
      lenderDetails: lenderDetails,
      updateSucess: true,
    });

    this.props.updateUser(this.state.lenderDetails);
  };
  Deactivate = () => {
    const { lenderDetails } = this.state;
    lenderDetails.wallet_activation = WALLET_ACTIVATION.NO;
    this.setState({
      lenderDetails: lenderDetails,
      updateSucess: true,
    });

    this.props.updateUser(this.state.lenderDetails);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_isEmpty(nextProps.user.updateSucess) && this.state.updateSucess) {
      toast.info(" updated Successfully");

      this.setState({
        updateSucess: false,
        activate: false,
      });
    }
  }
  pageChange = async (index) => {
    this.props
      .getLenders(index)
      .then((result) => {
      
        this.setState({
          lenders: result.data,
          paginationItem: result,
        });
      })
      .catch((err) => {});
  };
  componentDidMount() {
    //TODO refactor this
    this.props
      .getLenders()
      .then((result) => {
      
        let finalData = result.data.filter((data) => {
          if (
            data.wallet_activation === WALLET_ACTIVATION.YES ||
            data.wallet_activation === WALLET_ACTIVATION.NO
          ) {
            return data;
          }
        });

        this.setState({
          lenders: finalData,
          paginationItem: result,
        });
      })
      .catch((err) => {});
  }

  render() {
    return (
      <div>
        <Sidebar />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="main-content">
          <header>
            <div className="dropdown show m-4">
              <a
                className="admin_button dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="pr-4">LENDER DASHBOARD</span>
              </a>

              <div
                className="admin_button dropdown-menu"
                aria-labelledby="dropdownMenuLink"
              >
                <Link to="/dashboard" className="text-decoration-none ">
                  <a className="text-white dropdown-item" href="#">
                    CLIENT DASHBOARD
                  </a>
                </Link>
              </div>
            </div>
            <MainHeader />
          </header>
          {/*main content*/}

          <main>
            <div className="d-flex w-100 justify-content-between ">
              <span className="mr-auto my-2 mr-4 search d-flex justify-content-end">
                <i
                  style={{
                    position: "absolute",
                    margin: "8px 5px 7px auto",
                    color: "#fff",
                  }}
                  className="uil-search-alt"
                ></i>

                <input
                  className="homesearchbox"
                  type="text"
                  placeholder="Search..."
                  name="searchLender"
                  value={this.state.searchLender}
                  onChange={this.handleChange}
                />
              </span>
            </div>
            <div className="table-full-width mt-3 table-responsive px-0">
              <table className="border-0">
                <thead className="disp_non_sm">
                  <tr className="text_tra1 ">
                    <th className="pl-2">Wallet Address</th>
                    <th className="pl-2">Lender Email</th>
                    <th className="pl-2">Total Deposit</th>
                    <th className="pl-2">
                      Wallet Activation
                      <i
                        className="uil-info-circle ml-1"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="User minimum deposit must be $100 to activate the  wallet"
                      ></i>
                    </th>
                    <th className="pl-2">Created On</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.lenders === undefined ? (
                    <tr>No Lenders</tr>
                  ) : (
                    this.state.lenders
                      .filter((data) => {
                        return data.email
                          .toLowerCase()
                          .includes(this.state.searchLender.toLowerCase());
                      })
                      .map((lender, i) => (
                        <tr key={i} className="sec1 ">
                          <td className="font-style-3">
                            {lender
                              ? lender.wallet_details !== "{}"
                                ? lender.wallet_address
                                : "-"
                              : "-"}
                          </td>
                          <td>{lender.email}</td>

                          {lender.lending_data > 0 ? (
                            <td>
                              $
                              {lender.lending_data.reduce(
                                (total, listValue) =>
                                  total + parseInt(listValue.total_amount),
                                0
                              )}
                            </td>
                          ) : (
                            <td>-</td>
                          )}
                          {lender.wallet_activation ===
                          WALLET_ACTIVATION.YES ? (
                            <td className="txt_b">
                              {lender.wallet_activation}
                            </td>
                          ) : (
                            <td className="txt_red">No</td>
                          )}
                          <td>
                            {moment(lender.createdAt).format("DD MMM YYYY")}
                          </td>
                        </tr>
                      ))
                  )}
                </tbody>
              </table>
              <div className="d-flex justify-content-end mx-4 mt-2">
                <Paginations
                  item={this.state.paginationItem}
                  pageClick={this.pageChange}
                />
              </div>
            </div>
          </main>
          <div
            style={{ overflowY: "auto" }}
            className={`menuNav ${this.state.activate ? "showMenu1" : ""}`}
          >
            <div
              style={{ borderBottom: "1px solid gray" }}
              className="d-flex justify-content-between text-white p-3 pl-1"
            >
              <div>
                <h5>{this.state.lenderDetails.email}</h5>
              </div>
              <h5 style={{ cursor: "pointer" }} onClick={this.handleClose}>
                <i className="uil-multiply" />
              </h5>
            </div>
            <div className="row m-3">
              <div className="col-6">
                <div className="text_tra1">Total Deposit</div>
                <h5 className="text-white">
                  {this.state.lenderDetails.lending_data !== undefined
                    ? this.state.lenderDetails.lending_data.reduce(
                        (total, listValue) =>
                          total + parseInt(listValue.total_amount),
                        0
                      )
                    : ""}
                </h5>
              </div>
              <div className="col-6">
                <div className="text_tra1">Total Lending</div>
                <h5 className="text-white">
                  {this.state.lenderDetails.lending_data !== undefined
                    ? this.state.lenderDetails.lending_data.length
                    : ""}
                </h5>
              </div>
              <div className="col-6 mt-2">
                <div className="text_tra1">Active Lending</div>
                <h5 className="text-white">
                  {this.state.lenderDetails.lending_data !== undefined
                    ? this.state.lenderDetails.lending_data.length
                    : ""}
                </h5>
              </div>
            </div>
            <div className="mx-3 mb-2" style={{ marginTop: "20rem" }}>
              {this.state.viewWallet ? (
                <button className="btn-blue w-100 " onClick={this.Deactivate}>
                  Deactivate Wallet{" "}
                </button>
              ) : (
                <button
                  className="btn-blue w-100 d-none "
                  onClick={this.Activate}
                >
                  Activate Confirm
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  kycStatus: state.kycStatus,
  coin: state.coin,
});

const mapDispatchToProps = (dispatch) => ({
  getKycStatus: (data) => dispatch(getKycStatus(data)),
  getLenders: (data) => dispatch(getLenders(data)),
  getCoins: (data) => dispatch(getCoins(data)),
  getLenderById: (data) => dispatch(getLenderById(data)),
  updateUser: (data) => dispatch(updateUser(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VenlyWallet));

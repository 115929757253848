const CONSTANTS = {
  ACTION: {
    VIEW: "view",
    EDIT: "edit",
    DELETE: "delete",
    ADD: "add",
  },

  ENVIRONMENT_VAR: {
    LOCAL: "local",
    DEVELOPMENT: "development",
    STAGING: "staging",
    PRODUCTION: "production",
  },

  PRODUCT_GENDER_DATA: [
    { value: "MALE", label: "Male" },
    { value: "FEMALE", label: "Female" },
    { value: "UNISEX", label: "Unisex" },
  ],

  API: {
    USER: "/api/v1/user",
  },
  CLIENT_STATUS: {
    PENDING: "Pending",
    IN_REVIEW: "In Review",
    APPROVED: "Approved",
    REJECTED: "Rejected",
    DEACTIVATED: "Deactivated",
    DISCARDED: "Discarded",
    UPLOADED: "Uploaded",
  },

  PATHS: {
    DEFAULT: "/",
  },
  API_METHODS: {
    GET: "GET",
    POST: "POST",
    DELETE: "DELETE",
    PUT: "PUT",
  },
  STATUS: {
    OK: 200,
    CREATED: 201,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    DATA_NOT_FOUND: 404,
    DUPLICATE_RECORD: 409,
    INTERNAL_SERVER_ERROR: 500,
    NOT_APPLICABLE: 406,
    BAD_REQUEST: 400,
    UNPROCESSABLE_ENTITY: 422,
    TOO_MANY_REQUESTS: 429,
  },
  MESSAGES: {
    LOGIN_SUCCESS: "Login Success",
    ERROR: "Error",
    INVALID_CREDENTIALS: "Invalid Credentials",
    INVALID_TOKEN: "Invalid Token",
    INVALID_REQUEST: "Invalid Request",
    UNAUTHORIZED: "Unauthorized",
    FORBIDDEN: "Forbidden",
    TOO_MANY_REQUESTS: "Too Many Requests",
    INTERNAL_SERVER_ERROR: "Internal Server Error",
    DISCLAIMER: "As a Lender on Polytrade V1, the USDC interest earned on your deposit has been returned to your designated wallet address. We apologize for any inconvenience caused by a technical issue that made the lender pool continue calculating Accrued Interest beyond the end of the locking period. Our development team has resolved this issue.",
  },
  
  CURRENT_DEPOSIT: 0,

  ROLE: {
    ADMIN: "ADMIN",
    CLIENT: "CLIENT",
    LENDER: "LENDER",
  },

  STYLES: {
    DEFAULT_BUTTON: "standardButton",
    DEFAULT_ENTRY: "standardEntry",
    COLOR: {
      BUTTON: {
        DARK_BUTTON: "standardDarkButton",
        LIGHT_BUTTON: "standardLightButton",
      },
    },
  },
};

export default CONSTANTS;

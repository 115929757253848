import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import preview from "../../../assets/images/preview.png";
import moment from "moment";
import {
  getInvoiceDetailsById,
  getAllInvoices,
  getInvoiceChanges,
  getInvoiceCreatedByUser,
} from "../../../services/actions/invoiceAction";
import { updateInvoice } from "../../../services/actions/invoiceAction";
import { getInvoiceStatus } from "../../../services/actions/invoiceStatusAction";
import {
  getOfferById,
  postOffer,
} from "../../../services/actions/invoiceOfferAction";
import { postAlert } from "../../../services/actions/alertActions";
import Modal from "react-bootstrap/Modal";
import InvoiceMoreDetail from "./invoiceMoreDetail";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Progressbar from "./progressBar";
import InvoicesListId from "./invoicesListId";
import Header from "./header";
import { STATUSES } from "../../../config/string_constants";
import {
  INVOICE_STATUSES_KEY,
  CLIENT_ALERT_TYPES,
  INVOICE_SUB_STATUSES,
} from "../../../config/string_constants";
import BuyerDetails from "./buyerDetails";
import {
  getInvoiceForAdmin,
  approveInvoiceByAdmin,
} from "../../../api/invoiceService";
import SoftOffer from "./softOfferDetails";
import InvoiceData from "./invoiceData";
import DeclineInvoice from "./declineInvoice";
import helper from "../../../utils/helper";
import UnlockCard from "./sideCard/unlockCard";

class OfferMade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "password",
      lock: false,
      generateOffer: false,
      buyerCompany: "",
      previewOffer: false,
      invoiceId: this.props.match.params.id,
      invoicedetails: [],
      invoiceOffer: [],
      invoices: [],
      invoiceCompany: [],
      company_id: "",
      showPdf: false,
      pdfUrl: "",
      selected: false,
      articleId: "",
      invoiceAmount: 0,
      advanceRatioAmount: 0,
      factoring_fee: { percentage: 0, amount: 0 },
      discount: { percentage: 0, amount: 0 },
      advance_ratio: { percentage: 85, amount: "" },
      tenure: 0,
      tenureError: false,
      regenerate_request: false,
      offerData: {
        invoice_id: "",
        invoice_ref_no: "",
        invoice_amount: "",
        factoring_fee: "",
        discount: "",
        advance_ratio: "",
        tenure: "",
        expire_date: "",
        expired: STATUSES.ACTIVE,
        regenerate_request: false,
        comment: "",
      },
      offerSuccess: false,
      unlockData: { email: "", password: "" },
      dirty: { email: "", password: "" },
      errors: { email: [], password: [] },
      locked: true,
      offerStatus: "",
      offerSubStatus: "",
      alertData: {
        invoice_id: "",
        invoice_ref_no: "",
        invoice_status_id: "",
        invoice_substatus_id: "",
        client_Id: "",
      },
      updateSuccess: false,
      alertSuccess: false,
      screen: INVOICE_STATUSES_KEY.OFFER_MADE,
    };
  }

  componentDidMount() {
    this.props
      .getInvoiceDetailsById(this.state.invoiceId)
      .then((result) => {
        this.props.getInvoiceChanges(result);
        this.setState(
          {
            invoicedetails: result,
            company_id: result.buyer_company._id,
            selected: true,
            articleId: result._id,
            invoiceCompany: result.client_company,
            buyerCompany: result.buyer_company,
          },
          () => {
            this.setState({
              advance_ratio: {
                ...this.state.advance_ratio,
                regenerate_request: true,
                amount:
                  ((this.state.invoicedetails
                    ? this.state.invoicedetails.invoice_limit
                    : 0) *
                    this.state.advance_ratio.percentage) /
                  100,
              },
              advanceRatioAmount:
                ((this.state.invoicedetails
                  ? this.state.invoicedetails.invoice_limit
                  : 0) *
                  this.state.advance_ratio.percentage) /
                100,
              tenure: moment(this.state.invoicedetails.invoice_due_date).diff(
                moment().format("YYYY-MM-DD"),
                "days"
              ),
            });
          }
        );

        getInvoiceForAdmin(result.created_by_id)
          .then((invoices) => {
            //Get company details of particular invoice

            this.setState({
              invoices: invoices.data,
            });
          })
          .catch((err) => {
            return err;
          });
      })
      .catch((err) => {
        return err;
      });

    //Get Offer Details
    this.props
      .getOfferById(this.state.invoiceId)
      .then((result) => {
        this.setState(
          {
            invoiceOffer: result,
            factoring_fee: {
              percentage: result.factoring_fee.percentage,
              amount: result.factoring_fee.percentage,
            },
            regenerate_request: true,
            discount: {
              percentage: result.discount.percentage,
              amount: result.discount.percentage,
            },
            advance_ratio: {
              percentage: result.advance_ratio.percentage,
              amount: result.advance_ratio.percentage,
            },
          },
          () => {}
        );
      })
      .catch((err) => {
        return err;
      });

    let updateStatus =
      this.props.invoiceStatus.length !== undefined
        ? this.props.invoiceStatus.map((status) =>
            status.key === INVOICE_STATUSES_KEY.OFFER_MADE
              ? status.subStatus !== undefined
                ? status.subStatus.map((sub) =>
                    sub.statusName === INVOICE_SUB_STATUSES.CREATED
                      ? this.setState({
                          invoicestatus: this.props.invoiceStatus,
                          offerStatus: status._id,
                          offerSubStatus: sub._id,
                        })
                      : ""
                  )
                : ""
              : ""
          )
        : "";
  }

  showData = (item) => {
    this.setState({
      invoiceId: item._id,
      company_id: item.buyer_company._id,
      selected: true,
      articleId: item._id,
      invoiceCompany: item.client_company,
      invoicedetails: item,
    });
    this.props
      .getOfferById(item.invoice_id)
      .then((result) => {
        this.setState({
          invoiceOffer: result,
        });
      })
      .catch((err) => {
        return err;
      });
  };

  onPdfShow = (url) => {
    this.setState({
      showPdf: true,
      pdfUrl: url,
    });
  };

  blurChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };

  handleClose = () => {
    this.setState({ showPdf: false });
  };

  closeOffer = () => {
    this.setState({
      generateOffer: false,
    });
  };

  generateOffer = () => {
    this.setState({
      generateOffer: true,
      invoiceOffer: this.state.invoices.filter((item) => {
        return item.invoice_id === this.state.invoicedetails.invoice_id;
      })[0],
      invoiceAmount: this.state.invoices.filter((item) => {
        return item.invoice_id === this.state.invoicedetails.invoice_id;
      })[0].invoice_limit,
    });
  };

  handleFactoringFeeChange = (e) => {
    const factoringFee = e.target.value;
    this.setState({
      factoring_fee: {
        ...this.state.factoring_fee,
        percentage: factoringFee,
        amount: (this.state.invoiceOffer.invoice_amount * factoringFee) / 100,
      },
    });
  };
  handleDiscountChange = (e) => {
    const discountingFee = e.target.value;
    this.setState({
      regenerate_request: true,
      discount: {
        ...this.state.discount,
        percentage: discountingFee,
        amount: (
          ((this.state.advanceRatioAmount * discountingFee) / 100 / 365) *
          this.state.tenure
        ).toFixed(2),
      },
    });
  };
  handleAdvanceRatioChange = (e) => {
    this.setState(
      {
        advance_ratio: {
          ...this.state.advance_ratio,
          percentage: e.target.value,
          amount:
            (this.state.invoiceOffer.invoice_limit * e.target.value) / 100,
        },
        advanceRatioAmount:
          (this.state.invoiceOffer.invoice_limit * e.target.value) / 100,
      },
      () => {
        this.setState({
          regenerate_request: true,
          discount: {
            ...this.state.discount,
            amount: (
              ((this.state.advanceRatioAmount *
                this.state.discount.percentage) /
                100 /
                365) *
              this.state.tenure
            ).toFixed(2),
          },
        });
      }
    );
  };

  tenureChange = (e) => {
    this.setState(
      {
        tenure: e.target.value,
      },
      () => {
        if (
          e.target.value >
          moment(this.state.invoicedetails.invoice_due_date)
            .startOf("day")
            .diff(moment().startOf("day"), "days")
        ) {
          this.setState({ tenureError: true });
        } else {
          this.setState({ tenureError: false });
        }
      },
      () => {
        this.setState({
          regenerate_request: true,
          discount: {
            ...this.state.discount,

            amount: (
              ((this.state.advanceRatioAmount *
                this.state.discount.percentage) /
                100 /
                365) *
              this.state.tenure
            ).toFixed(2),
          },
        });
      }
    );
  };

  handleComment = (e) => {
    const { value } = e.target;
    let comment = value;
    this.setState({
      offerData: {
        comment: comment,
      },
    });
  };
  createOffer = (e) => {
    this.setState({
      offerData: {
        ...this.state.offerData,
        invoice_id: this.state.invoiceOffer._id,
        invoice_ref_no: this.state.invoiceOffer.invoice_id,
        invoice_amount: this.state.invoiceOffer.invoice_amount,
        invoice_limit: this.state.invoiceOffer.invoice_limit,
        factoring_fee: this.state.factoring_fee,
        discount: this.state.discount,
        advance_ratio: this.state.advance_ratio,
        tenure: this.state.tenure,
        expired: STATUSES.ACTIVE,
        expire_date: new Date(new Date().getTime() + 60 * 60 * 24 * 1000),
        regenerate_request: false,
      },
    });
    this.setState(
      {
        offerSuccess: true,
      },
      () => {
        this.props
          .postOffer(this.state.offerData)
          .then((result) => {
            toast.info("Offer Generated Successfully");
            let data = {
              invoiceStatus_id: this.state.offerStatus,
              invoiceSubStatus_id: this.state.offerSubStatus,
              update_type: CLIENT_ALERT_TYPES.OFFER_CREATED,
              _id: this.state.invoicedetails._id,
            };

            this.setState(
              {
                updateSuccess: true,
              },
              () => {
                approveInvoiceByAdmin(data)
                  .then((result) => {
                    this.setState(
                      {
                        offerSuccess: false,
                        generateOffer: false,
                      },
                      () => {
                        this.props
                          .getOfferById(this.state.invoiceId)
                          .then((offer) => {
                            this.setState({
                              invoiceOffer: offer,
                            });
                          })
                          .catch((err) => {
                            return err;
                          });
                      }
                    );
                  })
                  .catch((err) => {
                    return err;
                  });
              }
            );
          })
          .catch((err) => {
            toast.info(err.msg);
          });
      }
    );
  };

  render() {
    return (
      <div>
        <Header />
        <div className="row m-4">
          <div className="col-3">
            <InvoiceMoreDetail
              invoiceCompany={this.state.invoiceCompany}
              invoicedetails={this.state.invoicedetails}
            />
          </div>
          <div className="col-9 overflow-auto">
            <Progressbar
              invoiceId={this.state.invoiceId}
              screen={this.state.screen}
            />
            <UnlockCard
              lock={this.state.lock}
              unlock={() => this.setState({ lock: false })}
              triggerFunction={() => this.setState({ locked: false })}
            />
          </div>
        </div>
        <div className="row m-2 my-4">
          <div className="col-6">
            <div className="info-one h-100">
              <div className="tab-content">
                <div className="tab-pane active" id="tabs-1" role="tabpanel">
                  {this.state.invoices === undefined ? (
                    <div>No data Available </div>
                  ) : (
                    this.state.invoices
                      .filter((item) => {
                        return (
                          item.invoice_id ===
                          this.state.invoicedetails.invoice_id
                        );
                      })
                      .map((item, index) => (
                        <div key={index}>
                          <InvoiceData
                            invoices={item}
                            tenure={this.state.invoiceOffer?.tenure}
                            onPdfShow={this.onPdfShow}
                          />
                          <div className="mt-5 text-center">
                            <DeclineInvoice
                              invoice={item}
                              toastMessage="Soft Offer Declined"
                              updateType={CLIENT_ALERT_TYPES.OFFER_MADE}
                              invoiceStatus={this.props.invoiceStatus}
                              currentStatusKey={INVOICE_STATUSES_KEY.OFFER_MADE}
                            />
                          </div>
                        </div>
                      ))
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="info-one h-100">
              <p className="text_tra1 mb-2">INVOICES</p>
              <InvoicesListId
                invoices={this.state.invoices}
                selected={this.state.selected}
                showData={this.showData}
                itemId={this.state.articleId}
              />
              <BuyerDetails buyerDetails={this.state.buyerCompany} />
              {this.state.invoiceOffer.length === 0 ? (
                <div style={{ marginTop: "2rem" }} className="p-3  bgc-dark">
                  <p className="text_tra1">Offer Generate Pending</p>
                </div>
              ) : (
                <div>
                  {this.state.invoiceOffer.regenerate_request ? (
                    <p
                      style={{ marginTop: "2rem" }}
                      className="p-3  bgc-dark text_tra1"
                    >
                      Offer Expired!!! Client has requested for a new Offer
                    </p>
                  ) : (
                    <SoftOffer
                      invoiceOffer={this.state.invoiceOffer}
                      show={true}
                    />
                  )}
                </div>
              )}

              <div className="p-3 d-flex my-2 justify-content-between bgc-dark">
                <div className="text-center">
                  <i className="txt_b uil-cloud" />
                  <p className="text_tra1">Upload Report</p>
                </div>
                <div>
                  <span className=" mx-3 ">
                    <button
                      className="btn-blue "
                      disabled={
                        this.state.locked
                          ? this.state.invoiceOffer
                            ? true
                            : true
                          : false
                      }
                      onClick={this.generateOffer}
                    >
                      {this.state.regenerate_request ? (
                        <span>Re-Generate Offer</span>
                      ) : (
                        <span>Generate Offer</span>
                      )}
                    </button>
                  </span>
                  {this.state.locked ? (
                    <i
                      className="txt_b uil-lock-alt font25"
                      onClick={() => this.setState({ lock: true })}
                    />
                  ) : (
                    <i
                      className="txt_b uil-unlock font25"
                      onClick={() => this.setState({ lock: true })}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ overflowY: "auto" }}
            className={`menuNav ${this.state.generateOffer ? "showMenu1" : ""}`}
          >
            <div
              style={{ borderBottom: "1px solid gray" }}
              className="d-flex justify-content-between text-white p-3"
            >
              <div>
                <h5>Generate Offer</h5>
                <p className="text_tra1">Invoice #{this.state.invoiceId}</p>
              </div>
              <h5 style={{ cursor: "pointer" }} onClick={this.closeOffer}>
                <i className="uil-multiply"></i>
              </h5>
            </div>

            <div className="bgc-dark text-center p-3 mx-5 my-4">
              <span className="text_tra1">Invoice Limit</span>
              <h4 className="text-white">
                $
                {this.state.invoiceOffer
                  ? this.state.invoiceOffer.invoice_limit
                    ? Number(this.state.invoiceOffer.invoice_limit)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    : 0
                  : ""}
              </h4>
            </div>
            <div className="mx-3 my-2 ">
              <label className="text_tra1">
                Factoring Fee <i className="uil-info-circle" />
              </label>
              <p className="float-right text-white">
                ${this.state.factoring_fee.amount}{" "}
              </p>
              <div>
                <input
                  type="range"
                  style={{ width: "90%" }}
                  name="fee"
                  min="0"
                  max="10"
                  step="0.1"
                  title={this.state.factoring_fee.percentage}
                  value={this.state.factoring_fee.percentage}
                  onChange={this.handleFactoringFeeChange}
                />
                <span className="text_tra1">
                  {" "}
                  {this.state.factoring_fee.percentage}%{" "}
                </span>
              </div>
            </div>
            <div className="mx-3 my-2 ">
              <label className="text_tra1">
                Discounting Fee <i className="uil-info-circle" />
              </label>
              <p className="float-right text-white">
                $
                {helper.discountFeeHandler(
                  +this.state.discount.percentage,
                  +this.state.advance_ratio?.amount,
                  +this.state.tenure
                )}
              </p>
              <div>
                <input
                  type="range"
                  style={{ width: "90%" }}
                  name="fee"
                  min="0"
                  max="30"
                  step="0.1"
                  title={this.state.discount.percentage}
                  value={this.state.discount.percentage}
                  onChange={this.handleDiscountChange}
                />
                <span className="text_tra1">
                  {" "}
                  {this.state.discount.percentage}%{" "}
                </span>
              </div>
            </div>
            <div className="mx-3 my-2 ">
              <label className="text_tra1">
                Advance Ratio <i className="uil-info-circle" />
              </label>
              <p className="float-right text-white">
                ${this.state.advance_ratio.amount}{" "}
              </p>
              <div>
                <div>
                  <input
                    type="range"
                    style={{ width: "90%" }}
                    name="fee"
                    min="0"
                    max="100"
                    title={this.state.advance_ratio.percentage}
                    value={this.state.advance_ratio.percentage}
                    onChange={this.handleAdvanceRatioChange}
                  />
                  <span className="text_tra1">
                    {" "}
                    {this.state.advance_ratio.percentage}%{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="mx-3 my-4">
              <label className="text_tra1">Tenure</label>
              <div className="mt-3 d-flex justify-content-end">
                <span
                  className="text_tra1"
                  style={{ position: "absolute", margin: "8px 15px 7px auto" }}
                >
                  DAYS
                </span>
                <input
                  className="input"
                  type="number"
                  value={this.state.tenure}
                  onChange={this.tenureChange}
                ></input>
              </div>
              {this.state.tenureError && (
                <div className="text-danger">
                  Tenure is greater than available days to lend.
                </div>
              )}
            </div>
            <div className="mx-3 my-4">
              <label className="text_tra1">Comment if any</label>
              <input
                className="input"
                name="comment"
                value={this.state.offerData.comment}
                onChange={this.handleComment}
              ></input>
            </div>
            <div
              style={{ bottom: "0" }}
              className=" justify-content-center text-center  d-flex"
            >
              <span
                className="uploadb w-100 mx-3"
                onClick={() => this.setState({ previewOffer: true })}
              >
                Preview
              </span>
              <button
                className={
                  this.state.tenureError
                    ? "btn-danger w-100 mx-3 "
                    : "btn-blue w-100 mx-3 "
                }
                onClick={this.createOffer}
                disabled={this.state.tenureError}
              >
                Generate Offer
              </button>
            </div>
          </div>
          <div
            style={{ overflowY: "auto" }}
            className={`menuNav ${this.state.previewOffer ? "showMenu1" : ""}`}
          >
            <div
              style={{ borderBottom: "1px solid gray" }}
              className="d-flex justify-content-between text-white p-3"
            >
              <div>
                <h5>Offer Preview</h5>
                <p className="text_tra1">Invoice {this.state.invoiceId}</p>
              </div>
              <h5
                style={{ cursor: "pointer" }}
                onClick={() => this.setState({ previewOffer: false })}
              >
                <i className="uil-multiply"></i>
              </h5>
            </div>

            <div className="m-5 p-3  bgc-dark">
              <div className="d-flex justify-content-between ">
                <p className="text_tra1">Invoice Limit</p>
                <h6 className="text-white">
                  ${" "}
                  {this.state.invoiceOffer
                    ? Number(this.state.invoiceOffer.invoice_limit)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                    : ""}
                </h6>
              </div>
              <div className="d-flex justify-content-between ">
                <p className="text_tra1">
                  Factoring Fee{" "}
                  <i
                    className="uil-info-circle"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Factoring Fee"
                  >
                    <span className="mx-1">
                      {this.state.factoring_fee.percentage}%
                    </span>
                  </i>
                </p>
                <h6 className="text-white">
                  ${" "}
                  {Number(this.state.factoring_fee.amount)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </h6>
              </div>
              <div className="d-flex justify-content-between ">
                <p className="text_tra1">
                  Advance Amount{" "}
                  <i
                    className="uil-info-circle"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Advance Amount"
                  >
                    <span className="mx-1">
                      {this.state.advance_ratio.percentage}%
                    </span>
                  </i>
                </p>
                <h6 className="txt_b">
                  ${" "}
                  {Number(this.state.advance_ratio.amount)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </h6>
              </div>
              <div className="d-flex justify-content-between ">
                <p className="text_tra1">
                  Discounting Fee{" "}
                  <i
                    className="uil-info-circle"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Discounting Fee"
                  >
                    <span className="mx-1">
                      {this.state.discount.percentage}%
                    </span>
                  </i>
                </p>
                <h6 className="text-white">
                  $
                  {helper.discountFeeHandler(
                    +this.state.discount.percentage,
                    +this.state.advance_ratio?.amount,
                    +this.state.tenure
                  )}
                </h6>
              </div>
              <div className="d-flex justify-content-between ">
                <p className="text_tra1">Tenure</p>
                <h6 className="text-white">{this.state.tenure} Days</h6>
              </div>
            </div>
            <div
              style={{ bottom: "0" }}
              className=" justify-content-center text-center  d-flex"
            >
              <span
                className="uploadb w-25 mx-3"
                onClick={() => this.setState({ previewOffer: false })}
              >
                Back
              </span>
            </div>
          </div>
        </div>

        <Modal show={this.state.showPdf} onHide={this.handleClose}>
          <div style={{ backgroundColor: "#333333", color: "white" }}>
            {this.state.pdfUrl !== null ? (
              this.state.pdfUrl.split(".").pop() === "pdf" ||
              this.state.pdfUrl.split(".").pop() === "PDF" ? (
                <iframe
                  src={`https://docs.google.com/gview?url=${this.state.pdfUrl}&embedded=true`}
                  style={{ width: "50rem", height: "50rem" }}
                  frameBorder="0"
                ></iframe>
              ) : (
                <>
                  <img
                    src={this.state.pdfUrl}
                    style={{ width: "30rem", height: "30rem" }}
                  />
                </>
              )
            ) : (
              <div></div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  invoiceStatus: state.invoiceStatus.status,
  alert: state.alert,
  company: state.company,
  invoiceOffer: state.invoiceOffer,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceChanges: (data) => dispatch(getInvoiceChanges(data)),
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  postAlert: (data) => dispatch(postAlert(data)),
  getAllInvoices: (data) => dispatch(getAllInvoices(data)),
  getOfferById: (data) => dispatch(getOfferById(data)),
  postOffer: (data) => dispatch(postOffer(data)),
  getInvoiceCreatedByUser: (data) => dispatch(getInvoiceCreatedByUser(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OfferMade));

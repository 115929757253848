import { get, isEmpty, cloneDeep } from "lodash";

import qs from "qs";
import * as INVOICE_CONSTANTS from "../constants/actionConstant";
import ajax from "../../utils/ajax";

export const getInvoiceDashboardRequest = () => ({
  type: INVOICE_CONSTANTS.GET_DASHBOARD_CONSTANTS.REQUEST,
});

export const getInvoiceDashboardResponse = (data) => ({
  type: INVOICE_CONSTANTS.GET_DASHBOARD_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const getInvoiceDashboardError = (err) => ({
  type: INVOICE_CONSTANTS.GET_DASHBOARD_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getInvoiceDashboard = (id) => async (dispatch) => {
  dispatch(getInvoiceDashboardRequest());
  try {
    let url = INVOICE_CONSTANTS.GET_DASHBOARD_CONSTANTS.URL + id;
    const res = await ajax("GET", url, {});

    dispatch(getInvoiceDashboardResponse(res.data));

    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(getInvoiceDashboardError(err));
    return Promise.reject(err);
  }
};

export const requestAddInvoice = () => ({
  type: INVOICE_CONSTANTS.HOME_POST_INVOICE_CONSTANTS.REQUEST,
});

export const recievedAddInvoiceResponse = (data) => ({
  type: INVOICE_CONSTANTS.HOME_POST_INVOICE_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedAddInvoiceError = (err) => ({
  type: INVOICE_CONSTANTS.HOME_POST_INVOICE_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const postInvoice = (data) => async (dispatch) => {
  dispatch(requestAddInvoice());
  try {
    let url = INVOICE_CONSTANTS.HOME_POST_INVOICE_CONSTANTS.URL;
    var res = await ajax("POST", url, {}, {}, data);
    if (res) {
      dispatch(recievedAddInvoiceResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedAddInvoiceError(err));
    return Promise.reject(err);
  }
};

export const requestGetAllInvoice = () => ({
  type: INVOICE_CONSTANTS.GET_ALL_INVOICE_CONSTANTS.REQUEST,
});

export const recievedGetInvoiceResponse = (data) => ({
  type: INVOICE_CONSTANTS.GET_ALL_INVOICE_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetInvoiceError = (err) => ({
  type: INVOICE_CONSTANTS.GET_ALL_INVOICE_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getAllInvoices =
  (index = 1) =>
  async (dispatch) => {
    dispatch(requestGetAllInvoice());
    try {
      let url =
        INVOICE_CONSTANTS.GET_ALL_INVOICE_CONSTANTS.URL + `?page=${index}`;
      const res = await ajax("GET", url, {});

      dispatch(recievedGetInvoiceResponse(res.data));

      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(recievedGetInvoiceError(err));
      return Promise.reject(err);
    }
  };

export const requestUpdateInvoice = () => ({
  type: INVOICE_CONSTANTS.HOME_UPDATE_INVOICE_CONSTANTS.REQUEST,
});

export const recievedUpdateInvoiceResponse = (data) => ({
  type: INVOICE_CONSTANTS.HOME_UPDATE_INVOICE_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedUpdateInvoiceError = (err) => ({
  type: INVOICE_CONSTANTS.HOME_UPDATE_INVOICE_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const updateInvoice = (data) => async (dispatch) => {
  dispatch(requestUpdateInvoice());
  try {
    let url = INVOICE_CONSTANTS.HOME_UPDATE_INVOICE_CONSTANTS.URL + data._id;
    const res = await ajax("PUT", url, {}, {}, data);

    dispatch(recievedUpdateInvoiceResponse(res.data));

    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(recievedUpdateInvoiceError(err));
    return Promise.reject(err);
  }
};

export const requestDeleteInvoice = () => ({
  type: INVOICE_CONSTANTS.HOME_DELETE_INVOICE_CONSTANTS.REQUEST,
});

export const recievedDeleteInvoiceResponse = (data) => ({
  type: INVOICE_CONSTANTS.HOME_DELETE_INVOICE_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedDeleteInvoiceError = (err) => ({
  type: INVOICE_CONSTANTS.HOME_DELETE_INVOICE_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const deleteInvoice = (data) => {
  let url = INVOICE_CONSTANTS.HOME_DELETE_INVOICE_CONSTANTS.URL + "/" + data;

  return (dispatch) => {
    dispatch(requestDeleteInvoice());

    ajax("DELETE", url, {})
      .then((res) => dispatch(recievedDeleteInvoiceResponse(res.data)))
      .catch((err) => dispatch(recievedDeleteInvoiceError(err)));
  };
};

export const requestGetInvoiceCreatedByUser = () => ({
  type: INVOICE_CONSTANTS.GET_CREATED_BY_USER_INVOICE_CONSTANTS.REQUEST,
});

export const recievedGetInvoiceCreatedByUser = (data) => ({
  type: INVOICE_CONSTANTS.GET_CREATED_BY_USER_INVOICE_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetInvoiceCreatedByUserError = (err) => ({
  type: INVOICE_CONSTANTS.GET_CREATED_BY_USER_INVOICE_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getInvoiceCreatedByUser = (id, index=1) => async (dispatch) => {
  dispatch(requestGetInvoiceCreatedByUser());
  try {
    let url = INVOICE_CONSTANTS.GET_CREATED_BY_USER_INVOICE_CONSTANTS.URL + id + `?page=${index}`;
    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetInvoiceCreatedByUser(res.data));

      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetInvoiceCreatedByUserError(err));
    return Promise.reject(err);
  }
};

export const requestGetInvoiceDetailsById = () => ({
  type: INVOICE_CONSTANTS.GET_INVOICE_DETAILS_BY_ID_CONSTANTS.REQUEST,
});

export const recievedGetInvoiceDetailsByIdResponse = (data) => ({
  type: INVOICE_CONSTANTS.GET_INVOICE_DETAILS_BY_ID_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetInvoiceDetailsByIdError = (err) => ({
  type: INVOICE_CONSTANTS.GET_INVOICE_DETAILS_BY_ID_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getInvoiceDetailsById = (id) => async (dispatch) => {
  dispatch(requestGetInvoiceDetailsById());
  try {
    let url = INVOICE_CONSTANTS.GET_INVOICE_DETAILS_BY_ID_CONSTANTS.URL + id;
    const res = await ajax("GET", url, {});

    dispatch(recievedGetInvoiceDetailsByIdResponse(res.data));

    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(recievedGetInvoiceDetailsByIdError(err));
    return Promise.reject(err);
  }
};

export const getInvoiceChanges = (data) => (dispatch) => {
  dispatch({
    type: INVOICE_CONSTANTS.INVOICE_ID_LOCATION,
    data,
  });
};

export const getInvoiceData = (data) => (dispatch) => {
  dispatch({
    type: INVOICE_CONSTANTS.INVOICE_ID_DATA,
    data,
  });
};

import React from "react";
import ajax from "../../../utils/ajax";
import { URI } from "../../../constant";
import { ToastContainer, toast } from "react-toastify";
import { ADMIN_ALERT_TYPES } from "../../../config/string_constants";
import helper from "../../../utils/helper";
import { updateCompanyDoc } from "../../../api/companyService";
export default class UploadDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadLoader: [],
      company_doc_error: [],
      company_coi_url: null,
      company_kyc_url: null,
      company_moa_url: null,
      company_audit_url: null,
      company_gst_url: null,
      sales_ledger_url: null,
      bank_statement_url: null,
      previous_audit_url: null,
    };
  }
  fileChange = (e) => {
    if (helper.validateFileType(e.target.files)) {
      this.uploadFile(e.target.files[0], e.target.name);
    } else {
      var errorMessage =
        "Please Upload file in JPG, PNG, PDF and max size 5mb.";
      let error = {};
      error[e.target.name] = errorMessage;
      this.setState({ company_doc_error: error });
    }
  };

  uploadFile = async (file, name) => {
    let error = {};
    error[name] = "";
    let loader = {};
    loader[name] = true;
    this.setState({ uploadLoader: loader });
    const formData = new FormData();
    formData.append("image", file);
    if (file !== undefined) {
      let data = await ajax(
        "POST",
        `${URI}/api/v1/image-upload`,
        {},
        { "content-type": "multipart/form-data" },
        formData
      );
      if (data) {
        let company = this.props.company;
        company.update_type = ADMIN_ALERT_TYPES.COMPANY_DOCUMENT;
        company.createdById = sessionStorage.getItem("userId");
        company[name] = data.data.imageUrl;
        loader[name] = false;
        this.setState({
          company: company,
          uploadLoader: loader,
        });
        let dd = await updateCompanyDoc(this.props.company);
        toast.info("Document Uploaded");
        this.setState({
          company_doc_error: error,
        });
      }
    }
  };
  render() {
    return (
      <>
        <div className="d-block d-sm-none text-white">
          <p>Please go to web for update the KYC</p>
        </div>
        <div className="display_none">
          <span className="m-3 text-white"> Upload Documents</span>
          <h5 className="dis_block">Please go to Web for update the KYC</h5>
          <p className="m-3">
            Upload Documents in JPG, PNG and PDF Format. Max file should 5 MB.
          </p>
          <div className="row m-1">
            <div className="col-lg-6 col-sm-12">
              <div className="list-group-item d-flex justify-content-between lh-condensed mt-3 bg_gai">
                <div>
                  <h6 className="my-0 col_erone">
                    Certificate of Incorporation{" "}
                    <span style={{ color: "#5a5c5d" }}>(Optional)</span>
                  </h6>
                </div>
                <span className="text-muted">
                  <label className="uploadb">
                    {this.state.uploadLoader.company_coi_url ? (
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                        }}
                        className="spinner-border btn--icon"
                      ></div>
                    ) : (
                      "Upload"
                    )}
                    <input
                      type="file"
                      style={{
                        display: "btn btn-outline-primary col_erone",
                      }}
                      name="company_coi_url"
                      onChange={this.fileChange}
                    />
                  </label>
                  {this.props.company.company_coi_url ? (
                    <a
                      className="ml-1 txt_g"
                      href={this.props.company.company_coi_url}
                    >
                      Download
                    </a>
                  ) : (
                    <h6></h6>
                  )}
                </span>
              </div>
              {this.state.company_doc_error.company_coi_url && (
                <div className="text-danger">
                  {this.state.company_doc_error.company_coi_url}
                </div>
              )}
              <div className="list-group-item d-flex justify-content-between lh-condensed mt-3 bg_gai">
                <div>
                  <h6 className="my-0 col_erone">
                    MOA/AOA <span style={{ color: "#5a5c5d" }}>(Optional)</span>
                  </h6>
                </div>
                <span className="text-muted">
                  <label className="uploadb">
                    {this.state.uploadLoader.company_moa_url ? (
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                        }}
                        className="spinner-border btn--icon"
                      ></div>
                    ) : (
                      "Upload"
                    )}
                    <input
                      type="file"
                      name="company_moa_url"
                      onChange={this.fileChange}
                    />
                  </label>
                  {this.props.company.company_moa_url ? (
                    <a
                      className="ml-1 txt_g"
                      href={this.props.company.company_moa_url}
                    >
                      Download
                    </a>
                  ) : (
                    <h6></h6>
                  )}
                </span>
              </div>
              {this.state.company_doc_error.company_moa_url && (
                <div className="text-danger">
                  {this.state.company_doc_error.company_moa_url}
                </div>
              )}
              <div className="list-group-item d-flex justify-content-between lh-condensed mt-3 bg_gai">
                <div>
                  <h6 className="my-0 col_erone">
                    Last 6 months Sales ledger/Journal
                    <span style={{ color: "#E9556E" }}>*</span>
                  </h6>
                </div>
                <span className="text-muted">
                  <label className="uploadb">
                    {this.state.uploadLoader.sales_ledger_url ? (
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                        }}
                        className="spinner-border btn--icon"
                      ></div>
                    ) : (
                      "Upload"
                    )}
                    <input
                      type="file"
                      name="sales_ledger_url"
                      onChange={this.fileChange}
                    />
                  </label>
                  {this.props.company.sales_ledger_url ? (
                    <a
                      className="ml-1 txt_g"
                      href={this.props.company.sales_ledger_url}
                    >
                      Download
                    </a>
                  ) : (
                    <h6></h6>
                  )}
                  {/* <button type="button" className="btn btn-outline-primary col_erone">Upload</button> */}
                </span>
              </div>
              {this.state.company_doc_error.sales_ledger_url && (
                <div className="text-danger">
                  {this.state.company_doc_error.sales_ledger_url}
                </div>
              )}
              <div className="list-group-item d-flex justify-content-between lh-condensed mt-3 bg_gai">
                <div>
                  <h6 className="my-0 col_erone">
                    Last 6 months bank statements
                    <span style={{ color: "#E9556E" }}>*</span>
                  </h6>
                </div>
                <span className="text-muted">
                  <label className="uploadb">
                    {this.state.uploadLoader.bank_statement_url ? (
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                        }}
                        className="spinner-border btn--icon"
                      ></div>
                    ) : (
                      "Upload"
                    )}
                    <input
                      type="file"
                      name="bank_statement_url"
                      onChange={this.fileChange}
                    />
                  </label>
                  {this.props.company.bank_statement_url ? (
                    <a
                      className="ml-1 txt_g"
                      href={this.props.company.bank_statement_url}
                    >
                      Download
                    </a>
                  ) : (
                    <h6></h6>
                  )}
                  {/* <button type="button" className="btn btn-outline-primary col_erone">Upload</button> */}
                </span>
              </div>
              {this.state.company_doc_error.bank_statement_url && (
                <div className="text-danger">
                  {this.state.company_doc_error.bank_statement_url}
                </div>
              )}
            </div>
            <div className="col-sm-6">
              <div className="list-group-item d-flex justify-content-between lh-condensed mt-3 bg_gai">
                <div>
                  <h6 className="my-0 col_erone">
                    GST <span style={{ color: "#5a5c5d" }}>(Optional)</span>
                  </h6>
                </div>
                <span className="text-muted">
                  <label className="uploadb">
                    {this.state.uploadLoader.company_gst_url ? (
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                        }}
                        className="spinner-border btn--icon"
                      ></div>
                    ) : (
                      "Upload"
                    )}
                    <input
                      type="file"
                      name="company_gst_url"
                      onChange={this.fileChange}
                    />
                  </label>
                  {this.props.company.company_gst_url ? (
                    <a
                      className="ml-1 txt_g"
                      href={this.props.company.company_gst_url}
                    >
                      Download
                    </a>
                  ) : (
                    <h6></h6>
                  )}
                  {/* <button type="button" className="btn btn-outline-primary col_erone">Upload</button> */}
                </span>
              </div>
              {this.state.company_doc_error.company_gst_url && (
                <div className="text-danger">
                  {this.state.company_doc_error.company_gst_url}
                </div>
              )}
              <div className="list-group-item d-flex justify-content-between lh-condensed mt-3 bg_gai">
                <div>
                  <h6 className="my-0 col_erone">
                    Latest Audited Financial Statements
                    <span style={{ color: "#E9556E" }}>*</span>
                  </h6>
                </div>
                <span className="text-muted">
                  <label className="uploadb">
                    {this.state.uploadLoader.company_audit_url ? (
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                        }}
                        className="spinner-border btn--icon"
                      ></div>
                    ) : (
                      "Upload"
                    )}
                    <input
                      type="file"
                      name="company_audit_url"
                      onChange={this.fileChange}
                    />
                  </label>
                  {this.props.company.company_audit_url ? (
                    <a
                      className="ml-1 txt_g"
                      href={this.props.company.company_audit_url}
                    >
                      Download
                    </a>
                  ) : (
                    <h6></h6>
                  )}
                  {/* <button type="button" className="btn btn-outline-primary col_erone">Upload</button> */}
                </span>
              </div>
              {this.state.company_doc_error.company_audit_url && (
                <div className="text-danger">
                  {this.state.company_doc_error.company_audit_url}
                </div>
              )}
              <div className="list-group-item d-flex justify-content-between lh-condensed mt-3 bg_gai">
                <div>
                  <h6 className="my-0 col_erone">
                    Previous Audited Financial Statements
                    <span style={{ color: "#E9556E" }}>*</span>
                  </h6>
                </div>
                <span className="text-muted">
                  <label className="uploadb">
                    {this.state.uploadLoader.previous_audit_url ? (
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                        }}
                        className="spinner-border btn--icon"
                      ></div>
                    ) : (
                      "Upload"
                    )}
                    <input
                      type="file"
                      name="previous_audit_url"
                      onChange={this.fileChange}
                    />
                  </label>
                  {this.props.company.previous_audit_url ? (
                    <a
                      className="ml-1 txt_g"
                      href={this.props.company.previous_audit_url}
                    >
                      Download
                    </a>
                  ) : (
                    <h6></h6>
                  )}
                  {/* <button type="button" className="btn btn-outline-primary col_erone">Upload</button> */}
                </span>
              </div>
              {this.state.company_doc_error.previous_audit_url && (
                <div className="text-danger">
                  {this.state.company_doc_error.previous_audit_url}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

import React from "react";

/**
 * This component returns buyer details card UI
 * @param {*} props
 * @returns
 */
export default function BuyerDetailsCard(props) {
  return (
    <>
      {props.buyerDetails ? (
        <div style={{ marginTop: "1rem" }} className="p-3  ">
          <div className="border_b" style={{ color: "white" }}>
            <h5>Buyer Details</h5>
          </div>
          <div className="row mt-4  text_tra1">
            <div className="col-4">
              Buyer Name{" "}
              <h5 className="text-white">{props.buyerDetails.company_name}</h5>
            </div>
            <div className="col-4">
              Buyer Email{" "}
              <h5 className="text-white">
                {props.buyerDetails.company_email_id}
              </h5>
            </div>
            <div className="col-4">
              Buyer Address
              <h5 className="text-white">{props.buyerDetails.address_line1}</h5>
            </div>
          </div>
          <div className="row mt-4  text_tra1">
            <div className="col-4">
              Website{" "}
              <h5 className="text-white">{props.buyerDetails.website}</h5>
            </div>
            <div className="col-4">
              Contact Number{" "}
              <h5 className="text-white">
                {props.buyerDetails.company_dialCode}{" "}
                {props.buyerDetails.company_phone_no}
              </h5>
            </div>
          </div>
          <div
            className="border_b"
            style={{ color: "white", marginTop: "20px" }}
          >
            <h5>Contact Person</h5>
          </div>
          <hr />
          <div className="row mt-4  text_tra1">
            <div className="col-4">
              Name{" "}
              <h5 className="text-white">
                {props.buyerDetails.contact_details.full_name}
              </h5>
            </div>
            <div className="col-4">
              Designation
              <h5 className="text-white">
                {props.buyerDetails.contact_details.designation}
              </h5>
            </div>
            <div className="col-4">
              Contact Number
              <h5 className="text-white">
                {props.buyerDetails.contact_details.user_dialCode}{" "}
                {props.buyerDetails.contact_details.phone_number}
              </h5>
            </div>
          </div>
          <div className="row mt-4  text_tra1">
            <div className="col-4">
              Email{" "}
              <h5 className="text-white">
                {props.buyerDetails.contact_details.email}
              </h5>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

import React, { Component } from "react";
import DetailsExpander from "./detailsExpander";
import FinalOfferDetailsCard from "./detailsCard/finalOfferDetailsCard";
import { DETAILS } from "../../../config/string_constants";

/**
 * This component displays final offer details in final-offer page
 */
class FinalOfferDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      finaloffer: [],
    };
  }
  getFinalOfferData = (invoiceAmount, smartData) => {
    if (smartData.amount_to_be_funded) {
      smartData.amount_to_be_funded =
        (smartData.advance_percentage * smartData.amount) / 100;
      smartData.discount_amount =
        ((smartData.amount_to_be_funded * smartData.discount) / 100 / 365) *
        smartData.tenure;
      smartData.factoring_amount =
        (smartData.factoring_fee * parseInt(invoiceAmount)) / 100;
      smartData.fee = smartData.discount_amount + smartData.factoring_amount;
      smartData.created_date = new Date();
    }
    return smartData;
  };

  /**
   * Will run once the component is mounted (pre-render) to the UI
   */
  componentDidMount() {
    if (this.props.invoicedetails) {
      const result = this.props.invoicedetails;
      if (result.final_offer) {
        const final_offer = this.getFinalOfferData(
          result.invoice_amount,
          result.final_offer
        );
        this.setState({
          finaloffer: final_offer,
        });
      }
    }
  }

  render() {
    const props = this.state;
    props.tenure = this.props.tenure;
    return (
      <DetailsExpander label={DETAILS.FINAL_OFFER_DETAILS}>
        <FinalOfferDetailsCard {...props} />
      </DetailsExpander>
    );
  }
}
export default FinalOfferDetails;

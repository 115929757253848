import axios from "axios";
import { URI } from "../constant";
import ajax from "../utils/ajax";

export let getPoolForAdmin = () => {
  let url = URI + "/pool/admin/";
  const res = ajax("GET", url, {});
  return res;
};

export let getPoolHistoryForAdmin = (index = 1) => {
  let url = URI + `/pool/admin/history?page=${index}`;
  const res = ajax("GET", url, {});
  return res;
};
export let getPoolHistoryForLender = () => {
  let url = URI + `/pool/Lender/history`;
  const res = ajax("GET", url, {});
  return res;
};
export let createPoolData = (data) => {
  let url = URI + "/pool/";
  const res = ajax("POST", url, {}, {}, data);
  return res;
};

export let getLendingDetailsByPool = (id) => {
  let url = URI + `/pool/users/${id}`;
  const res = ajax("GET", url, {});
  return res;
};

export let updatePoolData = (data) => {
  let url = URI + "/pool/" + data._id;
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};
export let updatePoolStatus = (data) => {
  let url = URI + "/pool/status/" + data._id;
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};
export let closePool = (data) => {
  let url = URI + "/pool/close/" + data._id;
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};


export let getPoolByUserId = (id) => {
  let url = URI + "/pool/user/" + id;
  const res = ajax("GET", url, {});
  return res;
};

export let getCoinsUser = (id) => {
  let url = URI + "/coin/user/" + id;
  const res = ajax("GET", url, {});
  return res;
};
import axios from "axios";
import * as POOL_CONSTANTS from "../constants/poolConstant";
import ajax from "../../utils/ajax";

export const requestGetPool = () => ({
  type: POOL_CONSTANTS.HOME_GET_POOL_CONSTANTS.REQUEST,
});

export const recievedGetPoolResponse = (data) => ({
  type: POOL_CONSTANTS.HOME_GET_POOL_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetPoolError = (err) => ({
  type: POOL_CONSTANTS.HOME_GET_POOL_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getPools = () => async (dispatch) => {
  dispatch(requestGetPool());
  try {
    let url = POOL_CONSTANTS.HOME_GET_POOL_CONSTANTS.URL;
    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetPoolResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetPoolError(err));
    return Promise.reject(err);
  }
};

export const requestPostPool = () => ({
  type: POOL_CONSTANTS.HOME_POST_POOL_CONSTANTS.REQUEST,
});

export const recievedPostPoolResponse = (data) => ({
  type: POOL_CONSTANTS.HOME_POST_POOL_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedPostPoolError = (err) => ({
  type: POOL_CONSTANTS.HOME_POST_POOL_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const postPool = (data) => async (dispatch) => {
  dispatch(requestPostPool());
  try {
    let url = POOL_CONSTANTS.HOME_POST_POOL_CONSTANTS.URL;
    const res = await ajax("POST", url, {}, {}, data);
    if (res) {
      dispatch(recievedPostPoolResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedPostPoolError(err));
    return Promise.reject(err);
  }
};

export const requestUpdatePool = () => ({
  type: POOL_CONSTANTS.HOME_UPDATE_POOL_CONSTANTS.REQUEST,
});

export const recievedUpdatePoolResponse = (data) => ({
  type: POOL_CONSTANTS.HOME_UPDATE_POOL_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedUpdatePoolError = (err) => ({
  type: POOL_CONSTANTS.HOME_UPDATE_POOL_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const updatePool = (data) => async (dispatch) => {
  dispatch(requestUpdatePool());
  try {
    let url = POOL_CONSTANTS.HOME_POST_POOL_CONSTANTS.URL + "/" + data._id;
    const res = await ajax("PUT", url, {}, {}, data);
    if (res) {
      dispatch(recievedUpdatePoolResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedUpdatePoolError(err));
    return Promise.reject(err);
  }
};

export const requestDeletePool = () => ({
  type: POOL_CONSTANTS.HOME_DELETE_POOL_CONSTANTS.REQUEST,
});

export const recievedDeletePoolResponse = (data) => ({
  type: POOL_CONSTANTS.HOME_DELETE_POOL_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedDeletePoolError = (err) => ({
  type: POOL_CONSTANTS.HOME_DELETE_POOL_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const deletePool = (id) => async (dispatch) => {
  dispatch(requestDeletePool());
  try {
    let url = POOL_CONSTANTS.HOME_DELETE_POOL_CONSTANTS.URL + id;
    const res = await ajax("DELETE", url, {});
    if (res) {
      dispatch(recievedDeletePoolResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedDeletePoolError(err));
    return Promise.reject(err);
  }
};

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAllInvoices,
  getInvoiceDetailsById,
  getInvoiceChanges,
  getInvoiceCreatedByUser,
} from "../../../services/actions/invoiceAction";
import { updateInvoice } from "../../../services/actions/invoiceAction";
import { getInvoiceStatus } from "../../../services/actions/invoiceStatusAction";
import { postAlert } from "../../../services/actions/alertActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import MainHeader from "../mainHeader";
import Modal from "react-bootstrap/Modal";
import InvoiceMoreDetail from "./invoiceMoreDetail";
import Progressbar from "./progressBar";
import InvoicesListId from "./invoicesListId";
import Header from "./header";
import {
  INVOICE_STATUSES_KEY,
  CLIENT_ALERT_TYPES,
} from "../../../config/string_constants";
import { getOfferById } from "../../../services/actions/invoiceOfferAction";
import { getInvoiceForAdmin } from "../../../api/invoiceService";
import InvoiceData from "./invoiceData";
import ApproveInvoice from "./approveInvoice";
import DeclineInvoice from "./declineInvoice";
class PoolGovernance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceId: this.props.match.params.id,
      invoicedetails: [],
      invoicestatus: [],
      company_id: "",
      companydetails: [],
      showPdf: false,
      invoices: [],
      alertData: {
        invoice_id: "",
        invoice_ref_no: "",
        invoice_status_id: "",
        invoice_substatus_id: "",
        client_Id: "",
      },
      selected: false,
      itemId: "",
      firstItem: "",
      pdfUrl: null,
      screen: INVOICE_STATUSES_KEY.POOL_GOVERNANCE,
      clientCompanyId: "",
    };
  }
  handleClose = () => {
    this.setState({ showPdf: false });
  };
  onPdfShow = (url) => {
    this.setState({
      showPdf: true,
      pdfUrl: url,
    });
  };
  goback = () => {
    this.props.history.push("/dashboard");
  };

  componentDidMount() {
    this.props
      .getInvoiceDetailsById(this.state.invoiceId)
      .then((result) => {
        this.props.getInvoiceChanges(result);

        this.setState({
          invoicedetails: result,
          company_id: result.buyer_company._id,
          selected: true,
          itemId: result._id,
          companydetails: result.client_company,
        });
        getInvoiceForAdmin(result.created_by_id)
          .then((invoices) => {
            //Get company details of particular invoice

            this.setState({
              invoices: invoices.data,
            });
          })
          .catch((err) => {});
        //Get Soft Offer Tenure
        this.props
          .getOfferById(result._id)
          .then((offer) => {
            this.setState({
              tenure: offer.tenure,
            });
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }

  showData = (item) => {
    this.setState({
      invoiceId: item._id,
      selected: true,
      itemId: item._id,
      companydetails: item.client_company,
    });
  };

  render() {
    return (
      <div>
        <Header />

        <div className="row m-4 ">
          <div className="col-3">
            <InvoiceMoreDetail
              invoicedetails={this.state.invoicedetails}
              invoiceCompany={this.state.companydetails}
            />
          </div>
          <div className="col-9 overflow-auto">
            <Progressbar
              invoiceId={this.state.invoiceId}
              screen={this.state.screen}
            />
          </div>
        </div>

        <div className="row m-2 my-4">
          <div className="col-6">
            <div className="info-one h-100">
              {this.state.invoices.length === undefined ? (
                <div>No data Available </div>
              ) : (
                this.state.invoices
                  .filter((item) => {
                    return (
                      item.invoice_id === this.state.invoicedetails.invoice_id
                    );
                  })
                  .map((item, index) => (
                    <div key={index}>
                      <InvoiceData
                        invoices={item}
                        tenure={this.state.tenure}
                        onPdfShow={this.onPdfShow}
                      />

                      <div></div>

                      <>
                        <div className="mt-5 text-center">
                          <DeclineInvoice
                            invoice={item}
                            toastMessage="Pool Governance Declined"
                            updateType={
                              CLIENT_ALERT_TYPES.POOL_GOVERNANCE_APPROVED
                            }
                            invoiceStatus={this.props.invoiceStatus}
                            currentStatusKey={INVOICE_STATUSES_KEY.POOL_GOVERNANCE}
                          />
                           <ApproveInvoice
                            invoice={item}
                            nextStatusRoute="final-offer"
                            updateType={
                              CLIENT_ALERT_TYPES.POOL_GOVERNANCE_APPROVED
                            }
                            toastMessage="Pool Governance Approved"
                            invoiceStatus={this.props.invoiceStatus}
                            nextStatusKey={INVOICE_STATUSES_KEY.FINAL_OFFER}
                            currentStatusKey={INVOICE_STATUSES_KEY.POOL_GOVERNANCE}

                          />
                        </div>
                      </>
                    </div>
                  ))
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="info-one h-100">
              <p className="text_tra1 mb-2">INVOICES</p>
              <InvoicesListId
                invoices={this.state.invoices}
                selected={this.state.selected}
                showData={this.showData}
                itemId={this.state.itemId}
              />

              <div style={{ marginTop: "4rem" }} className="p-3  bgc-dark">
                <p className="text-white mt-4">COMPANY DETAILS</p>
                <div className="row">
                  <div className="col-6">
                    <div className="row mt-3  text_tra1">
                      <div className="col-6">
                        Company Phone
                        <h6 className="text-white">
                          {this.state.companydetails
                            ? this.state.companydetails.company_dialCode
                            : ""}{" "}
                          {this.state.companydetails
                            ? this.state.companydetails.company_phone_no
                            : ""}
                        </h6>
                      </div>
                      <div className="col-6">
                        Company Email{" "}
                        <h6 className="text-white">
                          {this.state.companydetails
                            ? this.state.companydetails.company_email_id
                            : ""}
                        </h6>
                      </div>
                    </div>
                    <div className="row mt-4 j text_tra1">
                      <div className="col-6">
                        Company Address
                        <h6 className="text-white">
                          {this.state.companydetails
                            ? this.state.companydetails.address_line1
                            : ""}
                        </h6>
                      </div>
                      <div className="col-6">
                        Company IEC Number{" "}
                        <h6 className="text-white">
                          {this.state.companydetails
                            ? this.state.companydetails.company_iec_number
                            : ""}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="p-3 info-one h-100 mx-5">
                      <div className="mt-2">
                        <label className="text_tra1">Last Updated</label>
                        <h6 className="text_tra1">
                          {moment(this.state.companydetails.updatedAt).format(
                            "DD MMM YYYY"
                          )}
                        </h6>
                      </div>
                      <div className="mt-2">
                        <label className="txt_b">View Insurance Report</label>
                        <p className="txt_b">Coming Soon...</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showPdf} onHide={this.handleClose}>
          <div style={{ backgroundColor: "#333333", color: "white" }}>
            {this.state.pdfUrl !== null ? (
              this.state.pdfUrl.split(".").pop() === "pdf" ||
              this.state.pdfUrl.split(".").pop() === "PDF" ? (
                <iframe
                  src={`https://docs.google.com/gview?url=${this.state.pdfUrl}&embedded=true`}
                  style={{ width: "50rem", height: "50rem" }}
                  frameBorder="0"
                ></iframe>
              ) : (
                <>
                  <img
                    src={this.state.pdfUrl}
                    style={{ width: "30rem", height: "30rem" }}
                  />
                </>
              )
            ) : (
              <div></div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  invoiceStatus: state.invoiceStatus.status,
  alert: state.alert,
  company: state.company,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceChanges: (data) => dispatch(getInvoiceChanges(data)),
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  postAlert: (data) => dispatch(postAlert(data)),
  getAllInvoices: (data) => dispatch(getAllInvoices(data)),
  getInvoiceCreatedByUser: (data) => dispatch(getInvoiceCreatedByUser(data)),
  getOfferById: (data) => dispatch(getOfferById(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PoolGovernance));

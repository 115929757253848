import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import styles from "../../assets/css/SignUp.module.css";
import Logo from "../../assets/images/Logo.svg";
import RightImage from "../../assets/images/SignUpPage/signup.svg";
import { Magic } from "magic-sdk";
import axios from "axios";
import { URI } from "../../constant";
import { propTypes } from "react-bootstrap/esm/Image";
import CONSTANTS from "../../utils/constant";
import { toast } from "react-toastify";
import { MAGIC_LINK_KEY } from "../../constant";

const magic = new Magic(MAGIC_LINK_KEY);
const { KYC_STATUS } = require("../../config/string_constants");
export default class Login extends Component {
  state = {
    email: "",
    password: "",
    errorEmail: false,
    errorPassword: false,
    invalidLogin: false,
    showMessage: false,
    isLoading: false,
    isVerifyPopup: false,
    submitButton: React.createRef(),
  };

  componentDidMount() {
    //To handle login from landing page
    const queryParams = new URLSearchParams(window.location.search);
    let autoLogin = queryParams.get("auto") === "true";
    if (queryParams.get("email") && autoLogin) {
      this.setState(
        {
          email: queryParams.get("email"),
        },
        (e) => {
          this.state.submitButton.current.click();
        }
      );
    }
  }

  handleSubmit = async (event) => {
    this.setState({
      isLoading: true,
    });
    event.preventDefault();

    const { email } = this.state;
    try {
      const pattern =
        /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,20}[\.][a-z]{2,5}/g;
      const isValidEmail = !pattern.test(email);
      if (!(email === "" || email.length < 0 || isValidEmail)) {
        await magic.auth.loginWithMagicLink({ email });
        const userData = await axios.post(`${URI}/auth/login`, {
          email,
          user_type: "LENDER",
        });
        sessionStorage.setItem("emailOfUser", email);
        sessionStorage.setItem("isLoggedIn", "false");
        sessionStorage.setItem("user-type", "LENDER");
        if (userData.data.success) {
          let userRes = userData.data.data;
          this.setState({
            isLoading: false,
          });
          sessionStorage.setItem("isLoggedIn", "true");
          sessionStorage.setItem("userId", userRes._id);
          sessionStorage.setItem(
            "x_access_token",
            userRes.x_access_token ? userRes.x_access_token : ""
          );
          sessionStorage.setItem("reward", userRes.reward ? userRes.reward : 0);
          sessionStorage.setItem(
            "kyc_session",
            userRes.kyc_session ? userRes.kyc_session : ""
          );
          sessionStorage.setItem(
            "kyc_data",
            userRes.kyc_data ? JSON.stringify(userRes.kyc_data) : ""
          );
          sessionStorage.setItem("ShowKycPopup", "false");
          sessionStorage.setItem("ActionToPerform", false);
          sessionStorage.setItem("ShowSecurityAlert", true);
          sessionStorage.setItem(
            "wallet_Id",
            userRes.wallet_address ? userRes.wallet_address : ""
          );
          if (userRes.kyc_data && userRes.kyc_data.steps.length > 0) {
            const stepsData = userRes.kyc_data.steps;
            const NOT_STARTED = stepsData.filter(
              (d) => d.state === KYC_STATUS.NOT_STARTED
            ).length;
            const VALIDATED = stepsData.filter(
              (d) => d.state === KYC_STATUS.VALIDATED
            ).length;
            const PENDING = stepsData.filter(
              (d) => d.state === KYC_STATUS.PENDING
            ).length;
            const FINAL_REJECTED = stepsData.filter(
              (d) => d.state === KYC_STATUS.FINAL_REJECTED
            ).length;

            const kycStatus =
              VALIDATED === 3
                ? KYC_STATUS.VALIDATED
                : NOT_STARTED > 0
                ? KYC_STATUS.NOT_STARTED
                : FINAL_REJECTED > 0
                ? KYC_STATUS.FINAL_REJECTED
                : KYC_STATUS.PENDING;
            sessionStorage.setItem("kycStatus", kycStatus);
            sessionStorage.setItem(
              "ActionToPerform",
              (kycStatus === KYC_STATUS.VALIDATED).toString()
            );
            sessionStorage.setItem(
              "ShowKycPopup",
              (kycStatus === KYC_STATUS.NOT_STARTED).toString()
            );
          } else {
            sessionStorage.setItem("kycStatus", KYC_STATUS.NOT_STARTED);
            sessionStorage.setItem("ActionToPerform", false);
            sessionStorage.setItem("ShowKycPopup", "true");
          }
          this.props.history.push("/lender/home");
        } else {
          toast.error(CONSTANTS.MESSAGES.UNAUTHORIZED);
        }
      } else {
        this.setState({
          errorEmail: true,
          isLoading: false,
        });
        toast.error("Invalid email");
      }
    } catch (err) {
      if (err.response.status === CONSTANTS.STATUS.TOO_MANY_REQUESTS) {
        toast.error(CONSTANTS.MESSAGES.TOO_MANY_REQUESTS);
      } else if (err.response.status === CONSTANTS.STATUS.UNAUTHORIZED) {
        toast.error(CONSTANTS.MESSAGES.UNAUTHORIZED);
      } else {
        toast.error(CONSTANTS.MESSAGES.INTERNAL_SERVER_ERROR);
      }
    }
  };

  handleValidation = () => {
    // alert((this.state.email==="" && this.state.email.length<0))
    //  return !(this.state.email==="" && this.state.email.length<0)
  };
  handleChange = (event) => {
    this.setState({
      email: event.target.value.trim(),
      errorEmail: false,
    });
  };

  render() {
    return (
      <>
        <div className={styles.SignUp}>
          <Link to="/">
            <img className={styles.Logo} src={Logo} alt="" />
          </Link>

          <div className={styles.Left}>
            <div className={styles.LeftContainer}>
              <div style={{ marginBottom: "70px" }}>
                <h1>Get in</h1>
                <p>
                  We will send a magic link on the email you enter below to get
                  you in securely.
                </p>
              </div>
              <form className={styles.SignUpForm}>
                <input
                  type="text"
                  name="email  "
                  className="custom-textbox"
                  id="email"
                  placeholder="Enter email address"
                  value={this.state.email}
                  onChange={this.handleChange}
                />

                {this.state.errorEmail ? (
                  <span className="text-danger mb-2">
                    {" "}
                    Please enter Email Id
                  </span>
                ) : (
                  ""
                )}
                <button
                  className="custom-button"
                  ref={this.state.submitButton}
                  onClick={this.handleSubmit}
                >
                  {this.state.isLoading ? "Loading..." : "Get in"}
                </button>
              </form>
            </div>
          </div>
          <div className={styles.Right}>
            <div className={styles.RightContainer}>
              <img src={RightImage} alt="" />
              <h3 className="mt-2">Shaping the future of Trade Finance</h3>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Login.propTypes = {
  history: PropTypes.any.isRequired,
};

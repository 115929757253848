import * as COUNTRY_CONSTANTS from '../constants/countryConstants';

export const countryReducer = (state = {
    type: '',
    error: '',
    isFetching: false,
   country:undefined,
}, action) => {
   
    switch (action.type) {
                case COUNTRY_CONSTANTS.HOME_GET_COUNTRY_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    country: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case COUNTRY_CONSTANTS.HOME_GET_COUNTRY_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    country: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case COUNTRY_CONSTANTS.HOME_GET_COUNTRY_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });


        default:
            return state;
    }
};


export default countryReducer;

import axios from "axios";
import { URI } from "../constant";
import ajax from "../utils/ajax";

export let getWalletHistory = (id) => {
  let url = URI + "/user/wallet/history/" + id;
  const res = ajax("GET", url, {});
  return res;
};

export let postApproveExecute = (id) => {
  let url = URI + "/user/wallet/approve/" + id;
  const res = ajax("POST", url, {});
  return res;
};

import React from "react";
import V2 from "../../../assets/images/lender-v2.svg";
import { LENDER_V2_LINK } from "../../../config/string_constants";
import {Link } from "react-router-dom";

export default function V2Banner(props) {
  return (
    <div className="row m-1 section p-4">
      <div className="col-md-6 col-sm-12 m-auto">
        <h2 className="text-white">
          Start lending now on Polytrade Lender Pool v2
        </h2>
        {props.kycMigration ? (
          <Link to='/lender/profile'>
                <button className="btn-blue">Migrate Your KYC</button>
          </Link>
        ) : (
          <a href={LENDER_V2_LINK} rel="noreferrer" target="_blank">
            <button className="btn-blue">Lend on V2</button>
          </a>
        )}
      </div>
      <div className="col-md-6 col-sm-12">
        <img src={V2} alt="lender-v2" className="lenderV2" width="100%" />
      </div>
    </div>
  );
}

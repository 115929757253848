import { get, isEmpty, cloneDeep } from "lodash";
import axios from "axios";
import qs from "qs";
import * as COMPANY_CONSTANTS from "../constants/companyConstants";
import * as ACTION_CONSTANTS from "../constants/actionConstant";
import ajax from "../../utils/ajax";

export const requestAddCompany = () => ({
  type: COMPANY_CONSTANTS.HOME_POST_COMPANY_CONSTANTS.REQUEST,
});

export const recievedAddCompanyResponse = (data) => ({
  type: COMPANY_CONSTANTS.HOME_POST_COMPANY_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedAddCompanyError = (err) => ({
  type: COMPANY_CONSTANTS.HOME_POST_COMPANY_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const postCompany = (data) => async (dispatch) => {
  dispatch(requestAddCompany());
  try {
    let url = COMPANY_CONSTANTS.HOME_POST_COMPANY_CONSTANTS.URL;

    const res = await ajax("POST", url, {}, {}, data);
    if (res) {
      dispatch(recievedAddCompanyResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedAddCompanyError(err));
    return Promise.reject(err);
  }
};

export const requestGetCreatedCompany = () => ({
  type: COMPANY_CONSTANTS.HOME_GET_CREATED_COMPANY_CONSTANTS.REQUEST,
});

export const recievedGetCreatedCompanyResponse = (data) => ({
  type: COMPANY_CONSTANTS.HOME_GET_CREATED_COMPANY_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetCreatedCompanyError = (err) => ({
  type: COMPANY_CONSTANTS.HOME_GET_CREATED_COMPANY_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getCompaniesByCreatedByAndApproved = (id) => async (dispatch) => {
  dispatch(requestGetCreatedCompany());
  try {
    let url = COMPANY_CONSTANTS.HOME_GET_CREATED_COMPANY_CONSTANTS.URL + id;
    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetCreatedCompanyResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetCreatedCompanyError(err));
    return Promise.reject(err);
  }
};
export const requestGetCompany = () => ({
  type: COMPANY_CONSTANTS.HOME_GET_COMPANY_CONSTANTS.REQUEST,
});

export const recievedGetCompanyResponse = (data) => ({
  type: COMPANY_CONSTANTS.HOME_GET_COMPANY_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetCompanyError = (err) => ({
  type: COMPANY_CONSTANTS.HOME_GET_COMPANY_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getCompany = () => async (dispatch) => {
  dispatch(requestGetCompany());
  try {
    let url = COMPANY_CONSTANTS.HOME_GET_COMPANY_CONSTANTS.URL;
    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetCompanyResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetCompanyError(err));
    return Promise.reject(err);
  }
};

export const requestUpdateCompany = () => ({
  type: COMPANY_CONSTANTS.HOME_UPDATE_COMPANY_CONSTANTS.REQUEST,
});

export const recievedUpdateCompanyResponse = (data) => ({
  type: COMPANY_CONSTANTS.HOME_UPDATE_COMPANY_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedUpdateCompanyError = (err) => ({
  type: COMPANY_CONSTANTS.HOME_UPDATE_COMPANY_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const updateCompany = (data) => async (dispatch) => {
  dispatch(requestUpdateCompany());
  try {
    let url = COMPANY_CONSTANTS.HOME_UPDATE_COMPANY_CONSTANTS.URL + data._id;
    const res = await ajax("PUT", url, {}, {}, data);
    if (res) {
      dispatch(recievedUpdateCompanyResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedUpdateCompanyError(err));
    return Promise.reject(err);
  }
};

//  export const updateCompany =  (data) => {
//    let url= COMPANY_CONSTANTS.HOME_UPDATE_COMPANY_CONSTANTS.URL + data._id;

//  return dispatch => {
//    dispatch(requestUpdateCompany());

//    axios.put(url,data,
//    {
//      headers:
//      {
//          'Content-Type': 'application/json'

//  } }
//    )
//      .then(res =>

//       dispatch(recievedUpdateCompanyResponse(res.data))
//
//        )
//      .catch(err => dispatch(recievedUpdateCompanyError(err)))
//  }
// }

export const requestGetCompanyByCompanyId = () => ({
  type: COMPANY_CONSTANTS.HOME_GET_COMPANY_BY_COMPANY_ID_CONSTANTS.REQUEST,
});

export const recievedGetCompanyByCompanyIdResponse = (data) => ({
  type: COMPANY_CONSTANTS.HOME_GET_COMPANY_BY_COMPANY_ID_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetCompanyByCompanyIdError = (err) => ({
  type: COMPANY_CONSTANTS.HOME_GET_COMPANY_BY_COMPANY_ID_CONSTANTS
    .RECEIVED_ERROR,
  errorCode: err,
});

export const getCompanyByCompanyId = (id) => async (dispatch) => {
  dispatch(requestGetCompanyByCompanyId());
  try {
    let url =
      COMPANY_CONSTANTS.HOME_GET_COMPANY_BY_COMPANY_ID_CONSTANTS.URL + id;
    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetCompanyByCompanyIdResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetCompanyByCompanyIdError(err));
    return Promise.reject(err);
  }
};

export const requestGetBuyerList = () => ({
  type: COMPANY_CONSTANTS.HOME_GET_BUYERS_LIST_CONSTANTS.REQUEST,
});

export const recievedGetBuyerListResponse = (data) => ({
  type: COMPANY_CONSTANTS.HOME_GET_BUYERS_LIST_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetBuyerListError = (err) => ({
  type: COMPANY_CONSTANTS.HOME_GET_BUYERS_LIST_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getBuyer = (id) => {
  let url = COMPANY_CONSTANTS.HOME_GET_BUYERS_LIST_CONSTANTS.URL + id;

  return (dispatch) => {
    dispatch(requestGetBuyerList());

    ajax("GET", url, {})
      .then((res) => dispatch(recievedGetBuyerListResponse(res.data)))
      .catch((err) => dispatch(recievedGetBuyerListError(err)));
  };
};

export const requestDeleteCompany = () => ({
  type: COMPANY_CONSTANTS.HOME_DELETE_COMPANY_CONSTANTS.REQUEST,
});

export const recievedDeleteCompanyResponse = (data) => ({
  type: COMPANY_CONSTANTS.HOME_DELETE_COMPANY_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedDeleteCompanyError = (err) => ({
  type: COMPANY_CONSTANTS.HOME_DELETE_COMPANY_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const deleteCompany = (id) => {
  let url = COMPANY_CONSTANTS.HOME_DELETE_COMPANY_CONSTANTS.URL + id;

  return (dispatch) => {
    dispatch(requestDeleteCompany());

    ajax("DELETE", url, {})
      .then((res) => dispatch(recievedDeleteCompanyResponse(res.data)))
      .catch((err) => dispatch(recievedDeleteCompanyError(err)));
  };
};

export const requestGetCompanyById = () => ({
  type: COMPANY_CONSTANTS.HOME_GET_COMPANY_BY_ID_CONSTANTS.REQUEST,
});

export const recievedGetCompanyByIdResponse = (data) => ({
  type: COMPANY_CONSTANTS.HOME_GET_COMPANY_BY_ID_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetCompanyByIdError = (err) => ({
  type: COMPANY_CONSTANTS.HOME_GET_COMPANY_BY_ID_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getCompanyById = (id) => async (dispatch) => {
  dispatch(requestGetCompanyById());
  try {
    let url = COMPANY_CONSTANTS.HOME_GET_COMPANY_BY_ID_CONSTANTS.URL + id;
    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetCompanyByIdResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetCompanyByIdError(err));
    return Promise.reject(err);
  }
};

export const requestVerifyCompany = () => ({
  type: ACTION_CONSTANTS.HOME_VERIFY_COMPANY_CONSTANTS.REQUEST,
});

export const recievedVerifyCompanyResponse = (data) => ({
  type: ACTION_CONSTANTS.HOME_VERIFY_COMPANY_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedVerifyCompanyError = (err) => ({
  type: ACTION_CONSTANTS.HOME_VERIFY_COMPANY_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});
export const verifyCompany = (data) => async (dispatch) => {
  dispatch(requestVerifyCompany());
  try {
    let url = ACTION_CONSTANTS.HOME_VERIFY_COMPANY_CONSTANTS.URL + data._id;

    const res = await ajax("PUT", url, {}, {}, data);
    if (res) {
      dispatch(recievedVerifyCompanyResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedVerifyCompanyError(err));
    return Promise.reject(err);
  }
};

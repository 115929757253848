import React, { Component } from "react";

import {
  createBonusUser,
  getAllLenderUserData,
  createSingleBonusUser,
  getActiveBonusUsersByBonusApyId,
  updateBonusUserStatus,
  updateBonusUsersStatus,
} from "../../../api/bonusUserService";
import { ToastContainer, toast } from "react-toastify";
export default class manageUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      selectedUserList: [],
      selectedRemovalUserList: [],
      singleBonusUser: {},
      activeUserData: [],
      searchUser:""
    };
  }
  componentDidMount() {
    if (this.props.bonusApyData) {
      this.getAllLenderUserData();
      this.getActiveBonusUsersByBonusApyId();
    }
  }
  getAllLenderUserData = async () => {
    let lender = await getAllLenderUserData(this.props.bonusApyData._id);
    this.setState({
      userList: lender.data,
    });
  };
  getActiveBonusUsersByBonusApyId = async () => {
    let user = await getActiveBonusUsersByBonusApyId(
      this.props.bonusApyData._id
    );

    this.setState({
      activeUserData: user.data,
    });
  };

  handleRemoveUser = (e, data) => {
    let userList = [...this.state.selectedRemovalUserList];
    let exist = 0;
    let position = null;
    for (let i = 0; i < this.state.selectedRemovalUserList.length; i++) {
      if (this.state.selectedRemovalUserList[i].user_id === data.user_id._id) {
        exist = 1;
        position = i;
        break;
      } else {
        exist = 0;
      }
    }
    if (e) {
      data.value = e;
      let userData = {
        _id: data._id,
        bonus_apy_id: this.props.bonusApyData._id,
        user_id: data.user_id._id,
      };
      if (exist === 0) {
        userList.push(userData);
      }
    } else {
      data.value = false;
      if (exist === 1 && position != null) {
        userList.splice(position, 1);
      }
    }
    this.setState({
      selectedRemovalUserList: userList,
    });
  };
  handleRemoveAllUser = (e) => {
    this.setState(
      {
        selectedRemovalUserList: [],
      },
      () => {
        this.selectAllRemoveData(e);
      }
    );
  };
  selectAllRemoveData = (e) => {
    let userList = [];

    if (e) {
      for (let i = 0; i < this.state.activeUserData.length; i++) {
        this.state.activeUserData[i].value = true;
        let userData = {
          _id: this.state.activeUserData[i]._id,
          bonus_apy_id: this.props.bonusApyData._id,
          user_id: this.state.activeUserData[i].user_id._id,
        };
        userList.push(userData);
      }
    } else {
      for (let i = 0; i < this.state.activeUserData.length; i++) {
        this.state.activeUserData[i].value = false;
      }
    }
    this.setState({
      selectedRemovalUserList: userList,
    });
  };
  handleAddUser = (e, data) => {
    let userList = [...this.state.selectedUserList];
    let exist = 0;
    let position = null;
    for (let i = 0; i < this.state.selectedUserList.length; i++) {
      if (this.state.selectedUserList[i].user_id === data._id) {
        exist = 1;
        position = i;
        break;
      } else {
        exist = 0;
      }
    }
    if (e) {
      data.value = e;
      let userData = {
        bonus_apy_id: this.props.bonusApyData._id,
        user_id: data._id,
      };
      if (exist === 0) {
        userList.push(userData);
      }
    } else {
      data.value = false;
      if (exist === 1 && position != null) {
        userList.splice(position, 1);
      }
    }
    this.setState({
      selectedUserList: userList,
    });
  };
  handleSelectAllUser = (e) => {
    this.setState(
      {
        selectedUserList: [],
      },
      () => {
        this.selectAllData(e);
      }
    );
  };
  selectAllData = (e) => {
    let userList = [];
    if (e) {
      for (let i = 0; i < this.state.userList.length; i++) {
        this.state.userList[i].value = true;
        let userData = {
          bonus_apy_id: this.props.bonusApyData._id,
          user_id: this.state.userList[i]._id,
        };
        userList.push(userData);
      }
    } else {
      for (let i = 0; i < this.state.userList.length; i++) {
        this.state.userList[i].value = false;
      }
    }
    this.setState({
      selectedUserList: userList,
    });
  };
  saveBonusApyUserList = async () => {
    if (this.state.selectedUserList.length > 0) {
      let data = await createBonusUser(this.state.selectedUserList);
      if (data) {
        this.setState({
          selectedUserList: [],
          selectedRemovalUserList: [],
        });
        toast.info("Added successfully");
        this.getAllLenderUserData();
        this.getActiveBonusUsersByBonusApyId();
      }
    }
  };
  saveSingleBonusApyUser = async (id) => {
    if (id) {
      let userData = {
        bonus_apy_id: this.props.bonusApyData._id,
        user_id: id,
      };
      let data = await createSingleBonusUser(userData);
      if (data) {
        this.setState({
          selectedUserList: [],
          selectedRemovalUserList: [],
        });
        toast.info("Added successfully");
        this.getAllLenderUserData();
        this.getActiveBonusUsersByBonusApyId();
      }
    }
  };
  removeBonusApyUserList = async () => {
    if (this.state.selectedRemovalUserList.length > 0) {
      let data = await updateBonusUsersStatus(
        this.state.selectedRemovalUserList
      );
      if (data) {
        this.setState({
          selectedUserList: [],
          selectedRemovalUserList: [],
        });
        toast.info("Removed successfully");
        this.getAllLenderUserData();
        this.getActiveBonusUsersByBonusApyId();
      }
    }
  };
  removeSingleBonusApyUser = async (item) => {
    if (item) {
      let data = await updateBonusUserStatus(item);
      if (data) {
        this.setState({
          selectedUserList: [],
          selectedRemovalUserList: [],
        });
        toast.info("Removed successfully");
        this.getAllLenderUserData();
        this.getActiveBonusUsersByBonusApyId();
      }
    }
  };
  handleSearch = (e) => {
    this.setState({
      searchUser: e.target.value,
    });
  };
  render() {
    return (
      <div className="container">
        <div className="info-section mt-4">
          <div className="info-one">
            <div className="d-flex pb-3 borderw justify-content-between">
              <div>
                <h6 className="text-white">ACTIVE USERS</h6>
                <p className="text_tra1">
                  Bonus APY ID #{this.props.bonusApyData.bonus_apy_no}
                </p>
              </div>
              <div>
                <button
                  className="btn-blue"
                  onClick={this.removeBonusApyUserList}
                >
                  REMOVE({this.state.selectedRemovalUserList.length})
                </button>
              </div>
            </div>
            <div className="d-flex mt-3 justify-content-between">
              {this.state.activeUserData.length > 0 ? (
                <div>
                  <input
                    className="vh"
                    type="checkbox"
                    id="box-8"
                    value=""
                    checked={
                      this.state.activeUserData.length ===
                      this.state.selectedRemovalUserList.length
                    }
                    onChange={(e) => {
                      this.handleRemoveAllUser(e.target.checked);
                    }}
                  />
                  <label htmlFor="box-8" className="checkme text_tra1">
                    Select all
                  </label>
                </div>
              ) : (
                ""
              )}
              <div>
                <p className="text_tra1">
                  Total Users in this Bonus: {this.state.activeUserData.length}
                </p>
              </div>
            </div>
           
            {this.state.activeUserData.map((item, index) => (
              <div
                className="d-flex mt-2 bgc-dark p-3 borderR justify-content-between"
                key={index}
              >
                <div>
                  <input
                    className="vh"
                    type="checkbox"
                    id={"activeUser-" + index}
                    name={"activeUser" + index}
                    value=""
                    checked={item.value === true}
                    onChange={(e) => {
                      this.handleRemoveUser(e.target.checked, item);
                    }}
                  />
                  <label
                    htmlFor={"activeUser-" + index}
                    className="checkme text_tra1"
                  >
                    {item.user_id.email}
                  </label>
                </div>
                <div>
                  <span
                    className="uploadb text_tra1"
                    onClick={(e) => {
                      this.removeSingleBonusApyUser(item);
                    }}
                  >
                    Remove
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="info-one">
            <div className="d-flex pb-3 borderw justify-content-between">
              <div>
                <h6 className="text-white">Add More Users</h6>
                <p className="text_tra1">
                  Bonus APY ID #{this.props.bonusApyData.bonus_apy_no}
                </p>
              </div>
              <div>
                <button
                  className="btn-blue"
                  onClick={this.saveBonusApyUserList}
                >
                  ADD({this.state.selectedUserList.length})
                </button>
              </div>
            </div>
            <div className="row mt-3">
            <div  className="col-sm-4">
              {this.state.userList.length > 0 ? (
              <>
                  <input
                    className="vh"
                    type="checkbox"
                    id="add-selectAll"
                    value=""
                    checked={
                      this.state.userList.length ===
                      this.state.selectedUserList.length
                    }
                    onChange={(e) => {
                      this.handleSelectAllUser(e.target.checked);
                    }}
                  />
                  <label htmlFor="add-selectAll" className="checkme text_tra1">
                    Select all
                  </label>
                  </>
              ) : (
                ""
              )
              
              }
               </div>
                <div
                className="col-sm-4"
              
              >
            <span className="justify-content-end search d-flex ">
                          <i
                            style={{
                              position: "absolute",
                              margin: "8px 10px 7px auto",
                              color: "#fff",
                            }}
                            className="uil-search-alt"
                          ></i>

                          <input
                            className="homesearchbox"
                            onChange={this.handleSearch}
                            value={this.state.searchUser}
                            type="text"
                            placeholder="Search User...."
                          />
                        </span>
                        </div>
              <div  className="col-sm-4">
                <p className="text_tra1">
                  Total Users: {this.state.userList.length}
                </p>
              </div>
            </div>
          
            {this.state.userList.filter((data) => {
                                  return data.email
                                    .toLowerCase()
                                    .includes(
                                      this.state.searchUser.toLowerCase()
                                    );
                                }).map((item, index) => (
              <div
                className="d-flex mt-2 bgc-dark p-3 borderR justify-content-between"
                key={index}
              >
                <div>
                  <input
                    className="vh"
                    type="checkbox"
                    id={"user-" + index}
                    name={"user" + index}
                    checked={item.value === true}
                    value=""
                    onChange={(e) => {
                      this.handleAddUser(e.target.checked, item);
                    }}
                  />
                  <label
                    htmlFor={"user-" + index}
                    className="checkme text_tra1"
                  >
                    {item.email}
                  </label>
                </div>
                <div>
                  <span
                    className="uploadb text_tra1"
                    onClick={(e) => {
                      this.saveSingleBonusApyUser(item._id);
                    }}
                  >
                    Add
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

import axios from "axios";
import { URI } from "../constant";
import ajax from "../utils/ajax";

export let createBonusUser = (data) => {
  let url = URI + "/bonus-user/";
  const res = ajax("POST", url, {}, {}, data);
  return res;
};
export let createSingleBonusUser = (data) => {
  let url = URI + "/bonus-user/user";
  const res = ajax("POST", url, {}, {}, data);
  return res;
};
export let getActiveBonusUsersByBonusApyId = (id) => {
  let url = URI + "/bonus-user/apy/" + id;
  const res = ajax("GET", url, {});
  return res;
};
export let getAllLenderUserData = (id) => {
  let url = URI + "/bonus-user/user/" + id;
  const res = ajax("GET", url, {});
  return res;
};
export let updateBonusUserStatus = (data) => {
  let url = URI + "/bonus-user/status/" + data._id;
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};
export let updateBonusUsersStatus = (data) => {
  let url = URI + "/bonus-user/statuses/";
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};
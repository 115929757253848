import axios from "axios";
import { URI } from "../constant";
import ajax from "../utils/ajax";

export let acceptSoftOffer = (data) => {
  let url = URI + "/invoice/soft-offer/" + data._id;
  const res = ajax("POST", url, {}, {}, data);
  return res;
};
export let rejectSoftOffer = (data) => {
  let url = URI + "/invoice/soft-offer/reject/" + data._id;
  const res = ajax("POST", url, {}, {}, data);
  return res;
};
export let acceptFinalOffer = (data) => {
  let url = URI + "/invoice/final-offer/" + data._id;
  const res = ajax("POST", url, {}, {}, data);
  return res;
};

export let createFinalOffer = (data) => {
  let url = URI + "/final-offer";
  const res = ajax("POST", url, {}, {}, data);
  return res;
};

export let getInvoiceForAdmin = (userId) => {
  let url = URI + `/invoice/admin/${userId}`;
  const res = ajax("GET", url, {});
  return res;
};

export let createDisburse = (invoiceId, userId) => {
  const url = URI + `/final-offer/disburse/${invoiceId}/user/${userId}`;
  const res = ajax("GET", url, {});
  return res;
};
export let updateAdvanceDate = (data) => {
  let url = URI + "/final-offer/advance-date";
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};

export let addCollection = (data) => {
  let url = URI + "/final-offer/add-collection";
  const res = ajax("POST", url, {}, {}, data);
  return res;
};
export let addReserveFundAllocation = (data) => {
  let url = URI + "/final-offer/add-reserve-fund";
  const res = ajax("POST", url, {}, {}, data);
  return res;
};
export let updateInvoiceStatus = (data) => {
  let url = URI + "/final-offer/change-invoice-status";
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};
export let approveInvoiceByAdmin = (data) => {
  let url = URI + "/invoice/approve-invoice/" + data._id;
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};
export let rejectInvoiceByAdmin = (data) => {
  let url = URI + "/invoice/reject-invoice/" + data._id;
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};
export let approveFormat = (data) => {
  let url = URI + "/invoice/approve-format/" + data._id;
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};
export let approveFundAllocation = (data) => {
  let url = URI + "/invoice/approve-fund/" + data._id;
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};



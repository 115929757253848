import React, { Component } from "react";
import helper from "../../../utils/helper";
import preview from "../../../assets/images/preview.png";
import download from "../../../assets/images/download1.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllInvoices,
  getInvoiceDetailsById,
  getInvoiceChanges,
  getInvoiceCreatedByUser,
} from "../../../services/actions/invoiceAction";
import { getCompanyByCompanyId } from "../../../services/actions/companyActions";
import { updateInvoice } from "../../../services/actions/invoiceAction";
import { getInvoiceStatus } from "../../../services/actions/invoiceStatusAction";
import { postAlert } from "../../../services/actions/alertActions";
import { getFinalOfferById } from "../../../services/actions/invoiceFinalOfferAction";
import { postSmartToken } from "../../../services/actions/smartContractAction";
import ajax from "../../../utils/ajax";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import InvoiceMoreDetail from "./invoiceMoreDetail";
import Progressbar from "./progressBar";
import { URI } from "../../../constant";
import InvoicesListId from "./invoicesListId";
import FinalOfferDetails from "./finalOfferDetails";
import Header from "./header";
import Spinner from "react-bootstrap/Spinner";
import {
  INVOICE_STATUSES_KEY,
  CLIENT_ALERT_TYPES,
  DETAILS,
} from "../../../config/string_constants";
import { addCollection } from "../../../api/invoiceService";
import { getInvoiceForAdmin } from "../../../api/invoiceService";
import PricingDetails from "./pricingDetails";
import { HELPERS } from "../../../helpers/index";
import ApproveInvoice from "./approveInvoice";

class Collection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceId: this.props.match.params.id,
      invoicedetails: "",
      fileLoader: false,
      fileError: "",
      invoicestatus: [],
      updateSuccess: false,
      alertSuccess: false,
      company_id: "",
      companydetails: [],
      showPdf: false,
      invoices: [],
      alertData: {
        invoice_id: "",
        invoice_ref_no: "",
        invoice_status_id: "",
        invoice_substatus_id: "",
        client_Id: "",
      },
      selected: false,
      itemId: "",
      firstItem: "",
      pdfUrl: null,
      offerStatus: "",
      offerSubStatus: "",
      overDuestatusId: "",
      overDueSubStatus: "",
      screen: INVOICE_STATUSES_KEY.COLLECTION,
      collectionStatus: false,
      fileurl: "",
      collection_details: {
        collection_format: "",
        reference_tx_id: "",
        payment_date: "",
        payment_ref_no: "",
        buyerId: "",
        amount_received: 0,
        amount_received_from_supplier: 0,
        invoice_ref_no: "",
        short_amount: 0,
        messages: "",
        doc: null,
        status: "",
        queue_id: "",
        finance_tenure: 0,
      },
      dirty: {
        collection_format: false,
        reference_tx_id: false,
        payment_date: false,
        payment_ref_no: false,
        buyerId: false,
        amount_received: false,
        invoice_ref_no: false,
        short_amount: false,
        messages: false,
      },
      errors: {
        collection_format: [],
        reference_tx_id: [],
        payment_date: [],
        payment_ref_no: [],
        buyerId: [],
        amount_received: [],
        invoice_ref_no: [],
        short_amount: [],
        messages: [],
      },
      finaloffer: [],
      paymentData: {
        offerId: "",
        access_token: "",
        payment_date: "",
        payment_ref_no: "",
        received_amount: "",
        applied_to_invoice_ref_no: "",
        un_applied_or_short_amount: "",
      },
      paymentSuccess: false,
      loader: false,
      currentStatus: "",
    };
  }

  handleClose = () => {
    this.setState({ showPdf: false });
  };

  onPdfShow = (url) => {
    this.setState({
      showPdf: true,
      pdfUrl: url,
    });
  };

  goback = () => {
    this.props.history.push("/dashboard");
  };

  financeTenure = () => {
    const dateDiff = helper.calculateFinanceTenure(
      this.state.collection_details.payment_date,
      this.state.invoicedetails.final_offer.advance_date
    );
    if (dateDiff < 0) {
      const paymentDateError = this.state.errors.payment_date;
      const paymentDateDirty = true;
      paymentDateError.push(
        "You cannot select date before advanced fund date!"
      );
      this.setState({
        errors: {
          ...this.state.errors,
          payment_date: paymentDateError,
        },
        dirty: {
          ...this.state.dirty,
          payment_date: paymentDateDirty,
        },
      });
    } else {
      return dateDiff;
    }
  };
  componentDidMount() {
    //Get Invoice details of passed Invoice ID

    this.props
      .getInvoiceDetailsById(this.state.invoiceId)
      .then((result) => {
        this.props.getInvoiceChanges(result);

        this.setState({
          invoicedetails: result,
          company_id: result.buyer_company._id,
          selected: true,
          itemId: result._id,
          finaloffer: result.final_offer,
          companydetails: result.client_company,
        });
        this.setState(
          {
            collection_details: result.collection_details,
          },
          () => {
            this.setState({
              collection_details: {
                ...this.state.collection_details,
                buyerId: result.buyer_company.company_name,
                invoice_ref_no: result.supplier_invoice_number,
                finance_tenure: this.financeTenure(),
                reference_tx_id: result.collection_details.reference_tx_id
                  ? result.collection_details.reference_tx_id
                  : result.invoice_id,
              },
            });
          }
        );
        //calling Helper function to getPricingDetails
        HELPERS.getPricingDetails(this.state.finaloffer.pricing_id)
          .then((pricing) => {
            this.setState({ pricingData: pricing });
          })
          .catch((err) => {});

        getInvoiceForAdmin(result.created_by_id)
          .then((invoices) => {
            //Get company details of particular invoice

            this.setState({
              invoices: invoices.data,
            });
          })
          .catch((err) => {});
      })
      .catch((err) => {});

    //Get All Invoice status

    for (let j = 0; j < this.props.invoiceStatus.length; j++) {
      if (this.props.invoiceStatus[j].key === INVOICE_STATUSES_KEY.COLLECTION) {
        for (let i = 0; i < this.props.invoiceStatus[j].subStatus.length; i++) {
          if (
            this.props.invoiceStatus[j].subStatus[i].statusName === "OverDue"
          ) {
            this.setState({
              overDueSubStatus: this.props.invoiceStatus[j].subStatus[i]._id,
              overDuestatusId: this.props.invoiceStatus[j]._id,
              currentStatus: this.props.invoiceStatus[j]._id,
            });
          }
        }
      }
    }
  }

  submit = async (e) => {
    e.preventDefault();
    this.validate();
    const dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty });

    if (this.isValid()) {
      this.setState({
        loader: true,
      });

      this.state.collection_details.invoice_id = this.state.invoicedetails._id;
      const response = await addCollection(this.state.collection_details);
      if (response) {
        if (response.data.success) {
          toast.info("Collection Details Updated");
          const data = this.state.invoicedetails;
          data.invoiceStatus_id = this.state.overDuestatusId;
          data.invoiceSubStatus_id = this.state.overDueSubStatus;
          data.collection_details = this.state.collection_details;

          this.setState(
            {
              updateSuccess: false,
              alertSuccess: true,
              collectionStatus: false,
              loader: false,
              alertData: {
                ...this.state.alertData,
                invoice_id: this.state.invoicedetails._id,
                invoice_ref_no: this.state.invoicedetails.invoice_id,
                invoice_status_id: this.state.overDuestatusId,
                invoice_substatus_id: this.state.overDueSubStatus,
                client_Id: this.state.invoicedetails.created_by_id,
              },
            },
            () => {
              this.props
                .postAlert(this.state.alertData)
                .then((result) => {
                  this.setState({ alertSuccess: false });
                })
                .catch((err) => {});
              this.props
                .getInvoiceDetailsById(this.state.invoiceId)
                .then((invoice) => {
                  this.props.getInvoiceChanges(invoice);
                  this.setState({
                    collection_details: invoice.collection_details,
                  });
                })
                .catch((err) => {});
            }
          );
        }
      }
    }
  };

  showData = (item) => {
    this.setState({
      invoiceId: item._id,
      invoicedetails: item,
      selected: true,
      itemId: item._id,
      finaloffer: item.final_offer,
      companydetails: item.client_company,
    });
  };

  addAmountCalculation = () =>
    Number(this.state.collection_details.amount_received) +
    Number(this.state.collection_details.amount_received_from_supplier);

  handleChange = (e) => {
    const { name, value } = e.target;
    const post = this.state.collection_details;
    post[name] = value;
    if (name === "payment_date") {
      post.finance_tenure = this.financeTenure();
    }
    post.short_amount =
      Number(this.state.invoicedetails.invoice_amount) -
      this.addAmountCalculation();
    this.setState({ collection_details: post }, this.validate);
  };

  blurChange = (e) => {
    const { name } = e.target;
    const dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };

  validate = () => {
    const { errors } = this.state;
    Object.keys(errors).forEach((control) => {
      errors[control] = [];

      //  /^(ftp|https?):\/\/+(www\.)?[a-z0-9\-\.]{3,}\.[a-z]{3}$/
      const collectionDetails = this.state.collection_details[control];
      switch (control) {
        case "collection_format":
          if (!collectionDetails) {
            errors[control].push("This field is required");
          }
          break;

        case "reference_tx_id":
          if (!collectionDetails) {
            errors[control].push("This field is required");
          }
          break;
        case "payment_date":
          if (!collectionDetails) {
            errors[control].push("This field is required");
          }

          break;
        case "payment_ref_no":
          if (!collectionDetails) {
            errors[control].push("This field is required");
          }
          break;

        case "buyerId":
          if (!collectionDetails) {
            errors[control].push("This field is required");
          }
          break;

        case "amount_received":
          if (!collectionDetails) {
            errors[control].push("This field is required");
          }
          break;

        case "invoice_ref_no":
          if (!collectionDetails) {
            errors[control].push("This field is required");
          }
          break;

        case "short_amount":
          if (!collectionDetails) {
          } else {
            if (parseInt(collectionDetails) < 0) {
              errors[control].push("This field can't be negative");
            }
          }
          break;

        case "messages":
          if (!collectionDetails) {
            errors[control].push("This field is required");
          }
          break;

        default:
          break;
      }
    });
    this.setState({ errors });
  };
  isValid = () => {
    let valid = true;

    for (let control in this.state.errors) {
      if (this.state.errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };

  fileChange = (e) => {
    if (helper.validateFileType(e.target.files)) {
      this.uploadFile(e.target.files[0]);
    } else {
      this.setState({
        fileLoader: false,
        fileError: "Please Upload file in JPG, PNG, PDF and max size 5mb.",
      });
    }
  };
  uploadFile(file) {
    this.setState({ fileLoader: true });
    const formData = new FormData();
    formData.append("image", file);
    if (file !== undefined) {
      ajax(
        "POST",
        `${URI}/api/v1/image-upload`,
        {},
        { "content-type": "multipart/form-data" },
        formData
      )
        .then((data) => {
          this.setState(
            {
              collection_details: {
                ...this.state.collection_details,
                doc: data.data.imageUrl,
              },
              fileLoader: false,
              fileError: "",
            },
            () => {
              toast.info("Document Uploaded");
            }
          );
        })
        .catch((err) => {});
    }
  }

  render() {
    return (
      <div>
        <Header />

        <div className="row m-4 ">
          <div className="col-3">
            <InvoiceMoreDetail
              invoicedetails={this.state.invoicedetails}
              invoiceCompany={this.state.companydetails}
            />
          </div>
          <div className="col-9 overflow-auto">
            <Progressbar
              invoiceId={this.state.invoiceId}
              screen={this.state.screen}
            />
          </div>
        </div>

        <div className="row m-2 my-4">
          <div className="col-6">
            <div className="info-one h-100">
              <p className="text-white">INVOICE DETAILS</p>
              {this.state.invoices.length === undefined ? (
                <div>No data Available </div>
              ) : (
                this.state.invoices
                  .filter((item) => {
                    return (
                      item.invoice_id === this.state.invoicedetails.invoice_id
                    );
                  })
                  .map((item, index) => (
                    <div key={index}>
                      <div className="d-flex justify-content-between mt-4  text_tra1">
                        <div>
                          Invoice Amount{" "}
                          <h5 className="text-white">
                            {item.currency}{" "}
                            {Number(item.invoice_amount)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </h5>
                        </div>
                        <div>
                          Invoice Limit{" "}
                          <h5 className="text-white">
                            {item.currency}{" "}
                            {Number(item.invoice_limit)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </h5>
                        </div>
                        <div>
                          Invoice
                          <h5 className="text-white">
                            <p>
                              <a
                                onClick={() =>
                                  this.onPdfShow(item.invoice_pdf_link)
                                }
                              >
                                <img src={preview} alt="preview" />
                              </a>
                              <a
                                href={item.invoice_pdf_link}
                                style={{ color: "white" }}
                              >
                                {" "}
                                <img src={download} />
                              </a>
                            </p>
                          </h5>
                        </div>
                      </div>
                      <div className=" d-flex justify-content-between mt-4 p-2 bgc-dark  text_tra1">
                        <div>
                          Invoice Date{" "}
                          <h6 className="text-white">
                            {moment(item.invoice_date).format("DD MMM YYYY")}
                          </h6>
                        </div>
                        <div>
                          Due Date
                          <h6 className="text-white">
                            {moment(item.invoice_due_date).format(
                              "DD MMM YYYY"
                            )}
                          </h6>
                        </div>
                        <div>
                          Current Date
                          <h6 className="text-white">
                            {moment().format("DD MMM YYYY")}
                          </h6>
                        </div>
                        <div>
                          Days To Due(DTD)
                          <h6 className="text-white">
                            {moment(item.invoice_due_date).diff(
                              moment(),
                              "days"
                            )}
                          </h6>
                        </div>
                      </div>

                      <div className="bgc-dark p-3 mt-4">
                        <p className="text-white mt-4">
                          {DETAILS.SELLER_DETAILS}
                        </p>

                        <div className="d-flex justify-content-between mt-4   text_tra1">
                          <div>
                            Invoice Amount{" "}
                            <h6 className="text-white">
                              {item.currency}{" "}
                              {Number(item.invoice_amount)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </h6>
                          </div>
                          <div>
                            Invoice Limit
                            <h6 className="text-white">
                              {item.currency}{" "}
                              {Number(item.invoice_limit)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </h6>
                          </div>
                          <div>
                            Date of Advanced Fund
                            <h6 className="text-white">
                              {" "}
                              {item.final_offer
                                ? moment(item.final_offer.advance_date).format(
                                    "DD MMM YYYY"
                                  )
                                : ""}
                            </h6>
                          </div>
                        </div>

                        <p className="text-white mt-4">CONTACT PERSON</p>

                        <div className="d-flex justify-content-between mt-4   text_tra1">
                          <div>
                            Full Name
                            <h6 className="text-white">{item.full_name}</h6>
                          </div>
                          <div>
                            Designation
                            <h6 className="text-white">{item.designation}</h6>
                          </div>
                          <div>
                            Contact Number
                            <h6 className="text-white">
                              {" "}
                              {item.contact_dialCode} {item.phone_number}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className=" d-flex justify-content-between mt-4 p-2 bgc-dark  text-white">
                        <div>
                          Collection Status{" "}
                          {this.state.invoicedetails ? (
                            this.state.invoicedetails.collection_details
                              .collection_format ? (
                              <span className="txt_g">Verified</span>
                            ) : (
                              <span className="txt_y">Pending</span>
                            )
                          ) : (
                            <span className="txt_y">Pending</span>
                          )}
                        </div>
                        <div>
                          <i
                            className="uil-angle-right txt_b"
                            onClick={() =>
                              this.setState({ collectionStatus: true })
                            }
                          ></i>
                        </div>
                      </div>

                      {this.state.invoicedetails ? (
                        this.state.invoicedetails.collection_details
                          .collection_format ? (
                          <div className="mt-5 text-center">
                            <ApproveInvoice
                              invoice={item}
                              nextStatusRoute="reserve-fund-allocation"
                              updateType={
                                CLIENT_ALERT_TYPES.COLLECTION_APPROVED
                              }
                              toastMessage="Collection Approved"
                              invoiceStatus={this.props.invoiceStatus}
                              nextStatusKey={
                                INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION
                              }
                              currentStatusKey={INVOICE_STATUSES_KEY.COLLECTION}
                            />
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  ))
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="info-one h-100">
              <p className="text_tra1 mb-2">INVOICES</p>
              <InvoicesListId
                invoices={this.state.invoices}
                selected={this.state.selected}
                showData={this.showData}
                itemId={this.state.itemId}
              />

              <PricingDetails
                pricingData={this.state.pricingData}
                show={false}
              />
              {this.state.invoicedetails ? (
                <FinalOfferDetails
                  invoiceId={this.state.invoiceId}
                  invoicedetails={this.state.invoicedetails}
                  tenure={this.state.tenure}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div
          style={{ overflowY: "auto" }}
          className={`menuNav ${
            this.state.collectionStatus ? "showMenu1" : ""
          }`}
        >
          <div
            style={{ borderBottom: "1px solid gray" }}
            className="d-flex justify-content-between text-white p-3"
          >
            <div>
              <h5>Collection Status </h5>
              <p className="text_tra1">Invoice {this.state.invoiceId}</p>
            </div>
            <h5
              style={{ cursor: "pointer" }}
              onClick={() => this.setState({ collectionStatus: false })}
            >
              <i className="uil-multiply"></i>
            </h5>
          </div>

          <div className="row p-3">
            <div className="col-6">
              <label>Collection Format</label>
              <div>
                <select
                  style={{
                    background: "#333333",
                    width: "100%",
                    height: "40px",
                  }}
                  className="form-select"
                  name="collection_format"
                  value={this.state.collection_details.collection_format}
                  onChange={this.handleChange}
                  onBlur={this.blurChange}
                >
                  <option>Choose Format</option>
                  <option>Fiat</option>
                </select>
              </div>
              <div className="text-danger">
                {this.state.errors.collection_format[0] &&
                this.state.dirty.collection_format
                  ? this.state.errors.collection_format
                  : ""}
              </div>
            </div>
            <div className="col-6">
              <label>Reference Txn ID</label>
              <input
                className="input"
                name="reference_tx_id"
                value={this.state.collection_details.reference_tx_id}
                onChange={this.handleChange}
                onBlur={this.blurChange}
              />
              <div className="text-danger">
                {this.state.errors.reference_tx_id[0] &&
                this.state.dirty.reference_tx_id
                  ? this.state.errors.reference_tx_id
                  : ""}
              </div>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-6">
              <label>Buyer Payment Date</label>
              <input
                className="input"
                type="date"
                name="payment_date"
                value={this.state.collection_details.payment_date}
                onChange={this.handleChange}
                onBlur={this.blurChange}
              />
              <div className="text-danger">
                {this.state.errors.payment_date[0] &&
                this.state.dirty.payment_date[0]
                  ? this.state.errors.payment_date
                  : ""}
              </div>
            </div>
            <div className="col-6">
              <label>Swift Ref.No</label>
              <input
                className="input"
                name="payment_ref_no"
                value={this.state.collection_details.payment_ref_no}
                onChange={this.handleChange}
                onBlur={this.blurChange}
              />
              <div className="text-danger">
                {this.state.errors.payment_ref_no[0] &&
                this.state.dirty.payment_ref_no
                  ? this.state.errors.payment_ref_no
                  : ""}
              </div>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-6">
              <label>Buyer Name</label>
              <input
                className="input"
                name="buyerId"
                disabled
                value={this.state.collection_details.buyerId}
                onChange={this.handleChange}
                onBlur={this.blurChange}
              />
              <div className="text-danger">
                {this.state.errors.buyerId[0] && this.state.dirty.buyerId
                  ? this.state.errors.buyerId
                  : ""}
              </div>
            </div>
            <div className="col-6">
              <label>Amount Received from Buyer</label>
              <input
                className="input"
                name="amount_received"
                value={this.state.collection_details.amount_received}
                onChange={this.handleChange}
                onBlur={this.blurChange}
              />
              <div className="text-danger">
                {this.state.errors.amount_received[0] &&
                this.state.dirty.amount_received
                  ? this.state.errors.amount_received
                  : ""}
              </div>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-6">
              <label>Supplier Invoice Number</label>
              <input
                className="input"
                name="invoice_ref_no"
                disabled
                value={this.state.collection_details.invoice_ref_no}
                onChange={this.handleChange}
                onBlur={this.blurChange}
              />
              <div className="text-danger">
                {this.state.errors.invoice_ref_no[0] &&
                this.state.dirty.invoice_ref_no
                  ? this.state.errors.invoice_ref_no
                  : ""}
              </div>
            </div>
            <div className="col-6">
              <label>Unapplied/Short Amount</label>
              <input
                className="input"
                name="short_amount"
                disabled
                value={this.state.collection_details.short_amount || 0}
              />
              <div className="text-danger">
                {this.state.errors.short_amount[0] &&
                this.state.dirty.short_amount
                  ? this.state.errors.short_amount
                  : ""}
              </div>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-6">
              <label>Finance Tenure</label>
              <input
                className="input"
                name="finance_tenure"
                disabled
                value={this.state.collection_details.finance_tenure}
                onChange={this.handleChange}
                onBlur={this.blurChange}
              />
            </div>
            <div className="col-6">
              <label>Amount Received from Supplier</label>
              <input
                className="input"
                name="amount_received_from_supplier"
                value={
                  this.state.collection_details.amount_received_from_supplier
                }
                onChange={this.handleChange}
                onBlur={this.blurChange}
              />
            </div>
          </div>
          <div className="row mt-3  p-3">
            <div className="col-12">
              <textarea
                className="input"
                name="messages"
                value={this.state.collection_details.messages}
                onChange={this.handleChange}
                placeholder="If any message write here....."
                onBlur={this.blurChange}
              ></textarea>
              <div className="text-danger">
                {this.state.errors.messages[0] && this.state.dirty.messages
                  ? this.state.errors.messages
                  : ""}
              </div>
            </div>
          </div>
          <div className="row mt-3  p-3">
            <div className="col-12">
              <label>Upload If Any Document </label>
              <div className="bgc-dark d-flex p-2 justify-content-between">
                <div className="text_tra1">Upload here</div>
                <div className="txt_b">
                  {this.state.fileLoader ? (
                    <div className="spinner-border btn--icon"></div>
                  ) : (
                    "Upload"
                  )}{" "}
                  <input type="file" onChange={this.fileChange} />
                </div>
              </div>
              {this.state.fileError && (
                <div className="text-danger">{this.state.fileError}</div>
              )}
            </div>
          </div>

          <div
            style={{ bottom: "0" }}
            className=" justify-content-center text-center my-2  d-flex"
          >
            {this.state.loader ? (
              <Spinner
                style={{ width: "40px", height: "40px" }}
                animation="border"
                variant="info"
              />
            ) : this.state.invoicedetails &&
              this.state.invoicedetails.invoiceStatus_id &&
              this.state.invoicedetails.invoiceStatus_id._id ===
                this.state.currentStatus ? (
              <button className="btn-blue w-25 " onClick={this.submit}>
                Update
              </button>
            ) : (
              ""
            )}
          </div>
        </div>

        <Modal show={this.state.showPdf} onHide={this.handleClose}>
          <div style={{ backgroundColor: "#333333", color: "white" }}>
            {this.state.pdfUrl !== null ? (
              this.state.pdfUrl.split(".").pop() === "pdf" ||
              this.state.pdfUrl.split(".").pop() === "PDF" ? (
                <iframe
                  src={`https://docs.google.com/gview?url=${this.state.pdfUrl}&embedded=true`}
                  style={{ width: "50rem", height: "50rem" }}
                  frameBorder="0"
                ></iframe>
              ) : (
                <>
                  <img
                    src={this.state.pdfUrl}
                    style={{ width: "30rem", height: "30rem" }}
                  />
                </>
              )
            ) : (
              <div></div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  invoiceStatus: state.invoiceStatus.status,
  alert: state.alert,
  company: state.company,
  invoiceFinalOffer: state.invoiceFinalOffer,
  pricing: state.pricing,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceChanges: (data) => dispatch(getInvoiceChanges(data)),
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  postAlert: (data) => dispatch(postAlert(data)),
  getCompanyByCompanyId: (data) => dispatch(getCompanyByCompanyId(data)),
  getAllInvoices: (data) => dispatch(getAllInvoices(data)),
  getInvoiceCreatedByUser: (data) => dispatch(getInvoiceCreatedByUser(data)),
  getFinalOfferById: (data) => dispatch(getFinalOfferById(data)),
  postSmartToken: (data) => dispatch(postSmartToken(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Collection));

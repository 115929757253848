import {URI} from '../../constant'

export const HOME_GET_INVOICE_OFFER_CONSTANTS = ({
    URL: URI+"/invoice-offer/",
    REQUEST: 'REQUEST_GET_INVOICE_OFFER_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_GET_INVOICE_OFFER_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_GET_INVOICE_OFFER_DATA_CONSTANTS',
  });

  export const HOME_POST_INVOICE_OFFER_CONSTANTS = {
    URL: URI + "/invoice-offer/",
    REQUEST: "REQUEST_POST_INVOICE_OFFER_DATA_CONSTANTS",
    RECEIVED: "RECEIVED_POST_INVOICE_OFFER_DATA_CONSTANTS",
    RECEIVED_ERROR: "ERROR_POST_INVOICE_OFFER_DATA_CONSTANTS",
  };

  export const HOME_UPDATE_INVOICE_OFFER_CONSTANTS = {
    URL: URI + "/invoice-offer/",
    REQUEST: "REQUEST_UPDATE_INVOICE_OFFER_DATA_CONSTANTS",
    RECEIVED: "RECEIVED_UPDATE_INVOICE_OFFER_DATA_CONSTANTS",
    RECEIVED_ERROR: "ERROR_UPDATE_INVOICE_OFFER_DATA_CONSTANTS",
  };
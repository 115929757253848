import React from "react";
import "../../../assets/css/BuyerProfile.css";
import "react-toastify/dist/ReactToastify.css";
import { USER_TYPE } from "../../../config/string_constants";
import DialCodeList from "../Common/dialCodeList";

class BuyerUserForm extends React.Component {
  updateStart = 0;
  constructor(props) {
    super(props);

    this.state = {
      loadbuyer: true,
      isLoader: false,
      user: {
        full_name: "",
        designation: "",
        email: "",
        phone_number: "",
        user_dialCode: "",
        user_type: USER_TYPE.BUYER,
        createdById: sessionStorage.getItem("userId"),
      },

      country: [],
      city: [],
      contact_details: false,
      addSucess: false,
      dirty: {
        full_name: false,
        designation: false,
        email: false,
        phone_number: false,
        user_dialCode: false,
      },

      errors: {
        full_name: [],
        designation: [],
        phone_number: [],
        email: [],
        company_dialCode: [],
        user_dialCode: [],
      },
    };
  }
  handleChange = (e) => {
    const { name, value } = e.target;

    let dirty = this.state.dirty;
    dirty[name] = true;

    let user = this.state.user;
    if (name === "phone_number") {
      let cleanText = ("" + value).replace(/\D/g, "");
      user[name] = cleanText;
    } else {
      user[name] = value;
    }
    this.setState(
      {
        user: user,
        dirty: dirty,
      },
      this.validate
    );
  };

  handleCountryChange = (e) => {
    const { name, value } = e.target;

    let dirty = this.state.dirty;
    dirty[name] = true;

    let user = this.state.user;

    user[name] = value;

    this.setState(
      {
        user: user,
        city: this.props.country
          .filter((item) => item._id === this.state.user.country_id)
          .map((item) => item.cities),
        dirty: dirty,
      },
      this.validate
    );
  };
  saveBuyer = () => {
    this.validate();
    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });

    if (this.isValid()) {
      this.props.saveBuyerData(this.state.user);
    }
  };

  blurChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };

  validate = () => {
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      errors[control] = [];
      var validName = /^[A-Za-z_'-\s]+$/;
      var validEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      switch (control) {
        case "full_name":
          if (!this.state.user[control]) {
            errors[control].push("Name can't be blank");
          }
          if (this.state.user[control]) {
            if (!validName.test(this.state.user[control])) {
              errors[control].push("Please Enter valid   Name");
            }
          }
          break;

        case "designation":
          if (!this.state.user[control]) {
            errors[control].push("This field is required");
          }
          if (this.state.user[control]) {
            if (!validName.test(this.state.user[control])) {
              errors[control].push("Please Enter valid Designation");
            }
          }
          break;
        case "phone_number":
          if (!this.state.user[control]) {
            errors[control].push("This field is required");
          }
          let length = this.state.user[control].length;
          if (this.state.user[control] && (length < 6 || length > 12)) {
            errors[control].push("Number min 6 max 12");
          }
          break;
        case "email":
          if (!this.state.user[control]) {
            errors[control].push("This field is required");
          }
          if (this.state.user[control]) {
            if (!validEmail.test(this.state.user[control])) {
              errors[control].push("Please Enter valid Email Id");
            }
          }
          break;
        case "user_dialCode":
          if (!this.state.user[control]) {
            errors[control].push("Dial code is required");
          }
          break;

        default:
          break;
      }
    });
    this.setState({ errors });
  };

  isValid = () => {
    let valid = true;

    for (let control in this.state.errors) {
      if (this.state.errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };
  componentDidMount() {}

  render() {
    return (
      <main className="main-form">
        <div
          className="mid-details"
          style={{ textAlign: "center", color: "#686868" }}
        >
          <a className="curserP" onClick={() => this.props.contactDetails()}>
            Buyer Company<span style={{ paddingLeft: "9px" }}></span>
          </a>
          <a style={{ color: "#4DB5C4", padding: "12px" }}>
            {" "}
            {">"} Buyer Contact Details
          </a>
        </div>

        <div className=" main__company_details1">
          <div className="main__company_details_header ">
            Buyer Contact Person
          </div>
          <div className="row p-4">
            <div className="col-sm-6 p-2">
              <label className="label" htmlFor="">
                Full Name
              </label>
              <input
                className="input"
                name="full_name"
                type="text"
                value={this.state.user.full_name}
                placeholder="Please Enter your Full Name"
                onChange={this.handleChange}
                onBlur={this.blurChange}
                maxLength="50"
              />
              <div className="text-danger">
                {this.state.errors.full_name[0] && this.state.dirty.full_name
                  ? this.state.errors.full_name
                  : ""}
              </div>
            </div>
            <div className="col-sm-6 p-2">
              <label className="label" htmlFor="">
                Designation
              </label>
              <input
                className="input"
                name="designation"
                type="text"
                placeholder="Designation"
                value={this.state.user.designation}
                onChange={this.handleChange}
                onBlur={this.blurChange}
                maxLength="15"
              />
              <div className="text-danger">
                {this.state.errors.designation[0] &&
                this.state.dirty.designation
                  ? this.state.errors.designation
                  : ""}
              </div>
            </div>
            <div className="col-sm-6 p-2">
              <label className="label" htmlFor="">
                Contact Number
              </label>
              <div className="d-flex">
                <DialCodeList handleChange={this.handleChange} name="user_dialCode" />

                <input
                  className="input"
                  name="phone_number"
                  type="text"
                  placeholder="Enter Buyer Contact Number"
                  value={this.state.user.phone_number}
                  onChange={this.handleChange}
                  onBlur={this.blurChange}
                  maxLength="13"
                />
              </div>
              <div className="text-danger">
                {this.state.errors.user_dialCode[0] &&
                this.state.dirty.user_dialCode
                  ? this.state.errors.user_dialCode
                  : ""}
              </div>
              <div className="text-danger">
                {this.state.errors.phone_number[0] &&
                this.state.dirty.phone_number
                  ? this.state.errors.phone_number
                  : ""}
              </div>
            </div>
            <div className="col-sm-6 p-2">
              <label className="label" htmlFor="">
                Email Address
              </label>
              <input
                className="input"
                type="text"
                name="email"
                placeholder="email@example.com"
                value={this.state.user.email}
                onChange={this.handleChange}
                onBlur={this.blurChange}
                maxLength="50"
              />
              <div className="text-danger">
                {this.state.errors.email[0] && this.state.dirty.email
                  ? this.state.errors.email
                  : ""}
              </div>
            </div>
          </div>
        </div>

        {/* <Link to='/buyerlist'> */}
        <button
          className="cont-btn"
          style={{
            backgroundColor: "rgb(77 181 196)",
            color: "#fff",
          }}
          onClick={this.saveBuyer}
        >
          {this.props.isLoader === false ? (
            <span>Submit Buyer</span>
          ) : (
            <div className="spinner-border btn--icon"></div>
          )}
        </button>

        {/* </Link>  */}
      </main>
    );
  }
}

export default BuyerUserForm;

import React, { Component } from "react";
import Sidebar from "./sidebar";
import MainHeader from "../mainHeader";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getLenders } from "../../../services/actions/userAction";
import { getKycStatus } from "../../../services/actions/kycStatusAction";
import { getCoins } from "../../../services/actions/coinActions";
import LenderHistorydetails from "./lenderHistorydetails";
import Paginations from "../../pagination";

class lendingHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      kycstatus: [],
      lenders: [],
      paginationItem:[],
      searchLender: "",
      selectedKyc: "",
      coins: [],
      viewMore: [],
      details: false,
    };
  }

  pageChange = async (index) => {
    this.props
    .getLenders(index)
    .then((result) => {
      this.setState({
        lenders: result.data,
        paginationItem: result,
      });
    })
    .catch((err) => {});
  }

  handleChange = (e) => {
    this.setState({
      searchLender: e.target.value,
    });
  };
  componentDidMount() {
    let currentPage = sessionStorage.getItem("current_history_page");
    if (!currentPage) {
      currentPage = 1;
    }
    this.props
      .getLenders()
      .then((result) => {
        this.setState({
          lenders: result.data,
          paginationItem:result
        });
      })
      .catch((err) => {});

    this.props
      .getCoins()
      .then((result) => {
        this.setState({
          coins: result,
        });
      })
      .catch((err) => {});

    this.props
      .getKycStatus()
      .then((result) => {
        this.setState({
          kycstatus: result,
        });
      })
      .catch((err) => {});
  }
  viewMore = (id) => {
    sessionStorage.setItem(
      "current_history_page",
      this.state.paginationItem.paginator.page
    );
    this.props.history.push(`/lender-history-details/${id}`);
  };
  viewMore1 = (id) => {
    this.setState({ viewMore: id });
    this.setState({ details: true });
  };
  back = (item) => {
    this.setState({ details: item });
  };
  render() {
    return (
      <div>
        <Sidebar />
        {!this.state.details ? (
          <div className="main-content">
            <header>
              <div className="dropdown show m-4">
                <a
                  className="admin_button dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="pr-4">LENDER DASHBOARD</span>
                </a>

                <div
                  className="admin_button dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link to="/dashboard" className="text-decoration-none ">
                    <a className="text-white dropdown-item" href="#">
                      CLIENT DASHBOARD
                    </a>
                  </Link>
                </div>
              </div>
              <MainHeader />
            </header>
            {/*main content*/}

            <main>
              <div className="d-flex w-100 justify-content-between ">
                <span className="mr-auto my-2 mr-4 search d-flex justify-content-end">
                  <i
                    style={{
                      position: "absolute",
                      margin: "8px 5px 7px auto",
                      color: "#fff",
                    }}
                    className="uil-search-alt"
                  ></i>

                  <input
                    className="homesearchbox"
                    type="text"
                    placeholder="Search..."
                    name="searchLender"
                    value={this.state.searchLender}
                    onChange={this.handleChange}
                  />
                </span>
              </div>

              <div className="table-full-width mt-3 table-responsive px-0">
                <table className="border-0">
                  <thead className="disp_non_sm">
                    <tr className="text_tra1 ">
                      <th>Lender Email</th>
                      <th>Total Lending</th>
                      <th>Active Lending</th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.lenders === undefined ? (
                      <tr>NO data</tr>
                    ) : (
                      this.state.lenders
                        .filter((data) => {
                          return data.email
                            .toLowerCase()
                            .includes(this.state.searchLender.toLowerCase());
                        })
                        .map((index, key) => (
                          <tr key={key} className="sec1 pt-1 pb-1">
                            <td className="pt-3 pb-3">{index.email}</td>
                            <td className="pt-3 pb-3">
                              {index.lend_total_count}
                            </td>
                            <td className="pt-3 pb-3">
                              {index.lend_active_count}
                            </td>
                            <td className="pt-3 pb-3">
                              {" "}
                              <span
                                className="curserP uploadb"
                                onClick={() => this.viewMore1(index)}
                              >
                                View More
                              </span>
                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end mx-4 mt-2">
                    <Paginations item={this.state.paginationItem} pageClick={this.pageChange} />
              </div>
            </main>
          </div>
        ) : (
          <LenderHistorydetails
            goBack={this.back}
            details={this.state.viewMore}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  kycStatus: state.kycStatus,
  coin: state.coin,
});

const mapDispatchToProps = (dispatch) => ({
  getKycStatus: (data) => dispatch(getKycStatus(data)),
  getLenders: (data) => dispatch(getLenders(data)),
  getCoins: (data) => dispatch(getCoins(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(lendingHistory));

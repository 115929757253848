import { URI } from "../constant";
import ajax from "../utils/ajax";

/**
 * Function to get Pricing Data based on pricing_id passed as parameter
 * @param {*} pricing_id
 * @returns Pricing Details
 */
export let getPricingData = (pricing_id) => {
  const url = URI + `/pricing/${pricing_id}`;
  const res = ajax("GET", url, {});
  return res;
};

import { Country } from "country-state-city";
import { useEffect } from "react";
import { useState } from "react";

/**
 * Component for dial-code list
 * @param {*} props 
 * @returns 
 */
export default function DialCodeList(props) {
  const [dialCode, setDialCode] = useState([]);

  /**
   * add + if dial code doesn't have. 
   */
  useEffect(() => {
    const dialCode = new Set();
    Country.getAllCountries().filter(data => {
      if (data.phonecode.charAt(0) !== '+') {
        dialCode.add(data.phonecode.replace(/^/, "+"));
      }
      else {
        dialCode.add(data.phonecode);
      }
      setDialCode(Array.from(dialCode));
    });
  }, [])

  return (
    <>
      <select
        style={{ width: "90px", height: "44px", paddingLeft: "9px" }}
        className="form-select"
        name={props.name}
        value={props.value}
        onChange={props.handleChange}
      >
        <option
          className="country-state-dropdown"
        >Dial No.</option>
        {dialCode.map((item) => (
          <option
            className="country-state-dropdown"
            key={item}
            value={item}

          >
            {item}
          </option>
        ))}
      </select>
    </>
  )
}
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Alert from '../../../assets/images/alert.svg'
import Close from '../../../assets/images/close_icon.svg'
import '../../../assets/css/common/fontStyle.css'
import '../../../assets/css/common/button.css'
export default function Kycpopup(props) {

  return (
    <div>
      <div style={{ fontFamily: "Averta-Semibold,sans-serif" }}>

        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ backgroundColor: 'transparent' }}  >
          <div className='alert-popup' style={{ backgroundColor: '#333333', color: 'white', border: '0' }}>
            <div className='mt-3 disp-lg-none'>
              <div className='line mx-auto'></div>
            </div>

            <Modal.Body style={{ textAlign: 'center' }} >
              <div className="float-right mb-2 " >
                <img src={Close} onClick={props.hidekycNow} className="  user-icon" alt="" />
              </div>
              <div style={{ margin: '0 auto', width: '100%', height: '100%', fontSize: '16px', lineHeight: '24px' }}>
                <div  ><img src={Alert} className="user-icon ml-4 mb-4" alt="" /></div>
                <label className="font-style-8"> Verify KYC to use our all the advanced features</label></div>
              <div style={{ margin: '20px', alignItems: 'center' }}><button onClick={props.kycNow} className='btn btn-sm btn-primary custom-button' style={{ minWidth: '200px' }}>Verify KYC Now</button></div>
              <div style={{ margin: '20px', alignItems: 'center' }}>  <button style={{ minWidth: '200px' }} className=' custom-button-1 btn btn-sm ' onClick={props.hidekycNow}>Later</button></div>

            </Modal.Body>
          </div>
        </Modal>
      </div>
    </div>
  )
}
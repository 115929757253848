import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import '../../../assets/css/uploadpopup.css'
import {Link} from 'react-router-dom'
import attach from '../../../assets/images/attach.png'
import trash from '../../../assets/images/trash.png'
import { URLS } from "../../../config/frontendUrls";

export default function uploadpopup(props) {

    function constructor(props) {
      this.state = {
        invoice:{invoice_date:'', invoice_due_date:'',invoice_amount:'',invoice_availabe_amount:'',full_name:'',designation:'',  phone_number: '', email:''},
        dirty:{invoice_date:'', invoice_due_date:'',invoice_amount:'',invoice_availabe_amount:'',full_name:'',designation:'',  phone_number:'', email:''},
        errors:{invoice_date:'', invoice_due_date:'',invoice_amount:'',invoice_availabe_amount:'',full_name:'',designation:'',  phone_number:'', email:''}
      };
    }
    
    return (
        <div  style={{fontFamily:"Averta-Semibold,sans-serif"}}>
             <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{backgroundColor:'black'}} >
          <div  style={{backgroundColor:'#333333',color:'white',border:'0'}}>
          <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{fontSize:'16px',lineHeight:'24px'}}>
          Upload Invoice
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{textAlign:'left'}}>
        <div style={{margin:'24px'}}> <span className='upload'><img src={attach}/> invoicename.pdf < img style={{marginLeft:'65px'}} src={trash} /></span></div>
        
        <div style={{margin:'24px'}} className='row'>
              <div className='col-sm-3'>
                <label className="label" htmlFor="">What is invoive date?</label>
                <input className='input' type="text" placeholder="DD/MM/YYYY" />
              </div>
              <div className='col-sm-3'>
                <label className="label" htmlFor="">What is due date?</label>
                <input className='input' type="text" placeholder="DD/MM/YYYY" />
              </div>
              <div className='col-sm-3'>
                <label className="label" htmlFor="">Invoice amount</label>
                <input className='input' type="text" placeholder="$" />
              </div>
              <div className='col-sm-3'>
                <label className="label" htmlFor="">Available Amount</label>
                <input className='input' type="text" placeholder="$530000" />
              </div>
            </div>
      
    <div style={{margin:'24px',border:"1px solid"}}>
        <div style={{backgroundColor:'#262626',marginBottom:'15px',padding:'11px',borderBottom:"0.2px solid",textAlign:'left'}}><p>Contact person for trade</p></div>
            <div  className='row' style={{margin:'19px'}}>
             <div className='col-sm-6'>
                <label className="label" htmlFor="">Full Name</label>
                <input className='input' type="text" placeholder="Enter your company name" />
              </div>
              <div className='col-sm-6'>
                <label className="label" htmlFor="">Designation</label>
                <input className='input' type="text" placeholder="Head of sales" />
              </div>
              <div className='col-sm-6'>
                <label className="label" htmlFor="">Contact Number</label>
                <input className='input' type="text" placeholder="123456789" />
              </div>
              <div className='col-sm-6'>
                <label className="label" htmlFor="">Email Address</label>
                <input className='input' type="text" placeholder="support@domain.com" />
              </div>
              
        </div>

    </div>
 
        <div >
            <Link to={URLS.CLIENT.INVOICES} style={{textDecoration: 'none'}}><Button  style={{backgroundColor:"#2DA3B4",margin:'10px'}}>Submit Upload</Button></Link>
            </div>
      </Modal.Body>
          </div>
     
     
    </Modal>
        </div>
    )
}

const CLIENT_BASE_URL = "/client";
const CLIENT = {
  HOME: CLIENT_BASE_URL,
  LOGIN: `${CLIENT_BASE_URL}/login`,
  ONBOARD: `${CLIENT_BASE_URL}/onboard`,
  HEADER: `${CLIENT_BASE_URL}/header`,
  DASHBOARD: `${CLIENT_BASE_URL}/dashboard`,
  VERIFY_AND_SUBMIT: `${CLIENT_BASE_URL}/verify-and-Submit`,
  BUYER_PROFILE: `${CLIENT_BASE_URL}/buyer-profile`,
  NEW_BUYER: `${CLIENT_BASE_URL}/new-buyer`,
  BUYER_LIST: `${CLIENT_BASE_URL}/buyer-list`,
  INVOICES: `${CLIENT_BASE_URL}/invoices`,
  INVOICES_BY_ID: `${CLIENT_BASE_URL}/invoices/:id`,
  ADD_INVOICES: `${CLIENT_BASE_URL}/add-invoices`,
  INVOICES_DETAILS: `${CLIENT_BASE_URL}/invoices-details`,
  INVOICES_DETAILS_BY_ID: `${CLIENT_BASE_URL}/invoices-details/:id`,
  PROFILE: `${CLIENT_BASE_URL}/profile`,
  PROFILE_SECTION: `${CLIENT_BASE_URL}/profile/:section`,
  REFERRAL: `${CLIENT_BASE_URL}/referral`,
  WALLET: `${CLIENT_BASE_URL}/wallet`,
};
export const URLS = { CLIENT };

import React from "react";
import _isEmpty from "lodash/isEmpty";
import "../../../../src/assets/css/dashboard.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getEmailUser } from "../../../services/actions/userAction";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logoutBE } from "../../../api/authenticate";
import { logoutUser } from "../../../services/magic";
import { toast } from "react-toastify";
import { URLS } from '../../../config/frontendUrls'



class MainHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadData: true,
      username: "",
      userId: "",
      userEmail: "",
      profileName: "",
    };
  }

  getProfileName = (name) => {
    var parts = name.split(" ");

    var initials = "";
    for (var i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== "") {
        initials += parts[i][0];
      }
    }
    this.setState({
      profileName: initials,
      username: name,
    });
  };
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if(!_isEmpty(nextProps.user.userEmailData)&& this.state.loadData)
  //       {
  //           this.setState({username:nextProps.user.userEmailData[0].full_name });
  //           // sessionStorage.setItem("uName",nextProps.user.userEmailData[0].full_name);
  //           // sessionStorage.setItem("uEmail",nextProps.user.userEmailData[0].email);
  //           // sessionStorage.setItem("userId",nextProps.user.userEmailData[0]._id);

  //           this.setState({ loadData : false});
  //       }

  // }

  componentDidMount() {
    this.props
      .getEmailUser(sessionStorage.getItem("emailOfUser"))
      .then((result) => {
        this.getProfileName(result[0].full_name);
      })
      .catch((err) => {});
  }

  logout = async () => {
    let res = await logoutBE();
    if(res.data.success) {
      toast.info(res.data.message);
      logoutUser(sessionStorage.getItem("emailOfUser"));
      sessionStorage.clear();
      this.props.history.push(URLS.CLIENT.LOGIN);
    }
  };

  render() {
    return (
      <div className='d-none d-sm-block'>
        <div className='user-wrapper '>
          <div className='profileInitials d-flex justify-content-center  '>
            <div className='center'>{this.state.profileName}</div>
          </div>

          <h4 className='user-name'>
            {this.state.username}
            <OverlayTrigger
              trigger='click'
              placement='bottom'
              overlay={
                <Popover id={`popover-positioned-bottom`}>
                  <Popover.Content style={{ backgroundColor: "#333333" }}>
                    <Button
                      onClick={() => this.logout()}
                      style={{ backgroundColor: "#2DA3B4", margin: "10px" }}
                    >
                      Logout
                    </Button>
                  </Popover.Content>
                </Popover>
              }
            >
              <Button
                variant='secondary'
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <i className='uil uil-angle-down' />
              </Button>
            </OverlayTrigger>
          </h4>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  getEmailUser: (data) => dispatch(getEmailUser(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainHeader));

import React from "react";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import helper from "../../../../utils/helper";
/**
 * This component returns soft offer details card UI
 * @param {*} props
 * @returns
 */
export default function SoftOfferDetailsCard(props) {
  return (
    <>
      <div className="row mt-4  text_tra1">
        <div className="col-4">
          Invoice Limit{" "}
          <h5 className="text-white">
            ${" "}
            {props.invoiceOffer.invoice_limit
              ? helper.formatNumber(props.invoiceOffer.invoice_limit)
              : 0}
          </h5>
        </div>
        <div className="col-4">
          Factoring Fee ({props.invoiceOffer.factoring_fee.percentage}%)
          <h5 className="text-white">
            $
            {props.invoiceOffer.factoring_fee
              ? helper.formatNumber(props.invoiceOffer.factoring_fee.amount)
              : ""}
          </h5>
        </div>
        <div className="col-4">
          Advance Ratio ({props.invoiceOffer.advance_ratio.percentage}%)
          <h5 className="text-white">
            $
            {props.invoiceOffer.advance_ratio
              ? helper.formatNumber(props.invoiceOffer.advance_ratio.amount)
              : ""}
          </h5>
        </div>
      </div>
      <div className="row mt-4  text_tra1">
        <div className="col-4">
          Discounting Fee ({props.invoiceOffer.discount?.percentage}%)
          <h5 className="text-white">
            $
            {helper.discountFeeHandler(
              props.invoiceOffer.discount?.percentage,
              props.invoiceOffer.advance_ratio?.amount,
              props.invoiceOffer.tenure
            )}
          </h5>
        </div>
        <div className="col-4">
          Tenure
          <h5 className="text-white">{props.invoiceOffer.tenure} Days</h5>
        </div>
        <div className="col-4">
          Comments
          <h5 className="text-white">
            <OverlayTrigger
              trigger="click"
              key="top"
              placement="top"
              overlay={
                <Popover id={`popover-positioned-top`}>
                  <Popover.Content>
                    {props.invoiceOffer.comment}
                  </Popover.Content>
                </Popover>
              }
            >
              <Button className="bgc-dark btn-secondary">
                <i className="uil-comment-alt-message" />
              </Button>
            </OverlayTrigger>
          </h5>
        </div>
      </div>
    </>
  );
}

import React, { Component } from "react";
import { URI } from "../../../constant";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PinInput from "react-pin-input";
class ForgotPin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pin: "",
      confirm_pin: "",
      CreateSecret: true,
      ConfirmSecret: true,
      errors: {
        confirm_pin: [],
        pin: [],
      },
      dirty: {
        confirm_pin: false,
        pin: false,
      },
    };
  }
  blurChanged = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };
  handleChange = (e) => {
    var { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;

    this.setState({ [name]: value, dirty: dirty }, this.validate);
  };
  validate = () => {
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      errors[control] = [];

      switch (control) {
        case "pin":
          //pin can't be blank
          if (!this.state[control]) {
            errors[control].push("Pin can't be blank");
          }
          if (this.state[control]) {
            if (
              this.state[control].length < 4 ||
              this.state[control].length > 4
            ) {
              errors[control].push("Pin should be equal to 4 character");
            }
          }
          break;
        case "confirm_pin":
          //pin can't be blank
          if (!this.state[control]) {
            errors[control].push("Confirm pin can't be blank");
          }
          if (this.state[control]) {
            if (
              this.state[control].length < 4 ||
              this.state[control].length > 4
            ) {
              errors[control].push(
                "Confirm pin should be equal to 4 character"
              );
            }
            if (parseInt(this.state[control]) != parseInt(this.state.pin)) {
              errors[control].push("Pin and Confirm pin should be same");
            }
          }
          break;
        default:
          break;
      }
    });

    //set errors
    this.setState({ errors });
  };

  //Checks state.errors property for error messages
  isValid = () => {
    let valid = true;

    for (let control in this.state.errors) {
      if (this.state.errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };

  handleSubmit = () => {
    this.validate();
    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });

    if (this.isValid()) {
      let data = {
        pin: this.state.pin,
        confirm_pin: this.state.confirm_pin,
      };

      axios
        .post(`${URI}/auth/forgot-pin/${this.props.match.params.id}`, data)
        .then((res) => {
          this.props.history.replace("/lender/success-pin");
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };
  render() {
    return (
      <div>
        <div style={{ fontFamily: "Averta-Semibold,sans-serif" }}>
          <div>
            <div className="alert-popup">
              <div className="mt-3 disp-lg-none">
                <div className="line mx-auto"></div>
              </div>

              <div className="d-flex justify-content-center ml-5">
                <div
                  className="d-flex  mt-5"
                  style={{ flexDirection: "column" }}
                >
                  <div className="kyc-pendingc  mt-5">
                    <h3 className="text-white">Create Secret PIN</h3>
                  </div>
                  <div className=" text_tra1">
                    <p>
                      This is your secret PIN which is going to be used
                      everytime you withdraw your assets.
                    </p>
                  </div>
                  <div className="mt-5">
                    <div className="text_tra1">Create Pin</div>
                    <div className="row my-3">
                      <PinInput
                        length={4}
                        initialValue={this.state.pin}
                        secret={this.state.CreateSecret}
                        type="numeric"
                        inputMode="number"
                        style={{ padding: "10px" }}
                        inputStyle={{
                          color: "#ffffff",
                          background: "#212022",
                          border: "none",
                          borderBottom: "3px solid #595959",
                        }}
                        onChange={(value, index) => {
                          this.setState(
                            {
                              pin: value,
                            },
                            this.validate
                          );
                        }}
                        name="pin"
                        inputFocusStyle={{ borderColor: "rgb(68 172 197)" }}
                        autoSelect={true}
                      />

                      <div className="mt-4">
                        <i
                          className={
                            this.state.CreateSecret
                              ? "ml-3 mr-2 uil-eye text_tra1 curserP "
                              : "text_tra1 ml-3 mr-2 uil-eye-slash curserP "
                          }
                          onClick={() => {
                            this.setState({
                              CreateSecret: !this.state.CreateSecret,
                            });
                          }}
                        />
                        <span className="txt_b">
                          {this.state.CreateSecret ? "Show" : "Hide"}
                        </span>
                      </div>
                    </div>
                    <div className="text-danger">
                      {this.state.errors.pin[0] && this.state.dirty.pin
                        ? this.state.errors.pin
                        : ""}
                    </div>
                    <div className="text_tra1">Confirm Pin</div>
                    <div className="row my-3">
                      <PinInput
                        length={4}
                        initialValue={this.state.pin}
                        secret={this.state.ConfirmSecret}
                        type="numeric"
                        inputMode="number"
                        name="confirm_pin"
                        style={{ padding: "10px" }}
                        inputStyle={{
                          color: "#ffffff",
                          background: "#212022",
                          border: "none",
                          borderBottom: "3px solid #595959",
                        }}
                        onChange={(value, index) => {
                          this.setState(
                            {
                              confirm_pin: value,
                            },
                            this.validate
                          );
                        }}
                        inputFocusStyle={{ borderColor: "rgb(68 172 197)" }}
                        autoSelect={true}
                      />

                      <div className="mt-4">
                        <i
                          className={
                            this.state.ConfirmSecret
                              ? "ml-3 mr-2 uil-eye text_tra1 curserP "
                              : "text_tra1 ml-3 mr-2 uil-eye-slash curserP "
                          }
                          onClick={() => {
                            this.setState({
                              ConfirmSecret: !this.state.ConfirmSecret,
                            });
                          }}
                        />
                        <span className="txt_b">
                          {this.state.ConfirmSecret ? "Show" : "Hide"}
                        </span>
                      </div>
                    </div>
                    <div className="text-danger">
                      {this.state.errors.confirm_pin[0] &&
                      this.state.dirty.confirm_pin
                        ? this.state.errors.confirm_pin
                        : ""}
                    </div>
                    <div>
                      <button
                        className="btn-blue my-2"
                        onClick={this.handleSubmit}
                      >
                        Create PIN
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ForgotPin);

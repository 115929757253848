import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import MainHeader from "../mainHeader";
import Logo from "../../../assets/images/Logo.svg";
import styles from "../../../assets/css/OnboardingPersonalDetails.module.css";
import { withRouter } from "react-router-dom";

class header extends Component {
  render() {
    return (
      <nav className="d-flex  Navbar">
        <img
          style={{ cursor: "pointer", width: "173px" }}
          onClick={() => this.props.history.push("/dashboard")}
          className={styles.Logo}
          src={Logo}
          alt=""
        />
        <div
          style={{
            height: "91px",
            borderRight: "1px solid gray",
            marginLeft: "227px",
          }}
        ></div>
        <div className="text-white d-flex align-items-center">
          <i
            style={{ fontSize: "20px", cursor: "pointer" }}
            className="ml-4 uil-arrow-left"
            onClick={() => this.props.history.push("/dashboard")}
          ></i>
          <span>invoices</span>
        </div>

        <div className="user-wrapper ml-auto mr-4">
          <MainHeader />
        </div>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </nav>
    );
  }
}
export default withRouter(header);

import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import { useHistory } from "react-router-dom";
import Alert from '../../../assets/images/alert.svg'
import { URLS } from "../../../config/frontendUrls";

export default function Kycpopup(props) {
    const history = useHistory();

    return (
        <div>
             <div style={{fontFamily:"Averta-Semibold,sans-serif"}}>
        
           <Modal
    {...props}
    aria-labelledby="contained-modal-title-vcenter"
    centered
style={{backgroundColor:'black'}}  >
    <div style={{backgroundColor:'#333333',color:'white',border:'0'}}> 
        <Modal.Header closeButton>

        <Modal.Title id="contained-modal-title-vcenter" style={{fontSize:'16px',lineHeight:'24px'}} >
        {props.buyerData ? "Buyer is Not Added " : "Please Complete KYC"}
        </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{textAlign:'center'}} >
        <div style={{margin:'0 auto',width:'100%',height:'100%',fontSize:'16px',lineHeight:'24px'}}>
            <div style={{paddingTop:'35px'}}><img src={Alert}  className="user-icon my-2" alt="" /></div>
            {props.buyerData ? "Please add a buyer first." : "KYC needs to be completed to proceed further."}</div>
        <div style={{margin:'20px',alignItems:'center'}}><Button onClick={()=>history.replace(URLS.CLIENT.BUYER_LIST)}   style={{backgroundColor:"#2DA3B4"}}>{props.buyerData ? "Add Buyer" :"Complete Now"}</Button></div>
        </Modal.Body>
        </div>
        </Modal>
        </div>
        </div>
    )
}

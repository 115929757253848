import React, { useEffect, useState, Component } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import Sidebar from "./Dashboard/Sidebar";
import MainHeader from "../Lender/Dashboard/mainHeader";
import "../../assets/css/calculator.css";
import { getPools, postPool } from "../../services/actions/poolAction";
//import {getCoins} from '../../services/actions/coinActions';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import ajax from "../../utils/ajax";
import { URI } from "../../constant";
import moment from "moment";
import LendNowpopup from "./Common/lendnow";
import { createLendNow } from "../../api/lendNowService";
import KycPendingModal from "./Common/kycPending";
import Kycpopup from "./Common/kycpopup";
// react-bootstrap components
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/wallet.css";
import "../../assets/css/calculator.css";
import Bottomnav from "./Dashboard/bottomnav";
import MobHeader from "./Dashboard/MobHeader";
import { KYC_STATUS, LEND_NOW_STATUS } from "../../config/string_constants";
import {
  getPoolByUserId,
  getPoolHistoryForLender,
} from "../../api/poolService";
// import { SliderComponent } from '@syncfusion/ej2-react-inputs';

class Calculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coindata: { current_price: 0, name: "", id: "", image: "", symbol: "" },
      coin: [],
      loading: false,
      pool: {
        minimum_locked_amount: "",
        day_apy: [],
        lending_users: [],
        logo_url: null,
      },
      poolType: "Active",
      pools: [],
      reward: "0",
      apy_array: { apy_month: "", percentage: "", is_active: false },
      coindata: [],
      values: "",
      amount: 0,
      coinG: [],
      modelLendNowshow: false,
      est_intrest: 0,
      est_reward: 0,
      total_reward: 0,
      // setCoins:'',
      setcoindata: {},
      settotalReward: "",
      setvalue: "",
      validateAmount: false,
      validateError: "",
      setCoins: [],
      userTokenBalance: "",
      selectedCoin: 0,
      inputAmount: "",
      amountInDollors: 0,
      progress: 0,
      lendNowdata: {},
      tenure: 0,
      selectedPool: [],
      selectedAPY: 0,
      selectedBounsAPY: 0,
      totalRewards: 0,
      total_bonus_reward_interest: 0,
      usdPrice: 0,
      steps: 0,
      est_percentage: 0,
      ActionToPerform: sessionStorage.getItem("ActionToPerform"),
      isLoggedIn: sessionStorage.getItem("isLoggedIn"),
      modalShow: sessionStorage.getItem("ShowKycPopup") === "true",
      kycStatus: sessionStorage.getItem("kycStatus"),
      modalKycPending: false,
      isLoading: false,
      headerMessage: "KYC Inprogress",
      mainMessage:
        "Please wait for KYC verification. After Verified you can proceed further.",
      walletData: {},
      poolsHistoryData: "",
    };
  }
  getPoolByUserId = async () => {
    let response = await getPoolByUserId(sessionStorage.getItem("userId"));
    this.setState({
      pools: response.data.data,
    });
  };

  getPoolHistoryForLender = async () => {
    let response = await getPoolHistoryForLender();
    this.setState({
      pools: response.data.data,
    });
  };
  showKycPendgin = async () => {
    this.setState({
      modalKycPending: true,
      headerMessage: "KYC Inprogress",
      mainMessage:
        "Please wait for KYC verification. After Verified you can proceed further.",
      // amount:this.state.reward
    });
  };
  getWalletDetails = async () => {
    try {
      const userData = await ajax(
        "GET",
        `${URI}/user/wallet/${sessionStorage.getItem("userId")}`,
        {}
      );
      const data = userData.data.result ? userData.data.result : {};
      this.setState({
        walletData: data,
      });
    } catch (e) {}
  };
  hideKycPending = async () => {
    this.setState({
      modalKycPending: false,
      // amount:this.state.reward
    });
  };
  kycNow = () => {
    this.props.history.push("/lender/profile");
  };
  hidekycNow = () => {
    sessionStorage.setItem("ShowKycPopup", false);
    this.setState({
      modalShow: false,
    });
  };

  ShowKyc = () => {
    this.setState({
      modalShow: true,
    });
  };

  /**
   * Updates calculation
   * @param {*} e
   */
  handlePoolChoise = (e) => {
    let choice = e.target.value;
    this.setState(
      {
        poolType: choice,
      },
      () => {
        if (choice === "Active") {
          this.getPoolByUserId();
        } else {
          this.getPoolHistoryForLender();
        }
      }
    );
  };
  handleCoinChange = (e) => {
    const selectedPoolData = this.state.pools.filter(
      (item) => item._id === e.target.value
    )[0];
    const currentPrice = this.state.coinG.filter(
      (item) => item.name === selectedPoolData.coin_id.name
    )[0];
    const selectedPool = selectedPoolData.day_apy;

    const current_price = currentPrice ? currentPrice.current_price : 0;
    const image = currentPrice ? currentPrice.image : "";
    const name = currentPrice ? currentPrice.name : "";
    const amount = this.state.inputAmount;
    const tenure = selectedPoolData.apy_month;
    this.setState(
      {
        coin: {
          ...this.state.coin,
          current_price: current_price,
          image: image,
          name: name,
        },
        tenure: tenure,
        selectedPool: selectedPool,
        amountInDollors: amount * current_price,

        lendNowdata: {
          ...this.state.lendNowdata,
          coin_id: selectedPoolData.coin_id,
          minimum_lock_amount: selectedPoolData.minimum_locked_amount,
          pool_id: selectedPoolData._id,
          total_deposited_amount: selectedPoolData.total_deposited_amount,
          bonus_reward: selectedPoolData.bonus_apy_data,
          tnc_status: false,
          max_amount: selectedPoolData.max_amount,
          est_apy: selectedPoolData.percentage,
        },
        selectedAPY: selectedPoolData.percentage,
        selectedBounsAPY: selectedPoolData.bonus_apy,
        selectValue: selectedPoolData.apy_month,
        progress: parseInt(selectedPoolData.apy_month) / 30,
        // current_price
      },
      () => {
        this.calculateReward();
      }
    );
  };

  gcoindata = async () => {
    const res = await fetch(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false"
    );
    const realdata = await res.json();

    this.setState({
      coinG: realdata,
    });
  };

  getCoinGeckoPolyTread = async () => {
    try {
      // const poolCoinGecko = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=polytrade&vs_currencies=USD`);
      const poolCoinGecko = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=polytrade&vs_currencies=USD`
      );

      this.setState({
        usdPrice: poolCoinGecko.data.polytrade.usd,
      });
    } catch (error) {}
  };

  getdata = (data) => {
    this.setState({
      setcoindata: data,
    });
  };

  validateAmount = (amount) => {
    if (amount < this.state.lendNowdata.minimum_lock_amount) {
      this.setState({
        validateAmount: true,
        validateError: "Amount is lower than minimum lock amount",
      });
    } else if (amount > this.state.lendNowdata.max_amount) {
      this.setState({
        validateAmount: true,
        validateError: "Amount is greater than pool available amount",
      });
    } else if (amount > this.state.userTokenBalance) {
      this.setState({
        validateAmount: true,
        validateError: "Amount is greater than available balance",
      });
    } else {
      this.setState({ validateAmount: false });
    }
  };
  onAmountchange = (e) => {
    this.validateAmount(e.target.value);
    this.setState(
      {
        inputAmount: e.target.value,
        amountInDollors:
          e.target.value *
          (this.state.coin.current_price ? this.state.coin.current_price : 0),
      },
      () => {
        this.calculateReward();
      }
    );
  };

  changeAmount = (amount) => {
    this.setState({ inputAmount: amount });
  };

  handleValidation = () => {};
  numberWithCommas = (x) => {
    return Number(x)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  claimUserBonusApy = (amt, bonusApy) => {
    if (parseFloat(amt) >= parseFloat(bonusApy.minimum_locked_amount)) {
      const bonus_reward_interest =
        (parseFloat(amt) *
          parseFloat(this.state.lendNowdata.bonus_reward.bonus_apy_percentage) *
          1) /
        100;
      const bonus_reward_interest_perDay = bonus_reward_interest / 365;
      const total_bonus_reward_interest =
        parseFloat(bonus_reward_interest_perDay) *
        parseFloat(this.state.lendNowdata.selected_duration);
      let lendData = { ...this.state.lendNowdata };
      lendData.user_bonus_checked = true;
      lendData.total_bonus_reward_interest = total_bonus_reward_interest;
      this.setState({
        lendNowdata: lendData,
      });
    }
  };
  postLendNow = async () => {
    let data = {
      amount: parseFloat(this.state.lendNowdata.lock_amount),
      pool_id: this.state.lendNowdata.pool_id,
      duration: this.state.lendNowdata.selected_duration,
      user_id: sessionStorage.getItem("userId"),
      tnc_status: true,
      user_bonus_checked: false,
    };
    if (this.state.lendNowdata.user_bonus_checked) {
      data.user_bonus_checked = this.state.lendNowdata.user_bonus_checked;
    }
    if (this.state.lendNowdata.tnc_status) {
      if (
        parseFloat(this.state.lendNowdata.minimum_lock_amount) <= data.amount &&
        data.amount <=
          parseFloat(
            this.state.lendNowdata.max_amount -
              parseFloat(this.state.lendNowdata.total_deposited_amount)
          )
      ) {
        this.setState({ loading: true }, async () => {
          let lendNowCreateResponse = await createLendNow(data);
          if (
            lendNowCreateResponse &&
            lendNowCreateResponse.data.success === true &&
            (lendNowCreateResponse.status === 200 ||
              lendNowCreateResponse.status === 201)
          ) {
            this.getPoolByUserId();
            toast.info(lendNowCreateResponse.message);
          } else if (lendNowCreateResponse) {
            toast.error(lendNowCreateResponse.message);
          }

          this.setState({
            loading: false,
            modelLendNowshow: false,
            lendNowdata: {},
          });
        });
      } else {
        if (
          parseFloat(this.state.lendNowdata.minimum_lock_amount) >= data.amount
        ) {
          toast.error("Amount is lower than minimum deposit.");
        } else {
          toast.error("Amount is higher than available amount for the pool.");
        }
      }
    } else {
      toast.error("Term and conditions not accepted");
    }
  };

  ShowLendNow = async () => {
    if (this.state.lendNowdata.coin_id) {
      const coinId = this.state.lendNowdata.coin_id._id;
      const response = await ajax(
        "GET",
        `${URI}/coin/user/status/${coinId}`,
        {},
        {}
      );
      if (this.state.isLoggedIn === "true") {
        if (this.state.ActionToPerform === "true") {
          if (!response.data.approved) {
            this.setState({
              modalKycPending: true,
              headerMessage: "ALERT",
              mainMessage:
                "Please approve coin spending limit in your wallet section to lend on the polytrade system.",
            });
          } else {
            this.setState({
              modelLendNowshow: true,
            });
          }
        } else {
          if (
            this.state.kycStatus === KYC_STATUS.NOT_STARTED ||
            this.state.kycStatus === KYC_STATUS.FINAL_REJECTED
          ) {
            this.setState({
              modalShow: true,
            });
          } else if (this.state.kycStatus === KYC_STATUS.PENDING) {
            this.showKycPendgin();
          } else {
            document.getElementById("exampleModal").style.display = "block";
            this.setState({
              modelLendNowshow: true,
            });
          }
        }
      } else {
        this.props.history.push("/lender/login");
      }
    } else {
      toast.error("Please select a coin first");
    }
  };

  calculateReward = async () => {
    const est_intrest =
      (parseFloat(this.state.amountInDollors) *
        parseFloat(this.state.selectedAPY)) /
      100;

    const bouns_intrest =
      (parseFloat(this.state.amountInDollors) *
        parseFloat(
          this.state.selectedBounsAPY ? this.state.selectedBounsAPY : 0
        )) /
      100;

    const reward_per_day = est_intrest / 365;
    const bouns_per_day = bouns_intrest / 365;
    const est_interest = reward_per_day * this.state.tenure;
    const estRewadDaller = bouns_per_day * this.state.tenure;
    let lendData = { ...this.state.lendNowdata };
    lendData.selected_duration = this.state.selectValue;
    lendData.total_accure_intrest = est_interest.toFixed(2);
    lendData.total_bouns_intrest = estRewadDaller.toFixed(2);
    lendData.total_intrest =
      parseFloat(est_interest) + parseFloat(estRewadDaller)
        ? parseFloat(est_interest) + parseFloat(estRewadDaller)
        : 0;
    lendData.accure_intrest_per_day =
      reward_per_day / parseFloat(this.state.usdPrice);
    lendData.bouns_apy = this.state.selectedBounsAPY;
    lendData.bouns_intrest_per_day =
      bouns_per_day / parseFloat(this.state.usdPrice);
    lendData.lending_id = this.state.lendNowdata._id;
    lendData.duration = this.state.selectValue;
    lendData.lock_amount = this.state.inputAmount;

    lendData.est_apy = this.state.selectedAPY;
    lendData.total_amount = parseFloat(estRewadDaller);
    lendData.accrue_days = 2;
    lendData.cumulative_interest = 0;
    lendData.redemption_date = moment()
      .add(parseFloat(this.state.selectValue), "days")
      .format("D MMM  YYYY, h:mm")
      .toString();
    lendData.status = LEND_NOW_STATUS.ACTIVE;
    lendData.subscription_date = moment()
      .format("D MMM  YYYY, h:mm")
      .toString();
    lendData.interest_end_date = moment()
      .add(parseFloat(this.state.selectValue), "days")
      .format("D MMM  YYYY, h:mm")
      .toString();
    lendData.interest_date = moment()
      .add(1, "days")
      .format("D MMM  YYYY, h:mm")
      .toString();
    lendData.est_next_distribution_amount = "19.00";
    lendData.interest_period = 1;
    lendData.selectValue = 0;
    lendData.lender_address = this.state.walletData
      ? this.state.walletData.address
      : "";
    lendData.user_bonus_checked = false;
    lendData.total_bonus_reward_interest = 0;
    this.setState({
      lendNowdata: lendData,
      est_intrest: est_interest.toFixed(2),
      est_reward: estRewadDaller.toFixed(2),
      totalRewards: (est_interest + estRewadDaller).toFixed(2),
      est_percentage:
        (parseFloat(est_interest + estRewadDaller) /
          parseFloat(this.state.amountInDollors)) *
        100,
    });
  };

  agreementChecked = (e) => {
    let lendData = { ...this.state.lendNowdata };
    lendData.tnc_status = e.target.checked;
    this.setState({
      lendNowdata: lendData,
    });
  };
  progress(stepNum) {
    let p = stepNum;
    let number = parseInt(p) + 1;
    let selectValue = (number * 3 * 10).toString();
    const selectedAPY = this.state.selectedPool
      ? this.state.selectedPool.filter(
          (item) => item.apy_month === selectValue
        )[0]
        ? this.state.selectedPool.filter(
            (item) => item.apy_month === selectValue
          )[0].percentage
        : 0
      : 0;
    const selectedBounsAPY = this.state.selectedPool
      ? this.state.selectedPool.filter(
          (item) => item.apy_month === selectValue
        )[0]
        ? this.state.selectedPool.filter(
            (item) => item.apy_month === selectValue
          )[0].bonus_apy
        : 0
      : 0;

    this.setState(
      {
        // progress: number,
        selectedAPY: selectedAPY,
        selectedBounsAPY: selectedBounsAPY,
        selectValue: selectValue,
      },
      () => {
        if (selectedAPY === 0) {
          this.setState({
            isLoading: true,
          });
        } else {
          this.setState({
            isLoading: false,
          });
          this.calculateReward();
        }
      }
    );
    if (stepNum == 0) {
      document.getElementsByClassName("percent")[0].style.width = `${
        number * 12
      }%`;
    } else if (stepNum == 1) {
      document.getElementsByClassName("percent")[0].style.width = `33%`;
    } else if (stepNum == 2) {
      document.getElementsByClassName("percent")[0].style.width = `55%`;
    } else {
      document.getElementsByClassName("percent")[0].style.width = `100%`;
    }

    let element = document.getElementsByClassName(`step-1`);

    Array.prototype.forEach.call(element, (e) => {
      e.classList.remove("selected-step");
    });
    try {
      this.state.steps.forEach((e) => {
        let els = document.getElementsByClassName(`st-${e.id}`)[0];

        if (e.id && e.id === stepNum) {
          e.classList.add("completed");

          // e.classList.remove('completed');
        }
        if (e.id && e.id < stepNum) {
          e.classList.add("completed");
          // els.classList.remove('selected-step');
        }
        if (e.id && e.id > stepNum) {
          e.classList.remove("selected-step");
          e.classList.remove("completed");
          // els.classList.remove('selected-step');
        }
      });
    } catch (err) {}
  }

  /**
   * Updates token balance
   * @param {*} data
   */
  tokenBal = (data) => {
    this.setState({ userTokenBalance: data }, () => {
      this.validateAmount(this.state.inputAmount);
    });
  };

  componentDidMount() {
    this.gcoindata();
    this.getCoinGeckoPolyTread();
    this.getWalletDetails();

    let els = document.getElementsByClassName("step");
    let steps = [];
    Array.prototype.forEach.call(els, (e) => {
      steps.push(e);
      this.setState({
        steps: steps,
      });
      e.addEventListener("click", (x) => {
        this.progress(x.target.id);
      });
    });
    this.getPoolByUserId();
  }

  handleProgress = (e) => {
    const selectedAPY = this.state.selectedPool
      ? this.state.selectedPool.filter(
          (item) => item.apy_month === e.target.value
        )[0]
        ? this.state.selectedPool.filter(
            (item) => item.apy_month === e.target.value
          )[0].percentage
        : 0
      : 0;

    this.setState(
      {
        progress: e.target.value,
        selectedAPY: selectedAPY,
      },
      () => {
        this.calculateReward();
      }
    );
  };

  render() {
    const labels = [60, 90, 120];

    return (
      <>
        <Sidebar />
        <Bottomnav />

        <div className="main-content main-content-lender">
          <header className="disp-sm-none">
            <h2 className="heading" style={{ marginLeft: "10px" }}></h2>
            <MainHeader />
          </header>
          <MobHeader />

          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Container fluid>
            {this.state.modelLendNowshow ? (
              <LendNowpopup
                amountError={this.state.amountError}
                changeLockAmount={this.onAmountchange}
                changeAmount={this.changeAmount}
                tokenBal={this.tokenBal}
                validateAmount={this.state.validateAmount}
                validateError={this.state.validateError}
                postLendNow={this.postLendNow}
                agreementChecked={this.agreementChecked}
                lendNowdata={this.state.lendNowdata}
                loading={this.state.loading}
                claimUserBonusApy={this.claimUserBonusApy}
                show={this.state.modelLendNowshow}
                onHide={() => {
                  this.setState({ modelLendNowshow: false });
                }}
              />
            ) : (
              ""
            )}
            <div className="headingmob">
              <h3
                style={{ color: "white", marginTop: "23px", fontSize: "24px" }}
              >
                Rewards Calculator
              </h3>
            </div>
            <Row>
              <Col lg={7}>
                <KycPendingModal
                  headerMessage={this.state.headerMessage}
                  mainMessage={this.state.mainMessage}
                  amount={0}
                  rewardDaller={0}
                  claimRewards={0}
                  hideReward={this.hideKycPending}
                  show={this.state.modalKycPending}
                  onHide={() => this.setState({ modalKycPending: false })}
                />
                <Kycpopup
                  hidekycNow={this.hidekycNow}
                  kycNow={this.kycNow}
                  show={this.state.modalShow}
                  onHide={() => this.setState({ modalShow: false })}
                />
                <div className="rewards mb-4">
                  <div className="reward-head">
                    <span className="reward-detail ">
                      Current Price
                      <div className="font-style-6 font-style-Averta-Semibold">
                        $
                        {this.numberWithCommas(
                          this.state.coin.current_price
                            ? this.state.coin.current_price
                            : 0
                        )}
                      </div>
                    </span>
                    <select
                      style={{
                        overflow: "auto",
                        background: "#333333",
                        width: "153px",
                      }}
                      className="form-select lg-4 font-style-Averta-Semibold mr-4"
                      value={this.state.poolType}
                      onChange={this.handlePoolChoise}
                    >
                      <option value="Active"> Active Pool</option>
                      <option value="History">Pool History</option>
                    </select>
                    {this.state.coin.image ? (
                      <span className="mx-2">
                        <img
                          alt="img"
                          style={{ height: "25px", width: "25px" }}
                          className="mt-2"
                          src={this.state.coin.image}
                        />{" "}
                      </span>
                    ) : (
                      // {this.state.coin.name}
                      ""
                    )}

                    {this.state.pools === undefined ? (
                      <select
                        className="font-style-11  font-style-Averta-Semibold ml-3"
                        style={{ height: "40px", width: "100px" }}
                      >
                        <option>loading</option>
                      </select>
                    ) : (
                      <select
                        style={{
                          overflow: "auto",
                          background: "#333333",
                          width: "153px",
                        }}
                        className="form-select lg-4 font-style-Averta-Semibold"
                        value={this.state.pool.name}
                        onChange={this.handleCoinChange}
                      >
                        <option value="" selected disabled hidden>
                          Choose coin...
                        </option>
                        {this.state.pools
                          .filter((pp) => {
                            return pp.is_active ? pp : "";
                          })
                          .map((pool, i) => (
                            <option key={i} value={pool._id}>
                              {" "}
                              {pool.coin_id && pool.coin_id.name}&nbsp;-&nbsp;
                              {pool.apy_month} Days
                            </option>
                          ))}
                      </select>
                    )}
                  </div>
                  <Form className="w-100">
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label className="mt-5">
                        How much {this.state.coin.name} you want to deposit?
                        &nbsp;(Minimum amount is :{" "}
                        {this.state.lendNowdata.minimum_lock_amount
                          ? this.state.lendNowdata.minimum_lock_amount
                          : 0}
                        )
                      </Form.Label>

                      <Form.Control
                        className="col-12 font-style-11 input-style font-style-Averta-Semibold custom-textbox"
                        type="number"
                        placeholder=""
                        value={this.state.inputAmount}
                        onChange={this.onAmountchange}
                      />
                    </Form.Group>
                    <div>
                      <h6 className="text-white">
                        Tenure : {this.state.tenure}
                      </h6>
                    </div>
                  </Form>
                </div>
              </Col>
              <Col lg={5}>
                <div className="rewards">
                  <h3 style={{ color: "white" }}>Rewards</h3>
                  <div className="total-rewards">
                    <h2 style={{ color: "white" }}>
                      $
                      {this.numberWithCommas(
                        this.state.totalRewards > 0 ||
                          this.state.totalRewards === 0
                          ? this.state.totalRewards
                          : 0
                      )}
                      <sub className="font-style-18">
                        {" "}
                        (
                        {this.numberWithCommas(
                          this.state.est_percentage
                            ? this.state.est_percentage
                            : 0
                        )}
                        %)
                      </sub>
                    </h2>
                    <p className="txt-tra font-style-18">Total Rewards</p>
                  </div>
                  <Row>
                    <Col className="reward-element">
                      {this.numberWithCommas(this.state.selectedAPY)}%
                      <p className="txt-tra font-style-18">Standard APY</p>
                    </Col>
                    <Col className="reward-element ">
                      ${this.numberWithCommas(this.state.est_intrest)}
                      <p className="txt-tra font-style-18">
                        Est.Interest Earned
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="reward-element ">
                      {this.numberWithCommas(this.state.selectedBounsAPY)}%
                      <p
                        className="txt-tra font-style-18"
                        title="Bonus reward APY. Only issued in TRADE tokens."
                      >
                        Bonus APY <i className="uil-info-circle" />
                      </p>
                    </Col>
                    <Col className="reward-element ">
                      ${this.numberWithCommas(this.state.est_reward)}
                      <p className=" txt-tra font-style-18">
                        Est.Rewards Earned
                      </p>
                    </Col>
                  </Row>
                  <div style={{ textAlign: "center", marginTop: "12px" }}>
                    {this.state.poolType === "Active" && (
                      <Button
                        onClick={this.ShowLendNow}
                        disabled={this.state.isLoading}
                        className="btn mt-3 btn-lg w-75 long-w-100"
                        style={{
                          background: "#2DA3B4",
                          borderRadius: "4px",
                        }}
                      >
                        Lend now
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  pool: state.pool,
  coin: state.coin,
});
const mapDispatchToProps = (dispatch) => ({
  getPools: (data) => dispatch(getPools(data)),
  postPool: (data) => dispatch(postPool(data)),
  // getCoins:(data) => dispatch(getCoins(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Calculator));
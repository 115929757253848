import {URI} from '../../constant'

export const HOME_GET_PRICING_CONSTANTS = ({
    URL: URI+"/pricing/",
    REQUEST: 'REQUEST_GET_PRICING_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_GET_PRICING_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_GET_PRICING_DATA_CONSTANTS',
  });

  export const HOME_POST_PRICING_CONSTANTS = ({
    URL: URI+"/pricing",
    REQUEST: 'REQUEST_POST_PRICING_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_POST_PRICING_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_POST_PRICING_DATA_CONSTANTS',
  });
import moment from "moment";

const helper = {
  capitalizeFirstLetter(string) {
    string += "";
    return string[0].toUpperCase() + string.toString().slice(1);
  },

  /* use to got discount in soft offer*/
  discountFeeHandler(percentage, amount, tenure) {
    if (!percentage || !amount || !tenure) {
      return;
    }
    return Number((percentage / 100) * (amount / 365) * tenure)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  },

  isEmpty(item) {
    if (typeof item === "undefined") {
      // undefined
      return true;
    }
    if (typeof item === "string" && item.trim().length === 0) {
      // string
      return true;
    }
    if (Object.prototype.toString.call(item) === "[object Null]") {
      // null
      return true;
    }
    if (
      Object.prototype.toString.call(item) === "[object Object]" &&
      Object.keys(item).length === 0
    ) {
      // object
      return true;
    }
    if (
      Object.prototype.toString.call(item) === "[object Array]" &&
      item.length === 0
    ) {
      // array
      return true;
    }

    return false;
  },

  isNotEmpty(item) {
    return !this.isEmpty(item);
  },

  isFunction(item) {
    return (
      this.isNotEmpty(item) &&
      Object.prototype.toString.call(item) === "[object Function]"
    );
  },

  /**
   * Function to check if 'numberString' is signed/unsigned float.
   * Example:
   * Input "12345" returns true.
   * Input "+12345" returns true.
   * Input "-12345" returns true.
   * Input "12345.123" returns true.
   * Input "+12345.123" returns true.
   * Input "-12345.123" returns true.
   * Input "12E45" returns false.
   */
  isValidFloat(numberString) {
    const floatRegex = /^[-+]?[0-9]*(\.[0-9]+)?$/;

    // if not empty and abides to regex return true
    if (!this.isEmpty(numberString) && floatRegex.test(numberString)) {
      return true;
    }

    return false;
  },

  isValidNaturalNumber(numberString) {
    const nnRegex = /^[1-9]+[0-9]*$/;

    // if not empty and abides to regex return true
    if (!this.isEmpty(numberString) && nnRegex.test(numberString)) {
      return true;
    }

    return false;
  },

  isDigit(numberString) {
    const dRegex = /^[0-9]*$/;

    // if not empty and abides to regex return true
    if (!this.isEmpty(numberString) && dRegex.test(numberString)) {
      return true;
    }

    return false;
  },

  /**
   * Function to check if 'inputString' is valid name.
   * Example:
   * Input "dh ee" returns true.
   * Input "--dhee" returns true.
   * Input "dhee--" returns true.
   * Input "--" returns true.
   * Input "dh - ee" returns true.
   * Input "dhee$m" returns false.
   * Input "$dhee " returns false.
   */
  isValidName(inputString) {
    const nameRegex = /^[a-z\-]+(?: +[a-z\-]+)*$/i;

    // if not empty and abides to regex return true
    if (!this.isEmpty(inputString) && nameRegex.test(inputString)) {
      return true;
    }

    return false;
  },

  /**
   * Function to check if 'inputString' is valid email.
   */
  isValidEmail(inputString) {
    /**
     * Below regex is causing "too much recursion" error in React.js.
     * Need to debug later, hence providing a simpler regex without groups.
     */
    // let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const emailRegex = /^[a-z0-9\.\_\-]+@[a-z0-9\.\_\-]+\.[a-z]{2,}$/i;

    // if not empty and abides to regex return true
    if (!this.isEmpty(inputString) && emailRegex.test(inputString)) {
      return true;
    }

    return false;
  },

  /**
   * Function to check if password is valid
   */

  isValidPassword(password) {
    const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return regex.test(password);
  },

  /**
   * Function to check if 'inputString' is valid username.
   * Input "dheemanth" return true.
   * Input "dheemanth-bhat" return true.
   * Input "dheemanth.bhat" return true.
   * Input "7dheemanth.bhat7" return true.
   * Input "dheemanth Bhat" return false.
   */
  isValidUsername(inputString) {
    const usernameRegex = /^[a-z0-9\.\_\-\@]+$/i;

    // if not empty and abides to regex return true
    if (!this.isEmpty(inputString) && usernameRegex.test(inputString)) {
      return true;
    }

    return false;
  },

  isArray(array) {
    return (
      Object.prototype.toString.call(array) === "[object Array]" && array.length
    );
  },

  isValidDate(date) {
    return Date.parse(date) || false;
  },

  getValidInteger(inputNumber) {
    const self = this;
    let outputNumber;

    if (self.isValidInteger(inputNumber)) {
      outputNumber = parseInt(inputNumber);
    } else {
      outputNumber = 0;
    }

    return outputNumber;
  },

  isValidInteger(numberString) {
    // if not empty and number-string contains only digits
    const digitsRegex = /^[+-]?[0-9]+$/;
    if (!this.isEmpty(numberString) && digitsRegex.test(numberString)) {
      return true;
    }

    return false;
  },

  getDateTime(days, time) {
    const d1990_1_1 = new Date(1990, 0, 1, 0, 0, 0, 0);
    const dateTime = new Date(d1990_1_1.setDate(d1990_1_1.getDate() + days));
    dateTime.setMilliseconds(time - dateTime.getTimezoneOffset() * 60000);
    return dateTime;
  },

  roundOffNumbers(number, decimalPlace = 2) {
    if (
      !isNaN(number) &&
      number > 0 &&
      !isNaN(decimalPlace) &&
      decimalPlace > 0
    ) {
      const numToMul = 10 ** decimalPlace;
      return Math.round(number * numToMul) / numToMul;
    }
    return number;
  },

  isBool(value) {
    if (typeof value === "boolean") {
      return true;
    }
    return false;
  },

  hasClassName(classString, className) {
    if (this.isNotEmpty(classString) && this.isNotEmpty(className)) {
      return classString.includes(className);
    }

    return false;
  },

  charLengthCheck(string, charLength) {
    if (
      string &&
      this.isValidInteger(charLength) &&
      string.length > charLength
    ) {
      return true;
    }

    return false;
  },

  sortArray(array) {
    if (array && this.isArray(array)) {
      const temp = array;
      temp.sort((a, b) => {
        return b - a;
      });
      return temp;
    }
    return [];
  },

  getPerDayInterest(roi, amount) {
    return (parseFloat(amount) * parseFloat(roi)) / 100 / 365;
  },

  calculateTotalInterest(roi, amount, day) {
    return ((parseFloat(amount) * parseFloat(roi)) / 100 / 365) * parseInt(day);
  },

  calculateDiscountFee(percent, amt, tenure, lateDay) {
    const data =
      ((parseFloat(percent) * parseFloat(amt)) / 100 / 365) *
      (parseInt(tenure) - parseInt(lateDay));
    return data;
  },
  calculateFactoringFee(percent, amt) {
    const data = (parseFloat(percent) * parseFloat(amt)) / 100;
    return data;
  },

  calculateNumberOfLateDays(invoice_due_date, grace_period, payment_date) {
    const dateDiff = moment(payment_date).diff(
      moment(invoice_due_date).format("YYYY-MM-DD"),
      "days"
    );

    const lateDays = dateDiff - grace_period;

    return Math.max(lateDays, 0);
  },
  calculateNetPayableToClientHandler(
    total_amount_received,
    amount_to_be_funded,
    fee
  ) {
    const data = (total_amount_received - amount_to_be_funded - fee).toFixed(2);
    return data;
  },
  calculateFinanceTenure(payment_recieved_date, advance_payment_date) {
    const financeTenure = moment(payment_recieved_date).diff(
      moment(advance_payment_date).format("YYYY-MM-DD"),
      "days"
    );
    return Math.max(financeTenure + 1, 0);
  },

  calculateLateFeesHandler(lateFee, advanceAmount, lateDays) {
    return (lateFee / 100) * (advanceAmount / 365) * lateDays;
  },

  formatNumber(data) {
    return Number(data)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  },

  validateFileType(file) {
    const fileTypes = [
      "image/jpg",
      "image/png",
      "image/jpeg",
      "application/pdf",
    ];
    if (fileTypes.includes(file[0].type) && file[0].size < 5000000) {
      let files = file;
      let reader = new FileReader();
      if (file[0] !== undefined) {
        reader.readAsDataURL(files[0]);
        reader.onLoad = (e) => {};
      }
      return true;
    }
  },

  calculateInterest(lockingPeriod, interest) {
    return (parseFloat(interest) * lockingPeriod)
  },

};


export default helper;

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getInvoiceDetailsById,
  getAllInvoices,
  updateInvoice,
  getInvoiceChanges,
  getInvoiceCreatedByUser,
} from "../../../services/actions/invoiceAction";
import { updateCompany } from "../../../services/actions/companyActions";
import { getInvoiceStatus } from "../../../services/actions/invoiceStatusAction";
import { postAlert } from "../../../services/actions/alertActions";
import {
  postSmartToken,
  getPricingData,
  postOffer,
} from "../../../services/actions/smartContractAction";
import { getPricingIDList } from "../../../services/actions/pricingActions";
import { getOfferById } from "../../../services/actions/invoiceOfferAction";
import { getExpiration } from "../../../services/actions/offerExpirationActions";
import Progressbar from "./progressBar";
import Header from "./header";
import Spinner from "react-bootstrap/Spinner";
import { INVOICE_STATUSES_KEY } from "../../../config/string_constants";
import { approveInvoiceByAdmin } from "../../../api/invoiceService";
import { CLIENT_ALERT_TYPES } from "../../../config/string_constants";
import {
  createFinalOffer,
  getInvoiceForAdmin,
} from "../../../api/invoiceService";
import BuyerDetails from "./buyerDetails";
import SoftOffer from "./softOfferDetails";
import InvoiceData from "./invoiceData";
import PricingDetails from "./pricingDetails";
import InvoiceMoreDetail from "./invoiceMoreDetail";
import InvoicesListId from "./invoicesListId";
import FinalOfferDetails from "./finalOfferDetails";
import DeclineInvoice from "./declineInvoice";
import { HELPERS } from "../../../helpers";

class finalOffer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyId: this.props.match.params.cId,
      InvoiceId: this.props.match.params.id,
      request_doc: false,
      invoiceCompany: [],
      invoicedetails: "",
      invoiceOffer: [],
      country: [],
      invoices: [],
      showPdf: false,
      pdfUrl: "",
      selected: false,
      buyerCompany: "",
      itemId: "",
      triggerSmartContract: false,
      offerDetails: false,
      regenerateFinalOffer: false,
      resendOffer: false,
      generateOffer: false,
      offerCreatedId: "",
      offerDoneId: "",
      offerDeclinedId: "",
      offerId: "",
      alertData: {
        invoice_id: "",
        invoice_ref_no: "",
        invoice_status_id: "",
        invoice_substatus_id: "",
        client_Id: "",
      },
      screen: INVOICE_STATUSES_KEY.FINAL_OFFER,
      finaloffer: [],
      alertSuccess: false,
      updateSuccess: false,
      sendToClient: false,
      clientCompanyId: "",
      getToken: true,
      tokenData: {
        username: "test",
        password: "test",
      },
      pricing_id: "",
      pricingDetails: false,
      triggerSuccess: false,
      priceSuccess: false,
      statusSuccess: false,
      tokenResponse: { pricing_id: "", access_token: "" },
      pricingData: [],
      isofferSent: false,
      isDeclined: false,
      newOffer: {
        invoice_id: "",
        amount: "",
        grade: "",
        tenure: "",
        pricing_id: "",
        factoring_fee: "",
        discount: "",
        advance_percentage: "",
        reserve_percentage: "",
        grace_period: "",
        bank_charges: "",
        amount_to_be_funded: "",
        late_fee: "",
      },

      smartOffer: null,
      offerSuccess: false,
      sendSuccess: false,
      loader: false,
      pricingList: [],
      showSmartOffer: true,

      dirty: {
        advance_percentage: false,
        factoring_fee: false,
        tenure: false,
        discount: false,
      },
      errors: {
        tenure: [],
        factoring_fee: [],
        discount: [],
        advance_percentage: [],
      },
    };
  }

  generateOffer = () => {
    this.validate();
    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });

    if (this.isValid()) {
      this.setState({ offerDetails: false });
      this.setState({ triggerSmartContract: false });
      this.setState({ generateOffer: true });
      this.setState({
        newOffer: {
          ...this.state.newOffer,
          reserve_percentage:
            100 -
            (this.state.newOffer.advance_percentage
              ? this.state.newOffer.advance_percentage
              : 0),
          amount_to_be_funded:
            ((this.state.newOffer.advance_percentage
              ? this.state.newOffer.advance_percentage
              : 0) *
              (this.state.newOffer.amount ? this.state.newOffer.amount : 0)) /
            100,
        },
      });
    }
  };

  showData = (item) => {
    this.setState(
      {
        invoicedetails: item,
        // offer:{...this.state.offer, invoice_amount: item.invoice_amount},
        newOffer: { ...this.state.newOffer, amount: item.invoice_amount },
        selected: true,
        InvoiceId: item._id,
        itemId: item._id,
        companyId: item.client_company,
        triggerSmartContract:
          item.final_offer && item.final_offer.advance_percentage === null
            ? true
            : false,
        invoiceCompany: item.client_company,
      },
      () =>
        this.props
          .getOfferById(item.invoice_id)
          .then((offer) => {
            this.setState({});

            this.props
              .getPricingIDList(item.invoice_amount, offer.tenure)
              .then((pricing) => {
                this.setState({
                  pricingList: pricing,
                });
              })
              .catch((err) => {});
          })
          .catch((err) => {})
    );
  };

  checkInvoiceAndOffer = (invoice) => {
    const isOfferSentToClientStatus = HELPERS.checkOfferSentToClient(
      invoice.invoiceStatus_id,
      invoice.invoiceSubStatus_id
    );
    const isInvoiceDeclinedStatus = HELPERS.checkInvoiceDecline(
      invoice.invoiceStatus_id.subStatus,
      invoice.invoiceSubStatus_id
    );
    this.setState({
      isofferSent: isOfferSentToClientStatus,
      isDeclined: isInvoiceDeclinedStatus,
    });
  };

  componentDidMount() {
    this.props.getInvoiceDetailsById(this.state.InvoiceId).then((result) => {
      this.checkInvoiceAndOffer(result);
      this.props.getInvoiceChanges(result);
      this.setState({
        invoicedetails: result,
        newOffer: { ...this.state.newOffer, amount: result.invoice_limit },
        company_id: result.client_company,
        selected: true,
        buyerCompany: result.buyer_company,
        itemId: result._id,
        clientCompanyId: result.client_company,
        triggerSmartContract:
          result.final_offer && result.final_offer.advance_percentage
            ? false
            : true,
        invoiceCompany: result.client_company,
      });
      getInvoiceForAdmin(result.created_by_id)
        .then((invoices) => {
          this.setState({
            invoices: invoices.data,
          });
        })
        .catch((err) => {});
      this.props
        .getOfferById(result._id)
        .then((offer) => {
          this.setState({
            invoiceOffer: offer,
          });

          this.props
            .getPricingIDList(result.invoice_limit, offer.tenure)
            .then((pricing) => {
              this.setState(
                {
                  pricingList: pricing,
                  tenure: offer.tenure,
                },
                () => {
                  for (let i = 0; i < this.state.pricingList.length; i++) {
                    if (
                      this.state.pricingList[i].pricing_id ===
                      this.state.finaloffer.pricing_id
                    ) {
                      this.setState({
                        pricingData: this.state.pricingList[i],
                      });
                      break;
                    }
                  }
                }
              );
            });
        })
        .catch((err) => {});

      let updateStatus =
        this.props.invoiceStatus.length !== undefined
          ? this.props.invoiceStatus.map((status) =>
              status.key === INVOICE_STATUSES_KEY.FINAL_OFFER
                ? status.subStatus !== undefined
                  ? status.subStatus.map((sub) =>
                      sub.statusName === "Created"
                        ? this.setState({
                            invoicestatus: this.props.invoiceStatus,
                            offerId: status._id,
                            offerCreatedId: sub._id,
                          })
                        : status.subStatus.map((sub) =>
                            sub.statusName === "Done"
                              ? this.setState({
                                  offerId: status._id,
                                  offerDoneId: sub._id,
                                })
                              : ""
                          )
                    )
                  : ""
                : ""
            )
          : "";
    });
  }

  onOfferChange = (e) => {
    let { newOffer } = this.state;
    switch (e.target.name) {
      default:
        newOffer[e.target.name] = e.target.value;
    }

    this.setState(
      { newOffer },
      () => {
        if (e.target.name === "advance_percentage") {
          this.setState({
            newOffer: {
              ...this.state.newOffer,
              reserve_percentage:
                100 -
                (this.state.newOffer.advance_percentage
                  ? this.state.newOffer.advance_percentage
                  : 0),
              amount_to_be_funded:
                ((this.state.newOffer.advance_percentage
                  ? this.state.newOffer.advance_percentage
                  : 0) *
                  (this.state.newOffer.amount
                    ? this.state.newOffer.amount
                    : 0)) /
                100,
              pricing_id: this.state.pricing_id,
              grade: this.state.pricingData.grade,
              // access_token: sessionStorage.getItem("smartToken")
            },
          });
        }
      },

      this.validate
    );
  };

  createFinalOffer = () => {
    this.validate();
    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });
    if (this.isValid()) {
      const data = this.state.invoicedetails;

      data.invoiceStatus_id = this.state.offerId;
      data.invoiceSubStatus_id = this.state.offerCreatedId;
      if (this.state.regenerateFinalOffer) {
        this.setState({
          regenerateFinalOffer: false,
          resendOffer: true,
          sendToClient: false,
        });
      }
      this.setState(
        {
          loader: true,
          showSmartOffer: false,
          alertData: {
            ...this.state.alertData,
            invoice_id: data._id,
            invoice_ref_no: data.invoice_id,
            invoice_status_id: this.state.offerId,
            invoice_substatus_id: this.state.offerCreatedId,
            client_Id: data.created_by_id,
          },
        },
        async () => {
          this.state.newOffer.invoice_id = data._id;
          await createFinalOffer(this.state.newOffer);

          toast.info("Offer Generated Successfully");
          this.props
            .getInvoiceDetailsById(this.state.InvoiceId)
            .then((invoice) => {
              this.setState({
                invoicedetails: invoice,
              });
              this.props.getInvoiceChanges(invoice);
            })
            .catch((err) => {});
          this.props
            .postAlert(this.state.alertData)
            .then((alert) => {
              this.setState({
                offerDetails: false,
                generateOffer: false,
                showSmartOffer: true,
                loader: false,
              });
            })
            .catch((err) => {});
        }
      );
    }
  };

  sendToClient = () => {
    const data = {
      invoiceStatus_id: this.state.offerId,
      invoiceSubStatus_id: this.state.offerDoneId,
      update_type: CLIENT_ALERT_TYPES.OFFER_MADE,
      _id: this.state.invoicedetails._id,
    };

    this.setState(
      {
        updateSuccess: true,
        resendOffer: false,
      },
      () => {
        approveInvoiceByAdmin(data)
          .then((result) => {
            toast.info("Final Offer Sent to Client");
            this.setState(
              {
                updateSuccess: false,
                alertSuccess: true,
                sendToClient: true,
              },
              () => {
                this.setState({ alertSuccess: false });
              }
            );
            const payload = {
              ...this.state.invoicedetails,
              arpa_document: null,
              borrower_arpa_document: null,
              ...data,
            };

            this.props.updateInvoice(payload);
            window.location.reload();
          })
          .catch((err) => {});
      }
    );
  };
  priceChange = (e) => {
    e.target.value !== "Pricing ID"
      ? this.setState(
          {
            pricing_id: e.target.value,
            pricingDetails: true,
          },
          () => {
            for (let i = 0; i < this.state.pricingList.length; i++) {
              if (
                this.state.pricingList[i].pricing_id === this.state.pricing_id
              ) {
                this.setState({
                  pricingData: this.state.pricingList[i],
                  priceSuccess: false,
                });
                break;
              }
            }
          }
        )
      : this.setState({
          pricingDetails: false,
        });
  };
  onTrigger = (e) => {
    this.setState(
      {
        offerDetails: true,
        triggerSmartContract: false,
        generateOffer: false,
        triggerSuccess: true,
      },
      () => {
        for (let i = 0; i < this.state.pricingList.length; i++) {
          if (this.state.pricingList[i].pricing_id === this.state.pricing_id) {
            this.setState({
              pricingData: this.state.pricingList[i],
              priceSuccess: false,
            });
            break;
          } else {
            this.setState({
              pricingData: "",
              triggerSuccess: false,
            });
          }
        }
      }
    );
  };

  regenerateFinalOffer = () => {
    if (this.isValid()) {
      this.setState({ offerDetails: false });
      this.setState({ generateOffer: false });
      this.setState({ regenerateFinalOffer: true });
      this.setState({ triggerSmartContract: true });
    }
  };

  validate = () => {
    let errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      errors[control] = [];
      switch (control) {
        case "advance_percentage":
          if (
            this.state.newOffer[control] <
              Number(this.state.pricingData.min_advance_ratio * 100).toFixed(
                0
              ) ||
            this.state.newOffer[control] >
              Number(this.state.pricingData.max_advance_ratio * 100).toFixed(0)
          ) {
            errors[control].push("Enter valid value for Advance Ratio");
          }

          break;
        case "discount":
          if (
            this.state.newOffer[control] <
              Number(this.state.pricingData.min_discount_range * 100) ||
            this.state.newOffer[control] >
              Number(this.state.pricingData.max_discount_range * 100)
          ) {
            errors[control].push("Enter valid value for Discounting Fee");
          }

          break;
        case "factoring_fee":
          if (
            this.state.newOffer[control] <
              Number(this.state.pricingData.min_factoring_fee * 100) ||
            this.state.newOffer[control] >
              Number(this.state.pricingData.max_factoring_fee * 100)
          ) {
            errors[control].push("Enter valid value for Factoring Fee");
          }

          break;
        case "tenure":
          if (
            this.state.newOffer[control] < this.state.pricingData.min_tenure ||
            this.state.newOffer[control] > this.state.pricingData.max_tenure
          ) {
            errors[control].push("Enter valid  value for Tenure");
          }

          break;
        default:
          break;
      }
    });
    this.setState({ errors });
  };
  isValid = () => {
    let valid = true;
    for (let control in this.state.errors) {
      if (this.state.errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };
  render() {
    return (
      <div>
        <Header />
        <div className="row m-4">
          <div className="col-3">
            <InvoiceMoreDetail
              invoicedetails={this.state.invoicedetails}
              invoiceCompany={this.state.invoiceCompany}
            />
          </div>
          <div className="col-9 overflow-auto">
            <Progressbar
              invoiceId={this.state.InvoiceId}
              screen={this.state.screen}
            />
          </div>
        </div>

        <div className="row m-2 my-4">
          <div className="col-6">
            <div className="info-one h-100">
              <div className="tab-content">
                <div className="tab-pane active" id="tabs-1" role="tabpanel">
                  {this.state.invoices === undefined ? (
                    <div>No data Available </div>
                  ) : (
                    this.state.invoices
                      .filter((item) => {
                        return (
                          item.invoice_id ===
                          this.state.invoicedetails.invoice_id
                        );
                      })
                      .map((item, index) => (
                        <div key={index}>
                          <InvoiceData
                            invoices={item}
                            tenure={this.state.tenure}
                            onPdfShow={this.onPdfShow}
                          />
                          <>
                            <div className="mt-5 text-center">
                              {item.invoiceStatus_id &&
                                item.invoiceStatus_id.subStatus &&
                                this.state.isDeclined && (
                                  <DeclineInvoice
                                    invoice={item}
                                    toastMessage="Final Offer Declined"
                                    updateType={CLIENT_ALERT_TYPES.FINAL_OFFER}
                                    invoiceStatus={this.props.invoiceStatus}
                                    currentStatusKey={
                                      INVOICE_STATUSES_KEY.FINAL_OFFER
                                    }
                                  />
                                )}
                            </div>
                          </>
                        </div>
                      ))
                  )}

                  <div>
                    {this.state.invoicedetails.final_offer &&
                      this.state.invoicedetails.final_offer
                        .advance_percentage &&
                      this.state.isDeclined && (
                        <div className="mt-5 text-center">
                          {this.state.resendOffer &&
                          !this.state.invoicedetails.is_advance_approved ? (
                            <button
                              className="btn-blue mx-2"
                              onClick={this.sendToClient}
                            >
                              Resend
                            </button>
                          ) : this.state.sendToClient === true ||
                            this.state.invoicedetails.final_offer_tnc_status ||
                            this.state.isofferSent ? (
                            <button
                              className="btn btn-outline-light mx-2"
                              disabled={true}
                            >
                              Sent
                            </button>
                          ) : (
                            <button
                              className="btn-blue mx-2"
                              onClick={this.sendToClient}
                            >
                              Send To Client
                            </button>
                          )}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="info-one h-100">
              <p className="text_tra1 mb-2">INVOICES</p>
              <InvoicesListId
                invoices={this.state.invoices}
                selected={this.state.selected}
                showData={this.showData}
                itemId={this.state.itemId}
              />

              {this.state.pricingDetails && (
                <PricingDetails
                  pricingData={this.state.pricingData}
                  show={false}
                />
              )}

              <BuyerDetails buyerDetails={this.state.buyerCompany} />
              <SoftOffer invoiceOffer={this.state.invoiceOffer} show={false} />
              {this.state.invoicedetails.final_offer?.amount_to_be_funded && (
                <>
                  <FinalOfferDetails
                    invoiceId={this.state.InvoiceId}
                    invoicedetails={this.state.invoicedetails}
                    tenure={this.state.tenure}
                  />

                  <div className="text-center mt-3">
                    <button
                      className="btn-blue"
                      onClick={this.regenerateFinalOffer}
                    >
                      Regenerate Final Offer
                    </button>
                  </div>
                </>
              )}
              <div className="tab-pane" id="tabs-1" role="tabpanel">
                {this.state.triggerSmartContract && (
                  <div className="bgc-dark p-4 mt-5">
                    <div>
                      <div className="text-center my-4">
                        <label>Pricing ID</label>
                        <div className="dropdown">
                          {/* <input className='input' role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" placeholder='Choose Buyer Grade'></input> */}
                          <select
                            className="form-select w-50  "
                            name="pricing_id"
                            value={this.state.pricing_id}
                            onChange={this.priceChange}
                          >
                            <option
                              style={{ background: "#262626", color: "grey" }}
                              className="text_tra1"
                            >
                              {" "}
                              Pricing ID
                            </option>
                            {this.state.pricingList !== undefined ? (
                              this.state.pricingList.map((item, index) => (
                                <option
                                  key={item._id}
                                  style={{
                                    background: "#262626",
                                    color: "grey",
                                  }}
                                  className="text_tra1"
                                  value={item.pricing_id}
                                >
                                  {" "}
                                  {item.pricing_id}
                                </option>
                              ))
                            ) : (
                              <option
                                style={{ background: "#262626", color: "grey" }}
                                className="text_tra1"
                              >
                                {" "}
                                Pricing ID
                              </option>
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        {this.state.pricingDetails && (
                          <button
                            className="btn-blue w-50"
                            onClick={this.onTrigger}
                          >
                            Trigger Price
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {this.state.offerDetails && (
                  <div>
                    <div className="bgc-dark  mt-5">
                      <div className="border-bottom d-flex justify-content-between">
                        <h6 className="text-white p-3">OFFER DETAILS </h6>
                        <i className="txt_b p-3 uil-angle-down"></i>
                      </div>
                      <div className="row p-3">
                        <div className="col-4">
                          <label>
                            Advance Ratio{" "}
                            <span className="txt_b">
                              (
                              {this.state.pricingData
                                ? this.state.pricingData
                                  ? Number(
                                      this.state.pricingData.min_advance_ratio *
                                        100
                                    ).toFixed(0)
                                  : 0
                                : 0}
                              %-
                              {this.state.pricingData
                                ? this.state.pricingData
                                  ? Number(
                                      this.state.pricingData.max_advance_ratio *
                                        100
                                    ).toFixed(0)
                                  : 0
                                : 0}
                              %)
                            </span>
                          </label>
                          <input
                            type="number"
                            className="input"
                            name="advance_percentage"
                            value={this.state.newOffer.advance_percentage}
                            onChange={this.onOfferChange}
                          ></input>

                          <div className="text-danger">
                            {this.state.errors.advance_percentage[0] &&
                            this.state.dirty.advance_percentage
                              ? this.state.errors.advance_percentage
                              : ""}
                          </div>
                        </div>
                        <div className="col-4">
                          <label>
                            Discounting Fee{" "}
                            <span className="txt_b">
                              (
                              {this.state.pricingData
                                ? this.state.pricingData.min_discount_range
                                  ? Number(
                                      this.state.pricingData
                                        .min_discount_range * 100
                                    ).toFixed(2)
                                  : 0
                                : 0}
                              %-
                              {this.state.pricingData
                                ? this.state.pricingData.max_discount_range
                                  ? Number(
                                      this.state.pricingData
                                        .max_discount_range * 100
                                    ).toFixed(2)
                                  : 0
                                : 0}
                              %) (Pa)
                            </span>
                          </label>
                          <input
                            type="number"
                            className="input"
                            name="discount"
                            value={this.state.newOffer.discount}
                            onChange={this.onOfferChange}
                          ></input>
                          <div className="text-danger">
                            {this.state.errors.discount[0] &&
                            this.state.dirty.discount
                              ? this.state.errors.discount
                              : ""}
                          </div>
                        </div>
                        <div className="col-4">
                          <label>
                            Factoring Fee{" "}
                            <span className="txt_b">
                              (
                              {this.state.pricingData
                                ? this.state.pricingData.min_factoring_fee * 100
                                  ? Number(
                                      this.state.pricingData.min_factoring_fee *
                                        100
                                    ).toFixed(2)
                                  : 0
                                : ""}
                              %-
                              {this.state.pricingData
                                ? this.state.pricingData.max_factoring_fee * 100
                                  ? Number(
                                      this.state.pricingData.max_factoring_fee *
                                        100
                                    ).toFixed(2)
                                  : 0
                                : ""}
                              %)
                            </span>
                          </label>
                          <input
                            type="number"
                            className="input"
                            name="factoring_fee"
                            value={this.state.newOffer.factoring_fee}
                            onChange={this.onOfferChange}
                          ></input>
                          <div className="text-danger">
                            {this.state.errors.factoring_fee[0] &&
                            this.state.dirty.factoring_fee
                              ? this.state.errors.factoring_fee
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4 p-3">
                        <div className="col-4">
                          <label>Choose Buyer Grade</label>

                          <input
                            className="input"
                            name="grade"
                            value={this.state.pricingData.grade}
                            onChange={this.onOfferChange}
                          ></input>
                        </div>
                        <div className="col-4">
                          <label>Invoice Limit</label>
                          <input
                            disabled="true"
                            className="input"
                            name="amount"
                            value={Number(this.state.newOffer.amount)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            onChange={this.onOfferChange}
                          ></input>
                        </div>
                        <div className="col-4">
                          <label>
                            Tenure{" "}
                            <span className="txt_b">
                              (
                              {this.state.pricingData
                                ? this.state.pricingData.min_tenure
                                  ? Number(this.state.pricingData.min_tenure)
                                  : 0
                                : ""}
                              &nbsp; Days &nbsp;- &nbsp;
                              {this.state.pricingData
                                ? this.state.pricingData.max_tenure
                                  ? Number(this.state.pricingData.max_tenure)
                                  : 0
                                : ""}
                              &nbsp; Days)
                            </span>
                          </label>
                          <input
                            type="number"
                            className="input"
                            name="tenure"
                            value={this.state.newOffer.tenure}
                            onChange={this.onOfferChange}
                          ></input>
                          <div className="text-danger">
                            {this.state.errors.tenure[0] &&
                            this.state.dirty.tenure
                              ? this.state.errors.tenure
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-4 p-3">
                        <button
                          className="btn-blue w-25"
                          onClick={this.generateOffer}
                        >
                          Create Offer
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.generateOffer && (
                  <div>
                    {this.state.loader ? (
                      <Spinner
                        style={{
                          marginTop: "150px",
                          marginLeft: "250px",
                          width: "6rem",
                          height: "6rem",
                        }}
                        animation="border"
                        variant="info"
                      />
                    ) : (
                      <div className="bgc-dark  mt-5">
                        <div className="border-bottom d-flex justify-content-between">
                          <h6 className="text-white p-3">OFFER DETAILS </h6>
                          <i className="txt_b p-3 uil-angle-down"></i>
                        </div>
                        <div className="row p-3">
                          <div className="col-4">
                            <label>
                              Advance Ratio{" "}
                              <span className="txt_b">
                                (
                                {this.state.pricingData
                                  ? this.state.pricingData
                                    ? Number(
                                        this.state.pricingData
                                          .min_advance_ratio * 100
                                      ).toFixed(0)
                                    : 0
                                  : 0}
                                %-
                                {this.state.pricingData
                                  ? this.state.pricingData
                                    ? Number(
                                        this.state.pricingData
                                          .max_advance_ratio * 100
                                      ).toFixed(0)
                                    : 0
                                  : 0}
                                %)
                              </span>
                            </label>

                            <input
                              type="number"
                              disabled="true"
                              className="input"
                              name="advance_percentage"
                              value={this.state.newOffer.advance_percentage}
                              onChange={this.onOfferChange}
                            ></input>
                            <div className="text-danger">
                              {this.state.errors.advance_percentage[0] &&
                              this.state.dirty.advance_percentage
                                ? this.state.errors.advance_percentage
                                : ""}
                            </div>
                          </div>
                          <div className="col-4">
                            <label>
                              Discounting fee{" "}
                              <span className="txt_b">
                                (
                                {this.state.pricingData
                                  ? this.state.pricingData.min_discount_range
                                    ? Number(
                                        this.state.pricingData
                                          .min_discount_range * 100
                                      ).toFixed(2)
                                    : 0
                                  : 0}
                                %-
                                {this.state.pricingData
                                  ? this.state.pricingData.max_discount_range
                                    ? Number(
                                        this.state.pricingData
                                          .max_discount_range * 100
                                      ).toFixed(2)
                                    : 0
                                  : 0}
                                %) (Pa)
                              </span>
                            </label>
                            <input
                              type="number"
                              disabled="true"
                              className="input"
                              name="discount_range"
                              value={this.state.newOffer.discount}
                              onChange={this.onOfferChange}
                            ></input>
                            <div className="text-danger">
                              {this.state.errors.discount[0] &&
                              this.state.dirty.discount
                                ? this.state.errors.discount
                                : ""}
                            </div>
                          </div>
                          <div className="col-4">
                            <label>
                              Factoring Fee{" "}
                              <span className="txt_b">
                                (
                                {this.state.pricingData
                                  ? this.state.pricingData.min_factoring_fee *
                                    100
                                    ? Number(
                                        this.state.pricingData
                                          .min_factoring_fee * 100
                                      ).toFixed(2)
                                    : 0
                                  : ""}
                                %-
                                {this.state.pricingData
                                  ? this.state.pricingData.max_factoring_fee *
                                    100
                                    ? Number(
                                        this.state.pricingData
                                          .max_factoring_fee * 100
                                      ).toFixed(2)
                                    : 0
                                  : ""}
                                %)
                              </span>
                            </label>
                            <input
                              type="number"
                              disabled="true"
                              className="input"
                              name="factoring_fee"
                              value={this.state.newOffer.factoring_fee}
                              onChange={this.onOfferChange}
                            ></input>
                            <div className="text-danger">
                              {this.state.errors.factoring_fee[0] &&
                              this.state.dirty.factoring_fee
                                ? this.state.errors.factoring_fee
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="row p-3">
                          <div className="col-4">
                            <label>Invoice Limit</label>
                            <input
                              className="input"
                              value={Number(this.state.newOffer.amount)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              onChange={this.onOfferChange}
                            ></input>
                          </div>
                          <div className="col-4">
                            <label>Amount to be Funded</label>
                            <input
                              type="number"
                              className="input"
                              disabled
                              name="amount_to_be_funded"
                              value={this.state.newOffer.amount_to_be_funded}
                              onChange={this.onOfferChange}
                            ></input>
                          </div>
                          <div className="col-4">
                            <label>
                              Tenure{" "}
                              <span className="txt_b">
                                (
                                {this.state.pricingData
                                  ? this.state.pricingData.min_tenure
                                    ? Number(this.state.pricingData.min_tenure)
                                    : 0
                                  : ""}
                                -
                                {this.state.pricingData
                                  ? this.state.pricingData.max_tenure
                                    ? Number(this.state.pricingData.max_tenure)
                                    : 0
                                  : ""}
                                Days)
                              </span>
                            </label>

                            <input
                              type="number"
                              className="input"
                              disabled="true"
                              name="tenure"
                              value={this.state.newOffer.tenure}
                              onChange={this.onOfferChange}
                            ></input>
                            <div className="text-danger">
                              {this.state.errors.tenure[0] &&
                              this.state.dirty.tenure
                                ? this.state.errors.tenure
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="row p-3">
                          <div className="col-4">
                            <label>Grace Period</label>
                            <input
                              className="input"
                              name="grace_period"
                              value={this.state.newOffer.grace_period}
                              onChange={this.onOfferChange}
                            ></input>
                          </div>
                          <div className="col-4">
                            <label>Bank Charges</label>
                            <input
                              type="number"
                              className="input"
                              name="bank_charges"
                              value={this.state.newOffer.bank_charges}
                              onChange={this.onOfferChange}
                            ></input>
                          </div>
                          <div className="col-4">
                            <label>Reserve Ratio</label>
                            <input
                              type="number"
                              className="input"
                              disabled
                              name="reserve_percentage"
                              value={this.state.newOffer.reserve_percentage}
                              onChange={this.onOfferChange}
                            ></input>
                          </div>
                          <div className="col-4 mt-4">
                            <label>Late Fee % (Pa)</label>
                            <input
                              type="number"
                              min="0"
                              max="100"
                              className="input"
                              name="late_fee"
                              value={this.state.newOffer.late_fee}
                              onChange={this.onOfferChange}
                            />
                          </div>
                        </div>

                        <div className="text-center mt-4 p-3">
                          <button
                            className="btn-blue w-50"
                            onClick={this.createFinalOffer}
                          >
                            Generate Offer
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  invoiceStatus: state.invoiceStatus.status,
  company: state.company,
  country: state.country,
  invoiceFinalOffer: state.invoiceFinalOffer,
  sc: state.sc,
  pricing: state.pricing,
  expire: state.expire,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceChanges: (data) => dispatch(getInvoiceChanges(data)),
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  getAllInvoices: (data) => dispatch(getAllInvoices(data)),

  updateCompany: (data) => dispatch(updateCompany(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  postAlert: (data) => dispatch(postAlert(data)),
  getInvoiceCreatedByUser: (data) => dispatch(getInvoiceCreatedByUser(data)),
  postSmartToken: (data) => dispatch(postSmartToken(data)),
  getPricingData: (data) => dispatch(getPricingData(data)),
  postOffer: (data) => dispatch(postOffer(data)),
  getPricingIDList: (data, value) => dispatch(getPricingIDList(data, value)),
  getOfferById: (data) => dispatch(getOfferById(data)),
  getExpiration: (data) => dispatch(getExpiration(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(finalOffer));

import React, { Component } from "react";
import Sidebar from "./Dashboard/Sidebar";
import { Card, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import { getCoins } from "../../services/actions/coinActions";
import Modal from "react-bootstrap/Modal";
import Mainheader from "../Lender/Dashboard/mainHeader";
import Kycpopup from "./Common/kycpopup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/wallet.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ajax from "../../utils/ajax";
import {
  URI,
  TRANSAK_API_KEY,
  TRANSAK_ENV,
  TRANSAK_CRYPTO_LIST,
} from "../../constant";
import copy from "../../assets/images/Copy.png";
import V2 from "../../assets/images/lender-v2.svg";
import ContractTickImg from "../../assets/images/Subtract.png";
import KycPendingModal from "./Common/kycPending";
import { Button } from "react-bootstrap";
import Bottomnav from "../Lender/Dashboard/bottomnav";
import MobHeader from "../Lender/Dashboard/MobHeader";
import { KYC_STATUS, LENDER_V2_LINK } from "../../config/string_constants";
import Spinner from "react-bootstrap/Spinner";
import { getCoinsUser } from "../../api/poolService";
import axios from "axios";
import transakSDK from "@transak/transak-sdk";
import V2Banner from "./Common/v2Banner";

class wallet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      showPageLoader: sessionStorage.getItem("wallet_Id") ? true : false,
      UserWallets: [],
      editData: false,
      walletLoader: false,
      UserReward: `0`,
      userInterestEarned: `0`,
      userWalletBalance: `0`,
      coins: [],
      walletActive: sessionStorage.getItem("wallet_Id") ? false : true,
      walletAddress: sessionStorage.getItem("wallet_Id")
        ? sessionStorage.getItem("wallet_Id")
        : "",
      walletActions: [],
      walletActionPopup: false,
      reward: 0,
      userCoinsBalance: [],
      coin: [],
      modalKycPending: false,
      reward: "",
      amount: "",
      tokenBal: [],
      copyId: false,
      Tether: 0,
      Dai: 0,
      setCoins: [],
      selectedCoin: "",
      walletData: {},
      ActionToPerform: sessionStorage.getItem("ActionToPerform"),
      isLoggedIn: sessionStorage.getItem("isLoggedIn"),
      modalShow: sessionStorage.getItem("ShowKycPopup") === "true",
      kycStatus: sessionStorage.getItem("kycStatus"),
      headerMessage: "KYC Inprogress",
      mainMessage:
        "Please wait for KYC verification. After Verified you can proceed further.",
      dashboardData: {},
      maticPopup: false,
      maticMessage:
        "Matic is required to complete transaction on Polygon. Polytrade treasury will trigger the Gas station and send Matic Automatically for you to complete the same. There may be a delay in transaction sometime. Please Raise a ticket on Support if you see the same.",
    };
  }
  showKycPending = async () => {
    this.setState({
      modalKycPending: true,
      headerMessage: "KYC Inprogress",
      mainMessage:
        "Please wait for KYC verification. After Verified you can proceed further.",
      // amount:this.state.reward
    });
  };

  /*
   * Handling transak
   */
  handleBuyCoin = () => {
    let transak = new transakSDK({
      apiKey: TRANSAK_API_KEY, // Your API Key
      environment: TRANSAK_ENV, // STAGING/PRODUCTION
      defaultCryptoCurrency: "usd-coin",
      walletAddress: this.state.walletAddress, // Your customer's wallet address
      disableWalletAddressForm: true,
      themeColor: "000000", // App theme color
      fiatCurrency: "", // INR/GBP
      email: sessionStorage.getItem("emailOfUser"), // Your customer's email address
      redirectURL: "",
      hostURL: window.location.origin,
      widgetHeight: "560px",
      widgetWidth: "470px",
      network: "polygon",
      cryptoCurrencyList: TRANSAK_CRYPTO_LIST,
    });

    transak.init();

    // To get all the events
    transak.on(transak.ALL_EVENTS, (data) => {});

    // This will trigger when the user marks payment is made.
    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
      transak.close();
    });
  };

  getDashboardData = async () => {
    try {
      const dashboardResponseData = await ajax(
        "GET",
        `${URI}/lender/dashboard/${sessionStorage.getItem("userId")}`,
        {}
      );
      let totalAccuredInterestEarned = 0;
      let totalBonusInterestEarned = 0;
      for (let i = 0; i < dashboardResponseData.data.length; i++) {
        totalAccuredInterestEarned =
          totalAccuredInterestEarned +
          dashboardResponseData.data[i].totalAccuredInterestEarned;
        totalBonusInterestEarned =
          totalBonusInterestEarned +
          dashboardResponseData.data[i].totalBonusInterestEarned;
      }
      let usdcId = "usd-coin";
      const usdcData = await this.coinPrice(usdcId);

      let tradeId = "polytrade";
      const tradeData = await this.coinPrice(tradeId);

      this.setState({
        userInterestEarned:
          totalAccuredInterestEarned == undefined
            ? 0
            : parseFloat(totalAccuredInterestEarned) *
              usdcData.data[usdcId].usd,
        reward:
          totalBonusInterestEarned == undefined
            ? 0
            : totalBonusInterestEarned * tradeData.data[tradeId].usd,
      });
    } catch (error) {}
  };

  getReward = async () => {
    try {
      const rewardData = await ajax(
        "GET",
        `${URI}/rewards/users/${sessionStorage.getItem("userId")}`,
        {}
      );
      this.setState({
        reward: rewardData.data[0].reward ? rewardData.data[0].reward : 0,
      });
    } catch (error) {}
  };

  hideKycPending = async () => {
    this.setState({
      modalKycPending: false,
      // amount:this.state.reward
    });
  };

  coinPrice = async (CoinId) => {
    const coinPrice = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${CoinId}&vs_currencies=usd`
    );
    return coinPrice;
  };

  //Get wallet balance details
  fetchBalance = async () => {
    try {
      const walletData = await ajax(
        "GET",
        `${URI}/user/wallet/balance/${sessionStorage.getItem("userId")}`,
        {}
      );
      this.setState({
        showPageLoader: false,
      });
      let maticId = "matic-network";
      const maticData = await this.coinPrice(maticId);
      let maticPrice = maticData.data[maticId].usd;
      if (walletData.data.success) {
        let totalBal = walletData.data.data.balance * maticPrice;
        let tokenData = walletData.data.data.data;
        if (tokenData.length > 0) {
          for (let i = 0; i < this.state.coins.length; i++) {
            for (let token of tokenData) {
              if (token.tokenAddress === this.state.coins[i].address) {
                const currentPrice = await this.coinPrice(
                  this.state.coins[i].id
                );
                totalBal =
                  totalBal +
                  token.balance * currentPrice.data[this.state.coins[i].id].usd;
              }
            }
          }
        }
        this.setState(
          {
            userWalletBalance: totalBal,
            userCoinsBalance: tokenData,
            showPageLoader: false,
          },
          () => {
            //This logic has to change with comparing token address
            let bal = {};
            for (let i = 0; i < this.state.coins.length; i++) {
              for (let token of this.state.userCoinsBalance) {
                if (token.tokenAddress === this.state.coins[i].address) {
                  bal[this.state.coins[i].address] = token ? token.balance : 0;
                }
              }
            }

            this.setState({ tokenBal: bal });
          }
        );
      }
    } catch (error) {}
  };

  handleDeposit = (coinName) => {
    if (this.state.isLoggedIn === "true") {
      if (this.state.ActionToPerform === "true") {
        this.props.history.push({
          pathname: "/lender/deposit-crypto",

          state: { selected: coinName },
        });
      } else {
        if (
          this.state.kycStatus === KYC_STATUS.NOT_STARTED ||
          this.state.kycStatus === KYC_STATUS.FINAL_REJECTED
        ) {
          this.setState({
            modalShow: true,
          });
        } else if (this.state.kycStatus === KYC_STATUS.PENDING) {
          this.showKycPending();
        } else {
          this.props.history.push({
            pathname: "/lender/deposit-crypto",

            state: { selected: coinName },
          });
        }
      }
    } else {
      this.props.history.push("/lender/login");
    }
  };
  kycNow = () => {
    this.props.history.push("/lender/profile");
  };
  hidekycNow = () => {
    sessionStorage.setItem("ShowKycPopup", false);
    this.setState({
      modalShow: false,
    });
  };

  ShowKyc = () => {
    this.setState({
      modalShow: true,
    });
  };

  numberWithCommas = (x) => {
    return Number(x)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  handleWithdraw = (id) => {
    if (this.state.isLoggedIn === "true") {
      if (this.state.ActionToPerform === "true") {
        this.props.history.push(`/lender/withdraw-crypto/${id}`);
      } else {
        if (
          this.state.kycStatus === KYC_STATUS.NOT_STARTED ||
          this.state.kycStatus === KYC_STATUS.FINAL_REJECTED
        ) {
          this.setState({
            modalShow: true,
          });
        } else if (this.state.kycStatus === KYC_STATUS.PENDING) {
          this.showKycPending();
        } else {
          this.props.history.push("/lender/withdraw-crypto");
        }
      }
    } else {
      this.props.history.push("/lender/login");
    }
  };

  handleApprovalToken = async (coinId) => {
    const bodyData = {
      selected_coin: coinId,
    };
    this.setState({
      isLoading: true,
      selectedCoin: coinId,
    });
    try {
      const response = await ajax(
        "POST",
        `${URI}/user/wallet/approve/${coinId}`,
        {},
        {},
        bodyData
      );
      if (response.data.success) {
        this.getCoinUser();
        toast.info(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      this.setState({
        isLoading: false,
      });
    } catch (error) {}
  };

  createWallet = async () => {
    if (
      this.state.kycStatus === KYC_STATUS.NOT_STARTED ||
      this.state.kycStatus === KYC_STATUS.FINAL_REJECTED
    ) {
      this.setState({
        modalShow: true,
      });
    } else if (this.state.kycStatus === KYC_STATUS.PENDING) {
      this.showKycPending();
    } else {
      this.setState({ walletLoader: true });

      const userData = await ajax(
        "POST",
        `${URI}/user/wallet/create/${sessionStorage.getItem("userId")}`,
        {}
      );
      sessionStorage.setItem(
        "wallet_Id",
        userData.data.wallet_address ? userData.data.wallet_address : ""
      );
      this.setState({ maticPopup: true });
      // setTimeout(window.location.reload(), 15000);
    }
  };

  // getWalletDetails = async () => {
  //   try {
  //     const userData = await ajax(
  //       "GET",
  //       `${URI}/user/wallet/${sessionStorage.getItem("userId")}`,
  //       {}
  //     );
  //     const data = userData.data.result ? userData.data.result : {};
  //     console.log(data, " getWalletDetails")
  //     this.setState(
  //       {
  //         walletData: data,
  //         showPageLoader: false,
  //       },
  //       () => {
  //         if (data.address == undefined) {
  //           this.setState({
  //             walletActive: true,
  //           });
  //         }
  //       }
  //     );
  //   } catch (e) {}
  // };

  getCoinUser = async () => {
    let response = await getCoinsUser(sessionStorage.getItem("userId"));
    if (response.data.success) {
      this.setState(
        {
          coins: response.data.data,
        },
        () => {
          this.fetchBalance();
        }
      );
    }
  };

  copyWalletId = () => {
    navigator.clipboard.writeText(
      this.state.walletAddress ? this.state.walletAddress : ""
    );
    this.setState({ copyId: true });
    setTimeout(() => {
      this.setState({ copyId: false });
    }, 2000);
  };

  componentDidMount() {
    //this.getReward();
    this.getDashboardData();
    // this.getWalletDetails();
    this.getCoinUser();
    this.fetchBalance();
  }

  walletAction = (data) => {
    this.setState({ walletActionPopup: true, walletActions: data });
  };

  render() {
    if (this.state.showPageLoader) {
      return (
        <div id="cover-spin">
          <center>
            <Spinner
              style={{ marginTop: "300px", width: "6rem", height: "6rem" }}
              animation="border"
              variant="info"
            />
          </center>
        </div>
      );
    }
    return (
      <div>
        <Sidebar />
        <Bottomnav />
        <div className="main-content main-content-lender">
          <MobHeader />
          <header className="disp-sm-none">
            <h2
              className="heading text-white ml-4 mt-3 "
              style={{ marginLeft: "16px" }}
            ></h2>
            <Mainheader />
          </header>
          <Row>
            <KycPendingModal
              headerMessage={this.state.headerMessage}
              mainMessage={this.state.mainMessage}
              amount={0}
              rewardDaller={0}
              claimRewards={0}
              hideReward={this.hideKycPending}
              show={this.state.modalKycPending}
              onHide={() => this.setState({ modalKycPending: false })}
            />
            <Kycpopup
              hidekycNow={this.hidekycNow}
              kycNow={this.kycNow}
              show={this.state.modalShow}
              onHide={() => this.setState({ modalShow: false })}
            />
            <div className="col-md-5 headingmob">
              <h2
                className=" heading text-white ml-4 mt-3"
                style={{ marginLeft: "16px" }}
              >
                Wallet
              </h2>
            </div>
            <div className="col-md-3 col-6 ml-5 text-right mt-3">
              <div className="font-style-2">
                {" "}
                {this.state.copyId ? (
                  <span className="txt_b">Copied </span>
                ) : (
                  ""
                )}
                <img
                  onClick={() => {
                    this.copyWalletId();
                  }}
                  src={copy}
                  alt="img"
                  style={{ cursor: "pointer" }}
                ></img>{" "}
                Wallet ID
              </div>
              <div className="font-style-3" style={{ textTransform: "none" }}>
                {this.state.walletActive ? (
                  "To be created"
                ) : (
                  <span>{this.state.walletAddress}</span>
                )}
              </div>
            </div>
            {!this.state.walletActive ? (
              <div className="col-md-3 text-right mt-3 disp-sm-none">
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>
                      You can now buy USDC directly using money from your bank
                      account. Please note that USDC will be deposited on your
                      Polytrade wallet address.
                    </Tooltip>
                  }
                >
                  <span>
                    <i
                      style={{ fontSize: "24px" }}
                      className="uil-info-circle txt_y"
                    />{" "}
                  </span>
                </OverlayTrigger>
                <button
                  className=" mr-4 btn-blue"
                  onClick={() => this.handleBuyCoin()}
                >
                  Buy USDC
                </button>
              </div>
            ) : (
              ""
            )}
          </Row>

          <div className="sec  ml-4 mr-4 mt-3 mb-4">
            <Row>
              <Col lg="4" sm="6" className="mt-2">
                <div>
                  <Card.Body className="bgc-dark">
                    <h4
                      className="acc-item link"
                      data-tooltip="Value of total funds held in the wallet"
                    >
                      Account Balance{" "}
                      <i className="uil-info-circle text_tra1" />
                    </h4>
                    {this.state.UserWallets === undefined ? (
                      ""
                    ) : (
                      <h4 className="text-white text-center">
                        {this.state.walletActive ? (
                          "----"
                        ) : (
                          <span>
                            $
                            {this.numberWithCommas(
                              parseFloat(this.state.userWalletBalance).toFixed(
                                2
                              )
                            )}
                          </span>
                        )}
                      </h4>
                    )}
                  </Card.Body>
                </div>
              </Col>
              <Col lg="4" sm="6" className="mt-2">
                <div>
                  <Card.Body className="bgc-dark">
                    <h4
                      className="acc-item link"
                      data-tooltip="Total Interest earned on the funds held"
                    >
                      Interest Earned{" "}
                      <i className="uil-info-circle text_tra1" />
                    </h4>
                    <h4 className="text-white text-center">
                      {this.state.walletActive ? (
                        "----"
                      ) : (
                        <span>
                          $
                          {this.numberWithCommas(this.state.userInterestEarned)}
                        </span>
                      )}
                    </h4>
                  </Card.Body>
                </div>
              </Col>
              <Col lg="4" sm="6" className="mt-2">
                <div>
                  <Card.Body className="bgc-dark">
                    <h4
                      className="acc-item link"
                      data-tooltip="Total value of rewards disbursed in form of Bonus tokens (USD, based on current value of TRADE token)"
                    >
                      Rewards Earned <i className="uil-info-circle text_tra1" />
                    </h4>
                    <h4 className="text-white text-center">
                      {this.state.walletActive ? (
                        "----"
                      ) : (
                        <span>
                          $ {this.numberWithCommas(this.state.reward)}
                        </span>
                      )}
                    </h4>
                  </Card.Body>
                </div>
              </Col>
            </Row>
          </div>
          {/* mobile view start */}
          <div className="text-center mx-4 disp-lg-none my-3 ">
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip id={`tooltip-top`}>
                  You can now buy USDC directly using money from your bank
                  account. Please note that USDC will be deposited on your
                  Polytrade wallet address.
                </Tooltip>
              }
            >
              <span>
                <i
                  style={{ fontSize: "24px" }}
                  className="uil-info-circle txt_y"
                />{" "}
              </span>
            </OverlayTrigger>
            <button
              className=" mr-4 w-100 custom-button btn-sm btn"
              onClick={() => this.handleBuyCoin()}
            >
              Buy USDC
            </button>
          </div>
          {/* mobile view end */}
          <div className="row">
            <div className="col-md-7 d-flex "></div>
            <div className="col-md-2 d-flex "></div>
            <div className="col-md-3 text-right float-right ">
              <h5 className="font-3 mr-4 d-none">
                Convert Small Balance to TRADE
              </h5>
            </div>
          </div>
          {this.state.walletActive ? (
            <div className="text-center">
              <button
                className="btn-blue my-3"
                onClick={() => {
                  this.createWallet();
                }}
              >
                {this.state.walletLoader ? (
                  <Spinner animation="border" variant="info" />
                ) : (
                  "Create Wallet"
                )}
              </button>
              <Modal
                show={this.state.maticPopup}
                onHide={() => this.setState({ maticPopup: false })}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
              >
                <div className="finaloffer  p-4">
                  <i className="uil-info-circle txt_y" />{" "}
                  {this.state.maticMessage}
                  <div>
                    <button
                      className="btn-blue my-3"
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          ) : (
            <div>
              <div className="mx-4 disp-sm-none">
                <p
                  className="text_tra1 d-flex justify-content-end pr-2"
                  title="Please approve spending limit to lend on smart contract."
                >
                  Spending Limit Status{" "}
                  <i className=" ml-1 uil-info-circle text_tra1" />
                </p>
              </div>
              <div className=" ml-4 mr-4 px-3 ">
                {this.state.coins.map((coin) => (
                  <div className="row p-2 mt-2 section disp-sm-none">
                    <div className="col-md-4 d-flex ">
                      <img
                        className="mt-2"
                        src={coin.image}
                        width="25px"
                        height="25px"
                        alt="img"
                      />
                      <div className="ml-2">
                        <div className="font-1 text-left">{coin.name}</div>
                        <label className="font-2 text-left p-0 m-0 text-uppercase">
                          {coin.symbol}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-2 ">
                      <div className="ml-2  justify-content-end ">
                        <div className="font-1 text-right text-uppercase mt-3">
                          {Object.keys(this.state.tokenBal).length !== 0
                            ? this.state.tokenBal[coin.address]
                            : "0"}{" "}
                          {coin.symbol}
                        </div>
                        <div className="font-2 d-none p-0 m-0 text-right">
                          $ {100}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 text-right mt-2">
                      <div className="row ">
                        <div className="col-md-4 text-right ">
                          <button
                            className="mr-2 btn-sm custom-button-1 btn "
                            onClick={() => this.handleWithdraw(coin._id)}
                          >
                            Withdraw
                          </button>
                        </div>
                        <div className="col-md-3 text-right ">
                          <button
                            className="mr-1 btn-blue w-75"
                            onClick={() => this.handleDeposit(coin.name)}
                          >
                            Deposit
                          </button>
                        </div>
                        <div className="col-md-4">
                          {coin.coin_user &&
                          coin.coin_user.status === "APPROVED" ? (
                            <div className="sucess">
                              {" "}
                              <h3 className="acc-item my-1 pb-1 font-style-3">
                                <img
                                  src={ContractTickImg}
                                  className="mr-2"
                                  alt="img"
                                />
                                Approved
                              </h3>
                            </div>
                          ) : (
                            <div
                              className={
                                !this.state.isLoading
                                  ? "txt_g mx-2 underline  curserP"
                                  : "pointerE txt_g mx-2 underline"
                              }
                              onClick={() => this.handleApprovalToken(coin._id)}
                            >
                              {this.state.isLoading &&
                              this.state.selectedCoin == coin._id ? (
                                <Spinner animation="border" variant="info" />
                              ) : (
                                "Approve Now"
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* Mobile view  strart*/}
              {this.state.coins.map((coin) => (
                <div
                  className="d-flex disp-lg-none justify-content-between bgc-dark1 p-2 mx-4 my-2 borderR"
                  onClick={() => this.walletAction(coin)}
                >
                  <div className="d-flex ">
                    <img
                      className="mt-2 mx-2"
                      src={coin.image}
                      width="25px"
                      height="25px"
                      alt="img"
                    />
                    <div>
                      <div className="font-1 text-left">{coin.name}</div>
                      <label className="font-2 text-left p-0 m-0 text-uppercase">
                        {coin.symbol}
                      </label>
                    </div>
                  </div>
                  <div className="ml-auto">
                    <div>
                      <div className="font-1 text-right text-uppercase mt-3">
                        {Object.keys(this.state.tokenBal).length !== 0
                          ? this.state.tokenBal[coin.address]
                          : "0"}{" "}
                        {coin.symbol}
                      </div>
                      <div className="font-2 d-none p-0 m-0 text-right">
                        $ {100}
                      </div>
                    </div>
                  </div>

                  <div></div>
                </div>
              ))}

              <Modal
                show={this.state.walletActionPopup}
                onHide={() => this.setState({ walletActionPopup: false })}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backgroundColor: "transparent" }}
              >
                <div className="finaloffer  p-3">
                  <span className=" disp-lg-none">
                    <div className="line mx-auto"></div>
                  </span>
                  <div className="d-flex justify-content-end">
                    <i
                      className=" uil-multiply"
                      onClick={() =>
                        this.setState({ walletActionPopup: false })
                      }
                    />
                  </div>

                  <div className="text-center">
                    <div className="bgc-dark m-2 p-2">
                      <div className="text_tra1">Total</div>
                      <h4 style={{ textTransform: "uppercase" }}>
                        {Object.keys(this.state.tokenBal).length !== 0
                          ? this.state.tokenBal[
                              this.state.walletActions.address
                            ]
                          : "0"}{" "}
                        {this.state.walletActions.symbol}
                      </h4>
                    </div>
                  </div>
                  <div className="text-center my-3">
                    <button
                      className="mr-2 btn-sm custom-button-1 btn "
                      onClick={() =>
                        this.handleWithdraw(this.state.walletActions._id)
                      }
                    >
                      Withdraw
                    </button>
                    <button
                      className="mr-1 btn-sm custom-button btn"
                      onClick={() =>
                        this.handleDeposit(this.state.walletActions.name)
                      }
                    >
                      Deposit
                    </button>

                    <span>
                      <p
                        className="text_tra1 mt-3 pr-2"
                        title="Please approve spending limit to lend on smart contract."
                      >
                        Spending Limit Status{" "}
                        <i className=" ml-1 uil-info-circle text_tra1" />
                      </p>
                      {this.state.walletActions.coin_user &&
                      this.state.walletActions.coin_user.status ===
                        "APPROVED" ? (
                        <div className="sucess mt-3 w-50  mx-auto">
                          {" "}
                          <h3 className="acc-item pt-1 font-style-3">
                            <img
                              src={ContractTickImg}
                              className="mr-2"
                              alt="img"
                            />
                            Approved
                          </h3>
                        </div>
                      ) : (
                        <div
                          className={
                            !this.state.isLoading
                              ? "txt_g mx-2 mt-3 underline  curserP"
                              : "pointerE txt_g mt-3 mx-2 underline"
                          }
                          onClick={() =>
                            this.handleApprovalToken(
                              this.state.walletActions._id
                            )
                          }
                        >
                          {this.state.isLoading &&
                          this.state.selectedCoin ==
                            this.state.walletActions._id ? (
                            <Spinner animation="border" variant="info" />
                          ) : (
                            "Approve Now"
                          )}
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </Modal>
              <div style={{ margin: "21px" }}>
                <V2Banner />
              </div>
              <div
                className="bgc-dark p-2 m-4 text_tra1 "
                style={{ bottom: "0", position: "static" }}
              >
                <p className="m-4">
                  <i className="uil-info-circle txt_y" />{" "}
                  {this.state.maticMessage}
                </p>
              </div>
            </div>
          )}
          {/* Mobile View End */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userAccount: state.userAccount,
  activity: state.activity,
  userWallet: state.userWallet,
});

const mapDispatchToProps = (dispatch) => ({
  getCoins: (data) => dispatch(getCoins(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(wallet));

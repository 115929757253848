import * as ALERT_CONSTANTS from "../constants/alertConstant";
export const alertReducer = (state = {
    type: '',
    error: '',
    isFetching: false,
   alerts:undefined,
   postAlert:undefined,
   updateAlert:undefined,
}, action) => {
   
    switch (action.type) {
                case ALERT_CONSTANTS.HOME_GET_ALERT_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    alerts: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case ALERT_CONSTANTS.HOME_GET_ALERT_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    alerts: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case ALERT_CONSTANTS.HOME_GET_ALERT_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });

                case ALERT_CONSTANTS.HOME_POST_ALERT_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    postAlert: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case ALERT_CONSTANTS.HOME_POST_ALERT_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    postAlert: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case ALERT_CONSTANTS.HOME_POST_ALERT_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });

                case ALERT_CONSTANTS.HOME_UPDATE_ALERT_CONSTANTS.REQUEST:
                    return Object.assign({}, state, {
                        isFetching: true,
                        type: action.type,
                        updateAlert: undefined,
                        lastUpdated: action.receivedAt,
                    });
        
                case ALERT_CONSTANTS.HOME_UPDATE_ALERT_CONSTANTS.RECEIVED:
                    return Object.assign({}, state, {
                        isFetching: false,
                        type: action.type,
                        updateAlert: action.data,
                        lastUpdated: action.receivedAt,
                    });
        
                case ALERT_CONSTANTS.HOME_UPDATE_ALERT_CONSTANTS.RECEIVED_ERROR:
                    return Object.assign({}, state, {
                        isFetching: false,
                        type: action.type,
                        error: action.error,
                    });


        default:
            return state;
    }
};


export default alertReducer;

import React from "react";
import { NavLink } from "react-router-dom";
import { URLS } from "../../../config/frontendUrls";

export default function bottomnav() {
  return (
    <div>
      <div className="bottom_nav disp-lg-none">
        <NavLink
          activeClassName="act"
          className="text-center text_tra1"
          to={URLS.CLIENT.DASHBOARD}
          exact={true}
        >
          <i className="uil uil-create-dashboard" />
          <div>Dashboard</div>
        </NavLink>
        <NavLink
          activeClassName="act"
          className="text-center text_tra1"
          to={URLS.CLIENT.BUYER_LIST}
          exact={true}
        >
          <i className="uil uil-users-alt" />
          <div>Buyer</div>
        </NavLink>
        <NavLink
          activeClassName="act"
          className="text-center text_tra1"
          to={URLS.CLIENT.INVOICES}
          exact={true}
        >
          <i className="uil uil-receipt-alt" />
          <div>Invoice</div>
        </NavLink>
        <NavLink
          activeClassName="act"
          className="text-center text_tra1"
          to={URLS.CLIENT.PROFILE_SECTION}
          exact={true}
        >
          <i className="uil uil-user-circle" />
          <div>Profile</div>
        </NavLink>
      </div>
    </div>
  );
}

import react from "react";
import { Logo, Brand } from "../../constant";
import styles from "../../assets/css/OnboardingPersonalDetails.module.css";
import "../../Global.css";
import "../../assets2/css/profile.css";
import { UserContext } from "../../context/UserContext";
import upload from "../../assets/images/upload.png";
import trash from "../../assets/images/trash.png";
import "react-toastify/dist/ReactToastify.css";
import DialCodeList from "./Common/dialCodeList";

class UserCompany extends react.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      showPageLoader: false,
      file: null,
      progressState: 1,
      showSuceess: false,
      fillForms: { form1: false, form2: false },
      company: {
        company_name: "",
        company_email_id: "",
        company_phone_no: "",
        website: "",
        about: "",
        company_landline: "",
        user_id: sessionStorage.getItem("userId"),
        company_logo: "",
        company_iec_number: "",
        company_dialCode: "",
      },
      country: [],

      dirty: {
        company_name: false,
        company_email_id: false,
        company_phone_no: false,
        website: false,
        about: false,
        company_landline: false,
        company_iec_number: false,
        company_dialCode: false,
      },

      errors: {
        company_name: [],
        company_email_id: [],
        company_phone_no: [],
        website: [],
        about: [],
        company_landline: [],
        company_iec_number: [],
        company_dialCode: [],
      },

      city: [],
    };
  }
  handleChange = (e) => {
    const { name, value } = e.target;

    let dirty = this.state.dirty;
    dirty[name] = true;

    let company = this.state.company;
    if (name === "company_phone_no") {
      let cleanText = ("" + value).replace(/\D/g, "");
      company[name] = cleanText;
    } else {
      company[name] = value;
    }
    this.setState(
      {
        company: company,
        dirty: dirty,
      },
      this.validate
    );
  };

  blurChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };
  componentDidMount() {
    this.setState({ company: this.props.company });
  }
  validate = () => {
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      errors[control] = [];
      var validEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      var validUrl =
        /(http(s)?:\/\/.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
      //  /^(ftp|https?):\/\/+(www\.)?[a-z0-9\-\.]{3,}\.[a-z]{3}$/
      var validIEC = /^[a-zA-Z0-9]{10}$/;
      var validCompany = /^[a-zA-Z0-9_'-\s]*$/;

      switch (control) {
        case "company_name":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          if (this.state.company[control]) {
            if (!validCompany.test(this.state.company[control])) {
              errors[control].push("Enter valid Company Name");
            }
          }
          break;
        case "company_dialCode":
          if (!this.state.company[control]) {
            errors[control].push("Dial code is required");
          }
          break;
        case "company_phone_no":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }

          let clength = this.state.company[control].length;
          if (this.state.company[control] && (clength < 6 || clength > 12)) {
            errors[control].push("Number min 6 max 12");
          }
          break;
        case "company_iec_number":
          if (this.state.company[control]) {
            if (!validIEC.test(this.state.company[control])) {
              errors[control].push("Please Enter valid IEC Number");
            }
          }
          break;

        case "company_email_id":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          if (this.state.company[control]) {
            if (!validEmail.test(this.state.company[control])) {
              errors[control].push("Please Enter valid Email Id");
            }
          }
          break;

        case "website":
          if (this.state.company[control]) {
            if (!validUrl.test(this.state.company[control])) {
              errors[control].push("Please Enter valid Website");
            }
          }
          break;

        default:
          break;
      }
    });
    this.setState({ errors });
  };

  isValid = () => {
    let valid = true;

    for (let control in this.state.errors) {
      if (this.state.errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };
  goToNextPage = () => {
    this.validate();
    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });

    if (this.isValid()) {
      this.props.goToAddressDetails(this.state.company);
    }
  };

  render() {
    return (
      <>
        <div className="row m-3">
          <div className="col-12 text-center pt-3">
            <h3 className="text-white">Company Details</h3>
            <p className="text_color2 ">
              {Brand} needs your Company Information to offer you trade finance{" "}
            </p>
            {/* uplod image */}
            <div className="mt-3 mb-4   form-selectgroup-label align-items-center ">
              <div className="pl-2 form-selectgroup-label-content  align-items-center">
                <div style={{ textAlign: "center" }}>
                  <div className=" header-input font-weight-medium col_er ">
                    {this.props.company.company_logo ? (
                      <div className="d-flex justify-content-center">
                        <div
                          style={{
                            background: "#404040",
                            padding: "12px",
                            borderRadius: "4px",
                          }}
                        >
                          <img
                            width="100px"
                            height="75px"
                            src={this.props.company.company_logo}
                          />
                          <img
                            className="ml-4"
                            src={trash}
                            onClick={this.props.removeLogo}
                          />
                        </div>
                      </div>
                    ) : (
                      <span
                        className={
                          this.state.errors.company_logo ? " input-error" : ""
                        }
                      >
                        <label className="ulogo w-50">
                          <img width="18px" src={upload} /> Upload Company Logo{" "}
                          <div className="text-muted ">(Optional)</div>{" "}
                          <input
                            type="file"
                            name="myImage"
                            onChange={(e) => this.props.onChange(e)}
                          />{" "}
                        </label>{" "}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
          <div className="col-md-6">
            <label className="form-label text_tra">Company Name</label>
            <input
              type="text"
              className="form-control input_bg"
              placeholder="Enter Your Company's Name"
              name="company_name"
              value={this.state.company.company_name}
              onChange={this.handleChange}
              onBlur={this.blurChange}
              maxLength="80"
            />
            <div className="text-danger">
              {this.state.errors.company_name[0] &&
              this.state.dirty.company_name
                ? this.state.errors.company_name
                : ""}
            </div>

            <div className="  mb-3 text-left mt-4">
              <label className="form-label text_tra">Contact No.</label>
              <div className="input-group">
                <DialCodeList handleChange={this.handleChange} name="company_dialCode" />
                <input
                  type="text"
                  className=" ml-2 form-control input_bg"
                  aria-label="Text input with dropdown button"
                  name="company_phone_no"
                  value={this.state.company.company_phone_no}
                  onChange={this.handleChange}
                  onBlur={this.blurChange}
                  maxLength="13"
                />
              </div>
              <div className="text-danger">
                {this.state.errors.company_phone_no[0] &&
                this.state.dirty.company_phone_no
                  ? this.state.errors.company_phone_no
                  : ""}
              </div>
              <div className="text-danger">
                {this.state.errors.company_dialCode[0] &&
                this.state.dirty.company_dialCode
                  ? this.state.errors.company_dialCode
                  : ""}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <label className="form-label text_tra">Company Email Address</label>
            <input
              type="text"
              className="form-control input_bg"
              placeholder="email@example.com"
              name="company_email_id"
              value={this.state.company.company_email_id}
              onChange={this.handleChange}
              onBlur={this.blurChange}
              maxLength="50"
            />
            <div className="text-danger">
              {this.state.errors.company_email_id[0] &&
              this.state.dirty.company_email_id
                ? this.state.errors.company_email_id
                : ""}
            </div>

            <div className="  mb-3 text-left mt-4">
              <label className="form-label text_tra">Landline (Optional)</label>
              <input
                type="number"
                className="form-control input_bg"
                placeholder="Landline (Optional)"
                name="company_landline"
                value={this.state.company.company_landline}
                onChange={this.handleChange}
                onBlur={this.blurChange}
                maxLength="13"
              />
              <div className="text-danger">
                {this.state.errors.company_landline[0] &&
                this.state.dirty.company_landline
                  ? this.state.errors.company_landline
                  : ""}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="  mb-3 text-left mt-4">
              <label className="form-label text_tra">IEC Number (Optional)</label>

              <input
                type="text"
                className="form-control input_bg"
                placeholder="IEC Number"
                name="company_iec_number"
                value={this.state.company.company_iec_number}
                onChange={this.handleChange}
                onBlur={this.blurChange}
                maxLength="10"
              />
              <div className="text-danger">
                {this.state.errors.company_iec_number[0] &&
                this.state.dirty.company_iec_number
                  ? this.state.errors.company_iec_number
                  : ""}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="  mb-3 text-left mt-4">
              <label className="form-label text_tra">Website (Optional)</label>

              <input
                type="text"
                className="form-control input_bg"
                placeholder="www.example.com"
                name="website"
                value={this.state.company.website}
                onChange={this.handleChange}
                onBlur={this.blurChange}
              />
              <div className="text-danger">
                {this.state.errors.website[0] && this.state.dirty.website
                  ? this.state.errors.website
                  : ""}
              </div>
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <div className="row row justify-content-center pt-3">
              <div className="col-md-8 mb-3">
                <button
                  style={{
                    backgroundColor: "rgb(77 181 196)",
                    border: "none",
                    color: "#ffffff",
                  }}
                  className="btn w-100"
                  onClick={() => this.goToNextPage()}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UserCompany;

import Pagination from "react-bootstrap/Pagination";
import { useEffect, useState } from "react";

const Paginations = (props) => {
  const [paginationConfig, setPaginationConfig] = useState();
  useEffect(() => {
    if (props.item.paginator) {
      let pagination = props.item.paginator;
      let showMax = 4;
      let active = pagination.page;
      let startingIndex = active;
      let endEndex = pagination.totalPages;
      if (startingIndex + showMax < endEndex) {
        endEndex = startingIndex + showMax;
      } else if (startingIndex + showMax > endEndex) {
        startingIndex = endEndex - showMax;
      }
      let items = [];
      for (let num = startingIndex; num < endEndex; num++) {
        if (num > 1) {
          items.push(
            <Pagination.Item
              key={num}
              active={num === active}
              onClick={() => props.pageClick(num)}
            >
              {num}
            </Pagination.Item>
          );
        }
      }
      let page = (
        <>
          <Pagination.Prev
            disabled={pagination.hasPrevPage === false}
            onClick={() => props.pageClick(pagination.page - 1)}
          />
          {pagination.totalPages >= 1 ? (
            <Pagination.Item
              key={1}
              active={1 === active}
              onClick={() => props.pageClick(1)}
            >
              {1}
            </Pagination.Item>
          ) : (
            ""
          )}
          {startingIndex - 1 > 1 ? <Pagination.Ellipsis /> : ""}
          {items}
          {pagination.totalPages - endEndex > 1 ? <Pagination.Ellipsis /> : ""}

          {pagination.totalPages >= 2 ? (
            <Pagination.Item
              key={pagination.totalPages}
              active={pagination.totalPages === active}
              onClick={() => props.pageClick(pagination.totalPages)}
            >
              {pagination.totalPages}
            </Pagination.Item>
          ) : (
            ""
          )}
          <Pagination.Next
            onClick={() => props.pageClick(pagination.page + 1)}
            disabled={pagination.hasNextPage === false}
          />
        </>
      );

      setPaginationConfig(page);
    }
  }, [props.item.paginator]);

  return (
    <div className="pagination-index">
      <Pagination>{paginationConfig}</Pagination>
    </div>
  );
};
export default Paginations;

import {URI} from '../../constant'

export const HOME_GET_COIN_CONSTANTS = ({
    URL: URI+"/coin/",
    REQUEST: 'REQUEST_GET_COIN_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_GET_COIN_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_GET_COIN_DATA_CONSTANTS',
  });

//   export const HOME_POST_COIN_CONSTANTS = ({
//     URL: URI+"/Coin",
//     REQUEST: 'REQUEST_POST_COIN_DATA_CONSTANTS',
//     RECEIVED: 'RECEIVED_POST_COIN_DATA_CONSTANTS',
//     RECEIVED_ERROR: 'ERROR_POST_COIN_DATA_CONSTANTS',
//   });
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAllInvoices,
  getInvoiceDetailsById,
  getInvoiceChanges,
  getInvoiceCreatedByUser,
} from "../../../services/actions/invoiceAction";
import { updateInvoice } from "../../../services/actions/invoiceAction";
import { getInvoiceStatus } from "../../../services/actions/invoiceStatusAction";
import { postAlert } from "../../../services/actions/alertActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import InvoiceMoreDetail from "./invoiceMoreDetail";
import Progressbar from "./progressBar";
import InvoicesListId from "./invoicesListId";
import Header from "./header";
import ajax from "../../../utils/ajax";
import {
  INVOICE_STATUSES_KEY,
  CLIENT_ALERT_TYPES,
} from "../../../config/string_constants";
import { getOfferById } from "../../../services/actions/invoiceOfferAction";
import InvoiceData from "./invoiceData";
import {
  getInvoiceForAdmin,
} from "../../../api/invoiceService";
import ApproveInvoice from "./approveInvoice";
import DeclineInvoice from "./declineInvoice";
class InvoiceDetailsAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceId: this.props.match.params.id,
      invoicedetails: [],
      invoicestatus: [],
      invoice_limit: "",
      selectedInvoice: { invoice_amount: "" },
      companydetails: [],
      showPdf: false,
      invoices: [],
      alertData: {
        invoice_id: "",
        invoice_ref_no: "",
        invoice_status_id: "",
        invoice_substatus_id: "",
        client_Id: "",
      },
      selected: false,
      itemId: "",
      pdfUrl: null,
      screen: INVOICE_STATUSES_KEY.INVOICE_DETAILS,
      dirty: { invoice_limit: false },
      errors: { invoice_limit: [] },
    };
  }
  handleClose = () => {
    this.setState({ showPdf: false });
  };
  onPdfShow = (url) => {
    this.setState({
      showPdf: true,
      pdfUrl: url,
    });
  };
  goback = () => {
    this.props.history.push("/dashboard");
  };
  handleAmount = (e, item) => {
    this.setState(
      {
        invoice_limit: e.target.value,
        selectedInvoice: item,
      },
      this.validate
    );
  };
  validate = () => {
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      errors[control] = [];

      switch (control) {
        case "invoice_limit":
          //fullName can't be blank
          if (!this.state[control]) {
            errors[control].push("This field is required");
          } else {
            if (parseInt(this.state[control]) < 0) {
              errors[control].push("Invoice limit can't be less than zero");
            }
            if (
              parseInt(this.state[control]) >
              parseInt(this.state.selectedInvoice.invoice_amount)
            ) {
              errors[control].push(
                "Invoice limit can't be greater than invoice amount"
              );
            }
          }
        default:
          break;
      }
    });

    this.setState({ errors });
  };
  isValid = () => {
    let valid = true;
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      if (errors[control].length > 0) {
        valid = false;
      }
    });

    return valid;
  };
  blurChange = (e, type) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };
  approveInvoiceLimit = async (item) => {
    this.validate();
    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });

    if (this.isValid()) {
      var invoice = { _id: item._id, invoice_limit: this.state.invoice_limit };
      const invoiceData = await ajax(
        "PUT",
        `/invoice/limit/${item._id}`,
        {},
        {},
        invoice
      );

      toast.info("Invoice limit added");
      this.getAllInvoices(item.created_by_id);
    }
  };
  getAllInvoices = (created_by_id) => {
    getInvoiceForAdmin(created_by_id)
      .then((invoices) => {
        //Get company details of particular invoice
        this.setState({
          invoices: invoices.data,
        });
      })
      .catch((err) => {});
  };
  componentDidMount() {
    this.props
      .getInvoiceDetailsById(this.state.invoiceId)
      .then((result) => {
        this.props.getInvoiceChanges(result);
        this.setState({
          invoicedetails: result,
          selected: true,
          itemId: result._id,
          companydetails: result.client_company,
        });
        this.getAllInvoices(result.created_by_id);
        this.props
          .getOfferById(result._id)
          .then((offer) => {
            this.setState({
              tenure: offer.tenure,
            });
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }

  showData = (item) => {
    this.setState({
      invoiceId: item._id,
      selected: true,
      itemId: item._id,
      companydetails: item.client_company,
    });
  };

  render() {
    return (
      <div>
        <Header />

        <div className="row m-4 ">
          <div className="col-3">
            <InvoiceMoreDetail
              invoicedetails={this.state.invoicedetails}
              invoiceCompany={this.state.companydetails}
            />
          </div>
          <div className="col-9 overflow-auto">
            <Progressbar
              invoiceId={this.state.invoiceId}
              screen={this.state.screen}
            />
          </div>
        </div>

        <div className="row m-2 my-4">
          <div className="col-6">
            <div className="info-one h-100">
              {this.state.invoices.length === undefined ? (
                <div>No data Available </div>
              ) : (
                this.state.invoices
                  .filter((item) => {
                    return (
                      item.invoice_id === this.state.invoicedetails.invoice_id
                    );
                  })
                  .map((item, index) => (
                    <div key={index}>
                      <InvoiceData
                        invoices={item}
                        tenure={this.state.tenure}
                        onPdfShow={this.onPdfShow}
                      />
                      <div></div>
                      {item.invoice_limit ? (
                        item.invoiceStatus_id.key ===
                        INVOICE_STATUSES_KEY.INVOICE_DETAILS ? (
                          <>
                            <div className="mt-5 text-center">
                              <DeclineInvoice
                                invoice={item}
                                toastMessage="Invoice Declined"
                                currentStatusKey={INVOICE_STATUSES_KEY.INVOICE_DETAILS}
                                updateType={CLIENT_ALERT_TYPES.OFFER_MADE}
                                invoiceStatus={this.props.invoiceStatus}
                              />
                                <ApproveInvoice
                                invoice={item}
                                nextStatusRoute="offer-made"
                                nextStatusKey={INVOICE_STATUSES_KEY.OFFER_MADE}
                                currentStatusKey={INVOICE_STATUSES_KEY.INVOICE_DETAILS}
                                updateType={CLIENT_ALERT_TYPES.OFFER_MADE}
                                toastMessage="Invoice Approved"
                                invoiceStatus={this.props.invoiceStatus}
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <div className="mt-5 text-center">
                          <div className="p-3 d-flex my-2 justify-content-between bgc-dark">
                            <div>
                              <span className="text_tra ">
                                Available Credit Limit
                              </span>

                              <input
                                className="input mt-2"
                                type="number"
                                placeholder="Enter Amount"
                                name="invoice_limit"
                                value={this.state.invoice_limit}
                                onChange={(e) => {
                                  this.handleAmount(e, item);
                                }}
                                onBlur={this.blurChanged}
                              />
                              <div className="text-danger">
                                {" "}
                                {this.state.errors.invoice_limit[0] &&
                                this.state.dirty.invoice_limit
                                  ? this.state.errors.invoice_limit
                                  : ""}{" "}
                              </div>
                              {/*(e)=>this.setState({ available_amount : e.target.value}) */}
                            </div>
                            <div style={{ marginTop: "31px" }}>
                              <button
                                className="btn-blue mx-2"
                                onClick={() => {
                                  this.approveInvoiceLimit(item);
                                }}
                              >
                                Approve
                              </button>
                            </div>
                            <div style={{ marginTop: "24px" }}>
                              <i className="txt_b uil-comment-alt-lock" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="info-one h-100">
              <p className="text_tra1 mb-2">INVOICES</p>
              <InvoicesListId
                invoices={this.state.invoices}
                selected={this.state.selected}
                showData={this.showData}
                itemId={this.state.itemId}
              />

              <div style={{ marginTop: "4rem" }} className="p-3  bgc-dark">
                <p className="text-white mt-4">COMPANY DETAILS</p>
                {this.state.companydetails ? (
                  <div className="row">
                    <div className="col-6">
                      <div className="row mt-3  text_tra1">
                        <div className="col-6">
                          Company Contact Number
                          <h6 className="text-white">
                            {this.state.companydetails.company_dialCode}{" "}
                            {this.state.companydetails.company_phone_no}
                          </h6>
                        </div>
                        <div className="col-6">
                          Company Email{" "}
                          <h6 className="text-white">
                            {this.state.companydetails.company_email_id}
                          </h6>
                        </div>
                      </div>
                      <div className="row mt-4 j text_tra1">
                        <div className="col-6">
                          Company Address
                          <h6 className="text-white">
                            {this.state.companydetails.address_line1}
                          </h6>
                        </div>
                        <div className="col-6">
                          Company IEC Number{" "}
                          <h6 className="text-white">
                            {this.state.companydetails.company_iec_number}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="p-3 info-one mx-5 h-100">
                        <div className="mt-2">
                          <label className="text_tra1">Last Updated</label>
                          <h6 className="text_tra1">
                            {moment(this.state.companydetails.updatedAt).format(
                              "DD MMM YYYY"
                            )}
                          </h6>
                        </div>
                        <div className="mt-2">
                          <label className="txt_b">View Insurance Report</label>
                          <p className="txt_b">Coming Soon...</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showPdf} onHide={this.handleClose}>
          <div style={{ backgroundColor: "#333333", color: "white" }}>
            {this.state.pdfUrl !== null ? (
              this.state.pdfUrl.split(".").pop() === "pdf" ||
              this.state.pdfUrl.split(".").pop() === "PDF" ? (
                <iframe
                  src={`https://docs.google.com/gview?url=${this.state.pdfUrl}&embedded=true`}
                  style={{ width: "50rem", height: "50rem" }}
                  frameBorder="0"
                ></iframe>
              ) : (
                <>
                  <img
                    src={this.state.pdfUrl}
                    style={{ width: "30rem", height: "30rem" }}
                  />
                </>
              )
            ) : (
              <div></div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  invoiceStatus: state.invoiceStatus.status,
  alert: state.alert,
  company: state.company,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceChanges: (data) => dispatch(getInvoiceChanges(data)),
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  postAlert: (data) => dispatch(postAlert(data)),
  getAllInvoices: (data) => dispatch(getAllInvoices(data)),
  getInvoiceCreatedByUser: (data) => dispatch(getInvoiceCreatedByUser(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvoiceDetailsAdmin));

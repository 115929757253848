import React, { Component } from "react";
import moment from "moment";
import "../../../assets/css/common/fontStyle.css";
import "../../../assets/css/common/button.css";
import "../../../assets/css/common/popup.css";

import { LEND_NOW_STATUS } from "../../../config/string_constants";
import helper from "../../../utils/helper";
export default class Training extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_show: this.props.show,
      selectedCoinPrice: 0,
      selectedCoin: 0,
    };
  }

  numberWithCommas = (x) => {
    return Number(x)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  ondismis = () => {
    document.getElementById("showViewmoreModal").style.display = "none";
  };

  componentDidMount() { }

  render() {
    const { show, ActiveLendingDetails, selectedCoinPrice } = this.props;
    const userInterestPerDay = helper.getPerDayInterest(
      ActiveLendingDetails.user_bonus_rewards,
      ActiveLendingDetails.lock_amount
    );
    const totalInterest = this.numberWithCommas(
      helper.calculateInterest(ActiveLendingDetails.duration, ActiveLendingDetails.accure_interest_per_day)
      +
      helper.calculateInterest(ActiveLendingDetails.duration, ActiveLendingDetails.bonus_interest_per_day)
      +
      helper.calculateInterest(ActiveLendingDetails.duration, userInterestPerDay)

    );
    const totalInterestInDollars = totalInterest * selectedCoinPrice;

    return (
      <>
        <div
          className={`${this.state.is_show ? "displayModel" : ""
            } modal-lg modal left fade test show`}
          id="showViewmoreModal"
          tabindex=""
          role="dialog"
          aria-labelledby="exampleModalLabel"
        >
          <div
            className="modal-dialog modal-lg"
            role="document"
            style={{ background: "#333333", width: "100%" }}
          >
            <div
              className="modal-content"
              style={{ background: "#333333", width: "100%" }}
            >
              <div
                className="modal-header"
                style={{ borderBottom: "2px solid #595959" }}
              >
                <h5 className="modal-title text-white">Lending Details</h5>
                <button
                  type="button"
                  className="close"
                  onClick={this.ondismis}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ background: "#333333" }}>
                <div className="row">
                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0">Coin Name</label>
                    <div>
                      <img
                        className="mr-2"
                        src={
                          ActiveLendingDetails.pool_id &&
                          ActiveLendingDetails.pool_id.coin_id.image
                        }
                        width="25px"
                        height="25px"
                        alt="img"
                      />
                      <label className="m-0 p-0 font-style-14">
                        {ActiveLendingDetails.pool_id &&
                          ActiveLendingDetails.pool_id.coin_id.name}
                      </label>
                    </div>
                  </div>
                  {/* <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0">Pool Name</label>
                    <div>
                      <label className="m-0 p-0 font-style-14 ">
                        {ActiveLendingDetails.pool_id &&
                          ActiveLendingDetails.pool_id.pool_name}
                      </label>
                    </div>
                  </div> */}
                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0">Pool Name</label>
                    <div>
                      <label className="m-0 p-0 font-style-14 ">
                        {ActiveLendingDetails.pool_id &&
                          ActiveLendingDetails.pool_id.pool_name}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0">Total Amount</label>
                    <div>
                      <label className="m-0 p-0 font-style-14 text-uppercase">
                        {this.numberWithCommas(
                          ActiveLendingDetails.lock_amount
                        )}{" "}
                        {ActiveLendingDetails.pool_id &&
                          ActiveLendingDetails.pool_id.coin_id.symbol}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0">
                      Total Amount in Dollar
                    </label>
                    <div>
                      <label className="m-0 p-0 font-style-14">
                        $
                        {this.numberWithCommas(
                          ActiveLendingDetails.lock_amount * selectedCoinPrice
                        )}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0">
                      Subscription Date
                    </label>
                    <div>
                      <label className="m-0 p-0 font-style-14">
                        {" "}
                        {moment(ActiveLendingDetails.subscription_date).format(
                          "DD MMM  YYYY"
                        )}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0">
                      Redemption Date
                    </label>
                    <div>
                      <label className="m-0 p-0 font-style-14">
                        {moment(ActiveLendingDetails.redemption_date).format(
                          "DD MMM  YYYY"
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0">
                      Locked Period (Days)
                    </label>
                    <div>
                      <label className="m-0 p-0 font-style-14">
                        {ActiveLendingDetails.duration} Days
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0">Standard APY</label>
                    <div>
                      <label className="m-0 p-0 font-style-16">
                        {this.numberWithCommas(ActiveLendingDetails.est_apy)}%
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0">
                      Accured Interest
                    </label>
                    <div>
                      <label className="m-0 p-0 font-style-16 text-uppercase">
                        {this.numberWithCommas(
                          helper.calculateInterest(ActiveLendingDetails.duration, ActiveLendingDetails.accure_interest_per_day)
                        )}{" "}
                        {ActiveLendingDetails.pool_id &&
                          ActiveLendingDetails.pool_id.coin_id.symbol}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0">
                      Interest End Date
                    </label>
                    <div>
                      <label className="m-0 p-0 font-style-14">
                        {moment(ActiveLendingDetails.interest_end_date).format(
                          "DD MMM  YYYY"
                        )}{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 col-6 mt-4">
                    <label
                      className="font-style-8 m-0 p-0"
                      title="Bonus reward APY will be only issued in TRADE tokens."
                    >
                      Bonus APY <i className="uil-info-circle text_tra1" />
                    </label>
                    <div>
                      <label className="m-0 p-0 font-style-16">
                        {this.numberWithCommas(ActiveLendingDetails.bonus_apy)}%
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0 ">
                      Bonus Interest
                    </label>
                    <div>
                      <label className="m-0 p-0 font-style-16 text-uppercase">
                        {this.numberWithCommas(
                          helper.calculateInterest(ActiveLendingDetails.duration, ActiveLendingDetails.bonus_interest_per_day)
                        )}{" "}
                        {ActiveLendingDetails.pool_id &&
                          ActiveLendingDetails.pool_id.coin_id.symbol}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0">Current Date</label>
                    <div>
                      <label className="m-0 p-0 font-style-14">
                        {" "}
                        {moment().format("DD MMM  YYYY")}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0">User APY</label>
                    <div>
                      <label className="m-0 p-0 font-style-16">
                        {this.numberWithCommas(
                          ActiveLendingDetails.user_bonus_rewards
                        )}
                        %
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0 ">
                      User Interest
                    </label>
                    <div>
                      <label className="m-0 p-0 font-style-16 text-uppercase">
                        {this.numberWithCommas(
                          helper.calculateInterest(ActiveLendingDetails.duration, userInterestPerDay)
                        )}
                        {ActiveLendingDetails.pool_id &&
                          ActiveLendingDetails.pool_id.coin_id.symbol}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 col-6 mt-4 d-none">
                    <label className="font-style-8 m-0 p-0">
                      Accrued Coins
                    </label>
                    <div>
                      <label className="m-0 p-0 font-style-14">0 Trade </label>
                    </div>
                  </div>

                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0">Accrued Days</label>
                    <div>
                      <label className="m-0 p-0 font-style-14">
                        {ActiveLendingDetails.duration}{" "}
                        Days
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0 ">Total APY</label>
                    <div>
                      <label className="m-0 p-0 font-style-16 text-uppercase">
                        {this.numberWithCommas(
                          ActiveLendingDetails.user_bonus_rewards +
                          ActiveLendingDetails.est_apy +
                          ActiveLendingDetails.bonus_apy
                        )}
                        %
                      </label>
                    </div>
                  </div>

                  {/* <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0 ">
                      Total Interest
                    </label>
                    <div>
                      <label className="m-0 p-0 font-style-16 text-uppercase">
                        ${totalInterestInDollars.toFixed(2)}
                      </label>
                    </div>
                  </div> */}

                  <div className="col-md-4 col-6 mt-4 d-none">
                    <label className="font-style-8 m-0 p-0">
                      Interest Date
                    </label>
                    <div>
                      <label className="m-0 p-0 font-style-14">
                        {" "}
                        {moment(ActiveLendingDetails.interest_date).format(
                          "DD MMM  YYYY"
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-6 mt-4 d-none">
                    <label className="font-style-8 m-0 p-0">
                      Est. Next Distribution Amount
                    </label>
                    <div>
                      <label className="m-0 p-0 font-style-14">
                        {ActiveLendingDetails.est_next_distribution_amount}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12 col-12 mt-4">
                    <label className="font-style-8 m-0 p-0">
                      Transaction ID
                    </label>
                    <div>
                      <label className="m-0 p-0 font-style-16">
                        <a
                          target="_blank"
                          href={
                            "https://polygonscan.com/tx/" +
                            ActiveLendingDetails.tax_id
                          } rel="noreferrer"
                        >
                          <span className="txt_g">
                            {ActiveLendingDetails.tax_id}
                          </span>
                        </a>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-6 mt-4">
                    <label className="font-style-8 m-0 p-0">Status</label>
                    <div>
                      <label
                        className="yet-verify p-1 m-1 pl-2 pr-2"
                      >
                        Closed
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

import React, { Component } from "react";
import Sidebar from "./sidebar";
import MainHeader from "../mainHeader";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getLenders } from "../../../services/actions/userAction";
import { getKycStatus } from "../../../services/actions/kycStatusAction";
import { getCoins } from "../../../services/actions/coinActions";
import { KYC_STATUS } from "../../../config/string_constants";
import { WALLET_ACTIVATION } from "../../../config/string_constants";
import Paginations from "../../pagination";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kycstatus: [],
      lenders: [],
      paginationItem: [],
      searchLender: "",
      selectedKyc: "",
      coins: [],
    };
  }

  pageChange = async (index) => {
    this.props
      .getLenders(index)
      .then((result) => {
        this.setState({
          lenders: result.data,
          paginationItem: result,
        });
      })
      .catch((err) => {});
  };

  handleChange = (e) => {
    this.setState({
      searchLender: e.target.value,
    });
  };
  handleKycChange = (e) => {
    this.setState({
      selectedKyc: e.target.value,
    });
  };
  viewMore = (id) => {
    sessionStorage.setItem(
      "current_dash_page",
      this.state.paginationItem.paginator.page
    );
    this.props.history.push(`/lender-details/${id}`);
  };

  componentDidMount() {
    let currentPage = sessionStorage.getItem("current_dash_page");
    if (!currentPage) {
      currentPage = 1;
    }
    this.props
      .getLenders(currentPage)
      .then((result) => {
        this.setState({
          lenders: result.data,
          paginationItem: result,
        });
      })
      .catch((err) => {});

    this.props
      .getCoins()
      .then((result) => {
        this.setState({
          coins: result,
        });
      })
      .catch((err) => {});

    this.props
      .getKycStatus()
      .then((result) => {
        this.setState({
          kycstatus: result,
        });
      })
      .catch((err) => {});
  }

  render() {
    return (
      <div>
        <Sidebar />
        <div className="main-content">
          <header>
            <div className="dropdown show m-4">
              <a
                className="admin_button dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="pr-4">LENDER DASHBOARD</span>
              </a>

              <div
                className="admin_button dropdown-menu"
                aria-labelledby="dropdownMenuLink"
              >
                <Link to="/dashboard" className="text-decoration-none ">
                  <a className="text-white dropdown-item" href="#">
                    CLIENT DASHBOARD
                  </a>
                </Link>
              </div>
            </div>
            <MainHeader />
          </header>

          {/*main content*/}

          <main>
            <div className="d-flex w-100 justify-content-between ">
              <span className="mr-auto my-3 mr-4 search d-flex justify-content-end">
                <i
                  style={{
                    position: "absolute",
                    margin: "8px 5px 7px auto",
                    color: "#fff",
                  }}
                  className="uil-search-alt"
                ></i>

                <input
                  className="homesearchbox"
                  type="text"
                  placeholder="Search..."
                  name="searchLender"
                  value={this.state.searchLender}
                  onChange={this.handleChange}
                />
              </span>
              <div>
                <div className="dropdown show my-3">
                  {this.state.kycstatus === undefined ? (
                    <select className="form-select wit_th">
                      <option style={{ background: "#212022", color: "grey" }}>
                        Loading
                      </option>
                    </select>
                  ) : (
                    <select
                      className="form-select wit_th"
                      name="country_id"
                      value={this.state.selectedkyc}
                      onChange={this.handleKycChange}
                    >
                      <option value="">All</option>
                      {this.state.kycstatus.map((item) => (
                        <option
                          style={{ background: "#212022", color: "grey" }}
                          key={item._id}
                          value={item._id}
                        >
                          {item.kycStatus}
                        </option>
                      ))}
                    </select>
                  )}
                  {/* <a className="all_drop dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <span style={{paddingRight:"114px"}}>All</span>
  </a> */}

                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  ></div>
                </div>
              </div>
            </div>
            <div className="table-full-width table-responsive px-0">
              <table className="border-0">
                <thead className="disp_non_sm">
                  <tr className="text_tra1 ">
                    <th className="pl-2">Lender Email</th>
                    <th className="pl-2">Total Lending</th>
                    <th className="pl-2">Active Lending</th>
                    <th className="pl-2">Wallet Activation</th>
                    <th className="pl-3">KYC Status</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.lenders === undefined ? (
                    <tr>No Lenders</tr>
                  ) : (
                    this.state.lenders
                      .filter((data) => {
                        return data.email
                          .toLowerCase()
                          .includes(this.state.searchLender.toLowerCase());
                      })
                      .filter((data) => {
                        return (
                          data.kycStatus === this.state.selectedKyc ||
                          this.state.selectedKyc === ""
                        );
                      })
                      .map((lender, index) => (
                        <tr className="sec1" key={index}>
                          <td className="pt-2 pb-2">{lender.email}</td>
                          <td className="pt-3 pb-3">
                            {lender.lend_total_count}
                          </td>
                          <td className="pt-3 pb-3">
                            {lender.lend_active_count}
                          </td>
                          {lender.wallet_activation ===
                          WALLET_ACTIVATION.YES ? (
                            <td className="txt_b pt-2 pb-2">
                              {lender.wallet_activation}
                            </td>
                          ) : (
                            <td className="txt_red pt-2 pb-2">No</td>
                          )}

                          <td className="pt-2 pb-2">
                            {lender && lender.kyc_data ? (
                              lender.kyc_data.steps.filter(
                                (d) => d.state === KYC_STATUS.NOT_STARTED
                              ).length > 0 ? (
                                <span className="yet-verify">NOT STARTED</span>
                              ) : lender.kyc_data.steps.filter(
                                  (d) => d.state === KYC_STATUS.VALIDATED
                                ).length === 3 ? (
                                <span className="sucess ">VALIDATED</span>
                              ) : lender.kyc_data.steps.filter(
                                  (d) => d.state === KYC_STATUS.PENDING
                                ).length > 0 ? (
                                <span className="in-progress">PENDING</span>
                              ) : (
                                <span className="yet-verify"> NOT STARTED</span>
                              )
                            ) : (
                              <span className="final-reject">
                                FINAL REJECTED
                              </span>
                            )}
                          </td>
                          {/* <td className="pt-2 pb-2">{this.state.kycstatus === undefined ? "" : this.state.kycstatus.filter((status) => status._id === lender.kycStatus).
                            map((item, i) => (
                              <span style={{ color: item.textColor, padding: "10px", backgroundColor: item.backgroundColor, borderRadius: "4px" }}>{item.kycStatus}</span>))}
                          </td> */}
                          <td className="pt-2 pb-2"></td>
                          <td className="pt-2 pb-2">
                            <label
                              className="uploadb px-4 py-2"
                              onClick={() => this.viewMore(lender._id)}
                            >
                              View More
                            </label>
                          </td>
                        </tr>
                      ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-end mx-4 mt-2">
              <Paginations
                item={this.state.paginationItem}
                pageClick={this.pageChange}
              />
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  kycStatus: state.kycStatus,
  coin: state.coin,
});

const mapDispatchToProps = (dispatch) => ({
  getKycStatus: (data) => dispatch(getKycStatus(data)),
  getLenders: (data) => dispatch(getLenders(data)),
  getCoins: (data) => dispatch(getCoins(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));

import * as USER_ACCOUNT_CONSTANTS from "../constants/userAccountConstant";
export const userAccountReducer = (state = {
    type: '',
    error: '',
    isFetching: false,
    postUserAccount: undefined,
   userAccounts:undefined,
   updateUserAccount: undefined,
}, action) => {
   
    switch (action.type) {
                case USER_ACCOUNT_CONSTANTS.HOME_GET_USER_ACCOUNT_BY_ID_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    userAccounts: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case USER_ACCOUNT_CONSTANTS.HOME_GET_USER_ACCOUNT_BY_ID_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    userAccounts: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case USER_ACCOUNT_CONSTANTS.HOME_GET_USER_ACCOUNT_BY_ID_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });

                case USER_ACCOUNT_CONSTANTS.HOME_POST_USER_ACCOUNT_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    postUserAccount: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case USER_ACCOUNT_CONSTANTS.HOME_POST_USER_ACCOUNT_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    postUserAccount: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case USER_ACCOUNT_CONSTANTS.HOME_POST_USER_ACCOUNT_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });

                case USER_ACCOUNT_CONSTANTS.HOME_UPDATE_USER_ACCOUNT_CONSTANTS.REQUEST:
                    return Object.assign({}, state, {
                        isFetching: true,
                        type: action.type,
                        updateUserAccount: undefined,
                        lastUpdated: action.receivedAt,
                    });
        
                case USER_ACCOUNT_CONSTANTS.HOME_UPDATE_USER_ACCOUNT_CONSTANTS.RECEIVED:
                    return Object.assign({}, state, {
                        isFetching: false,
                        type: action.type,
                        updateUserAccount: action.data,
                        lastUpdated: action.receivedAt,
                    });
        
                case USER_ACCOUNT_CONSTANTS.HOME_UPDATE_USER_ACCOUNT_CONSTANTS.RECEIVED_ERROR:
                    return Object.assign({}, state, {
                        isFetching: false,
                        type: action.type,
                        error: action.error,
                    });

        default:
            return state;
    }
};


export default userAccountReducer;

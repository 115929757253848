import React, { Component } from "react";
import Sidebar from "../Lender/Dashboard/Sidebar";
import MainHeader from "../Lender/Dashboard/mainHeader";
import "../../assets/css/common/button.css";
import { URI } from "../../constant";
import { getLenderById } from "../../services/actions/userAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ajax from "../../utils/ajax";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SynapsClient from "@synaps-io/verify.js";
import MobHeader from "./Dashboard/MobHeader";
import Bottomnav from "./Dashboard/bottomnav";
import "../../assets/css/dashboard.css";
import "../../assets2/css/profile_page.css";
import "../../assets2/css/profile.css";
import "../../assets/css/invoices.css";
import "bootstrap/dist/css/bootstrap.min.css";
import kycSuccess from "../../assets/images/Success.svg";
import kycPending from "../../assets/images/Info_kyc.svg";
import { KYC_STATUS } from "../../config/string_constants";
import helper from "../../utils/helper";
import V2KycPopup from "./Common/v2KycPopup";
import V2Banner from "./Common/v2Banner";

class LenderProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sessionId: "",
      redoKyc: false,
      // personal_details: false,
      Loader: true,
      isLoader: false,
      synap_id: "",
      synap_residency: "",
      showSuccess: false,
      aliasList: [],
      full_name: "",
      date_of_birth: "",
      country: [],
      city: [],
      email: "",
      isKyc: true,
      addSuccess: false,
      updateSuccess: false,
      kycData: "",
      document_proof: null,
      kycStatus: sessionStorage.getItem("kycStatus"),
      v2KycModal: false,
      isV2Verified: false,
    };
  }

  getKycSession = async () => {
    try {
      const userData = await ajax(
        "GET",
        `${URI}/kyc/new-session/${sessionStorage.getItem("userId")}`,
        {}
      );
      sessionStorage.setItem(
        "kyc_session",
        userData.data[0].kyc_session ? userData.data[0].kyc_session : ""
      );
    } catch (err) {}
  };

  /**
   * Check for session or initialise kyc session
   */
  checkKycSessionSynapse = async () => {
    const sessionKyc = sessionStorage.getItem("kyc_session");
    if (this.state.redoKyc) {
      this.initializeKycSynapse();
    } else {
      const initializeKyc = await this.getKycSession();
      this.initializeKycSynapse();
    }
  };

  sessionAlias = async () => {
    const aliasList = await ajax("GET", `${URI}/kyc/session-alias`, {});
    this.setState({ aliasList: aliasList });
    if (aliasList.data.length !== 0) {
      const verifiedSession = aliasList.data.filter((data) => {
        return data.status === KYC_STATUS.VERIFIED;
      });
      const pendingSession = aliasList.data.filter((data) => {
        return data.status === KYC_STATUS.PENDING;
      });
      if (verifiedSession.length !== 0) {
        this.setState({
          sessionId: verifiedSession[0].session_id,
          kycStatus: KYC_STATUS.VALIDATED,
        });
        sessionStorage.setItem("kycStatus", KYC_STATUS.VALIDATED);
        sessionStorage.setItem("ActionToPerform", true);
        sessionStorage.setItem("kyc_session", verifiedSession[0].session_id);
        this.getDetails();
      } else {
        this.setState({
          sessionId: pendingSession[0].session_id,
          kycStatus: KYC_STATUS.PENDING,
        });
        sessionStorage.setItem("kycStatus", KYC_STATUS.PENDING);
        sessionStorage.setItem("kyc_session", pendingSession[0].session_id);
        this.getDetails();
      }
    }
  };

  /**
   * KYC initialize and trigger kyc
   */
  initializeKycSynapse = async () => {
    const Synaps = new SynapsClient(
      sessionStorage.getItem("kyc_session"),
      "individual"
    );
    Synaps.init({
      type: "embed",
    });
    Synaps.on("finish", async (data) => {
      this.sessionAlias();
    });
  };

  componentDidMount() {
    this.sessionAlias();
    sessionStorage.setItem("ShowKycPopup", "false");
    this.setState({ showInviteChannel: true });
  }

  handleV2Popup = (user) => {
    if (user.data.is_v2_verified) {
      this.setState({ isV2Verified: true });
    } else {
      this.setState({ v2KycModal: true });
    }
  };

  //Verify why we need this function
  getDetails = async () => {
    const userSessionData = await ajax(
      "GET",
      `${URI}/kyc/session/${sessionStorage.getItem("userId")}/${
        this.state.sessionId
      }`,
      {}
    );
    this.setState({ Loader: false });
    this.setState({
      kycData: userSessionData.data.kyc_data,
      email: helper.isNotEmpty(userSessionData.data.email)
        ? userSessionData.data.email
        : "",
      synap_id: helper.isNotEmpty(
        userSessionData.data.kyc_data.submitted_documents.identity
      )
        ? userSessionData.data.kyc_data.submitted_documents.identity
        : "",
      synap_residency: helper.isNotEmpty(
        userSessionData.data.kyc_data.submitted_documents.residency
      )
        ? userSessionData.data.kyc_data.submitted_documents.residency
        : "",
      full_name: helper.isNotEmpty(userSessionData.data.document)
        ? userSessionData.data.document.details.info.firstname.value
        : sessionStorage.getItem("uName"),
      date_of_birth: helper.isNotEmpty(userSessionData.data.document)
        ? userSessionData.data.document.details.info.birth_date.value
        : "",
    });
    if (
      !helper.isNotEmpty(this.state.synap_id) ||
      !helper.isNotEmpty(this.state.synap_residency)
    ) {
      this.setState({ redoKyc: true });
    }
    this.handleV2Popup(userSessionData);
  };

  render() {
    return (
      <div>
        <Sidebar />
        <Bottomnav />
        <div className="main-content main-content-lender">
          <MobHeader />

          <header className="disp-sm-none">
            <h2 className="heading" style={{ marginLeft: "16px" }}></h2>
            <MainHeader />
          </header>

          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {this.state.kycStatus === KYC_STATUS.VALIDATED ||
          this.state.kycStatus === KYC_STATUS.PENDING ? (
            <div className="container-fluid mt-85">
              <div className="container-fluid">
                <div className="d-flex ml-2 row">
                  <h5 className="text-white mt-4">
                    {this.state.Loader ? (
                      <span
                        className="spinner-border text-info mx-2 loader-sm"
                        role="status"
                      ></span>
                    ) : (
                      ""
                    )}
                    {this.state.kycStatus === KYC_STATUS.VALIDATED ? (
                      <span>
                        <img
                         alt="kyc success"
                          src={kycSuccess}
                          style={{ height: "20px", width: "20px" }}
                        />
                        <lable
                          style={{
                            color: "#52CCB8",
                            fontSize: "14px",
                            fontFamily: "Averta",
                          }}
                        >
                          KYC Verified
                        </lable>
                      </span>
                    ) : (
                      <span>
                        <img
                          alt="kyc pending"
                          src={kycPending}
                          style={{ height: "20px", width: "20px" }}
                        />
                        <lable style={{ color: "#F2BD54" }}>KYC Pending</lable>
                      </span>
                    )}{" "}
                  </h5>
                </div>
                <div className="container-fluid profile-grid p-3">
                  <div>
                    <h5 className="text-white"> KYC details</h5>
                  </div>
                  <div className="row ">
                    <div className="col-6">
                      <p className="text_tra1">Email</p>
                      <h5 className="text-white text-capitalize">
                        {this.state.email
                          ? this.state.email.toLowerCase()
                          : "-"}
                      </h5>
                    </div>
                  </div>

                  <hr />
                  <div>
                    <h5 className="text-white"> KYC Status</h5>
                  </div>
                  <div className="acc_details">
                    <div>
                      <p className="text_tra1">
                        Liveness
                        <img
                          alt="img"
                          src={
                            this.state.kycData && this.state.kycData.steps[0]
                              ? this.state.kycData.steps[0].state ===
                                KYC_STATUS.VALIDATED
                                ? kycSuccess
                                : kycPending
                              : kycSuccess
                          }
                          style={{ height: "20px", width: "20px" }}
                        />
                      </p>
                      <h5 className="text-white">
                        {this.state.kycStatus === KYC_STATUS.VALIDATED
                          ? "Verified"
                          : "Pending"}
                      </h5>
                    </div>
                    <div>
                      <p className="text_tra1">
                        ID Verification
                        <img
                          alt="img"
                          src={
                            this.state.kycData && this.state.kycData.steps[1]
                              ? this.state.kycData.steps[1].state ===
                                KYC_STATUS.VALIDATED
                                ? kycSuccess
                                : kycPending
                              : kycSuccess
                          }
                          style={{ height: "20px", width: "20px" }}
                        />
                      </p>
                      <h5 className="text-white text-lowercase">
                        {this.state.synap_id}{" "}
                      </h5>
                    </div>
                    <div>
                      <p className="text_tra1">
                        Proof Of Residency{" "}
                        <img
                          alt="img"
                          src={
                            this.state.kycData && this.state.kycData.steps[2]
                              ? this.state.kycData.steps[2].state ===
                                KYC_STATUS.VALIDATED
                                ? kycSuccess
                                : kycPending
                              : kycSuccess
                          }
                          style={{ height: "20px", width: "20px" }}
                        />
                      </p>

                      <h5 className="text-white text-lowercase">
                        {this.state.synap_residency}{" "}
                      </h5>
                    </div>
                    <div>
                      <p className="text_tra1"></p>
                      <h5 className="text-white"> </h5>
                    </div>
                  </div>
                </div>

                {this.state.kycStatus === KYC_STATUS.VALIDATED &&
                  !this.state.isV2Verified && (
                    <V2KycPopup
                      show={this.state.v2KycModal}
                      onHide={() => this.setState({ v2KycModal: false })}
                    />
                  )}

                {this.state.redoKyc ? (
                  <div className="mt-4 text-center">
                    {/* <button
                      data-toggle='modal'
                      data-target='#exampleModal'
                      className='btn  custom-button '
                      id='synaps-btn'
                      onClick={this.checkKycSessionSynapse}
                    >
                      Continue KYC
                    </button> */}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div style={{ margin: "11px" }}>
                <V2Banner />
              </div>
            </div>
          ) : (
            <div
              className="h-100"
              style={{
                position: "relative",
                top: "10%",
                bottom: "25%",
              }}
            >
              <V2Banner />
              <h3 className="text-white text-center">
                {" "}
                "Please wait for version 2 to be released."{" "}
              </h3>
              {/* <button
                data-toggle='modal'
                data-target='#exampleModal'
                className='btn-blue justify-content-center align-self-center'
                id='synaps-btn'
                onClick={this.checkKycSessionSynapse}
              >
                Verify KYC
              </button> */}
            </div>
          )}
          <div
            className="modal fade"
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div
                    className="container "
                    style={{ height: "90vh" }}
                    id="synaps-embed"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  country: state.country,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  getLenderById: (data) => dispatch(getLenderById(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LenderProfile));

import React, { Component } from "react";
import styles from "../../assets/css/OnboardingPersonalDetails.module.css";
import Logo from "../../assets/images/Logo.svg";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getInvoiceStatus } from "../../services/actions/invoiceStatusAction";
import { postAlert } from "../../services/actions/alertActions";
import MainHeader from "./mainHeader";
import ActivityLog from "./activityLog";
import { getAlertsForAdmin } from "../../api/alertService";
import Select from "react-select";
import { INVOICE_STATUSES_KEY } from "../../config/string_constants";
import ClientTable from "./Borrower/ClientTable";
import InvoiceTable from "./Borrower/InvoiceTable";

class BorrowerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceStatus: [],
      selectedStatus: "",
      searchInvoice: "",
      focus: false,
      alertDetails: [],
    };
  }

  activityItem = async (data) => {
    const res = await getAlertsForAdmin(data);
    this.setState({
      alertDetails: res.data,
    });
  };

  getAlertsForAdmin = async () => {
    try {
      const res = await getAlertsForAdmin();
      this.setState({
        alertDetails: res.data,
      });
    } catch (error) {}
  };

  handleStatusChange = (data) => {
    let status = [];
    for (let i = 0; i < data.length; i++) {
      status.push(data[i].value);
    }
    this.setState(
      {
        selectedStatus: status,
      }
    );
  };

  handleSearchChange = (e) => {
    this.setState({
      searchInvoice: e.target.value,
      focus: e.target.value.length > 0 ? true : false,
    });
  };

  callInvoice = (currentStatus, id) => {
    if (currentStatus === INVOICE_STATUSES_KEY.INVOICE_DETAILS) {
      this.props.history.push(`/invoice-details/${id}`);
    }
    if (currentStatus === INVOICE_STATUSES_KEY.OFFER_MADE) {
      this.props.history.push(`/offer-made/${id}`);
    }
    if (currentStatus === INVOICE_STATUSES_KEY.COMPANY_DOCUMENT) {
      this.props.history.push(`/company-document/${id}`);
    }
    if (currentStatus === INVOICE_STATUSES_KEY.KYC_PROCESS) {
      this.props.history.push(`/kyc-process/${id}`);
    }
    if (currentStatus === INVOICE_STATUSES_KEY.INVOICE_DOCUMENT) {
      this.props.history.push(`/invoice-document/${id}`);
    }
    if (currentStatus === INVOICE_STATUSES_KEY.POOL_GOVERNANCE) {
      this.props.history.push(`/pool-governance/${id}`);
    }
    if (currentStatus === INVOICE_STATUSES_KEY.FINAL_OFFER) {
      this.props.history.push(`/final-offer/${id}`);
    }
    if (currentStatus === INVOICE_STATUSES_KEY.ARPA) {
      this.props.history.push(`/arpa/${id}`);
    }
    if (currentStatus === INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER) {
      this.props.history.push(`/invoice-verification-by-buyer/${id}`);
    }
    if (currentStatus === INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION) {
      this.props.history.push(`/advance-fund-allocation/${id}`);
    }
    if (currentStatus === INVOICE_STATUSES_KEY.RESERVE_FUND_ALLOCATION) {
      this.props.history.push(`/reserve-fund-allocation/${id}`);
    }
    if (currentStatus === INVOICE_STATUSES_KEY.COLLECTION) {
      this.props.history.push(`/collection/${id}`);
    }
    if (currentStatus === INVOICE_STATUSES_KEY.HISTORY_BOOK) {
      this.props.history.push(`/history-book/${id}`);
    }
  };

  componentDidMount() {
    //Get All Invoice status
    this.props
      .getInvoiceStatus()
      .then((result) => {
        this.setState({
          invoiceStatus: result,
        });
      })
      .catch((err) => {});
  }

  render() {
    return (
      <div>
        <nav className="d-flex  Navbar" style={{ zIndex: 2 }}>
          <img
            onClick={() => this.props.history.push("/dashboard")}
            className={styles.Logo}
            src={Logo}
            alt="logo"
            style={{ width: "173px" }}
          />
          <div
            style={{
              height: "91px",
              borderRight: "1px solid gray",
              marginLeft: "227px",
            }}
          ></div>

          <div className="d-flex dropdown show m-4">
            <a
              className="admin_button dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="pr-4">CLIENT DASHBOARD</span>
            </a>

            <div
              className="admin_button dropdown-menu"
              aria-labelledby="dropdownMenuLink"
            >
              <Link to="/lender-dashboard">
                <a className="text-white dropdown-item" href="#">
                  LENDER DASHBOARD
                </a>
              </Link>
            </div>
          </div>

          <div className="user-wrapper ml-auto mr-4">
            <MainHeader />
          </div>
        </nav>

        <div className="tab" role="tabpanel">
          <ul className="nav nav-tabs" role="tablist">
            <li role="presentation" className="active pro_mone1">
              <a
                style={{ textDecoration: "none" }}
                href="#Sections1"
                aria-controls="home"
                role="tab"
                data-toggle="tab"
              >
                Invoices
              </a>
            </li>
            <li role="presentation" className="pro_mone2">
              <a
                style={{ textDecoration: "none" }}
                href="#Sections2"
                aria-controls="profile"
                role="tab"
                data-toggle="tab"
              >
                Client
              </a>
            </li>
            <li role="presentation" className="pro_mone2">
              <a
                style={{ textDecoration: "none" }}
                href="#activity"
                aria-controls="alerts"
                role="tab"
                data-toggle="tab"
                onClick={this.getAlertsForAdmin}
              >
                Activity
              </a>
            </li>
            <span className="ml-auto mr-4 search d-flex justify-content-end dropdown-index">
              <span className="ml-auto mr-4 search d-flex justify-content-end">
                <i
                  style={{
                    position: "absolute",
                    margin: "8px 5px 7px auto",
                    color: "#fff",
                  }}
                  className="uil-search-alt"
                ></i>

                <input
                  className="homesearchbox"
                  id="searchInvoice"
                  name="searchInvoice"
                  type="text"
                  placeholder="Search..."
                  value={this.state.searchInvoice}
                  onChange={this.handleSearchChange}
                />
              </span>
              {this.state.invoiceStatus === undefined ? (
                <select className="form-select wit_th">
                  <option style={{ background: "#212022", color: "grey" }}>
                    Loading
                  </option>
                </select>
              ) : (
                <Select
                  isMulti
                  options={Object.entries(this.state.invoiceStatus).map(
                    ([value, invoiceStatus]) => ({
                      label: invoiceStatus.displayName,
                      value: invoiceStatus._id,
                    })
                  )}
                  styles={{
                    input: (provided, state) => ({
                      ...provided,
                      color: "white",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused && "lightgray",
                      color: state.isFocused && "#333333",
                    }),
                  }}
                  onChange={this.handleStatusChange}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              )}
            </span>
          </ul>

          <div className="tab-content">
            <div role="tabpanel" className="tab-pane  active" id="Sections1">
              <InvoiceTable
                searchInvoice={this.state.searchInvoice}
                selectedStatus={this.state.selectedStatus}
                callInvoice={this.callInvoice}
              />
            </div>
            <div role="tabpanel" className="tab-pane fade" id="Sections2">
              <ClientTable />
            </div>
            <div role="tabpanel" className="tab-pane fade" id="activity">
              <ActivityLog
                alertDetails={this.state.alertDetails}
                history={this.props.history}
                paginateActivity={this.activityItem}
              ></ActivityLog>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  invoiceStatus: state.invoiceStatus,
  alert: state.alert,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  postAlert: (data) => dispatch(postAlert(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BorrowerDashboard));

import * as ACTIVITY_CONSTANTS from "../constants/activityConstant";
export const activityReducer = (state = {
    type: '',
    error: '',
    isFetching: false,
   activity:undefined,
}, action) => {
   
    switch (action.type) {
                case ACTIVITY_CONSTANTS.HOME_GET_ACTIVITY_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    activity: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case ACTIVITY_CONSTANTS.HOME_GET_ACTIVITY_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    activity: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case ACTIVITY_CONSTANTS.HOME_GET_ACTIVITY_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });

                case ACTIVITY_CONSTANTS.HOME_POST_ACTIVITY_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    activity: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case ACTIVITY_CONSTANTS.HOME_POST_ACTIVITY_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    activity: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case ACTIVITY_CONSTANTS.HOME_POST_ACTIVITY_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });


        default:
            return state;
    }
};


export default activityReducer;

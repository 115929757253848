import react from "react";
import { Logo, Brand } from "../../constant";
import styles from "../../assets/css/OnboardingPersonalDetails.module.css";
import "../../Global.css";
import "../../assets2/css/profile.css";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { postCompany } from "./../../services/actions/companyActions";
import { getCountry } from "./../../services/actions/countryActions";
import { postUser } from "./../../services/actions/userAction";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import { UserContext } from "../../context/UserContext";
import check from "../../assets/images/check1.png";
import upload from "../../assets/images/upload.png";
import { URI } from "../../constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ajax from "../../utils/ajax";
// import Loader from '../assets/../images/ajax-loader.gif'
import UserHeader from "./userHeader";
import UserCompany from "./userCompany";
import UserCompAddress from "./userCompAddress";
import { URLS } from '../../config/frontendUrls';

class Headers extends react.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      showPageLoader: false,
      file: null,
      progressState: 1,
      showSuceess: false,
      fillForms: { form1: false, form2: false },

      country: [],
      company: {
        company_name: "",
        company_email_id: "",
        company_phone_no: "",
        website: "",
        about: "",
        company_landline: "",
        user_id: sessionStorage.getItem("userId"),
        address_line1: "",
        address_line2: "",
        city: "",
        zipcode: "",
        company_logo: "",
        company_iec_number: "",
        company_dialCode: "",
        full_name: "",
        designation: "",
        phone_number: "",
        email: "",
        user_dialCode: "",
      },
      city: [],
    };
  }

  saveCompany = (e) => {
    this.props.postCompany(this.state.company);
    this.setState({
      showPageLoader: true,
    });
  };

  onChange = (e) => {
    this.uploadFile(e.target.files[0]);
    let files = e.target.files;

    let reader = new FileReader();
    if (e.target.files[0] !== undefined) {
      reader.readAsDataURL(files[0]);
      reader.onLoad = (e) => {};
    }
  };

  uploadFile(file) {
    const formData = new FormData();

    formData.append("image", file);

    if (file !== undefined) {
      ajax(
        "POST",
        `${URI}/api/v1/image-upload`,
        {},
        { "content-type": "multipart/form-data" },
        formData
      )
        .then((data) => {
          // let { company } = this.state;
          //      company.company_logo =data.data.imageUrl;
          this.setState(
            {
              company: {
                ...this.state.company,
                company_logo: data.data.imageUrl,
              },
            },
            () => {}
          );
        })
        .catch((err) => {});
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_isEmpty(nextProps.company.company) && this.state.showPageLoader) {
      this.setState({
        showPageLoader: false,
      });
      setTimeout(() => toast.info("Company Added Successfully"), 1000);

      // sessionStorage.setItem("userId",nextProps.user.userChecked._id);
      setTimeout(() => this.props.history.replace(URLS.CLIENT.DASHBOARD), 2000);
    }
    if (!_isEmpty(nextProps.user.userChecked) && this.state.showPageLoader) {
      this.setState({
        showPageLoader: false,
      });
      // setTimeout(() => toast.info("Company added Successfully"), 1000);

      // sessionStorage.setItem("userId",nextProps.user.userChecked._id);
      setTimeout(() => this.props.history.replace(URLS.CLIENT.DASHBOARD), 2000);
    }
    if (!_isEmpty(nextProps.country.country)) {
      this.setState(
        {
          country: nextProps.country.country,
        },
        () => {}
      );
    }
  }

  componentDidMount() {
    this.setState({
      user: {
        ...this.state.user,
        email: sessionStorage.getItem("emailOfUser"),
      },
    });

    this.props.getCountry();
    if (!_isEmpty(this.props.country.country)) {
      this.setState({ country: this.props.country.country });
    }
  }
  removeLogo = () => {
    this.setState({ company: { ...this.state.company, company_logo: "" } });
  };
  goToCompanyDetails = (user) => {
    this.setState({
      company: user,
      progressState: 2,
    });
  };
  goToAddressDetails = (copmany) => {
    this.setState({
      copmany: copmany,
      progressState: 3,
    });
  };
  render() {
    return (
      <>
        <nav className="d-flex  Navbar">
          <a href="#">
            <img className={styles.Logo} src={Logo} alt="" />
            <div
              className="disp-sm-none"
              style={{
                height: "91px",
                borderRight: "1px solid gray",
                marginLeft: "227px",
              }}
            ></div>
          </a>
        </nav>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* Body Tab */}
        <div className="page-content page-container" id="page-content ">
          <div>
            <div className=" d-flex justify-content-center">
              <div className="header-container">
                <div className="tab-content mb-4">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                  >
                    <div className="my-3">
                      <ul
                        className="nav nav-pills justify-content-center  pt-2"
                        id="myTab"
                        role="tablist"
                      >
                        <li className="nav-item curserP  move1">
                          <span className="nav-link p-1 StatusItem active">
                            {this.state.progressState === 1 ? (
                              "01"
                            ) : (
                              <img
                              alt="check"
                                src={check}
                                onClick={() =>
                                  this.setState({ progressState: 1 })
                                }
                              />
                            )}
                            <div
                              className="mt-1 font-12 txt_b"
                              style={{ width: "7rem", marginLeft: "-37px" }}
                            >
                              Personal Details
                            </div>
                          </span>
                        </li>
                        {this.state.progressState === 1 ? (
                          <div className="line_right mt-3"></div>
                        ) : (
                          <div className="Cline_left mt-3"></div>
                        )}
                        <li className="nav-item curserP ">
                          <span
                            className={
                              this.state.progressState === 1
                                ? "nav-link p-6px StatusItem text_act"
                                : "nav-link p-1 active StatusItem text_act"
                            }
                          >
                            {this.state.progressState === 3 ? (
                              <span>
                                <img
                                alt="check"
                                  src={check}
                                  onClick={() =>
                                    this.setState({ progressState: 2 })
                                  }
                                />
                              </span>
                            ) : (
                              "02"
                            )}

                            <div
                              className={
                                this.state.progressState == 3
                                  ? "mt-1 txt_b font-12"
                                  : "mt-1 font-12"
                              }
                              style={{ width: "7rem", marginLeft: "-38px" }}
                            >
                              Company Details
                            </div>
                          </span>
                        </li>

                        {this.state.progressState == 3 ? (
                          <div className="Cline_left mt-3"></div>
                        ) : (
                          <div className="line_right mt-3"></div>
                        )}

                        <li className="nav-item curserP move3">
                          <span
                            className={
                              this.state.progressState == 3
                                ? "nav-link p-6px active StatusItem text_act"
                                : "nav-link p-6px StatusItem text_act"
                            }
                          >
                            03
                            <div
                              className="mt-1 font-12"
                              style={{ width: "8rem", marginLeft: "-65px" }}
                            >
                              Company Address
                            </div>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="borderw mt-5 disp-lg-none"></div>
                    {/* first page start */}
                    <div className="container">
                      {this.state.progressState == 1 ? (
                        <UserHeader
                          country={this.state.country}
                          user={this.state.company}
                          goToCompanyDetails={this.goToCompanyDetails}
                        />
                      ) : (
                        ""
                      )}
                    </div>

                    {/* first page End */}
                  </div>
                  <div>
                    <div className="borderw mt-5 disp-lg-none"></div>

                    {/* second page start */}
                    {this.state.progressState === 2 ? (
                      <UserCompany
                        country={this.state.country}
                        onChange={this.onChange}
                        company={this.state.company}
                        removeLogo={this.removeLogo}
                        goToAddressDetails={this.goToAddressDetails}
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  {/* second page End */}

                  <div>
                    <div className="borderw mt-5 disp-lg-none"></div>

                    {/* third page start */}

                    <div className="container">
                      {this.state.progressState == 3 ? (
                        <UserCompAddress
                          country={this.state.country}
                          company={this.state.company}
                          saveCompany={this.saveCompany}
                        />
                      ) : (
                        ""
                      )}
                    </div>

                    {/* third page End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  company: state.company,
  user: state.user,
  country: state.country,
});

const mapDispatchToProps = (dispatch) => ({
  postCompany: (data) => dispatch(postCompany(data)),
  postUser: (data) => dispatch(postUser(data)),
  getCountry: (data) => dispatch(getCountry(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Headers));

import {URI} from '../../constant'

export const HOME_POST_SMART_TOKEN_CONSTANTS = ({
    URL: URI+"/sc/login",
    REQUEST: 'REQUEST_POST_SMART_TOKEN__DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_POST_SMART_TOKEN__DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_POST_SMART_TOKEN__DATA_CONSTANTS',
  });

  export const HOME_GET_PRICING_DATA_CONSTANTS = ({
    URL: URI+"/sc/pricing/",
    REQUEST: 'REQUEST_GET_PRICING_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_GET_PRICING_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_GET_PRICING_DATA_CONSTANTS',
  });

  export const HOME_CREATE_NEW_OFFER_DATA_CONSTANTS = ({
    URL: URI+"/sc/offer/",
    REQUEST: 'REQUEST_CREATE_NEW_OFFER_CONSTANTS',
    RECEIVED: 'RECEIVED_CREATE_NEW_OFFER_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_CREATE_NEW_OFFER_CONSTANTS',
  });

  export const HOME_GET_TXN_STATUS_CONSTANTS = ({
    URL: URI+"/sc/txn-status/",
    REQUEST: 'REQUEST_GET_TXN_STATUS_CONSTANTS',
    RECEIVED: 'RECEIVED_GET_TXN_STATUS_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_GET_TXN_STATUS_CONSTANTS',
  });
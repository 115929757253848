import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import preview from "../../../assets/images/preview.png";
import download from "../../../assets/images/download1.png";
import { getOfferById } from "../../../services/actions/invoiceOfferAction";

import {
  getInvoiceDetailsById,
  getInvoiceChanges,
  getInvoiceCreatedByUser,
} from "../../../services/actions/invoiceAction";
import { updateInvoice } from "../../../services/actions/invoiceAction";
import { getInvoiceStatus } from "../../../services/actions/invoiceStatusAction";
import { postAlert } from "../../../services/actions/alertActions";
import InvoiceMoreDetail from "./invoiceMoreDetail";
import { URI } from "../../../constant";
import trash from "../../../assets/images/trash.png";
import Progressbar from "./progressBar";
import ajax from "../../../utils/ajax";
import Header from "./header";
import {
  INVOICE_STATUSES_KEY,
  CLIENT_ALERT_TYPES,
} from "../../../config/string_constants";
import helper from "../../../utils/helper";
import { getInvoiceForAdmin } from "../../../api/invoiceService";
import BuyerDetails from "./buyerDetails";
import SoftOffer from "./softOfferDetails";
import InvoiceData from "./invoiceData";
import PricingDetails from "./pricingDetails";
import InvoicesListId from "./invoicesListId";
import FinalOfferDetails from "./finalOfferDetails";
import { HELPERS } from "../../../helpers/index";
import ApproveInvoice from "./approveInvoice";
import DeclineInvoice from "./declineInvoice";
class arpa extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeId: "",
      Unlock: false,
      generateOffer: false,
      previewOffer: false,
      invoiceOffer: [],
      buyerCompany: "",
      invoiceId: this.props.match.params.id,
      invoicedetails: "",
      invoices: [],
      invoiceCompany: [],
      pricingData: [],
      company_id: "",
      showPdf: false,
      pdfUrl: null,
      selected: false,
      articleId: "",
      locked: true,
      error: "",
      uploadLoader: false,
      alertData: {
        invoice_id: "",
        invoice_ref_no: "",
        invoice_status_id: "",
        invoice_substatus_id: "",
        client_Id: "",
      },
      updateSuccess: false,
      alertSuccess: false,
      pricingDetails: false,
      screen: INVOICE_STATUSES_KEY.ARPA,
      finaloffer: [],
      clientCompanyId: "",
      pricingList: [],
      pricing_id: "",
    };
  }

  componentDidMount() {
    this.props
      .getInvoiceDetailsById(this.state.invoiceId)
      .then((result) => {
        this.props.getInvoiceChanges(result);
        this.setState({
          invoicedetails: result,
          buyerCompany: result.buyer_company,
          company_id: result.client_company,
          selected: true,
          articleId: result._id,
          clientCompanyId: result.client_company,
          finaloffer: result.final_offer,
          invoiceCompany: result.client_company,
        });

        getInvoiceForAdmin(result.created_by_id)
          .then((invoices) => {
            //Get company details of particular invoice
            this.setState({
              invoices: invoices.data,
            });
          })
          .catch((err) => {});
        this.props
          .getOfferById(result._id)
          .then((offer) => {
            this.setState({
              invoiceOffer: offer,
              tenure: offer.tenure,
            });
          })
          .catch((err) => {});
        //calling Helper function to getPricingDetails
        HELPERS.getPricingDetails(this.state.finaloffer.pricing_id)
          .then((pricing) => {
            this.setState({ pricingData: pricing });
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }

  showData = (item) => {
    this.setState(
      {
        invoiceId: item._id,
        company_id: item.client_company,
        selected: true,
        articleId: item._id,
      },
      () =>
        this.props
          .getInvoiceDetailsById(this.state.invoiceId)
          .then((result) => {
            this.setState({
              invoicedetails: result,
              company_id: result.client_company,
              selected: true,
              articleId: result._id,
              finaloffer: result.final_offer,
              invoiceCompany: result.client_company,
            });
          })
          .catch((err) => {})
    );
  };

  onPdfShow = (url) => {
    this.setState({
      showPdf: true,
      pdfUrl: url,
    });
  };

  handleClose = () => {
    this.setState({ showPdf: false });
  };

  fileChange = (e, item) => {
    if (helper.validateFileType(e.target.files)) {
      this.uploadFile(e.target.files[0], item);
    } else {
      var errorMessage =
        "Please Upload file in JPG, PNG, PDF and max size 5mb.";
      this.setState({
        error: errorMessage,
        uploadLoader: false,
      });
    }
  };
  uploadFile(file, item) {
    this.setState({ uploadLoader: true });
    let alert_data = {
      invoice_id: this.state.invoicedetails._id,
      invoice_ref_no: this.state.invoicedetails.invoice_id,
      invoice_status_id: this.state.invoicedetails.invoice_status_id._id,
      invoice_substatus_id: this.state.invoicedetails.invoice_substatus_id,
      client_Id: this.state.invoicedetails.created_by_id,
      alert_message: "Please attach arpa document",
    };
    const formData = new FormData();
    formData.append("image", file);
    if (file !== undefined) {
      ajax(
        "POST",
        `${URI}/api/v1/image-upload`,
        {},
        { "content-type": "multipart/form-data" },
        formData
      )
        .then((data) => {
          item.arpa_document = data.data.imageUrl;

          this.setState(
            {
              updateSuccess: true,
              uploadLoader: false,
            },
            () => {
              this.props
                .updateInvoice(item)
                .then((invoice) => {
                  toast.info("ARPA Document Uploaded");
                  this.props.postAlert(alert_data);
                  this.setState({
                    updateSuccess: false,
                    invoiceId: invoice._id,
                    error: "",
                  });
                  this.props
                    .getInvoiceDetailsById(this.state.invoiceId)
                    .then((invoice) => {
                      this.props.getInvoiceChanges(invoice);
                    })
                    .catch((err) => {});
                })
                .catch((err) => {});
            }
          );
        })
        .catch((err) => {});
    }
  }

  deleteArpa = (data) => {
    data.arpa_document = null;
    data.borrower_arpa_document = null;
    this.setState(
      {
        updateSuccess: true,
      },
      () => {
        this.props.updateInvoice(data).then((result) => {
          toast.info("ARPA Document Deleted");
          this.setState({
            updateSuccess: false,
            invoiceId: result._id,
          });
        });
      }
    );
  };
  deleteUserArpa = (data) => {
    data.borrower_arpa_document = null;

    this.setState(
      {
        updateSuccess: true,
      },
      () => {
        this.props.updateInvoice(data).then((result) => {
          toast.info("ARPA Client Document Deleted");
          this.setState({
            updateSuccess: false,
            invoiceId: result._id,
          });
        });
      }
    );
  };

  render() {
    return (
      <div>
        <Header />
        <div className="row m-4">
          <div className="col-3">
            <InvoiceMoreDetail
              invoicedetails={this.state.invoicedetails}
              invoiceCompany={this.state.invoiceCompany}
            />
          </div>
          <div className="col-9 overflow-auto">
            <Progressbar
              invoiceId={this.state.invoiceId}
              screen={this.state.screen}
            />
          </div>
        </div>
        <div className="row m-2 my-4">
          <div className="col-6">
            <div className="info-one h-100">
              <div className="tab-content">
                <div className="tab-pane active" id="tabs-1" role="tabpanel">
                  {this.state.invoices === undefined ? (
                    <div>No data Available </div>
                  ) : (
                    this.state.invoices
                      .filter((item) => {
                        return (
                          item.invoice_id ===
                          this.state.invoicedetails.invoice_id
                        );
                      })
                      .map((item, index) => (
                        <div key={index}>
                          <InvoiceData
                            invoices={item}
                            tenure={this.state.tenure}
                            onPdfShow={this.onPdfShow}
                          />
                          <p className="text_tra1 mt-4">Upload ARPA document</p>
                          <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                            <h5>ARPA document</h5>
                            <div className="text-white">
                              {item.arpa_document ? (
                                <div className="row">
                                  <div className="col-sm-6">
                                    Admin File
                                    <div></div>
                                    <a
                                      href
                                      onClick={() =>
                                        this.onPdfShow(item.arpa_document)
                                      }
                                    >
                                      <img
                                        alt="preview"
                                        className="mx-3"
                                        src={preview}
                                      />
                                    </a>
                                    <a
                                      href={item.arpa_document}
                                      style={{ color: "white" }}
                                    >
                                      {" "}
                                      <img
                                        alt="download"
                                        className="mx-3"
                                        src={download}
                                      />
                                    </a>
                                    <a
                                      href
                                      onClick={() => this.deleteArpa(item)}
                                      style={{ color: "white" }}
                                    >
                                      {" "}
                                      <img
                                        alt="delete"
                                        className="mx-3"
                                        src={trash}
                                      />
                                    </a>
                                  </div>
                                  <div className="col-sm-6">
                                    Client File
                                    <div></div>
                                    <span
                                      style={{
                                        paddingLeft: "10vw !important;",
                                      }}
                                    >
                                      {" "}
                                      {item.borrower_arpa_document == null ? (
                                        "Sent to Client"
                                      ) : (
                                        <span>
                                          <a
                                            href
                                            onClick={() =>
                                              this.onPdfShow(
                                                item.borrower_arpa_document
                                              )
                                            }
                                          >
                                            <img
                                              alt="preview"
                                              className="mx-3"
                                              src={preview}
                                            />
                                          </a>
                                          <a
                                            href={item.borrower_arpa_document}
                                            style={{ color: "white" }}
                                          >
                                            {" "}
                                            <img
                                              alt="download"
                                              className="mx-3"
                                              src={download}
                                            />
                                          </a>
                                          <a
                                            href
                                            onClick={() =>
                                              this.deleteUserArpa(item)
                                            }
                                            style={{ color: "white" }}
                                          >
                                            {" "}
                                            <img
                                              alt="delete"
                                              className="mx-3"
                                              src={trash}
                                            />
                                          </a>
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <span>
                                  {" "}
                                  <label className="uploadb header-input mx-3">
                                    {this.state.uploadLoader ? (
                                      <div className="spinner-border btn--icon"></div>
                                    ) : (
                                      "Upload"
                                    )}
                                    <input
                                      type="file"
                                      style={{
                                        display:
                                          "btn btn-outline-primary col_erone",
                                      }}
                                      name="company_coi_url"
                                      onChange={(e) => this.fileChange(e, item)}
                                    />
                                  </label>
                                </span>
                              )}
                            </div>

                            {this.state.error && (
                              <div className="text-danger">
                                {this.state.error}
                              </div>
                            )}
                          </div>
                          <div></div>
                          {item.arpa_document && item.borrower_arpa_document ? (
                            <div className="mt-5 text-center">
                              <DeclineInvoice
                                invoice={item}
                                toastMessage="ARPA Declined"
                                updateType={CLIENT_ALERT_TYPES.ARPA_APPROVED}
                                invoiceStatus={this.props.invoiceStatus}
                                currentStatusKey={INVOICE_STATUSES_KEY.ARPA}
                              />
                              <ApproveInvoice
                                invoice={item}
                                nextStatusRoute="invoice-verification-by-buyer"
                                updateType={CLIENT_ALERT_TYPES.ARPA_APPROVED}
                                toastMessage="ARPA Approved"
                                invoiceStatus={this.props.invoiceStatus}
                                nextStatusKey={
                                  INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER
                                }
                                currentStatusKey={INVOICE_STATUSES_KEY.ARPA}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ))
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="info-one h-100">
              <p className="text_tra1 mb-2">INVOICES</p>
              <InvoicesListId
                invoices={this.state.invoices}
                selected={this.state.selected}
                showData={this.showData}
                itemId={this.state.articleId}
              />

              <PricingDetails
                pricingData={this.state.pricingData}
                show={false}
              />

              {this.state.invoicedetails ? (
                <FinalOfferDetails
                  invoiceId={this.state.invoiceId}
                  invoicedetails={this.state.invoicedetails}
                  tenure={this.state.tenure}
                />
              ) : (
                ""
              )}
              <BuyerDetails buyerDetails={this.state.buyerCompany} />
              <SoftOffer invoiceOffer={this.state.invoiceOffer} show={false} />
            </div>
          </div>
        </div>

        <Modal show={this.state.showPdf} onHide={this.handleClose}>
          <div style={{ backgroundColor: "#333333", color: "white" }}>
            {this.state.pdfUrl !== null ? (
              this.state.pdfUrl.split(".").pop() === "pdf" ||
              this.state.pdfUrl.split(".").pop() === "PDF" ? (
                <iframe
                  src={`https://docs.google.com/gview?url=${this.state.pdfUrl}&embedded=true`}
                  style={{ width: "50rem", height: "50rem" }}
                  frameBorder="0"
                ></iframe>
              ) : (
                <>
                  <img
                    src={this.state.pdfUrl}
                    style={{ width: "30rem", height: "30rem" }}
                    alt="pdfUrl"
                  />
                </>
              )
            ) : (
              <div></div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  invoiceStatus: state.invoiceStatus.status,
  alert: state.alert,
  company: state.company,
  invoiceOffer: state.invoiceOffer,
  invoiceFinalOffer: state.invoiceFinalOffer,
  pricing: state.pricing,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceChanges: (data) => dispatch(getInvoiceChanges(data)),
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  postAlert: (data) => dispatch(postAlert(data)),
  getInvoiceCreatedByUser: (data) => dispatch(getInvoiceCreatedByUser(data)),
  getOfferById: (data) => dispatch(getOfferById(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(arpa));

import React from "react";
import Sidebar from "../Dashboard/Sidebar";
import "../../../assets/css/invoices.css";
import OfferPopup from "./offerPopup";
import Check from "../../../assets/images/check.png";
import Eye from "../../../assets/images/Eye.svg";
import preview from "../../../assets/images/preview.png";
import Decline from "../../../assets/images/Rejected.png";
import Uncheck from "../../../assets/images/Uncheck.png";
import InProgress from "../../../assets/images/inprogress.png";
import arrow from "../../../assets/images/arrow.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getFinalOfferById } from "../../../services/actions/invoiceFinalOfferAction";
import { postOffer } from "../../../services/actions/smartContractAction";
import { getInvoiceDetailsById } from "../../../services/actions/invoiceAction";
import MainHeader from "../Dashboard/mainHeader";
import download from "../../../assets/images/download1.png";
import { getInvoiceStatus } from "../../../services/actions/invoiceStatusAction";
import { postAlert } from "../../../services/actions/alertActions";
import moment from "moment";
import { pdfjs } from "react-pdf";
import Modal from "react-bootstrap/Modal";
import FinalcontractPopup from "./finalcontractPopup";
import { getOfferById } from "../../../services/actions/invoiceOfferAction";
import {
  updateInvoice,
  getInvoiceData,
} from "../../../services/actions/invoiceAction";
import { URI } from "../../../constant";
import { toast } from "react-toastify";
import ajax from "../../../utils/ajax";
import { updateOffer } from "../../../services/actions/invoiceOfferAction";
import {
  INVOICE_STATUSES,
  ADMIN_ALERT_TYPES,
  INVOICE_SUB_STATUSES,
  INVOICE_STATUSES_KEY,
} from "../../../config/string_constants";
import helper from "../../../utils/helper";
import Mob_header from "../Dashboard/mob_header";
import { acceptSoftOffer, rejectSoftOffer } from "../../../api/invoiceService";
import { getUserAccountsById } from "../../../services/actions/userAccountAction";
import { ACCOUNT_TYPE } from "../../../config/string_constants";
import { URLS } from "../../../config/frontendUrls";

class InvoiceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      invoiceId: this.props.match.params.id,
      invoice_ref_no: "",
      softOffer: true,
      showSoftOffer: false,
      finalOffer: true,
      fileError: "",
      invoicedetails: [],
      invoiceStatus: "",
      finaloffer: {},
      reserve_fund_allocation: {},
      invoiceSubStatus: "",
      invoiceStatusArray: [],
      showPdf: false,
      pdfUrl: "",
      showContract: false,
      invoiceOffer: {},
      kycStatus: false,
      invoiceDetailpopupShow: true,
      updateSuccess: false,
      loader: false,
      alertSuccess: false,
      offerCreatedId: "",
      offerDoneId: "",
      offerDeclinedId: "",
      offerId: "",
      bankDetails: {},
      alertData: {
        invoice_id: "",
        invoice_ref_no: "",
        invoice_status_id: "",
        invoice_substatus_id: "",
        client_Id: "",
      },
      newOffer: {
        amount: "",
        grade: "",
        tenure: "",
        pricing_id: "",
        factoring_fee: "",
        discount: "",
        advance_percentage: "",
        reserve_percentage: "",
        grace_period: "",
        bank_charges: "",
        amount_to_be_funded: "",
        access_token: "",
        duration: 12,
        fee: "",
        late_fee: "",
        created_date: "",
        expired: false,
        mailSentStatus: "NOTSENT",
        regenerate_request: false,
      },
      smartOffer: [],
    };
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }
  handleClose = () => {
    this.setState({ showPdf: false });
  };
  onPdfShow = (url) => {
    this.setState({
      showPdf: true,
      pdfUrl: url,
    });
  };

  getSoftOfferById = (id) => {
    this.setState({
      modalShow: true,
    });
  };
  getAccountDetails = (_id) => {
    this.props.getUserAccountsById(_id).then((accounts) => {
      const userPrimaryAccount = accounts.find(
        (item) => item.account_type === ACCOUNT_TYPE.PRIMARY
      );
      this.setState({ bankDetails: userPrimaryAccount });
    });
  };
  postAcceptSoftOffer = async (id) => {
    try {
      let data = { _id: this.state.invoiceId };
      const invoiceStatusArray = this.state.invoiceStatusArray;
      let obj = this.state.invoiceStatusArray.find(
        (o) => o.key === INVOICE_STATUSES_KEY.COMPANY_DOCUMENT
      );
      const finalSubStatus = obj
        ? obj.subStatus.find(
            (o) => o.statusName === INVOICE_SUB_STATUSES.IN_PROGRESS
          )
        : {};

      data.invoiceSubStatus_id = finalSubStatus._id;
      data.invoiceStatus_id = obj._id;
      data.update_type = ADMIN_ALERT_TYPES.OFFER_ACCEPTED;
      data.tnc_status = true;

      let response = await acceptSoftOffer(data);

      if (response.status === 200 || response.status === 201) {
        if (
          !this.state.invoicedetails.client_company.sales_ledger_url ||
          !this.state.invoicedetails.client_company.company_audit_url ||
          !this.state.invoicedetails.client_company.bank_statement_url ||
          !this.state.invoicedetails.client_company.previous_audit_url
        ) {
          this.setState({
            modalShow: true,
            kycStatus: true,
          });
        } else {
          this.props.history.push(URLS.CLIENT.INVOICES);
        }
      } else {
        this.props.history.push(URLS.CLIENT.INVOICES);
      }
    } catch (e) {}
  };
  postRejectSoftOffer = async (reason) => {
    try {
      let data = { _id: this.state.invoiceId };
      const invoiceStatusArray = this.state.invoiceStatusArray;
      let obj = this.state.invoiceStatusArray.find(
        (o) => o.key === INVOICE_STATUSES_KEY.OFFER_MADE
      );
      const finalSubstatus = obj
        ? obj.subStatus.find(
            (o) => o.statusName === INVOICE_SUB_STATUSES.DECLINED
          )
        : {};
      data.soft_offer_reject_reason = reason;
      data.invoiceStatus_id = obj._id;
      data.invoiceSubStatus_id = finalSubstatus._id;

      let response = await rejectSoftOffer(data);
      if (response.status === 200 || response.status === 201) {
        this.setState({
          kycStatus: false,
          modalShow: false,
        });
        this.props.history.push(URLS.CLIENT.INVOICES);
      }
    } catch (e) {}
  };
  getFinalOfferData = (invoiceAmount, smartData) => {
    if (smartData.amount_to_be_funded) {
      smartData.amount_to_be_funded =
        (smartData.advance_percentage * smartData.amount) / 100;
      smartData.discount_amount =
        ((smartData.amount_to_be_funded * smartData.discount) / 100 / 365) *
        smartData.tenure;
      smartData.factoring_amount =
        (smartData.factoring_fee * parseInt(invoiceAmount)) / 100;
      smartData.fee = smartData.discount_amount + smartData.factoring_amount;
      smartData.created_date = new Date();
    }
    return smartData;
  };
  componentDidMount() {
    this.props
      .getInvoiceDetailsById(this.state.invoiceId)
      .then((result) => {
        this.getOfferMadeDetails(result._id);
        if (result.invoiceStatus_id !== undefined) {
          if (result.invoiceStatus_id.subStatus !== undefined) {
            result.invoiceStatus_id.subStatus.map((sub) => {
              if (sub._id === result.invoiceSubStatus_id) {
                this.setState({
                  invoicedetails: result,
                  invoiceStatus: result.invoiceStatus_id.displayName,
                  invoiceSubStatus: sub.statusName,
                });
              }
            });
          }
        }
        this.calculateValues();
        this.getAccountDetails(result.created_by_id);
        let final_offer = this.getFinalOfferData(
          result.invoice_amount,
          result.final_offer
        );
        this.setState({
          finaloffer: final_offer,
          invoice_ref_no: result.invoice_id,
        });
      })
      .catch((err) => {});

    this.setState({
      invoiceStatusArray: this.props.invoiceStatus,
    });
    let updateStatus =
      this.props.invoiceStatus?.length !== undefined
        ? this.props.invoiceStatus.map((status) =>
            status.invoiceStatus === INVOICE_STATUSES.FINAL_OFFER
              ? status.subStatus !== undefined
                ? status.subStatus.map((sub) =>
                    sub.statusName === INVOICE_SUB_STATUSES.CREATED
                      ? this.setState({
                          invoicestatus: this.props.invoiceStatus,
                          offerId: status._id,
                          offerCreatedId: sub._id,
                        })
                      : status.subStatus.map((sub) =>
                          sub.statusName === INVOICE_SUB_STATUSES.DONE
                            ? this.setState({
                                offerId: status._id,
                                offerDoneId: sub._id,
                              })
                            : ""
                        )
                  )
                : ""
              : ""
          )
        : "";
  }
  calculateValues = () => {
    const lateDay = helper.calculateNumberOfLateDays(
      this.state.invoicedetails?.invoice_due_date,
      this.state.invoicedetails?.final_offer.grace_period,
      this.state.invoicedetails?.collection_details.payment_date
    );
    const financeTenure = helper.calculateFinanceTenure(
      this.state.invoicedetails?.collection_details?.payment_date,
      this.state.invoicedetails?.final_offer?.advance_date
    );
    const totalAmountRecieved =
      Number(this.state.invoicedetails.collection_details.amount_received) +
      Number(
        this.state.invoicedetails.collection_details
          .amount_received_from_supplier
      );
    const lateFeeValue = helper.calculateLateFeesHandler(
      this.state.invoicedetails?.final_offer?.late_fee,
      this.state.invoicedetails?.final_offer?.amount_to_be_funded,
      lateDay
    );
    const discountingFee = helper.calculateDiscountFee(
      this.state.invoicedetails?.final_offer?.discount,
      this.state.invoicedetails?.final_offer?.amount_to_be_funded,
      financeTenure,
      lateDay
    );
    const factoringFee = helper.calculateFactoringFee(
      this.state.invoicedetails?.final_offer?.factoring_fee,
      this.state.invoicedetails?.invoice_amount
    );
    const fee =
      discountingFee +
      factoringFee +
      lateFeeValue +
      this.state.invoicedetails?.final_offer?.bank_charges;
    const client_payable = helper.calculateNetPayableToClientHandler(
      totalAmountRecieved,
      this.state.invoicedetails.final_offer.amount_to_be_funded,
      fee
    );
    this.setState({
      reserve_fund_allocation: {
        ...this.state.reserve_fund_allocation,
        client_payable: client_payable,
        fee: fee,
        late_day: lateDay,
        late_fee_value: lateFeeValue,
        total_amount_received: totalAmountRecieved
      },
    });
  };

  softOfferDetails = () => {
    this.getSoftOfferById(this.state.invoiceId);
    this.setState({ softOffer: true });
  };
  getOfferMadeDetails = (invoiceId) => {
    this.props
      .getOfferById(invoiceId)
      .then((result) => {
        this.setState({
          invoiceOffer: result,
          showSoftOffer: true,
        });
      })
      .catch((err) => {});
  };
  sendRequestForSoftOffer = () => {
    const data = this.state.invoiceOffer;
    data.regenerate_request = true;

    this.props
      .updateOffer(data)
      .then((response) => {
        this.getOfferMadeDetails(this.state.invoiceId);
        // this.props.history.push(URLS.CLIENT.INVOICES);
      })
      .catch((err) => {});
  };
  fileChange = (e, item) => {
    if (helper.validateFileType(e.target.files)) {
      this.uploadFile(e.target.files[0], item);
    } else {
      this.setState({
        loader: false,
        fileError: "Please Upload file in JPG, PNG, PDF and max size 5mb.",
      });
    }
  };
  uploadFile(file, item) {
    this.setState({ loader: true });
    const formData = new FormData();
    formData.append("image", file);
    if (file !== undefined) {
      ajax(
        "POST",
        `${URI}/api/v1/image-upload`,
        {},
        { "content-type": "multipart/form-data" },
        formData
      )
        .then((data) => {
          item.borrower_arpa_document = data.data.imageUrl;
          item.update_type = ADMIN_ALERT_TYPES.ARPA;
          this.setState(
            {
              updateSuccess: true,
              loader: false,
              fileError: "",
            },
            () => {
              this.props
                .updateInvoice(item)
                .then((invoice) => {
                  toast.info("ARPA Document Uploaded");
                  this.setState({
                    updateSuccess: false,
                    invoiceId: invoice.invoice_id,
                  });
                })
                .catch((err) => {});
            }
          );
        })
        .catch((err) => {});
    }
  }
  deleteUserArpa = (data) => {
    data.borrower_arpa_document = null;

    this.setState(
      {
        updateSuccess: true,
      },
      () => {
        this.props.updateInvoice(data).then((result) => {
          toast.info("ARPA Document Deleted");
          this.setState({
            updateSuccess: false,
            invoiceId: result.invoice_id,
          });
        });
      }
    );
  };
  DateDiff = (date1, date2) => {
    var datediff = date1.getTime() - date2.getTime(); //store the getTime diff - or +
    return Math.round(datediff / (24 * 60 * 60 * 1000)); //Convert values to -/+ days and return value
  };
  sendRequest = () => {
    this.setState({
      loader: true,
    });

    const data = this.state.invoicedetails;
    const final = this.state.invoicedetails.final_offer;

    this.setState({
      alertData: {
        ...this.state.alertData,
        invoice_id: data._id,
        invoice_ref_no: data.invoice_id,
        client_Id: data.created_by_id,
      },
      newOffer: {
        amount: final.amount,
        grade: final.grade,
        tenure:
          final.tenure -
          this.DateDiff(new Date(), new Date(final.created_date)),
        pricing_id: final.pricing_id,
        factoring_fee: final.factoring_fee,
        discount: final.discount,
        advance_percentage: final.advance_percentage,
        reserve_percentage: final.reserve_percentage,
        grace_period: final.grace_period,
        bank_charges: final.bank_charges,
        amount_to_be_funded: final.amount_to_be_funded,
        duration: 12,
      },
    });

    this.setState(
      {
        updateSuccess: true,
      },
      () => {
        this.props
          .postOffer(this.state.newOffer)
          .then((result) => {
            this.setState(
              {
                smartOffer: result.result,
                offerSuccess: true,
                updateSuccess: false,
                generateOffer: false,
                offerDetails: false,
                triggerSmartContract: false,
              },
              () => {
                const smartData = result.result;
                smartData.amount_to_be_funded =
                  (this.state.smartOffer.advance_percentage *
                    this.state.smartOffer.amount) /
                  100;
                smartData.discount_amount =
                  ((smartData.amount_to_be_funded *
                    this.state.smartOffer.discount) /
                    100 /
                    365) *
                  this.state.smartOffer.tenure;
                smartData.factoring_amount =
                  (this.state.smartOffer.factoring_fee *
                    this.state.smartOffer.amount) /
                  100;
                smartData.fee =
                  smartData.discount_amount + smartData.factoring_amount;
                smartData.created_date = new Date();
                smartData.regenerate_request = false;
                smartData.expired = false;
                smartData.mailSentStatus = "NOTSENT";

                data.final_offer = smartData;

                this.setState(
                  {
                    updateSuccess: true,
                  },
                  () => {
                    // data.invoiceSubStatus_id = "617152905ec5c64b00e7cd1b";
                    this.props
                      .updateInvoice(data)
                      .then((result) => {
                        toast.info("Offer Generated Successfully");
                        this.setState(
                          {
                            updateSuccess: false,
                            alertSuccess: true,
                            loader: false,
                          },
                          () => {
                            this.props
                              .postAlert(this.state.alertData)
                              .then((alert) => {
                                this.setState({ alertSuccess: false });
                                this.props
                                  .getInvoiceDetailsById(this.state.invoiceId)
                                  .then((invoice) => {
                                    this.setState({
                                      finaloffer: invoice.final_offer,
                                    });
                                  })
                                  .catch((err) => {});
                              })
                              .catch((err) => {});
                          }
                        );
                      })
                      .catch((err) => {});
                  }
                );
              }
            );
          })
          .catch((err) => {
            toast.error("Invoice Expired!!");
            this.setState({
              loader: false,
            });
          });
      }
    );
  };

  render() {
    return (
      <div>
        <Sidebar />
        <OfferPopup
          show={this.state.modalShow}
          kycStatus={this.state.kycStatus}
          postRejectSoftOffer={this.postRejectSoftOffer}
          postAcceptSoftOffer={() => this.postAcceptSoftOffer}
          invoiceOffer={this.state.invoiceOffer}
          softOffer={this.state.softOffer}
          onHide={() => this.setState({ modalShow: false })}
        />
        <FinalcontractPopup
          show={this.state.showContract}
          dataofinvoice={this.state.invoicedetails}
          idofinvoice={this.state.invoiceId}
          finalOffer={this.state.finalOffer}
          finalOfferDetails={this.state.finaloffer ? this.state.finaloffer : {}}
          onHide={() => this.setState({ showContract: false })}
        />
        <div className="main-content">
          <Mob_header />
          <header className="header1 ">
            <div className="header_title ">
              <h2 className="heading">Invoices</h2>
            </div>

            <MainHeader />
          </header>
          <div className="sub-header">
            <p style={{ padding: "7px", color: "white", paddingLeft: "21px" }}>
              <img
                style={{ marginRight: "12px" }}
                src={arrow}
                width="18px"
                height="18x"
                onClick={() => this.props.history.push(URLS.CLIENT.INVOICES)}
                alt="img"
              />
              Invoice #{this.state.invoice_ref_no}
            </p>
            <hr style={{ backgroundColor: "#4d4d4d" }} />
          </div>
          <div className="invoicebar d-none d-sm-block">
            <div className="row">
              <div className="col-sm-2 text-white">
                <p>Buyer</p>
                <p className="text_tra1">
                  {this.state.invoicedetails
                    ? this.state.invoicedetails.buyer_company
                      ? this.state.invoicedetails.buyer_company.company_name
                      : ""
                    : ""}
                </p>
              </div>
              <div className="col-sm-2 text-white">
                <p>Invoice date</p>
                <p className="text_tra1">
                  {moment(
                    this.state.invoicedetails
                      ? this.state.invoicedetails.invoice_date
                      : ""
                  ).format("DD/MM/YYYY")}
                </p>
              </div>
              <div className="col-sm-2 text-white">
                <p>Due date</p>
                <p className="text_tra1">
                  {moment(
                    this.state.invoicedetails
                      ? this.state.invoicedetails.invoice_due_date
                      : ""
                  ).format("DD/MM/YYYY")}
                </p>
              </div>
              <div className="col-sm-2 text-white">
                <p>Amount</p>
                <p className="text_tra1">
                  {"$" +
                    Number(
                      this.state.invoicedetails
                        ? this.state.invoicedetails.invoice_amount
                        : 0
                    )
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </p>
              </div>
              <div className="col-sm-2 text-white">
                <p>Offer Amount</p>
                <p>
                  {"$" +
                    Number(
                      this.state.invoicedetails.final_offer
                        ? this.state.invoicedetails.final_offer
                            .amount_to_be_funded
                        : 0
                    )
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </p>
              </div>
              <div className="col-sm-2 text-white">
                <p>Invoice</p>
                {this.state.invoicedetails ? (
                  <p>
                    <a
                      onClick={() =>
                        this.onPdfShow(
                          this.state.invoicedetails.invoice_pdf_link
                        )
                      }
                    >
                      <img src={preview} />
                    </a>
                    <a
                      href={this.state.invoicedetails.invoice_pdf_link}
                      style={{ color: "white" }}
                    >
                      {" "}
                      <img src={download} />
                    </a>
                  </p>
                ) : (
                  ""
                )}{" "}
              </div>
            </div>
          </div>

          {/* mobile view */}
          <div className="d-block m-4 d-sm-none">
            <table
              className=" borderR"
              style={{ backgroundColor: "#333333", border: "none" }}
            >
              <tr>
                <td>
                  <div className="text-white">Buyer</div>
                  <div className="font14">
                    {this.state.invoicedetails
                      ? this.state.invoicedetails.buyer_company
                        ? this.state.invoicedetails.buyer_company.company_name
                        : ""
                      : ""}
                  </div>
                </td>
                <td>
                  <div className="text-white">Invoice Date</div>
                  <div className="font14">
                    {moment(
                      this.state.invoicedetails
                        ? this.state.invoicedetails.invoice_date
                        : ""
                    ).format("DD/MM/YYYY")}
                  </div>
                </td>
                <td>
                  <div className="text-white">Due Date</div>
                  <div className="font14">
                    {moment(
                      this.state.invoicedetails
                        ? this.state.invoicedetails.invoice_due_date
                        : ""
                    ).format("DD/MM/YYYY")}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="text-white">Amount</div>
                  <div className="font14">
                    {"$" +
                      Number(
                        this.state.invoicedetails
                          ? this.state.invoicedetails.invoice_amount
                          : ""
                      )
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                  </div>
                </td>
                <td>
                  <div className="text-white">Trade Offer</div>
                  <div className="font14">--</div>
                </td>
                <td></td>
              </tr>

              <tr>
                <td>
                  {this.state.invoicedetails ? (
                    <div
                      onClick={() =>
                        this.onPdfShow(
                          this.state.invoicedetails.invoice_pdf_link
                        )
                      }
                      className="txt_b"
                    >
                      Preview
                    </div>
                  ) : (
                    ""
                  )}
                </td>
                <td></td>
                <td>
                  {this.state.invoicedetails ? (
                    <a
                      href={this.state.invoicedetails.invoice_pdf_link}
                      className="txt_b"
                    >
                      Download
                    </a>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            </table>
          </div>
          {/* mobile view end */}
          {/* {this.state.invoiceStatus}-{this.state.invoiceSubStatus} */}
          <div style={{ margin: "12px" }}>
            <p
              style={{ color: "white", paddingLeft: "25px", fontSize: "18px" }}
            >
              Invoice Status
            </p>
          </div>
          <div className="container row">
            <div className="col-lg-8 col-sm-12">
              <div className="vl">
                <div>
                  {this.state.invoiceStatus === INVOICE_STATUSES.SUBMITTED ||
                  this.state.invoiceStatus === INVOICE_STATUSES.OFFER_MADE ||
                  this.state.invoiceStatus ===
                    INVOICE_STATUSES.COMPANY_DOCUMENTATION ||
                  this.state.invoiceStatus === INVOICE_STATUSES.KYC ||
                  this.state.invoiceStatus ===
                    INVOICE_STATUSES.INVOICE_DOCUMENTATION ||
                  this.state.invoiceStatus ===
                    INVOICE_STATUSES.POOL_GOVERNANCE ||
                  this.state.invoiceStatus === INVOICE_STATUSES.FINAL_OFFER ||
                  this.state.invoiceStatus === INVOICE_STATUSES.ARPA ||
                  this.state.invoiceStatus ===
                    INVOICE_STATUSES.FINAL_INVOICE_VERIFICATION ||
                  this.state.invoiceStatus ===
                    INVOICE_STATUSES.ADVANCE_FUND_ALLOCATION ||
                  this.state.invoiceStatus === INVOICE_STATUSES.BUYER_PAYMENT ||
                  this.state.invoiceStatus ===
                    INVOICE_STATUSES.RESERVE_FUND_ALLOCATION ||
                  this.state.invoiceStatus === INVOICE_STATUSES.TRADE_CLOSED ? (
                    <p
                      className={
                        this.state.invoiceStatus ===
                          INVOICE_STATUSES.OFFER_MADE &&
                        this.state.invoiceSubStatus ===
                          INVOICE_SUB_STATUSES.DECLINED
                          ? `invoice-list-decline`
                          : `${
                              this.state.invoiceStatus ===
                                INVOICE_STATUSES.OFFER_MADE &&
                              this.state.invoiceStatus ===
                                INVOICE_SUB_STATUSES.IN_PROGRESS
                                ? "invoice-list-progress"
                                : "invoice-list"
                            }`
                      }
                    >
                      {this.state.invoiceStatus ===
                        INVOICE_STATUSES.SUBMITTED &&
                      this.state.invoiceSubStatus ===
                        INVOICE_SUB_STATUSES.DECLINED ? (
                        <img
                          style={{ width: "19px" }}
                          src={Decline}
                          className="user-icon"
                          alt="img"
                        />
                      ) : (
                        <img
                          style={{ width: "19px" }}
                          src={Check}
                          className="user-icon"
                          alt="img"
                        />
                      )}
                      Invoice Submitted
                      {this.state.invoiceStatus ===
                      INVOICE_STATUSES.SUBMITTED ? (
                        <p>
                          <p
                            style={{
                              fontSize: "small",
                              color: `${
                                this.state.invoiceSubStatus ===
                                INVOICE_SUB_STATUSES.DECLINED
                                  ? "#E9556E"
                                  : "#24B29B"
                              }`,
                              paddingLeft: "24px",
                            }}
                          >
                            {this.state.invoiceSubStatus}
                          </p>
                        </p>
                      ) : (
                        ""
                      )}
                    </p>
                  ) : (
                    <p className="invoice-list  list-gray fnt-clr">
                      <img
                        style={{ width: "19px" }}
                        className="user-icon"
                        src={Uncheck}
                        alt="img"
                      />
                      Invoice Submitted
                    </p>
                  )}

                  {this.state.invoiceStatus === INVOICE_STATUSES.SUBMITTED &&
                  this.state.invoiceSubStatus ===
                    INVOICE_SUB_STATUSES.DECLINED ? (
                    ""
                  ) : (
                    <div>
                      {this.state.invoiceStatus ===
                        INVOICE_STATUSES.OFFER_MADE ||
                      this.state.invoiceStatus ===
                        INVOICE_STATUSES.COMPANY_DOCUMENTATION ||
                      this.state.invoiceStatus === INVOICE_STATUSES.KYC ||
                      this.state.invoiceStatus ===
                        INVOICE_STATUSES.INVOICE_DOCUMENTATION ||
                      this.state.invoiceStatus ===
                        INVOICE_STATUSES.POOL_GOVERNANCE ||
                      this.state.invoiceStatus ===
                        INVOICE_STATUSES.FINAL_OFFER ||
                      this.state.invoiceStatus === INVOICE_STATUSES.ARPA ||
                      this.state.invoiceStatus ===
                        INVOICE_STATUSES.FINAL_INVOICE_VERIFICATION ||
                      this.state.invoiceStatus ===
                        INVOICE_STATUSES.ADVANCE_FUND_ALLOCATION ||
                      this.state.invoiceStatus ===
                        INVOICE_STATUSES.BUYER_PAYMENT ||
                      this.state.invoiceStatus ===
                        INVOICE_STATUSES.RESERVE_FUND_ALLOCATION ||
                      this.state.invoiceStatus ===
                        INVOICE_STATUSES.TRADE_CLOSED ? (
                        <p
                          className={
                            this.state.invoiceStatus ===
                              INVOICE_STATUSES.COMPANY_DOCUMENTATION &&
                            this.state.invoiceSubStatus ===
                              INVOICE_SUB_STATUSES.DECLINED
                              ? "invoice-list-decline"
                              : `${
                                  this.state.invoiceStatus ===
                                    INVOICE_STATUSES.OFFER_MADE &&
                                  this.state.invoiceSubStatus ===
                                    INVOICE_SUB_STATUSES.IN_PROGRESS
                                    ? "invoice-list-progress"
                                    : "invoice-list"
                                }`
                          }
                        >
                          {this.state.invoiceStatus ===
                            INVOICE_STATUSES.OFFER_MADE &&
                          this.state.invoiceSubStatus ===
                            INVOICE_SUB_STATUSES.DECLINED ? (
                            <img
                              style={{ width: "19px" }}
                              src={Decline}
                              className="user-icon"
                              alt="img"
                            />
                          ) : this.state.invoiceStatus ===
                              INVOICE_STATUSES.OFFER_MADE &&
                            this.state.invoiceSubStatus ===
                              INVOICE_SUB_STATUSES.IN_PROGRESS ? (
                            <img
                              style={{ width: "19px" }}
                              src={InProgress}
                              className="user-icon"
                              alt="img"
                            />
                          ) : (
                            <img
                              style={{ width: "19px" }}
                              src={Check}
                              className="user-icon"
                              alt="img"
                            />
                          )}{" "}
                          Offer made
                          {this.state.invoiceStatus ===
                          INVOICE_STATUSES.OFFER_MADE ? (
                            <p>
                              <p
                                style={{
                                  fontSize: "small",
                                  color: `${
                                    this.state.invoiceSubStatus ===
                                    INVOICE_SUB_STATUSES.DECLINED
                                      ? "#E9556E"
                                      : `${
                                          this.state.invoiceSubStatus ===
                                          INVOICE_SUB_STATUSES.IN_PROGRESS
                                            ? "#F2BD54"
                                            : "#52CCB8"
                                        }`
                                  }`,
                                  paddingLeft: "24px",
                                }}
                              >
                                {this.state.invoiceSubStatus}
                              </p>
                              <div>
                                {this.state.invoiceSubStatus ===
                                INVOICE_SUB_STATUSES.CREATED ? (
                                  this.state.invoiceOffer != null &&
                                  this.state.invoiceOffer.expired !==
                                    INVOICE_SUB_STATUSES.EXPIRED ? (
                                    <button
                                      onClick={() => this.softOfferDetails()}
                                      className="viewoffbtn"
                                    >
                                      View Offer
                                    </button>
                                  ) : this.state.invoiceOffer
                                      .regenerate_request ? (
                                    <span className="ml-5">
                                      Regeneration request sent
                                    </span>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        this.sendRequestForSoftOffer()
                                      }
                                      className="verifyR-btn"
                                    >
                                      Regenerate Offer
                                    </button>
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </p>
                          ) : (
                            ""
                          )}
                        </p>
                      ) : (
                        <p className="invoice-list list-gray fnt-clr">
                          <img
                            style={{ width: "19px" }}
                            className="user-icon"
                            src={Uncheck}
                          />
                          Offer made
                        </p>
                      )}
                      {this.state.invoiceStatus ===
                        INVOICE_STATUSES.OFFER_MADE &&
                      this.state.invoiceSubStatus ===
                        INVOICE_SUB_STATUSES.DECLINED ? (
                        ""
                      ) : (
                        <div>
                          {this.state.invoiceStatus ===
                            INVOICE_STATUSES.COMPANY_DOCUMENTATION ||
                          this.state.invoiceStatus === INVOICE_STATUSES.KYC ||
                          this.state.invoiceStatus ===
                            INVOICE_STATUSES.INVOICE_DOCUMENTATION ||
                          this.state.invoiceStatus ===
                            INVOICE_STATUSES.POOL_GOVERNANCE ||
                          this.state.invoiceStatus ===
                            INVOICE_STATUSES.FINAL_OFFER ||
                          this.state.invoiceStatus === INVOICE_STATUSES.ARPA ||
                          this.state.invoiceStatus ===
                            INVOICE_STATUSES.FINAL_INVOICE_VERIFICATION ||
                          this.state.invoiceStatus ===
                            INVOICE_STATUSES.ADVANCE_FUND_ALLOCATION ||
                          this.state.invoiceStatus ===
                            INVOICE_STATUSES.BUYER_PAYMENT ||
                          this.state.invoiceStatus ===
                            INVOICE_STATUSES.RESERVE_FUND_ALLOCATION ||
                          this.state.invoiceStatus ===
                            INVOICE_STATUSES.TRADE_CLOSED ? (
                            <p
                              className={
                                this.state.invoiceStatus ===
                                  INVOICE_STATUSES.KYC &&
                                this.state.invoiceSubStatus ===
                                  INVOICE_SUB_STATUSES.DECLINED
                                  ? "invoice-list-decline"
                                  : `${
                                      this.state.invoiceStatus ===
                                        INVOICE_STATUSES.COMPANY_DOCUMENTATION &&
                                      this.state.invoiceSubStatus ===
                                        INVOICE_SUB_STATUSES.IN_PROGRESS
                                        ? "invoice-list-progress"
                                        : "invoice-list"
                                    }`
                              }
                            >
                              {this.state.invoiceStatus ===
                                INVOICE_STATUSES.COMPANY_DOCUMENTATION &&
                              this.state.invoiceSubStatus ===
                                INVOICE_SUB_STATUSES.DECLINED ? (
                                <img
                                  style={{ width: "19px" }}
                                  src={Decline}
                                  className="user-icon"
                                />
                              ) : this.state.invoiceStatus ===
                                  INVOICE_STATUSES.COMPANY_DOCUMENTATION &&
                                this.state.invoiceSubStatus ===
                                  INVOICE_SUB_STATUSES.IN_PROGRESS ? (
                                <img
                                  style={{ width: "19px" }}
                                  src={InProgress}
                                  className="user-icon"
                                />
                              ) : (
                                <img
                                  style={{ width: "19px" }}
                                  src={Check}
                                  className="user-icon"
                                />
                              )}{" "}
                              Company Documentation
                              {this.state.invoiceStatus ===
                              INVOICE_STATUSES.COMPANY_DOCUMENTATION ? (
                                <p>
                                  <p
                                    style={{
                                      fontSize: "small",
                                      color: `${
                                        this.state.invoiceSubStatus ===
                                        INVOICE_SUB_STATUSES.DECLINED
                                          ? "#E9556E"
                                          : `${
                                              this.state.invoiceSubStatus ===
                                              INVOICE_SUB_STATUSES.IN_PROGRESS
                                                ? "#F2BD54"
                                                : "#52CCB8"
                                            }`
                                      }`,
                                      paddingLeft: "24px",
                                    }}
                                  >
                                    {this.state.invoiceSubStatus ===
                                    INVOICE_SUB_STATUSES.DECLINED
                                      ? "Sorry to inform you that your invoice documentations are declined. we can’t process this invoice further"
                                      : ""}
                                  </p>
                                </p>
                              ) : (
                                ""
                              )}
                            </p>
                          ) : (
                            <p className="invoice-list list-gray fnt-clr">
                              <img
                                style={{ width: "19px" }}
                                className="user-icon"
                                src={Uncheck}
                              />
                              Company Documentation
                            </p>
                          )}

                          {this.state.invoiceStatus ===
                            INVOICE_STATUSES.COMPANY_DOCUMENTATION &&
                          this.state.invoiceSubStatus ===
                            INVOICE_SUB_STATUSES.DECLINED ? (
                            ""
                          ) : (
                            <div>
                              {this.state.invoiceStatus ===
                                INVOICE_STATUSES.KYC ||
                              this.state.invoiceStatus ===
                                INVOICE_STATUSES.INVOICE_DOCUMENTATION ||
                              this.state.invoiceStatus ===
                                INVOICE_STATUSES.POOL_GOVERNANCE ||
                              this.state.invoiceStatus ===
                                INVOICE_STATUSES.FINAL_OFFER ||
                              this.state.invoiceStatus ===
                                INVOICE_STATUSES.ARPA ||
                              this.state.invoiceStatus ===
                                INVOICE_STATUSES.FINAL_INVOICE_VERIFICATION ||
                              this.state.invoiceStatus ===
                                INVOICE_STATUSES.ADVANCE_FUND_ALLOCATION ||
                              this.state.invoiceStatus ===
                                INVOICE_STATUSES.BUYER_PAYMENT ||
                              this.state.invoiceStatus ===
                                INVOICE_STATUSES.RESERVE_FUND_ALLOCATION ||
                              this.state.invoiceStatus ===
                                INVOICE_STATUSES.TRADE_CLOSED ? (
                                <p
                                  className={
                                    this.state.invoiceStatus ===
                                      INVOICE_STATUSES.INVOICE_DOCUMENTATION &&
                                    this.state.invoiceSubStatus ===
                                      INVOICE_SUB_STATUSES.DECLINED
                                      ? "invoice-list-decline"
                                      : `${
                                          this.state.invoiceStatus ===
                                            INVOICE_STATUSES.KYC &&
                                          this.state.invoiceSubStatus ===
                                            INVOICE_SUB_STATUSES.IN_PROGRESS
                                            ? "invoice-list-progress"
                                            : "invoice-list"
                                        }`
                                  }
                                >
                                  {this.state.invoiceStatus ===
                                    INVOICE_STATUSES.KYC &&
                                  this.state.invoiceSubStatus ===
                                    INVOICE_SUB_STATUSES.DECLINED ? (
                                    <img
                                      style={{ width: "19px" }}
                                      src={Decline}
                                      className="user-icon"
                                    />
                                  ) : this.state.invoiceStatus ===
                                      INVOICE_STATUSES.KYC &&
                                    this.state.invoiceSubStatus ===
                                      INVOICE_SUB_STATUSES.IN_PROGRESS ? (
                                    <img
                                      style={{ width: "19px" }}
                                      src={InProgress}
                                      className="user-icon"
                                    />
                                  ) : (
                                    <img
                                      style={{ width: "19px" }}
                                      src={Check}
                                      className="user-icon"
                                    />
                                  )}
                                  KYC
                                  {this.state.invoiceStatus ===
                                  INVOICE_STATUSES.KYC ? (
                                    <p>
                                      <p
                                        style={{
                                          fontSize: "small",
                                          color: `${
                                            this.state.invoiceSubStatus ===
                                            INVOICE_SUB_STATUSES.DECLINED
                                              ? "#E9556E"
                                              : `${
                                                  this.state
                                                    .invoiceSubStatus ===
                                                  INVOICE_SUB_STATUSES.IN_PROGRESS
                                                    ? "#F2BD54"
                                                    : "#52CCB8"
                                                }`
                                          }`,
                                          paddingLeft: "24px",
                                        }}
                                      >
                                        {this.state.invoiceSubStatus}
                                      </p>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              ) : (
                                <p className="invoice-list list-gray fnt-clr">
                                  <img
                                    style={{ width: "19px" }}
                                    className="user-icon"
                                    src={Uncheck}
                                  />
                                  KYC
                                </p>
                              )}

                              {this.state.invoiceStatus ===
                                INVOICE_STATUSES.KYC &&
                              this.state.invoiceSubStatus ===
                                INVOICE_SUB_STATUSES.DECLINED ? (
                                ""
                              ) : (
                                <div>
                                  {this.state.invoiceStatus ===
                                    INVOICE_STATUSES.INVOICE_DOCUMENTATION ||
                                  this.state.invoiceStatus ===
                                    INVOICE_STATUSES.POOL_GOVERNANCE ||
                                  this.state.invoiceStatus ===
                                    INVOICE_STATUSES.FINAL_OFFER ||
                                  this.state.invoiceStatus ===
                                    INVOICE_STATUSES.ARPA ||
                                  this.state.invoiceStatus ===
                                    INVOICE_STATUSES.FINAL_INVOICE_VERIFICATION ||
                                  this.state.invoiceStatus ===
                                    INVOICE_STATUSES.ADVANCE_FUND_ALLOCATION ||
                                  this.state.invoiceStatus ===
                                    INVOICE_STATUSES.BUYER_PAYMENT ||
                                  this.state.invoiceStatus ===
                                    INVOICE_STATUSES.RESERVE_FUND_ALLOCATION ||
                                  this.state.invoiceStatus ===
                                    INVOICE_STATUSES.TRADE_CLOSED ? (
                                    <p
                                      className={
                                        this.state.invoiceStatus ===
                                          INVOICE_STATUSES.POOL_GOVERNANCE &&
                                        this.state.invoiceSubStatus ===
                                          INVOICE_SUB_STATUSES.DECLINED
                                          ? "invoice-list-decline"
                                          : `${
                                              this.state.invoiceStatus ===
                                                INVOICE_STATUSES.INVOICE_DOCUMENTATION &&
                                              this.state.invoiceSubStatus ===
                                                INVOICE_SUB_STATUSES.IN_PROGRESS
                                                ? "invoice-list-progress"
                                                : "invoice-list"
                                            }`
                                      }
                                    >
                                      {this.state.invoiceStatus ===
                                        INVOICE_STATUSES.INVOICE_DOCUMENTATION &&
                                      this.state.invoiceSubStatus ===
                                        INVOICE_SUB_STATUSES.DECLINED ? (
                                        <img
                                          style={{ width: "19px" }}
                                          src={Decline}
                                          className="user-icon"
                                        />
                                      ) : this.state.invoiceStatus ===
                                          INVOICE_STATUSES.INVOICE_DOCUMENTATION &&
                                        this.state.invoiceSubStatus ===
                                          INVOICE_SUB_STATUSES.IN_PROGRESS ? (
                                        <img
                                          style={{ width: "19px" }}
                                          src={InProgress}
                                          className="user-icon"
                                        />
                                      ) : (
                                        <img
                                          style={{ width: "19px" }}
                                          src={Check}
                                          className="user-icon"
                                        />
                                      )}
                                      Invoice Documentation
                                      {this.state.invoiceStatus ===
                                      INVOICE_STATUSES.INVOICE_DOCUMENTATION ? (
                                        <p>
                                          <p
                                            style={{
                                              fontSize: "small",
                                              color: `${
                                                this.state.invoiceSubStatus ===
                                                INVOICE_SUB_STATUSES.DECLINED
                                                  ? "#E9556E"
                                                  : `${
                                                      this.state
                                                        .invoiceSubStatus ===
                                                      INVOICE_SUB_STATUSES.IN_PROGRESS
                                                        ? "#F2BD54"
                                                        : "#52CCB8"
                                                    }`
                                              }`,
                                              paddingLeft: "24px",
                                            }}
                                          >
                                            {this.state.invoiceSubStatus}
                                          </p>
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  ) : (
                                    <p className="invoice-list list-gray fnt-clr">
                                      <img
                                        style={{ width: "19px" }}
                                        className="user-icon"
                                        src={Uncheck}
                                      />
                                      Invoice Documentation
                                    </p>
                                  )}

                                  {this.state.invoiceStatus ===
                                    INVOICE_STATUSES.INVOICE_DOCUMENTATION &&
                                  this.state.invoiceSubStatus ===
                                    INVOICE_SUB_STATUSES.DECLINED ? (
                                    ""
                                  ) : (
                                    <div>
                                      {this.state.invoiceStatus ===
                                        INVOICE_STATUSES.POOL_GOVERNANCE ||
                                      this.state.invoiceStatus ===
                                        INVOICE_STATUSES.FINAL_OFFER ||
                                      this.state.invoiceStatus ===
                                        INVOICE_STATUSES.ARPA ||
                                      this.state.invoiceStatus ===
                                        INVOICE_STATUSES.FINAL_INVOICE_VERIFICATION ||
                                      this.state.invoiceStatus ===
                                        INVOICE_STATUSES.ADVANCE_FUND_ALLOCATION ||
                                      this.state.invoiceStatus ===
                                        INVOICE_STATUSES.BUYER_PAYMENT ||
                                      this.state.invoiceStatus ===
                                        INVOICE_STATUSES.RESERVE_FUND_ALLOCATION ||
                                      this.state.invoiceStatus ===
                                        INVOICE_STATUSES.TRADE_CLOSED ? (
                                        <p
                                          className={
                                            this.state.invoiceStatus ===
                                              INVOICE_STATUSES.FINAL_OFFER &&
                                            this.state.invoiceSubStatus ===
                                              INVOICE_SUB_STATUSES.DECLINED
                                              ? "invoice-list-decline"
                                              : `${
                                                  this.state.invoiceStatus ===
                                                    INVOICE_STATUSES.POOL_GOVERNANCE &&
                                                  this.state
                                                    .invoiceSubStatus ===
                                                    INVOICE_SUB_STATUSES.IN_PROGRESS
                                                    ? "invoice-list-progress"
                                                    : "invoice-list"
                                                }`
                                          }
                                        >
                                          {this.state.invoiceStatus ===
                                            INVOICE_STATUSES.POOL_GOVERNANCE &&
                                          this.state.invoiceSubStatus ===
                                            INVOICE_SUB_STATUSES.DECLINED ? (
                                            <img
                                              style={{ width: "19px" }}
                                              src={Decline}
                                              className="user-icon"
                                            />
                                          ) : this.state.invoiceStatus ===
                                              INVOICE_STATUSES.POOL_GOVERNANCE &&
                                            this.state.invoiceSubStatus ===
                                              INVOICE_SUB_STATUSES.IN_PROGRESS ? (
                                            <img
                                              style={{ width: "19px" }}
                                              src={InProgress}
                                              className="user-icon"
                                            />
                                          ) : (
                                            <img
                                              style={{ width: "19px" }}
                                              src={Check}
                                              className="user-icon"
                                            />
                                          )}
                                          Governance
                                          {this.state.invoiceStatus ===
                                          INVOICE_STATUSES.POOL_GOVERNANCE ? (
                                            <p>
                                              <p
                                                style={{
                                                  fontSize: "small",
                                                  color: `${
                                                    this.state
                                                      .invoiceSubStatus ===
                                                    INVOICE_SUB_STATUSES.DECLINED
                                                      ? "#E9556E"
                                                      : `${
                                                          this.state
                                                            .invoiceSubStatus ===
                                                          INVOICE_SUB_STATUSES.IN_PROGRESS
                                                            ? "#F2BD54"
                                                            : "#52CCB8"
                                                        }`
                                                  }`,
                                                  paddingLeft: "24px",
                                                }}
                                              >
                                                {this.state.invoiceSubStatus}
                                              </p>
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                      ) : (
                                        <p className="invoice-list list-gray fnt-clr">
                                          <img
                                            style={{ width: "19px" }}
                                            className="user-icon"
                                            src={Uncheck}
                                          />
                                          Governance
                                        </p>
                                      )}

                                      {this.state.invoiceStatus ===
                                        INVOICE_STATUSES.POOL_GOVERNANCE &&
                                      this.state.invoiceSubStatus ===
                                        INVOICE_SUB_STATUSES.DECLINED ? (
                                        ""
                                      ) : (
                                        <div>
                                          {this.state.invoiceStatus ===
                                            INVOICE_STATUSES.FINAL_OFFER ||
                                          this.state.invoiceStatus ===
                                            INVOICE_STATUSES.ARPA ||
                                          this.state.invoiceStatus ===
                                            INVOICE_STATUSES.FINAL_INVOICE_VERIFICATION ||
                                          this.state.invoiceStatus ===
                                            INVOICE_STATUSES.ADVANCE_FUND_ALLOCATION ||
                                          this.state.invoiceStatus ===
                                            INVOICE_STATUSES.BUYER_PAYMENT ||
                                          this.state.invoiceStatus ===
                                            INVOICE_STATUSES.RESERVE_FUND_ALLOCATION ||
                                          this.state.invoiceStatus ===
                                            INVOICE_STATUSES.TRADE_CLOSED ? (
                                            <p
                                              className={
                                                this.state.invoiceStatus ===
                                                  INVOICE_STATUSES.ARPA &&
                                                this.state.invoiceSubStatus ===
                                                  INVOICE_SUB_STATUSES.DECLINED
                                                  ? "invoice-list-decline"
                                                  : `${
                                                      this.state
                                                        .invoiceStatus ===
                                                        INVOICE_STATUSES.FINAL_OFFER &&
                                                      this.state
                                                        .invoiceSubStatus ===
                                                        INVOICE_SUB_STATUSES.IN_PROGRESS
                                                        ? "invoice-list-progress"
                                                        : "invoice-list"
                                                    }`
                                              }
                                            >
                                              {this.state.invoiceStatus ===
                                                INVOICE_STATUSES.FINAL_OFFER &&
                                              this.state.invoiceSubStatus ===
                                                INVOICE_SUB_STATUSES.DECLINED ? (
                                                <img
                                                  style={{ width: "19px" }}
                                                  src={Decline}
                                                  className="user-icon"
                                                />
                                              ) : this.state.invoiceStatus ===
                                                  INVOICE_STATUSES.FINAL_OFFER &&
                                                this.state.invoiceSubStatus ===
                                                  INVOICE_SUB_STATUSES.IN_PROGRESS ? (
                                                <img
                                                  style={{ width: "19px" }}
                                                  src={InProgress}
                                                  className="user-icon"
                                                />
                                              ) : (
                                                <img
                                                  style={{ width: "19px" }}
                                                  src={Check}
                                                  className="user-icon"
                                                />
                                              )}
                                              Final Offer
                                              {this.state.invoiceStatus ===
                                              INVOICE_STATUSES.FINAL_OFFER ? (
                                                <p>
                                                  <p
                                                    style={{
                                                      fontSize: "small",
                                                      color: `${
                                                        this.state
                                                          .invoiceSubStatus ===
                                                        INVOICE_SUB_STATUSES.DECLINED
                                                          ? "#E9556E"
                                                          : `${
                                                              this.state
                                                                .invoiceSubStatus ===
                                                              INVOICE_SUB_STATUSES.IN_PROGRESS
                                                                ? "#F2BD54"
                                                                : "#52CCB8"
                                                            }`
                                                      }`,
                                                      paddingLeft: "24px",
                                                    }}
                                                  >
                                                    {
                                                      this.state
                                                        .invoiceSubStatus
                                                    }
                                                  </p>
                                                  <p>
                                                    {this.state
                                                      .invoiceSubStatus ===
                                                    "Done" ? (
                                                      <div>
                                                        {this.state.finaloffer
                                                          .expired ? (
                                                          <div>
                                                            <p
                                                              style={{
                                                                fontSize:
                                                                  "small",
                                                                color:
                                                                  "#F2BD54",
                                                                paddingLeft:
                                                                  "24px",
                                                              }}
                                                            >
                                                              {" "}
                                                              Offer retracted as
                                                              not accepted in
                                                              time{" "}
                                                            </p>
                                                            <button
                                                              onClick={
                                                                this.sendRequest
                                                              }
                                                              className="verifyR-btn"
                                                            >
                                                              {this.state
                                                                .loader ===
                                                              false ? (
                                                                <span>
                                                                  {" "}
                                                                  Request to
                                                                  Re-Generate
                                                                  Offer
                                                                </span>
                                                              ) : (
                                                                <div className="spinner-border btn--icon"></div>
                                                              )}
                                                            </button>
                                                          </div>
                                                        ) : (
                                                          <div>
                                                            <p
                                                              style={{
                                                                fontSize:
                                                                  "small",
                                                                color:
                                                                  "#F2BD54",
                                                                paddingLeft:
                                                                  "24px",
                                                              }}
                                                            >
                                                              {" "}
                                                              In order to
                                                              proceed further,
                                                              you have to sign
                                                              the final trade
                                                              contract
                                                            </p>
                                                            <button
                                                              onClick={() =>
                                                                this.setState({
                                                                  showContract: true,
                                                                  finalOffer: true,
                                                                })
                                                              }
                                                              className="verify-btn"
                                                            >
                                                              View Final Offer
                                                            </button>
                                                          </div>
                                                        )}{" "}
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </p>
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </p>
                                          ) : (
                                            <p className="invoice-list list-gray fnt-clr">
                                              <img
                                                style={{ width: "19px" }}
                                                className="user-icon"
                                                src={Uncheck}
                                              />
                                              Final Offer
                                            </p>
                                          )}
                                          {this.state.invoiceStatus ===
                                            INVOICE_STATUSES.FINAL_OFFER &&
                                          this.state.invoiceSubStatus ===
                                            INVOICE_SUB_STATUSES.DECLINED ? (
                                            ""
                                          ) : (
                                            <div>
                                              {this.state.invoiceStatus ===
                                                INVOICE_STATUSES.ARPA ||
                                              this.state.invoiceStatus ===
                                                INVOICE_STATUSES.FINAL_INVOICE_VERIFICATION ||
                                              this.state.invoiceStatus ===
                                                INVOICE_STATUSES.ADVANCE_FUND_ALLOCATION ||
                                              this.state.invoiceStatus ===
                                                INVOICE_STATUSES.BUYER_PAYMENT ||
                                              this.state.invoiceStatus ===
                                                INVOICE_STATUSES.RESERVE_FUND_ALLOCATION ||
                                              this.state.invoiceStatus ===
                                                INVOICE_STATUSES.TRADE_CLOSED ? (
                                                <p
                                                  className={
                                                    this.state.invoiceStatus ===
                                                      INVOICE_STATUSES.FINAL_INVOICE_VERIFICATION &&
                                                    this.state
                                                      .invoiceSubStatus ===
                                                      INVOICE_SUB_STATUSES.DECLINED
                                                      ? "invoice-list-decline"
                                                      : `${
                                                          this.state
                                                            .invoiceStatus ===
                                                            INVOICE_STATUSES.ARPA &&
                                                          this.state
                                                            .invoiceSubStatus ===
                                                            INVOICE_SUB_STATUSES.IN_PROGRESS
                                                            ? "invoice-list-progress"
                                                            : "invoice-list"
                                                        }`
                                                  }
                                                >
                                                  {this.state.invoiceStatus ===
                                                    INVOICE_STATUSES.ARPA &&
                                                  this.state
                                                    .invoiceSubStatus ===
                                                    INVOICE_SUB_STATUSES.DECLINED ? (
                                                    <img
                                                      style={{ width: "19px" }}
                                                      src={Decline}
                                                      className="user-icon"
                                                    />
                                                  ) : this.state
                                                      .invoiceStatus ===
                                                      INVOICE_STATUSES.ARPA &&
                                                    this.state
                                                      .invoiceSubStatus ===
                                                      INVOICE_SUB_STATUSES.IN_PROGRESS ? (
                                                    <img
                                                      style={{ width: "19px" }}
                                                      src={InProgress}
                                                      className="user-icon"
                                                    />
                                                  ) : (
                                                    <img
                                                      style={{ width: "19px" }}
                                                      src={Check}
                                                      className="user-icon"
                                                    />
                                                  )}
                                                  ARPA
                                                  {this.state.invoiceStatus ===
                                                  INVOICE_STATUSES.ARPA ? (
                                                    <p>
                                                      <p
                                                        style={{
                                                          fontSize: "small",
                                                          color: `${
                                                            this.state
                                                              .invoiceSubStatus ===
                                                            INVOICE_SUB_STATUSES.DECLINED
                                                              ? "#E9556E"
                                                              : `${
                                                                  this.state
                                                                    .invoiceSubStatus ===
                                                                  INVOICE_SUB_STATUSES.IN_PROGRESS
                                                                    ? "#F2BD54"
                                                                    : "#52CCB8"
                                                                }`
                                                          }`,
                                                          paddingLeft: "24px",
                                                        }}
                                                      >
                                                        {
                                                          this.state
                                                            .invoiceSubStatus
                                                        }
                                                      </p>
                                                    </p>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {this.state.invoicedetails
                                                    .arpa_document ? (
                                                    <div className="row ml-5 mt-2">
                                                      <div className="col-sm-6">
                                                        <span>
                                                          Download Document
                                                        </span>
                                                        <div></div>
                                                        <a
                                                          href={
                                                            this.state
                                                              .invoicedetails
                                                              .arpa_document
                                                          }
                                                          style={{
                                                            color: "white",
                                                          }}
                                                        >
                                                          {" "}
                                                          <img
                                                            className="mx-3"
                                                            src={download}
                                                          />
                                                        </a>
                                                        {/* <a className="viewoffbtn" >Download Doc</a> */}
                                                      </div>
                                                      <div className="col-sm-6">
                                                        <span>
                                                          Uploaded Document
                                                        </span>
                                                        <div></div>
                                                        <span
                                                          style={{
                                                            paddingLeft:
                                                              "10vw !important;",
                                                          }}
                                                        >
                                                          {this.state
                                                            .invoicedetails
                                                            .borrower_arpa_document ==
                                                          null ? (
                                                            <label className="uploadb header-input mx-3">
                                                              {this.state
                                                                .loader ? (
                                                                <div
                                                                  style={{
                                                                    width:
                                                                      "1.5rem",
                                                                    height:
                                                                      "1.5rem",
                                                                  }}
                                                                  className="spinner-border btn--icon"
                                                                ></div>
                                                              ) : (
                                                                "Upload"
                                                              )}
                                                              <input
                                                                type="file"
                                                                style={{
                                                                  display:
                                                                    "btn btn-outline-primary col_erone",
                                                                }}
                                                                name="company_coi_url"
                                                                onChange={(e) =>
                                                                  this.fileChange(
                                                                    e,
                                                                    this.state
                                                                      .invoicedetails
                                                                  )
                                                                }
                                                              />
                                                            </label>
                                                          ) : (
                                                            <span>
                                                              <a
                                                                href={
                                                                  this.state
                                                                    .invoicedetails
                                                                    .borrower_arpa_document
                                                                }
                                                                style={{
                                                                  color:
                                                                    "white",
                                                                }}
                                                              >
                                                                {" "}
                                                                <img
                                                                  className="mx-3"
                                                                  src={download}
                                                                />
                                                              </a>
                                                              {/* <a onClick={() => this.deleteUserArpa(this.state.invoicedetails)} style={{ color: "white" }}> <img className='mx-3' src={trash} /></a> */}
                                                            </span>
                                                          )}
                                                        </span>
                                                        {this.state
                                                          .fileError && (
                                                          <div className="text-danger">
                                                            {
                                                              this.state
                                                                .fileError
                                                            }
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </p>
                                              ) : (
                                                <p className="invoice-list list-gray fnt-clr">
                                                  <img
                                                    style={{ width: "19px" }}
                                                    className="user-icon"
                                                    src={Uncheck}
                                                  />
                                                  ARPA
                                                </p>
                                              )}
                                              {this.state.invoiceStatus ===
                                                INVOICE_STATUSES.ARPA &&
                                              this.state.invoiceSubStatus ===
                                                INVOICE_SUB_STATUSES.DECLINED ? (
                                                ""
                                              ) : (
                                                <div>
                                                  {this.state.invoiceStatus ===
                                                    INVOICE_STATUSES.FINAL_INVOICE_VERIFICATION ||
                                                  this.state.invoiceStatus ===
                                                    INVOICE_STATUSES.ADVANCE_FUND_ALLOCATION ||
                                                  this.state.invoiceStatus ===
                                                    INVOICE_STATUSES.BUYER_PAYMENT ||
                                                  this.state.invoiceStatus ===
                                                    INVOICE_STATUSES.RESERVE_FUND_ALLOCATION ||
                                                  this.state.invoiceStatus ===
                                                    INVOICE_STATUSES.TRADE_CLOSED ? (
                                                    <p
                                                      className={
                                                        this.state
                                                          .invoiceStatus ===
                                                          INVOICE_STATUSES.ADVANCE_FUND_ALLOCATION &&
                                                        this.state
                                                          .invoiceSubStatus ===
                                                          INVOICE_SUB_STATUSES.DECLINED
                                                          ? "invoice-list-decline"
                                                          : `${
                                                              this.state
                                                                .invoiceStatus ===
                                                                INVOICE_STATUSES.FINAL_INVOICE_VERIFICATION &&
                                                              this.state
                                                                .invoiceSubStatus ===
                                                                INVOICE_SUB_STATUSES.IN_PROGRESS
                                                                ? "invoice-list-progress"
                                                                : "invoice-list"
                                                            }`
                                                      }
                                                    >
                                                      {this.state
                                                        .invoiceStatus ===
                                                        INVOICE_STATUSES.FINAL_INVOICE_VERIFICATION &&
                                                      this.state
                                                        .invoiceSubStatus ===
                                                        INVOICE_SUB_STATUSES.DECLINED ? (
                                                        <img
                                                          style={{
                                                            width: "19px",
                                                          }}
                                                          src={Decline}
                                                          className="user-icon"
                                                        />
                                                      ) : this.state
                                                          .invoiceStatus ===
                                                          INVOICE_STATUSES.FINAL_INVOICE_VERIFICATION &&
                                                        this.state
                                                          .invoiceSubStatus ===
                                                          INVOICE_SUB_STATUSES.IN_PROGRESS ? (
                                                        <img
                                                          style={{
                                                            width: "19px",
                                                          }}
                                                          src={InProgress}
                                                          className="user-icon"
                                                        />
                                                      ) : (
                                                        <img
                                                          style={{
                                                            width: "19px",
                                                          }}
                                                          src={Check}
                                                          className="user-icon"
                                                        />
                                                      )}{" "}
                                                      Final Invoice Verification
                                                      {this.state
                                                        .invoiceStatus ===
                                                      INVOICE_STATUSES.FINAL_INVOICE_VERIFICATION ? (
                                                        <p>
                                                          <p
                                                            style={{
                                                              fontSize: "small",
                                                              color: `${
                                                                this.state
                                                                  .invoiceSubStatus ===
                                                                INVOICE_SUB_STATUSES.DECLINED
                                                                  ? "#E9556E"
                                                                  : `${
                                                                      this.state
                                                                        .invoiceSubStatus ===
                                                                      INVOICE_SUB_STATUSES.IN_PROGRESS
                                                                        ? "#F2BD54"
                                                                        : "#52CCB8"
                                                                    }`
                                                              }`,
                                                              paddingLeft:
                                                                "24px",
                                                            }}
                                                          >
                                                            {
                                                              this.state
                                                                .invoiceSubStatus
                                                            }
                                                          </p>
                                                        </p>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </p>
                                                  ) : (
                                                    <p className="invoice-list list-gray fnt-clr">
                                                      <img
                                                        style={{
                                                          width: "19px",
                                                        }}
                                                        className="user-icon"
                                                        src={Uncheck}
                                                      />
                                                      Final Invoice Verification
                                                    </p>
                                                  )}
                                                  {this.state.invoiceStatus ===
                                                    INVOICE_STATUSES.FINAL_INVOICE_VERIFICATION &&
                                                  this.state
                                                    .invoiceSubStatus ===
                                                    INVOICE_SUB_STATUSES.DECLINED ? (
                                                    ""
                                                  ) : (
                                                    <div>
                                                      {this.state
                                                        .invoiceStatus ===
                                                        INVOICE_STATUSES.ADVANCE_FUND_ALLOCATION ||
                                                      this.state
                                                        .invoiceStatus ===
                                                        INVOICE_STATUSES.BUYER_PAYMENT ||
                                                      this.state
                                                        .invoiceStatus ===
                                                        INVOICE_STATUSES.RESERVE_FUND_ALLOCATION ||
                                                      this.state
                                                        .invoiceStatus ===
                                                        INVOICE_STATUSES.TRADE_CLOSED ? (
                                                        <p
                                                          className={
                                                            this.state
                                                              .invoiceStatus ===
                                                              INVOICE_STATUSES.BUYER_PAYMENT &&
                                                            this.state
                                                              .invoiceSubStatus ===
                                                              INVOICE_SUB_STATUSES.DECLINED
                                                              ? "invoice-list-decline"
                                                              : `${
                                                                  this.state
                                                                    .invoiceStatus ===
                                                                    INVOICE_STATUSES.ADVANCE_FUND_ALLOCATION &&
                                                                  this.state
                                                                    .invoiceSubStatus ===
                                                                    INVOICE_SUB_STATUSES.IN_PROGRESS
                                                                    ? "invoice-list-progress"
                                                                    : "invoice-list"
                                                                }`
                                                          }
                                                        >
                                                          {this.state
                                                            .invoiceStatus ===
                                                            INVOICE_STATUSES.ADVANCE_FUND_ALLOCATION &&
                                                          this.state
                                                            .invoiceSubStatus ===
                                                            INVOICE_SUB_STATUSES.DECLINED ? (
                                                            <img
                                                              style={{
                                                                width: "19px",
                                                              }}
                                                              src={Decline}
                                                              className="user-icon"
                                                            />
                                                          ) : this.state
                                                              .invoiceStatus ===
                                                              INVOICE_STATUSES.ADVANCE_FUND_ALLOCATION &&
                                                            this.state
                                                              .invoiceSubStatus ===
                                                              INVOICE_SUB_STATUSES.IN_PROGRESS ? (
                                                            <img
                                                              style={{
                                                                width: "19px",
                                                              }}
                                                              src={InProgress}
                                                              className="user-icon"
                                                            />
                                                          ) : (
                                                            <img
                                                              style={{
                                                                width: "19px",
                                                              }}
                                                              src={Check}
                                                              className="user-icon"
                                                            />
                                                          )}
                                                          Advance Fund
                                                          Allocation
                                                          {this.state
                                                            .invoiceStatus ===
                                                          INVOICE_STATUSES.ADVANCE_FUND_ALLOCATION ? (
                                                            <p>
                                                              <p
                                                                style={{
                                                                  fontSize:
                                                                    "small",
                                                                  color: `${
                                                                    this.state
                                                                      .invoiceSubStatus ===
                                                                    INVOICE_SUB_STATUSES.DECLINED
                                                                      ? "#E9556E"
                                                                      : `${
                                                                          this
                                                                            .state
                                                                            .invoiceSubStatus ===
                                                                          INVOICE_SUB_STATUSES.IN_PROGRESS
                                                                            ? "#F2BD54"
                                                                            : "#52CCB8"
                                                                        }`
                                                                  }`,
                                                                  paddingLeft:
                                                                    "24px",
                                                                }}
                                                              >
                                                                {
                                                                  this.state
                                                                    .invoiceSubStatus
                                                                }
                                                              </p>
                                                            </p>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </p>
                                                      ) : (
                                                        <p className="invoice-list list-gray fnt-clr">
                                                          <img
                                                            style={{
                                                              width: "19px",
                                                            }}
                                                            className="user-icon"
                                                            src={Uncheck}
                                                          />
                                                          Advance Fund
                                                          Allocation
                                                        </p>
                                                      )}

                                                      {this.state
                                                        .invoiceStatus ===
                                                        INVOICE_STATUSES.BUYER_PAYMENT ||
                                                      this.state
                                                        .invoiceStatus ===
                                                        INVOICE_STATUSES.RESERVE_FUND_ALLOCATION ||
                                                      this.state
                                                        .invoiceStatus ===
                                                        INVOICE_STATUSES.TRADE_CLOSED ? (
                                                        <p className="invoice-list">
                                                          <img
                                                            style={{
                                                              width: "19px",
                                                            }}
                                                            src={Check}
                                                            className="user-icon"
                                                          />
                                                          Collection
                                                          {this.state
                                                            .invoiceStatus ===
                                                          INVOICE_STATUSES.BUYER_PAYMENT ? (
                                                            <p>
                                                              <p
                                                                style={{
                                                                  fontSize:
                                                                    "small",
                                                                  color: `${
                                                                    this.state
                                                                      .invoiceSubStatus ===
                                                                    INVOICE_SUB_STATUSES.DECLINED
                                                                      ? "#E9556E"
                                                                      : `${
                                                                          this
                                                                            .state
                                                                            .invoiceSubStatus ===
                                                                          INVOICE_SUB_STATUSES.IN_PROGRESS
                                                                            ? "#F2BD54"
                                                                            : "#52CCB8"
                                                                        }`
                                                                  }`,
                                                                  paddingLeft:
                                                                    "24px",
                                                                }}
                                                              >
                                                                {
                                                                  this.state
                                                                    .invoiceSubStatus
                                                                }
                                                              </p>
                                                            </p>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </p>
                                                      ) : (
                                                        <p className="invoice-list list-gray fnt-clr">
                                                          <img
                                                            style={{
                                                              width: "19px",
                                                            }}
                                                            className="user-icon"
                                                            src={Uncheck}
                                                          />
                                                          Collection
                                                        </p>
                                                      )}

                                                      {this.state
                                                        .invoiceStatus ===
                                                        INVOICE_STATUSES.RESERVE_FUND_ALLOCATION ||
                                                      this.state
                                                        .invoiceStatus ===
                                                        INVOICE_STATUSES.TRADE_CLOSED ? (
                                                        <p className="invoice-list">
                                                          <img
                                                            style={{
                                                              width: "19px",
                                                            }}
                                                            src={Check}
                                                            className="user-icon"
                                                          />
                                                          Reserve Fund
                                                          Allocation
                                                          {this.state
                                                            .invoiceStatus ===
                                                          INVOICE_STATUSES.RESERVE_FUND_ALLOCATION ? (
                                                            <p>
                                                              <p
                                                                style={{
                                                                  fontSize:
                                                                    "small",
                                                                  color: `${
                                                                    this.state
                                                                      .invoiceSubStatus ===
                                                                    INVOICE_SUB_STATUSES.DECLINED
                                                                      ? "#E9556E"
                                                                      : `${
                                                                          this
                                                                            .state
                                                                            .invoiceSubStatus ===
                                                                          INVOICE_SUB_STATUSES.IN_PROGRESS
                                                                            ? "#F2BD54"
                                                                            : "#52CCB8"
                                                                        }`
                                                                  }`,
                                                                  paddingLeft:
                                                                    "24px",
                                                                }}
                                                              >
                                                                {
                                                                  this.state
                                                                    .invoiceSubStatus
                                                                }
                                                              </p>
                                                            </p>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </p>
                                                      ) : (
                                                        <p className="invoice-list list-gray fnt-clr">
                                                          <img
                                                            style={{
                                                              width: "19px",
                                                            }}
                                                            className="user-icon"
                                                            src={Uncheck}
                                                          />
                                                          Reserve Fund
                                                          Allocation
                                                        </p>
                                                      )}

                                                      {this.state
                                                        .invoiceStatus ===
                                                      INVOICE_STATUSES.TRADE_CLOSED ? (
                                                        <p className="invoice-list">
                                                          <img
                                                            style={{
                                                              width: "19px",
                                                            }}
                                                            src={Check}
                                                            className="user-icon"
                                                          />
                                                          Trade close
                                                          {this.state
                                                            .invoiceStatus ===
                                                          INVOICE_STATUSES.TRADE_CLOSED ? (
                                                            <p>
                                                              <p
                                                                style={{
                                                                  fontSize:
                                                                    "small",
                                                                  color: `${
                                                                    this.state
                                                                      .invoiceSubStatus ===
                                                                    INVOICE_SUB_STATUSES.DECLINED
                                                                      ? "#E9556E"
                                                                      : `${
                                                                          this
                                                                            .state
                                                                            .invoiceSubStatus ===
                                                                          INVOICE_SUB_STATUSES.IN_PROGRESS
                                                                            ? "#F2BD54"
                                                                            : "#52CCB8"
                                                                        }`
                                                                  }`,
                                                                  paddingLeft:
                                                                    "24px",
                                                                }}
                                                              >
                                                                {
                                                                  this.state
                                                                    .invoiceSubStatus
                                                                }
                                                              </p>
                                                            </p>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </p>
                                                      ) : (
                                                        <p className="invoice-list list-gray fnt-clr">
                                                          <img
                                                            style={{
                                                              width: "19px",
                                                            }}
                                                            className="user-icon"
                                                            src={Uncheck}
                                                          />
                                                          Trade close
                                                        </p>
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {/* {this.state.invoiceStatus === "Sign Contract" || this.state.invoiceStatus === "Final verification" || this.state.invoiceStatus === "Fund Release"
                    || this.state.invoiceStatus === "Payment Recieved" || this.state.invoiceStatus === "Reserve Funds" || this.state.invoiceStatus === "Polytrade Rewards" || this.state.invoiceStatus === "Transaction done" ?
                    <div className="invoice-list" ><p><img style={{ width: "19px" }} src={Check} className="user-icon" />Sign Contract </p>
                      {this.state.invoiceStatus === "Sign Contract" ? <p><p style={{ fontSize: 'small', color: '#F2BD54', paddingLeft: "24px" }}>In order to proceed further, you have to sign the final trade contract</p> <button onClick={() => this.setState({ showContract: true })} className='verify-btn' >Verify & Sign Contract</button></p> : ""}</div> : <p className="invoice-list list-gray fnt-clr"><img style={{ width: "19px" }} className="user-icon" src={Uncheck} />Sign Contract</p>} */}
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-12">
              {/* here */}

              {this.state.showSoftOffer && (
                <div className="bgc-dark1 d-flex justify-content-between my-2">
                  <div className="text-white p-2">Soft Offer</div>
                  <div>
                    <img
                      style={{ width: "35px" }}
                      alt="img"
                      onClick={() =>
                        this.setState({
                          modalShow: true,
                          softOffer: false,
                        })
                      }
                      src={Eye}
                      className="user-icon"
                    />
                  </div>
                </div>
              )}
              {this.state.invoicedetails?.final_offer?.amount && (
                <div className="bgc-dark1 d-flex justify-content-between my-3">
                  <div className="text-white p-2">Final Offer</div>
                  <div>
                    <img
                      style={{ width: "35px" }}
                      alt="img"
                      onClick={() =>
                        this.setState({
                          showContract: true,
                          finalOffer: false,
                        })
                      }
                      src={Eye}
                      className="user-icon"
                    />
                  </div>
                </div>
              )}

              {(this.state.invoiceStatus ===
                INVOICE_STATUSES.ADVANCE_FUND_ALLOCATION &&
                this.state.subStatus === "Done") ||
              this.state.invoiceStatus === INVOICE_STATUSES.BUYER_PAYMENT ||
              this.state.invoiceStatus ===
                INVOICE_STATUSES.RESERVE_FUND_ALLOCATION ||
              this.state.invoiceStatus === INVOICE_STATUSES.TRADE_CLOSED ? (
                <div
                  style={{
                    backgroundColor: "#333333",
                    border: "none",
                    marginBottom: "14px",
                    borderRadius: "4px",
                  }}
                >
                  <div
                    className="d-flex justify-content-between p-0 m-0"
                    style={{ borderBottom: "1px solid gray" }}
                  >
                    <div>
                      {" "}
                      <h6 className="text-white p-2">Advanced Fund</h6>
                    </div>
                    <div className=" text-right">
                      <img
                        style={{ width: "35px" }}
                        data-toggle="modal"
                        data-target="#advanceFundAllocation"
                        src={Eye}
                        className="user-icon"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between p-2">
                    <div className="text_tra1">
                      Amount
                      <div className="text-white">
                        $
                        {Number(
                          this.state.invoicedetails.final_offer
                            ? this.state.invoicedetails.final_offer
                                .amount_to_be_funded
                            : ""
                        )
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </div>
                    </div>
                    <div className="text_tra1">
                      Percentage
                      <div className="text-white">
                        {this.state.invoicedetails.final_offer
                          ? this.state.invoicedetails.final_offer
                              .advance_percentage
                          : ""}
                        %
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {(this.state.invoiceStatus ===
                INVOICE_STATUSES.RESERVE_FUND_ALLOCATION &&
                this.state.subStatus === "Done") ||
              this.state.invoiceStatus === INVOICE_STATUSES.TRADE_CLOSED ? (
                <div
                  style={{
                    backgroundColor: "#333333",
                    border: "none",
                    marginBottom: "14px",
                    borderRadius: "4px",
                  }}
                >
                  <div
                    className="d-flex justify-content-between p-0 m-0"
                    style={{ borderBottom: "1px solid gray" }}
                  >
                    <div>
                      {" "}
                      <h6 className="text-white p-2">Reserve Fund</h6>
                    </div>
                    <div>
                      <img
                        style={{ width: "35px" }}
                        data-toggle="modal"
                        data-target="#ReverseFundAllocation"
                        src={Eye}
                        className="user-icon"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between p-2">
                    <div className="text_tra1">
                      Amount
                      <div className="text-white">
                        $
                        {Number(
                          this.state.reserve_fund_allocation?.client_payable ||
                            0
                        )
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </div>
                    </div>
                    <div className="text_tra1">
                      Percentage
                      <div className="text-white">
                        {this.state.invoicedetails.final_offer
                          ? this.state.invoicedetails.final_offer
                              .reserve_percentage
                          : ""}
                        %
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="text_tra1">Trasanction ID</div>
                    <div className="txt_b">
                      {this.state.invoicedetails.reverse_fund_allocation
                        ? this.state.invoicedetails.reverse_fund_allocation
                            .transaction_id
                        : ""}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.invoiceStatus === INVOICE_STATUSES.TRADE_CLOSED ? (
                <div
                  style={{
                    backgroundColor: "#333333",
                    border: "none",
                    marginBottom: "14px",
                    borderRadius: "4px",
                    padding: "12px",
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5 className="text_tra1">Rewards</h5>
                      <div className="txt_b">Coming Soon.... </div>
                      <div className="text-white d-none">8250 TRADE </div>
                      <div className="text_tra1 d-none">Values in $4,125</div>
                    </div>
                    <div>
                      <label className="uploadb d-none">Claim</label>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <Modal show={this.state.showPdf} onHide={this.handleClose}>
            <div style={{ backgroundColor: "#333333", color: "white" }}>
              {this.state.pdfUrl !== null ? (
                this.state.pdfUrl.split(".").pop() === "pdf" ||
                this.state.pdfUrl.split(".").pop() === "PDF" ? (
                  <iframe
                    src={`https://docs.google.com/gview?url=${this.state.pdfUrl}&embedded=true`}
                    style={{ width: "50rem", height: "50rem" }}
                    frameBorder="0"
                  ></iframe>
                ) : (
                  <>
                    <img
                      src={this.state.pdfUrl}
                      style={{ width: "30rem", height: "30rem" }}
                    />
                  </>
                )
              ) : (
                <div></div>
              )}
            </div>
          </Modal>

          <div
            className="modal modal-lg right fade"
            id="advanceFundAllocation"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel2"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title font-style-14 " id="myModalLabel2">
                    Advanced Fund Disbursed Details
                  </h4>
                  <button
                    type="button font-style-14 "
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body" style={{ background: "#333333" }}>
                  <div className="row">
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">Buyer Name</label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {this.state.invoicedetails.full_name}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Invoice Grade
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {this.state.invoicedetails.final_offer
                            ? this.state.invoicedetails.final_offer.grade
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Invoice Ref. No
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {this.state.invoicedetails
                            ? this.state.invoicedetails.invoice_id
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Invoice Number
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {" "}
                          {this.state.invoicedetails
                            ? this.state.invoicedetails.supplier_invoice_number
                            : ""}{" "}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Invoice Date
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {this.state.invoicedetails
                            ? moment(
                                this.state.invoicedetails.invoice_date
                              ).format("DD MMM  YYYY")
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Invoice Limit
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          $
                          {Number(
                            this.state.invoicedetails
                              ? this.state.invoicedetails.invoice_limit
                              : ""
                          )
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Advanced Date{" "}
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {this.state.invoicedetails.final_offer
                            ? moment(
                                this.state.invoicedetails.final_offer
                                  .advance_date
                              ).format("DD MMM  YYYY")
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Advanced Amount
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          $
                          {Number(
                            this.state.invoicedetails.final_offer
                              ? this.state.invoicedetails.final_offer
                                  .amount_to_be_funded
                              : ""
                          )
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Reserve Held
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          $
                          {Number(
                            this.state.invoicedetails.final_offer
                              ? parseFloat(
                                  this.state.invoicedetails.invoice_amount
                                ) -
                                  parseFloat(
                                    this.state.invoicedetails.final_offer
                                      .amount_to_be_funded
                                  )
                              : ""
                          )
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          {/* {moment(this.state.invoicedetails.invoice_due_date).format('DD MMM  YYYY')} */}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">Due Date</label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          <label className="m-0 p-0 font-style-14">
                            {this.state.invoicedetails
                              ? moment(
                                  this.state.invoicedetails.invoice_due_date
                                ).format("DD MMM  YYYY")
                              : ""}
                          </label>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Grace period
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {this.state.invoicedetails.final_offer
                            ? this.state.invoicedetails.final_offer.grace_period
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">Amount Due</label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          $
                          {Number(
                            this.state.invoicedetails.final_offer
                              ? parseFloat(
                                  this.state.invoicedetails.invoice_amount
                                ) -
                                  parseFloat(
                                    this.state.invoicedetails.final_offer
                                      .amount_to_be_funded
                                  )
                              : ""
                          )
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">Bank Name</label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {this.state.bankDetails?.bank_name || ""}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Account Number
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {this.state.bankDetails?.account_number || ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">IFSC Code</label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {this.state.bankDetails?.ifsc_code || ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">Documents</label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {this.state.invoicedetails.final_offer?.document ? (
                            <div>
                              <a
                                href
                                onClick={() =>
                                  this.onPdfShow(
                                    this.state.invoicedetails.final_offer
                                      ?.document
                                  )
                                }
                              >
                                <img alt="preivew" src={preview} />
                              </a>
                              <a
                                href={
                                  this.state.invoicedetails.final_offer
                                    ?.document
                                }
                                style={{ color: "white" }}
                              >
                                {" "}
                                <img alt="download" src={download} />
                              </a>
                            </div>
                          ) : (
                            <span className="text-white">Not Available</span>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal modal-lg right fade"
            id="ReverseFundAllocation"
            tabindex="-1"
            role="dialog"
            aria-labelledby="ReverseFundAllocation"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title font-style-14 " id="myModalLabel2">
                    Reserve Offers Details
                  </h4>
                  <button
                    type="button font-style-14 "
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body" style={{ background: "#333333" }}>
                  <div className="row">
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">Buyer Name</label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {this.state.invoicedetails.full_name}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Invoice Ref. No
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {this.state.invoicedetails.invoice_id}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Invoice Number
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {this.state.invoicedetails.supplier_invoice_number}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Invoice Limit
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          $
                          {Number(this.state.invoicedetails.invoice_limit)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Advanced Amount
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          $
                          {Number(
                            this.state.invoicedetails.final_offer
                              ? this.state.invoicedetails.final_offer
                                  .amount_to_be_funded
                              : ""
                          )
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Reserve Held
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          $
                          {Number(
                            this.state.invoicedetails.final_offer
                              ? parseFloat(
                                  this.state.invoicedetails.invoice_amount
                                ) -
                                  parseFloat(
                                    this.state.invoicedetails.final_offer
                                      .amount_to_be_funded
                                  )
                              : ""
                          )
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Amount Received{" "}
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          $
                          {Number(
                            this.state.reserve_fund_allocation
                              .total_amount_received
                          )
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,") || 0}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Invoice Due Date
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {moment(
                            this.state.invoicedetails?.invoice_due_date
                          ).format("DD MMM  YYYY")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Payment Received Date
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {" "}
                          {moment(
                            this.state.invoicedetails?.collection_details
                              ?.payment_date
                          ).format("DD MMM  YYYY")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Grace period
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {this.state.invoicedetails.final_offer
                            ? this.state.invoicedetails.final_offer.grace_period
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Number of Late Days
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {this.state.reserve_fund_allocation?.late_day || 0}{" "}
                          day
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">late Fee</label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {Number(
                            this.state.invoicedetails?.final_offer?.late_fee
                          )
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,") || 0}
                          %
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Late Fee Value
                      </label>
                      <div>
                        <label className="font-style-14 ">
                          $
                          {Number(this.state.reserve_fund_allocation?.late_fee_value).toFixed(2) ||
                            0}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">Total Fee</label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          $
                          {Number(
                            this.state.reserve_fund_allocation?.fee
                          ).toFixed(2) || 0}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Net Amount Payable to Client
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          $
                          {this.state.reserve_fund_allocation.client_payable ||
                            0}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">
                        Date Closed
                      </label>
                      <div>
                        <label className="m-0 p-0 font-style-14">
                          {this.state.invoicedetails.reverse_fund_allocation
                            ? moment(
                                this.state.invoicedetails
                                  .reverse_fund_allocation.date_closed
                              ).format("DD MMM  YYYY")
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mt-4">
                      <label className="font-style-8 m-0 p-0">Documents</label>
                      <div>
                        {this.state.invoicedetails.reverse_fund_allocation
                          ?.document ? (
                          <p>
                            <a
                              href
                              onClick={() =>
                                this.onPdfShow(
                                  this.state.invoicedetails
                                    .reverse_fund_allocation?.document
                                )
                              }
                            >
                              <img alt="preivew" src={preview} />
                            </a>
                            <a
                              href={
                                this.state.invoicedetails
                                  .reverse_fund_allocation.document
                              }
                              style={{ color: "white" }}
                            >
                              {" "}
                              <img alt="download" src={download} />
                            </a>
                          </p>
                        ) : (
                          <span className="text-white">Not Available</span>
                        )}
                        {/* <label className="m-0 p-0 font-style-14"> */}
                        {/* {this.state.invoicedetails.advance_disburse?this.state.invoicedetails.reverse_fund_allocation.document:""} */}
                        {/* </label> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  invoiceStatus: state.invoiceStatus.status,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  getUserAccountsById: (data) => dispatch(getUserAccountsById(data)),
  getOfferById: (data) => dispatch(getOfferById(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  getFinalOfferById: (data) => dispatch(getFinalOfferById(data)),
  getInvoiceData: (data) => dispatch(getInvoiceData(data)),
  postOffer: (data) => dispatch(postOffer(data)),
  postAlert: (data) => dispatch(postAlert(data)),
  updateOffer: (data) => dispatch(updateOffer(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvoiceDetail));

import { get, isEmpty, cloneDeep } from "lodash";
import axios from "axios";
import qs from "qs";
import * as USER_CONSTANTS from "../constants/actionConstant";
import * as COMPANY_CONSTANTS from "../constants/companyConstants";
import ajax from "../../utils/ajax";

export const requestGetUsers = () => ({
  type: USER_CONSTANTS.HOME_GET_USER_CONSTANTS.REQUEST,
});

export const recievedGetUsersResponse = (data) => ({
  type: USER_CONSTANTS.HOME_GET_USER_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetUsersError = (err) => ({
  type: USER_CONSTANTS.HOME_GET_USER_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getUsers = () => {
  let url = USER_CONSTANTS.HOME_GET_USER_CONSTANTS.URL;
  return (dispatch) => {
    dispatch(requestGetUsers());

    ajax("GET", url, {})
      .then((res) => dispatch(recievedGetUsersResponse(res.data)))
      .catch((err) => dispatch(recievedGetUsersError(err)));
  };
};

export const requestAddUser = () => ({
  type: USER_CONSTANTS.HOME_POST_USER_CONSTANTS.REQUEST,
});

export const recievedAddUserResponse = (data) => ({
  type: USER_CONSTANTS.HOME_POST_USER_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedAddUserError = (err) => ({
  type: USER_CONSTANTS.HOME_POST_USER_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const postUser = (data) => {
  let url = USER_CONSTANTS.HOME_POST_USER_CONSTANTS.URL;
  return (dispatch) => {
    dispatch(requestAddUser());

    ajax("POST", url, {}, {}, data)
      .then((res) => dispatch(recievedAddUserResponse(res.data)))
      .catch((err) => dispatch(recievedAddUserError(err)));
  };
};

export const requestUpdateUser = () => ({
  type: USER_CONSTANTS.HOME_UPDATE_USER_CONSTANTS.REQUEST,
});

export const recievedUpdateUserResponse = (data) => ({
  type: USER_CONSTANTS.HOME_UPDATE_USER_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedUpdateUserError = (err) => ({
  type: USER_CONSTANTS.HOME_UPDATE_USER_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const updateUser = (data) => async (dispatch) => {
  dispatch(requestUpdateUser());
  let url = USER_CONSTANTS.HOME_UPDATE_USER_CONSTANTS.URL + data._id;
  const res = await ajax("PUT", url, {}, {}, data);
  if (res) {
    dispatch(recievedUpdateUserResponse(res.data));
    return Promise.resolve(res.data);
  }
};

export const requestEmailGetUsers = () => ({
  type: USER_CONSTANTS.HOME_GET_USER_EMAIL_CONSTANTS.REQUEST,
});

export const recievedEmailGetUsersResponse = (data) => ({
  type: USER_CONSTANTS.HOME_GET_USER_EMAIL_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedEmailGetUsersError = (err) => ({
  type: USER_CONSTANTS.HOME_GET_USER_EMAIL_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getEmailUser = (data) => async (dispatch) => {
  dispatch(requestEmailGetUsers());
  try {
    let url = USER_CONSTANTS.HOME_GET_USER_EMAIL_CONSTANTS.URL + data;
    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedEmailGetUsersResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedEmailGetUsersError(err));
    return Promise.reject(err);
  }
};
export const requestDeleteUser = () => ({
  type: USER_CONSTANTS.HOME_DELETE_USER_CONSTANTS.REQUEST,
});

export const recievedDeleteUserResponse = (data) => ({
  type: USER_CONSTANTS.HOME_DELETE_USER_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedDeleteUserError = (err) => ({
  type: USER_CONSTANTS.HOME_DELETE_USER_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const DeleteUser = (id) => {
  let url = USER_CONSTANTS.HOME_DELETE_USER_CONSTANTS.URL + id;
  return (dispatch) => {
    dispatch(requestDeleteUser());

    ajax("DELETE", url, {})
      .then((res) => dispatch(recievedDeleteUserResponse(res.data)))
      .catch((err) => dispatch(recievedDeleteUserError(err)));
  };
};

export const requestBuyersforCompanyId = () => ({
  type: USER_CONSTANTS.HOME_GET_BUYERS_FOR_COMPANY_ID.REQUEST,
});

export const recievedGetBuyersforCompanyIdResponse = (data) => ({
  type: USER_CONSTANTS.HOME_GET_BUYERS_FOR_COMPANY_ID.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedBuyersforCompanyIdError = (err) => ({
  type: USER_CONSTANTS.HOME_GET_BUYERS_FOR_COMPANY_ID.RECEIVED_ERROR,
  errorCode: err,
});

export const getBuyersforCompany = (id) => {
  let url = USER_CONSTANTS.HOME_GET_BUYERS_FOR_COMPANY_ID.URL + id;
  return (dispatch) => {
    dispatch(requestBuyersforCompanyId());

    ajax("GET", url, {})
      .then((res) => dispatch(recievedGetBuyersforCompanyIdResponse(res.data)))
      .catch((err) => dispatch(recievedBuyersforCompanyIdError(err)));
  };
};

export const requestGetLenderById = () => ({
  type: USER_CONSTANTS.GET_LENDER_BY_ID_CONSTANTS.REQUEST,
});

export const recievedGetLenderByIdResponse = (data) => ({
  type: USER_CONSTANTS.GET_LENDER_BY_ID_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetLenderByIdError = (err) => ({
  type: USER_CONSTANTS.GET_LENDER_BY_ID_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getLenderById = (id) => async (dispatch) => {
  dispatch(requestGetLenderById());
  try {
    let url = USER_CONSTANTS.GET_LENDER_BY_ID_CONSTANTS.URL + id;
    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetLenderByIdResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetLenderByIdError(err));
    return Promise.reject(err);
  }
};

export const requestGetUserDetails = () => ({
  type: USER_CONSTANTS.GET_USER_DETAILS_CONSTANTS.REQUEST,
});

export const recievedGetUserDetailsResponse = (data) => ({
  type: USER_CONSTANTS.GET_USER_DETAILS_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetUserDetailsError = (err) => ({
  type: USER_CONSTANTS.GET_USER_DETAILS_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getUserDetails =
  (index = 1) =>
  async (dispatch) => {
    dispatch(requestGetUserDetails());
    try {
      let url =
        USER_CONSTANTS.GET_USER_DETAILS_CONSTANTS.URL + `?page=${index}`;
      const res = await ajax("GET", url, {});
      if (res) {
        dispatch(recievedGetUserDetailsResponse(res.data));
        return Promise.resolve(res.data);
      }
    } catch (err) {
      dispatch(recievedGetUserDetailsError(err));
      return Promise.reject(err);
    }
  };

// GET_LENDERS_CONSTANTS

export const requestGetLenders = () => ({
  type: USER_CONSTANTS.GET_LENDERS_CONSTANTS.REQUEST,
});

export const recievedGetLendersResponse = (data) => ({
  type: USER_CONSTANTS.GET_LENDERS_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetLendersError = (err) => ({
  type: USER_CONSTANTS.GET_LENDERS_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getLenders =
  (index = 1) =>
  async (dispatch) => {
    dispatch(requestGetLenders());
    try {
      let url = USER_CONSTANTS.GET_LENDERS_CONSTANTS.URL + `?page=${index}`;
      const res = await ajax("GET", url, {});
      if (res) {
        dispatch(recievedGetLendersResponse(res.data));
        return Promise.resolve(res.data);
      }
    } catch (err) {
      dispatch(recievedGetLendersError(err));
      return Promise.reject(err);
    }
  };

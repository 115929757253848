import React, { useState } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import ReactGA from "react-ga";
import { URLS } from './config/frontendUrls'
// Borrower starts
import Authenticate from "./components/Borrower/Authenticate";
import BuyerProfile from "./components/Borrower/BuyerProfile/BuyerProfile";
import Onboard from "./components/Borrower/onboard";
import Header from "./components/Borrower/header";
import Invoices from "./components/Borrower/invoices/invoices";
import Personaldetails from "./components/Borrower/personaldetails";
import Profile from "./components/Borrower/profile";
import Verifybuyer from "./components/Borrower/BuyerProfile/verifybuyer";
import InvoiceDetails from "./components/Borrower/invoices/invoiceDetails";
import Newbuyer from "../src/components/Borrower/BuyerProfile/newbuyer";
import AddInvoices from "../src/components/Borrower/invoices/addInvoice";
import Buyerlist from "./components/Borrower/BuyerProfile/buyerlist";
import DashMain from "./components/Borrower/Dashboard/dashMain";
import Referral from "./components/Borrower/referral";
import Wallet from "./components/Borrower/wallet";
// Borrower ends

//Common starts
import Home from "./pages/Home";
import { UserContext } from "./context/UserContext";
import Landing from "./components/Landing";
import NotFound from "./components/notFound";
import MobResponsive from "./components/mobResponsive";
import Maintenance from "./components/maintenance";
//Common starts

//Lender starts
import CreatePin from "./components/Lender/Common/createPin";
import ForgotPin from "./components/Lender/Common/forgotPin";
import SuccessPinCreation from "./components/Lender/Common/successPinCreation";
import L_Wallet from "./components/Lender/wallet";
import LenderProfile from "./components/Lender/LenderProfile";
import Login from "./components/Lender/Login";
import Calculator from "./components/Lender/calculator";
import L_dashBoard from "./components/Lender/home";
import W_crypto from "./components/Lender/WithdrawCrypto";
import D_crypto from "./components/Lender/DepositCrypto";
import History from "./components/Lender/history";
//Lender ends

//Admin starts here
import A_LOGIN from "./components/Admin/login";
import A_dashboard from "./components/Admin/borrowerDashboard";
import A_invoiceDetails from "./components/Admin/invoice/invoiceDetails";
import A_borrowerDetails from "./components/Admin/Borrower/borrowerDetails";
import A_BuyerVerification from "./components/Admin/Borrower/buyerverification";
import A_offerMade from "./components/Admin/invoice/offerMade";
import A_companyDocument from "./components/Admin/invoice/companyDocumentation";
import A_InvoiceDocumentation from "./components/Admin/invoice/invoiceDocumentation";
import A_KYCProcess from "./components/Admin/invoice/kycProcess";
import A_Lenderdashboard from "./components/Admin/LenderDashboard/dashboard";
import A_poolcreation from "./components/Admin/LenderDashboard/poolcreation";
import A_lenderDetail from "./components/Admin/LenderDashboard/lenderDetail";
import A_lendingHistory from "./components/Admin/LenderDashboard/lendingHistory";
import A_rewards from "./components/Admin/LenderDashboard/rewards";
import A_venlyWallet from "./components/Admin/LenderDashboard/venlyWallet";
import A_lenderHistorydetails from "./components/Admin/LenderDashboard/lenderHistorydetails";
import A_FinalOffer from "./components/Admin/invoice/finalOffer";
import A_PoolGovernance from "./components/Admin/invoice/poolGovernance";
import A_Arpa from "./components/Admin/invoice/arpa";
import A_InvoiceVerificationByBuyer from "./components/Admin/invoice/invoiceVerificationByBuyer";
import A_AdvancedFundAllocation from "./components/Admin/invoice/advancedFundAllocation";
import A_Collection from "./components/Admin/invoice/collection";
import A_ReserveFundAllocation from "./components/Admin/invoice/reserveFundAllocation";
import A_HistoryBook from "./components/Admin/invoice/historyBook";
import A_bonusApy from "./components/Admin/LenderDashboard/bonusApy";
// Admin ends here
import { GA_TRACKING_ID, ENVIRONMENT } from "./constant";

if (!(ENVIRONMENT !== "production")) {
  ReactGA.initialize(GA_TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
}
const App = () => {
  const [user, setUser] = useState({ isLoggedIn: false, email: "" });
  const [pageG] = useState("global");
  const [pageB] = useState("borrower");
  return (
    <>
      <UserContext.Provider value={user}>
        <Router>
          <Switch>
            {/* Common */}
            <Route path="/mob-view" exact={true} component={MobResponsive} />
            {/* Home page */}
            <Route path="/" exact component={Login}></Route>

            {/* Borrowe start */}
            {/* <Route path="/" exact>
              <Authenticate setStatus={setUser} />
            </Route>
            <Route path={URLS.CLIENT.HOME} exact>
              <Authenticate setStatus={setUser} />
            </Route>
            <Route path={URLS.CLIENT.LOGIN}>
              <Authenticate setStatus={setUser} />
            </Route>
            <Onboard path={URLS.CLIENT.ONBOARD} />
            <Header path={URLS.CLIENT.HEADER} />
            <Route path={URLS.CLIENT.DASHBOARD}>
              <DashMain />
            </Route>
            <Route
              path={URLS.CLIENT.VERIFY_AND_SUBMIT}
              exact
              component={Verifybuyer}
            ></Route>
            <Route
              path={URLS.CLIENT.BUYER_PROFILE}
              exact
              component={BuyerProfile}
            ></Route>
            <Route path={URLS.CLIENT.NEW_BUYER}>
              <Newbuyer />
            </Route>
            <Route path={URLS.CLIENT.BUYER_LIST}>
              <Buyerlist />
            </Route>
            <Route path={URLS.CLIENT.INVOICES_BY_ID} >
              <Invoices />
            </Route>
            <Route path={URLS.CLIENT.INVOICES}>
              <Invoices />
            </Route>
            <Route path={URLS.CLIENT.ADD_INVOICES}>
              <AddInvoices />
            </Route>
            <Route path={URLS.CLIENT.INVOICES_DETAILS_BY_ID}>
              <InvoiceDetails />
            </Route>
            <Personaldetails path="/personal-details" />
            <Route path={URLS.CLIENT.PROFILE_SECTION} component={Profile} />
            <Referral path={URLS.CLIENT.REFERRAL} />
            <Wallet path={URLS.CLIENT.WALLET} /> */}
            {/* Borrowe start end*/}

            {/* Adnin */}
            <A_LOGIN path="/admin" />
            <Route path="/dashboard">
              <A_dashboard />
            </Route>
            <Route path="/lender-dashboard">
              <A_Lenderdashboard />
            </Route>
            <Route path="/lender-details/:id">
              <A_lenderDetail />
            </Route>
            <Route path="/pool-creation">
              <A_poolcreation />
            </Route>
            <Route path="/bonus-apy">
              <A_bonusApy />
            </Route>
            <Route path="/lending-history">
              <A_lendingHistory />
            </Route>
            <Route path="/lender-history-details/:id">
              <A_lenderHistorydetails />
            </Route>
            <Route path="/rewards">
              <A_rewards />
            </Route>
            <Route path="/venly-wallet">
              <A_venlyWallet />
            </Route>
            <Route path="/borrower-details/:id/:uId">
              <A_borrowerDetails />
            </Route>
            <Route path="/invoice-details/:id">
              <A_invoiceDetails />
            </Route>
            <Route path="/buyer-verification/:uId">
              <A_BuyerVerification />
            </Route>
            <Route path="/company-document/:id">
              <A_companyDocument />
            </Route>
            <Route path="/invoice-document/:id">
              <A_InvoiceDocumentation />
            </Route>
            <Route path="/pool-governance/:id">
              <A_PoolGovernance />
            </Route>
            <Route path="/final-offer/:id">
              <A_FinalOffer />
            </Route>
            <Route path="/arpa/:id">
              <A_Arpa />
            </Route>
            <Route path="/invoice-verification-by-buyer/:id">
              <A_InvoiceVerificationByBuyer />
            </Route>
            <Route path="/advance-fund-allocation/:id">
              <A_AdvancedFundAllocation />
            </Route>
            <Route path="/kyc-process/:id">
              <A_KYCProcess />
            </Route>
            <Route path="/collection/:id">
              <A_Collection />
            </Route>
            <Route path="/reserve-fund-allocation/:id">
              <A_ReserveFundAllocation />
            </Route>
            <Route path="/history-book/:id">
              <A_HistoryBook />
            </Route>
            <Route path="/offer-made/:id">
              <A_offerMade />
            </Route>
            {/* Admmin end */}

            {/* Lender start */}
            <L_Wallet path="/lender/wallet" />
            <Route path="/lender/login" component={Login} />
            <Route exact path="/lender/dashboad" component={L_dashBoard} />
            <Route path="/lender/profile">
              <LenderProfile />
            </Route>
            <Route path="/lender/calculator">
              <Calculator />
            </Route>
            <Route path="/lender/home">
              <L_dashBoard />
            </Route>
            <Route path="/lender/withdraw-crypto/:id">
              <W_crypto />
            </Route>
            <Route path="/lender/deposit-crypto">
              <D_crypto />
            </Route>
            <Route path="/lender/history">
              <History />
            </Route>
            <Route path="/lender/create-pin/:id">
              <CreatePin />
            </Route>
            <Route path="/lender/forgot-pin/:id">
              <ForgotPin />
            </Route>
            <Route path="/lender/success-pin">
              <SuccessPinCreation />
            </Route>
            <Route path="/lender" component={Login} />

            {/* Lender ends */}
            <Route path="/under-maintainance" component={Maintenance} />
            <Route path="*" exact={true} component={NotFound} />
          </Switch>
        </Router>
      </UserContext.Provider>
    </>
  );
};

export default App;

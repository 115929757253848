import React, { Component } from "react";
import Sidebar from "./sidebar";
import MainHeader from "../mainHeader";
import { Link } from "react-router-dom";
import {
  getLenderById,
  updateUser,
} from "../../../services/actions/userAction";
import { getKycStatus } from "../../../services/actions/kycStatusAction";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import preview from "../../../assets/images/preview.png";
import download from "../../../assets/images/download1.png";
import Modal from "react-bootstrap/Modal";
import trash from "../../../assets/images/trash.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class LenderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kycstatus: [],
      lenderDetails: [],
      searchLender: "",
      selectedKyc: "",
      country: [],
      showPdf: false,
      pdfUrl: "",
      updateSucess: false,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_isEmpty(nextProps.user.updateSucess) && this.state.updateSucess) {
      toast.info(" updated Successfully");

      this.setState({
        updateSucess: false,
      });
      this.props
        .getLenderById(this.props.match.params.id)
        .then((result) => {
          this.setState({
            lenderDetails: result,
          });
        })
        .catch((err) => {});
    }
  }
  componentDidMount() {
    this.props
      .getLenderById(this.props.match.params.id)
      .then((result) => {
        this.setState({
          lenderDetails: result,
        });
      })
      .catch((err) => {});

    this.props
      .getKycStatus()
      .then((result) => {
        this.setState({
          kycstatus: result,
        });
      })
      .catch((err) => {});
  }

  deletePOI = () => {
    // "https://usmrktrepotesla.s3.us-west-2.amazonaws.com/1630410203972dummy.pdf"

    const { lenderDetails } = this.state;
    lenderDetails.document_proof = null;
    this.setState({
      lenderDetails: lenderDetails,
      updateSucess: true,
    });

    this.props.updateUser(this.state.lenderDetails);
  };
  handleKycChange = (e) => {
    const { lenderDetails } = this.state;
    lenderDetails.kycStatus = e.target.value;
    this.setState({
      lenderDetails: lenderDetails,
      updateSucess: true,
    });

    this.props.updateUser(this.state.lenderDetails);
  };
  onPdfShow = (url) => {
    this.setState({
      showPdf: true,
      pdfUrl: url,
    });
  };
  handleClose = () => {
    this.setState({ showPdf: false });
  };

  render() {
    return (
      <div>
        <Sidebar />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <div className="main-content">
          <header>
            <div className="mt-4 text-white">
              <i
                style={{ fontSize: "20px" }}
                className="ml-4 uil-arrow-left"
                onClick={() => this.props.history.goBack()}
              >
                {" "}
              </i>
              <span>Lenders</span>
            </div>
            <MainHeader />
          </header>
          <div className=" sec3 d-flex justify-content-between">
            <div>
              <p className="text_tra1">Lender Email</p>
              <h6 className="text-white">
                {this.state.lenderDetails ? this.state.lenderDetails.email : ""}
              </h6>
            </div>
            <div>
              <p className="text_tra1">Total Lending</p>
              <h6 className="text-white">
                {this.state.lenderDetails.lend_total_count}
              </h6>
            </div>
            <div>
              <p className="text_tra1">Active Lending</p>
              <h6 className="text-white">
                {this.state.lenderDetails.lend_active_count}
              </h6>
            </div>
            <div>
              <p className="text_tra1">Wallet Activation</p>
              <h6 className="text-white">
                {this.state.lenderDetails ? (
                  this.state.lenderDetails.wallet_activation ? (
                    <span className="txt_b">
                      {this.state.lenderDetails.wallet_activation}
                    </span>
                  ) : (
                    <span className="txt_red">No</span>
                  )
                ) : (
                  ""
                )}
              </h6>
            </div>
            <div>
              <p className="text_tra1 d-none">Buyer</p>
              <h6 className="text-white d-none">Verified</h6>
            </div>
          </div>
          <div className="row m-2">
            <div className=" col-6">
              <div className="info-one h-100">
                <h6 className="text-white">KYC status</h6>
                <div className="bgc-dark mt-2 p-2">
                  <h6 className="text-white">LIVENESS Test</h6>
                </div>
                <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                  <h6 className="text-white">POI </h6>
                  {this.state.lenderDetails.document_proof === null ||
                  this.state.lenderDetails.document_proof === undefined ? (
                    ""
                  ) : (
                    <p>
                      <a
                        onClick={() =>
                          this.onPdfShow(
                            this.state.lenderDetails.document_proof
                          )
                        }
                      >
                        <img src={preview} />
                      </a>
                      <a
                        href={this.state.lenderDetails.document_proof}
                        style={{ color: "white" }}
                      >
                        {" "}
                        <img src={download} />
                      </a>
                      <img
                        className="ml-4"
                        src={trash}
                        onClick={this.deletePOI}
                      />
                    </p>
                  )}
                </div>

                <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                  <h6 className="text-white">POA </h6>
                  {this.state.lenderDetails.document_proof === null ||
                  this.state.lenderDetails.document_proof === undefined ? (
                    ""
                  ) : (
                    <p>
                      <a
                        onClick={() =>
                          this.onPdfShow(
                            this.state.lenderDetails.document_proof
                          )
                        }
                      >
                        <img src={preview} />
                      </a>
                      <a
                        href={this.state.lenderDetails.document_proof}
                        style={{ color: "white" }}
                      >
                        {" "}
                        <img src={download} />
                      </a>
                      <img
                        className="ml-4"
                        src={trash}
                        onClick={this.deletePOI}
                      />
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="info-one h-100">
                <h6 className="text-white">LENDER DETAILS</h6>
                <div className="row mt-4  text_tra1">
                  <div className="col-6">
                    Full Name{" "}
                    <h5 className="text-white">
                      {this.state.lenderDetails
                        ? this.state.lenderDetails.full_name
                        : ""}
                    </h5>
                  </div>
                  <div className="col-6">
                    Date of Birth
                    <h5 className="text-white">
                      {this.state.lenderDetails
                        ? this.state.lenderDetails.date_of_birth
                        : ""}
                    </h5>
                  </div>
                  <div className="col-6">
                    Wallet Address
                    <h5 className="text-white">
                      {this.state.lenderDetails
                        ? this.state.lenderDetails.wallet_details !== "{}"
                          ? this.state.lenderDetails.wallet_address
                          : "-"
                        : "-"}
                    </h5>
                  </div>
                </div>
                <div className="row mt-4  text_tra1">
                  <div className="col-6">
                    {" "}
                    <h5 className="text-white"></h5>
                  </div>
                  <div className="col-6">
                    <h5 className="text-white"></h5>
                  </div>
                </div>
                <div className="row mt-4  text_tra1">
                  <div className="col-6">
                    {" "}
                    <h5 className="text-white"></h5>
                  </div>
                  <div className="col-6">
                    <h5 className="text-white"></h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showPdf} onHide={this.handleClose}>
          <div style={{ backgroundColor: "#333333", color: "white" }}>
            {this.state.pdfUrl !== null ? (
              this.state.pdfUrl.split(".").pop() === "pdf" ||
              this.state.pdfUrl.split(".").pop() === "PDF" ? (
                <iframe
                  src={`https://docs.google.com/gview?url=${this.state.pdfUrl}&embedded=true`}
                  style={{ width: "50rem", height: "50rem" }}
                  frameBorder="0"
                ></iframe>
              ) : (
                <>
                  <img
                    src={this.state.pdfUrl}
                    style={{ width: "30rem", height: "30rem" }}
                  />
                </>
              )
            ) : (
              <div></div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  kycStatus: state.kycStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getKycStatus: (data) => dispatch(getKycStatus(data)),
  getLenderById: (data) => dispatch(getLenderById(data)),
  updateUser: (data) => dispatch(updateUser(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LenderDetail));

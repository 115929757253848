import axios from "axios";
import * as SC_CONSTANTS from "../constants/smartContract";
import ajax from "../../utils/ajax";

export const requestPostSmartToken = () => ({
  type: SC_CONSTANTS.HOME_POST_SMART_TOKEN_CONSTANTS.REQUEST,
});

export const recievedPostSmartTokenResponse = (data) => ({
  type: SC_CONSTANTS.HOME_POST_SMART_TOKEN_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedPostSmartTokenError = (err) => ({
  type: SC_CONSTANTS.HOME_POST_SMART_TOKEN_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const postSmartToken = (data) => async (dispatch) => {
  dispatch(requestPostSmartToken());
  try {
    let url = SC_CONSTANTS.HOME_POST_SMART_TOKEN_CONSTANTS.URL;
    const res = await ajax("POST", url, {}, {}, data);
    if (res) {
      dispatch(recievedPostSmartTokenResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedPostSmartTokenError(err));
    return Promise.reject(err);
  }
};

// GET PRICING DATA

export const requestGetPricingData = () => ({
  type: SC_CONSTANTS.HOME_GET_PRICING_DATA_CONSTANTS.REQUEST,
});

export const recievedGetPricingDataResponse = (data) => ({
  type: SC_CONSTANTS.HOME_GET_PRICING_DATA_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetPricingDataError = (err) => ({
  type: SC_CONSTANTS.HOME_GET_PRICING_DATA_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getPricingData = (id) => async (dispatch) => {
  dispatch(requestGetPricingData());
  try {
    let url = SC_CONSTANTS.HOME_GET_PRICING_DATA_CONSTANTS.URL + id;
    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetPricingDataResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetPricingDataError(err));
    return Promise.reject(err);
  }
};

//Create Offer

export const requestPostOffer = () => ({
  type: SC_CONSTANTS.HOME_CREATE_NEW_OFFER_DATA_CONSTANTS.REQUEST,
});

export const recievedPostOfferResponse = (data) => ({
  type: SC_CONSTANTS.HOME_CREATE_NEW_OFFER_DATA_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedPostOfferError = (err) => ({
  type: SC_CONSTANTS.HOME_CREATE_NEW_OFFER_DATA_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const postOffer = (data) => async (dispatch) => {
  dispatch(requestPostOffer());
  try {
    let url = SC_CONSTANTS.HOME_CREATE_NEW_OFFER_DATA_CONSTANTS.URL;

    const res = await ajax("POST", url, {}, {}, data);
    if (res) {
      dispatch(recievedPostOfferResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedPostOfferError(err));
    return Promise.reject(err);
  }
};

// Get Transaction Status

export const requestGetTxnStatus = () => ({
  type: SC_CONSTANTS.HOME_GET_TXN_STATUS_CONSTANTS.REQUEST,
});

export const recievedGetTxnStatusResponse = (data) => ({
  type: SC_CONSTANTS.HOME_GET_TXN_STATUS_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetTxnStatusError = (err) => ({
  type: SC_CONSTANTS.HOME_GET_TXN_STATUS_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getTxnStatus = (data) => async (dispatch) => {
  dispatch(requestGetTxnStatus());
  try {
    let url = SC_CONSTANTS.HOME_GET_TXN_STATUS_CONSTANTS.URL + data.queue_id;
    const res = await ajax("POST", url, {}, {}, data);
    if (res) {
      dispatch(recievedGetTxnStatusResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetTxnStatusError(err));
    return Promise.reject(err);
  }
};

import React from "react";

import DetailsExpander from "./detailsExpander";
import BuyerDetailsCard from "./detailsCard/buyerDetailsCard";
import { DETAILS } from "../../../config/string_constants";

/**
 * This component displays buyer details in final-offer page
 */
export default function BuyerDetails(props) {
  return (
    <DetailsExpander label={DETAILS.BUYER_DETAILS}>
      <BuyerDetailsCard {...props} />
    </DetailsExpander>
  );
}

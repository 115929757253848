import * as USER_CONSTANTS from "../constants/actionConstant";

export const userReducer = (
  state = {
    type: "",
    error: "",
    isFetching: false,
    userChecked: undefined,
    updateSucess: undefined,
    addSucess: undefined,
    userEmailData: [],
    deleteUser: undefined,
    directors: undefined,
    shareholders: undefined,
    buyer_list: undefined,
    lender: undefined,
    allLenders: undefined,
  },
  action
) => {
  switch (action.type) {
    case USER_CONSTANTS.HOME_GET_USER_CONSTANTS.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        userChecked: undefined,
        users: undefined,
        addSucess: undefined,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.HOME_GET_USER_CONSTANTS.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        userChecked: action.data,
        users: action.data,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.HOME_GET_USER_CONSTANTS.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        error: action.error,
      });
    case USER_CONSTANTS.HOME_POST_USER_CONSTANTS.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        userChecked: undefined,
        addSucess: undefined,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.HOME_POST_USER_CONSTANTS.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        userChecked: action.data,
        addSucess: action.data,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.HOME_POST_USER_CONSTANTS.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        error: action.error,
      });
    case USER_CONSTANTS.HOME_UPDATE_USER_CONSTANTS.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        updateSucess: undefined,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.HOME_UPDATE_USER_CONSTANTS.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        updateSucess: action.data,
        userChecked: action.data,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.HOME_UPDATE_USER_CONSTANTS.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        error: action.error,
      });

    case USER_CONSTANTS.HOME_GET_USER_EMAIL_CONSTANTS.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        didInvalidate: false,
        addSucess: undefined,
        userEmailData: [],
        lastUpdated: action.receivedAt,
      });
    case USER_CONSTANTS.HOME_GET_USER_EMAIL_CONSTANTS.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        userEmailData: action.data,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.HOME_GET_USER_EMAIL_CONSTANTS.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        userEmailData: [],
        error: action.error,
      });

    //Delete
    case USER_CONSTANTS.HOME_DELETE_USER_CONSTANTS.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        didInvalidate: false,
        deleteUser: undefined,
        lastUpdated: action.receivedAt,
      });
    case USER_CONSTANTS.HOME_DELETE_USER_CONSTANTS.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        deleteUser: action.data,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.HOME_DELETE_USER_CONSTANTS.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        error: action.error,
      });

    case USER_CONSTANTS.HOME_GET_BUYERS_FOR_COMPANY_ID.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        addSucess: undefined,
        buyer_list: undefined,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.HOME_GET_BUYERS_FOR_COMPANY_ID.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        buyer_list: action.data,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.HOME_GET_BUYERS_FOR_COMPANY_ID.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        error: action.error,
      });

    case USER_CONSTANTS.GET_LENDER_BY_ID_CONSTANTS.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        lender: undefined,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.GET_LENDER_BY_ID_CONSTANTS.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        lender: action.data,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.GET_LENDER_BY_ID_CONSTANTS.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        error: action.error,
      });
    case USER_CONSTANTS.GET_USER_DETAILS_CONSTANTS.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        lenders: undefined,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.GET_USER_DETAILS_CONSTANTS.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        lenders: action.data,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.GET_USER_DETAILS_CONSTANTS.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        error: action.error,
      });

    case USER_CONSTANTS.GET_LENDERS_CONSTANTS.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        allLenders: undefined,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.GET_LENDERS_CONSTANTS.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        allLenders: action.data,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.GET_LENDERS_CONSTANTS.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        error: action.error,
      });

    case USER_CONSTANTS.HOME_VERIFY_COMPANY_CONSTANTS.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        type: action.type,
        updateSucess: undefined,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.HOME_VERIFY_COMPANY_CONSTANTS.RECEIVED:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        didInvalidate: false,
        updateSucess: action.data,
        userChecked: action.data,
        lastUpdated: action.receivedAt,
      });

    case USER_CONSTANTS.HOME_VERIFY_COMPANY_CONSTANTS.RECEIVED_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        type: action.type,
        error: action.error,
      });

    default:
      return state;
  }
};

export default userReducer;

import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Alert from '../../../assets/images/alert.svg'
import Close from '../../../assets/images/close_icon.svg'
import '../../../assets/css/common/fontStyle.css'
import '../../../assets/css/common/button.css'
import { useHistory } from "react-router-dom";

export default function KycPending(props) {
  const history = useHistory();

  return (
    <div>
      <div style={{ fontFamily: "Averta-Semibold,sans-serif" }}>

        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ backgroundColor: 'transparent' }}  >
          <div className='alert-popup' >

            <div className='mt-3 disp-lg-none'>
              <div className='line mx-auto'></div>
            </div>

            <Modal.Body className='text-center'>
              <div className="float-right mb-2 " >
                <img src={Close} onClick={props.hideReward} className="  user-icon" alt="" />
              </div>
              <div className='kyc-pending'>
                <div  ><img src={Alert} className="user-icon mb-4 ml-4 mt-5" alt="" /></div>
                <div>

                  <div>
                    {props.headerMessage === "Login" ?
                      <button className=" mr-4 custom-button btn-sm btn" onClick={() => {
                        window.location.href = "/lender/login";
                      }}>Login</button> : <label className=" mb-1" style={{ color: '#F2BD54' }}>{props.headerMessage}</label>}
                  </div>
                  <label className="font-style-8 mb-2">{props.mainMessage}</label>
                  {props.walletLimit ? 
                  <button className='btn-blue' onClick={()=>history.replace('/lender/wallet')}>Wallet</button>
                  :""}
                </div>
              </div>

            </Modal.Body>
          </div>
        </Modal>
      </div>
    </div>
  )
}

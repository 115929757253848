import React, { Component } from "react";
import Sidebar from "./sidebar";
import MainHeader from "../mainHeader";
import { Link } from "react-router-dom";
import { getCoins } from "../../../services/actions/coinActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { DATA_STATUS } from "../../../config/string_constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getPoolForAdmin,
  createPoolData,
  updatePoolData,
  updatePoolStatus,
  getLendingDetailsByPool,
  closePool,
  getPoolHistoryForAdmin,
} from "../../../api/poolService";
import Paginations from "../../pagination";
import PoolDetails from "./poolDetails";
class Poolcreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poolShowName: "Create",
      tenureData: ["60", "90", "120"],
      Pools: [],
      poolsHistory: [],
      paginationItem:[],
      showDetails:false,
      addpool: false,
      statusPoolConfirm: false,
      activatePool: true,
      poolCount: 1,
      searchPool: "",
      poolUsers:[],
      deactivatePoolId: "",
      editItem: false,
      closePoolConfirmStatus: false,
      Coins: [],
      Pool: {
        coin_id: "",
        start_date: "",
        pool_name: "",
        end_date: "",
        max_amount: "",
        contract_ticker_symbol: "",
        minimum_locked_amount: "",
        apy_month: "",
        percentage: "",
        bonus_apy: "",
        is_active: false,
        day_apy: [
          {
            apy_month: "",
            percentage: "",
            bonus_apy: "",
            is_active: false,
          },
        ],
        lending_users: [],
        logo_url: null,
      },
      dirty: {
        minimum_locked_amount: false,
        coin_id: false,
        pool_name: false,
        start_date: false,
        end_date: false,
        max_amount: false,
        apy_month: false,
        percentage: false,
        bonus_apy: false,
      },

      errors: {
        minimum_locked_amount: [],
        pool_name: [],
        coin_id: [],
        start_date: [],
        end_date: [],
        max_amount: [],
        apy_month: [],
        percentage: [],
        bonus_apy: [],
      },
    };
  }
  validate = () => {
    var errors = this.state.errors;

    Object.keys(errors).forEach((control, index) => {
      if (control === "minimum_locked_amount") {
        errors[control] = [];
        if (!this.state.Pool[control]) {
          errors[control].push("This field is required");
        }
      } else if (control === "pool_name") {
        errors[control] = [];
        if (!this.state.Pool[control]) {
          errors[control].push("This field is required");
        }
      } else if (control === "start_date") {
        errors[control] = [];
        if (!this.state.Pool[control]) {
          errors[control].push("This field is required");
        }
      } else if (control === "end_date") {
        errors[control] = [];
        if (!this.state.Pool[control]) {
          errors[control].push("This field is required");
        } else {
          if (this.state.Pool[control] < this.state.Pool.start_date) {
            errors[control].push("End date is smaller than start date");
          }
        }
      } else if (control === "max_amount") {
        errors[control] = [];
        if (!this.state.Pool[control]) {
          errors[control].push("This field is required");
        } else {
          if (
            parseFloat(this.state.Pool[control]) <
            parseFloat(this.state.Pool.minimum_locked_amount)
          ) {
            errors[control].push(
              "Maximum Amount should be greater than minimum locked amount"
            );
          }
        }
      } else if (control === "coin_id") {
        errors[control] = [];
        if (!this.state.Pool[control]) {
          errors[control].push("This field is required");
        }
      } else if (control === "apy_month") {
        errors[control] = [];
        if (!this.state.Pool[control]) {
          errors[control].push("This field is required");
        }
      } else if (control === "percentage") {
        errors[control] = [];
        if (!this.state.Pool[control]) {
          errors[control].push("This field is required");
        } else {
          if (parseInt(this.state.Pool[control]) >= 100) {
            errors[control].push("Bonus Apy apy can't be greater than 100");
          }
        }
      } else {
        errors[control] = [];
        if (!this.state.Pool[control]) {
          errors[control].push("This field is required");
        } else {
          if (parseInt(this.state.Pool[control]) >= 100) {
            errors[control].push("Bonus Apy apy can't be greater than 100");
          }
        }
      }
    });

    this.setState({ errors });
  };
  isValid = () => {
    let valid = true;
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      if (errors[control].length > 0) {
        valid = false;
      }
    });

    return valid;
  };
  blurChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };
  handleChange = (e) => {
    const { name, value } = e.target;

    let Pool = this.state.Pool;

    Pool[name] = value;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState(
      {
        Pool: Pool,
        dirty: dirty,
      },
      this.validate
    );
  };

  handleChange1 = (e, item) => {
    const { name, value } = e.target;
    item[name] = value;
    let pool = { ...this.state.Pool };
    this.setState(
      {
        Pool: pool,
      },
      this.validate
    );
  };
  handleClose = () => {
    this.setState({
      poolShowName: "Create",
      statusPoolConfirm: false,
      addpool: false,
      editItem: false,
      closePoolConfirmStatus: false,
      poolCount: 1,
      Pool: {
        pool_name: "",
        coin_id: "",
        start_date: "",
        end_date: "",
        max_amount: "",
        contract_ticker_symbol: "",
        minimum_locked_amount: "",
        apy_month: "",
        percentage: "",
        bonus_apy: "",
        is_active: false,
      },
      dirty: {
        minimum_locked_amount: false,
        coin_id: false,
        start_date: false,
        end_date: false,
        max_amount: false,
        apy_month: false,
        percentage: false,
        bonus_apy: false,
      },

      errors: {
        minimum_locked_amount: [],
        coin_id: [],
        start_date: [],
        end_date: [],
        max_amount: [],
        apy_month: [],
        percentage: [],
        bonus_apy: [],
      },
    });
  };

  goback = () =>{
    this.setState({showDetails:false})
  }

  confirmPoolStatus = async () => {
    this.setState({
      statusPoolConfirm: false,
    });
    let data = await updatePoolStatus(this.state.Pool);
    if (data) {
      toast.info("Status changed successfully");
      this.getPools();
    }
  };

  changePoolStatus(item, status, poolStatus) {
    let data = { ...item };
    data.status = status;
    this.setState({
      statusPoolConfirm: true,
      activatePool: poolStatus,
      Pool: data,
    });
  }

  postPool = async (e) => {
    e.preventDefault();
    this.validate();

    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });

    if (this.isValid()) {
      if (this.state.editItem) {
        let response = await updatePoolData(this.state.Pool);
        if (response) {
          this.handleClose();
          toast.info("updated successfully");
          this.getPools();
        }
      } else {
        let response = await createPoolData(this.state.Pool);
        if (response) {
          this.handleClose();
          toast.info("added successfully");
          this.getPools();
        }
      }
    }
  };
  editPool = (item) => {
    let data = { ...item };
    data.start_date = moment(data.start_date).format("YYYY-MM-DD");
    data.end_date = moment(data.end_date).format("YYYY-MM-DD");
    data.coin_id = item.coin_id._id;
    this.setState({
      Pool: data,
      poolShowName: "Update",
      addpool: true,
      editItem: true,
    });
  };
  handleSearch = (e) => {
    this.setState({
      searchPool: e.target.value,
    });
  };

  getPools = async () => {
    let result = await getPoolForAdmin();
    this.setState({
      Pools: result.data.data,
    });
  };

  pageChange = async (index) => {
    let result = await getPoolHistoryForAdmin(index);
    this.setState({
      poolsHistory: result.data.data,
      paginationItem: result.data,
    });
  };

  getPoolHistoryForAdmin = async () => {
    let result = await getPoolHistoryForAdmin();
    this.setState({
      poolsHistory: result.data.data,
      paginationItem: result.data,
    });
  };

  closePool = async () => {
    let data = await closePool(this.state.Pool);
    if (data) {
      toast.info("pool closed successfully");
      this.handleClose();
      this.getPools();
    }
  };

  lendindDetails = (data)=> {
    this.setState({showDetails:true})
    getLendingDetailsByPool(data)
    .then((result) => {
      this.setState({
        poolUsers: result.data.data.data,
      });
    })
    .catch((err) => {});
  }

  componentDidMount() {
    this.getPools();
    this.props
      .getCoins()
      .then((result) => {
        this.setState({
          Coins: result,
        });
      })
      .catch((err) => {});
  }
  render() {
    return (
      <div>
        <Sidebar />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="main-content">
          <header>
            <div className="dropdown show m-4">
              <a
                className="admin_button dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="pr-4">LENDER DASHBOARD</span>
              </a>

              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <Link to="/dashboard">
                  <span className="pr-4">BORROWER DASHBOARD</span>
                </Link>
              </div>
            </div>
            <MainHeader />
          </header>

          {/*main content*/}
          {!this.state.showDetails ?
          <div className="tab" role="tabpanel">
            <ul className="nav nav-tabs" role="tablist">
              <li role="presentation" className="active pro_mone1">
                <a
                  style={{ textDecoration: "none" }}
                  href="#ApYSec"
                  aria-controls="home"
                  role="tab"
                  data-toggle="tab"
                >
                  Pool
                </a>
              </li>
              <li role="presentation" className="pro_mone1">
                <a
                  style={{ textDecoration: "none" }}
                  href="#HistorySec"
                  aria-controls="home"
                  role="tab"
                  data-toggle="tab"
                  onClick={this.getPoolHistoryForAdmin}
                >
                  Pool History
                </a>
              </li>
              <span className="ml-auto mr-4 search d-flex justify-content-end">
                <button
                  className="btn-blue"
                  onClick={() => {
                    this.setState({ addpool: true });
                  }}
                >
                  Create Pool
                </button>
              </span>
            </ul>

            <div className="tab-content">
              <div role="tabpanel" className="tab-pane  active" id="ApYSec">
                <div className="table-full-width table-responsive px-0">
                  <table className="border-0">
                    <thead className="disp_non_sm">
                      <tr className="text_tra1 ">
                        <th className="pl-2">Coin Name</th>
                        {/* <th className="pl-2"> Pool Name</th> */}
                        <th className="pl-2"> Tenure</th>
                        <th className="pl-2"> Bonus APY</th>
                        <th className="pl-2">Standard APY</th>
                        <th className="pl-2">Filled Amount</th>
                        <th className="pl-2">Minimum Locked Amount</th>
                        <th className="pl-2">Pool Limit</th>
                        <th className="pl-2">Status</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.Pools === undefined ? (
                        <tr>No data available</tr>
                      ) : (
                        this.state.Pools.map((index, key) => (
                          <tr key={key} className="sec1">
                            <td>
                              {index.coin_id ? (
                                <>
                                  {" "}
                                  <img
                                    src={index.coin_id.image}
                                    height="20px"
                                  />
                                  <span className="ml-1">
                                    {index.coin_id.name}
                                  </span>
                                  <div className="text_tra1 pl-4 ml-1">
                                    {index.coin_id.symbol.toUpperCase()}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                            {/* <td>{index.pool_name}</td> */}
                            <td>{index.apy_month}</td>
                            <td>
                              {Number(index.bonus_apy)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              %
                            </td>
                            <td>
                              {Number(index.percentage)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              %
                            </td>
                            <td>
                              {Number(index.total_deposited_amount)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                              {index.coin_id
                                ? index.coin_id.symbol.toUpperCase()
                                : ""}
                            </td>
                            <td>
                              {index.minimum_locked_amount}{" "}
                              {index.coin_id
                                ? index.coin_id.symbol.toUpperCase()
                                : ""}
                            </td>
                            <td>
                              {index.max_amount}{" "}
                              {index.coin_id
                                ? index.coin_id.symbol.toUpperCase()
                                : ""}
                            </td>
                            <td>{index.status}</td>
                            <td>
                              <div className="dropdown">
                                <a
                                  className="dropdown-toggle"
                                  id="LinkDropdownDemo"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <h5
                                    className="curserP"
                                    variant="secondary txt_b"
                                    style={{ color: "rgb(255, 255, 255)" }}
                                  >
                                    ...
                                  </h5>
                                </a>

                                <div
                                  className="dropdown-menu dark-dropdown"
                                  aria-labelledby="LinkDropdownDemo"
                                >
                                  <a
                                    className="dropdown-item dark-dropdown"
                                    onClick={() => this.editPool(index)}
                                  >
                                    <i className="uil uil-edit"></i>
                                    &nbsp;&nbsp;Edit
                                  </a>
                                  {index.status === "ACTIVE" ? (
                                    <a
                                      className="dropdown-item dark-dropdown"
                                      onClick={() =>
                                        this.changePoolStatus(
                                          index,
                                          DATA_STATUS.INACTIVE,
                                          false
                                        )
                                      }
                                    >
                                      <i className="uil uil-power"></i>
                                      &nbsp;&nbsp;Deactivate
                                    </a>
                                  ) : (
                                    <a
                                      className="dropdown-item dark-dropdown"
                                      onClick={() =>
                                        this.changePoolStatus(
                                          index,
                                          DATA_STATUS.ACTIVE,
                                          true
                                        )
                                      }
                                    >
                                      <i className="uil uil-power"></i>
                                      &nbsp;&nbsp;Activate
                                    </a>
                                  )}
                                     <a
                                    className="dropdown-item dark-dropdown"
                                    onClick={() => {
                                      this.lendindDetails(index._id)
                                    }}
                                  >
                                    &nbsp;
                                    <i
                                      className="uil-user-md"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;&nbsp;Pool User
                                  </a>

                                  <a
                                    className="dropdown-item dark-dropdown"
                                    onClick={() => {
                                      this.setState({
                                        closePoolConfirmStatus: true,
                                        Pool: index,
                                      });
                                    }}
                                  >
                                    &nbsp;
                                    <i
                                      className="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;&nbsp;Close
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div role="tabpanel" className="tab-pane" id="HistorySec">
                <div className="table-full-width table-responsive px-0">
                  <table className="border-0">
                    <thead className="disp_non_sm">
                      <tr className="text_tra1 ">
                        <th className="pl-2">Coin Name</th>
                        {/* <th className="pl-2"> Pool Name</th> */}
                        <th className="pl-2"> Tenure</th>
                        <th className="pl-2"> Bonus APY</th>
                        <th className="pl-2">Standard APY</th>
                        {/* <th className="pl-2">90 Days APY</th>
                        <th className="pl-2">120 Days APY</th> */}
                        <th className="pl-2">Minimum Locked Amount</th>
                        <th className="pl-2">Pool Limit</th>
                        <th className="pl-2">Status</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.poolsHistory === undefined ? (
                        <tr>No data available</tr>
                      ) : (
                        this.state.poolsHistory.map((index, key) => (
                          <tr key={key} className="sec1">
                            <td>
                              {index.coin_id ? (
                                <>
                                  {" "}
                                  <img
                                    src={index.coin_id.image}
                                    height="20px"
                                  />
                                  <span className="ml-1">
                                    {index.coin_id.name}
                                  </span>
                                  <div className="text_tra1 pl-4 ml-1">
                                    {index.coin_id.symbol.toUpperCase()}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                            {/* <td>{index.pool_name}</td> */}
                            <td>{index.apy_month}</td>
                            <td>
                              {Number(index.bonus_apy)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              %
                            </td>
                            <td>
                              {Number(index.percentage)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              %
                            </td>
                            {/* <td>
                              {index.day_apy.map((item) =>
                                parseInt(item.apy_month) === 60 ? (
                                  <td className="txt_b">
                                    {item.percentage}%
                                    <div className="text-white">
                                      <span className="text_tra1">
                                        Bonus APY
                                      </span>{" "}
                                      {item.bonus_apy}%
                                    </div>
                                  </td>
                                ) : (
                                  ""
                                )
                              )}
                            </td>
                            <td>
                              {" "}
                              {index.day_apy.map((item) =>
                                parseInt(item.apy_month) === 90 ? (
                                  <td className="txt_b">
                                    {item.percentage}%
                                    <div className="text-white">
                                      <span className="text_tra1">
                                        Bonus APY
                                      </span>{" "}
                                      {item.bonus_apy}%
                                    </div>
                                  </td>
                                ) : (
                                  ""
                                )
                              )}
                            </td>
                            <td>
                              {" "}
                              {index.day_apy.map((item) =>
                                parseInt(item.apy_month) === 120 ? (
                                  <td className="txt_b">
                                    {item.percentage}%
                                    <div className="text-white">
                                      <span className="text_tra1">
                                        Bonus APY
                                      </span>{" "}
                                      {item.bonus_apy}%
                                    </div>
                                  </td>
                                ) : (
                                  ""
                                )
                              )}
                            </td> */}
                            <td>
                              {index.minimum_locked_amount}{" "}
                              {index.coin_id
                                ? index.coin_id.symbol.toUpperCase()
                                : ""}
                            </td>
                            <td>
                              {index.max_amount}{" "}
                              {index.coin_id
                                ? index.coin_id.symbol.toUpperCase()
                                : ""}
                            </td>
                            <td>Closed</td>
                            <td>   <div className="dropdown">
                                <a
                                  className="dropdown-toggle"
                                  id="LinkDropdownDemo"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <h5
                                    className="curserP"
                                    variant="secondary txt_b"
                                    style={{ color: "rgb(255, 255, 255)" }}
                                  >
                                    ...
                                  </h5>
                                </a>

                                <div
                                  className="dropdown-menu dark-dropdown"
                                  aria-labelledby="LinkDropdownDemo"
                                >
                                     <a
                                    className="dropdown-item dark-dropdown"
                                    onClick={() => {
                                      this.lendindDetails(index._id)
                                    }}
                                  >
                                    &nbsp;
                                    <i
                                      className="uil-user-md"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;&nbsp;Pool User
                                  </a>
                                </div>
                              </div></td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-end mx-4 mt-2">
                  <Paginations
                    item={this.state.paginationItem}
                    pageClick={this.pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
          :<PoolDetails  poolUsers={this.state.poolUsers} goback={this.goback} /> }
          <div
            style={{ overflowY: "auto" }}
            className={`menuNav ${this.state.addpool ? "showMenu1" : ""}`}
          >
            <div
              style={{ borderBottom: "1px solid gray" }}
              className="d-flex justify-content-between text-white p-3 pl-1"
            >
              <div>
                <h5>{this.state.poolShowName} Pool</h5>
              </div>
              <h5 style={{ cursor: "pointer" }} onClick={this.handleClose}>
                <i className="uil-multiply" />
              </h5>
            </div>
            <div className="w-100 pr-4 pl-4 mt-2 mx-auto text_tra1">
              <label>Search and select the coin</label>
              <div className="row">
                {this.state.Coins.map((item) => (
                  <div
                    className={
                      this.state.Pool.coin_id === item._id
                        ? "selected-coin-button"
                        : "coin-button"
                    }
                  >
                    <input
                      type="radio"
                      name="coin_id"
                      value={item._id}
                      className="input-hidden"
                      onClick={this.handleChange}
                    />
                    <div className="pos-abs">
                      <img
                        name="coin_id"
                        className="coin-img"
                        src={item.image}
                      ></img>
                      {item.name}
                    </div>
                  </div>
                ))}
              </div>

              <div className="text-danger">
                {this.state.errors.coin_id[0] && this.state.dirty.coin_id
                  ? this.state.errors.coin_id
                  : ""}
              </div>
            </div>
            <div className="row w-100 pr-4 pl-4 mt-3">
              <div className="col-12">
                <label>Pool Name</label>
                <div className="mt-1 d-flex justify-content-end">
                  <input
                    type="text"
                    className="input"
                    placeholder="Pool Name"
                    name="pool_name"
                    value={this.state.Pool.pool_name}
                    onChange={this.handleChange}
                    onBlur={this.blurChange}
                  ></input>
                </div>
                <div className="text-danger">
                  {this.state.errors.minimum_locked_amount[0] &&
                  this.state.dirty.minimum_locked_amount
                    ? this.state.errors.minimum_locked_amount
                    : ""}
                </div>
              </div>
              <div className="col-6">
                <label>Minimum Locked Amount</label>
                <div className="mt-1 d-flex justify-content-end">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter Amount"
                    name="minimum_locked_amount"
                    value={this.state.Pool.minimum_locked_amount}
                    onChange={this.handleChange}
                    onBlur={this.blurChange}
                  ></input>
                </div>
                <div className="text-danger">
                  {this.state.errors.minimum_locked_amount[0] &&
                  this.state.dirty.minimum_locked_amount
                    ? this.state.errors.minimum_locked_amount
                    : ""}
                </div>
              </div>
              <div className="col-6">
                <label>Pool Limit</label>
                <div className="mt-1 d-flex justify-content-end">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter Amount"
                    name="max_amount"
                    value={this.state.Pool.max_amount}
                    onChange={this.handleChange}
                    onBlur={this.blurChange}
                  ></input>
                </div>
                <div className="text-danger">
                  {this.state.errors.max_amount[0] &&
                  this.state.dirty.max_amount
                    ? this.state.errors.max_amount
                    : ""}
                </div>
              </div>
            </div>
            <div className="row w-100 pr-4 pl-4 mt-3">
              <div className="col-6">
                <div className="text_tra1">Start Date</div>
                <div className=" ">
                  <div className="select-group">
                    <input
                      className="input "
                      type="date"
                      name="start_date"
                      value={this.state.Pool.start_date}
                      onChange={this.handleChange}
                      onBlur={(e) => {
                        this.blurChange(e);
                      }}
                    />
                    <div className="select-over display-none">
                      <i className="uil uil-calender cal-dimension" />
                    </div>
                  </div>

                  <div className="text-danger">
                    {this.state.errors.start_date[0] &&
                    this.state.dirty.start_date
                      ? this.state.errors.start_date
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="text_tra1">End Date</div>
                <div className=" ">
                  <div className="select-group">
                    <input
                      className="input "
                      type="date"
                      name="end_date"
                      value={this.state.Pool.end_date}
                      onChange={this.handleChange}
                      onBlur={(e) => {
                        this.blurChange(e);
                      }}
                    />
                    <div className="select-over display-none">
                      <i className="uil uil-calender cal-dimension" />
                    </div>
                  </div>

                  <div className="text-danger">
                    {this.state.errors.end_date[0] && this.state.dirty.end_date
                      ? this.state.errors.end_date
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className=" w-100 pr-4 pl-4 mt-3">
              <h6 className="text-white ">POOLS</h6>
            </div>
            <div className="pl-2 pr-2">
              <div className="bgc-dark borderR m-3 p-3">
                <div className="row">
                  <div className="col-6">
                    <div className="text_tra1">Tenure</div>
                    <select
                      className="form-select wit_th"
                      name="apy_month"
                      value={this.state.Pool.apy_month}
                      onChange={this.handleChange}
                      onBlur={(e) => {
                        this.blurChange(e);
                      }}
                    >
                      <option className="bgc-dark" value="">
                        Select Tenure
                      </option>
                      {this.state.tenureData &&
                        this.state.tenureData.map((itemData, ind) => (
                          <option className="bgc-dark" value={itemData}>
                            {itemData}
                          </option>
                        ))}
                    </select>
                    <div className="text-danger">
                      {this.state.errors.apy_month[0] &&
                      this.state.dirty.apy_month
                        ? this.state.errors.apy_month
                        : ""}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="text_tra1">APY</div>
                    <div className=" ">
                      <input
                        className="input "
                        type="number"
                        name="percentage"
                        value={this.state.Pool.percentage}
                        onChange={this.handleChange}
                        onBlur={(e) => {
                          this.blurChange(e);
                        }}
                      />
                      <div className="text-danger">
                        {this.state.errors.percentage[0] &&
                        this.state.dirty.percentage
                          ? this.state.errors.percentage
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mt-2">
                    <div className=" p-2 ">
                      {" "}
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={this.state.Pool.is_active}
                          onChange={(e) => {
                            var data = { ...this.state.Pool };
                            data.is_active = false;
                            if (e.target.checked) {
                              data.is_active = true;
                            }
                            this.setState({
                              Pool: data,
                            });
                          }}
                        />
                        <span className="slider round"></span>
                      </label>
                      {this.state.Pool.is_active ? (
                        <span className="text-white m-2 ">Acitve</span>
                      ) : (
                        <span className="text_tra1  m-2">Disable</span>
                      )}
                    </div>
                  </div>
                  <div className="col-6 mt-2">
                    <div className="text_tra1">Bonus APY</div>
                    <input
                      className="input "
                      type="number"
                      name="bonus_apy"
                      value={this.state.Pool.bonus_apy}
                      onChange={this.handleChange}
                      onBlur={(e) => {
                        this.blurChange(e);
                      }}
                    />
                    <div className="text-danger">
                      {this.state.errors.bonus_apy[0] &&
                      this.state.dirty.bonus_apy
                        ? this.state.errors.bonus_apy
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center m-4">
              <button className=" w-100 btn-blue" onClick={this.postPool}>
                {this.state.poolShowName}
              </button>
            </div>
          </div>
          <Modal
            show={this.state.statusPoolConfirm}
            onHide={this.handleClose}
            centered
          >
            <div style={{ backgroundColor: "#333333", color: "white" }}>
              <Modal.Header closeButton>
                {this.state.activatePool ? "Activate" : "Deactivate"}
              </Modal.Header>
              <Modal.Body>
                <label>
                  Are you sure you want to{" "}
                  {this.state.activatePool ? "Activate" : "Deactivate"} Pool ?
                </label>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={this.confirmPoolStatus}>
                  {this.state.activatePool ? "Activate" : "Deactivate"}
                </Button>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </div>
          </Modal>

          <Modal
            show={this.state.closePoolConfirmStatus}
            onHide={this.handleClose}
            centered
          >
            <div style={{ backgroundColor: "#333333", color: "white" }}>
              <Modal.Header closeButton>Close Pool</Modal.Header>
              <Modal.Body>
                <label>Are you sure you want to close this Pool ?</label>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={this.closePool}>
                  Close Pool
                </Button>
                <Button variant="secondary" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  coin: state.coin,
});

const mapDispatchToProps = (dispatch) => ({
  getCoins: (data) => dispatch(getCoins(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Poolcreation));

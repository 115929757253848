import axios from "axios";
import { URI } from "../constant";
import ajax from "../utils/ajax";

export let authenticateAdmin = (data) => {
  let url = URI + "/auth/admin/authenticate";
  const res = ajax("POST", url, {}, {}, data);
  return res;
};

export let logoutBE = (data) => {
  let url = URI + "/auth/logout";
  const res = ajax("POST", url, {}, {}, data);
  return res;
};

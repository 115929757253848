import axios from "axios";
import { URI } from "../constant";
import ajax from "../utils/ajax";

export let createDirectorUser = (data) => {
  let url = URI + "/user/buyer-director/";
  const res = ajax("POST", url, {}, {}, data);
  return res;
};

export let updateCompanyUserStatus = (data) => {
  let url = URI + "/user/company/status/" + data.company_id;
  const res = ajax("POST", url, {}, {}, data);
  return res;
};

export let createShareholderUser = (data) => {
  let url = URI + "/user/buyer-shareholder/";
  const res = ajax("POST", url, {}, {}, data);
  return res;
};
export let getLenders = () => {
  let url = URI + "/user/lenders/";
  const res = ajax("GET", url, {});
  return res;
};
export let getUserById = (id) => {
  let url = URI + "/user/" + id;
  const res = ajax("GET", url, {});
  return res;
};
export let sendUserPinLink = (id) => {
  let url = URI + "/user/generate-pin/send-mail/" + id;
  const res = ajax("GET", url, {});
  return res;
};
export let createCompanyUser = (data) => {
  let url = URI + "/user/company/create";
  const res = ajax("POST", url, {}, {}, data);
  if (res) {
    return res;
  } else {
    return false;
  }
};
export let getCompanyUsersAPI = (id) => {
  let url = URI + "/user/company/" + id;
  const res = ajax("GET", url, {});
  return res;
};
export let updateCompanyUserDocument = (data) => {
  let url = URI + "/user/company/document";
  const res = ajax("POST", url, {}, {}, data);
  return res;
};
export let sendUserForgotPinLink = (id, data) => {
  let url = URI + "/user/forgot-pin/send-mail/" + id;
  const res = ajax("POST", url, {}, {}, data);
  return res;
};
export let getGeneralQuestionByType = () => {
  let url = URI + "/user/security-qna/";
  const res = ajax("GET", url, {});
  return res;
};

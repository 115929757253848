import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SignPopup from "./signPopup";
import { withRouter } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import {
  getInvoiceDetailsById,
  updateInvoice,
  getInvoiceData,
} from "../../../services/actions/invoiceAction";
import { getInvoiceStatus } from "../../../services/actions/invoiceStatusAction";
import {
  getFinalOfferById,
} from "../../../services/actions/invoiceFinalOfferAction";
import { postAlert } from "../../../services/actions/alertActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TcPopup from "./tcPopup";
import {
  ADMIN_ALERT_TYPES,
  INVOICE_STATUSES_KEY,
} from "../../../config/string_constants";
import { acceptFinalOffer } from "../../../api/invoiceService";
import { URLS } from "../../../config/frontendUrls";

class FinalcontractPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      showChoice: false,
      offlineSign: false,
      finaloffer: [],
      approvestatus: "",
      approveSubStatus: "",
      invoicedetails: [],
      load: false,
      status: [],
      showPdf: false,
      checked: false,
    };
  }
  handleCheckBox() {
    this.setState({
      checked: !this.state.checked,
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_isEmpty(nextProps.dataofinvoice)) {
      this.setState({
        invoicedetails: nextProps.dataofinvoice,
      });
    }
  }
  componentDidMount() {
    this.props
      .getFinalOfferById(this.props.idofinvoice)
      .then((result) => {
        this.setState({
          load: false,
          finaloffer: result,
        });
      })
      .catch((err) => {});

    this.props
      .getInvoiceStatus()
      .then((result) => {
        let updateStatus =
          result.length !== undefined
            ? result.map((status) =>
                status.key === INVOICE_STATUSES_KEY.ARPA
                  ? status.subStatus !== undefined
                    ? status.subStatus.map((sub) =>
                        sub.statusName === "In Progress"
                          ? this.setState({
                              invoicestatus: result,
                              approvestatus: status._id,
                              approveSubStatus: sub._id,
                            })
                          : ""
                      )
                    : ""
                  : ""
              )
            : "";
      })
      .catch((err) => {});
  }
  handleClose = () => {
    this.setState({ showPdf: false });
  };
  handleSubmit = () => {
    const data = this.state.invoicedetails;
    data.invoiceStatus_id = this.state.approvestatus;
    data.invoiceSubStatus_id = this.state.approveSubStatus;
    data.update_type = ADMIN_ALERT_TYPES.FINAL_OFFER;
    data.final_offer_tnc_status = true;

    this.setState(
      {
        updateSuccess: true,
      },
      () => {
        acceptFinalOffer(data).then((result) => {
          toast.info("Done");
          this.setState({
            updateSuccess: false,
          });
          this.props.history.push(URLS.CLIENT.INVOICES);
        });
      }
    );
  };
  render() {
    return (
      <div>
        <div style={{ fontFamily: "Averta-Semibold,sans-serif" }}>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <SignPopup
            show={this.state.modalShow}
            onHide={() => this.setState({ modalShow: false })}
          />

          <Modal
            {...this.props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ backgroundColor: "black" }}
          >
            <div className="finaloffer">
              <Modal.Header>
                <Modal.Title
                  id="contained-modal-title-vcenter"
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  Final Offer
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: "center" }}>
                <div
                  style={{
                    margin: "0 auto",
                    height: "100%",
                    fontSize: "13px",
                    lineHeight: "24px",
                    textAlign: "left",
                  }}
                >
                  {/* <p style={{ color: "#B5B5B5" }}>
                    {" "}
                    This is the contract that we are going further with. Please
                    confirm and sign.
                    <span
                      className="txt_b curserP"
                      onClick={() => this.setState({ showPdf: true })}
                    >
                      T&C apply
                      <i className="uil-external-link-alt" />
                    </span>
                  </p> */}
                  <div className="row">
                    <div className="col-sm-4 col-6">
                      <div
                        style={{
                          border: "1px solid #595959",
                          margin: "4px",
                          padding: "7px",
                          width: "160px",
                          borderRadius: "5px",
                        }}
                      >
                        <p style={{ color: "#B5B5B5" }}>Invoice Amount</p>
                        <p style={{ fontSize: "18px" }}>
                          $
                          {this.state.invoicedetails
                            ? Number(this.state.invoicedetails.invoice_amount)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 col-6">
                      <div
                        style={{
                          border: "1px solid #595959",
                          margin: "4px",
                          padding: "7px",
                          width: "160px",
                          borderRadius: "5px",
                        }}
                      >
                        <p style={{ color: "#B5B5B5" }}>
                          Factoring Fee(
                          {this.props.finalOfferDetails
                            ? this.props.finalOfferDetails.factoring_fee
                            : ""}
                          %)
                        </p>
                        <p style={{ fontSize: "18px" }}>
                          $
                          {this.props.finalOfferDetails
                            ? Number(
                                this.props.finalOfferDetails.factoring_amount
                              )
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                            : ""}
                        </p>
                      </div>
                    </div>

                    <div className="col-sm-4 col-6">
                      <div
                        style={{
                          border: "1px solid #595959",
                          margin: "4px",
                          padding: "6px",
                          width: "160px",
                          borderRadius: "5px",
                        }}
                      >
                        <p style={{ color: "#B5B5B5" }}>
                          Discounting Fee(
                          {this.props.finalOfferDetails
                            ? this.props.finalOfferDetails.discount
                            : ""}
                          %)
                        </p>

                        <p style={{ fontSize: "18px" }}>
                          $
                          {this.props.finalOfferDetails
                            ? Number(
                                this.props.finalOfferDetails.discount_amount
                              )
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 col-6">
                      <div
                        style={{
                          border: "1px solid #595959",
                          margin: "4px",
                          padding: "7px",
                          width: "160px",
                          borderRadius: "5px",
                        }}
                      >
                        <p style={{ color: "#B5B5B5" }}>Tenure</p>
                        <p style={{ fontSize: "18px" }}>
                          {this.props.finalOfferDetails
                            ? this.props.finalOfferDetails.tenure
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 col-6">
                      <div
                        style={{
                          border: "1px solid #595959",
                          margin: "4px",
                          padding: "7px",
                          width: "160px",
                          borderRadius: "5px",
                        }}
                      >
                        <p style={{ color: "#B5B5B5" }}>Grace Period</p>
                        <p style={{ fontSize: "18px" }}>
                          {this.props.finalOfferDetails
                            ? this.props.finalOfferDetails.grace_period
                            : ""}{" "}
                          Days
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 col-6">
                      <div
                        style={{
                          border: "1px solid #595959",
                          margin: "4px",
                          padding: "7px",
                          width: "160px",
                          borderRadius: "5px",
                        }}
                      >
                        <p style={{ color: "#B5B5B5" }}>Bank Charges</p>
                        <p style={{ fontSize: "18px" }}>
                          $
                          {this.props.finalOfferDetails
                            ? Number(
                                this.props.finalOfferDetails.bank_charges
                                  ? this.props.finalOfferDetails.bank_charges
                                  : 0
                              )
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div
                        style={{
                          border: "1px solid #595959",
                          margin: "8px",
                          padding: "14px",
                          borderRadius: "5px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          Approved Amount
                          <span
                            style={{
                              color: "#2DA3B4",
                              fontSize: "12px",
                              float: "right",
                              fontSize: "24px",
                            }}
                          >
                            $
                            {this.props.finalOfferDetails
                              ? Number(this.props.dataofinvoice.invoice_limit)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="col-12">
                      <div
                        style={{
                          border: "1px solid #595959",
                          margin: "8px",
                          padding: "14px",
                          borderRadius: "5px",
                        }}
                      >
                        <p style={{ fontSize: "18px" }}>
                          Amount to be Funded
                          <span
                            style={{
                              color: "#2DA3B4",
                              fontSize: "12px",
                              float: "right",
                              fontSize: "24px",
                            }}
                          >
                            $
                            {this.props.finalOfferDetails
                              ? Number(
                                  this.props.finalOfferDetails
                                    .amount_to_be_funded
                                )
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.finalOffer ? (
                  <div>
                    <div>
                      <div className="text-white mt-2">
                        <input
                          className="form-check-input mr-5"
                          type="checkbox"
                          onClick={() => this.handleCheckBox()}
                          style={{ height: "15px", width: "60px" }}
                        />
                        <span className="ml-4">I agree to the</span>{" "}
                        <span
                          className="txt_b curserP"
                          onClick={() => this.setState({ showPdf: true })}
                        >
                          {" "}
                          Terms & Conditions
                        </span>
                      </div>
                    </div>

                    <div style={{ margin: "20px", alignItems: "right" }}>
                      <Button
                        disabled={!this.state.checked}
                        onClick={this.handleSubmit}
                        style={{ backgroundColor: "#2DA3B4" }}
                      >
                        Confirm Offer
                      </Button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Modal.Body>
            </div>

            <TcPopup
              showPdf={this.state.showPdf}
              handleClose={this.handleClose}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  invoiceStatus: state.invoiceStatus,
  invoiceFinalOffer: state.invoiceFinalOffer,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  getInvoiceData: (data) => dispatch(getInvoiceData(data)),
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  getFinalOfferById: (data) => dispatch(getFinalOfferById(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  postAlert: (data) => dispatch(postAlert(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FinalcontractPopup));

{
  /* choose mode of sign */
}
{
  /* <div style={{fontFamily:"Averta-Semibold,sans-serif"}}>
<Modal
show={this.state.showChoice}
onHide={() => this.setState({showChoice: false })}
aria-labelledby="contained-modal-title-vcenter"
centered
style={{backgroundColor:'black'}}  >
<div style={{backgroundColor:'#333333',color:'white',border:'0'}}> 
<Modal.Header>

<Modal.Title id="contained-modal-title-vcenter" style={{fontSize:'16px',lineHeight:'24px'}} >
Sign Contract
</Modal.Title>
</Modal.Header>
<Modal.Body style={{textAlign:'left'}} >
<div style={{margin:'0 auto',width:'100%',height:'100%',fontSize:'13px',lineHeight:'24px'}}>

   <p style={{textAlign:'left',padding:"12px",color:"#8E8E8E"}}>Choose the prefered method to sign the contract.</p>
     <div style={{float:"left"}}>
         <div> <input style={{width:"auto"}} type="radio" id="" name="fav_language" value=""/>
          <label for="html"  style={{fontSize:"14px",lineHeight:"24px"}}>Offline Contract Sign</label>
        <p style={{paddingLeft:"20px",color:"#8E8E8E",lineHeight:"20px"}}>In Offline, you will have to download the contract document and
upload the signed document.</p>
        </div>
        <div> <input style={{width:"auto",marginTop:"12px"}} type="radio" id="" name="fav_language" value=""/>
          <label for="css" style={{fontSize:"14px",lineHeight:"24px"}}>Online Contract Sign</label>
        <p style={{paddingLeft:"20px",color:"#8E8E8E",lineHeight:"20px"}}>In Online, You will be redirected to DocuSign to sing the document online.</p>
        </div>
         
 </div>
</div>


<div style={{margin:'20px',textAlign:"center"}}>
 <Button onClick={() => this.setState({offlineSign :true} )} style={{backgroundColor:"#2DA3B4",alignSelf:"center"}}>Proceed</Button>
</div>
</Modal.Body>
</div>
</Modal>
</div> */
}

{
  /* offline sign contract */
}
{
  /* <div style={{fontFamily:"Averta-Semibold,sans-serif"}}>
        
<Modal show={this.state.offlineSign}
aria-labelledby="contained-modal-title-vcenter"
centered
style={{backgroundColor:'black'}}  >
<div style={{backgroundColor:'#333333',color:'white',border:'0'}}> 
<Modal.Header>

<Modal.Title id="contained-modal-title-vcenter" style={{fontSize:'16px',lineHeight:'24px'}} >
Offline Contract Sign 
</Modal.Title>
</Modal.Header>
<Modal.Body style={{}} >
<div style={{margin:'0 auto',width:'100%',height:'100%',fontSize:'13px',lineHeight:'24px'}}>

   <p style={{textAlign:'left',padding:"12px",fontSize:"14px"}}>Download the contract document from here. <span style={{float:"right"}}><img src={Download}/> Download</span></p> 
     <div style={{margin:"24px",padding:"12px",border:"1px solid #595959",textAlign:"center",borderRadius:"6px"}}>
         <p style={{color:"#B6B6B6"}}>Upload the signed contract document here</p>
         <button style={{backgroundColor:"#333333",margin:"12px",border:"1px solid #2DA3B4",padding:"10px",borderRadius:"3px",color:"white"}}>Upload</button>
     </div>
         
 
</div>


<div style={{margin:'20px',textAlign:"center"}}>
< Button  style={{backgroundColor:"#2DA3B4"}} onClick={this.handleSubmit}>Submit</Button>
</div>
</Modal.Body>
</div>
</Modal>
</div> */
}

import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Alert from '../../../assets/images/alert.svg'
import Close from '../../../assets/images/close_icon.svg'
import polytrade from '../../../assets/images/polytrade.svg'
import '../../../assets/css/common/fontStyle.css'
import '../../../assets/css/common/button.css'
import QRCode from 'qrcode.react';
export default function Reward_soon(props) {
    
    return (
        <div>
             <div style={{fontFamily:"Averta-Semibold,sans-serif"}}>
        
           <Modal
    {...props}
    aria-labelledby="contained-modal-title-vcenter"
    centered
style={{backgroundColor:'transparent'}}  >
    <div style={{backgroundColor:'#333333',color:'white',border:'0'}}> 
      
        
        <Modal.Body  style={{textAlign:'center'}} >
        <div className="float-right mb-2 " >
        <img src={Close} onClick={props.hidekycNow}  className="  user-icon" alt=""/>
        </div>
        <div style={{margin:'0 auto',width:'100%',height:'100%',fontSize:'16px',lineHeight:'24px'}}>
            <div  >
            <QRCode value={`${props.address}`} />
            </div>
         
           </div>
      
        </Modal.Body>
        </div>
        </Modal>
        </div>
        </div>
    )
}

import {React, Component} from "react";
import Logo from '../../assets/images/Logo.svg';
import styles from '../../assets/css/OnboardingPersonalDetails.module.css';
// import "../personaldetails.css";
import '../../assets2/css/personaldetails.css';
import {postCompany} from './../../services/actions/companyActions';
import {postUser} from './../../services/actions/userAction';
import {connect} from 'react-redux';
import _isEmpty from "lodash/isEmpty";

class  Personaldetails extends Component{
  constructor(props){ 
    super(props);
    this.state = {
      user:{full_name:'',designation:'',phone_number:'',company_id:''},

      company:{company_name:'',company_email_id:'',company_phone_no:'',website:'',about:'',company_landline:'',
       address_line1:'',address_line2:'',city:'',zipcode:''},

       dirty:{full_name:false,designation:false,phone_number:false,company_id:false,company_name:false,company_email_id:false,
        company_phone_no:false,website:false,about:false,company_landline:false,
       address_line1:false,address_line2:false,city:false,zipcode:false},

       errors:{full_name:[],designation:[],phone_number:[],company_id:[],company_name:[],company_email_id:[],company_phone_no:[],
        website:[],about:[],company_landline:[], address_line1:[],address_line2:[],city:[],zipcode:[]}
    };

  }

  handleChange =(e)=> {
    const{name,value} = e.target;

    let dirty = this.state.dirty;
    dirty[name]=true;

    let user = this.state.user;
    let company = this.state.company;
    user[name]=value;
    company[name]= value;

  this.setState({
    user:user,
    company:company,
    dirty:dirty
  }, this.validate);
  } 

  blurChange=(e)=>{
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  }
  validate = () => {
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
    errors[control] = [];

      switch (control) {
       case "full_name":
          //fullName can't be blank
          if (!this.state.user[control]) {
            errors[control].push("Name can't be blank");
          }
          break;
        case "designation":
          //email can't be blank
          if (!this.state.user[control]) {
            errors[control].push("This field is required");
          }
        break;
        case "phone_number":
          //fullName can't be blank
          if (!this.state.user[control]) {
            errors[control].push("This field is required");
          }
          break;
          case "company_name":
          //fullName can't be blank
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
          case "company_name":
          //fullName can't be blank
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
          case "company_email_id":
          //fullName can't be blank
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
          case "company_email_id":
          //fullName can't be blank
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
          case "company_phone_no":
          //fullName can't be blank
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
          case "website":
          //fullName can't be blank
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
          case "about":
          //fullName can't be blank
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
          case "company_landline":
          //fullName can't be blank
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
          case "address_line1":
          //fullName can't be blank
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
          case "address_line2":
          //fullName can't be blank
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
          case "city":
          //fullName can't be blank
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
          case "zipcode":
          //fullName can't be blank
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;

       default:
          break;
      }
    });
  this.setState({ errors });
  };
  isValid = () => {
    let valid = true;
   
    for (let control in this.state.errors) {
    
      if (this.state.errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  }
  checkUser=(e)=>{
    e.preventDefault();
    
  }

  saveCompany=(e)=>{
    <div className="text-danger">
    {this.state.errors.full_name[0] &&
    this.state.dirty.full_name
      ? this.state.errors.full_name
      : ""}
  </div>
this.validate();
var dirty = this.state.dirty;
Object.keys(dirty).forEach((control) => {
dirty[control] = true;
});
this.setState({ dirty: dirty });
  
if (this.isValid()) {
  e.preventDefault();
  this.props.postCompany(this.state.company)
}
     
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(!_isEmpty(nextProps.company.company))    
        {
          let companyDetails = nextProps.company.company;
          this.setState({ user: { ...this.state.user, company_id: companyDetails.company_id} })
        }
        this.props.postUser(this.state.user);
        
  }
      componentDidMount(){
   
      }

     
 
render(){


return(
    
    <>
        <nav className="navbar navbar-light Navbar">
  <a className="navbar-brand" href="#">

  <img className={styles.Logo} src={Logo} alt="" />
  </a>
</nav>

{/* Body Tab */}
<section className="design-process-section bg_black" id="process-tab">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-8"> 
     
        <ul className="nav  process-model more-icon-preocess" role="tablist">
          <li role="presentation" className="active"><a href="#discover" aria-controls="discover" role="tab" data-toggle="tab"><i>01</i>
            <p>Personaldetail</p>
            </a></li>
          <li role="presentation"><a href="#strategy" aria-controls="strategy" role="tab" data-toggle="tab"><i>02</i>
            <p>Company Detail</p>
            </a></li>
          <li role="presentation"><a href="#optimization" aria-controls="optimization" role="tab" data-toggle="tab"><i>03</i>
            <p>Company Address</p>
            </a></li>
     
        </ul>
     




     
        <div className="tab-content">
          <div role="tabpanel" className="tab-pane active" id="discover">
            <div className="design-process-content">
              
             {/* first page start */}
           <h3 className="text_color text-center pt-3">Tell as a Bit About Your Self</h3>
    <p className="text_color3  ">Polytrade need this information to give you better product experience </p>
    {/* input box */}
    <div className="row">
    <div className="col-md-3"></div>
    <div className="col-md-6">
    <div className="  mb-3 text-left mt-4">
     <label className="form-label text_color">Full name</label>
     <input type="text" className="form-control input_bg" name="example-text-input" placeholder="Input placeholder"
    name="full_name"  value={this.state.user.full_name} onChange={this.handleChange} onBlur={this.blurChange}
/>
<div className="text-danger">{this.state.errors.full_name[0] && this.state.dirty.full_name ? this.state.errors.full_name : ""}</div>
                            </div>
                            </div>
                            <div className="col-md-2"></div>
                         </div>
                          
                         

                         <div className="row">
    <div className="col-md-3"></div>
    <div className="col-md-6">
    <div className="  mb-3 text-left">
                              <label className="form-label text_color">Designation</label>
                              <input type="text" className="form-control input_bg" name="example-text-input" placeholder="Input placeholder"
                           name="designation"   value={this.state.user.designation} onChange={this.handleChange} onBlur={this.blurChange} />
                            <div className="text-danger">{this.state.errors.designation[0] && this.state.dirty.designation ? this.state.errors.designation : ""}</div>
                            </div>
                            </div>
                            <div className="col-md-2"></div>
                         </div>
                         {/*  */}
                         <div className="row">
    <div className="col-md-3"></div>
    <div className="col-md-6">
    <div className="  mb-3 text-left">
                              <label className="form-label text_color">Phone no</label>
                              <div className="input-group">
                                <button type="button" className="btn dropdown-toggle input_bg" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                               <span className="text_act"> +91</span>
                                </button>
                                <div className="dropdown-menu">
                                  <a className="dropdown-item" href="#">
                                    Action
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    Another action
                                  </a>
                                </div>
                                <input type="text" className=" ml-2 form-control input_bg" aria-label="Text input with dropdown button"
                              name="phone_number"  value={this.state.user.phone_number} onChange={this.handleChange} onBlur={this.blurChange}/>
                               <div className="text-danger">{this.state.errors.phone_number[0] && this.state.dirty.phone_number ? this.state.errors.phone_number : ""}</div>
                              </div>
                            </div>
                            </div>
                            <div className="col-md-2"></div>
                         </div>
                         {/*  */}
                         <div className="row row justify-content-center pt-3">
   
                         <div className="col-md-6 mb-3">
                        <a href="#" className="btn btn-primary w-100" onClick={this.checkUser}>
                       Continue
                        </a>
                      </div>
                         
                         </div>
                           
         
      {/* first page End */}
             </div>
          </div>
          <div role="tabpanel" className="tab-pane" id="strategy">
            <div className="design-process-content ">
                   {/* second page start */}
                                
                   <div className="row bg_black2">
                                          <div className="justify-content-center  cent_move">
                                      <h3 className="text_color cent_move2">Company Detail</h3>
    <p className="text_color2 ">Polytrade need this information to give you better product experience </p>
                                    {/* uplod image */}
                                    <div className="justify-content-center img_box">
                                 <div className="mt-3   form-selectgroup-label d-flex align-items-center p-3 bg-dark bor_der">
                                  <div className="me-3">
                                    <span className="form-selectgroup-check cent_move3"><i className="fas fa-cloud-upload-alt  "></i></span>
                                  </div>
                                  <div className="pl-2 form-selectgroup-label-content d-flex align-items-center"> <span className="avatar me-3"  style={{ backgroundImage: `url("https://via.placeholder.com/500")` 
                                        }}></span>
                                    <div>
                                      <div className="font-weight-medium col_er ">Mallory Hulme</div>
                                      <div className="text-muted " >Geologist IV</div>
                                    </div>
                                  </div>
                                </div>
                                </div>

                                {/*  */}
                                 </div>
                                      <div className="col-md-6">
                                     
                              <label className="form-label text_gray">Company Name</label>
                              <input type="text" className="form-control input_bg" name="example-text-input" placeholder="Input placeholder"
                             name="company_name" value={this.state.company.company_name} onChange={this.handleChange} onBlur={this.blurChange}/>
                              <div className="text-danger">{this.state.errors.company_name[0] && this.state.dirty.company_name ? this.state.errors.company_name : ""}</div>
                              <div className="  mb-3 text-left mt-4">
                              <label className="form-label text_gray">About Company</label>
                             
                              <input type="text" className="form-control input_bg" name="example-text-input" placeholder="Input placeholder"
                             name="about" value={this.state.company.about} onChange={this.handleChange} onBlur={this.blurChange}/>
                              <div className="text-danger">{this.state.errors.about[0] && this.state.dirty.about ? this.state.errors.about : ""}</div>
                            </div> <div className="  mb-3 text-left mt-4">
                             
                              <label className="form-label text_gray">Phone No.</label>
                              <div className="input-group">
                                <button type="button" className="btn dropdown-toggle input_bg" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                               <span className="text_act"> +91</span>
                                </button>
                                <div className="dropdown-menu">
                                  <a className="dropdown-item" href="#">
                                    Action
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    Another action
                                  </a>
                                </div>
                                <input type="text" className=" ml-2 form-control input_bg" aria-label="Text input with dropdown button"
                               name="company_phone_no" value={this.state.company.company_phone_no} onChange={this.handleChange} onBlur={this.blurChange}/>
                              <div className="text-danger">{this.state.errors.company_phone_no[0] && this.state.dirty.company_phone_no ? this.state.errors.company_phone_no : ""}</div> </div>
                            </div>
                                          </div>

                                  <div className="col-md-6">
                                     
                              <label className="form-label text_gray">Company email address</label>
                              <input type="text" className="form-control input_bg" name="example-text-input" placeholder="Input placeholder"
                             name="company_email_id" value={this.state.company.company_email_id} onChange={this.handleChange} onBlur={this.blurChange}/>
                              <div className="text-danger">{this.state.errors.company_email_id[0] && this.state.dirty.company_email_id ? this.state.errors.company_email_id : ""}</div>
                              <div className="  mb-3 text-left mt-4">
                              <label className="form-label text_gray">Website</label>
                             
                              <input type="text" className="form-control input_bg" name="example-text-input" placeholder="Input placeholder"
                             name="website" value={this.state.company.website} onChange={this.handleChange} onBlur={this.blurChange}/>
                              <div className="text-danger">{this.state.errors.website[0] && this.state.dirty.website ? this.state.errors.website : ""}</div>
                            </div> <div className="  mb-3 text-left mt-4">
                             
                              <label className="form-label text_gray">Landline (optional)</label>
                              <input type="text" className="form-control input_bg" name="example-text-input" placeholder="Input placeholder"
                            name="company_landline"  value={this.state.company.company_landline} onChange={this.handleChange} onBlur={this.blurChange}/>
                            <div className="text-danger">{this.state.errors.company_landline[0] && this.state.dirty.company_landline ? this.state.errors.company_landline : ""}</div> </div>
                                          </div>
                                               {/*  */}
                        
   <div className="with_but">
  <a href="#" className="btn btn-primary but_pad w-100">
 Continue
  </a>
  </div>
 
                                   
                                  </div>
       
      {/* second page End */}
             
              </div>
          </div>
          <div role="tabpanel" className="tab-pane" id="optimization">
            <div className="design-process-content">
             
                                    {/* third page start */}
                                    <div className="container">
        <div className="row">
          <div className="col-md-2 text-center pt-3"></div>
          <div className="col-md-8 text-center pt-3"><h3 className="text_color">Company Address That's it!</h3>
    <p className="text_color3 ">Polytrade offer your Company information to offer you trade finance</p>
    {/* input box */}
    <div className="row">
    <div className="col-md-2"></div>
    <div className="col-md-8">
    <div className="  mb-3 text-left mt-4">
                              <label className="form-label text_color">Address Line1</label>
                              <input type="text" className="form-control input_bg" name="example-text-input" placeholder="Input placeholder"
                             name="address_line1" value={this.state.company.address_line1} onChange={this.handleChange} onBlur={this.blurChange}/>
                             <div className="text-danger">{this.state.errors.address_line1[0] && this.state.dirty.address_line1 ? this.state.errors.address_line1 : ""}</div></div>
                            </div>
                            <div className="col-md-2"></div>
                         </div>
                          {/*  */}
                          <div className="row">
    <div className="col-md-2"></div>
    <div className="col-md-8">
    <div className="  mb-3 text-left">
                              <label className="form-label text_color">Address Line2</label>
                              <input type="text" className="form-control input_bg" name="example-text-input" placeholder="Input placeholder"
                            name="address_line2" value={this.state.company.address_line2} onChange={this.handleChange} onBlur={this.blurChange}/>
                            <div className="text-danger">{this.state.errors.address_line2[0] && this.state.dirty.address_line2 ? this.state.errors.address_line2 : ""}</div> </div>
                            </div>
                            <div className="col-md-2"></div>
                         </div>
                         {/*  */}

                         <div className="row">
                         <div className="col-md-2"></div>
    <div className="col-md-8">
    <div className="  mb-3 text-left">
    <label className="form-label text_color">Address Line2</label>
                        <select className="form-select wit_th">
                          <option>Option 1</option>
                        </select>
                    </div>
                            </div>
                            <div className="col-md-2"></div>
   
                         </div>
                         
                     
                         {/*  */}
                         <div className="row">
                          <div className="col-md-2">
                        
                          </div>
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-6">
                              <div className="  mb-3 text-left">
                              <label className="form-label text_color">City</label>
                              <input type="text" className="form-control input_bg" name="example-text-input" placeholder="Input placeholder"
                            name="city"  value={this.state.company.city} onChange={this.handleChange} onBlur={this.blurChange}/>
                             <div className="text-danger">{this.state.errors.city[0] && this.state.dirty.city ? this.state.errors.city : ""}</div></div>
                              </div>
                              <div className="col-md-6">
                              <div className="  mb-3 text-left">
                              <label className="form-label text_color">Zip Code</label>
                              <input type="text" className="form-control input_bg" name="example-text-input" placeholder="Input placeholder"
                              value={this.state.company.zipcode}
                             name="zipcode" onChange={this.handleChange} onBlur={this.blurChange}/>
                            <div className="text-danger">{this.state.errors.zipcode[0] && this.state.dirty.zipcode ? this.state.errors.zipcode : ""}</div>
                            </div>
                              </div>
                            </div>
                          </div>
   
                            <div className="col-md-2">
                         
                            </div>
                         </div>
                         {/*  */}
                         <div className="row row justify-content-center pt-3">
   
                         <div className="col-md-8 mb-3">
                        <a href="#" className="btn btn-primary w-100" onClick={this.saveCompany}>
                       Continue
                        </a>
                      </div>
                         
                         </div>
                            </div>
          <div className=" text-center pt-3"></div>
        </div>
      </div>
               </div>
          </div>
          <div role="tabpanel" className="tab-pane" id="content">
            <div className="design-process-content">
              <h3 className="semi-bold">Content</h3>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincid unt ut laoreet dolore magna aliquam erat volutpat</p>              
              </div>
          </div>
          <div role="tabpanel" className="tab-pane" id="reporting">
            <div className="design-process-content">
              <h3>Reporting</h3>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincid unt ut laoreet dolore magna aliquam erat volutpat. </p>
          </div>
        </div>
      </div>




    </div>
  </div>
  </div>
</section>

    </>

                             )                             }
}

const mapStateToProps = state => ({
  company: state.company,
  user: state.user
});


const mapDispatchToProps = dispatch => ({
  postCompany: (data) => dispatch(postCompany(data)),
  postUser: (data) => dispatch(postUser(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Personaldetails);
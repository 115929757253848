import {URI} from '../../constant'

export const HOME_GET_COMPANY_CONSTANTS = ({
    URL: URI+"/company",
    REQUEST: 'REQUEST_GET_COMPANY_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_GET_COMPANY_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_GET_COMPANY_DATA_CONSTANTS',
  });
  
  export const HOME_GET_CREATED_COMPANY_CONSTANTS = ({
    URL: URI+"/company/created-by/",
    REQUEST: 'REQUEST_GET_CREATED_COMPANY_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_GET_CREATED_COMPANY_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_GET_CREATED_COMPANY_DATA_CONSTANTS',
  });
  export const HOME_POST_COMPANY_CONSTANTS = ({
    URL: URI+"/company",
    REQUEST: 'REQUEST_POST_COMPANY_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_POST_COMPANY_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_POST_COMPANY_DATA_CONSTANTS',
  });

  export const HOME_UPDATE_COMPANY_CONSTANTS = ({
    URL: URI+"/company/",
    REQUEST: 'REQUEST_UPDATE_COMPANY_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_UPDATE_COMPANY_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_UPDATE_COMPANY_DATA_CONSTANTS',
  });
  export const HOME_GET_COMPANY_BY_ID_CONSTANTS = ({
    URL: URI+"/company/",
    REQUEST: 'REQUEST_COMPANY_BY_ID_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_COMPANY_BY__ID_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_COMPANY_BY_ID_DATA_CONSTANTS',
  });

  export const HOME_GET_COMPANY_BY_COMPANY_ID_CONSTANTS = ({
    URL: URI+"/company/company/",
    REQUEST: 'REQUEST_COMPANY_BY_COMPANY_ID_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_COMPANY_BY_COMPANY_ID_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_COMPANY_BY_COMPANY_ID_DATA_CONSTANTS',
  });

  export const HOME_GET_BUYERS_LIST_CONSTANTS = ({
    URL: URI+"/company/buyer/",
    REQUEST: 'REQUEST_GET_BUYERS_LIST_CONSTANTS',
    RECEIVED: 'RECEIVED_GET_BUYERS_LIST_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_GET_BUYERS_LIST_CONSTANTS',
  });

  export const HOME_DELETE_COMPANY_CONSTANTS = ({
    URL: URI+"/company/",
    REQUEST: 'REQUEST_HOME_DELETE_COMPANY_CONSTANTS',
    RECEIVED: 'RECEIVED_HOME_DELETE_COMPANY_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_HOME_DELETE_COMPANY_CONSTANTS',
  });
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import userReducer from './reducers/userReducer';
import invoiceReducer from './reducers/invoiceReducer';
import companyReducer from './reducers/companyReducer'
import countryReducer from './reducers/countryReducer';
import activityReducer from './reducers/activityReducer';
import buyerReducer from './reducers/buyerReducer';
import referralReducer from './reducers/referralReducer'
import userAccountReducer from './reducers/userAccountsReducer'
import UserWalletReducer from './reducers/userWalletReducer'
import invoiceStatusReducer from './reducers/invoiceStatusReducer'
import alertReducer from './reducers/alertReducer'
import kycStatusReducer from './reducers/kycStatusReducer'
import invoiceOfferReducer from './reducers/invoiceOfferReducer'
import poolReducer from './reducers/poolReducer'
import coinReducer from './reducers/coinReducer'
import invoiceFinalOfferReducer from './reducers/invoiceFinalOfferReducer'
import scReducer from './reducers/smartContractReducer'
import pricingReducer from './reducers/pricingReducer'

const rootReducer = combineReducers({
  user:userReducer,
  dashboard:invoiceReducer,
  company:companyReducer,
  country:countryReducer,
  invoice: invoiceReducer,
  activity:activityReducer,
  buyer:buyerReducer,
  referral:referralReducer,
  userAccount:userAccountReducer,
  userWallet: UserWalletReducer,
  invoiceStatus:invoiceStatusReducer,
  alert : alertReducer,
  kycStatus :kycStatusReducer,
  pool : poolReducer,
  coin : coinReducer,
  invoiceOffer:invoiceOfferReducer,
  invoiceFinalOffer:invoiceFinalOfferReducer,
  sc:scReducer,
  pricing:pricingReducer,
});

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsBlacklist, actionsCreators and other options
});

const configureStore = () => {
  return createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
};

export default configureStore;


import React from "react";
import _isEmpty from "lodash/isEmpty";
import "bootstrap/dist/css/bootstrap.min.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router-dom";
import { logoutBE } from "../../api/authenticate";
import { toast } from "react-toastify";


class MainHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadData: true,
      username: "",
      userId: "",
      userEmail: "",
      profileName: "",
    };
  }

  getProfileName = (name) => {
    if (name) {
      var parts = name.split(" ");

      var initials = "";
      for (var i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }

      this.setState({ profileName: initials });
    }
   
  };

  componentDidMount() {
    const userEmail = sessionStorage.getItem("emailOfUser");
    const name = sessionStorage.getItem("uName");
    this.setState({ username: name });
    this.getProfileName(name);
  }

  logout =async  () => {
    let res = await logoutBE();
    if(res.data.success) {
      toast.info(res.data.message);
      sessionStorage.clear();
      this.props.history.push("/admin");
    }
  };

  render() {
    return (
      <div>
        <div className='user-wrapper'>
          <div className='profileInitials '>{this.state.profileName}</div>
          {/* <img src={Login} width="30px" height="30px" className="user-icon" alt="" /> */}
          <h4 className='text_tra1 user-name'>
            {this.state.username}
            <OverlayTrigger
              // delay={{ show: 250, hide: 400 }}
              trigger='click'
              placement='bottom'
              overlay={
                <Popover id={`popover-positioned-bottom`}>
                  <Popover.Content style={{ backgroundColor: "#333333" }}>
                    <Button
                      onClick={() => this.logout()}
                      style={{ backgroundColor: "#2DA3B4", margin: "10px" }}
                    >
                      Logout
                    </Button>
                  </Popover.Content>
                </Popover>
              }
            >
              <Button
                variant='secondary'
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <i className='uil uil-angle-down' />
              </Button>
            </OverlayTrigger>
          </h4>
        </div>
      </div>
    );
  }
}

export default withRouter(MainHeader);

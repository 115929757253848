import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import "../../../assets/css/dashboard.css";
import { NavLink, Link } from "react-router-dom";
import Logo from "../../../assets/images/product_logo.svg";
import support from "../../../assets/images/support.svg";
import _isEmpty from "lodash/isEmpty";
import "bootstrap/dist/css/bootstrap.min.css";
import { getEmailUser } from "../../../services/actions/userAction";
import { getCompanyByCompanyId } from "../../../services/actions/companyActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ZENDESK } from "../../../config/string_constants";


class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilled: false,
      loadData: true,
      companyId: "",
      isLoggedIn: sessionStorage.getItem("isLoggedIn"),
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_isEmpty(nextProps.user.userEmailData) && this.state.loadData) {
      if (
        nextProps.user.userEmailData[0].full_name === "" ||
        nextProps.user.userEmailData[0].designation === "" ||
        nextProps.user.userEmailData[0].phone_number === "" ||
        nextProps.user.userEmailData[0].country_id === ""
      ) {
        this.setState({
          isFilled: true,
          loadData: false,
        });
      }
      this.setState({ companyId: nextProps.user.userEmailData[0].company_id });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.userEmailData !== prevProps.user.userEmailData) {
      // if (this.state.companyId) {
      //   this.props.getCompanyByCompanyId(this.state.companyId);
      //   this.setState({
      //     loadData: true
      //   })
      // }
    }
  }

  componentDidMount() {
    const userEmail = sessionStorage.getItem("emailOfUser");
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.id = ZENDESK.ZENDESK_ID;
    script.src = ZENDESK.ZENDESK_LINK;
    if(window.innerWidth>650){
      document.body.appendChild(script);
    }
    // this.props.getEmailUser(userEmail);
  }
  render() {
    return (
      <div>
        <input type="checkbox" id="nav-toggle" />
        <div className="sidebar Lendersidebar">
          <div className="sidebar-brand">
            <h2 className="pt-sidebar-logosize ">
             <Link to='/lender/home'> <img src={Logo} alt="" /></Link>
            </h2>
          </div>
          <div className="sidebar-menu">
            <ul>
              <li className="dashboard">
                <NavLink
                  activeClassName="act"
                  style={{ textDecoration: "none" }}
                  to="/lender/home"
                  exact={true}
                >
                  <div className="row">
                    <div className="cols-md-1">
                      {" "}
                      <i className="uil icon-home side-icon mr-2 " />
                    </div>
                    <div className="cols-md-11">
                      {" "}
                      <span className="dash">Home</span>
                    </div>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="act"
                  style={{ textDecoration: "none" }}
                  to="/lender/calculator"
                  exact={true}
                >
                  <div className="row">
                    <div className="cols-md-1">
                      <i className="uil icon-calculator side-icon mr-2 " />
                    </div>
                    <div className="cols-md-11">
                      {" "}
                      <span>Calculator</span>
                    </div>
                  </div>
                </NavLink>
              </li>
              <li className="invoices-menu">
                <NavLink
                  activeClassName="act"
                  style={{ textDecoration: "none" }}
                  to="/lender/wallet"
                  exact={true}
                >
                  <div className="row">
                    <div className="cols-md-1">
                      {" "}
                      <i className="uil icon-wallet side-icon mr-2  " />
                    </div>
                    <div className="cols-md-11">
                      {" "}
                      <span>Wallet</span>
                    </div>
                  </div>
                </NavLink>
              </li>
              <li className="invoices-menu">
                <NavLink
                  activeClassName="act"
                  style={{ textDecoration: "none" }}
                  to="/lender/history"
                  exact={true}
                >
                  <div className="row">
                    <div className="cols-md-1">
                      {" "}
                      <i className="uil uil-clock side-icon mr-2" />
                    </div>
                    <div className="cols-md-11">
                      {" "}
                      <span>History</span>
                    </div>
                  </div>
                </NavLink>
              </li>

              <li className="profile-menu">
                <NavLink
                  activeClassName="act"
                  style={{ textDecoration: "none" }}
                  to={
                    this.state.isLoggedIn === "true"
                      ? `/lender/profile`
                      : `/lender/login`
                  }
                  exact={true}
                >
                  <div className="row">
                    <div className="cols-md-1">
                      {" "}
                      <i className="uil icon-profile side-icon mr-2  " />
                    </div>
                    <div className="cols-md-11">
                      {" "}
                      <span>Profile</span>
                    </div>
                  </div>
                  {/* <div className="cols-md-2">   <i className="uil icon-profile side-icon mr-2 " /></div>
                  <div className="cols-md-10">  <span> </span></div> */}
                </NavLink>
              </li>
            </ul>
          </div>

          {/* <div className="sidebar-menu support">
            <li className="  ">
              <NavLink
                disabled
                style={{ textDecoration: "none", marginLeft: "-16px" }}
                onClick={() => {
                  window.open("mailto:support@polytrade.finance");
                }}
                to=""
                target="_blank"
                exact={true}
              >
                <span>Support </span> <img src={support} />
              </NavLink>
            </li>
          </div> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  company: state.company,
});

const mapDispatchToProps = (dispatch) => ({
  getEmailUser: (data) => dispatch(getEmailUser(data)),
  getCompanyByCompanyId: (data) => dispatch(getCompanyByCompanyId(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import "../../../assets/css/dashboard.css";
import { NavLink, Link } from "react-router-dom";
import { Logo } from "../../../constant";
import "bootstrap/dist/css/bootstrap.min.css";
import { getEmailUser } from "../../../services/actions/userAction";
import { getCompanyByCompanyId } from "../../../services/actions/companyActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ZENDESK } from "../../../config/string_constants";
import { URLS } from '../../../config/frontendUrls';
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilled: false,
      loadData: true,
      companyId: "",
    };
  }

  componentDidMount() {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.id = ZENDESK.ZENDESK_ID;
    script.src = ZENDESK.ZENDESK_LINK;
    if (window.innerWidth > 650) {
      document.body.appendChild(script);
    }
  }
  render() {
    return (
      <div>
        <input type="checkbox" id="nav-toggle" />
        <div className="sidebar">
          <div className="sidebar-brand1">
            <h2 className="pt-sidebar-logosize">
              <Link to= {URLS.CLIENT.DASHBOARD}>
                <img src={Logo} width="120px" alt="" />
              </Link>
            </h2>
          </div>
          <div
            style={{ borderTop: "1px solid #A5A5A5" }}
            className="sidebar-menu"
          >
            <ul>
              <li className="dashboard">
                <NavLink
                  activeClassName="act"
                  style={{ textDecoration: "none" }}
                  to={URLS.CLIENT.DASHBOARD}
                  exact={true}
                >
                  <i className="uil uil-create-dashboard" />
                  <span className="dash">Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="act"
                  style={{ textDecoration: "none" }}
                  to={URLS.CLIENT.BUYER_LIST}
                  exact={true}
                >
                  <i className="uil uil-users-alt" />
                  <span>Buyers</span>
                </NavLink>
              </li>
              <li className="invoices-menu">
                <NavLink
                  activeClassName="act"
                  style={{ textDecoration: "none" }}
                  to={URLS.CLIENT.INVOICES}
                  exact={true}
                >
                  <i className="uil uil-receipt-alt" />
                  <span>Invoices</span>
                </NavLink>
              </li>
              <li className="profile-menu">
                <NavLink
                  activeClassName="act"
                  style={{ textDecoration: "none" }}
                  to={URLS.CLIENT.PROFILE_SECTION}
                  exact={true}
                >
                  <i className="uil uil-user-circle" />
                  <span>Profile </span>
                </NavLink>
                {/* {this.state.isFilled ? "(inComplete)":""} */}
              </li>
              <li className="invoices-menu">
                <NavLink
                  activeClassName="act"
                  style={{ textDecoration: "none" }}
                  to={URLS.CLIENT.REFERRAL}
                  exact={true}
                >
                  <i className="uil-user-plus" />
                  <span>Referral</span>
                </NavLink>
              </li>
              <li className="invoices-menu">
                <NavLink
                  activeClassName="act"
                  style={{ textDecoration: "none" }}
                  to={URLS.CLIENT.WALLET}
                  exact={true}
                >
                  <i className="uil uil-wallet" />
                  <span>Wallet</span>
                </NavLink>
              </li>
            </ul>
          </div>

          {/* <div className="sidebar-menu support">
          <li>
            <NavLink activeClassName="act" style={{ textDecoration: "none", marginLeft: "-16px" }} to='/support' exact={true}>
              <span>Support </span>          <i className='uil-external-link-alt' />
            </NavLink>
          </li>
        </div> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  company: state.company,
});

const mapDispatchToProps = (dispatch) => ({
  getEmailUser: (data) => dispatch(getEmailUser(data)),
  getCompanyByCompanyId: (data) => dispatch(getCompanyByCompanyId(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));

import React, { Component } from "react";

import helper from "../../../utils/helper";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import preview from "../../../assets/images/preview.png";
import download from "../../../assets/images/download1.png";
import moment from "moment";
import {
  getInvoiceDetailsById,
  getAllInvoices,
  getInvoiceChanges,
  getInvoiceCreatedByUser,
} from "../../../services/actions/invoiceAction";
import { getCompanyByCompanyId } from "../../../services/actions/companyActions";
import { updateInvoice } from "../../../services/actions/invoiceAction";
import { getInvoiceStatus } from "../../../services/actions/invoiceStatusAction";
import {
  postOffer,
  getOfferById,
} from "../../../services/actions/invoiceOfferAction";
import { postAlert } from "../../../services/actions/alertActions";
import Modal from "react-bootstrap/Modal";
import InvoiceMoreDetail from "./invoiceMoreDetail";
import "react-toastify/dist/ReactToastify.css";
import { URI } from "../../../constant";
import trash from "../../../assets/images/trash.png";
import upload from "../../../assets/images/upload.png";
import Progressbar from "./progressBar";
import InvoicesListId from "./invoicesListId";
import FinalOfferDetails from "./finalOfferDetails";
import Header from "./header";
import ajax from "../../../utils/ajax";
import {
  INVOICE_STATUSES_KEY,
  CLIENT_ALERT_TYPES,
} from "../../../config/string_constants";
import {
  approveInvoiceByAdmin,
  rejectInvoiceByAdmin,
} from "../../../api/invoiceService";
import { approveFormat, getInvoiceForAdmin } from "../../../api/invoiceService";
import BuyerDetails from "./buyerDetails";
import SoftOffer from "./softOfferDetails";
import InvoiceData from "./invoiceData";
import PricingDetails from "./pricingDetails";
import { HELPERS } from "../../../helpers/index";
import ApproveInvoice from "./approveInvoice";
import DeclineInvoice from "./declineInvoice";
class InvoiceVerificationByBuyer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeId: "",
      Unlock: false,
      generateOffer: false,
      loader: false,
      fileError: "",
      previewOffer: false,
      invoiceId: this.props.match.params.id,
      invoicedetails: "",
      invoiceOffer: [],
      invoices: [],
      invoiceCompany: [],
      company_id: "",
      showPdf: false,
      pdfUrl: null,
      selected: false,
      articleId: "",
      locked: true,
      verifyStatus: "",
      verifySubstatus: "",
      alertData: {
        invoice_id: "",
        invoice_ref_no: "",
        invoice_status_id: "",
        invoice_substatus_id: "",
        client_Id: "",
      },
      updateSuccess: false,
      alertSuccess: false,
      screen: INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER,
      approvalFormatWindow: false,
      finaloffer: [],
      showOffer: false,
      finaloffer: [],
      showBuyer: false,
      buyerCompany: "",
      currentStatus: "",
      pricingData: [],
    };
  }

  componentDidMount() {
    this.props
      .getInvoiceDetailsById(this.state.invoiceId)
      .then((result) => {
        this.props.getInvoiceChanges(result);
        this.setState({
          invoicedetails: result,
          company_id: result.buyer_company._id,
          selected: true,
          articleId: result._id,
          finaloffer: result.final_offer,
          buyerCompany: result.buyer_company,
          invoiceCompany: result.client_company,
        });
        getInvoiceForAdmin(result.created_by_id)
          .then((invoices) => {
            //Get company details of particular invoice
            this.setState({
              invoices: invoices.data,
            });
          })
          .catch((err) => {});
        this.props
          .getOfferById(result._id)
          .then((offer) => {
            this.setState({
              invoiceOffer: offer,
              tenure: offer.tenure,
            });
          })
          .catch((err) => {});
        //calling Helper function to getPricingDetails
        HELPERS.getPricingDetails(this.state.finaloffer.pricing_id)
          .then((pricing) => {
            this.setState({ pricingData: pricing });
          })
          .catch((err) => {});
      })
      .catch((err) => {});

    //Get Invoice Status
    for (let j = 0; j < this.props.invoiceStatus.length; j++) {
      if (
        this.props.invoiceStatus[j].key ===
        INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER
      ) {
        for (let i = 0; i < this.props.invoiceStatus[j].subStatus.length; i++) {
          if (this.props.invoiceStatus[j].subStatus[i].statusName === "Done") {
            this.setState({
              invoicestatus: this.props.invoiceStatus[j]._id,
              verifyStatus: this.props.invoiceStatus[j]._id,
              verifySubstatus: this.props.invoiceStatus[j].subStatus[i]._id,
            });
          }
        }
      }
    }
  }

  showData = (item) => {
    this.setState(
      {
        invoiceId: item._id,
        selected: true,
        articleId: item._id,
        invoiceCompany: item.client_company,
      },
      () =>
        this.props
          .getInvoiceDetailsById(this.state.invoiceId)
          .then((result) => {
            this.setState({
              invoicedetails: result,
              selected: true,
              articleId: result._id,
              finaloffer: result.final_offer,
              buyerCompany: result.buyer_company,
            });
          })
          .catch((err) => {})
    );
  };

  onPdfShow = (url) => {
    this.setState({
      showPdf: true,
      pdfUrl: url,
    });
  };

  onOfferShow = () => {
    this.setState({
      showOffer: true,
    });
  };

  handleClose = () => {
    this.setState({ showPdf: false });
  };

  handleOfferClose = () => {
    this.setState({ showOffer: false });
  };

  fileChange = (e, item) => {
    if (helper.validateFileType(e.target.files)) {
      this.uploadFile(e.target.files[0], e.target.name, item);
    } else {
      this.setState({
        loader: false,
        fileError: "Please Upload file in JPG, PNG, PDF and max size 5mb.",
      });
    }
  };
  uploadFile(file, name, item) {
    this.setState({ loader: true });
    const formData = new FormData();
    formData.append("image", file);
    if (file !== undefined) {
      ajax(
        "POST",
        `${URI}/api/v1/image-upload`,
        {},
        { "content-type": "multipart/form-data" },
        formData
      )
        .then((data) => {
          item[name] = data.data.imageUrl;
          // item.signed_document = data.data.imageUrl;

          this.setState(
            {
              updateSuccess: true,
              loader: false,
              fileError: "",
            },
            () => {
              this.props
                .updateInvoice(item)
                .then((invoice) => {
                  toast.info("Document Uploaded");
                  this.setState({
                    updateSuccess: false,
                    invoiceId: invoice._id,
                  });
                })
                .catch((err) => {});
            }
          );
        })
        .catch((err) => {});
    }
  }

  deleteSignedDocument = (data) => {
    data.signed_document = null;

    this.setState(
      {
        updateSuccess: true,
      },
      () => {
        this.props.updateInvoice(data).then((result) => {
          toast.info("Signed Document Deleted");
          this.setState({
            updateSuccess: false,
            invoiceId: result.invoice_id,
          });
        });
      }
    );
  };
  deleteReferenceDocument = (data) => {
    data.signed_reference_document = null;

    this.setState(
      {
        updateSuccess: true,
      },
      () => {
        this.props.updateInvoice(data).then((result) => {
          toast.info("Reference Document Deleted");
          this.setState({
            updateSuccess: false,
            invoiceId: result.invoice_id,
          });
        });
      }
    );
  };

  digitalSignature = (d) => {
    let data = {
      approval_format: "Digital Signature",
      invoiceStatus_id: this.state.verifyStatus,
      invoiceSubStatus_id: this.state.verifySubstatus,
      _id: d._id,
    };

    this.setState(
      {
        updateSuccess: true,
      },
      () => {
        approveFormat(data)
          .then((result) => {
            getInvoiceForAdmin(d.created_by_id)
              .then((invoices) => {
                //Get company details of particular invoice
                toast.info("Document Approved using Digital Signature Method");
                this.setState({
                  invoices: invoices.data,
                });
              })
              .catch((err) => {});
            this.setState({
              updateSuccess: false,
            });
          })
          .catch((err) => {});
      }
    );
  };

  offlineDocument = (d) => {
    let data = {
      approval_format: "Offline Document",
      invoiceStatus_id: this.state.verifyStatus,
      invoiceSubStatus_id: this.state.verifySubstatus,
      _id: d._id,
    };
    this.setState(
      {
        updateSuccess: true,
      },
      () => {
        approveFormat(data)
          .then((result) => {
            getInvoiceForAdmin(d.created_by_id)
              .then((invoices) => {
                //Get company details of particular invoice
                toast.info("Document Approved using Offline Document Method");
                this.setState({
                  invoices: invoices.data,
                });
              })
              .catch((err) => {});
            this.setState({
              updateSuccess: false,
            });
          })
          .catch((err) => {});
      }
    );
  };

  render() {
    return (
      <div>
        <Header />
        <div className="row m-4">
          <div className="col-3">
            <InvoiceMoreDetail
              invoicedetails={this.state.invoicedetails}
              invoiceCompany={this.state.invoiceCompany}
            />
          </div>
          <div className="col-9 overflow-auto">
            <Progressbar
              invoiceId={this.state.invoiceId}
              screen={this.state.screen}
            />
          </div>
        </div>
        {!this.state.approvalFormatWindow ? (
          <div className="row m-2 my-4">
            <div className="col-6">
              <div className="info-one h-100">
                <div className="tab-content">
                  <div className="tab-pane active" id="tabs-1" role="tabpanel">
                    {this.state.invoices === undefined ? (
                      <div>No data Available </div>
                    ) : (
                      this.state.invoices
                        .filter((item) => {
                          return (
                            item.invoice_id ===
                            this.state.invoicedetails.invoice_id
                          );
                        })
                        .map((item, index) => (
                          <div key={index}>
                            <InvoiceData
                              invoices={item}
                              tenure={this.state.tenure}
                              onPdfShow={this.onPdfShow}
                            />
                            <p className="text_tra1 mt-4">
                              Upload Signed document from Buyer
                            </p>
                            <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                              <h5>
                                <img alt="upload" src={upload} /> Upload Invoice
                              </h5>
                              <h5 className="text-white">
                                {item.signed_document ? (
                                  <span>
                                    <a
                                      href
                                      onClick={() =>
                                        this.onPdfShow(item.signed_document)
                                      }
                                    >
                                      <img
                                        alt="preview"
                                        className="mx-3"
                                        src={preview}
                                      />
                                    </a>
                                    <a
                                      href={item.signed_document}
                                      style={{ color: "white" }}
                                    >
                                      {" "}
                                      <img
                                        alt="download"
                                        className="mx-3"
                                        src={download}
                                      />
                                    </a>
                                    <span>
                                      {" "}
                                      <a
                                        onClick={() =>
                                          this.deleteSignedDocument(item)
                                        }
                                        name="signed_document"
                                        href={item.signed_document}
                                        style={{ color: "white" }}
                                      >
                                        {" "}
                                        <img
                                          alt="delete"
                                          className="mx-3"
                                          src={trash}
                                        />
                                      </a>
                                    </span>{" "}
                                  </span>
                                ) : (
                                  <span>
                                    {" "}
                                    <label className="uploadb header-input mx-3">
                                      {this.state.loader ? (
                                        <div className="spinner-border btn--icon"></div>
                                      ) : (
                                        "Upload"
                                      )}
                                      <input
                                        type="file"
                                        style={{
                                          display:
                                            "btn btn-outline-primary col_erone",
                                        }}
                                        name="signed_document"
                                        onChange={(e) =>
                                          this.fileChange(e, item)
                                        }
                                      />
                                    </label>
                                    {this.state.fileError && (
                                      <div className="text-danger">
                                        {this.state.fileError}
                                      </div>
                                    )}
                                  </span>
                                )}
                              </h5>
                            </div>
                            <div></div>
                            {item.signed_document &&
                            (item.approval_format === "Digital Signature" ||
                              item.approval_format === "Offline Document") ? (
                              <>
                                <div className="mt-5 text-center">
                                  <DeclineInvoice
                                    invoice={item}
                                    toastMessage="Invoice Declined"
                                    updateType={
                                      CLIENT_ALERT_TYPES.INVOICE_VERIFICATION_BY_BUYER_APPROVED
                                    }
                                    invoiceStatus={this.props.invoiceStatus}
                                    currentStatusKey={
                                      INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER
                                    }
                                  />
                                  <ApproveInvoice
                                    invoice={item}
                                    nextStatusRoute="advance-fund-allocation"
                                    updateType={
                                      CLIENT_ALERT_TYPES.INVOICE_VERIFICATION_BY_BUYER_APPROVED
                                    }
                                    toastMessage="Invoice Updated"
                                    invoiceStatus={this.props.invoiceStatus}
                                    nextStatusKey={
                                      INVOICE_STATUSES_KEY.ADVANCED_FUND_ALLOCATION
                                    }
                                    currentStatusKey={
                                      INVOICE_STATUSES_KEY.INVOICE_VERIFICATION_BY_BUYER
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        ))
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="info-one h-100">
                <p className="text_tra1 mb-2">INVOICES</p>
                <InvoicesListId
                  invoices={this.state.invoices}
                  selected={this.state.selected}
                  showData={this.showData}
                  itemId={this.state.articleId}
                />

                {this.state.invoices === undefined ? (
                  <div>No data Available </div>
                ) : (
                  this.state.invoices
                    .filter((item) => {
                      return (
                        item.invoice_id === this.state.invoicedetails.invoice_id
                      );
                    })
                    .map((item, index) => (
                      <div
                        key={index}
                        style={{ marginTop: "1rem" }}
                        className="p-3  "
                      >
                        <div className=" mt-4 bgc-dark  text-white">
                          <div className="d-flex p-3 justify-content-between">
                            <div>
                              Approval Format{" "}
                              {item.approval_format === "Digital Signature" ||
                              item.approval_format === "Offline Document" ? (
                                <span className="txt_g">Verified</span>
                              ) : (
                                <span className="txt_y">Pending</span>
                              )}
                            </div>
                            <div
                              className="txt_b curserP"
                              onClick={() =>
                                this.setState({ approvalFormatWindow: true })
                              }
                            >
                              View More
                            </div>
                          </div>
                        </div>
                        <div className="bgc-dark mt-2 text-white">
                          <div className="d-flex p-3 justify-content-between">
                            <div>Arpa</div>
                            <a
                              href
                              onClick={() =>
                                this.onPdfShow(item.invoice_pdf_link)
                              }
                            >
                              <img alt="preview" src={preview} />
                              view
                            </a>
                          </div>
                        </div>
                        {this.state.invoicedetails ? (
                          <FinalOfferDetails
                            invoiceId={this.state.invoiceId}
                            invoicedetails={this.state.invoicedetails}
                            tenure={this.state.tenure}
                          />
                        ) : (
                          ""
                        )}

                        <PricingDetails
                          pricingData={this.state.pricingData}
                          show={false}
                        />
                        <BuyerDetails buyerDetails={this.state.buyerCompany} />
                        <SoftOffer
                          invoiceOffer={this.state.invoiceOffer}
                          show={false}
                        />
                      </div>
                    ))
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="row m-2 my-4">
            <div className="col-6">
              <div className="info-one h-100">
                <div className="tab-content">
                  <div className="tab-pane active" id="tabs-1" role="tabpanel">
                    <p className="text_tra1 mb-2">Approval Format</p>
                    {this.state.invoices === undefined ? (
                      <div>No data Available </div>
                    ) : (
                      this.state.invoices
                        .filter((item) => {
                          return (
                            item.invoice_id ===
                            this.state.invoicedetails.invoice_id
                          );
                        })
                        .map((item, index) => (
                          <div key={index}>
                            {/* <p className='text_tra1 mt-4'>Upload Signed document from Buyer</p> */}
                            {item.approval_format ? (
                              item.approval_format === "Digital Signature" ? (
                                <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                                  <h5>Digital Signature</h5>
                                  <button className="btn-blue mx-2">
                                    Approved
                                  </button>
                                </div>
                              ) : (
                                <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                                  <h5>Signed Offline Document</h5>
                                  <button className="btn-blue mx-2">
                                    Approved
                                  </button>
                                </div>
                              )
                            ) : (
                              <div>
                                <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                                  <h5>Digital Signature</h5>
                                  <button
                                    className="btn-blue mx-2"
                                    onClick={() => this.digitalSignature(item)}
                                  >
                                    Approve
                                  </button>
                                </div>
                                <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                                  <h5>Signed Offline Document</h5>
                                  <button
                                    className="btn-blue mx-2"
                                    onClick={() => this.offlineDocument(item)}
                                  >
                                    Approve
                                  </button>
                                </div>
                              </div>
                            )}
                            <div></div>
                            <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                              <p>
                                <img alt="upload" src={upload} /> Click here to
                                upload reference document
                              </p>

                              {item.signed_reference_document ? (
                                <div className="d-flex justify-content-between">
                                  <span
                                    onClick={() =>
                                      this.onPdfShow(
                                        item.signed_reference_document
                                      )
                                    }
                                  >
                                    <img
                                      alt="preview"
                                      className="mx-3"
                                      src={preview}
                                    />
                                  </span>
                                  <a
                                    href={item.signed_reference_document}
                                    style={{ color: "white" }}
                                  >
                                    {" "}
                                    <img
                                      alt="download"
                                      className="mx-3"
                                      src={download}
                                    />
                                  </a>
                                  <span
                                    style={{ color: "white" }}
                                    onClick={() =>
                                      this.deleteReferenceDocument(item)
                                    }
                                  >
                                    {" "}
                                    <img
                                      alt="delete"
                                      className="mx-3"
                                      src={trash}
                                    />
                                  </span>
                                </div>
                              ) : (
                                <div>
                                  <label className="uploadb header-input mx-3">
                                    {this.state.loader ? (
                                      <div className="spinner-border btn--icon"></div>
                                    ) : (
                                      "Upload"
                                    )}
                                    <input
                                      type="file"
                                      style={{
                                        display:
                                          "btn btn-outline-primary col_erone",
                                      }}
                                      name="signed_reference_document"
                                      onChange={(e) => this.fileChange(e, item)}
                                    />
                                  </label>
                                  {this.state.fileError && (
                                    <div className="text-danger">
                                      {this.state.fileError}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        ))
                    )}
                    <div className="text-center mt-3">
                      <span
                        className="uploadb curserP"
                        onClick={() =>
                          this.setState({ approvalFormatWindow: false })
                        }
                      >
                        Back
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="info-one h-100">
                <p className="text_tra1 mb-2">INVOICE DETAILS</p>
                {this.state.invoices === undefined ? (
                  <div>No data Available </div>
                ) : (
                  this.state.invoices
                    .filter((item) => {
                      return item.invoice_id === this.state.invoiceId;
                    })
                    .map((item, index) => (
                      <div key={index}>
                        <div className="row mt-4  text_tra1">
                          <div className="col-4">
                            Invoice Amount{" "}
                            <h5 className="text-white">
                              {item.currency}{" "}
                              {Number(item.invoice_amount)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </h5>
                          </div>
                          <div className="col-4">
                            Available Limit{" "}
                            <h5 className="text-white">
                              {item.currency}{" "}
                              {Number(item.invoice_limit)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </h5>
                          </div>
                          <div className="col-4">
                            Supplier Invoice Number{" "}
                            <h5 className="text-white">
                              {item.supplier_invoice_number}
                            </h5>
                          </div>
                        </div>
                        <div className="row mt-4 j text_tra1">
                          <div className="col-4">
                            Invoice Date{" "}
                            <h5 className="text-white">
                              {moment(item.invoice_date).format("DD MMM YYYY")}
                            </h5>
                          </div>
                          <div className="col-4">
                            Due Date
                            <h5 className="text-white">
                              {moment(item.invoice_due_date).format(
                                "DD MMM YYYY"
                              )}
                            </h5>
                          </div>
                          <div className="col-4">
                            Invoice
                            <h5 className="text-white">
                              <p>
                                <a
                                  href
                                  onClick={() =>
                                    this.onPdfShow(item.invoice_pdf_link)
                                  }
                                >
                                  <img alt="preview" src={preview} />
                                </a>
                                <a
                                  href={item.invoice_pdf_link}
                                  style={{ color: "white" }}
                                >
                                  {" "}
                                  <img alt="download" src={download} />
                                </a>
                              </p>
                            </h5>
                          </div>
                        </div>
                        <div className="row mt-4 j text_tra1">
                          <div className="col-4">
                            Tenure
                            <h5 className="text-white">
                              {moment(item.invoice_due_date).diff(
                                moment(item.invoice_date),
                                "days"
                              )}{" "}
                              Days
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))
                )}
                {this.state.invoicedetails ? (
                  <FinalOfferDetails
                    invoiceId={this.state.invoiceId}
                    invoicedetails={this.state.invoicedetails}
                    tenure={this.state.tenure}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}

        <Modal show={this.state.showPdf} onHide={this.handleClose}>
          <div style={{ backgroundColor: "#333333", color: "white" }}>
            {this.state.pdfUrl !== null ? (
              this.state.pdfUrl.split(".").pop() === "pdf" ||
              this.state.pdfUrl.split(".").pop() === "PDF" ? (
                <iframe
                  src={`https://docs.google.com/gview?url=${this.state.pdfUrl}&embedded=true`}
                  style={{ width: "50rem", height: "50rem" }}
                  frameBorder="0"
                ></iframe>
              ) : (
                <>
                  <img
                    alt="pdf url"
                    src={this.state.pdfUrl}
                    style={{ width: "30rem", height: "30rem" }}
                  />
                </>
              )
            ) : (
              <div></div>
            )}
          </div>
        </Modal>

        <Modal show={this.state.showOffer} onHide={this.handleOfferClose}>
          <div style={{ backgroundColor: "#333333", color: "white" }}>
            {this.state.finaloffer ? (
              <div style={{ marginTop: "1rem" }} className="p-3  ">
                {this.state.invoicedetails ? (
                  <FinalOfferDetails
                    invoiceId={this.state.invoiceId}
                    invoicedetails={this.state.invoicedetails}
                    tenure={this.state.tenure}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  invoiceStatus: state.invoiceStatus.status,
  alert: state.alert,
  company: state.company,
  invoiceOffer: state.invoiceOffer,
  invoiceFinalOffer: state.invoiceFinalOffer,
  pricing: state.pricing,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  getInvoiceChanges: (data) => dispatch(getInvoiceChanges(data)),
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  postAlert: (data) => dispatch(postAlert(data)),
  getCompanyByCompanyId: (data) => dispatch(getCompanyByCompanyId(data)),
  getAllInvoices: (data) => dispatch(getAllInvoices(data)),
  postOffer: (data) => dispatch(postOffer(data)),
  getInvoiceCreatedByUser: (data) => dispatch(getInvoiceCreatedByUser(data)),
  getOfferById: (data) => dispatch(getOfferById(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvoiceVerificationByBuyer));

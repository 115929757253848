import {URI} from '../../constant'

export const HOME_GET_ACTIVITY_CONSTANTS = ({
    URL: URI+"/activity/",
    REQUEST: 'REQUEST_GET_ACTIVITY_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_GET_ACTIVITY_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_GET_ACTIVITY_DATA_CONSTANTS',
  });

  export const HOME_POST_ACTIVITY_CONSTANTS = ({
    URL: URI+"/activity",
    REQUEST: 'REQUEST_POST_ACTIVITY_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_POST_ACTIVITY_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_POST_ACTIVITY_DATA_CONSTANTS',
  });
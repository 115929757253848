import * as ACTIVITY_CONSTANTS from "../constants/activityConstant";
import ajax from "../../utils/ajax";

export const requestGetActivity = () => ({
  type: ACTIVITY_CONSTANTS.HOME_GET_ACTIVITY_CONSTANTS.REQUEST,
});

export const recievedGetActivityResponse = (data) => ({
  type: ACTIVITY_CONSTANTS.HOME_GET_ACTIVITY_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetActivityError = (err) => ({
  type: ACTIVITY_CONSTANTS.HOME_GET_ACTIVITY_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getActivity = (email) => async (dispatch) => {
  dispatch(requestGetActivity());
  try {
    let url = ACTIVITY_CONSTANTS.HOME_GET_ACTIVITY_CONSTANTS.URL + email;

    const res = await ajax("GET", url, {});
    dispatch(recievedGetActivityResponse(res.data));

    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(recievedGetActivityError(err));
    return Promise.reject(err);
  }
};

export const requestPostActivity = () => ({
  type: ACTIVITY_CONSTANTS.HOME_POST_ACTIVITY_CONSTANTS.REQUEST,
});

export const recievedPostActivityResponse = (data) => ({
  type: ACTIVITY_CONSTANTS.HOME_POST_ACTIVITY_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedPostActivityError = (err) => ({
  type: ACTIVITY_CONSTANTS.HOME_POST_ACTIVITY_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const postActivity = (data) => async (dispatch) => {
  dispatch(requestPostActivity());
  try {
    let url = ACTIVITY_CONSTANTS.HOME_POST_ACTIVITY_CONSTANTS.URL;
    const res = await ajax("POST", url, {}, {}, data);
    if (res) {
      dispatch(recievedPostActivityResponse(res.data));

      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedPostActivityError(err));
    return Promise.reject(err);
  }
};

import react from "react";
// import styles from 'src/assets/css/OnboardingPersonalDetails.module.css';
import Logo from '../../assets/images/Logo.svg';
import styles from '../../assets/css/OnboardingPersonalDetails.module.css';
import "../../Global.css";
import React, { Fragment } from "react";


import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

export default function onboard() {
  return (
    <>
    <nav className="navbar navbar-light Navbar">
  <a className="navbar-brand" href="#">

  <img className={styles.Logo} src={Logo} alt="" />
  </a>
</nav>

   <Router>
  
    <div className="container-fluid main">
    <div className="row">
    <div className="col-md-1"></div>
    <div className="col-md-10">
     
        <div className="row">
          <div className="col-md-4 text-center pt-3 pl-5"><p className="pl-4"><Link className="StatusItem" to="/">01</Link></p><p className="align">Personal Detail</p></div>
          <div className="col-md-4 text-center pt-3 pl-5"><p className="pl-4"><Link  className="StatusItem" to="/about">02</Link></p><p className="align">Company Detail</p></div>
          <div className="col-md-4 text-center pt-3 pl-5"><p className="pl-4"><Link className="StatusItem" to="/contact">03</Link></p><p className="align">Company Address</p></div>
        </div>
     
      </div>
      <div className="col-md-1"></div>
      </div>
      </div>
      <Switch>
    <Route exact path="/" Active ><Home/></Route>
    <Route path="/about"  component={About} />
    <Route path="/contact"  component={Contact} />
    </Switch>
   
</Router>
</>
  );
}

const Home = () => (
  <>

    {/*  */}
    <div className="container-fluid bg_clo">
    <div className="row">
    <div className="col-md-1"></div>
    <div className="col-md-10">

      <div className="container">
        <div className="row">
          <div className="col-md-2 text-center pt-3"></div>
          <div className="col-md-8 text-center pt-3"><h3 className="text_color">Tell as a Bit About Your Self</h3>
    <h5 className="text_color ">Tell as a Bit About Your Self</h5>
    {/* input box */}
    <div className="row">
    <div className="col-md-2"></div>
    <div className="col-md-8">
    <div className="  mb-3 text-left">
                              <label className="form-label text_color">Full name</label>
                              <input type="text" className="form-control input_bg" name="example-text-input" placeholder="Input placeholder"/>
                            </div>
                            </div>
                            <div className="col-md-2"></div>
                         </div>
                          
                         

                         <div className="row">
    <div className="col-md-2"></div>
    <div className="col-md-8">
    <div className="  mb-3 text-left">
                              <label className="form-label text_color">Designation</label>
                              <input type="text" className="form-control input_bg" name="example-text-input" placeholder="Input placeholder"/>
                            </div>
                            </div>
                            <div className="col-md-2"></div>
                         </div>
                         {/*  */}
                         <div className="row">
    <div className="col-md-2"></div>
    <div className="col-md-8">
    <div className="  mb-3 text-left">
                              <label className="form-label text_color">Phone no</label>
                              <div className="input-group">
                                <button type="button" className="btn dropdown-toggle input_bg" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                               <span className="text_act"> +91</span>
                                </button>
                                <div className="dropdown-menu">
                                  <a className="dropdown-item" href="#">
                                    Action
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    Another action
                                  </a>
                                </div>
                                <input type="text" className="form-control input_bg" aria-label="Text input with dropdown button"/>
                              </div>
                            </div>
                            </div>
                            <div className="col-md-2"></div>
                         </div>
                         {/*  */}
                         <div className="row row justify-content-center pt-3">
   
                         <div className="col-md-8 mb-3">
                        <a href="#" className="btn btn-primary w-100">
                          Primary
                        </a>
                      </div>
                         
                         </div>
                            </div>
          <div className="col-md-2 text-center pt-3"></div>
        </div>
      </div>
      
      </div>
      <div className="col-md-1"></div>
      </div>
      </div>
   
  </>
  );

const About = () => (
  <div>
    <h1> Page 2</h1>
  
  </div>
  );

const Contact = () => (
  <div>
    <h1 className="col_er">page 3 </h1>
   
  </div>
  );

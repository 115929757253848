import React, { Component } from "react";
import Sidebar from "./Dashboard/Sidebar";
import check from "../../assets/images/check1.png";
import MainHeader from "./Dashboard/mainHeader";
import { stubFalse } from "lodash";
import { getCountry } from "../../services/actions/countryActions";
import {
  postReferral,
  getReferralsById,
} from "../../services/actions/referralAction";
import { postActivity } from "../../services/actions/activityActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Mob_header from "./Dashboard/mob_header";
import Bottomnav from "./Dashboard/bottomnav";
import comingSoon from "../../assets/images/ComingSoon.svg";
class Referral extends Component {
  constructor(props) {
    super(props);

    this.state = {
      personal_details: false,
      referral: {
        full_name: "",
        designation: "",
        email: "",
        dialCode: "",
        phone_number: "",
        company_name: "",
        company_address: "",
        note: "",
        createdById: sessionStorage.getItem("userId"),
        createdByEmail: sessionStorage.getItem("uEmail"),
      },
      country: [],
      contact_details: false,
      addSucess: false,

      dirty: {
        full_name: false,
        designation: false,
        email: false,
        dialCode: false,
        phone_number: false,
        company_name: false,
        company_address: false,
        note: false,
      },

      errors: {
        full_name: [],
        designation: [],
        phone_number: [],
        email: [],
        dialCode: [],
        company_name: [],
        company_address: [],
        note: [],
      },
      activity: {
        activity_name: "Added A Referral",
        service_name: "addReferral",
        createdBy: {
          name: sessionStorage.getItem("uName"),
          email: sessionStorage.getItem("uEmail"),
          id: sessionStorage.getItem("userId"),
        },
      },
      showInviteModal: false,
      showInviteChannel: false,
      Invite: {
        email: "",
        dialCode: "",
        phone_number: "",
        link: "http://polytrade.s3-website.ap-south-1.amazonaws.com/#/Borrower/",
        invite_type: "direct",
        createdById: sessionStorage.getItem("userId"),
        createdByEmail: sessionStorage.getItem("uEmail"),
      },
      inviteErrors: { phone_number: [], email: [], dialCode: [] },
      inviteDirty: { email: false, dialCode: false, phone_number: false },
      referral_link: `http://polytrade.s3-website.ap-south-1.amazonaws.com/#/Borrower/?${sessionStorage.getItem(
        "uEmail"
      )}`,
    };
  }
  handleChange = (e) => {
    const { name, value } = e.target;

    let dirty = this.state.dirty;
    dirty[name] = true;

    let referral = this.state.referral;

    referral[name] = value;

    this.setState(
      {
        referral: referral,
        dirty: dirty,
      },
      this.validate
    );
  };

  blurChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };
  handlePhoneNumber = (e) => {
    const { name, value } = e.target;
    let newText = "";
    let cleanText = ("" + value).replace(/\D/g, "");
    for (var i = 0; i < cleanText.length; i++) {
      if (i == 0) {
        newText = "(";
      } else if (i == 3) {
        newText = newText + ")";
      } else if (i == 6) {
        newText = newText + "-";
      }
      newText = newText + cleanText[i];
    }
    let dirty = this.state.dirty;
    dirty[name] = true;

    let referral = this.state.referral;

    referral[name] = newText;

    this.setState(
      {
        referral: referral,
        dirty: dirty,
      },
      this.validate
    );
  };

  validate = () => {
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      errors[control] = [];
      //  var validPhone = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/
      var validEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      var validUrl =
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
      switch (control) {
        case "full_name":
          if (!this.state.referral[control]) {
            errors[control].push("Name can't be blank");
          }
          break;
        case "dialCode":
          if (!this.state.referral[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "designation":
          if (!this.state.referral[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "email":
          if (!this.state.referral[control]) {
            errors[control].push("This field is required");
          }
          if (this.state.referral[control]) {
            if (!validEmail.test(this.state.referral[control])) {
              errors[control].push("Please Enter valid Email Id");
            }
          }
          break;
        case "phone_number":
          if (!this.state.referral[control]) {
            errors[control].push("This field is required");
          }
          if (this.state.referral[control].length > 13) {
            errors[control].push("only 10 digit allowed");
          }
          break;

        case "company_name":
          if (!this.state.referral[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "note":
          if (!this.state.referral[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "company_address":
          if (!this.state.referral[control]) {
            errors[control].push("This field is required");
          }
          break;

        default:
          break;
      }
    });
    this.setState({ errors });
  };
  isValid = () => {
    let valid = true;

    for (let control in this.state.errors) {
      if (this.state.errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };
  handleInvitePhoneNumber = (e) => {
    const { name, value } = e.target;
    let newText = "";
    let cleanText = ("" + value).replace(/\D/g, "");
    for (var i = 0; i < cleanText.length; i++) {
      if (i == 0) {
        newText = "(";
      } else if (i == 3) {
        newText = newText + ")";
      } else if (i == 6) {
        newText = newText + "-";
      }
      newText = newText + cleanText[i];
    }
    let inviteDirty = this.state.inviteDirty;
    inviteDirty[name] = true;
    let Invite = this.state.Invite;

    Invite[name] = newText;

    this.setState(
      {
        Invite: Invite,
        inviteDirty: inviteDirty,
      },
      this.inviteValidate
    );
  };
  handleInviteChange = (e) => {
    const { name, value } = e.target;

    let inviteDirty = this.state.inviteDirty;
    inviteDirty[name] = true;

    let Invite = this.state.Invite;

    Invite[name] = value;

    this.setState(
      {
        Invite: Invite,
        inviteDirty: inviteDirty,
      },
      this.inviteValidate
    );
  };

  onInviteBlur = (e) => {
    const { name, value } = e.target;
    let inviteDirty = this.state.inviteDirty;
    inviteDirty[name] = true;
    this.setState({
      inviteDirty: inviteDirty,
    });
  };
  inviteValidate = () => {
    var inviteErrors = this.state.inviteErrors;
    Object.keys(inviteErrors).forEach((control) => {
      inviteErrors[control] = [];
      //  var validPhone = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/
      var validEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      switch (control) {
        case "dialCode":
          if (!this.state.Invite[control]) {
            inviteErrors[control].push("This field is required");
          }
          break;

        case "email":
          if (!this.state.Invite[control]) {
            inviteErrors[control].push("This field is required");
          }
          if (this.state.Invite[control]) {
            if (!validEmail.test(this.state.Invite[control])) {
              inviteErrors[control].push("Please Enter valid Email Id");
            }
          }
          break;
        case "phone_number":
          if (!this.state.Invite[control]) {
            inviteErrors[control].push("This field is required");
          }
          break;

        default:
          break;
      }
    });
    this.setState({ inviteErrors });
  };

  isInviteValid = () => {
    let valid = true;

    for (let control in this.state.inviteErrors) {
      if (this.state.inviteErrors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };

  urlInvite = () => {
    this.setState({
      showInviteModal: true,
    });
  };
  handleClose = () => {
    this.setState({
      showInviteModal: false,
    });
  };
  sendReferral = (e) => {
    e.preventDefault();
    this.validate();
    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });

    if (this.isValid()) {
      this.props.postReferral(this.state.referral);
      this.setState({
        addSucess: true,
      });
    }
  };

  sendInvite = (e) => {
    e.preventDefault();
    this.inviteValidate();
    var inviteDirty = this.state.inviteDirty;
    Object.keys(inviteDirty).forEach((control) => {
      inviteDirty[control] = true;
    });
    this.setState({ inviteDirty: inviteDirty });

    if (this.isInviteValid()) {
      this.props.postReferral(this.state.Invite);
      this.setState({
        addSucess: true,
      });
    }
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_isEmpty(nextProps.referral.postReferrals) && this.state.addSucess) {
      toast.info("Referral request sent Successfully");
      this.props.postActivity(this.state.activity);
      this.setState({
        addSucess: false,
        showInviteModal: false,
      });

      const { referral } = this.state;
      referral.full_name = "";
      referral.designation = "";
      referral.email = "";
      referral.phone_number = "";
      referral.company_name = "";
      referral.company_address = "";
      referral.note = "";
      referral.createdById = sessionStorage.getItem("userId");
      this.setState({
        referral,
      });
      const { Invite } = this.state;

      Invite.email = "";
      Invite.dialCode = "";
      Invite.phone_number = "";
      this.setState({
        Invite,
      });

      //  this.props.history.push("/dashboard")
    }
    if (!_isEmpty(nextProps.referral.referrals)) {
      // this.setState({
      //   referral_link: nextProps.referral.referrals[0].referral_link
      // })
    }
    if (!_isEmpty(nextProps.country.country)) {
      this.setState(
        {
          country: nextProps.country.country,
        },
        () => {}
      );
    }
  }

  componentDidMount() {
    // this.setState({showInviteChannel:true});
    this.props.getCountry();
    this.props.getReferralsById(sessionStorage.getItem("userId"));
    if (!_isEmpty(this.props.country.country)) {
      this.setState({ country: this.props.country.country });
    }
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Bottomnav />
        <div className="main-content">
          <Mob_header />

          <header className="header1">
            <h2 className="heading">Referral</h2>
            <MainHeader />
          </header>

          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <div className="text-white text-center mt-5">
            <img className="comingsoon" src={comingSoon} />
            <h2 className="mt-3">Coming Soon...</h2>
          </div>

          {/* <div>
              <div className='d-flex justify-content-end '>
               
      
        <span className='uploadb m-2 mr-4 p-2'  onClick={this.urlInvite}>Invite Through URL</span>

              </div>

          {(!this.state.personal_details) ? <div className=''>
            <ul className="newb_progress nav nav-pills d-flex justify-content-center pt-2" id="myTab" role="tablist">
                   
                   <li className="nav-item"><span className="nav-link active p-6px StatusItem"  >01 <div className='ntc mt-2 txt_b' style={{width:"7rem",marginLeft:"-3rem"}}>Personal Details</div></span></li>
               <div className="line_right1 mt-3"></div>
                   <li className="nav-item "><span className="nav-link StatusItem p-1 text_act" >02 <div className='ntc mt-2  text_act' style={{width:"7rem",marginLeft:"-3rem"}}>Add a Note </div></span></li>
                   
             
               </ul>
               <div className='mt-5 text-center'>
                   <h2 className='text-white'>Tell us a bit about your referral</h2>
                
               </div>

               <div className='cont_per' style={{marginTop:"30px"}}>
         <div className='row ref_details mx-auto mb-3'>
           <div className='col-sm-6 p-2'>
             <label className="label" htmlFor="">Full Name</label>
             <input className='input' name="full_name" type="text" value={this.state.referral.full_name} placeholder="Please Enter your Full name" onChange={this.handleChange} onBlur={this.blurChange} />
             <div className="text-danger">{this.state.errors.full_name[0] && this.state.dirty.full_name ? this.state.errors.full_name : ""}</div>
             </div>
           <div className='col-sm-6 p-2'>
            <label className="label" htmlFor="">Designation</label>
           <input className='input' name="designation" type="text" placeholder="Designation" value={this.state.referral.designation} onChange={this.handleChange} onBlur={this.blurChange} />
             <div className="text-danger">{this.state.errors.designation[0] && this.state.dirty.designation ? this.state.errors.designation : ""}</div>
             </div>
           <div className='col-sm-6 p-2'>
             <label className="label" htmlFor="">Contact Number</label>
             <div className="d-flex">
             <select style={{ "width":"60px","height":"44px"}} className="form-select" name="dialCode" value={this.state.referral.dialCode} onChange={this.handleChange}>
             <option></option>
                      {this.state.country.map(item =>(
                      <option  style={{ "background": "#212022", "color":"grey"}} key={item._id} value={item.dialCode}>+{item.dialCode}</option>
                        ))}
             </select>
             <input className='input' name="phone_number" type="text" placeholder="Enter with country code" value={this.state.referral.phone_number} onChange={this.handlePhoneNumber} onBlur={this.blurChange} maxLength="13"/>
             </div>  <div className="text-danger">{this.state.errors.phone_number[0] && this.state.dirty.phone_number ? this.state.errors.phone_number : ""}</div>
             <div className="text-danger">{this.state.errors.dialCode[0] && this.state.dirty.dialCode ? this.state.errors.dialCode : ""}</div>
              </div>
           <div className='col-sm-6 p-2'>
             <label className="label" htmlFor="">Email Address</label>
             <input className='input' type="text"  name="email" placeholder="email@example.com" value={this.state.referral.email} onChange={this.handleChange} onBlur={this.blurChange}/>
             <div className="text-danger">{this.state.errors.email[0] && this.state.dirty.email ? this.state.errors.email : ""}</div>
             </div>
             <div className='col-sm-6 p-2'>
             <label className="label" htmlFor="">Company Name</label>
             <input className='input' name="company_name" type="text" value={this.state.referral.company_name} placeholder="Enter your company name" onChange={this.handleChange} onBlur={this.blurChange} />
             <div className="text-danger">{this.state.errors.company_name[0] && this.state.dirty.company_name ? this.state.errors.company_name : ""}</div>
             </div>
             <div className='col-sm-6 p-2'>
             <label className="label" htmlFor="">Company Address</label>
             <input className='input' name="company_address" type="text" value={this.state.referral.company_address} placeholder="Enter your company address" onChange={this.handleChange} onBlur={this.blurChange} />
             <div className="text-danger">{this.state.errors.company_address[0] && this.state.dirty.company_address ? this.state.errors.company_address : ""}</div>
             </div>
         </div>
         <div className='text-center'>
         <button disabled={this.state.referral.full_name.length < 1 || this.state.referral.email.length <1 || this.state.referral.phone_number.length <1 || this.state.referral.dialCode.length <1 || this.state.referral.company_name.length < 1 || this.state.referral.designation.length < 1 || this.state.referral.company_address.length < 1}
           style={{backgroundColor: (this.state.referral.full_name.length < 1 || this.state.referral.email.length < 1 || this.state.referral.phone_number.length < 1 || this.state.referral.dialCode.length < 1  || this.state.referral.company_name.length < 1 || this.state.referral.designation.length < 1 || this.state.referral.company_address.length < 1 ) ? '#808080': '#4DB5C4',border:"none",color:"white"}}
            onClick={() => this.setState({personal_details:true})} 
             className="cont-btn">Continue</button>
        </div>
        
       </div>
          </div>:<div>
          <ul className="newb_progress nav nav-pills d-flex justify-content-center pt-2" id="myTab" role="tablist">
                   
                   <li className="nav-item"><span className="nav-link active p-1 StatusItem"  ><img src={check}/> <div className=' mt-2 ntc text-white' style={{width:"7rem",marginLeft:"-3rem"}}>Personal Details</div></span></li>
               <div className="Cline_left1 mt-3"></div>
                   <li className="nav-item "><span className="nav-link active p-6px StatusItem text_act" >02 <div className=' ntc mt-2 txt_b' style={{width:"7rem",marginLeft:"-3rem"}}>Add a Note</div></span></li>
                   
             
               </ul>    
               <div className='mt-5 text-center'>
                   <h2 className='text-white'>Add a Note</h2>
                   <p className='text_tra1'>Please write some notes about the company you refer </p>
               </div>
               <div style={{margin:"3rem 5rem auto 5rem"}}>
                    <textarea rows="4" cols="20" className="form-control input_bg " name="note" value={this.state.referral.note} onChange={this.handleChange}></textarea>
                    <div className="text-danger">{this.state.errors.note[0] && this.state.dirty.note ? this.state.errors.note : ""}</div>
                    </div>
                    <div className='text-center mt-4'>
         <button disabled={this.state.referral.note.length < 1}
           style={{backgroundColor: (this.state.referral.note.length < 1 ) ? '#808080': '#4DB5C4',border:"none",color:"white"}}
             className="cont-btn" onClick={this.sendReferral}>Send Referral Request</button>
        </div>
            </div>}
          </div> */}
        </div>

        <div
          style={{ overflowY: "auto" }}
          className={`menuNav ${this.state.showInviteModal ? "showMenu1" : ""}`}
        >
          <div
            style={{ backgroundColor: "#333333", color: "white", border: "0" }}
          >
            <div
              style={{ borderBottom: "1px solid gray" }}
              className="d-flex justify-content-between text-white p-3"
            >
              <h5>Send Invite Directly to Your Friends</h5>
              <h5
                style={{ cursor: "pointer" }}
                onClick={() => this.setState({ showInviteModal: false })}
              >
                <i className="uil-multiply"></i>
              </h5>
            </div>

            <div style={{ textAlign: "left" }}>
              <div style={{ margin: "24px" }}>
                <p className="text_tra1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor.
                </p>
                <div className="row invite">
                  <div className="col-sm-12">
                    <label className="label" htmlFor="">
                      Email Address
                    </label>
                    <div>
                      {" "}
                      <input
                        type="text"
                        className="input"
                        placeholder="Enter your Email address"
                        name="email"
                        onChange={this.handleInviteChange}
                        value={this.state.Invite.email}
                        onBlur={this.onInviteBlur}
                      />
                    </div>
                    <div className="text-danger">
                      {this.state.inviteErrors.email[0] &&
                      this.state.inviteDirty.email
                        ? this.state.inviteErrors.email
                        : ""}
                    </div>
                  </div>
                  <div className="col-sm-12 mt-2">
                    <label className="label" htmlFor="">
                      Contact Number
                    </label>
                    <div className="d-flex">
                      <select
                        style={{ width: "60px", height: "40px" }}
                        className="form-select"
                        id="dialCode"
                        name="dialCode"
                        value={this.state.Invite.dialCode}
                        onChange={this.handleInviteChange}
                        onBlur={this.onInviteBlur}
                      >
                        <option></option>
                        {this.state.country.map((item) => (
                          <option
                            style={{ background: "#212022", color: "grey" }}
                            key={item._id}
                            value={item.dialCode}
                          >
                            {item.dialCode}
                          </option>
                        ))}
                      </select>
                      <input
                        className="input"
                        type="text"
                        placeholder="Enter your contact number"
                        name="phone_number"
                        id="phone_number"
                        onChange={this.handleInvitePhoneNumber}
                        value={this.state.Invite.phone_number}
                        onBlur={this.onInviteBlur}
                        maxLength="13"
                      />
                    </div>
                    <div className="text-danger">
                      {this.state.inviteErrors.dialCode[0] &&
                      this.state.inviteDirty.dialCode
                        ? this.state.inviteErrors.dialCode
                        : ""}
                    </div>
                    <div className="text-danger">
                      {this.state.inviteErrors.phone_number[0] &&
                      this.state.inviteDirty.phone_number
                        ? this.state.inviteErrors.phone_number
                        : ""}
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ textAlign: "center" }}>
                <button
                  disabled={
                    this.state.Invite.email.length < 1 ||
                    this.state.Invite.phone_number.length < 1 ||
                    this.state.Invite.dialCode.length < 1
                  }
                  style={{
                    backgroundColor:
                      this.state.Invite.email.length < 1 ||
                      this.state.Invite.phone_number.length < 1 ||
                      this.state.Invite.dialCode.length < 1
                        ? "#3E6C73"
                        : "#4DB5C4",
                    border: "none",
                    color:
                      this.state.Invite.email.length < 1 ||
                      this.state.Invite.phone_number.length < 1 ||
                      this.state.Invite.dialCode.length < 1
                        ? "rgba(255, 255, 255, 0.32)"
                        : "white",
                  }}
                  onClick={this.sendInvite}
                  className="cont-btn w-50"
                >
                  Send Referral Link
                </button>
              </div>
              <div className="p-5">
                <div style={{ position: "absolute" }} className="d-flex ">
                  <div className="white_line mt-2"></div>
                  <div
                    style={{ position: "relative", marginTop: "-12px" }}
                    className="p-2 "
                  >
                    or
                  </div>
                  <div className="white_line mt-2"></div>
                </div>
              </div>
              <div className="mt-2 p-3">
                <h5>Invite through link</h5>
                <p className="text_tra1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor.
                </p>
                <div
                  style={{ borderRadius: "4px" }}
                  className="bgc-dark p-3 m-5"
                >
                  Refferal Link
                  <div className="d-flex">
                    <input
                      className="input mt-2"
                      type="text"
                      name="link"
                      value={this.state.referral_link}
                    ></input>
                    <button
                      style={{ width: "56px", margin: "10px 0px" }}
                      className="cont-btn"
                      onClick={() => {
                        navigator.clipboard.writeText(this.state.referral_link);
                      }}
                    >
                      Copy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showInviteChannel}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ backgroundColor: "transparent" }}
        >
          <div
            style={{ backgroundColor: "#333333", color: "white", border: "0" }}
          >
            <Modal.Body style={{ textAlign: "center" }}>
              <div
                style={{
                  margin: "0 auto",
                  width: "100%",
                  height: "100%",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                <div style={{ paddingTop: "35px" }}></div>
                <p className="text_tra1">
                  Invite your client through channel partner where you can have
                  more features.
                </p>
              </div>
              <div style={{ margin: "20px", alignItems: "center" }}>
                <button className="btn-blue w-75">
                  Invite through channel partner
                </button>
                <div>
                  <label
                    onClick={() => this.setState({ showInviteChannel: false })}
                    className="uploadb mt-2 w-75 p-2"
                  >
                    Later
                  </label>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  country: state.country,
  referral: state.referral,
  activity: state.activity,
});

const mapDispatchToProps = (dispatch) => ({
  getCountry: (data) => dispatch(getCountry(data)),
  postReferral: (data) => dispatch(postReferral(data)),
  postActivity: (data) => dispatch(postActivity(data)),
  getReferralsById: (data) => dispatch(getReferralsById(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Referral));

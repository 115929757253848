import * as KYC_STATUS_CONSTANTS from "../constants/kycStatusConstant";

export const kycStatusReducer = (state = {
    type: '',
    error: '',
    isFetching: false,
   status:undefined,
}, action) => {
   
    switch (action.type) {
                case KYC_STATUS_CONSTANTS.HOME_GET_KYC_STATUS_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    status: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case KYC_STATUS_CONSTANTS.HOME_GET_KYC_STATUS_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    status: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case KYC_STATUS_CONSTANTS.HOME_GET_KYC_STATUS_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });


        default:
            return state;
    }
};


export default kycStatusReducer;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import "react-toastify/dist/ReactToastify.css";
import {
  getInvoiceDetailsById,
  getInvoiceChanges,
} from "../../../services/actions/invoiceAction";
import { getOfferById } from "../../../services/actions/invoiceOfferAction";
import InvoiceMoreDetail from "./invoiceMoreDetail";
import Progressbar from "./progressBar";
import InvoicesListId from "./invoicesListId";
import FinalOfferDetails from "./finalOfferDetails";
import Header from "./header";
import {
  ACCOUNT_TYPE,
  INVOICE_STATUSES_KEY,
} from "../../../config/string_constants";
import { getInvoiceForAdmin } from "../../../api/invoiceService";
import InvoiceData from "./invoiceData";
import PricingDetails from "./pricingDetails";
import { HELPERS } from "../../../helpers/index";
import { getUserAccountsById } from "../../../services/actions/userAccountAction";
import GeneratePdf from "../../GeneratePdf";

class HistoryBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceId: this.props.match.params.id,
      invoicedetails: "",
      companydetails: [],
      tenure: "",
      showPdf: false,
      invoices: [],
      selected: false,
      itemId: "",
      pdfUrl: null,
      screen: INVOICE_STATUSES_KEY.HISTORY_BOOK,
      finaloffer: [],
      softOfferDetail: [],
      selectedAccount: [],
      clientAccounts: [],
    };
  }

  handleClose = () => {
    this.setState({ showPdf: false });
  };

  onPdfShow = (url) => {
    this.setState({
      showPdf: true,
      pdfUrl: url,
    });
  };

  componentDidMount = () => {
    this.props
      .getInvoiceDetailsById(this.state.invoiceId)
      .then((result) => {
        this.getAccountDetails(result.created_by_id);
        this.props.getInvoiceChanges(result);
        this.setState({
          invoicedetails: result,
          selected: true,
          itemId: result._id,
          finaloffer: result.final_offer,
          companydetails: result.client_company,
        });
        getInvoiceForAdmin(result.created_by_id)
          .then((invoices) => {
            //Get company details of particular invoice

            this.setState({
              invoices: invoices.data,
            });
          })
          .catch((err) => {
            console.log(err);
          });

        HELPERS.getPricingDetails(this.state.finaloffer.pricing_id)
          .then((pricing) => {
            this.setState({ pricingData: pricing });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    //get soft offer detail
    this.props
      .getOfferById(this.state.invoiceId)
      .then((result) => {
        this.setState({
          tenure: result.tenure,
          softOfferDetail: result,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /* Calculating the NetaPayble to Client */
  calculateNetPayableToClientHandler = () =>
    (
      this.state.invoicedetails.collection_details.amount_received -
      this.state.invoicedetails.final_offer.amount_to_be_funded -
      this.state.reserve_fund_allocation.fee
    ).toFixed(2);

  //get account detail
  getAccountDetails = (created_by_id) => {
    this.props
      .getUserAccountsById(created_by_id)
      .then((accounts) => {
        this.setState(
          {
            clientAccounts: accounts,
          },
          () => {
            const userPrimaryAccount = accounts.find(
              (item) => item.account_type === ACCOUNT_TYPE.PRIMARY
            );

            this.setState({
              selectedAccount: userPrimaryAccount,
            });
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showData = (item) => {
    this.setState({
      invoiceId: item.invoice_id,
      selected: true,
      itemId: item._id,
      finaloffer: item.final_offer,
      companydetails: item.client_company,
    });
  };

  render() {
    return (
      <div>
        <Header />
        <div className="row m-4 ">
          <div className="col-3">
            <InvoiceMoreDetail
              invoicedetails={this.state.invoicedetails}
              invoiceCompany={this.state.companydetails}
            />
          </div>
          <div className="col-9 overflow-auto">
            <Progressbar
              invoiceId={this.state.invoiceId}
              screen={this.state.screen}
            />
          </div>
        </div>
        <div className="row m-2 my-4">
          <div className="col-6">
            <div className="info-one h-100">
              {this.state.invoices.length === undefined ? (
                <div>No data Available </div>
              ) : (
                this.state.invoices
                  .filter((item) => {
                    return (
                      item.invoice_id === this.state.invoicedetails.invoice_id
                    );
                  })
                  .map((item, index) => (
                    <div key={index}>
                      <InvoiceData
                        invoices={item}
                        tenure={this.state.tenure}
                        onPdfShow={this.onPdfShow}
                      />

                      <GeneratePdf
                        invoicedetails={this.state.invoicedetails}
                        selectedAccount={this.state.selectedAccount}
                        softOfferDetail={this.state.softOfferDetail}
                      />
                    </div>
                  ))
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="info-one h-100">
              <p className="text_tra1 mb-2">INVOICES</p>
              <InvoicesListId
                invoices={this.state.invoices}
                selected={this.state.selected}
                showData={this.showData}
                itemId={this.state.itemId}
              />

              <PricingDetails
                pricingData={this.state.pricingData}
                show={false}
              />
              {this.state.invoicedetails ? (
                <FinalOfferDetails
                  invoiceId={this.state.invoiceId}
                  invoicedetails={this.state.invoicedetails}
                  tenure={this.state.tenure}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Modal show={this.state.showPdf} onHide={this.handleClose}>
          <div style={{ backgroundColor: "#333333", color: "white" }}>
            {this.state.pdfUrl !== null ? (
              this.state.pdfUrl.split(".").pop() === "pdf" ||
              this.state.pdfUrl.split(".").pop() === "PDF" ? (
                <iframe
                  title="pdf"
                  src={`https://docs.google.com/gview?url=${this.state.pdfUrl}&embedded=true`}
                  style={{ width: "50rem", height: "50rem" }}
                  frameBorder="0"
                ></iframe>
              ) : (
                <>
                  <img
                    alt="pdf"
                    src={this.state.pdfUrl}
                    style={{ width: "30rem", height: "30rem" }}
                  />
                </>
              )
            ) : (
              <div></div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  company: state.company,
  pricing: state.pricing,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceChanges: (data) => dispatch(getInvoiceChanges(data)),
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  getOfferById: (data) => dispatch(getOfferById(data)),
  getUserAccountsById: (data) => dispatch(getUserAccountsById(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HistoryBook));

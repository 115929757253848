import { Country } from "country-state-city";

/**
 * Component for Country list
 * @param {*} props 
 * @returns 
 */
export default function CountryList(props) {
    return (
        <>
            <select
                className="form-select wit_th"
                name="country_name"
                onChange={props.handleCountryChange}
                value={props.value}
            >
                <option className="country-state-dropdown">
                    Choose Country
                </option>
                {Country.getAllCountries().map((item) => (
                    <option
                        className="country-state-dropdown"
                        key={item}
                        value={item.name}

                    >
                        {item.name}
                    </option>
                ))}
            </select>
        </>
    )
}
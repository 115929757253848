import React from "react";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import Sidebar from "../Dashboard/Sidebar";
import "../../../assets/css/buyerlist.css";
import { getUsers, DeleteUser } from "../../../services/actions/userAction";
import { Link } from "react-router-dom";
import {
  getCompany,
  getBuyer,
  deleteCompany,
} from "../../../services/actions/companyActions";
import MainHeader from "../Dashboard/mainHeader";
import AddBuyer from "./addBuyer";
import { withRouter } from "react-router-dom";
import Search from "../../../assets/images/search.png";
import Button from "react-bootstrap/Button";
import { postActivity } from "../../../services/actions/activityActions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import Bottomnav from "../Dashboard/bottomnav";
import Mob_header from "../Dashboard/mob_header";
import { getCompaniesByCreatedByAndApproved } from "../../../services/actions/companyActions";
import Spinner from "react-bootstrap/Spinner";
import { URLS } from "../../../config/frontendUrls";

class buyerlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPageLoader: true,
      user: {
        full_name: "",
        email: "",
        phone_number: "",
        company_id: "",
        buyer_country: "",
        buyer_company: "",
        count: "",
        totalSum: "",
      },
      company: {
        company_name: "",
        company_email_id: "",
        company_phone_no: "",
        website: "",
        about: "",
        company_landline: "",
        address_line1: "",
        address_line2: "",
        city: "",
        zipcode: "",
        country_id: "",
        company_id: "",
      },
      buyer_list: false,
      buyers: [],
      deleteSuccess: false,
      deleteModal: false,
      deleteUserId: "",
      deleteCompanyId: "",
      activity: {
        activity_name: "Deleted A Buyer",
        service_name: "deleteBuyer",
        createdBy: {
          name: sessionStorage.getItem("uName"),
          email: sessionStorage.getItem("uEmail"),
          id: sessionStorage.getItem("userId"),
          // {company_name:'', contact_person:'',contact_phone_no:'',totalInvoices:'',totalAmountFunded:''}
        },
      },
      searchBuyer: "",
    };
  }

  componentDidMount() {
    this.props
      .getCompaniesByCreatedByAndApproved(sessionStorage.getItem("userId"))
      .then((response) => {
        this.setState({
          showPageLoader: false,
          buyers: response,
        });
      })
      .catch((err) => {});
  }

  handleSearch = (e) => {
    this.setState({
      searchBuyer: e.target.value,
    });
  };
  // onEdit = (item) => {
  //   let id = item.id;
  //   this.props.history.replace(`/borrower/edit-buyer/${id}`);
  // };
  handleClose = () => {
    this.setState({
      deleteModal: false,
    });
  };

  render() {
    if (this.state.showPageLoader) {
      return (
        <div id="cover-spin">
          <center>
            <Spinner
              style={{ marginTop: "300px", width: "6rem", height: "6rem" }}
              animation="border"
              variant="info"
            />
            {/* <img src={ Loader } style={{height:'126px',marginTop:'300px'}} alt="lazy-loader"/> */}
          </center>
        </div>
      );
    }
    return (
      <div>
        {this.state.buyers ? (
          <div>
            <Sidebar /> <Bottomnav />
            <div className="main-content">
              <Mob_header />
              <header className="header1">
                <div className="header__title">
                  <h2 className="heading">Buyers</h2>
                </div>
                <div>
                  <div className="d-flex justify-content-end">
                    <MainHeader />
                    <Link to={URLS.CLIENT.NEW_BUYER}>
                      {" "}
                      <button
                        variant="primary"
                        className="btn-blue d-block d-sm-none"
                      >
                        + New Buyer
                      </button>
                    </Link>
                  </div>
                </div>
              </header>
              <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <div className="m-4 d-flex  justify-content-between">
                <span className="search d-flex justify-content-end">
                  <img
                    style={{ position: "absolute", margin: "12px" }}
                    src={Search}
                  ></img>

                  <input
                    className="homesearchbox"
                    type="text"
                    placeholder="Search"
                    value={this.state.searchBuyer}
                    onChange={this.handleSearch}
                  />
                </span>

                <div className="mt-sm-6">
                  <span className="sortby">
                    Sort by <i className="uil uil-angle-down" />
                  </span>

                  <Link to={URLS.CLIENT.NEW_BUYER}>
                    {" "}
                    <button variant="primary" className="btn-blue disp_non">
                      + New Buyer
                    </button>
                  </Link>
                </div>
              </div>

              <div
                className="tablecont d-none d-sm-block"
                style={{ margin: "24px", marginTop: "35px", overflowX: "auto" }}
              >
                <table>
                  <thead>
                    <tr className="top">
                      <th>Buyer Name</th>
                      <th>Contact person</th>
                      <th>Contact</th>
                      <th>Country</th>
                      <th>Total Invoice</th>
                      <th>Total Funded Amount</th>
                      <th>Status</th>
                      <th>Add Invoice </th>
                    </tr>
                  </thead>

                  {this.state.buyers == undefined ? (
                    <tr>No Buyers added yet</tr>
                  ) : (
                    this.state.buyers
                      .filter((data) => {
                        return data.company_name
                          .toLowerCase()
                          .includes(this.state.searchBuyer.toLowerCase());
                      })
                      .map((item, index) => (
                        <tr className="text-white borderw" key={item.id}>
                          <td>
                            {item.company_name}
                            <div className="txt-sml">{item.website}</div>
                          </td>
                          <td>
                            {item.contact_details
                              ? item.contact_details.full_name
                              : ""}
                          </td>
                          <td>
                            {item.contact_details
                              ? item.contact_details.user_dialCode
                              : ""}{" "}
                            {item.contact_details.phone_number}
                            <div className="txt-sml">
                              {item.contact_details
                                ? item.contact_details.email
                                : ""}
                            </div>
                          </td>
                          <td>{item.country_id ? item.country_id.name : item.country_name}</td>
                          <td>{item.count}</td>
                          <td>
                            $
                            {Number(item.totalSum ? item.totalSum : 0)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </td>
                          <td>
                            {item.verify_company === true ? (
                              <span
                                style={{
                                  color: "green",
                                  padding: "10px",
                                  backgroundColor: "rgb(44, 43, 60)",
                                  borderRadius: "4px",
                                }}
                              >
                                Verified
                              </span>
                            ) : (
                              <div
                                style={{
                                  color: "rgb(82, 204, 184)",
                                  padding: "10px",
                                  backgroundColor: "rgb(39, 53, 51)",
                                  borderRadius: "4px",
                                }}
                                className="w-75"
                              >
                                Successfully Added
                              </div>
                            )}{" "}
                          </td>
                          <td>
                            <button
                              className="btn-blue w-75"
                              onClick={() =>
                                this.props.history.push(
                                  `${URLS.CLIENT.INVOICES}/${item._id}`
                                )
                              }
                            >
                              Add Invoice
                            </button>
                          </td>
                          {/* <td><button variant="primary" className='addinvoice' onClick={()=>this.onEdit(item)}>Edit </button></td>
    <td><button variant="primary" className='addinvoice' onClick={()=>this.onDelete(item)}>Delete </button></td> */}
                        </tr>
                      ))
                  )}
                </table>
              </div>
              {/* mobile view table */}
              <div className="d-block m-3 d-sm-none ">
                {this.state.buyers == undefined ? (
                  <tr>No Buyers added yet</tr>
                ) : (
                  this.state.buyers
                    .filter((data) => {
                      return data.company_name
                        .toLowerCase()
                        .includes(this.state.searchBuyer.toLowerCase());
                    })
                    .map((item, index) => (
                      <table
                        className="text-white borderR "
                        key={item.id}
                        style={{
                          backgroundColor: "#333333",
                          border: "none",
                          marginBottom: "14px",
                        }}
                      >
                        <tr className="borderw">
                          <td>
                            <div className="text_tra1 font14">Name</div>
                            <div className="font14">{item.company_name}</div>
                            <div className="text_tra1">{item.website}</div>
                          </td>
                          <td>
                            <div className="text_tra1 font14">
                              Contact Person
                            </div>
                            <div className="font14">
                              {item.contact_details
                                ? item.contact_details.full_name
                                : ""}
                            </div>
                          </td>
                        </tr>
                        <tr className="borderw">
                          <td>
                            <div className="text_tra1 font14">Contact</div>
                            <div className="font14">
                              +
                              {item.contact_details
                                ? item.contact_details.user_dialCode
                                : ""}{" "}
                              {item.contact_details
                                ? item.contact_details.phone_number
                                : ""}
                            </div>
                            <div className="text_tra1">
                              {item.contact_details
                                ? item.contact_details.email
                                : ""}
                            </div>
                          </td>
                          <td>
                            <div className="text_tra1 font14">Country</div>
                            <div className="font14">
                              <img
                                src={
                                  item.country_id ? item.country_id.flag : ""
                                }
                                className="mx-1"
                                width="30px"
                                height="20px"
                              />
                              {item.country_id ? item.country_id.name : ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="text_tra1 font14">
                              Total invoice
                            </div>
                            <div className="font14">{item.count}</div>
                          </td>
                          <td>
                            <div className="text_tra1 font14">
                              Total funded{" "}
                            </div>
                            <div className="font14">
                              {"$" +
                                Number(item.totalSum)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </div>
                          </td>
                        </tr>
                      </table>
                    ))
                )}
              </div>

              {/* mobile view end */}
              <Modal show={this.state.deleteModal} onHide={this.handleClose}>
                <div style={{ backgroundColor: "#333333", color: "white" }}>
                  <Modal.Header closeButton>Delete</Modal.Header>
                  <Modal.Body>
                    <label> Are you sure you want to delete Buyer??</label>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="danger" onClick={this.onDeleteConfirm}>
                      Delete
                    </Button>
                    <Button variant="secondary" onClick={this.handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          <AddBuyer />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.user,
  company: state.company,
  activity: state.activity,
  // dashboard: state.dashboard
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: () => dispatch(getUsers()),
  getCompany: () => dispatch(getCompany()),
  getBuyer: (data) => dispatch(getBuyer(data)),
  DeleteUser: (data) => dispatch(DeleteUser(data)),
  deleteCompany: (data) => dispatch(deleteCompany(data)),
  postActivity: (data) => dispatch(postActivity(data)),
  getCompaniesByCreatedByAndApproved: (data) =>
    dispatch(getCompaniesByCreatedByAndApproved(data)),
  // getInvoiceDashboard:() => dispatch(getInvoiceDashboard()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(buyerlist));

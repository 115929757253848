import react from "react";
import { Logo, Brand } from "../../constant";
import styles from "../../assets/css/OnboardingPersonalDetails.module.css";
import "../../Global.css";
import "../../assets2/css/profile.css";
import { UserContext } from "../../context/UserContext";
import "react-toastify/dist/ReactToastify.css";
import DialCodeList from "./Common/dialCodeList";

class UserHeader extends react.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      showPageLoader: false,
      file: null,
      progressState: 1,
      showSuceess: false,
      fillForms: { form1: false, form2: false },
      user: {
        full_name: "",
        designation: "",
        phone_number: "",
        company_id: "",
        email: "",
        user_dialCode: "",
        user_type: "CLIENT",
      },
      country: [],

      dirty: {
        full_name: false,
        designation: false,
        phone_number: false,
        user_dialCode: false,
      },

      errors: {
        full_name: [],
        designation: [],
        phone_number: [],
        user_dialCode: [],
      },

      city: [],
    };
  }
  handleChange = (e) => {
    const { name, value } = e.target;

    let dirty = this.state.dirty;
    dirty[name] = true;

    let user = this.state.user;
    if (name === "phone_number") {
      let cleanText = ("" + value).replace(/\D/g, "");
      user[name] = cleanText;
    } else {
      user[name] = value;
    }
    this.setState(
      {
        user: user,
        dirty: dirty,
      },
      this.validate
    );
  };

  blurChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };
  componentDidMount() {
    this.setState({ user: this.props.user });
  }
  validate = () => {
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      errors[control] = [];

      var validName = /^[a-zA-Z_'-\s]*$/;

      switch (control) {
        case "full_name":
          if (!this.state.user[control]) {
            errors[control].push("Name can't be blank");
          }
          if (this.state.user[control]) {
            if (!validName.test(this.state.user[control])) {
              errors[control].push("Enter valid Name");
            }
          }
          break;

        case "designation":
          if (!this.state.user[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "user_dialCode":
          if (!this.state.user[control]) {
            errors[control].push("Dial code is required");
          }
          break;
        case "phone_number":
          if (!this.state.user[control]) {
            errors[control].push("This field is required");
          }

          let length = this.state.user[control].length;
          if (this.state.user[control] && (length < 6 || length > 12)) {
            errors[control].push("Number min 6 max 12");
          }

          break;

        default:
          break;
      }
    });
    this.setState({ errors });
  };

  isValid = () => {
    let valid = true;

    for (let control in this.state.errors) {
      if (this.state.errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };
  goToNextPage = () => {
    this.validate();
    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });

    if (this.isValid()) {
      this.props.goToCompanyDetails(this.state.user);
    }
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-2 text-center pt-3"></div>
          <div className="col-md-8 text-center mt-2 pt-3">
            <h3 className="text_color">Tell us a bit about yourself</h3>
            <p className="text_color2 ">
              {Brand} needs this Information to give you better product
              experience{" "}
            </p>
            {/* input box */}
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="  mb-3 text-left mt-4">
                  <label className="form-label text_tra">Full Name</label>
                  <input
                    type="text"
                    className="form-control input_bg"
                    placeholder="Enter your full name"
                    name="full_name"
                    value={this.state.user.full_name}
                    onChange={this.handleChange}
                    onBlur={this.blurChange}
                    maxLength="50"
                  />
                  <div className="text-danger">
                    {this.state.errors.full_name[0] &&
                    this.state.dirty.full_name
                      ? this.state.errors.full_name
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>

            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="  mb-3 text-left">
                  <label className="form-label text_tra">Designation</label>
                  <input
                    type="text"
                    className="form-control input_bg"
                    placeholder="Enter your Designation"
                    name="designation"
                    value={this.state.user.designation}
                    onChange={this.handleChange}
                    onBlur={this.blurChange}
                    maxLength="15"
                  />
                  <div className="text-danger">
                    {this.state.errors.designation[0] &&
                    this.state.dirty.designation
                      ? this.state.errors.designation
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
            {/*  */}
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="  mb-3 text-left">
                  <label className="form-label text_tra ">Contact No</label>
                  <div className="input-group">
                    <DialCodeList handleChange={this.handleChange} name="user_dialCode" />

                    <input
                      type="text"
                      className=" ml-2 form-control input_bg"
                      aria-label="Text input with dropdown button"
                      name="phone_number"
                      value={this.state.user.phone_number}
                      onChange={this.handleChange}
                      onBlur={this.blurChange}
                      minLength={6}
                      maxLength="15"
                    />
                  </div>

                  <div className="text-danger">
                    {this.state.errors.phone_number[0] &&
                    this.state.dirty.phone_number
                      ? this.state.errors.phone_number
                      : ""}
                  </div>
                  <div className="text-danger">
                    {this.state.errors.user_dialCode[0] &&
                    this.state.dirty.user_dialCode
                      ? this.state.errors.user_dialCode
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
            {/*  */}
            <div className="row row justify-content-center pt-3">
              <div className="col-md-8 mb-3">
                <button
                  style={{
                    backgroundColor: "rgb(77 181 196)",
                    border: "none",
                    color: "#ffffff",
                  }}
                  className="btn w-100"
                  onClick={() => this.goToNextPage()}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>

          <div className=" text-center pt-3"></div>
        </div>
      </>
    );
  }
}

export default UserHeader;

import * as PRICING_CONSTANTS from "../constants/pricingConstant";

export const pricingReducer = (state = {
    type: '',
    error: '',
    isFetching: false,
    princingData:undefined,
}, action) => {
   
    switch (action.type) {
                case PRICING_CONSTANTS.HOME_GET_PRICING_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    princingData: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case PRICING_CONSTANTS.HOME_GET_PRICING_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    princingData: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case PRICING_CONSTANTS.HOME_GET_PRICING_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });
    
            

        default:
            return state;
    }
};


export default pricingReducer;

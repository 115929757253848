import React, { useState, useEffect } from "react";
import { approveInvoiceByAdmin } from "../../../api/invoiceService";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

/**
 * This component returns Approve  button.
 * @param {*} props
 * @returns
 */

export default function ApproveInvoice(props) {
  const history = useHistory();
  const [approveStatus, setApproveStatus] = useState("");
  const [approveSubStatus, setApproveSubStatus] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");

  useEffect(() => {
    for (let j = 0; j < props.invoiceStatus.length; j++) {
      if (props.invoiceStatus[j].key === props.nextStatusKey) {
        for (let i = 0; i < props.invoiceStatus[j].subStatus.length; i++) {
          if (
            props.invoiceStatus[j].subStatus[i].statusName === "In Progress"
          ) {
            setApproveStatus(props.invoiceStatus[j]._id);
            setApproveSubStatus(props.invoiceStatus[j].subStatus[i]._id);
            break;
          }
        }
      }
      if (props.invoiceStatus[j].key === props.currentStatusKey) {
        for (let i = 0; i < props.invoiceStatus[j].subStatus.length; i++) {
          if (props.invoiceStatus[j].subStatus[i].statusName === "Done") {
            setCurrentStatus(props.invoiceStatus[j]._id);
            break;
          }
        }
      }
    }
  }, [props.currentStatusKey, props.invoiceStatus, props.nextStatusKey]);

  const approve = (invoice) => {
    let data = {
      invoiceStatus_id: approveStatus,
      invoiceSubStatus_id: approveSubStatus,
      update_type: props.updateType,
      _id: invoice._id,
    };

    approveInvoiceByAdmin(data)
      .then(() => {
        toast.info(props.toastMessage);
        history.replace(`/${props.nextStatusRoute}/${invoice._id}`);
      })
      .catch((err) => {});
  };

  return (
    <>
      <span>
        {props.invoice.invoiceStatus_id._id === currentStatus && (
          <button
            className="btn-blue mx-2"
            onClick={() => approve(props.invoice)}
          >
            Approve
          </button>
        )}
      </span>
    </>
  );
}

//export const URI='http://polytread-env.eba-tpgu3qtx.ap-south-1.elasticbeanstalk.com' ;
// export const URI='https://polytradedev-env.eba-b7c8v3vx.ap-south-1.elasticbeanstalk.com'; //dev uri
//export const URI='https://api.polytrade.app'
export const ENVIRONMENT = process.env.REACT_APP_ENV || "dev";
export const URI = process.env.REACT_APP_SERVER_URL || "http://localhost:5000";
export const MAGIC_LINK_KEY = process.env.REACT_APP_MAGIC_LINK_KEY || "pk_live_6C2A7D3A6BFBE90E";
export const LENDER_TNC = "https://polytrade-general-documents.s3.ap-southeast-1.amazonaws.com/Polytrade+Membership+Agreement+v4.pdf";
export const CLIENT_TNC = "https://polytrade-general-documents.s3.ap-southeast-1.amazonaws.com/Terms+%26+Conditions_NineOne-Riqueza.pdf";
export const ROLE = {
  ADMIN: "ADMIN",
  CLIENT: "CLIENT",
  LENDER: "LENDER",
};
export const Brand = "Nine1";
export const GA_TRACKING_ID =  process.env.REACT_APP_GA_TRACKING_ID || "UA-199986598-3";
export const Logo = require('./assets/images/nine1/White_without_tagline.png').default;
export const TRANSAK_API_KEY = process.env.REACT_APP_TRANSAK_KEY || "6ff13338-f6c2-43e8-bb77-8f6b4cc906ee";
export const TRANSAK_ENV = process.env.REACT_APP_TRANSAK_ENV || "STAGING";
export const TRANSAK_CRYPTO_LIST = "USDC";
import React, { useContext } from "react";
import arrow from "../../../assets/images/arrow.png";
import "../../../assets/css/BuyerProfile.css";
import Sidebar from "../Dashboard/Sidebar";
import { Link } from "react-router-dom";
import MainHeader from "../Dashboard/mainHeader";
import { USER_TYPE } from "../../../config/string_constants";

class Verifybuyer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        full_name: "",
        email: "",
        phone_number: "",
        user_type: USER_TYPE.BUYER,
      },
      dirty: {
        full_name: false,
        email: false,
        phone_number: false,
        user_type: USER_TYPE.BUYER,
      },
      errors: { full_name: [], email: [], phone_number: [], user_type: [] },
    };
  }

  render() {
    return (
      <div>
        <Sidebar />

        <div className='main-content'>
          <header className='header'>
            <div className='header__title'>
              <h2 className='heading'>Buyers</h2>
            </div>
            <div>
              <div className='d-flex justify-content-end'>
                <MainHeader />
              </div>
            </div>
          </header>
          <div className='main__navigation'>
            <div className='main__goback'>
              <img src={arrow} width='18px' height='18x' />
              <h3>Walmart Pvt Ltd</h3>
            </div>
            <div>
              <div className='Account_Details'>
                <Link style={{ textDecoration: "none" }} to='/buyerlist'>
                  <button>Verify and Submit</button>
                </Link>
              </div>
            </div>
          </div>
          <main className='main-form'>
            <form className='main__company_details'>
              <div className='main__company_details_header'>
                Company Details
              </div>
              <div style={{ margin: "24px" }} className='row'>
                <div className='col-sm-4'>
                  <label className='label' htmlFor=''>
                    Company Name
                  </label>
                  <input
                    className='input'
                    type='text'
                    placeholder='Enter your company name'
                  />
                </div>
                <div className='col-sm-4 '>
                  <label className='label' htmlFor=''>
                    Corporate Email Address
                  </label>
                  <input
                    className='input'
                    type='text'
                    placeholder='support@domain.com'
                  />
                </div>
                <div className='col-sm-4 '>
                  <label className='label' htmlFor=''>
                    Contact Number
                  </label>
                  <input
                    className='input'
                    type='text'
                    placeholder='+1 23456789'
                  />
                </div>
                <div className='col-sm-12'>
                  <label className='label' htmlFor=''>
                    Company Address
                  </label>
                  <input
                    className='input'
                    type='text'
                    placeholder='112, sector 56, street, location, locations, 4445669'
                  />
                </div>

                <div className='col-sm-4'>
                  <label className='label' htmlFor=''>
                    Country
                  </label>
                  <input className='input' type='text' placeholder='India' />
                </div>
                <div className='col-sm-4'>
                  <label className='label' htmlFor=''>
                    City
                  </label>
                  <input className='input' type='text' placeholder='delhi' />
                </div>
                <div className='col-sm-4'>
                  <label className='label' htmlFor=''>
                    Website
                  </label>
                  <input
                    className='input'
                    type='text'
                    placeholder='domain.com'
                  />
                </div>
              </div>
            </form>
          </main>
        </div>
      </div>
    );
  }
}

export default Verifybuyer;

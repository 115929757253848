// import CONSTANTS from "../utils/constants"
const currentEnvironment = {
  local: {
    url: process.env.REACT_APP_SERVER_URL,
    S3URL: {},
  },
  development: {
    url: process.env.REACT_APP_SERVER_URL,
    S3URL: {},
  },
  staging: {
    url: process.env.REACT_APP_SERVER_URL,
    S3URL: {},
  },
  production: {
    url: process.env.REACT_APP_SERVER_URL,
    S3URL: {},
  },
};
export default process.env.REACT_APP_BASE_URL === "local"
  ? currentEnvironment.local
  : process.env.REACT_APP_BASE_URL === "development"
  ? currentEnvironment.development
  : process.env.REACT_APP_BASE_URL === "staging"
  ? currentEnvironment.staging
  : process.env.REACT_APP_BASE_URL === "production"
  ? currentEnvironment.production
  : currentEnvironment.staging;

import React, { useState } from "react";

/**
 * This component returns an expander view in admin dashboard
 * @param {} props 
 * @returns 
 */
export default function DetailsExpander(props) {
  const [show, setShow] = useState(false);

  return (
    <div>
      <div className=" d-flex justify-content-between mt-2 p-2 bgc-dark  text-white">
        <div>{props.label}</div>
        <div>
          <i
            onClick={() => setShow(!show)}
            className={show ? "uil-angle-up txt_b" : "uil-angle-down txt_b"}
          ></i>
        </div>
      </div>

      {show && <div className="p-3 mt-1  bgc-dark">{props.children}</div>}
    </div>
  );
}

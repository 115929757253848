import React from "react";
import Modal from "react-bootstrap/Modal";
import Close from "../../../assets/images/close_icon.svg";
import "../../../assets/css/common/fontStyle.css";
import "../../../assets/css/common/button.css";
import { useState } from "react";
import { useEffect } from "react";
import { getGeneralQuestionByType } from "../../../api/userService";
export default function ForgotPinPopup(props) {
  const [securityQuestions, setSecurityQuestions] = useState([]);
  const [inputs, setInputs] = useState({
    question_id: "",
    secret_answer: "",
  });

  useEffect(async () => {
    const response = await getGeneralQuestionByType();
    if (response) {
      setSecurityQuestions(response.data);
    }
  }, []);
  //validation code
  let [errors, setErrors] = useState({
    question_id: [],
    secret_answer: [],
  });
  let [dirty, setDirty] = useState({
    question_id: false,
    secret_answer: false,
  });
  let validate = (name) => {
    let errorData = {};
    errorData.question_id = [];

    if (!inputs.question_id) {
      errorData.question_id.push("This field is required");
    }

    errorData.secret_answer = [];
    if (!inputs.secret_answer) {
      errorData.secret_answer.push("This field is required");
    }
    setErrors(errorData);
  };
  let isValid = () => {
    let valid = true;

    //reading all controls from 'errors' state
    for (let control in errors) {
      if (errors[control].length > 0) {
        valid = false;
      }
    }

    return valid;
  };
  function blurChanged(e) {
    const { name, value } = e.target;

    setDirty((dirty) => ({ ...dirty, [name]: true }));
    //setDirty({ ...dirty, [event.target.question_id]: true });
    validate();
  }

  useEffect(validate, [inputs]);
  //validation code ends

  function handleChange(e) {
    const { name, value } = e.target;

    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }
  function sendMailLink(e) {
    e.preventDefault();
    let dirtyData = dirty;
    Object.keys(dirty).forEach((control) => {
      dirtyData[control] = true;
    });
    setDirty(dirtyData);
    validate();
    if (isValid()) {
      props.sendMailLink(inputs);
    }
  }
  return (
    <div>
      <div style={{ fontFamily: "Averta-Semibold,sans-serif" }}>
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ backgroundColor: "transparent" }}
        >
          <div className="alert-popup">
            <div className="mt-3 disp-lg-none">
              <div className="line mx-auto"></div>
            </div>

            <Modal.Body className="text-center">
              <div className="float-right mb-2 ">
                <img
                  src={Close}
                  className="user-icon  curserP"
                  onClick={props.hideReward}
                  alt=""
                />
              </div>
              <div
                className="d-flex mt-5  ml-5"
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <h3 className="text-white">Forgot Secret PIN?</h3>
                <p
                  className="my-2"
                  style={{
                    color: "rgba(255, 255, 255, 0.64)",
                    fontSize: "12px",
                  }}
                >
                  Please check your registered email to generate new PIN
                </p>
                <span
                  style={{ color: "rgba(255, 255, 255, 0.64)" }}
                  className="mt-4"
                >
                  Select your security question
                </span>
                <div className="text_tra1 mt-3">
                  {securityQuestions.map((quest) => (
                    <div className="row radio-group ml-0 my-2">
                      <input
                        type="radio"
                        name="question_id"
                        className="form-control"
                        checked={inputs.question_id === quest._id}
                        value={quest._id}
                        onChange={handleChange}
                        onBlur={blurChanged}
                      />
                      <span> {quest.name}</span>
                    </div>
                  ))}
                  <div className="text-danger">
                    {dirty["question_id"] && errors["question_id"][0]
                      ? errors["question_id"]
                      : ""}
                  </div>
                  <div
                    className="d-flex  mt-2"
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div className="my-2"> Your answer</div>
                    <input
                      type="text"
                      placeholder="Your answer"
                      className="input-style custom-textbox mb-3"
                      name="secret_answer"
                      value={inputs.secret_answer}
                      onChange={handleChange}
                      onBlur={blurChanged}
                    />
                    <div className="text-danger">
                      {dirty["secret_answer"] && errors["secret_answer"][0]
                        ? errors["secret_answer"]
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button className="btn-blue w-75 my-3" onClick={sendMailLink}>
                  Generate New PIN
                </button>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </div>
  );
}

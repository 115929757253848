import React from "react";
import { jsPDF } from "jspdf";
import moment from "moment";
import helper from "../utils/helper";
import logo from "../../src/assets/images/Black-Logo.png";

const GeneratePdf = ({ invoicedetails, softOfferDetail, selectedAccount }) => {
  //calculate finance tenure
  const reserve_fund_finance_tenure = helper.calculateFinanceTenure(
    invoicedetails.collection_details.payment_date,
    invoicedetails.final_offer.advance_date
  );

  //calculate late days
  const late_day = helper.calculateNumberOfLateDays(
    invoicedetails?.invoice_due_date,
    invoicedetails?.final_offer.grace_period,
    invoicedetails?.collection_details.payment_date
  );

  //calculate discount fee
  const reserve_fund_discounting_fee = helper.calculateDiscountFee(
    invoicedetails?.final_offer?.discount,
    invoicedetails?.final_offer?.amount_to_be_funded,
    reserve_fund_finance_tenure,
    late_day
  );

  //calculate factoring fee
  const reserve_fund_factoring_fee = helper.calculateFactoringFee(
    invoicedetails?.final_offer?.factoring_fee,
    invoicedetails?.invoice_amount
  );

  //calculate late fee value
  const late_fee_value = helper.calculateLateFeesHandler(
    invoicedetails?.final_offer?.late_fee,
    invoicedetails?.final_offer?.amount_to_be_funded,
    late_day
  );

  //calculate late fee
  const reserve_fund_fee =
    reserve_fund_discounting_fee +
    reserve_fund_factoring_fee +
    late_fee_value +
    invoicedetails?.final_offer?.bank_charges;

  //calculateclient payable amount
  const reserve_fund_client_payable = (
    invoicedetails.collection_details.amount_received -
    invoicedetails.final_offer.amount_to_be_funded -
    reserve_fund_fee
  ).toFixed(2);

  const generatePdf = async () => {
    const { final_offer, collection_details } = invoicedetails;
    const doc = new jsPDF();

    const pdfLogo = new Image();
    pdfLogo.src = logo;
    doc.addImage(pdfLogo, "PNG", 10, 10, 50, 7, "referenceDocument", "FAST", 0);

    doc.text("Soft Offer ", 90, 30);
    doc.setFontSize(16);
    doc.setFont(undefined, undefined, 600);
    doc.text("Invoice Amount :", 20, 50);
    doc.text("Invoice Limit :", 20, 65);
    doc.text(
      `Factoring Fee (${softOfferDetail.factoring_fee.percentage} %) :`,
      20,
      80
    );
    doc.text(
      `Discounting Fee (${softOfferDetail.discount.percentage} %) :`,
      20,
      95
    );
    doc.text(
      `Advance Ratio (${softOfferDetail.advance_ratio.percentage} %) :`,
      20,
      110
    );
    doc.setFontSize(14);
    doc.setFont(undefined, undefined, 400);
    doc.text(`$ ${softOfferDetail.invoice_amount} `, 62, 50);
    doc.text(`$ ${softOfferDetail.invoice_limit} `, 56, 65);
    doc.text(`$ ${softOfferDetail.factoring_fee.amount} `, 74, 80);
    doc.text(`$ ${softOfferDetail.discount.amount} `, 80, 95);
    doc.text(`$ ${softOfferDetail.advance_ratio.amount} `, 76, 110);
    doc.setFontSize(20);
    doc.text("Final Offer ", 90, 130);
    doc.setFontSize(16);
    doc.setFont(undefined, undefined, 600);
    doc.text("Invoice Amount :", 20, 150);
    doc.text(`Factoring Fee (${final_offer.factoring_fee} %) :`, 20, 165);
    doc.text(`Discounting Fee (${final_offer.discount} %) :`, 20, 180);
    doc.text(
      `Amount to be funded (${final_offer.advance_percentage} %) :`,
      20,
      195
    );
    doc.text("Advance Date :", 20, 210);
    doc.text("Bank Charges :", 20, 225);
    doc.text("Create Date :", 20, 240);
    doc.text("Expired :", 20, 255);
    doc.text("Fee :", 20, 270);
    doc.text("Grace Period :", 20, 285);
    doc.addPage("a4", "p");
    doc.text("Grade :", 20, 20);
    doc.text("Late Fee :", 20, 35);
    doc.text("Pricing Id :", 20, 50);
    doc.text("Regenerate Request :", 20, 65);
    doc.text("Reserve Percentage :", 20, 80);
    doc.text("Tenure :", 20, 95);
    doc.addPage("a4", "p");
    doc.text("ARPA", 90, 10);
    doc.text("Admin ARPA Document", 20, 25);
    doc.text("User ARPA Document", 20, 170);
    doc.addPage("a4", "p");
    doc.text("Invoice verification by Buyer", 80, 10);
    doc.text("Approval Format :", 20, 30);
    doc.text("Signed Document :", 20, 45);
    if (invoicedetails.signed_reference_document !== null) {
      doc.text("Signed Reference Document :", 20, 170);
    }
    doc.addPage("a4", "p");
    doc.text("Advance Fund Allocation", 80, 20);
    doc.text("Buyer Name :", 20, 35);
    doc.text("Invoice Grade :", 20, 48);
    doc.text("Invoice Ref.No :", 20, 61);
    doc.text("Supplier Invoice Number :", 20, 74);
    doc.text("Invoice Amount :", 20, 87);
    doc.text("Invoice Limit :", 20, 100);
    doc.text("Invoice Date :", 20, 113);
    doc.text("Advanced Date :", 20, 126);
    doc.text("Advanced Amount :", 20, 139);
    doc.text("Reserve Held :", 20, 152);
    doc.text("Days Aged :", 20, 165);
    doc.text("Invoice Due Date :", 20, 178);
    doc.text("Grace period :", 20, 191);
    doc.text("IFSC Code :", 20, 204);
    doc.text("Bank Name :", 20, 217);
    doc.text("Account Number :", 20, 230);
    doc.text("Advance Approved :", 20, 243);
    doc.text("Advance Disbursed :", 20, 256);
    doc.addPage("a4", "p");
    doc.text("Collections Stage", 80, 20);
    doc.text("Collection Format :", 20, 35);
    doc.text("Reference Txn ID :", 20, 50);
    doc.text("Buyer Payment Date :", 20, 65);
    doc.text("Swift Ref.No :", 20, 80);
    doc.text("Buyer Name :", 20, 95);
    doc.text("Amount Received from Buyer :", 20, 110);
    doc.text("Supplier Invoice Number :", 20, 125);
    doc.text("Unapplied/Short Amount :", 20, 140);
    doc.text("Finance Tenure :", 20, 155);
    doc.text("Amount Received from Supplier :", 20, 170);
    doc.text("Message :", 20, 185);
    if (collection_details.doc !== null) {
      doc.text("Reference Document :", 20, 195);
    }
    doc.addPage("a4", "p");
    doc.text("Reserve Offer Stage", 80, 20);
    doc.text("Buyer Name :", 20, 35);
    doc.text("Invoice Ref.No :", 20, 50);
    doc.text("Supplier Invoice Number :", 20, 65);
    doc.text("Invoice Amount :", 20, 80);
    doc.text("Invoice Limit :", 20, 95);
    doc.text("Advanced Amount :", 20, 110);
    doc.text("Reserve Amount :", 20, 125);
    doc.text("Amount Received :", 20, 140);
    doc.text("Invoice Due Date :", 20, 155);
    doc.text("Payment Received Date :", 20, 170);
    doc.text("Grace Period :", 20, 185);
    doc.text("Number of Late Days :", 20, 200);
    doc.text("Discounting Fee :", 20, 215);
    doc.text("Factoring Fee :", 20, 230);
    doc.text("Banking Charges :", 20, 245);
    doc.text("Total Fee :", 20, 260);
    doc.text("Late Fee (in percentage) :", 20, 275);
    doc.text("Late Fee Value :", 20, 290);
    doc.addPage("a4", "p");
    doc.text("Net Amount Payable to Client :", 20, 20);
    doc.text("Financed Tenure :", 20, 35);
    doc.text("Reserve Fund Approved :", 20, 50);
    doc.text("Reserved Disbursed :", 20, 65);
    doc.setFontSize(14);
    doc.setFont(undefined, undefined, 400);
    doc.setPage(1);
    doc.text(`$ ${final_offer.amount} `, 62, 150);
    doc.text(`$ ${final_offer.factoring_amount} `, 74, 165);
    doc.text(`$ ${final_offer.discount_amount} `, 80, 180);
    doc.text(`$ ${final_offer.amount_to_be_funded} `, 89, 195);
    doc.text(
      `${moment(final_offer.advance_date).format("Do MMM YYYY")}`,
      57,
      210
    );
    doc.text(`$ ${final_offer.bank_charges}`, 57, 225);
    doc.text(
      `${moment(final_offer.created_date).format("Do MMM YYYY")}`,
      52,
      240
    );
    doc.text(`${final_offer.expired ? "Yes" : "No"}`, 42, 255);
    doc.text(`$ ${final_offer.fee}`, 33, 270);
    doc.text(
      `${final_offer.grace_period} ${
        final_offer.grace_period > 1 ? "Days" : "Day"
      }`,
      55,
      285
    );
    doc.setPage(2);
    doc.text(`${final_offer.grade} `, 40, 20);
    doc.text(`$ ${final_offer.late_fee} `, 45, 35);
    doc.text(`${final_offer.pricing_id} `, 46, 50);
    doc.text(`${final_offer.regenerate_request ? "Yes" : "No"}`, 71, 65);
    doc.text(`${final_offer.reserve_percentage} %`, 69, 80);
    doc.text(`${final_offer.tenure}`, 42, 95);
    doc.setPage(3);
    const adminArpa = new Image();
    adminArpa.src = invoicedetails.arpa_document;
    doc.addImage(adminArpa, "JPEG", 20, 30, 170, 120, "admin_arpa", "FAST", 0);
    const userArpa = new Image();
    userArpa.src = invoicedetails.borrower_arpa_document;
    doc.addImage(userArpa, "JPEG", 20, 175, 170, 120, "user_arpa", "FAST", 0);
    doc.setPage(4);
    doc.text(`${invoicedetails.approval_format}`, 65, 30);
    const signedDocument = new Image();
    signedDocument.src = invoicedetails.signed_document;
    doc.addImage(
      signedDocument,
      "JPEG",
      20,
      50,
      170,
      110,
      "signedDocument",
      "FAST",
      0
    );
    if (invoicedetails.signed_reference_document !== null) {
      const signedReferenceDocument = new Image();
      signedReferenceDocument.src = invoicedetails.signed_reference_document;
      doc.addImage(
        signedReferenceDocument,
        "JPEG",
        20,
        175,
        170,
        110,
        "signedReferenceDocument",
        "FAST",
        0
      );
    }
    doc.setPage(5);
    doc.text(`${invoicedetails.buyer_company.company_name}`, 53, 35);
    doc.text(`${final_offer.grade}`, 57, 48);
    doc.text(`${invoicedetails.invoice_id}`, 59, 61);
    doc.text(`${invoicedetails.supplier_invoice_number}`, 83, 74);
    doc.text(`$ ${invoicedetails.invoice_amount}`, 62, 87);
    doc.text(`$ ${invoicedetails.invoice_limit}`, 56, 100);
    doc.text(
      `${moment(invoicedetails.invoice_date).format("DD MMM YYYY")}`,
      53,
      113
    );
    doc.text(
      `${moment(final_offer.advance_date).format("DD MMM YYYY")}`,
      61,
      126
    );
    doc.text(`$ ${final_offer.amount_to_be_funded}`, 67, 139);
    doc.text(
      `$ ${invoicedetails.invoice_amount - final_offer.amount_to_be_funded}`,
      55,
      152
    );
    doc.text(
      `${moment(new Date()).diff(moment(invoicedetails.invoice_date), "days")}`,
      50,
      165
    );
    doc.text(
      `${moment(invoicedetails.invoice_due_date).format("DD MMM YYYY")}`,
      65,
      178
    );
    doc.text(
      `${final_offer.grace_period} ${
        final_offer.grace_period > 1 ? "Days" : "Day"
      }`,
      53,
      191
    );
    doc.text(`${selectedAccount.ifsc_code}`, 51, 204);
    doc.text(`${selectedAccount.bank_name}`, 53, 217);
    doc.text(`${selectedAccount.account_number}`, 65, 230);
    doc.text(`${invoicedetails.is_advance_approved ? "Yes" : "No"}`, 68, 243);
    doc.text(`${invoicedetails.is_advance_disbursed ? "Yes" : "No"}`, 68, 256);
    doc.setPage(6);
    doc.text(`${collection_details.collection_format}`, 65, 35);
    doc.text(`${collection_details.reference_tx_id}`, 65, 50);
    doc.text(`${collection_details.payment_date}`, 72, 65);
    doc.text(`${collection_details.payment_ref_no}`, 54, 80);
    doc.text(`${invoicedetails.buyer_company.company_name}`, 53, 95);
    doc.text(`$ ${collection_details.amount_received}`, 93, 110);
    doc.text(`${invoicedetails.supplier_invoice_number}`, 82, 125);
    doc.text(`$ ${collection_details.short_amount}`, 81, 140);
    doc.text(`${collection_details.finance_tenure}`, 61, 155);
    doc.text(`$ ${collection_details.amount_received_from_supplier}`, 98, 170);
    doc.text(`${collection_details.messages}`, 45, 185);
    if (collection_details.doc !== null) {
      const referenceDocument = new Image();
      referenceDocument.src = collection_details.doc;
      doc.addImage(
        referenceDocument,
        "JPEG",
        20,
        200,
        170,
        100,
        "referenceDocument",
        "FAST",
        0
      );
    }

    doc.setPage(7);
    doc.text(`${invoicedetails.buyer_company.company_name}`, 53, 35);
    doc.text(`${invoicedetails.invoice_id}`, 59, 50);
    doc.text(`${invoicedetails.supplier_invoice_number}`, 82, 65);
    doc.text(`$ ${invoicedetails.invoice_amount}`, 62, 80);
    doc.text(`$ ${invoicedetails.invoice_limit}`, 54, 95);
    doc.text(`$ ${invoicedetails.final_offer.amount_to_be_funded}`, 67, 110);
    doc.text(
      `$ ${
        invoicedetails.invoice_limit -
        invoicedetails.final_offer.amount_to_be_funded
      }`,
      62,
      125
    );
    doc.text(`$ ${invoicedetails.collection_details.amount_received}`, 67, 140);
    doc.text(
      `${moment(invoicedetails.invoice_due_date).format(" DD/MM/YYYY")}`,
      64,
      155
    );
    doc.text(`${invoicedetails.collection_details.payment_date}`, 79, 170);
    doc.text(
      `${invoicedetails.final_offer.grace_period} ${
        final_offer.grace_period > 1 ? "Days" : "Day"
      }`,
      54,
      185
    );
    doc.text(`${late_day} ${late_day > 1 ? "Days" : "Day"}`, 73, 200);
    doc.text(`$ ${Number(reserve_fund_discounting_fee).toFixed(2)}`, 63, 215);
    doc.text(`$ ${reserve_fund_factoring_fee}`, 58, 230);
    doc.text(`$ ${invoicedetails.final_offer.bank_charges}`, 63, 245);
    doc.text(
      `${`$ ${Number(reserve_fund_fee)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}`,
      48,
      260
    );
    doc.text(`${invoicedetails.final_offer.late_fee} %`, 82, 275);
    doc.text(
      `${`$ ${Number(late_fee_value ? late_fee_value : 0)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}`,
      60,
      290
    );
    doc.setPage(8);
    doc.text(`$ ${reserve_fund_client_payable}`, 94, 20);
    doc.text(`${reserve_fund_finance_tenure}`, 65, 35);
    doc.text(`${invoicedetails.is_reserve_approved ? "Yes" : "No"}`, 79, 50);
    doc.text(`${invoicedetails.is_reserved_disbursed ? "Yes" : "No"}`, 70, 65);
    doc.save(`invoice-${invoicedetails.invoice_id}.pdf`);
  };
  return (
    <div className="mt-5 text-center">
      <span className="uploadb mx-2 " onClick={generatePdf}>
        Download History Book
      </span>
      <button className="btn-blue mx-2 w-25">View History Book</button>
    </div>
  );
};

export default GeneratePdf;

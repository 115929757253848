import Modal from 'react-bootstrap/Modal'
import React, { Component } from 'react'
import { CLIENT_TNC } from "../../../constant";
export default class tcPopup extends Component {
    render() {
        return (
            <div>
                <Modal show={this.props.showPdf} onHide={this.props.handleClose}>
                    <div style={{ backgroundColor: "#333333", color: "white" }}>

                        <iframe title="tnc" src={this.props.lenderTNC ? this.props.lenderTNC : CLIENT_TNC}
                            style={{ "width": "50rem", height: "50rem" }} frameBorder="0"
                        ></iframe>

                    </div>
                </Modal>
            </div>
        )
    }
}
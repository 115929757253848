import React from 'react'
import Logo from '../../../assets/images/Logo.svg';
import menu from '../../../assets/images/Menu.png';
import { NavLink } from 'react-router-dom'
import support from '../../../assets/images/support.svg';
import { URLS } from "../../../config/frontendUrls";

import { useState } from 'react';
export default function Mob_header() {
    const [navbarOpen, setNavbarOpen] = useState(false)
    
      const handleToggle = () => {
        setNavbarOpen(!navbarOpen)
      }
    return (
        <div>
            {/* mobile */}
        <div style={{borderBottom:"1px solid gray"}} className='mob-header d-flex justify-content-between d-block d-sm-none p-4'>
        <div> <img src={Logo} width='190px' alt="" />
           </div>
            {/* <img  src={menu} alt="" /> */}
            <nav className="navBar">
            <span style={{fontSize:"25px",position:"sticky"}} onClick={handleToggle}>{navbarOpen ? <i className='uil  uil-multiply text-white' /> : <img  src={menu} alt="" />}</span>
            <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>.
            
            <h4 className='text-white ml-4'>Menu</h4>
            <div className="mt-4 sidebar-menu">
            <li className="invoices-menu">
              <NavLink activeClassName="act1" style={{ textDecoration: "none" }} to={URLS.CLIENT.REFERRAL} exact={true}>
                <i className="uil-user-plus" /> <span>Referral</span></NavLink>

            </li>
            <li className="invoices-menu">
              <NavLink activeClassName="act1" style={{ textDecoration: "none" }} to={URLS.CLIENT.WALLET} exact={true}>
                <i className="uil uil-wallet" /> <span>Wallet</span></NavLink>

            </li>
            </div>
            <div className="sidebar-menu support">
          <li>
            <NavLink activeClassName="act" style={{ textDecoration: "none", marginLeft: "-16px" }} to='/support' exact={true}>
            <img src={support}  /><span className='mx-2'>Support </span>         
            </NavLink>
          </li>
        </div>
            </ul>
  </nav>
        </div>
{/* mobile end  */}
        </div>
    )
}

import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Bottomnav() {
    return (
        <div className='disp-lg-none'>
            <div className='bottom_nav'>
                <NavLink activeClassName="act" style={{ textDecoration: "none", color: "rgba(255, 255, 255, 0.64)", textAlign: "center" }} to='/lender/home' exact={true}>
                    <i className="uil uil-create-dashboard" />
                    <div>Home</div>
                </NavLink>
                <NavLink activeClassName="act" style={{ textDecoration: "none", color: "rgba(255, 255, 255, 0.64)", textAlign: "center" }} to='/lender/calculator' exact={true}>
                    <i className="uil uil-calculator-alt" />
                    <div>Calculator</div>
                </NavLink>
                <NavLink activeClassName="act" style={{ textDecoration: "none", color: "rgba(255, 255, 255, 0.64)", textAlign: "center" }} to='/lender/wallet' exact={true}>
                    <i className="uil uil-wallet" />
                    <div>Wallet</div>
                </NavLink>
                <NavLink activeClassName="act" style={{ textDecoration: "none", color:"rgba(255, 255, 255, 0.64)",textAlign:"center" }} to='/lender/history' exact={true}>
                <i className="uil uil-clock" />
                 <div>History</div>
                 </NavLink>
                <NavLink activeClassName="act" style={{ textDecoration: "none", color: "rgba(255, 255, 255, 0.64)", textAlign: "center" }} to='/lender/profile' exact={true}>
                    <i className="uil icon-profile side-icon" />
                    <div>Profile</div>
                </NavLink>
            </div>
        </div>
    )
}
import React, { Component } from 'react'
import { COMPONENT_TYPE } from '../../../config/string_constants';
export default class alertPopup extends Component {
    render(props) {
        return (
            <div>
                <div className="modal modal-lg right fade" id={this.props.screen} tabindex="-1" role="dialog" aria-labelledby="SendAlert">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                {this.props.screen === COMPONENT_TYPE.INVOICE_MORE_DETAIL &&
                                    <h4 className="modal-title font-style-14 " id="myModalLabel2">Send Alert</h4>}
                                {this.props.screen === COMPONENT_TYPE.INVOICE_DOCUMENTATION &&
                                    <h5 className="modal-title font-style-14 ">Create Request</h5>}
                                <i id={this.props.screen} data-dismiss="modal" aria-label="Close" className='uil-multiply text-white curserP' />
                            </div>
                            <div className="modal-body bgc-dark1">

                                {this.props.screen === COMPONENT_TYPE.INVOICE_MORE_DETAIL &&
                                    <div>
                                        <div className=' m-3 text_tra1'>
                                            <label>Enter the required alert</label>
                                            <textarea style={{ height: "100px" }} placeholder='If any message write here.....' maxLength="50" className='w-100 p-2 input' name="alertMessage" value={this.props.message} onChange={this.props.onChange} ></textarea>
                                            <div className="text-danger"></div>
                                        </div>

                                    </div>

                                }

                                {this.props.screen === COMPONENT_TYPE.INVOICE_DOCUMENTATION &&
                                    <div>
                                        <div className='text_tra1  p-3'>
                                            <p>Raise a request to upload your required documents</p>
                                        </div>

                                        <div className='w-75 mx-5 my-2 text_tra1'>
                                            <label>Enter the required document name</label>
                                            <input className='input' placeholder='Enter the required documents' name="name" maxLength="20" value={this.props.reqDocument.name} onChange={this.props.onChange} />
                                            <div className="text-danger">{this.props.error.name[0] && this.props.dirty.name ? this.props.error.name : ""}</div>
                                        </div>

                                        <div className='mx-5 my-4 w-75 text_tra1'>
                                            <textarea style={{ height: "100px" }} placeholder='If any message write here.....' maxLength="50" className='w-100 p-2 input' name="message" value={this.props.reqDocument.message} onChange={this.props.onChange}></textarea>
                                            <div className="text-danger">{this.props.error.message[0] && this.props.dirty.message ? this.props.error.message : ""}</div>
                                        </div>

                                    </div>

                                }

                                <div className='text-center'>
                                    <button className='btn-blue' onClick={this.props.onSubmit}>Send</button>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

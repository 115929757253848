import React from "react";
import Logo from "../../../assets/images/Logo.svg";
import menu from "../../../assets/images/Menu.png";
import { NavLink } from "react-router-dom";
import support from "../../../assets/images/support.svg";
import { logoutUser } from "../../../services/magic";
import { useHistory } from "react-router-dom";
import { logoutBE } from "../../../api/authenticate";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

export default function MobHeader() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [isLoggedIn, setisLoggedIn] = useState(
    sessionStorage.getItem("isLoggedIn")
  );

  const history = useHistory();
  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  let logout = async () => {
    let res = await logoutBE();
    if (res.data.success) {
      toast.info(res.data.message);
      logoutUser(sessionStorage.getItem("emailOfUser"));
      sessionStorage.clear();
      history.push("/lender/login");
    }
  };

  return (
    <div>
      {/* mobile */}
      <div
        style={{ borderBottom: "1px solid gray" }}
        className='mob-header d-flex justify-content-between d-block d-sm-none p-4'
      >
        <div>
          <img src={Logo} alt='' />
        </div>
        {/* <img  src={menu} alt="" /> */}
        <nav className='navBar'>
          <span
            style={{ fontSize: "25px", position: "sticky" }}
            onClick={handleToggle}
          >
            {navbarOpen ? (
              <i className='uil  uil-multiply text-white' />
            ) : (
              <img src={menu} alt='' />
            )}
          </span>
          <ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
            .<h4 className='text-white ml-4'>Menu</h4>
            <div className='mt-4 sidebar-menu'>
              <li className='invoices-menu'>
                <NavLink
                  activeClassName=''
                  style={{ textDecoration: "none" }}
                  to='/lender/home'
                  exact={true}
                >
                  <i className='uil uil-create-dashboard' /> <span>Home</span>
                </NavLink>
              </li>
              <li className='invoices-menu'>
                <NavLink
                  activeClassName=''
                  style={{ textDecoration: "none" }}
                  to='/lender/calculator'
                  exact={true}
                >
                  <i className='uil uil-calculator-alt' /> <span>Calculator</span>
                </NavLink>
              </li>
              <li className='invoices-menu'>
                <NavLink
                  activeClassName=''
                  style={{ textDecoration: "none" }}
                  to='/lender/wallet'
                  exact={true}
                >
                  <i className='uil uil-wallet' /> <span>Wallet</span>
                </NavLink>
              </li>
            </div>
            <div>
              <div className='sidebar-menu'>
                <li className='invoices-menu'>
                  <NavLink
                    activeClassName=''
                    style={{ textDecoration: "none" }}
                    to='/lender/history'
                    exact={true}
                  >
                    <i className='uil uil-clock' /> <span>History</span>
                  </NavLink>
                </li>
                <li className='invoices-menu'>
                  <NavLink
                    activeClassName=''
                    style={{ textDecoration: "none" }}
                    to='/lender/profile'
                    exact={true}
                  >
                    <i className='uil icon-profile side-icon mr-2' /> <span>Profile</span>
                  </NavLink>
                </li>
              </div>

              <div className='m-2 mt-5'>
                {isLoggedIn === "true" ? (
                  <div>
                    <button className='btn-blue w-100' onClick={logout}>
                      Logout
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      className='btn-blue w-100'
                      onClick={() => {
                        history.push("/lender/login");
                      }}
                    >
                      Login
                    </button>
                  </div>
                )}
              </div>

             
            </div>
          </ul>
        </nav>
      </div>
      {/* mobile end  */}
    </div>
  );
}

import React from "react";
import Sidebar from "../Dashboard/Sidebar";
import "../../../assets/css/invoices.css";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import { Link } from "react-router-dom";
import Invoice from "../../../assets/images/invoice.svg";
import MainHeader from "../Dashboard/mainHeader";
import { withRouter } from "react-router-dom";
import { getCompany } from "../../../services/actions/companyActions";
import Bottomnav from "../Dashboard/bottomnav";
import Mob_header from "../Dashboard/mob_header";
import { URLS } from "../../../config/frontendUrls";

class AddBuyer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buyers: [],
      filteredSuggestions: [],
      userInput: "",
    };
  }
  handleSearch = (e) => {
    const input = e.target.value;
    this.setState(
      {
        userInput: e.target.value,
      },
      () => {}
    );

    if (this.state.userInput !== null) {
      const filteredSuggestions = this.state.buyers.filter((data) =>
        data.company_name.includes(this.state.userInput.toLowerCase() || "")
      );
      // const filteredSuggestions = this.state.buyers.filter(data => data.company.company_name.toLowerCase().includes(input.toLowerCase()))

      this.setState({
        filteredSuggestions: filteredSuggestions,
      });
    }
  };

  onAddBuyer = () => {
    this.props.history.push(URLS.CLIENT.NEW_BUYER);
  };

  componentDidMount() {
    this.props.getCompany();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_isEmpty(nextProps.company.company)) {
      this.setState({ buyers: nextProps.company.company });
    }
  }

  render() {
    return (
      <div>
        <Sidebar /> <Bottomnav />
        <div className="main-content">
          <Mob_header />
          <header className="header1">
            <div>
              <h2 className="header_title">Buyers</h2>
            </div>
            <div>
              <div className="d-flex justify-content-end">
                <MainHeader />
              </div>
            </div>
          </header>
          {/* <div style={{margin:"41px",padding:"30px",border:"1px solid gray",borderRadius:"4px",position:"relative"}} className='text-center mt-5 ' >
        <h5 className='text-white mb-4'>Search or Add new buyer</h5>
        <span className='d-flex justify-content-end'>
        <img style={{position:"absolute",marginTop:"11px",marginRight:"12px"}} src={Search}></img>

      <input className='homesearchbox w-100' type="text" placeholder="Enter Company Name" name="search"
      name="userInput" value={this.state.userInput} onChange={this.handleSearch}/>
   </span>
   {this.state.userInput ?  <div className='d-flex bgc-dark1 p-2 py-3 justify-content-between border-bottom txt_b'>
    <div>Create new buyer called “{this.state.userInput}”</div>
    <div> <i className='uil-plus-circle font20' /></div>
   </div>
   :""}
  
 

   <div>
        {this.state.userInput==""?"":this.state.filteredSuggestions.map((item,key)=>(

            <div className='text-white'>
            
              <div className='bgc-dark1 p-2 border-bottom' key={key}>
                <div className='d-flex'>
                <h5 className='mx-3'>{item.company_name}</h5>.
                <div className='mx-3'>{item.website}</div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='text_tra1'><i className='uil-envelope mx-2' />{item.company_email_id}</div>
                  <div className='text_tra1'><i className='uil-calling mx-2' />{item.company_phone_no}</div>
                  <div className='text_tra1'><i className='uil-location-point mx-2' />{item.address_line1}</div>

                </div>

              </div>

            </div>

))}
    </div>
        
        </div> */}
          <div
            className="my-auto"
            style={{ position: "relative", height: "100%" }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
            >
              <img src={Invoice} />
              <p
                style={{
                  color: "white",
                  paddingBottom: "12px",
                  paddingTop: "12px",
                }}
              >
                Let's start with adding your first Buyer{" "}
              </p>
              <button
                variant="primary"
                onClick={this.onAddBuyer}
                className="btn-blue"
              >
                + Add Buyer
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  company: state.company,
});

const mapDispatchToProps = (dispatch) => ({
  getCompany: () => dispatch(getCompany()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddBuyer));

import React from "react";
import _isEmpty from "lodash/isEmpty";
import Sidebar from "../Dashboard/Sidebar";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import {
  getAllInvoices,
  postInvoice,
  updateInvoice,
  deleteInvoice,
  getInvoiceCreatedByUser,
} from "../../../services/actions/invoiceAction";
import {
  postUser,
  updateUser,
  getEmailUser,
} from "../../../services/actions/userAction";
import { getCompaniesByCreatedByAndApproved } from "../../../services/actions/companyActions";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/invoices.css";
import "../../../assets2/css/profile_page.css";
import "../../../assets2/css/profile.css";
import Invoice_svg from "../../../assets/images/invoice.svg";
import helper from "../../../utils/helper";
import download from "../../../assets/images/download1.png";
import MainHeader from "../Dashboard/mainHeader";
import { postActivity } from "../../../services/actions/activityActions";
import Select from "react-select";
import { URI } from "../../../constant";
import Search from "../../../assets/images/search.png";
import { getCountry } from "../../../services/actions/countryActions";
import { getInvoiceStatus } from "../../../services/actions/invoiceStatusAction";
import { withRouter } from "react-router-dom";
import Bottomnav from "../Dashboard/bottomnav";
import Mob_header from "../Dashboard/mob_header";
import Spinner from "react-bootstrap/Spinner";
import ajax from "../../../utils/ajax";
import Paginations from "../../pagination";
import { ADMIN_ALERT_TYPES } from "../../../config/string_constants";
import Kycpopup from "../Dashboard/kycpopup";
import { URLS } from "../../../config/frontendUrls";
import DialCodeList from "../Common/dialCodeList";

class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPageLoader: true,
      userInvoiceData: undefined,
      paginationItem: "",
      showModal: false,
      modalKyc: false,
      selectedBuyer: "",
      fields: {
        _id: null,
        invoice_date: "",
        invoice_due_date: new Date().toISOString().split("T")[0],
        invoice_amount: "",
        invoice_availabe_amount: "",
        contact_person_id: "",
        admin_notes: "",
        invoice_pdf_link: "",
        client_company: sessionStorage.getItem("uCompany"),
        client_company_name: sessionStorage.getItem("uCompanyName"),
        invoice_status_id: 1,
        created_by_id: sessionStorage.getItem("userId"),
        created_on: new Date(),
        updated_by_id: "",
        updated_on: "",
        buyer_company: "",
        full_name: "",
        designation: "",
        phone_number: "",
        country_id: "",
        contact_dialCode: "",
        currency: "",
        supplier_invoice_number: "",
        invoiceStatus_id: "",
        update_type: ADMIN_ALERT_TYPES.INVOICE_SUBMITED,
      },
      activity: {
        activity_name: "",
        service_name: "",
        createdBy: {
          name: sessionStorage.getItem("uName"),
          email: sessionStorage.getItem("uEmail"),
          id: sessionStorage.getItem("userId"),
        },
      },
      searchCompany: "",
      file: null,
      addUserLoader: false,
      addInvoiceLoader: false,
      isLoader: false,
      uploadFileLoader: false,
      updateUserLoader: false,
      updateInvoiceLoader: false,
      deleteLoader: false,
      companyData: "",
      selectedCompany: "",
      companyAllData: [],
      inovice_data: "",
      searchInvoice: "",
      removeInvoiceModal: "",
      removeInvoiceId: "",
      country: [],
      invoiceStatus: [],
      userDetails: [],
      dirty: {
        invoice_date: false,
        invoice_due_date: false,
        invoice_amount: false,
        invoice_pdf_link: false,
        full_name: false,
        designation: false,
        phone_number: false,
        country_id: false,
        contact_dialCode: false,
        currency: false,
        supplier_invoice_number: false,
        company_name: false,
        company_email_id: false,
        buyer_company: false,
        email: false,
      },
      errors: {
        invoice_date: [],
        invoice_due_date: [],
        invoice_amount: [],
        invoice_pdf_link: [],
        full_name: [],
        designation: [],
        phone_number: [],
        contact_dialCode: [],
        currency: [],
        supplier_invoice_number: [],
        company_name: [],
        company_email_id: [],
        buyer_company: [],
        email: [],
      },
    };
  }
  pageChange = async (index) => {
    const userData = await ajax(
      "GET",
      `${URI}/invoice/user/${sessionStorage.getItem("userId")}?page=${index}`,
      {}
    );
    if (userData.data) {
      this.setState({
        ...this.state,
        userInvoiceData: userData.data.data,
        paginationItem: userData.data
      });
    }
  }

  componentDidMount() {
    this.props.getCountry().then((res) => {
      this.setState({ country: res });
    });
    this.props.getInvoiceStatus().then((res) => {
      this.setState({ invoiceStatus: res });
    });
    this.props
      .getCompaniesByCreatedByAndApproved(sessionStorage.getItem("userId"))
      .then((res) => {
        if (this.props.match.params.id) {
          for (let i = 0; i < res.length; i++) {
            if (this.props.match.params.id === res[i]._id) {
              this.setState({
                selectedBuyer: res[i],
              });
              break;
            }
          }
        }
        this.setState({
          companyAllData: res,
        });
      });
    this.props
      .getInvoiceCreatedByUser(sessionStorage.getItem("userId"))
      .then((res) => {
        this.setState({
          ...this.state,
          showPageLoader: false,
          userInvoiceData: res.data,
          paginationItem: res,
        });
      });
    this.props.getEmailUser(sessionStorage.getItem("uEmail")).then((result) => {
      this.setState(
        {
          userDetails: result[0],
        },
        () => {
          if (this.props.match.params.id) {
            this.handleOpenInvoiceWithCompanyIdModal();
          }
        }
      );
    });
  }

  jsonEscape = (str) => {
    return str.replace(/\n/g, "");
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { fields } = this.state;

    if (
      nextProps.invoice.type === "RECEIVED_CREATED_BY_USER_INVOICE_CONSTANTS" &&
      this.state.showPageLoader
    ) {
      this.setState({
        showPageLoader: false,
      });
    }

    if (!_isEmpty(nextProps.invoice.invoiceDelete) && this.state.deleteLoader) {
      setTimeout(() => toast.info("Invoice Deleted Successfully"), 200);
      this.setState({
        deleteLoader: false,
        showPageLoader: true,
      });
      if (this.state.userInvoiceData.length === 1) {
        this.setState({
          userInvoiceData: [],
        });
      }
      this.props.getInvoiceCreatedByUser(sessionStorage.getItem("userId"));
    }
  }
  handleOpenInvoiceWithCompanyIdModal = () => {
    this.setState({
      showModal: !this.state.showModal,
      fields: {
        _id: null,
        invoice_date: "",
        invoice_due_date: "",
        invoice_amount: "",
        admin_notes: "",
        invoice_availabe_amount: "",
        contact_person_id: "",
        invoice_pdf_link: "",
        invoice_status_id: 1,
        created_by_id: sessionStorage.getItem("userId"),
        client_company: sessionStorage.getItem("uCompany"),
        client_company_name: sessionStorage.getItem("uCompanyName"),
        created_on: new Date(),
        updated_by_id: "",
        updated_on: "",
        buyer_company: this.props.match.params.id,
        full_name: this.state.userDetails.full_name,
        designation: this.state.userDetails.designation,
        phone_number: this.state.userDetails.phone_number,
        contact_dialCode: this.state.userDetails.user_dialCode,
        email: this.state.userDetails.email,
        update_type: ADMIN_ALERT_TYPES.INVOICE_SUBMITED,
      },
      isLoader: false,
    });
  };
  handleOpenInvoiceModal = () => {
    this.setState({
      showModal: !this.state.showModal,
      fields: {
        _id: null,
        invoice_date: "",
        invoice_due_date: "",
        invoice_amount: "",
        admin_notes: "",
        invoice_availabe_amount: "",
        contact_person_id: "",
        invoice_pdf_link: "",
        invoice_status_id: 1,
        created_by_id: sessionStorage.getItem("userId"),
        client_company: sessionStorage.getItem("uCompany"),
        client_company_name: sessionStorage.getItem("uCompanyName"),
        created_on: new Date(),
        updated_by_id: "",
        updated_on: "",
        buyer_company: "",
        full_name: this.state.userDetails.full_name,
        designation: this.state.userDetails.designation,
        phone_number: this.state.userDetails.phone_number,
        contact_dialCode: this.state.userDetails.user_dialCode,
        email: this.state.userDetails.email,
        update_type: ADMIN_ALERT_TYPES.INVOICE_SUBMITED,
      },

      dirty: {
        invoice_date: false,
        invoice_due_date: false,
        invoice_amount: false,
        invoice_pdf_link: false,
        full_name: false,
        designation: false,
        phone_number: false,
        country_id: false,
        contact_dialCode: false,
        currency: false,
        supplier_invoice_number: false,
        company_name: false,
        company_email_id: false,
        buyer_company: false,
        email: false,
      },
      errors: {
        invoice_date: [],
        invoice_due_date: [],
        invoice_amount: [],
        invoice_pdf_link: [],
        full_name: [],
        designation: [],
        phone_number: [],
        contact_dialCode: [],
        currency: [],
        supplier_invoice_number: [],
        company_name: [],
        company_email_id: [],
        buyer_company: [],
        email: [],
      },
      selectedBuyer: "",
      isLoader: false,
    });
  };

  handleSearch = (e) => {
    this.setState({
      searchInvoice: e.target.value,
    });
  };
  handleChange = (event) => {
    const { name, value } = event.target;

    let dirty = this.state.dirty;
    dirty[name] = true;

    let fields = this.state.fields;
    fields[name] = value;

    this.setState(
      {
        fields: fields,
        dirty: dirty,
      },
      this.handleValidation
    );
  };

  handleChangeNew = (event) => {
    let { fields1 } = this.state;
    switch (event.target.id) {
      default:
        fields1[event.target.id] = event.target.value;
    }
    this.setState({ fields1 });
  };

  onChange = (e) => {
    if (helper.validateFileType(e.target.files)) {
      this.setState({
        file: URL.createObjectURL(e.target.files[0]),
        errors: {
          ...this.state.errors,
          invoice_pdf_link: "",
        },
      });
      this.uploadFile(e.target.files[0]);
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          invoice_pdf_link:
            "Please Upload file in JPG, PNG, PDF and max size 5mb",
        },
        uploadFileLoader: false,
        dirty: {
          ...this.state.dirty,
          invoice_pdf_link: true,
        },
      });
    }
  };

  uploadFile(file) {
    this.setState({ uploadFileLoader: true });
    const formData = new FormData();

    formData.append("image", file);

    ajax(
      "POST",
      `${URI}/api/v1/image-upload`,
      {},
      { "content-type": "multipart/form-data" },
      formData
    )
      .then((data) => {
        let { fields } = this.state;
        fields.invoice_pdf_link = data.data.imageUrl;

        this.setState({
          fields,
          errors: {
            ...this.state.errors,
            invoice_pdf_link: [],
          },
          uploadFileLoader: false,
          dirty: {
            ...this.state.dirty,
            invoice_pdf_link: false,
          },
        });
      })
      .catch((err) => { });
  }

  handleValidation = () => {
    let errors = this.state.errors;
    let formIsValid = true;

    Object.keys(errors).forEach((control) => {
      errors[control] = [];
      var validSupplierNo = /^[\w /#-\s]+$/;
      var validEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      var validName = /^[A-Za-z_'-\s]+$/;
      var validFile = /(\.jpg|\.jpeg|\.pdf|\.png)$/i;

      switch (control) {
        case "supplier_invoice_number":
          if (!this.state.fields[control]) {
            errors[control].push("Invoice number is required");
          }
          if (
            this.state.fields[control] &&
            !validSupplierNo.test(this.state.fields[control])
          ) {
            errors[control].push("Invalid invoice number");
          }

          if (
            this.state.fields[control] &&
            this.state.fields[control].length < 3
          ) {
            errors[control].push("Minimum 4 characters for invoice number");
          }
          break;

        case "invoice_date":
          if (!this.state.fields[control]) {
            errors[control].push("This field is required");
            formIsValid = false;
          }
          if (this.state.fields[control]) {
            if (moment().diff(moment(this.state.fields[control]), "days") < 0) {
              errors[control].push("Invoice Date is greater than current date");
            }
            if (this.state.fields[control] == "") {
              errors[control].push("Invoice Date is required");
            }
          }
          break;

        case "invoice_due_date":
          if (!this.state.fields[control]) {
            errors[control].push("This field is required");
            formIsValid = false;
          }
          if (this.state.fields[control]) {
            if (moment().diff(moment(this.state.fields[control]), "days") > 0) {
              errors[control].push(
                "Invoice Due  Date is lesser than current date"
              );
            }
            if (this.state.fields[control] == "") {
              errors[control].push("Invoice Due Date is required");
            }
          }
          break;

        case "invoice_amount":
          if (this.state.fields[control] === "") {
            errors[control].push("Please enter invoice amount");
            formIsValid = false;
          }
          break;

        case "currency":
          if (!this.state.fields[control]) {
            errors[control].push("This field is required");
            formIsValid = false;
          }
          break;
        case "invoice_pdf_link":
          if (this.state.fields[control] === "") {
            errors[control].push("Please Upload Invoice file");
            formIsValid = false;
          }
          break;
        case "email":
          if (!this.state.fields[control]) {
            errors[control].push("Please enter email");
            formIsValid = false;
          }
          if (this.state.fields[control]) {
            if (!validEmail.test(this.state.fields[control])) {
              errors[control].push("Please enter valid email");
              formIsValid = false;
            }
          }
          break;
        case "full_name":
          if (!this.state.fields[control]) {
            if (!validName.test(this.state.fields[control])) {
              errors[control].push("Invalid name");
              formIsValid = false;
            }
          }
          break;
        case "designation":
          if (!this.state.fields[control]) {
            if (!validName.test(this.state.fields[control])) {
              errors[control].push("Invalid designation");
              formIsValid = false;
            }
          }
          break;
        case "buyer_company":
          if (!this.state.fields[control]) {
            errors[control].push("Please select buyer");
            formIsValid = false;
          }
          break;
        case "phone_number":
          if (!this.state.fields[control]) {
            errors[control].push("Please enter phone number");
            formIsValid = false;
          }
          let length = this.state.fields[control].length;
          if (this.state.fields[control] && (length < 6 || length > 12)) {
            errors[control].push("Number min 6 max 12");
            formIsValid = false;
          }
          break;
        case "contact_dialCode":
          if (!this.state.fields[control]) {
            errors[control].push("Please enter contact dial code");
            formIsValid = false;
          }
          break;
        default:
          break;
      }
    });
    this.setState({ errors });
    return formIsValid;
  };

  blurChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };
  isValid = () => {
    let valid = true;

    for (let control in this.state.errors) {
      if (this.state.errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };
  handleSubmit = async () => {
    const { fields } = this.state;
    this.handleValidation();
    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });

    if (this.isValid()) {
      if (fields._id === null) {
        this.setState({
          isLoader: true,
          3: true,
        });
      }
      this.setState(
        {
          fields,
        },
        () => {
          this.props.postInvoice(this.state.fields).then((res) => {
            if (res) {
              if (this.props.match.params.id) {
                this.props.history.push(URLS.CLIENT.INVOICES);
              }
              let activityName = "Added Invoice";
              let serviceName = "CreateInvoice";
              this.setState({
                activity: {
                  ...this.state.activity,
                  activity_name: activityName,
                  service_name: serviceName,
                },
                showModal: !this.state.showModal,
              });
              this.props.postActivity(this.state.activity);
              toast.info("Invoice Uploaded Successfully");
              this.props
                .getInvoiceCreatedByUser(sessionStorage.getItem("userId"))
                .then((res) => {
                  this.setState({
                    ...this.state,
                    showPageLoader: false,
                    userInvoiceData: res.data,
                  });
                });
            } else {
              this.setState({
                ...this.state,
                showPageLoader: false,
                isLoader: false,
              });
            }
          });
        }
      );
    }
  };

  changeInvoiceValue = (data) => {
    const { fields, fields1 } = this.state;

    fields.invoice_id = data.invoice_id;
    fields.invoice_pdf_link = data.invoice_pdf_link;
    fields.invoice_date = moment(data.invoice_date).format("YYYY-MM-DD");
    fields.invoice_due_date = moment(data.invoice_due_date).format(
      "YYYY-MM-DD"
    );
    fields.invoice_amount = data.invoice_amount;
    fields.invoice_availabe_amount = data.invoice_availabe_amount;
    fields.buyer_company = data.buyer_company._id;
    // fields.company.company_name=data.company.company_name;
    // fields.company.company_email_id=data.company.company_email_id;
    fields._id = data._id;
    fields.contact_person_id = sessionStorage.getItem("userId");
    fields.email = data.email;
    fields.full_name = data.full_name;
    fields.phone_number = data.phone_number;
    fields.designation = data.designation;
    fields.contact_dialCode = data.contact_dialCode;
    fields.currency = data.currency;
    fields.supplier_invoice_number = data.supplier_invoice_number;
    fields.invoiceStatus_id = data.invoiceStatus_id;

    this.setState({
      companyData: data.buyer_company.company_name,
    });
    this.setState({
      fields,
      fields1,
      showModal: true,
    });
  };

  handleCompanyChange = (event, options) => {
    this.setState({
      ...this.state,
      companyData: event.value,
    });

    let dirty = this.state.dirty;
    dirty[options.name] = true;

    let fields = this.state.fields;
    fields[options.name] = event.value;

    this.setState(
      {
        fields: fields,
        dirty: dirty,
      },
      this.handleValidation
    );
  };

  handleClose = () => {
    this.setState({ removeInvoiceModal: false });
  };
  removeInvoice = (invoiceId) => {
    this.setState({
      removeInvoiceId: invoiceId,
      removeInvoiceModal: true,
    });
  };
  removeInvoiceConfirm = () => {
    this.setState({
      removeInvoiceModal: false,
      deleteLoader: true,
    });
    this.props.deleteInvoice(this.state.removeInvoiceId);

    this.setState(
      {
        activity: {
          ...this.state.activity,
          activity_name: "Deleted Invoice",
          service_name: "DeleteInvoice",
        },
      },
      () => this.props.postActivity(this.state.activity)
    );
  };
  handleInvoiceDetails = (id) => {
    this.props.history.replace(`${URLS.CLIENT.INVOICES_DETAILS}/${id}`);
  };

  render() {
    const style = {
      control: (base) => ({
        ...base,
        border: 0,
        // This line disable the blue border
        boxShadow: "none",
      }),
    };

    if (this.state.showPageLoader) {
      return (
        <div id="cover-spin">
          <center>
            <Spinner
              style={{ marginTop: "300px", width: "6rem", height: "6rem" }}
              animation="border"
              variant="info"
            />
            {/* <img src={ Loader } style={{height:'126px',marginTop:'300px'}} alt="lazy-loader"/> */}
          </center>
        </div>
      );
    }

    const { userInvoiceData } = this.state;

    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Sidebar />
        <Bottomnav />
        <div className="main-content">
          <Mob_header />
          <header className="header1">
            <div className="header_title">
              <h2 className="heading">Invoices</h2>
            </div>
            <div>
              <div className="d-flex justify-content-end">
                <MainHeader />
                <button
                  variant="primary"
                  className="btn-blue d-block d-sm-none"
                  onClick={this.handleOpenInvoiceModal}
                >
                  + Add Invoice
                </button>
              </div>
            </div>
          </header>
          <Kycpopup
            show={this.state.modalKyc}
            buyerData={true}
            onHide={() => this.setState({ modalKyc: false })}
          />
          {userInvoiceData === undefined ? (
            <div style={{ position: "relative", height: "100%" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                }}
              >
                <img src={Invoice_svg} />
                <p
                  style={{
                    color: "white",
                    paddingBottom: "12px",
                    paddingTop: "12px",
                  }}
                >
                  Let's start with adding your first Invoice{" "}
                </p>
                <button
                  variant="primary"
                  className="btn-blue"
                  onClick={() =>
                    this.props.company.company.length == 0
                      ? this.setState({ modalKyc: true })
                      : this.handleOpenInvoiceModal()
                  }
                >
                  + Add Invoice
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="m-4  d-flex  justify-content-between">
                <span className="d-flex justify-content-end">
                  <img
                    style={{ position: "absolute", margin: "12px" }}
                    src={Search}
                    alt="img"
                  ></img>

                  <input
                    className="homesearchbox"
                    type="text"
                    placeholder="Search"
                    value={this.state.searchInvoice}
                    onChange={this.handleSearch}
                  />
                </span>
                <div className="mt-sm-6">
                  <span className="sortby">
                    Sort by <i className="uil uil-angle-down" />
                  </span>

                  <button
                    variant="primary"
                    className="btn-blue disp_non"
                    onClick={this.handleOpenInvoiceModal}
                  >
                    + Add Invoice
                  </button>
                </div>
              </div>

              <div
                style={{ marginTop: "40px", overflowX: "auto" }}
                className="table-content d-none d-sm-block"
              >
                <table>
                  <thead>
                    <tr className="top">
                      <th>Invoices Ref No.</th>
                      <th>Supplier Invoice No.</th>
                      <th>Buyer</th>
                      <th>Invoice Date</th>
                      <th>Due Date</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {userInvoiceData !== undefined &&
                      userInvoiceData
                        .filter((data) => {
                          return data.buyer_company.company_name
                            .toLowerCase()
                            .includes(this.state.searchInvoice.toLowerCase());
                        })
                        .map((key) => (
                          <tr
                            className="borderw curserP"
                            key={key.invoice_id}
                            onClick={() => {
                              this.handleInvoiceDetails(key._id);
                            }}
                          >
                            <td>
                              <span className="invoice_id txt_b">
                                {key.invoice_id}
                              </span>
                            </td>
                            <td>{key.supplier_invoice_number}</td>
                            <td>{key.buyer_company.company_name}</td>
                            <td>
                              {moment(key.invoice_date).format("DD/MM/YYYY")}
                            </td>
                            <td>
                              {moment(key.invoice_due_date).format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td>
                              {key.currency}
                              {" " +
                                Number(key.invoice_amount)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </td>
                            <td>
                              {key.invoiceStatus_id !== undefined
                                ? key.invoiceStatus_id.subStatus !== undefined
                                  ? key.invoiceStatus_id.subStatus.map((sub) =>
                                    sub._id === key.invoiceSubStatus_id ? (
                                      <span
                                        style={{
                                          color: sub.textColor,
                                          padding: "8px",
                                          backgroundColor:
                                            sub.backgroundColor,
                                          borderRadius: "4px",
                                        }}
                                      >
                                        {key.invoiceStatus_id.displayName.toUpperCase()}
                                      </span>
                                    ) : (
                                      ""
                                    )
                                  )
                                  : ""
                                : ""}
                            </td>
                            <td>
                              <a href={key.invoice_pdf_link}>
                                <img src={download} alt="img" />
                              </a>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <div className="d-flex justify-content-end mx-4 mt-2">
                  <Paginations item={this.state.paginationItem} pageClick={this.pageChange} />
                </div>
              </div>
              {/* mobile view table */}
              <div className="d-block m-3 d-sm-none">
                {userInvoiceData !== undefined &&
                  userInvoiceData
                    .filter((data) => {
                      return data.buyer_company.company_name
                        .toLowerCase()
                        .includes(this.state.searchInvoice.toLowerCase());
                    })
                    .map((key) => (
                      <div
                        className="my-3 py-2 borderR"
                        style={{ background: "#333" }}
                      >
                        <div className="row m-1 borderw ">
                          <div className="text_tra1 col-4">
                            Invoices Ref No.
                            <div
                              onClick={() => {
                                this.handleInvoiceDetails(key._id);
                              }}
                              className="font14 txt_b"
                            >
                              {key.invoice_id}
                            </div>
                          </div>
                          <div className="text_tra1 col-4">
                            Buyer
                            <div className="text-white font14">
                              {key.buyer_company.company_name}
                            </div>
                          </div>
                          <div className="text_tra1 col-4">
                            Invoice Date
                            <div className="font14 text-white">
                              {moment(key.invoice_date).format("DD/MM/YYYY")}
                            </div>
                          </div>
                        </div>
                        <div className="row m-1 borderw ">
                          <div className="text_tra1 col-4 ">
                            Due Date
                            <div className="text-white font14">
                              {moment(key.invoice_due_date).format(
                                "DD/MM/YYYY"
                              )}
                            </div>
                          </div>
                          <div className="text_tra1 col-4">
                            Amount
                            <div className="text-white font14">
                              {"$" +
                                Number(key.invoice_amount)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </div>
                          </div>
                          <div className="text_tra1 col-4">
                            Supplier Invoice No.
                            <div className="text-white font14">
                              {key.supplier_invoice_number}
                            </div>
                          </div>
                        </div>
                        <div className="ml-3">
                          <div className="text_tra1 w-100 ">Status</div>
                          <div>
                            {key.invoiceStatus_id !== undefined
                              ? key.invoiceStatus_id.subStatus !== undefined
                                ? key.invoiceStatus_id.subStatus.map((sub) =>
                                  sub._id === key.invoiceSubStatus_id ? (
                                    <div
                                      style={{
                                        color: sub.textColor,
                                        padding: "6px",
                                        backgroundColor: sub.backgroundColor,
                                        borderRadius: "4px",
                                        width: "max-content",
                                      }}
                                    >
                                      {key.invoiceStatus_id.displayName.toUpperCase()}
                                    </div>
                                  ) : (
                                    ""
                                  )
                                )
                                : ""
                              : ""}
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
              {/* mobile view end */}
            </div>
          )}
        </div>

        <Modal
          show={this.state.showModal}
          onHide={this.handleOpenInvoiceModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ backgroundColor: "black" }}
        >
          <div
            style={{ backgroundColor: "#333333", color: "white", border: "0" }}
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="contained-modal-title-vcenter"
                style={{ fontSize: "16px", lineHeight: "24px" }}
              >
                Upload Invoice
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: "left" }}>
              <div style={{ margin: "24px" }}>
                <div className="row">
                  <div className="col-sm-4 col-12">
                    <label className="label" htmlFor="">
                      Supplier Invoice Number
                    </label>
                    <div>
                      {" "}
                      <input
                        className={"input"}
                        type="text"
                        placeholder=""
                        name="supplier_invoice_number"
                        id="supplier_invoice_number"
                        onChange={this.handleChange}
                        onBlur={this.blurChange}
                        value={this.state.fields.supplier_invoice_number}
                        maxLength="20"
                      />
                      <div className="text-danger">
                        {this.state.errors.supplier_invoice_number[0] &&
                          this.state.dirty.supplier_invoice_number
                          ? this.state.errors.supplier_invoice_number
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 col-12 mt-20">
                    <label className="label" htmlFor="">
                      Upload Invoice{" "}
                      {this.state.uploadFileLoader ? (
                        <span
                          style={{ width: "1.5rem", height: "1.5rem" }}
                          className="spinner-border mx-3 btn--icon"
                        ></span>
                      ) : (
                        ""
                      )}
                    </label>

                    {this.state.fields.invoice_pdf_link ? (
                      <div
                        className="bgc-dark p-2"
                        style={{ overflow: "hidden" }}
                      >
                        {this.state.fields.invoice_pdf_link
                          .substring(50)
                          .replace(/%20/g, " ")}
                      </div>
                    ) : (
                      <div
                        className={"p-1"}
                        style={{
                          borderRadius: "4px",
                          border: "1px solid gray",
                          overflow: "hidden",
                        }}
                      >
                        {" "}
                        <input
                          type="file"
                          name="myImage"
                          onChange={this.onChange}
                          onBlur={this.blurChange}
                        />{" "}
                      </div>
                    )}
                    <div className="text-danger">
                      {this.state.errors.invoice_pdf_link[0] &&
                        this.state.dirty.invoice_pdf_link
                        ? this.state.errors.invoice_pdf_link
                        : ""}
                    </div>
                  </div>
                  <div className="col-sm-4 col-12 mt-20">
                    <div>
                      <label className="label" htmlFor="">
                        Currency
                      </label>
                      {this.state.country === undefined ? (
                        <select
                          className={
                            this.state.errors.currency
                              ? "form-control12 input-error"
                              : "form-control12"
                          }
                        >
                          <option
                            style={{ background: "#333333", color: "grey" }}
                          >
                            Loading
                          </option>
                        </select>
                      ) : (
                        <select
                          style={{ background: "#333333" }}
                          className="form-select wit_th"
                          id="currency"
                          name="currency"
                          value={this.state.fields.currency}
                          onChange={this.handleChange}
                          onBlur={this.blurChange}
                        >
                          <option>Select Currency</option>
                          {this.state.country
                            .filter((data) => {
                              return data.currency === "USD";
                            })
                            .map((item) => (
                              <option
                                style={{ background: "#212022", color: "grey" }}
                                key={item._id}
                                value={item.currency}
                              >
                                {item.currency}
                              </option>
                            ))}
                        </select>
                      )}
                      <div className="text-danger">
                        {this.state.errors.currency[0] &&
                          this.state.dirty.currency
                          ? this.state.errors.currency
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 mt-2 mt-20">
                    <label className="label" htmlFor="">
                      Select Buyer
                    </label>
                    {this.state.selectedBuyer ? (
                      <input
                        type="text"
                        style={{
                          backgroundColor: "#333333",
                          border: "1px solid #595959",
                          color: "white",
                          height: "50px",
                          padding: "10px",
                        }}
                        value={this.state.selectedBuyer.company_name}
                        disabled="true"
                      />
                    ) : (
                      <Select
                        name="buyer_company"
                        options={
                          this.props.company.company !== undefined &&
                          this.props.company.company &&
                          Object.entries(this.props.company.company).map(
                            ([value, thisState]) => ({
                              label: thisState.company_name,
                              value: thisState._id,
                            })
                          )
                        }
                        // value={}
                        onChange={this.handleCompanyChange}
                        onBlur={this.blurChange}
                        styles={{
                          input: (provided, state) => ({
                            ...provided,
                            color: "white",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused && "lightgray",
                            color: state.isFocused && "#333333",
                          }),
                        }}
                      />
                    )}
                    <div className="text-danger">
                      {this.state.errors.buyer_company[0] &&
                        this.state.dirty.buyer_company
                        ? this.state.errors.buyer_company
                        : ""}
                    </div>
                  </div>
                  <div className="col-sm-6 mt-20 mt-2">
                    <label className="label" htmlFor="">
                      Invoice Amount
                    </label>
                    <input
                      className={"input"}
                      type="number"
                      placeholder=""
                      name="invoice_amount"
                      id="invoice_amount"
                      onChange={this.handleChange}
                      onBlur={this.blurChange}
                      value={this.state.fields.invoice_amount}
                    />
                    <div className="text-danger">
                      {this.state.errors.invoice_amount[0] &&
                        this.state.dirty.invoice_amount
                        ? this.state.errors.invoice_amount
                        : ""}
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ margin: "24px" }} className="row">
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-sm-6">
                      <label className="label" htmlFor="">
                        Invoice Date
                      </label>
                      <div className=" d-flex justify-content-end">
                        <i
                          className="uil-angle-down txt_b"
                          style={{
                            position: "absolute",
                            marginTop: "10px",
                            marginLeft: "90%",
                          }}
                        ></i>
                        <input
                          className={"input"}
                          max={new Date().toISOString().split("T")[0]}
                          type="date"
                          placeholder="DD/MM/YYYY"
                          name="invoice_date"
                          id="invoice_date"
                          onChange={this.handleChange}
                          onBlur={this.blurChange}
                          value={this.state.fields.invoice_date}
                        />
                      </div>
                      <div className="text-danger">
                        {this.state.errors.invoice_date[0] &&
                          this.state.dirty.invoice_date
                          ? this.state.errors.invoice_date
                          : ""}
                      </div>
                    </div>
                    <div className="col-sm-6 mt-20">
                      <label className="label" htmlFor="">
                        Due Date
                      </label>
                      <div className=" d-flex justify-content-end">
                        <i
                          className="uil-angle-down txt_b"
                          style={{
                            position: "absolute",
                            marginTop: "10px",
                            marginLeft: "90%",
                          }}
                        ></i>

                        <input
                          className={"input"}
                          min={new Date().toISOString().split("T")[0]}
                          type="date"
                          placeholder="DD/MM/YYYY"
                          name="invoice_due_date"
                          id="invoice_due_date"
                          onChange={this.handleChange}
                          onBlur={this.blurChange}
                          value={this.state.fields.invoice_due_date}
                        />
                      </div>
                      <div className="text-danger">
                        {this.state.errors.invoice_due_date[0] &&
                          this.state.dirty.invoice_due_date
                          ? this.state.errors.invoice_due_date
                          : ""}
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2 ">
                      <label className="label">Tenure </label>
                      <div className="borderx input">
                        <div className="mt-2">
                          {this.state.fields.invoice_due_date &&
                            this.state.fields.invoice_date
                            ? moment(this.state.fields.invoice_due_date).diff(
                              moment(this.state.fields.invoice_date),
                              "days"
                            )
                            : 0}{" "}
                          Days{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="label">Notes to Admin (if any) </label>
                      <div >
                        <textarea className="text-area" rows="6" placeholder='Write here.....' name="admin_notes" value={this.state.fields.admin_notes} onChange={this.handleChange} ></textarea>
                      </div>  </div>
                  </div>
                </div>
              </div>
              <div style={{ margin: "24px", border: "1px solid #595959" }}>
                <div
                  style={{
                    backgroundColor: "#262626",
                    marginBottom: "15px",
                    padding: "11px",
                    borderBottom: "0.2px solid #595959",
                    textAlign: "left",
                  }}
                >
                  <p>Contact Person for Invoice</p>
                </div>
                <div className="row" style={{ margin: "19px" }}>
                  <div className="col-sm-6">
                    <label className="label" htmlFor="">
                      Full Name
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your Full name"
                      className={"input"}
                      name="full_name"
                      id="full_name"
                      onChange={this.handleChange}
                      onBlur={this.blurChange}
                      value={this.state.fields.full_name}
                      maxLength="20"
                    />
                    <div className="text-danger">
                      {this.state.errors.full_name[0] &&
                        this.state.dirty.full_name
                        ? this.state.errors.full_name
                        : ""}
                    </div>
                  </div>
                  <div className="col-sm-6 mt-20">
                    <label className="label" htmlFor="">
                      Designation
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your designation"
                      className={"input"}
                      name="designation"
                      id="designation"
                      onChange={this.handleChange}
                      onBlur={this.blurChange}
                      value={this.state.fields.designation}
                      maxLength="20"
                    />
                    <div className="text-danger">
                      {this.state.errors.designation[0] &&
                        this.state.dirty.designation
                        ? this.state.errors.designation
                        : ""}
                    </div>
                  </div>
                  <div className="col-sm-6 mt-3 mt-20">
                    <div>
                      <label className="label" htmlFor="">
                        Contact Number
                      </label>
                      <div className="d-flex">

                        <DialCodeList handleChange={this.handleChange} name="contact_dialCode" value={this.state.fields.contact_dialCode} />
                        <input
                          type="text"
                          placeholder="Enter your contact number"
                          className={"input"}
                          name="phone_number"
                          id="phone_number"
                          onChange={this.handleChange}
                          onBlur={this.blurChange}
                          value={this.state.fields.phone_number}
                          minLength="6"
                          maxLength="12"
                        />
                      </div>
                      <div className="text-danger">
                        {this.state.errors.contact_dialCode[0] &&
                          this.state.dirty.contact_dialCode
                          ? this.state.errors.contact_dialCode
                          : ""}
                      </div>
                      <div className="text-danger">
                        {this.state.errors.phone_number[0] &&
                          this.state.dirty.phone_number
                          ? this.state.errors.phone_number
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 mt-3 mt-20">
                    <label className="label" htmlFor="">
                      Email Address
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your Email address"
                      className={"input"}
                      name="email"
                      id="email"
                      onChange={this.handleChange}
                      onBlur={this.blurChange}
                      value={this.state.fields.email}
                      maxLength="25"
                    />
                    <div className="text-danger">
                      {this.state.errors.email[0] && this.state.dirty.email
                        ? this.state.errors.email
                        : ""}
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ textAlign: "center" }}>
                <Link style={{ textDecoration: "none" }}>
                  <Button
                    className="btn w-30"
                    onClick={this.handleSubmit}
                    style={{
                      margin: "12px",
                      backgroundColor: "#2DA3B4",
                      color: "#fff",
                    }}
                  >
                    {this.state.isLoader === false ? (
                      <span>Submit Invoice</span>
                    ) : (
                      <div className="spinner-border btn--icon"></div>
                    )}
                  </Button>
                </Link>
              </div>
            </Modal.Body>
          </div>
        </Modal>

        <Modal show={this.state.removeInvoiceModal} onHide={this.handleClose}>
          <div style={{ backgroundColor: "#333333", color: "white" }}>
            <Modal.Header closeButton>Delete</Modal.Header>
            <Modal.Body>
              <label>Are yo sure you want to delete Invoice??</label>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={this.removeInvoiceConfirm}>
                Delete
              </Button>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  user: state.user,
  company: state.company,
  activity: state.activity,
  country: state.country,
  invoiceStatus: state.invoiceStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getAllInvoices: () => dispatch(getAllInvoices()),
  postInvoice: (data) => dispatch(postInvoice(data)),
  postUser: (data) => dispatch(postUser(data)),
  updateUser: (data) => dispatch(updateUser(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  deleteInvoice: (data) => dispatch(deleteInvoice(data)),
  getInvoiceCreatedByUser: (data) => dispatch(getInvoiceCreatedByUser(data)),
  getCompaniesByCreatedByAndApproved: (id) =>
    dispatch(getCompaniesByCreatedByAndApproved(id)),
  postActivity: (data) => dispatch(postActivity(data)),
  getCountry: (data) => dispatch(getCountry(data)),
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  getEmailUser: (data) => dispatch(getEmailUser(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Invoice));

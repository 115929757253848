import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
export default function RejectPopup(props) {

    return (
        <div>
            <div style={{fontFamily:"Averta-Semibold,sans-serif"}}>
        
        <Modal
 {...props}
 aria-labelledby="contained-modal-title-vcenter"
 centered
style={{backgroundColor:'black'}}  >
 <div style={{backgroundColor:'#333333',color:'white',border:'0'}}> 
     <Modal.Header>

     <Modal.Title id="contained-modal-title-vcenter" style={{fontSize:'16px',lineHeight:'24px'}} >
     Reject Offer
     </Modal.Title>
     </Modal.Header>
     <Modal.Body style={{textAlign:'center'}} >
     <div style={{margin:'0 auto',width:'100%',height:'100%',fontSize:'13px',lineHeight:'24px'}}>
       
           <p style={{textAlign:'left'}}>  Oh no.! can you take a moment to tell us why you want to reject the
             offer? we would like to get in touch with you to solve your quries.</p>
             <div style={{}}>
                <p style={{textAlign:'left',paddingTop:"12px"}}>Write here the reason behind rejection</p>
                <textarea style={{backgroundColor:"#333333",width:"100%",height:"90px"}} placeholder="write here"></textarea>
         </div>
    </div>
    
   
     <div style={{margin:'20px',alignItems:'right'}}>
         <Button  style={{backgroundColor:"#2DA3B4"}}>Submit</Button>
    </div>
     </Modal.Body>
     </div>
     </Modal>
     </div>
        </div>
    )
}

import React, { Component } from "react";
import { ADMIN_ALERT_TYPES } from "../../config/string_constants";
import helper from "../../utils/helper";
import Paginations from "../pagination";
import { URI } from "../../constant";
import ajax from "../../utils/ajax";
class ActivityLog extends Component {
  constructor(props) {
    super(props);
  }

  pageChange=async(index)=>{
    this.props.paginateActivity(index)
    // const userData = await ajax(
    //   "GET",
    //   `${URI}/invoice?page=${index}`,
    //   {}
    // );
    //   if(userData.data){
    //     this.props.paginateActivity(userData.data)
    //   }
  }

  getInvoiceMsg = (alert) => {
    let actionName = alert.key.replace("_", " ").toLowerCase();
    if (alert.key === ADMIN_ALERT_TYPES.BANK_ACCOUNT) {
      return `${alert.client_Id.full_name} has added/updated ${actionName}`;
    } else {
      if (alert.client_Id) {
        return `${alert.client_Id.full_name} has completed ${actionName} step`;
      }
     
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className=" w-100">
          <div className="info-one">
            <h3 style={{ fontWeight: "500" }}>
              <i className="uil uil-bell" /> ALERT
            </h3>
            {helper.isNotEmpty(this.props.alertDetails.data)
              ? this.props.alertDetails.data.map((alert, i) => (
                  <div key={i} className="info-card">
                    {alert.invoice_ref_no ? (
                      <p className="subtitle">
                        Invoice #{alert.invoice_ref_no}
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="info-subcard">
                      {alert.key ? <p>{this.getInvoiceMsg(alert)}</p> : ""}
                      {alert.invoice_ref_no ? (
                        <button
                          onClick={() => {
                            this.props.history.replace(
                              `/${alert.invoice_status_id.key}/${alert.invoice_id}`
                            );
                          }}
                        >
                          View
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))
              : ""}
          </div>
        </div>
        <div className="d-flex justify-content-end mx-4 mt-2">
          <Paginations item={this.props.alertDetails} pageClick={this.pageChange} />
        </div>
      </React.Fragment>
    );
  }
}
export default ActivityLog;

import React, { Component } from "react";
import Pana from "../assets/images/pana.svg";
export default class maintenance extends Component {
  render() {
    return (
      <div className="mt-5 text-center">
        <img src={Pana} alt="maintance" />
        <h3 className="text-white mt-4">Under Maintenance</h3>
        <p className="text_tra1">We've got something special for you. </p>

        <p className="text_tra1">
          And we can't wait for you to see it. Please check back soon.
        </p>
      </div>
    );
  }
}

import * as USER_WALLET_CONSTANTS from "../constants/userWalletConstant";
export const UserWalletReducer = (state = {
    type: '',
    error: '',
    isFetching: false,
    postUserWallet: undefined,
   UserWallets:undefined,
}, action) => {
   
    switch (action.type) {
                case USER_WALLET_CONSTANTS.HOME_GET_USER_WALLET_BY_ID_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    UserWallets: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case USER_WALLET_CONSTANTS.HOME_GET_USER_WALLET_BY_ID_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    UserWallets: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case USER_WALLET_CONSTANTS.HOME_GET_USER_WALLET_BY_ID_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });

                case USER_WALLET_CONSTANTS.HOME_POST_USER_WALLET_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    postUserWallet: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case USER_WALLET_CONSTANTS.HOME_POST_USER_WALLET_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    postUserWallet: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case USER_WALLET_CONSTANTS.HOME_POST_USER_WALLET_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });

        default:
            return state;
    }
};


export default UserWalletReducer;

import React, { Component } from "react";
import { postAlert } from "../../../services/actions/alertActions";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { getInvoiceDetailsById } from "../../../services/actions/invoiceAction";
import { withRouter } from "react-router-dom";
import AlertPopup from "./alertPopup";
import { COMPONENT_TYPE } from "../../../config/string_constants";

class invoiceMoreDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      InvoiceId: "",
      invoice: {
        invoice_id: "",
        invoice_ref_no: "",
        invoice_status_id: "",
        invoice_substatus_id: "",
        client_Id: "",
        alert_message: "",
      },
      alertMessage: "",
      invoicedetails: [],
    };
  }

  handleChange = (e) => {
    const alertData = e.target.value;
    this.setState({ alertMessage: alertData });
  };

  submit = () => {
    let invoiceData = null;
    if (this.props.buyer) {
      invoiceData = {
        ...this.state.invoice,
        alert_message: this.state.alertMessage,
      };
    } else {
      invoiceData = {
        ...this.state.invoice,
        invoice_id: this.props.invoicedetails._id,
        invoice_ref_no: this.props.invoicedetails.invoice_id,
        invoice_status_id: this.props.invoicedetails.invoiceStatus_id._id,
        client_Id: this.props.invoicedetails.created_by_id,
        alert_message: this.state.alertMessage,
      };
    }
    this.setState(
      {
        invoice: invoiceData,
      },
      () => {
        this.props.postAlert(this.state.invoice).then(() => {
          toast.info("Request Sent");
          document.getElementById(COMPONENT_TYPE.INVOICE_MORE_DETAIL).click();
          this.setState({ alertMessage: "" });
        });
      }
    );
  };
  componentDidMount() {
    if (!this.props.buyer === true) {
      this.setState({ InvoiceId: this.props.match.params.id });
    } else {
      this.setState(
        {
          invoice: {
            client_Id: this.props.match.params.uId,
          },
        },
        () => {}
      );
    }
  }

  render(props) {
    return (
      <div>
        <div className="info-one text-white">
          <h4 className="text-white">
            {this.props.invoiceCompany
              ? this.props.invoiceCompany.company_name
              : ""}
          </h4>

          <div className="dropdown">
            <div
              className="text-white"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="pr-95px">More Details</span>
              <span>
                {" "}
                <i className="uil-angle-down txt_b"></i>
              </span>
            </div>

            <div
              className="p-3 bgc-dark invoice-drop dropdown-menu"
              aria-labelledby="dropdownMenuLink"
            >
              {this.props.invoiceCompany !== undefined ? (
                <div className="card-body  text-dark">
                  <div>
                    <div className="text_tra1">
                      {" "}
                      Company Website
                      <p>
                        <h5 className="text-white">
                          {this.props.invoiceCompany
                            ? this.props.invoiceCompany.website
                            : ""}
                        </h5>
                      </p>
                    </div>
                    <div className="text_tra1 pt-3">
                      {" "}
                      Company Email
                      <p>
                        <h5 className="text-white">
                          {this.props.invoiceCompany
                            ? this.props.invoiceCompany.company_email_id
                            : ""}
                        </h5>
                      </p>
                    </div>
                    <div className="text_tra1 pt-3">
                      {" "}
                      Phone Number
                      <p>
                        <h5 className="text-white">
                          {this.props.invoiceCompany
                            ? this.props.invoiceCompany.company_dialCode
                            : ""}{" "}
                          {this.props.invoiceCompany
                            ? this.props.invoiceCompany.company_phone_no
                            : ""}
                        </h5>
                      </p>
                    </div>
                    <div className="pt-3">
                      {" "}
                      <button
                        data-toggle="modal"
                        data-target="#INVOICE_MORE_DETAIL"
                        className="btn-blue w-100"
                      >
                        Send Alert
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <AlertPopup
          onSubmit={this.submit}
          message={this.state.alertMessage}
          onChange={this.handleChange}
          screen={COMPONENT_TYPE.INVOICE_MORE_DETAIL}
        />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  postAlert: (data) => dispatch(postAlert(data)),
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
});
export default connect(null, mapDispatchToProps)(withRouter(invoiceMoreDetail));

import React from "react";
import _isEmpty from "lodash/isEmpty";
import Sidebar from "./Dashboard/Sidebar";
import "../../assets/css/dashboard.css";
import { withRouter } from "react-router-dom";
import "../../assets2/css/profile_page.css";
import "../../assets2/css/profile.css";
import "bootstrap/dist/css/bootstrap.min.css";
import trash from "../../assets/images/trash.png";
import {
  postUser,
  updateUser,
  getEmailUser,
  DeleteUser,
} from "../../services/actions/userAction";
import {
  getCompany,
  updateCompany,
  getCompanyByCompanyId,
} from "../../services/actions/companyActions";
import { getUserWalletsById } from "../../services/actions/userWalletActions";
import { postActivity } from "../../services/actions/activityActions";
import { getCountry } from "../../services/actions/countryActions";
import { connect } from "react-redux";
import { UserContext } from "../../context/UserContext";
import { Modal } from "react-bootstrap";
import upload from "../../assets/images/upload.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainHeader from "./Dashboard/mainHeader";
import { URI } from "../../constant";
import Button from "react-bootstrap/Button";
import Bottomnav from "./Dashboard/bottomnav";
import Mob_header from "./Dashboard/mob_header";
import ajax from "../../utils/ajax";
import {
  USER_TYPE,
  STATUSES,
  ADMIN_ALERT_TYPES,
} from "../../config/string_constants";
import { updateBorrowerCompany } from "../../api/companyService";
import {
  getCompanyUsersAPI,
  createCompanyUser,
  // createDirectorUser,
  // createShareholderUser,
} from "../../api/userService";

import helper from "../../utils/helper";
import Directors from "./profileSubPages/Directors";
import ShareHolders from "./profileSubPages/ShareHolders";
import UploadDocuments from "./profileSubPages/UploadDocuments";
import BankDetails from "./profileSubPages/bankDetails";
import DialCodeList from "./Common/dialCodeList";

class Profile extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      toast_var: false,
      edit: false,
      hideEdit: false,
      documentation: this.props.match.params.section,
      accountDetailPage: this.props.match.params.section,
      edit2: false,
      UserWallets: [],
      show: false,
      account: {
        user_name: "",
        bank_name: "",
        account_number: "",
        ifsc_code: "",
        beneficiary_name: "",
        beneficiary_address: "",
        currency: "",
        swift_code: "",
        createdById: sessionStorage.getItem("userId"),
      },
      editData: false,

      shareholderLoader: [],

      user: {
        full_name: "",
        email: "",
        phone_number: "",
        company_id: "",
        user_dialCode: "",
      },
      country: [],
      company: {
        company_name: "",
        company_email_id: "",
        company_phone_no: "",
        website: "",
        about: "",
        company_landline: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        zipcode: "",
        country_id: "",
        company_logo: "",
        company_coi_url: "",
        company_moa_url: "",
        company_audit_url: "",
        company_kyc_url: "",
        company_gst_url: "",
        company_dialCode: "",
        company_iec_number: "",
        sales_ledger_url: "",
        bank_statement_url: "",
        previous_audit_url: "",
      },

      callUpdate: {
        company: false,
        user: false,
        adddirector: false,
        addshareholder: false,
        editshareholder: false,
        deleteshareholder: false,
        deleteshareholdermodal: false,
      },
      activity: {
        activity_name: "Updated profile",
        service_name: "updateprofile",
        createdBy: {
          name: sessionStorage.getItem("uName"),
          email: sessionStorage.getItem("uEmail"),
          id: sessionStorage.getItem("userId"),
        },
      },

      dirty: {
        full_name: false,
        email: false,
        phone_number: false,
        company_name: false,
        company_email_id: false,
        company_phone_no: false,
        website: false,
        about: false,
        company_landline: false,
        company_iec_number: false,
        address_line1: false,
        address_line2: false,
        city: false,
        state: false,
        zipcode: false,
        country_id: false,
        user_dialCode: false,
        company_dialCode: false,
        full_name: false,
        shareholder_full_name: false,
      },

      errors: {
        full_name: [],
        email: [],
        phone_number: [],
        company_name: [],
        company_email_id: [],
        company_phone_no: [],
        website: [],
        about: [],
        company_landline: [],
        address_line1: [],
        address_line2: [],
        city: [],
        state: [],
        zipcode: [],
        company_iec_number: [],
        country_id: [],
        user_dialCode: [],
        company_dialCode: [],
        full_name: [],
        shareholder_full_name: [],
      },
      Directors: [],
      ShareHolders: [],
      city: [],
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    let user = this.state.user;
    let company = this.state.company;
    let director = this.state.director;
    let shareholder = this.state.shareholder;
    let account = this.state.account;

    user[name] = value;
    company[name] = value;
    // director[name] = value;
    // shareholder[name] = value;
    account[name] = value;

    this.setState(
      {
        user: user,
        account: account,
        company: company,
        dirty: dirty,
        director: director,
        shareholder: shareholder,
      },
      this.validate,
    );
  };
  handleCountryChange = (e) => {
    const { name, value } = e.target;

    let dirty = this.state.dirty;
    dirty[name] = true;

    let company = this.state.company;

    company[name] = value;

    this.setState(
      {
        company: company,
        dirty: dirty,
      },
      this.validate
    );

    this.setState(
      {
        // company: { ...this.state.company, city:''},
        city: this.state.country
          .filter((item) => item._id === this.state.company.country_id)
          .map((item) => item.cities),
      },
      () => {}
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_isEmpty(nextProps.userWallet.UserWallets)) {
      this.setState({
        UserWallets: nextProps.userWallet.UserWallets,
      });
    }
  }

  removeLogo = () => {
    this.setState({ company: { ...this.state.company, company_logo: "" } });
  };

  saveUser = () => {
    this.setState({ callUpdate: { ...this.state.callUpdate, user: true } });
    this.props.updateUser(this.state.user);
  };

  saveCompany = async (e) => {
    let response = await updateBorrowerCompany(this.state.company);
    if (response) {
      toast.info(response.message);
    }
  };

  blurChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };

  handlePhoneNumber = (e) => {
    const { name, value } = e.target;

    let cleanText = ("" + value).replace(/\D/g, "");
    let dirty = this.state.dirty;
    dirty[name] = true;

    let user = this.state.user;
    let company = this.state.company;
    user[name] = cleanText;
    company[name] = cleanText;

    this.setState(
      {
        user: user,
        company: company,
        dirty: dirty,
      },
      this.validate
    );
  };

  validate = () => {
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      errors[control] = [];
      //  var validPhone = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/
      var validEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      var validUrl =
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
      var validState = /^[A-Za-z'-\s]+$/;
      switch (control) {
        case "full_name":
        // if (!this.state.user[control]|| !this.state.director[control]|| !this.state.shareholder[control]) {
        //   errors[control].push("Name can't be blank");
        // }

        case "designation":
          if (!this.state.user[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "phone_number":
          if (!this.state.user[control]) {
            errors[control].push("This field is required");
          }
          let length = this.state.user[control].length;
          if (this.state.user[control] && (length < 6 || length > 12)) {
            errors[control].push("Number min 6 max 12");
          }
          break;

        case "company_name":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;

        case "company_phone_no":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          let cLength = this.state.company[control].length;
          if (this.state.company[control] && (cLength < 6 || cLength > 12)) {
            errors[control].push("Number min 6 max 12");
          }
          break;

        case "company_email_id":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          if (this.state.company[control]) {
            if (!validEmail.test(this.state.company[control])) {
              errors[control].push("Please Enter valid Email Id");
            }
          }
          break;

        case "website":
          if (this.state.company[control] && this.state.company[control]) {
            if (!validUrl.test(this.state.company[control])) {
              errors[control].push("Please Enter valid Website");
            }
          }
          break;

        case "about":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "address_line1":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "address_line2":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "city":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "zipcode":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "state":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          if (this.state.company[control]) {
            if (!validState.test(this.state.company[control])) {
              errors[control].push("Please enter valid State");
            }
          }
          break;

        default:
          break;
      }
    });
    this.setState({ errors });
  };

  isValid = () => {
    let valid = true;

    for (let control in this.state.errors) {
      if (this.state.errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };

  isAccountValid = () => {
    let valid = true;

    for (let control in this.state.WalletErrors) {
      if (this.state.WalletErrors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };

  onChange = (e) => {
    this.uploadImage(e.target.files[0]);
    let files = e.target.files;

    let reader = new FileReader();
    if (e.target.files[0] !== undefined) {
      reader.readAsDataURL(files[0]);
      reader.onLoad = (e) => {};
    }
  };
  uploadImage(file) {
    const formData = new FormData();

    formData.append("image", file);

    if (file !== undefined) {
      ajax(
        "POST",
        `${URI}/api/v1/image-upload`,
        {},
        { "content-type": "multipart/form-data" },
        formData
      )
        .then((data) => {
          this.setState(
            {
              company: {
                ...this.state.company,
                company_logo: data.data.imageUrl,
              },
            },
            () => {}
          );
        })
        .catch((err) => {});
    }
  }

  handleClose = () => {
    this.setState({
      callUpdate: {
        ...this.state.callUpdate,
        deletedirectorModal: false,
        deleteshareholdermodal: false,
      },
    });
  };

  /**
   * Function to get comapny users
   * @param {*} nextProps
   */
  getCompanyUsers = async (companyId) => {
    let companyUsers = await getCompanyUsersAPI(companyId);
    if (companyUsers.data.success) {
      let directors = companyUsers.data.data.filter(
        (user) => user.user_type === USER_TYPE.DIRECTOR
      );
      let companyShareholders = companyUsers.data.data.filter(
        (user) => user.user_type === USER_TYPE.SHAREHOLDER
      );
      let approveDirector = directors.filter(
        (data) => data.kyc_status === STATUSES.VERIFIED
      );
      this.setState({
        Directors: directors,
        ShareHolders: companyShareholders,
      });
      // if (approveDirector.length !== 0) {
      //   this.setState({ hideEdit: true });
      // }
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_isEmpty(nextProps.user.userEmailData)) {
      this.setState({ user: nextProps.user.userEmailData[0] });
    }
    if (!_isEmpty(nextProps.company.profileCompany)) {
      this.setState({ company: nextProps.company.profileCompany });
      // toast.info("data added Successfully");
    }
    if (
      !_isEmpty(nextProps.company.updateSuccess) &&
      this.state.callUpdate.company
    ) {
      toast.info("Company Data Updated Successfully");
      this.props.postActivity(this.state.activity);
      this.setState({
        callUpdate: { ...this.state.callUpdate, company: false },
      });
    }
    if (!_isEmpty(nextProps.user.updateSucess) && this.state.callUpdate.user) {
      toast.info("User Data Updated Successfully");
      this.props.postActivity(this.state.activity);
      this.setState({ callUpdate: { ...this.state.callUpdate, user: false } });
    }
    if (!_isEmpty(nextProps.country.country)) {
      this.setState({ country: nextProps.country.country });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.user.userEmailData !== prevProps.user.userEmailData) {
      if (this.state.user.company_id) {
        this.props.getCompanyByCompanyId(this.state.user.company_id);
      }
    }
    if (
      this.state.user.company_id &&
      this.state.user.company_id !== prevState.user.company_id
    ) {
      this.getCompanyUsers(this.state.user.company_id);
    }
  }

  componentDidMount() {
    if (this.state.documentation === "section3") {
      document.getElementById("section3").click();
    }
    if (this.state.accountDetailPage === "section4") {
      document.getElementById("section4").click();
    }
    this.props.getUserWalletsById(sessionStorage.getItem("userId"));

    const userEmail = sessionStorage.getItem("emailOfUser");

    this.props.getEmailUser(userEmail);
    // this.props.getCompany()
    this.props.getCountry();
    if (!_isEmpty(this.props.country.country)) {
      this.setState({ country: this.props.country.country });
    }
    if (this.state.user.company_id) {
      this.getCompanyUsers(this.state.user.company_id);
    }
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Bottomnav />
        <div className="main-content">
          <Mob_header />
          <header className="header1">
            <h2 className="heading">Profile</h2>

            <MainHeader />
          </header>

          <ToastContainer
            position="top-center"
            style={{ height: "30px" }}
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          {/* <h1 className="bg_light">karthick</h1> */}
          <div>
            <div className="row">
              <div className="col-sm-12">
                <div className="tab" role="tabpanel">
                  {/* <!-- Nav tabs --> */}
                  <ul
                    style={{ borderBottom: "1px solid #4d4d4d" }}
                    className="nav nav-tabs"
                    role="tablist"
                  >
                    <li role="presentation" className="active pro_mone1">
                      <a
                        style={{ textDecoration: "none" }}
                        href="#Sections1"
                        aria-controls="home"
                        role="tab"
                        data-toggle="tab"
                      >
                        Personal Profile
                      </a>
                    </li>
                    <li role="presentation" className="pro_mone2">
                      <a
                        style={{ textDecoration: "none" }}
                        href="#Sections2"
                        aria-controls="profile"
                        role="tab"
                        data-toggle="tab"
                      >
                        Company Profile
                      </a>
                    </li>
                    <li role="presentation" className="pro_mone3">
                      <a
                        style={{ textDecoration: "none" }}
                        href="#Sections3"
                        id="section3"
                        aria-controls="messages"
                        role="tab"
                        data-toggle="tab"
                      >
                        Documentation
                      </a>
                    </li>
                    <li role="presentation" className="pro_mone3 mt-20">
                      <a
                        style={{ textDecoration: "none" }}
                        href="#Sections4"
                        id="section4"
                        aria-controls="messages"
                        role="tab"
                        data-toggle="tab"
                      >
                        Account Details
                      </a>
                    </li>
                  </ul>
                  {/* <hr className="hr_one ml-4"></hr> */}
                  {/* <!-- Tab panes --> */}
                  <div className="tab-content">
                    <div
                      role="tabpanel"
                      className="tab-pane  active"
                      id="Sections1"
                    >
                      <div className=" mt-4 profile-details bor_none">
                        <div className="card-header d-flex justify-content-between  crd_brd col_erone">
                          <div className="font-16">Personal Detail</div>
                          {!this.state.hideEdit && (
                            <i
                              style={{ cursor: "pointer" }}
                              className="uil-edit"
                              onClick={() => this.setState({ edit: true })}
                            />
                          )}
                        </div>
                        <div className="card-body card_bg ">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="  mb-3 text-left mt-4">
                                <label className="form-label ">Full Name</label>
                                <input
                                  disabled={!this.state.edit}
                                  type="text"
                                  className="form-control input_bg"
                                  name="full_name"
                                  value={this.state.user.full_name}
                                  onChange={this.handleChange}
                                  onBlur={this.blurChange}
                                  placeholder="Full name"
                                  maxLength="50"
                                />
                                <div className="text-danger">
                                  {this.state.errors.full_name[0] &&
                                  this.state.dirty.full_name
                                    ? this.state.errors.full_name
                                    : ""}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="  mb-3 text-left mt-4">
                                <label className="form-label ">Email Address</label>
                                <input
                                  disabled={!this.state.edit}
                                  type="text"
                                  className="form-control input_bg"
                                  name="email"
                                  value={this.state.user.email}
                                  onChange={this.handleChange}
                                  onBlur={this.blurChange}
                                  placeholder="example@gmail.com"
                                  maxLength="50"
                                />
                                <div className="text-danger">
                                  {this.state.errors.email[0] &&
                                  this.state.dirty.email
                                    ? this.state.errors.email
                                    : ""}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="  mt-4 text-left">
                                <label className="form-label ">Phone No</label>
                                <div className="input-group">
                                  <DialCodeList handleChange={this.handleChange} name="user_dialCode" value={this.state.user.user_dialCode} />

                                  <input
                                    disabled={!this.state.edit}
                                    type="text"
                                    className=" ml-2 form-control input_bg"
                                    name="phone_number"
                                    value={this.state.user.phone_number}
                                    onChange={this.handlePhoneNumber}
                                    onBlur={this.blurChange}
                                    maxLength="13"
                                  />
                                </div>
                                <div className="text-danger">
                                  {this.state.errors.phone_number[0] &&
                                  this.state.dirty.phone_number
                                    ? this.state.errors.phone_number
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div></div>
                      {this.state.edit && (
                        <button
                          type="button"
                          onClick={this.saveUser}
                          className="btn-g "
                        >
                          Save
                        </button>
                      )}
                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="Sections2">
                      {/* Company Profile */}
                      <div className="profile-details bor_none">
                        <div className="card-header d-flex justify-content-between crd_brd col_erone">
                          <div className="font-16">Company Detail</div>
                          {!this.state.hideEdit && (
                            <i
                              style={{ cursor: "pointer" }}
                              className="uil-edit"
                              onClick={() => this.setState({ edit2: true })}
                            />
                          )}
                        </div>
                        <div className="card-body card_bg">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-md-4">
                                {/* uplod image */}
                                <div className="  mb-3 text-left ">
                                  <label className="form-label ">
                                    Company Name
                                  </label>
                                  <input
                                    disabled
                                    type="text"
                                    className="form-control input_bg"
                                    placeholder=""
                                    name="company_name"
                                    value={this.state.company.company_name}
                                    onChange={this.handleChange}
                                    onBlur={this.blurChange}
                                    maxLength="25"
                                  />
                                  <div className="text-danger">
                                    {this.state.errors.company_name[0] &&
                                    this.state.dirty.company_name
                                      ? this.state.errors.company_name
                                      : ""}
                                  </div>
                                </div>
                                <div className="mt-4">
                                  <div
                                    style={{ background: "#333333" }}
                                    className="  form-selectgroup-label d-flex align-items-center p-3 "
                                  >
                                    {this.state.company.company_logo == "" ||
                                    this.state.company.company_logo == null ||
                                    this.state.company.company_logo ==
                                      undefined ? (
                                      <span
                                        className={
                                          this.state.errors.company_logo
                                            ? " input-error"
                                            : ""
                                        }
                                      >
                                        <label className="ulogo w-100">
                                          <img width="18px" src={upload} />{" "}
                                          Upload Company Logo{" "}
                                          <div className="text-muted ">
                                            (Optional)
                                          </div>{" "}
                                          <input
                                            type="file"
                                            name="myImage"
                                            onChange={this.onChange}
                                          />{" "}
                                        </label>{" "}
                                      </span>
                                    ) : (
                                      <div className="d-flex justify-content-center">
                                        <div
                                          style={{
                                            background: "#404040",
                                            padding: "12px",
                                            borderRadius: "4px",
                                          }}
                                        >
                                          <img
                                            width="100px"
                                            height="75px"
                                            src={
                                              this.state.company.company_logo
                                            }
                                          />
                                          <img
                                            className="ml-4"
                                            src={trash}
                                            onClick={this.removeLogo}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4 mt-20">
                                <div className="  text-left ">
                                  <label className="form-label ">Website</label>
                                  <input
                                    disabled={!this.state.edit2}
                                    type="text"
                                    className="form-control input_bg"
                                    placeholder=""
                                    name="website"
                                    value={this.state.company.website}
                                    onChange={this.handleChange}
                                    onBlur={this.blurChange}
                                  />
                                  <div className="text-danger">
                                    {this.state.errors.website[0] &&
                                    this.state.dirty.website
                                      ? this.state.errors.website
                                      : ""}
                                  </div>
                                </div>
                                <div className="  text-left mt-4 mt-20">
                                  <label className="form-label ">IEC Number</label>
                                  <input
                                    disabled
                                    type="text"
                                    className="form-control input_bg"
                                    placeholder="IEC Number"
                                    name="company_iec_number"
                                    value={
                                      this.state.company.company_iec_number
                                    }
                                    onChange={this.handleChange}
                                    onBlur={this.blurChange}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 mt-20">
                                <div className="   text-left">
                                  <label className="form-label ">
                                    Company Email Address
                                  </label>
                                  <input
                                    disabled={!this.state.edit2}
                                    type="text"
                                    className="form-control input_bg"
                                    placeholder=""
                                    name="company_email_id"
                                    value={this.state.company.company_email_id}
                                    onChange={this.handleChange}
                                    onBlur={this.blurChange}
                                    maxLength="25"
                                  />
                                  <div className="text-danger">
                                    {this.state.errors.company_email_id[0] &&
                                    this.state.dirty.company_email_id
                                      ? this.state.errors.company_email_id
                                      : ""}
                                  </div>
                                </div>

                                <div className=" mt-4 text-left">
                                  <label className="form-label ">Phone No</label>
                                  <div className="input-group">
                                 
                                    <DialCodeList handleChange={this.handleChange} name="company_dialCode" value={this.state.company.company_dialCode} />

                                    <input
                                      disabled={!this.state.edit2}
                                      type="text"
                                      className=" ml-2 form-control input_bg"
                                      aria-label="Text input with dropdown button"
                                      name="company_phone_no"
                                      value={
                                        this.state.company.company_phone_no
                                      }
                                      onChange={this.handlePhoneNumber}
                                      onBlur={this.blurChange}
                                      maxLength="13"
                                    />
                                  </div>
                                  <div className="text-danger">
                                    {this.state.errors.company_phone_no[0] &&
                                    this.state.dirty.company_phone_no
                                      ? this.state.errors.company_phone_no
                                      : ""}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Company Profile */}
                      <div className="profile-details mt-4 bor_none">
                        <div className="card-header  crd_brd col_erone">
                          <div className="font-16">Company Address</div>
                        </div>
                        <div className="card-body card_bg">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="  mb-3 text-left mt-4">
                                <label className="form-label ">
                                  Address Line 1
                                </label>
                                <input
                                  disabled
                                  type="text"
                                  className="form-control input_bg"
                                  placeholder=""
                                  name="address_line1"
                                  value={this.state.company.address_line1}
                                  onChange={this.handleChange}
                                  onBlur={this.blurChange}
                                  maxLength="50"
                                />
                                <div className="text-danger">
                                  {this.state.errors.address_line1[0] &&
                                  this.state.dirty.address_line1
                                    ? this.state.errors.address_line1
                                    : ""}
                                </div>{" "}
                              </div>

                              <div className="mt-20  mb-3 text-left">
                                <label className="form-label ">Country</label>
                                <input
                                  disabled
                                  type="text"
                                  className="form-control input_bg"
                                  value={this.state.company.country_id ? this.state.company.country_id?.name : this.state.company.country_name }
                                  maxLength="50"
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="  mb-3 text-left mt-4">
                                <label className="form-label ">
                                  Address Line 2
                                </label>
                                <input
                                  disabled
                                  type="text"
                                  className="form-control input_bg"
                                  placeholder=""
                                  name="address_line2"
                                  value={this.state.company.address_line2}
                                  onChange={this.handleChange}
                                  onBlur={this.blurChange}
                                  maxLength="50"
                                />
                                <div className="text-danger">
                                  {this.state.errors.address_line2[0] &&
                                  this.state.dirty.address_line2
                                    ? this.state.errors.address_line2
                                    : ""}
                                </div>{" "}
                              </div>

                              <div className="  mb-3 text-left mt-4">
                                <label className="form-label ">State</label>
                                <input
                                  disabled
                                  type="text"
                                  className="form-control input_bg"
                                  placeholder=""
                                  name="state"
                                  value={this.state.company.state}
                                  onChange={this.handleChange}
                                  onBlur={this.blurChange}
                                />
                                <div className="text-danger">
                                  {this.state.errors.state[0] &&
                                  this.state.dirty.state
                                    ? this.state.errors.state
                                    : ""}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="  mb-3 text-left mt-4">
                                <label className="form-label ">Zip Code</label>
                                <input
                                  disabled
                                  type="text"
                                  className="form-control input_bg"
                                  placeholder=""
                                  name="zipcode"
                                  value={this.state.company.zipcode}
                                  onChange={this.handleChange}
                                  onBlur={this.blurChange}
                                />
                                <div className="text-danger">
                                  {this.state.errors.zipcode[0] &&
                                  this.state.dirty.zipcode
                                    ? this.state.errors.zipcode
                                    : ""}
                                </div>
                              </div>

                              <div className="  mb-3 text-left mt-4">
                                <label className="form-label ">City {}</label>

                                <input
                                  disabled
                                  type="text"
                                  className="form-control input_bg"
                                  value={this.state.company.city}
                                  maxLength="50"
                                />  
   
                                <div className="text-danger">
                                  {this.state.errors.city[0] &&
                                  this.state.dirty.city
                                    ? this.state.errors.city
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pt-3">
                        {this.state.edit2 && (
                          <button
                            type="button  mt-3"
                            className="btn-g"
                            onClick={this.saveCompany}
                          >
                            Save
                          </button>
                        )}
                      </div>
                    </div>
                    <div role="tabpanel" className="tab-pane fade " id="Sections3">
                      {/* Documentation */}
                      <UploadDocuments
                        company={this.state.company}
                      ></UploadDocuments>
                      <Directors
                        directorsData={this.state.Directors}
                        company_id={this.state.user.company_id}
                        getCompanyUsers={this.getCompanyUsers}
                        countryList={this.state.country}
                      ></Directors>

                      {/* second table */}
                      <ShareHolders
                        ShareHoldersData={this.state.ShareHolders}
                        company_id={this.state.user.company_id}
                        getCompanyUsers={this.getCompanyUsers}
                      ></ShareHolders>
                    </div>

                    <div role="tabpanel" className="tab-pane fade" id="Sections4">
                      <BankDetails
                        countryList={this.state.country}
                        postActivity={this.props.postActivity}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  company: state.company,
  country: state.country,
  activity: state.activity,
  userWallet: state.userWallet,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(updateUser(data)),
  getCompany: (data) => dispatch(getCompany(data)),
  updateCompany: (data) => dispatch(updateCompany(data)),
  getCountry: (data) => dispatch(getCountry(data)),
  getEmailUser: (data) => dispatch(getEmailUser(data)),
  getCompanyByCompanyId: (data) => dispatch(getCompanyByCompanyId(data)),
  postActivity: (data) => dispatch(postActivity(data)),
  postUser: (data) => dispatch(postUser(data)),
  getUserWalletsById: (data) => dispatch(getUserWalletsById(data)),
  DeleteUser: (data) => dispatch(DeleteUser(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Profile));

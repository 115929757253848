import React from "react";
import moment from "moment";
import preview from "../../../assets/images/preview.png";
import download from "../../../assets/images/download1.png";
import helper from "../../../utils/helper";

export default function InvoiceData(props) {
  return (
    <div>
      <p className="text_tra1 mb-2">INVOICE DETAILS</p>
      <div className="row mt-4  text_tra1">
        <div className="col-4">
          Invoice Amount{" "}
          <h5 className="text-white">
            {props.invoices.currency}{" "}
            {helper.formatNumber(props.invoices.invoice_amount)}
          </h5>
        </div>
        <div className="col-4">
          Invoice Limit{" "}
          <h5 className="text-white">
            {props.invoices.currency}{" "}
            {helper.formatNumber(props.invoices.invoice_limit)}
          </h5>
        </div>
        <div className="col-4">
          Supplier Invoice Number{" "}
          <h5 className="text-white">
            {props.invoices.supplier_invoice_number}
          </h5>
        </div>
      </div>
      <div className="row mt-4 j text_tra1">
        <div className="col-4">
          Invoice Date{" "}
          <h5 className="text-white">
            {moment(props.invoices.invoice_date).format("DD MMM YYYY")}
          </h5>
        </div>
        <div className="col-4">
          Due Date
          <h5 className="text-white">
            {moment(props.invoices.invoice_due_date).format("DD MMM YYYY")}
          </h5>
        </div>
        <div className="col-4">
          Invoice
          <h5 className="text-white">
            <p>
              <a
                onClick={() => props.onPdfShow(props.invoices.invoice_pdf_link)}
              >
                <img src={preview} />
              </a>
              <a
                href={props.invoices.invoice_pdf_link}
                style={{ color: "white" }}
              >
                {" "}
                <img src={download} />
              </a>
            </p>
          </h5>
        </div>
      </div>
      <div className="row mt-4 j text_tra1">
        <div className="col-4">
          Invoice Tenure
          <h5 className="text-white">
            {moment(props.invoices.invoice_due_date).diff(
              moment(props.invoices.invoice_date),
              "days"
            )}{" "}
            Days
          </h5>
        </div>
        <div className="col-4">
          Soft Offer Tenure
          <h5 className="text-white">
            {props.tenure ? `${props.tenure} Days` : "Not available"}
          </h5>
        </div>
        <div className="col-4">
          Final Offer Tenure
          <h5 className="text-white">
            {props.invoices.final_offer?.tenure
              ? `${props.invoices.final_offer.tenure} Days`
              : "Not available"}
          </h5>
        </div>
        <div className="col-4">
          User Note to admin
          <h6
            className="acc-props.invoices mt-1 link font-style-3 "
            data-tooltip={props.invoices.admin_notes}
          >
            <i className="uil-comment-alt-message" />
          </h6>
        </div>
      </div>
      <p className="text_tra1 mt-4">CONTACT PERSON</p>

      <div className="row mt-4  text_tra1">
        <div className="col-4">
          Full Name
          <h5 className="text-white">{props.invoices.full_name}</h5>
        </div>
        <div className="col-4">
          Designation
          <h5 className="text-white">{props.invoices.designation}</h5>
        </div>
        <div className="col-4">
          Contact Number
          <h5 className="text-white">
            {" "}
            {props.invoices.contact_dialCode} {props.invoices.phone_number}
          </h5>
        </div>
      </div>
      <div className="row mt-4  text_tra1">
        <div className="col-4">
          Email<h5 className="text-white">{props.invoices.email}</h5>
        </div>
      </div>
    </div>
  );
}

import { toast } from "react-toastify";
import CONSTANTS from "./constant";
import helper from "./helper";
import { URLS } from '../config/frontendUrls'

toast.configure();
export default function handle_error(errorCode, err) {
  try {
    const userType = sessionStorage.getItem("user-type")
      ? sessionStorage.getItem("user-type")
      : "BORROWER";
    const redirectUrl =
      userType === CONSTANTS.ROLE.ADMIN
        ? "/Admin/login"
        : userType === CONSTANTS.ROLE.LENDER
        ? "/lender/login"
        : userType === CONSTANTS.ROLE.CLIENT
        ? URLS.CLIENT.LOGIN
        : "/";
    if (errorCode === CONSTANTS.STATUS.TOO_MANY_REQUESTS) {
      let message =
        helper.isNotEmpty(err) && helper.isNotEmpty(err.message)
          ? err.message
          : "Too Many Requests ,please try again later";

      toast.error(message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (errorCode === CONSTANTS.STATUS.INTERNAL_SERVER_ERROR) {
      let message =
        helper.isNotEmpty(err) && helper.isNotEmpty(err.message)
          ? err.message
          : "Internal Server Error. Please try again   ";
      toast.error(message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (errorCode === CONSTANTS.STATUS.DUPLICATE_RECORD) {
      toast.info("Data already exists.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (errorCode === CONSTANTS.STATUS.FORBIDDEN) {
      toast.error("Forbidden Access.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.location.pathname = redirectUrl;
      sessionStorage.clear();
    } else if (errorCode === CONSTANTS.STATUS.UNAUTHORIZED) {
      toast.error("Unauthorized Access.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.location.pathname = redirectUrl;
      sessionStorage.clear();
    } else if (errorCode === "failed") {
      toast.error("Internal server error. Contact service provider", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (errorCode === CONSTANTS.STATUS.NOT_APPLICABLE) {
      toast.error("This record cannot be deleted..!!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (errorCode === CONSTANTS.STATUS.BAD_REQUEST) {
      let message =
        helper.isNotEmpty(err) && helper.isNotEmpty(err.message)
          ? err.message
          : "Bad Request..!!";
      toast.error(message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (errorCode === CONSTANTS.STATUS.TOO_MANY_REQUESTS) {
      let message =
        helper.isNotEmpty(err) && helper.isNotEmpty(err.message)
          ? err.message
          : "Too Many Requests..!!";
      toast.error(message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (errorCode === CONSTANTS.STATUS.UNPROCESSABLE_ENTITY) {
      let message =
        helper.isNotEmpty(err) && helper.isNotEmpty(err.message)
          ? err.message
          : "Unprocessable Requests..!!";
      toast.error(message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Some error Occured", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (e) {}
}

import {URI} from '../../constant'

export const HOME_GET_INVOICE_FINAL_OFFER_CONSTANTS = ({
    URL: URI+"/invoice-final-offer/",
    REQUEST: 'REQUEST_GET_INVOICE_FINAL_OFFER_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_GET_INVOICE_FINAL_OFFER_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_GET_INVOICE_FINAL_OFFER_DATA_CONSTANTS',
  });

  export const HOME_POST_INVOICE_FINAL_OFFER_CONSTANTS = ({
    URL: URI+"/invoice-final-offer",
    REQUEST: 'REQUEST_POST_INVOICE_FINAL_OFFER_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_POST_INVOICE_FINAL_OFFER_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_POST_INVOICE_FINAL_OFFER_DATA_CONSTANTS',
  });
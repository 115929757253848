import React, { Component } from "react";
import styles from "../../../assets/css/OnboardingPersonalDetails.module.css";
import Logo from "../../../assets/images/Logo.svg";
import MainHeader from "../mainHeader";
import { withRouter } from "react-router-dom";
import { getUserDetails } from "../../../services/actions/userAction";
import { getCountry } from "../../../services/actions/countryActions";
import { connect } from "react-redux";
import Check from "../../../assets/images/check1.png";
import InvoiceMoreDetail from "../invoice/invoiceMoreDetail";
class BorrowerDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userDetails: [],
      activeId: "",
      user: [],
      uId: "",
      usercompanyId: [],
      company: [],
      country: '',
      personalDetails: false,
    };
  }

  setActive = (e) => {
    e.preventDefault();

    this.setState({ activeId: e.target.id });
  };

  componentDidMount = async () => {
    this.props
      .getUserDetails()
      .then((result) => {
        for (var i = 0; i < result.data.length; i++) {
          if (parseInt(this.props.match.params.id) === result.data[i].id) {
            this.setState({
              user: result.data[i].user,
              uId: result.data[i].user._id,
              usercompanyId: result.data[i].company._id,
              company: result.data[i].company,
            },
            );
          }
        }
      })
      .catch((err) => { });
    this.props.getCountry().then((result) => {
      const country = result.filter((item) => {
        return item._id === this.state.company.country_id
      })
      if (country.length !== 0) {
        this.setState({
          country: country[0].name,
        });
      }
    })



  }
  goback = () => {
    this.props.history.push("/dashboard");
  };

  callBuyer = () => {
    this.props.history.push(`/buyer-verification/${this.state.uId}`);
  };

  render() {
    return (
      <div>
        <nav className="d-flex  Navbar">
          <img
            className={styles.Logo}
            onClick={() => this.props.history.push("/dashboard")}
            src={Logo}
            alt=""
          />
          <div
            style={{
              height: "91px",
              borderRight: "1px solid gray",
              marginLeft: "227px",
            }}
          ></div>

          <div className="mt-4 text-white">
            <i
              onClick={this.goback}
              style={{ fontSize: "20px" }}
              className="ml-4 uil-arrow-left"
            >
              {" "}
            </i>
            <span>Client</span>
          </div>

          <div className="user-wrapper ml-auto mr-4">
            <MainHeader />
          </div>
        </nav>
        <div className="row m-2 my-4">
          <div className="col-3">
            <InvoiceMoreDetail
              buyer={true}
              invoiceCompany={this.state.company}
            />
          </div>
          <div className="col-9">
            <div className="info-two">
              <div className="mb-2">
                <ul
                  className="nav nav-pills justify-content-center pt-2"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item move1">
                    <span
                      className="nav-link text_act curserP   StatusItem active"
                      id="home-tab"
                    >
                      <img src={Check} className="check user-icon" />
                      <div
                        className="mt-1"
                        style={{ width: "7rem", marginLeft: "-49px" }}
                      >
                        Registration
                      </div>
                    </span>
                  </li>
                  <div className="line_right mt-3"></div>
                  <li className="nav-item ">
                    <span
                      className="nav-link text_act curserP p-1 StatusItem"
                      id="profile-tab"
                      onClick={() => this.callBuyer()}
                    >
                      02
                      <div
                        className="mt-2"
                        style={{ width: "8rem", marginLeft: "-49px" }}
                      >
                        Buyer Verification
                      </div>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-2 my-4">
          <div className="col-6">
            <div style={{ height: "20rem" }} className="info-one">
              <div className="tab-content">
                {this.state.user !== undefined ? (
                  <div className="tab-pane active" id="tabs-1" role="tabpanel">
                    <p className="text_tra1 mb-2">
                      CONTACT PERSONAL DETAILS{this.state.userDetails.id}
                    </p>

                    <div className="row mt-4  text_tra1">
                      <div className="col-4">
                        Name
                        <h5 className="text-white">
                          {this.state.user.full_name}
                        </h5>
                      </div>
                      <div className="col-4">
                        Designation
                        <h5 className="text-white">
                          {this.state.user.designation}
                        </h5>
                      </div>
                    </div>
                    <div className="row mt-4  text_tra1">
                      <div className="col-4">
                        Contact Number
                        {this.state.user.phone_number ? (
                          <h5 className="text-white">
                            {" "}
                            {this.state.user.user_dialCode}{" "}
                            {this.state.user.phone_number}
                          </h5>
                        ) : (
                          <h5></h5>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>data not found</div>
                )}

                {this.state.company !== undefined ? (
                  <div className="tab-pane" id="tabs-2" role="tabpanel">
                    <p className="text_tra1 mb-2">COMPANY DETAILS</p>

                    <div className="row mt-4  text_tra1">
                      <div className="col-4">
                        Company Name{" "}
                        <h5 className="text-white">
                          {this.state.company.company_name}
                        </h5>
                      </div>
                      <div className="col-4">
                        Company Email
                        <h5 className="text-white">
                          {this.state.company.company_email_id}
                        </h5>
                      </div>
                    </div>
                    <div className="row mt-4  text_tra1">
                      <div className="col-4">
                        Contact Number
                        <h5 className="text-white">
                          {this.state.company.company_dialCode}{" "}
                          {this.state.company.company_phone_no}
                        </h5>
                      </div>
                      <div className="col-4">
                        Website
                        <h5 className="text-white">
                          {this.state.company.website}
                        </h5>
                      </div>
                    </div>
                    <div className="row mt-4  text_tra1">
                      <div className="col-4">
                        IEC Number
                        <h5 className="text-white">
                          {this.state.company.company_iec_number}
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>data not found</div>
                )}
                {this.state.company !== undefined ? (
                  <div className="tab-pane" id="tabs-3" role="tabpanel">
                    <p className="text_tra1 mb-2">COMPANY ADDRESS</p>

                    <div className="row mt-4  text_tra1">
                      <div className="col-4">
                        Company Address1{" "}
                        <h5 className="text-white">
                          {this.state.company.address_line1}{" "}
                        </h5>
                      </div>
                      <div className="col-4">
                        Company Address 2{" "}
                        <h5 className="text-white">
                          {this.state.company.address_line2}{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="row mt-4  text_tra1">
                      <div className="col-4">
                        Country{" "}
                        <h5 className="text-white">
                          {this.state.company.country_name ? this.state.company.country_name :
                            this.state.country}{" "}
                        </h5>
                      </div>
                      <div className="col-4">
                        City{" "}
                        <h5 className="text-white">
                          {this.state.company.city}{" "}
                        </h5>
                      </div>
                    </div>
                    <div className="row mt-4  text_tra1">
                      <div className="col-4">
                        State{" "}
                        <h5 className="text-white">
                          {this.state.company.state}{" "}
                        </h5>
                      </div>
                      <div className="col-4">
                        Zip Code{" "}
                        <h5 className="text-white">
                          {this.state.company.zipcode}{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>data not found</div>
                )}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div style={{ height: "20rem" }} className="info-one">
              <p className="text_tra1 mb-2">REGISTRATION DETAILS</p>
              <div className="nav info-nav flex-column " role="tablist">
                <div
                  className="d-flex p-2 my-1 bgc-dark"
                  id="circle1"
                  onClick={(e) => this.setActive(e)}
                >
                  <div
                    className={
                      this.state.activeId == "circle1"
                        ? "circle mt-2 ml-2 active"
                        : "circle mt-2 ml-2"
                    }
                    id="circle1"
                  ></div>
                  <a
                    className="nav-link text-white "
                    data-toggle="tab"
                    href="#tabs-1"
                    id="circle1"
                    role="tab"
                  >
                    Contact Personal Details
                  </a>
                </div>
                <div
                  className="p-2 d-flex my-1 bgc-dark"
                  id="circle2"
                  onClick={(e) => this.setActive(e)}
                >
                  <div
                    className={
                      this.state.activeId == "circle2"
                        ? "circle mt-2 ml-2 active"
                        : "circle mt-2 ml-2"
                    }
                    id="circle2"
                  ></div>
                  <a
                    className="nav-link text-white"
                    data-toggle="tab"
                    href="#tabs-2"
                    id="circle2"
                    role="tab"
                  >
                    Company Details
                  </a>
                </div>
                <div
                  className="p-2 d-flex my-1 bgc-dark"
                  id="circle3"
                  onClick={(e) => this.setActive(e)}
                >
                  <div
                    className={
                      this.state.activeId == "circle3"
                        ? "circle mt-2 ml-2 active"
                        : "circle mt-2 ml-2"
                    }
                    id="circle3"
                  ></div>
                  <a
                    className="nav-link text-white"
                    data-toggle="tab"
                    href="#tabs-3"
                    id="circle3"
                    role="tab"
                  >
                    Company Address
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  country: state.country,
});

const mapDispatchToProps = (dispatch) => ({
  getUserDetails: (data) => dispatch(getUserDetails(data)),
  getCountry: (data) => dispatch(getCountry(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BorrowerDetails));

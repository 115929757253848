import * as COUNTRY_CONSTANTS from "../constants/countryConstants";
import ajax from "../../utils/ajax";

export const requestGetCountry = () => ({
  type: COUNTRY_CONSTANTS.HOME_GET_COUNTRY_CONSTANTS.REQUEST,
});

export const recievedGetCountryResponse = (data) => ({
  type: COUNTRY_CONSTANTS.HOME_GET_COUNTRY_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetCountryError = (err) => ({
  type: COUNTRY_CONSTANTS.HOME_GET_COUNTRY_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

//  export const getCountry =() => {
//    let url= COUNTRY_CONSTANTS.HOME_GET_COUNTRY_CONSTANTS.URL;

//  return dispatch => {
//    dispatch(requestGetCountry());

//    axios.get(url,
//    {
//      headers:
//      {
//          'Content-Type': 'application/json'

//  } }
//    )
//      .then(res =>
//       dispatch(recievedGetCountryResponse(res.data))

//        )
//      .catch(err => dispatch(recievedGetCountryError(err)))
//  }
// }

export const getCountry = () => async (dispatch) => {
  dispatch(requestGetCountry());
  try {
    let url = COUNTRY_CONSTANTS.HOME_GET_COUNTRY_CONSTANTS.URL;
    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetCountryResponse(res.data));
      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetCountryError(err));
    return Promise.reject(err);
  }
};

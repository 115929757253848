import * as COMPANY_CONSTANTS from '../constants/companyConstants';

export const companyReducer = (state = {
    type: '',
    error: '',
    isFetching: false,
   company:undefined,
   updateSuccess: undefined,
   profileCompany: undefined,
   buyerList: undefined,
   deleteCompany: undefined,
   userCompany:undefined,
}, action) => {
   
    switch (action.type) {
     
              case COMPANY_CONSTANTS.HOME_POST_COMPANY_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    company: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case COMPANY_CONSTANTS.HOME_POST_COMPANY_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    company: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case COMPANY_CONSTANTS.HOME_POST_COMPANY_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });
                case COMPANY_CONSTANTS.HOME_GET_COMPANY_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    company: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case COMPANY_CONSTANTS.HOME_GET_COMPANY_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    company: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case COMPANY_CONSTANTS.HOME_GET_COMPANY_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });
                case COMPANY_CONSTANTS.HOME_UPDATE_COMPANY_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    company: undefined,
                    updateSuccess: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case COMPANY_CONSTANTS.HOME_UPDATE_COMPANY_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    company: action.data,
                    updateSuccess:action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case COMPANY_CONSTANTS.HOME_UPDATE_COMPANY_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });
                case COMPANY_CONSTANTS.HOME_GET_COMPANY_BY_COMPANY_ID_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    profileCompany: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case COMPANY_CONSTANTS.HOME_GET_COMPANY_BY_COMPANY_ID_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    profileCompany: action.data,                  
                    lastUpdated: action.receivedAt,
                });
    
            case COMPANY_CONSTANTS.HOME_GET_COMPANY_BY_COMPANY_ID_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });
                case COMPANY_CONSTANTS.HOME_GET_BUYERS_LIST_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    buyerList: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case COMPANY_CONSTANTS.HOME_GET_BUYERS_LIST_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    buyerList: action.data,                  
                    lastUpdated: action.receivedAt,
                });
    
            case COMPANY_CONSTANTS.HOME_GET_BUYERS_LIST_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });

                //Delete Company
                case COMPANY_CONSTANTS.HOME_DELETE_COMPANY_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    deleteCompany: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case COMPANY_CONSTANTS.HOME_DELETE_COMPANY_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    deleteCompany: action.data,                  
                    lastUpdated: action.receivedAt,
                });
    
            case COMPANY_CONSTANTS.HOME_DELETE_COMPANY_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });
                case COMPANY_CONSTANTS.HOME_GET_COMPANY_BY_ID_CONSTANTS.REQUEST:
                    return Object.assign({}, state, {
                        isFetching: true,
                        type: action.type,
                        userCompany: undefined,
                        lastUpdated: action.receivedAt,
                    });
        
                case COMPANY_CONSTANTS.HOME_GET_COMPANY_BY_ID_CONSTANTS.RECEIVED:
                    return Object.assign({}, state, {
                        isFetching: false,
                        type: action.type,
                        userCompany: action.data,                  
                        lastUpdated: action.receivedAt,
                    });
        
                case COMPANY_CONSTANTS.HOME_GET_COMPANY_BY_ID_CONSTANTS.RECEIVED_ERROR:
                    return Object.assign({}, state, {
                        isFetching: false,
                        type: action.type,
                        error: action.error,
                    });

                    case COMPANY_CONSTANTS.HOME_GET_CREATED_COMPANY_CONSTANTS.REQUEST:
                        return Object.assign({}, state, {
                            isFetching: true,
                            type: action.type,
                            company: undefined,
                            lastUpdated: action.receivedAt,
                        });
            
                    case COMPANY_CONSTANTS.HOME_GET_CREATED_COMPANY_CONSTANTS.RECEIVED:
                        return Object.assign({}, state, {
                            isFetching: false,
                            type: action.type,
                            company: action.data,
                            lastUpdated: action.receivedAt,
                        });
            
                    case COMPANY_CONSTANTS.HOME_GET_CREATED_COMPANY_CONSTANTS.RECEIVED_ERROR:
                        return Object.assign({}, state, {
                            isFetching: false,
                            type: action.type,
                            error: action.error,
                        });   

        default:
            return state;
    }
};


export default companyReducer;

import React from "react";
import Modal from "react-bootstrap/Modal";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import Deposit from "../../assets/images/Deposit.png";
import Withdraw from "../../assets/images/Withdraw.png";
import Sidebar from "./Dashboard/Sidebar";
import MobHeader from "./Dashboard/MobHeader";
import Bottomnav from "./Dashboard/bottomnav";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/wallet.css";
import "../../assets/css/history.css";
import Mainheader from "../Lender/Dashboard/mainHeader";
import { getWalletHistory } from "../../api/venlyWalletService";
import { toast } from "react-toastify";

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [],
      id:'',
      WalletId: sessionStorage.getItem("walletId"),
      mobView:false,
      show:false,
      transaction:''
    };
  }
  showHistory = async (id) => {
    try {
      let id = sessionStorage.getItem("wallet_Id");
      let result = await getWalletHistory(id);
      if (result.data.success) {
        this.setState({
          history: result.data.data,
        });
      } else {
        toast.info(result.data.message);
      }
    } catch (err) {}
  };

  componentDidMount() {
    if(window.innerWidth<650){
      this.setState({mobView:true})
    }
    this.showHistory();
  }

  showDetails = (data)=> {  
  const id= JSON.parse(data.metadata).transactionId
  this.setState({
    transaction:data,
    id:id,
    show:true
  })  
  }

  render() {
    return (
      <>
        <Sidebar />
        <Bottomnav />
        <div className="main-content main-content-lender">
          <MobHeader />
          <header className="disp-sm-none">
            <h2
              className="heading text-white ml-4 mt-3 "
              style={{ marginLeft: "16px" }}
            ></h2>
            <Mainheader />
          </header>
          <Row>
            <div className="col-md-5 headingmob">
              <h2
                className=" heading text-white ml-4 mt-3"
                style={{ marginLeft: "16px" }}
              >
                Wallet Transactions
              </h2>
            </div>

        
          </Row>
          <Container fluid>
            <Row>
              <Col md="12">
                <div className="table-full-width border-0 table-responsive px-0">
                  <table className=" border-0">
                    <thead className="disp_non_sm">
                      <tr className="text_tra1">
                        <th>Date and Time</th>
                        <th title="Spending limit approval transaction are currently been show as withdraw">Action <i className="uil-info-circle" /></th>
                        <th>Transaction ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.history !== "" ? (
                        this.state.history.map((index, key) => {
                          return (
                            <tr key={key} className="sec1 pt-1 pb-1" onClick={()=>this.state.mobView && this.showDetails(index)}>
                              <td>
                                {moment(index.eventTimestamp).format(
                                  "DD-MM-YYYY"
                                )}
                                <td>
                                  {moment(index.eventTimestamp).format(
                                    "h:mm:ss"
                                  )}
                                </td>
                              </td>
                              <td>
                                {index.type === "INCOMING_TRANSACTION" ? (
                                  <div>
                                    {" "}
                                    <img alt="deposit" src={Deposit} /> DEPOSIT
                                  </div>
                                ) : (
                                  <div>
                                    <img alt="withdraw" src={Withdraw} />{" "}
                                    WITHDRAW
                                  </div>
                                )}
                              </td>
                              <td
                                className="trans_id disp-sm-none text-wrap"
                                style={{ cursor: "pointer" }}
                              >
                                <a
                                  target="_blank"
                                  href={
                                    "https://polygonscan.com/tx/" +
                                    JSON.parse(index.metadata).transactionId
                                  } rel="noreferrer"
                                >
                                  <p
                                    style={{ width: "360px", color: "#4db5c4" }}
                                  >
                                    {JSON.parse(index.metadata).transactionId}
                                  </p>
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>No Transactions to display</tr>
                      )}
                    </tbody>
                  </table>
                  <Modal
                show={this.state.show}
                onHide={() => this.setState({ show: false })}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backgroundColor: "transparent" }}
              >
                <div className="finaloffer  p-3">
                <div className="line mx-auto"></div>

                <div className="text_tra1 mt-3">
                Date and Time
                <div className="text-white">
                {moment(this.state.transaction.eventTimestamp).format(
                                  "DD-MM-YYYY"
                                )}
                <span className="text_tra1 mx-2">
                {moment(this.state.transaction.eventTimestamp).format(
                                  "h:mm:ss"
                                )}
                </span>
                </div>
                </div>

                <div className="text_tra1 mt-3">
                Actions
                <div className="text-white mt-2">
                {this.state.transaction.type === "INCOMING_TRANSACTION" ? (
                                  <div>
                                    {" "}
                                    <img alt="deposit" src={Deposit} /> DEPOSIT
                                  </div>
                                ) : (
                                  <div>
                                    <img alt="withdraw" src={Withdraw} />{" "}
                                    WITHDRAW
                                  </div>
                                )}
                </div>
                </div>

                <div className="text_tra1 mt-3">
                Transactions ID
                <div className="txt_g font-12">
                <a  target="_blank" href={
                                    "https://polygonscan.com/tx/" +
                                    this.state.id
                                  } rel="noreferrer">
                                  <p style={{ width: "360px", color: "#4db5c4" ,wordWrap:"break-word"}}>
                                    {this.state.id}
                                  </p>
                                </a> 
                </div>
                </div>
                </div>
              </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(History);

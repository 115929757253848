import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Uploadpopup from "./uploadpopup";
import Upload from "../../../assets/images/upload.png";
export default function Invoicepopup(props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div style={{ fontFamily: "Averta-Semibold,sans-serif" }}>
      <Uploadpopup show={modalShow} onHide={() => setModalShow(false)} />
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ backgroundColor: "black" }}
      >
        <div
          style={{ backgroundColor: "#333333", color: "white", border: "0" }}
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ fontSize: "16px", lineHeight: "24px" }}
            >
              Upload Invoice
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            <div
              style={{
                margin: "0 auto",
                width: "283px",
                height: "100px",
                backgroundColor: "#262626",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              <div style={{ paddingTop: "35px" }}>
                <img src={Upload} className="user-icon" alt="" />
              </div>
              Click here to upload invoice
            </div>
            <div style={{ margin: "20px", alignItems: "center" }}>
              <Button
                onClick={() => setModalShow(true)}
                style={{ backgroundColor: "#2DA3B4" }}
              >
                Submit Invoice
              </Button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
}

import axios from "axios";
import { URI } from "../constant";
import ajax from "../utils/ajax";

export let getActiveBonusApy = () => {
  let url = URI + "/bonus-apy/active";
  const res = ajax("GET", url, {});
  return res;
};

export let getBonusApyHistory = () => {
  let url = URI + "/bonus-apy/history";
  const res = ajax("GET", url, {});
  return res;
};
export let createBonusApy = (data) => {
  let url = URI + "/bonus-apy/";
  const res = ajax("POST", url, {}, {}, data);
  return res;
};

export let updateBonusApy = (data) => {
  let url = URI + "/bonus-apy/" + data._id;
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};
export let updateBonusApyStatus = (data) => {
  let url = URI + "/bonus-apy/status/" + data._id;
  const res = ajax("PUT", url, {}, {}, data);
  return res;
};

export let getBonusApyByDate = (coinId, userId) => {
  let url = URI + `/bonus-apy-lender/coin/${coinId}/user/${userId}`;
  const res = ajax("GET", url, {});
  return res;
};
import React from "react";

/**
 *
 * @param {*} props
 * @returns PricingDetails Component
 */
export default function PricingDetailsCard(props) {
  return (
    <div>
      <div className="row mt-4 text_tra1">
        <div className="col-4">
          Advance Ratio
          <div>
            <p className="txt_b">
              (
              {Number(props?.pricingData?.min_advance_ratio * 100).toFixed(0) ||
                0}
              %-
              {Number(props?.pricingData?.max_advance_ratio * 100).toFixed(0) ||
                0}
              %)
            </p>
          </div>
        </div>
        <div className="col-4">
          Discounting Fee{" "}
          <p className="txt_b">
            (
            {Number(props?.pricingData?.min_discount_range * 100).toFixed(2) ||
              0}
            %-
            {Number(props?.pricingData?.max_discount_range * 100).toFixed(2) ||
              0}
            %)
          </p>
        </div>
        <div className="col-4">
          Factoring Fee{" "}
          <p className="txt_b">
            (
            {Number(props?.pricingData?.min_factoring_fee * 100).toFixed(2) ||
              0}
            %-
            {Number(props?.pricingData?.max_factoring_fee * 100).toFixed(2) ||
              0}
            %)
          </p>
        </div>
      </div>
      <div className="row mt-4 text_tra1">
        <div className="col-4">
          Tenure{" "}
          <p className="txt_b">
            ({Number(props?.pricingData?.min_tenure) || 0}
            &nbsp; Days &nbsp;- &nbsp;
            {Number(props?.pricingData?.max_tenure) || 0}
            &nbsp; Days)
          </p>
        </div>
        <div className="col-4">
          Amount{" "}
          <p className="txt_b">
            (<span>$</span> {Number(props?.pricingData?.min_amount) || 0}
            &nbsp;- &nbsp; <span>$</span>{" "}
            {Number(props?.pricingData?.max_amount) || 0})
          </p>
        </div>
      </div>
    </div>
  );
}

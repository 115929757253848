import React, { Component } from "react";
import Sidebar from "./sidebar";
import MainHeader from "../mainHeader";
import { Link } from "react-router-dom";
import { getCoins } from "../../../services/actions/coinActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import {
  createBonusApy,
  updateBonusApy,
  getActiveBonusApy,
  updateBonusApyStatus,
  getBonusApyHistory,
} from "../../../api/bonusApyService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import helper from "../../../utils/helper";
import moment from "moment";
import { DATA_STATUS } from "../../../config/string_constants";
import ManageUser from "./manageUser";
class BonusApy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bonusShowName: "Add",
      tenureData: [60, 90, 120],
      bonusApyList: [],
      addbonus: false,
      changeBonusApyConfirm: false,
      bonusApyHistoryList: [],
      coins: [],
      manageUser: false,
      bonusApyData: {
        tenure: 0,
        start_date: "",
        end_date: "",
        minimum_locked_amount: "",
        bonus_apy_percentage: "",
        coin_id: "",
      },
      dirty: {
        minimum_locked_amount: false,
        tenure: false,
        start_date: false,
        end_date: false,
        bonus_apy_percentage: false,
        coin_id: [],
      },

      errors: {
        minimum_locked_amount: [],
        tenure: [],
        start_date: [],
        end_date: [],
        bonus_apy_percentage: [],
        coin_id: [],
      },
    };
  }
  validate = () => {
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      errors[control] = [];

      switch (control) {
        case "minimum_locked_amount":
          if (!this.state.bonusApyData[control]) {
            errors[control].push("This field is required");
          } else {
            if (parseInt(this.state.bonusApyData[control]) < 0) {
              errors[control].push(
                "minimum locked amount can't be less than zero"
              );
            }
          }
          break;
        case "coin_id":
          if (!this.state.bonusApyData[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "tenure":
          if (!this.state.bonusApyData[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "bonus_apy_percentage":
          if (!this.state.bonusApyData[control]) {
            errors[control].push("This field is required");
          } else {
            if (parseInt(this.state.bonusApyData[control]) < 0) {
              errors[control].push("bonus apy can't be less than zero");
            }
            if (parseInt(this.state.bonusApyData[control]) >= 100) {
              errors[control].push("bonus apy can't be greater than 100");
            }
          }
          break;
        case "start_date":
          if (!this.state.bonusApyData[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "end_date":
          if (!this.state.bonusApyData[control]) {
            errors[control].push("This field is required");
          } else {
            if (
              this.state.bonusApyData[control] <
              this.state.bonusApyData.start_date
            ) {
              errors[control].push("End date is smaller than start date");
            }
          }
          break;
        default:
          break;
      }
    });

    this.setState({ errors });
  };
  isValid = () => {
    let valid = true;
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      if (errors[control].length > 0) {
        valid = false;
      }
    });

    return valid;
  };
  blurChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    let bonusApyData = this.state.bonusApyData;
    if (name === "tenure") {
      bonusApyData[name] = parseInt(value);
    } else {
      bonusApyData[name] = value;
    }
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState(
      {
        bonusApyData: bonusApyData,
        dirty: dirty,
      },
      this.validate
    );
  };

  handleClose = () => {
    this.setState({
      bonusShowName: "Add",
      addbonus: false,
      changeBonusApyConfirm: false,
      bonusApyData: {
        tenure: 0,
        start_date: "",
        end_date: "",
        minimum_locked_amount: "",
        bonus_apy_percentage: "",
        coin_id: "",
      },
      dirty: {
        minimum_locked_amount: false,
        tenure: false,
        start_date: false,
        end_date: false,
        bonus_apy_percentage: false,
        coin_id: [],
      },

      errors: {
        minimum_locked_amount: [],
        tenure: [],
        start_date: [],
        end_date: [],
        bonus_apy_percentage: [],
        coin_id: [],
      },
    });
  };

  postBonusApy = async (e) => {
    e.preventDefault();
    this.validate();
    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });
    if (this.isValid()) {
      try{
        if (this.state.bonusApyData._id) {
          let response = await updateBonusApy(this.state.bonusApyData);
          if (response) {
            toast.info("Updated successfully");
            this.handleClose();
          }
  
          this.getActiveBonusApy();
        } else {
          let response = await createBonusApy(this.state.bonusApyData);
          if (response) {
            toast.info("Added successfully");
            this.handleClose();
          }
  
          this.getActiveBonusApy();
        }
  
        
      }
      catch(error){

      }
     
    }
  };
  editBonusApy = (item) => {
    let data = { ...item };
    data.start_date = moment(data.start_date).format("YYYY-MM-DD");
    data.end_date = moment(data.end_date).format("YYYY-MM-DD");
    data.coin_id = item.coin_id._id;
    this.setState({
      bonusApyData: data,
      bonusShowName: "Update",
      addbonus: true,
    });
  };
  handleSearch = (e) => {
    this.setState({
      searchPool: e.target.value,
    });
  };
  getActiveBonusApy = async () => {
    let baData = await getActiveBonusApy();
    if (helper.isNotEmpty(baData.data)) {
      this.setState({
        bonusApyList: baData.data,
      });
    }
  };
  getBonusApyHistory = async () => {
    let baData = await getBonusApyHistory();
    if (helper.isNotEmpty(baData.data)) {
      this.setState({
        bonusApyHistoryList: baData.data,
      });
    }
  };
  statusChangeBonusApy(item) {
    let data = { ...item };
    data.status = DATA_STATUS.INACTIVE;
    this.setState({
      changeBonusApyConfirm: true,
      bonusApyData: data,
    });
  }
  changeConfirmBonusStatus = async () => {
    this.setState({
      changeBonusApyConfirm: false,
    });
    let response = await updateBonusApyStatus(this.state.bonusApyData);
    if (response) {
      toast.info("Deactivated successfully");
    }

    this.getActiveBonusApy();
  };
  componentDidMount() {
    this.getActiveBonusApy();
    this.props
      .getCoins()
      .then((result) => {
        this.setState({
          coins: result,
        });
      })
      .catch((err) => {});
  }
  render() {
    return (
      <div>
        <Sidebar />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="main-content">
          <header>
            {!this.state.manageUser ? (
              <div className="dropdown show m-4">
                <a
                  className="admin_button dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="pr-4">LENDER DASHBOARD</span>
                </a>

                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <Link to="/dashboard">
                    <span className="pr-4">BORROWER DASHBOARD</span>
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <p
                  className="curserP"
                  onClick={() => {
                    this.getActiveBonusApy();
                    this.setState({ manageUser: false });
                  }}
                >
                  <i className="uil-angle-left" /> Bonus APY
                </p>
              </div>
            )}
            <MainHeader />
          </header>
          {!this.state.manageUser ? (
            <div>
              <div className="tab" role="tabpanel">
                <ul className="nav nav-tabs" role="tablist">
                  <li role="presentation" className="active pro_mone1">
                    <a
                      style={{ textDecoration: "none" }}
                      href="#ApYSec"
                      aria-controls="home"
                      role="tab"
                      data-toggle="tab"
                    >
                      Bonus APY
                    </a>
                  </li>
                  <li role="presentation" className="pro_mone1">
                    <a
                      style={{ textDecoration: "none" }}
                      href="#HistorySec"
                      aria-controls="home"
                      role="tab"
                      data-toggle="tab"
                      onClick={this.getBonusApyHistory}
                    >
                      Bonus History
                    </a>
                  </li>
                  <span className="ml-auto mr-4 search d-flex justify-content-end">
                    <button
                      className="btn-blue"
                      onClick={() => {
                        this.setState({ addbonus: true });
                      }}
                    >
                      Add Bonus
                    </button>
                  </span>
                </ul>

                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane  active" id="ApYSec">
                    <div className="table-full-width table-responsive px-0">
                      <table className="border-0">
                        <thead className="disp_non_sm">
                          <tr className="text_tra1 ">
                            <th className="pl-2">Bonus APY ID</th>
                            <th className="pl-2">Coin Name</th>
                            <th className="pl-2">Tenure</th>
                            <th className="pl-2">Start Date</th>
                            <th className="pl-2">End Date</th>
                            <th className="pl-2">Bonus APY</th>
                            <th className="pl-2">Users</th>
                            <th className="pl-2">Status</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody>
                          {this.state.bonusApyList === undefined ? (
                            <tr>No data available</tr>
                          ) : (
                            this.state.bonusApyList.map((item, key) => (
                              <tr key={key} className="sec1">
                                <td>#{item.bonus_apy_no}</td>
                                <td>
                                  {item.coin_id ? (
                                    <>
                                      {" "}
                                      <img
                                        src={item.coin_id.image}
                                        height="20px"
                                      />
                                      <span className="ml-1">
                                        {item.coin_id.name}
                                      </span>
                                      <div className="text_tra1 pl-4 ml-1">
                                        {item.coin_id.symbol.toUpperCase()}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>{item.tenure}</td>
                                <td>
                                  {moment(item.start_date).format(
                                    "DD MMM YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(item.end_date).format("DD MMM YYYY")}
                                </td>
                                <td>{Number(item.bonus_apy_percentage).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}%</td>
                                <td>{item.user_count}</td>
                                <td>{item.status}</td>
                                <td>
                                  <div className="dropdown">
                                    <a
                                      className="dropdown-toggle"
                                      id="LinkDropdownDemo"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <h5
                                        className="curserP"
                                        variant="secondary txt_b"
                                        style={{ color: "rgb(255, 255, 255)" }}
                                      >
                                        ...
                                      </h5>
                                    </a>

                                    <div
                                      className="dropdown-menu dark-dropdown"
                                      aria-labelledby="LinkDropdownDemo"
                                    >
                                      <a
                                        className="dropdown-item dark-dropdown"
                                        onClick={() => this.editBonusApy(item)}
                                      >
                                        <i className="uil uil-edit"></i>
                                        &nbsp;&nbsp;Edit
                                      </a>

                                      <a
                                        className="dropdown-item dark-dropdown"
                                        onClick={() =>
                                          this.setState({
                                            manageUser: true,
                                            bonusApyData: item,
                                          })
                                        }
                                      >
                                        <i className="uil uil-setting"></i>
                                        &nbsp;&nbsp;Manage User
                                      </a>

                                      <a
                                        className="dropdown-item dark-dropdown"
                                        onClick={() =>
                                          this.statusChangeBonusApy(item)
                                        }
                                      >
                                        <i className="uil uil-power"></i>
                                        &nbsp;&nbsp;Deactivate
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div role="tabpanel" className="tab-pane" id="HistorySec">
                    <div className="table-full-width table-responsive px-0">
                      <table className="border-0">
                        <thead className="disp_non_sm">
                          <tr className="text_tra1 ">
                            <th className="pl-2">Bonus APY ID</th>
                            <th className="pl-2">Coin Name</th>
                            <th className="pl-2">Tenure</th>
                            <th className="pl-2">Start Date</th>
                            <th className="pl-2">End Date</th>
                            <th className="pl-2">Bonus APY</th>
                            <th className="pl-2">Users</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody>
                          {this.state.bonusApyHistoryList === undefined ? (
                            <tr>No data available</tr>
                          ) : (
                            this.state.bonusApyHistoryList.map((item, key) => (
                              <tr key={key} className="sec1">
                                <td>#{item.bonus_apy_no}</td>
                                <td>
                                  {item.coin_id ? (
                                    <>
                                      {" "}
                                      <img
                                        src={item.coin_id.image}
                                        height="20px"
                                      />
                                      <span className="ml-1">
                                        {item.coin_id.name}
                                      </span>
                                      <div className="text_tra1 pl-4 ml-1">
                                        {item.coin_id.symbol.toUpperCase()}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>{item.tenure}</td>
                                <td>
                                  {moment(item.start_date).format(
                                    "DD MMM YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(item.end_date).format("DD MMM YYYY")}
                                </td>
                                <td>{item.bonus_apy_percentage}%</td>
                                <td>{item.user_count}</td>
                                <td>
                                  <a className="view-user-link">View User</a>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ overflowY: "auto" }}
                className={`menuNav ${this.state.addbonus ? "showMenu1" : ""}`}
              >
                <div
                  style={{ borderBottom: "1px solid gray" }}
                  className="d-flex justify-content-between text-white p-3 pl-1"
                >
                  <div>
                    <h5>{this.state.bonusShowName} Bonus</h5>
                  </div>
                  <h5 style={{ cursor: "pointer" }} onClick={this.handleClose}>
                    <i className="uil-multiply" />
                  </h5>
                </div>

                <div className="w-100 pr-4 pl-4 mt-2 mx-auto text_tra1">
                  <label>Search and select the coin</label>
                  <div className="row">
                    {this.state.coins.map((item) => (
                      <div
                        className={
                          this.state.bonusApyData.coin_id === item._id
                            ? "selected-coin-button"
                            : "coin-button"
                        }
                      >
                        <input
                          type="radio"
                          name="coin_id"
                          value={item._id}
                          className="input-hidden"
                          onClick={this.handleChange}
                        />
                        <div className="pos-abs">
                          <img
                            name="coin_id"
                            className="coin-img"
                            src={item.image}
                          ></img>
                          {item.name}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="text-danger">
                    {this.state.errors.coin_id[0] && this.state.dirty.coin_id
                      ? this.state.errors.coin_id
                      : ""}
                  </div>
                </div>
                <div className="w-100 mt-2 pr-4 pl-4 mx-auto text_tra1">
                  <label>Minimum Locked Amount</label>
                  <div className="mt-1 d-flex justify-content-end">
                    {/* <img
                  style={{
                    position: "absolute",
                    margin: "8px 5px 7px auto",
                    color: "#fff",
                  }}
                  height="20px"
                  src={this.state.Pool.logo_url}
                ></img> */}
                    <input
                      type="number"
                      className="input"
                      placeholder="Enter Amount"
                      name="minimum_locked_amount"
                      value={this.state.bonusApyData.minimum_locked_amount}
                      onChange={this.handleChange}
                      onBlur={this.blurChange}
                    ></input>
                  </div>
                  <div className="text-danger">
                    {this.state.errors.minimum_locked_amount[0] &&
                    this.state.dirty.minimum_locked_amount
                      ? this.state.errors.minimum_locked_amount
                      : ""}
                  </div>
                </div>
                <div className=" w-100 pr-4 pl-4 mt-3">
                  <h6 className="text-white ">TASKS</h6>
                </div>
                <div className="pl-2 pr-2">
                  {
                    <div className="bgc-dark borderR m-3 p-3">
                      <div className="row">
                        <div className="col-6">
                          <div className="text_tra1">Tenure</div>

                          <div className="row ml-0">
                            {this.state.tenureData &&
                              this.state.tenureData.map((item) => (
                                <div
                                  className={
                                    this.state.bonusApyData.tenure === item
                                      ? "selected-tenure-button"
                                      : "tenure-button"
                                  }
                                >
                                  <input
                                    type="radio"
                                    name="tenure"
                                    value={item}
                                    className="input-hidden"
                                    onClick={this.handleChange}
                                  />
                                  <div className="pos-abs">{item}</div>
                                </div>
                              ))}
                          </div>

                          <div className="text-danger">
                            {this.state.errors.tenure[0] &&
                            this.state.dirty.tenure
                              ? this.state.errors.tenure
                              : ""}
                          </div>
                          {/* <div className='input d-flex p-2 justify-content-between'><input name="apy_month" value={this.state.apy_array1.apy_month}onChange={this.handleChange1} /><span>Days</span></div> */}
                        </div>
                        <div className="col-6">
                          <div className="text_tra1">Start Date</div>
                          <div className=" ">
                            <div className="select-group">
                              <input
                                className="input "
                                type="date"
                                name="start_date"
                                value={this.state.bonusApyData.start_date}
                                onChange={this.handleChange}
                                onBlur={(e) => {
                                  this.blurChange(e);
                                }}
                              />
                              <div className="select-over display-none">
                                <i className="uil uil-calender cal-dimension" />
                              </div>
                            </div>

                            <div className="text-danger">
                              {this.state.errors.start_date[0] &&
                              this.state.dirty.start_date
                                ? this.state.errors.start_date
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="col-6 mt-2">
                          <div className="text_tra1">Bonus APY</div>
                          <input
                            className="input "
                            type="number"
                            name="bonus_apy_percentage"
                            value={this.state.bonusApyData.bonus_apy_percentage}
                            onChange={this.handleChange}
                            onBlur={(e) => {
                              this.blurChange(e);
                            }}
                          />
                          <div className="text-danger">
                            {this.state.errors.bonus_apy_percentage[0] &&
                            this.state.dirty.bonus_apy_percentage
                              ? this.state.errors.bonus_apy_percentage
                              : ""}
                          </div>
                        </div>
                        <div className="col-6 mt-2">
                          <div className="text_tra1">End Date</div>
                          <div className=" ">
                            <div className="select-group">
                              <input
                                className="input "
                                type="date"
                                name="end_date"
                                value={this.state.bonusApyData.end_date}
                                onChange={this.handleChange}
                                onBlur={(e) => {
                                  this.blurChange(e);
                                }}
                              />
                              <div className="select-over display-none">
                                <i className="uil uil-calender cal-dimension" />
                              </div>
                            </div>

                            <div className="text-danger">
                              {this.state.errors.end_date[0] &&
                              this.state.dirty.end_date
                                ? this.state.errors.end_date
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div className="text-center m-4">
                  <button
                    className=" w-100 btn-blue"
                    onClick={this.postBonusApy}
                  >
                    {this.state.bonusShowName}
                  </button>
                </div>
              </div>
              <Modal
                show={this.state.changeBonusApyConfirm}
                onHide={this.handleClose}
                centered
              >
                <div style={{ backgroundColor: "#333333", color: "white" }}>
                  <Modal.Header closeButton>Deactivate</Modal.Header>
                  <Modal.Body>
                    <label>
                      Are you sure you want to deactivate selected BonusAPY?
                    </label>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="danger"
                      onClick={this.changeConfirmBonusStatus}
                    >
                      Deactivate
                    </Button>
                    <Button variant="secondary" onClick={this.handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </div>
              </Modal>
            </div>
          ) : (
            <ManageUser
              manageUser={this.state.manageUser}
              bonusApyData={this.state.bonusApyData}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  coin: state.coin,
});

const mapDispatchToProps = (dispatch) => ({
  getCoins: (data) => dispatch(getCoins(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BonusApy));

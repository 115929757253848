import {URI} from '../../constant'

export const HOME_GET_POOL_CONSTANTS = ({
    URL: URI+"/pool/",
    REQUEST: 'REQUEST_GET_POOL_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_GET_POOL_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_GET_POOL_DATA_CONSTANTS',
  });

  export const HOME_POST_POOL_CONSTANTS = ({
    URL: URI+"/pool",
    REQUEST: 'REQUEST_POST_POOL_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_POST_POOL_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_POST_POOL_DATA_CONSTANTS',
  });
  
  export const HOME_UPDATE_POOL_CONSTANTS = ({
    URL: URI+"/pool/",
    REQUEST: 'REQUEST_UPDATE_POOL_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_UPDATE_POOL_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_UPDATE_POOL_DATA_CONSTANTS',
  });

  export const HOME_DELETE_POOL_CONSTANTS = ({
    URL: URI+"/pool/",
    REQUEST: 'REQUEST_DELETE_POOL_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_DELETE_POOL_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_DELETE_POOL_DATA_CONSTANTS',
  });
import React, { Component } from "react";

import "../../../assets/css/common/fontStyle.css";
import "../../../assets/css/common/button.css";
import "../../../assets/css/common/popup.css";
import Modal from "react-bootstrap/Modal";
import ajax from "../../../utils/ajax";
import { URI, LENDER_TNC } from "../../../constant";
import moment from "moment";

export default class Training extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_show: this.props.show,
      bonus_reward: null,
      userTokenBalance: "",
      showPdf: false,
      pdfUrl: LENDER_TNC,
    };
  }
  handleClose = () => {
    this.setState({ showPdf: false });
  };
  numberWithCommas = (x) => {
    return Number(x)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  ondismis = () => {
    document.getElementById("exampleModal").style.display = "none";
    this.props.onHide();
  };

  fetchBalance = async () => {
    try {
      let walletId = sessionStorage.getItem("wallet_Id");
      if (walletId) {
        const walletData = await ajax(
          "GET",
          `${URI}/user/wallet/token/${
            this.props.lendNowdata.coin_id.address
          }/balance/${sessionStorage.getItem("wallet_Id")}`,
          {}
        );
        if (walletData.data.success) {
          this.setState({
            userTokenBalance: walletData.data.data.balance,
          });
          this.props.tokenBal(walletData.data.data.balance);
        }
      }
    } catch (error) {}
  };
  componentDidMount() {
    this.fetchBalance();
  }
  render() {
    const { show, lendNowdata, postLendNow, changeLockAmount } = this.props;

    return (
      <>
        <div
          className={`${
            this.props.show || this.state.is_show ? "displayModel" : ""
          } modal-lg modal left fade test show`}
          id="exampleModal"
          tabindex=""
          role="dialog"
          aria-labelledby="exampleModalLabel"
        >
          <div
            className="modal-dialog modal-content modal-lg "
            role="document"
            style={{ width: "100%" }}
          >
            <div className=" lenNow">
              <div
                className="modal-header lenNowheader"
                style={{ borderBottom: "2px solid #595959" }}
              >
                <h5 className="modal-title mb-2 font-style-1 ">Lend Now</h5>
                <i onClick={this.ondismis} className="uil-multiply ml-auto curserP text-white" />
              </div>
              <div className="modal-body custom-modal-body mt-2">
                <div className="row">
                  <div className="col-md-6 p-0 pl-4 ">
                    <div className="p-2 disp-sm-none lendNowSum">
                      <div className="row pl-4 ">
                        <label className="font-style-15 ml-2 ">Summary</label>
                      </div>
                      <div className="row">
                        <ul className="events">
                          <li>
                            <time datetime="10:03"></time>
                            <span className="d-flex pr-0  m-0 w-100">
                              <div className="row w-100">
                                <div className="col-md-6 p-0">
                                  <label className="font-style-8 pl-2 p-0 m-0">
                                    Lend Date
                                  </label>
                                </div>
                                <div className="col-md-6 float-right p-0">
                                  <div className="font-style-14 p-0 font-style-Averta-Semibold m-0">
                                    {this.props.lendNowdata
                                      ? moment(
                                          this.props.lendNowdata
                                            .subscription_date
                                        ).format("DD-MMM-YYYY")
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </span>
                          </li>
                          <li>
                            <time datetime="10:03"></time>
                            <span className="d-flex pr-0  m-0 bluespan w-100">
                              <div className="row w-100">
                                <div className="col-md-6 p-0">
                                  <label className="font-style-8 pl-2 p-0 m-0">
                                    Lock-in Period
                                  </label>
                                </div>
                                <div className="col-md-6 p-0 float-right">
                                  <div className="font-style-14 p-0 m-0 font-style-Averta-Semibold">
                                    {this.props.lendNowdata
                                      ? this.props.lendNowdata.selected_duration
                                      : ""}{" "}
                                    Days
                                  </div>
                                </div>
                              </div>
                            </span>
                          </li>
                          <li>
                            <time
                              className="intrest_date"
                              datetime="10:03"
                            ></time>
                            <span className="d-flex pr-0  m-0 bluespan w-100">
                              <div className="row w-100">
                                <div className="col-md-6 p-0">
                                  <label className="font-style-8 pl-2 p-0 m-0">
                                    Interest End Date
                                  </label>
                                </div>
                                <div className="col-md-6 p-0 float-right">
                                  <div className="font-style-14 p-0 m-0 font-style-Averta-Semibold">
                                    {this.props.lendNowdata
                                      ? moment(
                                          this.props.lendNowdata
                                            .interest_end_date
                                        ).format("DD-MMM-YYYY")
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </span>
                          </li>

                          <li>
                            <time datetime="10:03"></time>
                            <span className="d-flex pr-0  m-0 bluespan w-100">
                              <div className="row w-100">
                                <div className="col-md-6 p-0">
                                  <label className="font-style-8 pl-2 p-0 m-0">
                                    Redemption Period
                                  </label>
                                </div>
                                <div className="col-md-6  p-0 float-right">
                                  <div className="font-style-14 p-0 m-0 font-style-Averta-Semibold">
                                    1 Day
                                  </div>
                                </div>
                              </div>
                            </span>
                          </li>

                          <li>
                            <time datetime="10:03"></time>

                            <div className="row w-100">
                              <div className="col-md-6">
                                <label className="font-style-8 pl-3 p-0 m-0">
                                  Redemption Date
                                </label>
                              </div>
                              <div className="col-md-6 p-0 float-right">
                                <div className="font-style-14 pl-1 m-0 font-style-Averta-Semibold">
                                  {this.props.lendNowdata
                                    ? moment(
                                        this.props.lendNowdata.redemption_date
                                      ).format("DD-MMM-YYYY")
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="divider mt-3"></div>
                      <div className="row m-0 p-0">
                        <div className="col-md-6 m-0 p-0">
                          <label className="font-style-8 ml-2">
                            Total Locked Amount
                          </label>
                        </div>
                        <div className="col-md-6 m-0 p-0 float-right">
                          <div className="font-style-16 float-right font-style-Averta-Semibold">
                            {this.props.lendNowdata
                              ? this.props.lendNowdata.lock_amount
                              : ""}
                            <label
                              className="font-style-16 float-right m-0 p-0"
                              style={{ textTransform: "uppercase" }}
                            >
                              {" "}
                              &nbsp;
                              {this.props.lendNowdata.coin_id &&
                                this.props.lendNowdata.coin_id.symbol}
                            </label>
                          </div>
                        </div>
                        <div>
                          <label className="text_tra1 font-14 mt-2 ml-2">
                            Funds once lent, cannot be redeemed before the end
                            of the lock in period. Amount will be redeemed from
                            the pool to your wallet automatically, at the end of
                            tenure.
                          </label>
                        </div>

                        <div className="row mt-125">
                          <div className="col-md-12">
                            <label className=" font-style-9 font-style-Averta-Semibold">
                              POOL Details
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label
                              className="font-style-8 m-0 p-0"
                              title="Amount of funds deposited to the pool"
                            >
                              Filled Amount (
                              {(
                                (this.props.lendNowdata.total_deposited_amount /
                                  this.props.lendNowdata.max_amount) *
                                100
                              ).toFixed(2)}
                              %)
                              <i className="uil-info-circle text_tra1" />
                            </label>
                            <div>
                              <h5 className="m-0 p-0 text-white text-uppercase font-style-Averta-Semibold">
                                {this.props.lendNowdata
                                  ? this.numberWithCommas(
                                      this.props.lendNowdata
                                        .total_deposited_amount
                                    )
                                  : ""}{" "}
                                {this.props.lendNowdata.coin_id
                                  ? this.props.lendNowdata.coin_id.symbol
                                  : ""}
                              </h5>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label className="font-style-8 m-0 p-0">
                              Minimum
                            </label>
                            <div>
                              <h5 className="m-0 p-0 text-white text-uppercase font-style-Averta-Semibold">
                                {this.props.lendNowdata
                                  ? this.numberWithCommas(
                                      this.props.lendNowdata.minimum_lock_amount
                                    )
                                  : ""}{" "}
                                {this.props.lendNowdata.coin_id
                                  ? this.props.lendNowdata.coin_id.symbol
                                  : ""}
                              </h5>
                            </div>
                          </div>
                          <div className="col-md-6 mt-2">
                            <label
                              className="font-style-8 m-0 p-0"
                              title="Current Liquidity"
                            >
                              Available Amount
                            </label>
                            <div>
                              <h5 className="m-0 p-0 text-white text-uppercase font-style-Averta-Semibold">
                                {this.props.lendNowdata
                                  ? this.numberWithCommas(
                                      this.props.lendNowdata.max_amount -
                                        this.props.lendNowdata
                                          .total_deposited_amount
                                    )
                                  : ""}{" "}
                                {this.props.lendNowdata.coin_id
                                  ? this.props.lendNowdata.coin_id.symbol
                                  : ""}
                              </h5>
                            </div>
                          </div>
                          <div className="col-md-6 mt-2">
                            <label
                              className="font-style-8 m-0 p-0"
                              title="Maximum size of funds that can be deposited to the pool"
                            >
                              Pool Size{" "}
                              <i className="uil-info-circle text_tra1" />
                            </label>
                            <div>
                              <h5 className="m-0 p-0 text-white text-uppercase font-style-Averta-Semibold">
                                {this.props.lendNowdata
                                  ? this.numberWithCommas(
                                      this.props.lendNowdata.max_amount
                                    )
                                  : ""}{" "}
                                {this.props.lendNowdata.coin_id
                                  ? this.props.lendNowdata.coin_id.symbol
                                  : ""}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-8 col-6">
                        <label className="font-style-8 m-0 p-0">
                          Coin Name
                        </label>

                        <div className="mt-1">
                          <img
                            alt=""
                            className="mr-2"
                            src={
                              this.props.lendNowdata.coin_id
                                ? this.props.lendNowdata.coin_id.image
                                : ""
                            }
                            width="25px"
                            height="25px"
                          />
                          <label className="m-0 p-0 font-style-14 font-style-Averta-Semibold">
                            {this.props.lendNowdata.coin_id
                              ? this.props.lendNowdata.coin_id.name
                              : ""}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 col-6">
                        <label className="font-style-8 m-0 p-0">
                          {" "}
                          Total APY*{" "}
                        </label>
                        <div className="font-style-14 m-0 p-0 font-style-Averta-Semibold text-uppercase">
                          <label className="font-style-16 m-0 p-0">
                            {this.numberWithCommas(
                              this.props.lendNowdata &&
                                this.props.lendNowdata.user_bonus_checked
                                ? parseFloat(this.props.lendNowdata.bouns_apy) +
                                    parseFloat(this.props.lendNowdata.est_apy) +
                                    parseFloat(
                                      this.props.lendNowdata.bonus_reward
                                        ? this.props.lendNowdata.bonus_reward
                                            .bonus_apy_percentage
                                        : 0
                                    )
                                : parseFloat(this.props.lendNowdata.bouns_apy) +
                                    parseFloat(this.props.lendNowdata.est_apy)
                            )}
                            %
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-5">
                        <label className="font-style-2 m-0 p-0">
                          Lock Amount
                        </label>
                      </div>
                      <div className="col-md-7">
                        <div className="font-style-2 float-right m-0 p-0 ">
                          Avl Bal: {this.state.userTokenBalance}{" "}
                          <label
                            className="font-style-2 float-right m-0 p-0"
                            style={{ textTransform: "uppercase" }}
                          >
                            {this.props.lendNowdata.coin_id
                              ? this.props.lendNowdata.coin_id.symbol
                              : ""}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <span className="ml-auto   d-flex justify-content-end">
                          <label
                            style={{ position: "absolute", color: "#2DA3B4" }}
                            className="font-style-2 mt-3 mr-1 p-0 m-0 font-style-Averta-Semibold"
                            onClick={() =>
                              this.props.changeAmount(
                                this.state.userTokenBalance
                              )
                            }
                          >
                            MAX{" "}
                            <label
                              className="font-style-2 float-right m-0 p-0 font-style-Averta-Semibold"
                              style={{ textTransform: "uppercase" }}
                            >
                              {" "}
                              |{" "}
                              {this.props.lendNowdata.coin_id
                                ? this.props.lendNowdata.coin_id.symbol
                                : ""}
                            </label>
                          </label>
                          <input
                            className="w-100 mb-1"
                            style={{
                              background: "#212121",
                              border: "1px solid #FFFFFF52",
                              paddingRight: "20px",
                              padding: "10px",
                              color: "white",
                            }}
                            type="number"
                            placeholder="Please enter the amount"
                            value={this.props.lendNowdata.lock_amount}
                            onChange={this.props.changeLockAmount}
                          />
                        </span>
                        {this.props.validateAmount ? (
                          <div className="text-danger">
                            {this.props.validateError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {this.props.amountError ? (
                      <span style={{ color: "red" }}>
                        Amount should be less then available amount
                      </span>
                    ) : (
                      ""
                    )}
                    <div className="row mt-3">
                      <div className="col-xs-5 mx-3">
                        <label className="font-style-2 m-0 p-0">
                          Standard APY Rewards
                        </label>
                      </div>
                      <div className="col-xs-3">
                        <div className="font-style-14 m-0 p-0 font-style-Averta-Semibold text-uppercase">
                          <label className="font-style-16 m-0 p-0">
                            {this.numberWithCommas(
                              this.props.lendNowdata
                                ? this.props.lendNowdata.est_apy
                                : "0"
                            )}
                            %
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-xs-5 mx-3">
                        <label className="font-style-2 m-0 p-0">
                          Bonus Rewards
                        </label>
                      </div>
                      <div className="col-xs-3">
                        <div className="font-style-14 m-0 p-0 font-style-Averta-Semibold text-uppercase">
                          <label className="font-style-16 m-0 p-0">
                            {this.numberWithCommas(
                              this.props.lendNowdata
                                ? this.props.lendNowdata.bouns_apy
                                : "0"
                            )}
                            %
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row my-3">
                      <>
                        {this.props.lendNowdata.bonus_reward &&
                        this.props.lendNowdata.bonus_reward.tenure ===
                          parseInt(this.props.lendNowdata.selected_duration) ? (
                          <>
                            <div className="col-xs-5 mx-3">
                              <label className="font-style-2 m-0 p-0">
                                User Bonus Rewards
                              </label>
                            </div>
                            <div className="col-xs-3">
                              <div className="font-style-14 m-0 p-0 font-style-Averta-Semibold text-uppercase">
                                <label className="font-style-16 m-0 p-0">
                                  {this.numberWithCommas(
                                    this.props.lendNowdata.bonus_reward
                                      ? this.props.lendNowdata.bonus_reward
                                          .bonus_apy_percentage
                                      : "0"
                                  )}
                                  %
                                </label>
                              </div>
                            </div>
                            {this.props.lendNowdata.user_bonus_checked ? (
                              <div className="col-xs-3 mx-3">
                                <i
                                  className="fa fa-check fa-lg "
                                  style={{ color: "green" }}
                                  aria-hidden="true"
                                ></i>
                              </div>
                            ) : parseFloat(
                                this.props.lendNowdata.lock_amount
                              ) >=
                              parseFloat(
                                this.props.lendNowdata.bonus_reward
                                  .minimum_locked_amount
                              ) ? (
                              <div className="col-xs-3 mx-3">
                                <button
                                  style={{ backgroundColor: "#2DA3B4" }}
                                  className="btn-md  btn btn-primary "
                                  disabled={
                                    this.props.lendNowdata
                                      ? this.props.lendNowdata.loading
                                      : false
                                  }
                                  onClick={() => {
                                    this.props.claimUserBonusApy(
                                      this.props.lendNowdata.lock_amount,
                                      this.props.lendNowdata.bonus_reward
                                    );
                                  }}
                                >
                                  Claim
                                </button>
                              </div>
                            ) : (
                              <div className="col-xs-3 mx-3">
                                <button
                                  style={{ backgroundColor: "#2DA3B4" }}
                                  className="btn-md  btn btn-primary "
                                  disabled
                                >
                                  Claim
                                </button>
                              </div>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </>
                      <label className="font-style-2 my-2 mx-3">
                        {this.props.lendNowdata.bonus_reward &&
                        this.props.lendNowdata.bonus_reward
                          ? `Note : You can get Bonus Rewards of ${
                              this.props.lendNowdata.bonus_reward
                                .bonus_apy_percentage
                            }% if you select duration of ${
                              this.props.lendNowdata.bonus_reward.tenure
                            } days and have mimimum amount of ${
                              this.props.lendNowdata.bonus_reward
                                .minimum_locked_amount
                            } ${
                              this.props.lendNowdata.bonus_reward.coin_id &&
                              this.props.lendNowdata.bonus_reward.coin_id.symbol.toUpperCase()
                            }`
                          : ""}
                      </label>
                    </div>
                    <div className=" row  pt-2  py-2 mt-2 insurance  ml-1 mr-1">
                      <div className="col-xs-5 px-3">
                        <label className="font-style-14 m-0 p-0">
                          Total Returns:
                        </label>
                      </div>
                      <div className="col-xs-7 px-3">
                        <div className="font-style-16 m-0 p-0">
                          <lable className="float-right">
                            {this.props.lendNowdata
                              ? this.numberWithCommas(
                                  isNaN(
                                    this.props.lendNowdata.total_accure_intrest
                                  )
                                    ? "0"
                                    : this.props.lendNowdata
                                        .total_accure_intrest
                                )
                              : ""}{" "}
                            &nbsp;
                            {this.props.lendNowdata.coin_id
                              ? this.props.lendNowdata.coin_id.symbol.toUpperCase()
                              : ""}
                            &nbsp;+ &nbsp;
                            {this.props.lendNowdata
                              ? this.numberWithCommas(
                                  parseFloat(
                                    isNaN(
                                      this.props.lendNowdata.total_bouns_intrest
                                    )
                                      ? "0"
                                      : this.props.lendNowdata
                                          .total_bouns_intrest
                                  ) +
                                    parseFloat(
                                      isNaN(
                                        this.props.lendNowdata
                                          .total_bonus_reward_interest
                                      )
                                        ? "0"
                                        : this.props.lendNowdata
                                            .total_bonus_reward_interest
                                    )
                                )
                              : ""}
                            &nbsp;
                            {this.props.lendNowdata.coin_id
                              ? this.props.lendNowdata.coin_id.symbol.toUpperCase()
                              : ""}{" "}
                          
                          </lable>
                        </div>
                      </div>
                    </div>
                    <div className=" row  pt-2  py-2 mt-2  ml-1 mr-1">
                      <label className="font-style-2 m-0 p-0">
                        <table style={{ border: "none", color: "inherit" }}>
                          <ul>
                            <li className="mb-2">
                              *TRADE token count will be based on TRADE token
                              price on date of redemption from the pool
                            </li>
                            <li className="mb-2">
                              *Rewards along with the principal will be
                              automatically disbursed back to user wallet on
                              completion of the specific pool tenure
                            </li>
                            <li className="mb-2">
                              *Bonus rewards will be distributed in TRADE coins.
                              Calculation will be done at the time of redemption
                            </li>
                          </ul>
                        </table>
                      </label>
                    </div>
                    <div className="divider mt-1"></div>
                    <div className="row mt-3 ">
                      <div className="col-md-6">
                        <label className="font-style-8 m-0 p-0">
                          Insurance
                        </label>
                      </div>
                      <div className="col-md-6 "></div>
                    </div>
                    <div className=" row  pt-2 pb-2 mt-2 insurance  ml-1 mr-1">
                      <div className="col-md-6">
                        <label className="font-style-14 m-0 p-0">
                          Insurance
                        </label>
                      </div>
                      <div className="col-md-6 ">
                        <div className="row w-100  justify-content-end font-style-14 m-0 p-0 ">
                          <lable className="float-right"> $0.00</lable>
                        </div>
                        <div className="font-style-17 row w-100  float-right  justify-content-end m-0 p-0">
                          <lable className="float-right"> Coming Soon...</lable>
                        </div>
                      </div>
                    </div>

                    {/* Mobile view start */}
                    <div className="row  mt-3  disp-lg-none">
                      <label className="font-style-15 ml-2 ">Summary</label>

                      <ul className="bgc-dark1  p-3 events">
                        <li>
                          <time datetime="10:03"></time>
                          <span className="d-flex pr-0  m-0 w-100">
                            <div className="row w-100">
                              <div className="col-6 p-0">
                                <label className="font-style-8 pl-2 p-0 m-0">
                                  Lend Date
                                </label>
                              </div>
                              <div className="col-6 float-right p-0">
                                <div className="font-style-14 p-0 font-style-Averta-Semibold m-0">
                                {this.props.lendNowdata
                                      ? moment(
                                          this.props.lendNowdata
                                            .subscription_date
                                        ).format("DD-MMM-YYYY")
                                      : ""}
                                </div>
                              </div>
                            </div>
                          </span>
                        </li>
                        <li>
                        <time
                            className="intrest_date"
                            datetime="10:03"
                          ></time>
                          <span className="d-flex pr-0  m-0 bluespan w-100">
                            <div className="row w-100">
                              <div className="col-6 p-0">
                                <label className="font-style-8 pl-2 p-0 m-0">
                                  Lock-in Period
                                </label>
                              </div>
                              <div className="col-6 p-0 float-right">
                                <div className="font-style-14 p-0 m-0 font-style-Averta-Semibold">
                                  {this.props.lendNowdata
                                    ? this.props.lendNowdata.selected_duration
                                    : ""}{" "}
                                  Days
                                </div>
                              </div>
                            </div>
                          </span>
                        </li>
                        <li>
                          <time
                            className="intrest_date"
                            datetime="10:03"
                          ></time>
                          <span className="d-flex pr-0  m-0 bluespan w-100">
                            <div className="row w-100">
                              <div className="col-6 p-0">
                                <label className="font-style-8 pl-2 p-0 m-0">
                                  Interest End Date
                                </label>
                              </div>
                              <div className="col-6 p-0 float-right">
                                <div className="font-style-14 p-0 m-0 font-style-Averta-Semibold">
                                      {this.props.lendNowdata
                                      ? moment(
                                          this.props.lendNowdata
                                            .interest_end_date
                                        ).format("DD-MMM-YYYY")
                                      : ""}
                                </div>
                              </div>
                            </div>
                          </span>
                        </li>

                        <li>
                          <time datetime="10:03"></time>
                          <span className="d-flex pr-0  m-0 bluespan w-100">
                            <div className="row w-100">
                              <div className="col-6 p-0">
                                <label className="font-style-8 pl-2 p-0 m-0">
                                  Redemption Period
                                </label>
                              </div>
                              <div className="col-6  p-0 float-right">
                                <div className="font-style-14 p-0 m-0 font-style-Averta-Semibold">
                                  1 Days
                                </div>
                              </div>
                            </div>
                          </span>
                        </li>

                        <li>
                          <time datetime="10:03"></time>

                          <div className="row w-100">
                            <div className="col-6">
                              <label className="font-style-8 pl-3 p-0 m-0">
                                Redemption Date
                              </label>
                            </div>
                            <div className="col-6 p-0 float-right">
                              <div className="font-style-14 p-0 m-0 font-style-Averta-Semibold">
                                {this.props.lendNowdata
                                  ? moment(
                                      this.props.lendNowdata.redemption_date
                                    ).format("DD-MM-YYYY")
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="row mt-4 disp-lg-none">
                          <div className="col-md-12">
                            <label className=" font-style-9 font-style-Averta-Semibold">
                              POOL Details
                            </label>
                          </div>
                          <div className="col-6">
                            <label
                              className="font-style-8 m-0 p-0"
                              title="Amount of funds deposited to the pool"
                            >
                              Filled Amount (
                              {(
                                (this.props.lendNowdata.total_deposited_amount /
                                  this.props.lendNowdata.max_amount) *
                                100
                              ).toFixed(2)}
                              %)
                              <i className="uil-info-circle text_tra1" />
                            </label>
                            <div>
                              <label className="m-0 p-0 font-style-9 font-style-Averta-Semibold">
                                {this.props.lendNowdata
                                  ? this.numberWithCommas(
                                      this.props.lendNowdata
                                        .total_deposited_amount
                                    )
                                  : ""}{" "}
                                {this.props.lendNowdata.coin_id
                                  ? this.props.lendNowdata.coin_id.symbol
                                  : ""}
                              </label>
                            </div>
                          </div>
                          <div className="col-6">
                            <label className="font-style-8 m-0 p-0">
                              Minimum
                            </label>
                            <div>
                              <label className="m-0 p-0 font-style-9 font-style-Averta-Semibold">
                                {this.props.lendNowdata
                                  ? this.numberWithCommas(
                                      this.props.lendNowdata.minimum_lock_amount
                                    )
                                  : ""}{" "}
                                {this.props.lendNowdata.coin_id
                                  ? this.props.lendNowdata.coin_id.symbol
                                  : ""}
                              </label>
                            </div>
                          </div>
                          <div className="col-6">
                            <label
                              className="font-style-8 m-0 p-0"
                              title="Current Liquidity"
                            >
                              Available Amount
                            </label>
                            <div>
                              <label className="m-0 p-0 font-style-9 font-style-Averta-Semibold">
                                {this.props.lendNowdata
                                  ? this.numberWithCommas(
                                      this.props.lendNowdata.max_amount -
                                        this.props.lendNowdata
                                          .total_deposited_amount
                                    )
                                  : ""}{" "}
                                {this.props.lendNowdata.coin_id
                                  ? this.props.lendNowdata.coin_id.symbol
                                  : ""}
                              </label>
                            </div>
                          </div>
                          <div className="col-6">
                            <label
                              className="font-style-8 m-0 p-0"
                              title="Maximum size of funds that can be deposited to the pool"
                            >
                              Pool Size{" "}
                              <i className="uil-info-circle text_tra1" />
                            </label>
                            <div>
                              <label className="m-0 p-0 font-style-9 font-style-Averta-Semibold">
                                {this.props.lendNowdata
                                  ? this.numberWithCommas(
                                      this.props.lendNowdata.max_amount
                                    )
                                  : ""}{" "}
                                {this.props.lendNowdata.coin_id
                                  ? this.props.lendNowdata.coin_id.symbol
                                  : ""}
                              </label>
                            </div>
                          </div>
                        </div>

                    {/* Mobile View end */}

                    <div className="divider mt-3"></div>
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        id="checkbox"
                        name="agreement"
                        value={this.props.lendNowdata.tnc_status}
                        onChange={this.props.agreementChecked}
                      />
                      <label className="font-style-2 ml-2 mr-2" for="checkbox">
                        <span>
                          I have read and I agree to Polytrade Terms and
                          Condition.{" "}
                          <span
                            onClick={() => {
                              this.setState({
                                showPdf: true,
                              });
                            }}
                            style={{ cursor: "pointer" }}
                            className="font-style-17 mx-2 mt-2"
                          >
                            {" "}
                            Read T & C
                          </span>
                        </span>
                      </label>
                    </div>
                    <button
                      style={{ backgroundColor: "#2DA3B4" }}
                      className="w-100 mr-3 mt-2  btn-lg  btn btn-primary "
                      disabled={
                        this.props.loading || this.props.validateAmount
                          ? true
                          : this.state.userTokenBalance
                          ? false
                          : true
                      }
                      onClick={this.props.postLendNow}
                    >
                      {this.props.loading === false ? (
                        <span> Lend Now</span>
                      ) : (
                        <div className="spinner-border btn--icon"></div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* modal box for term and condition */}
          <Modal show={this.state.showPdf} onHide={this.handleClose}>
            <div style={{ backgroundColor: "#333333", color: "white" }}
              className='embed-responsive embed-responsive-1by1'
            >
              {this.state.pdfUrl !== null ? (
                this.state.pdfUrl.split(".").pop() === "pdf" ||
                this.state.pdfUrl.split(".").pop() === "PDF" ? (
                  <iframe
                    src={`https://docs.google.com/gview?url=${this.state.pdfUrl}&embedded=true`}
                    className='embed-responsive-item'
                    title="T&C"
                    frameBorder="0"
                  ></iframe>
                ) : (
                  <>
                    <img
                      src={this.state.pdfUrl}
                      alt="pdf"
                      style={{ width: "30rem", height: "30rem" }}
                    />
                  </>
                )
              ) : (
                <div></div>
              )}
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

import * as ALERT_CONSTANTS from "../constants/alertConstant";
import ajax from "../../utils/ajax";

export const requestGetAlert = () => ({
  type: ALERT_CONSTANTS.HOME_GET_ALERT_CONSTANTS.REQUEST,
});

export const recievedGetAlertResponse = (data) => ({
  type: ALERT_CONSTANTS.HOME_GET_ALERT_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetAlertError = (err) => ({
  type: ALERT_CONSTANTS.HOME_GET_ALERT_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getAlertsById = (id) => async (dispatch) => {
  dispatch(requestGetAlert());
  try {
    let url = ALERT_CONSTANTS.HOME_GET_ALERT_CONSTANTS.URL + id;
    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetAlertResponse(res.data));

      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetAlertError(err));
    return Promise.reject(err);
  }
};

export const requestPostAlert = () => ({
  type: ALERT_CONSTANTS.HOME_POST_ALERT_CONSTANTS.REQUEST,
});

export const recievedPostAlertResponse = (data) => ({
  type: ALERT_CONSTANTS.HOME_POST_ALERT_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedPostAlertError = (err) => ({
  type: ALERT_CONSTANTS.HOME_POST_ALERT_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const postAlert = (data) => async (dispatch) => {
  dispatch(requestPostAlert());
  try {
    let url = ALERT_CONSTANTS.HOME_POST_ALERT_CONSTANTS.URL;
    const res = await ajax("POST", url, {}, {}, data);
    if (res) {
      dispatch(recievedPostAlertResponse(res.data));

      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedPostAlertError(err));
    return Promise.reject(err);
  }
};

export const requestUpdatetAlert = () => ({
  type: ALERT_CONSTANTS.HOME_UPDATE_ALERT_CONSTANTS.REQUEST,
});

export const recievedUpdateAlertResponse = (data) => ({
  type: ALERT_CONSTANTS.HOME_UPDATE_ALERT_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedUpdateAlertError = (err) => ({
  type: ALERT_CONSTANTS.HOME_UPDATE_ALERT_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const updateAlert = (data) => async (dispatch) => {
  dispatch(requestUpdatetAlert());
  try {
    let url = ALERT_CONSTANTS.HOME_UPDATE_ALERT_CONSTANTS.URL + data._id;
    const res = await ajax("PUT", url, {}, {}, data);
    if (res) {
      dispatch(recievedUpdateAlertResponse(res.data));

      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedUpdateAlertError(err));
    return Promise.reject(err);
  }
};

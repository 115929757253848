import React from "react";
import DetailsExpander from "./detailsExpander";
import PricingOfferDetailsCard from "./detailsCard/pricingDetailsCard";
import { DETAILS } from "../../../config/string_constants";
/**
 *
 * @param {*} props
 * @returns PricingDetails Component
 */

export default function PricingDetails(props) {
  return (
    <DetailsExpander label={DETAILS.PRICING_DETAILS}>
      <PricingOfferDetailsCard {...props} />
    </DetailsExpander>
  );
}

import React from 'react'
import moment from "moment";

export default function PoolDetails(props) {
    return (
        <div className='m-3'>
            <div className='text-white m-2 curserP' onClick={()=>props.goback()}>
                <i className='uil-arrow-left ' />
                Back
            </div>
            <div className="table-full-width table-responsive mt-5 px-0">
                <table className="border-0">
                    <thead className="disp_non_sm">
                        <tr className="text_tra1 ">
                            {/* <th className="pl-2">User Name</th> */}
                            <th className="pl-2">Lock Amount</th>
                            <th className="pl-2">Tenure</th>
                            <th className="pl-2">Bonus APY</th>
                            <th className="pl-2">Standard APY</th>
                            <th className="pl-2">Redemption Date</th>
                            <th className="pl-2">Subscription Date</th>
                            <th className="pl-2">Accure Days</th>
                            <th className="pl-2">Transaction Hash</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.poolUsers.map((index, key) => (
                            <tr key={key} className="sec1">
                                {/* <td>{index.user_id.full_name}</td> */}
                                <td>{index.lock_amount}</td>
                                <td>{index.duration}</td>
                                <td>{index.bonus_apy}</td>
                                <td>{index.est_apy}</td>
                                <td>{moment(index.redemption_date).format("DD MMM YYYY")}</td>
                                <td>{moment(index.subscription_date).format("DD MMM YYYY")}</td>
                                <td>{index.accrue_days}</td>
                                <td><a target="_blank" href={"https://polygonscan.com/tx/" + index.hash} rel="noreferrer">
                                    <span className="txt_g">{index.hash}</span></a></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

import React, { Component } from "react";
import { URI } from "../../../constant";
import { withRouter } from "react-router-dom";

class SuccessPinCreation extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div style={{ fontFamily: "Averta-Semibold,sans-serif" }}>
          <div>
            <div className="alert-popup">
              <div className="mt-3 disp-lg-none">
                <div className="line mx-auto"></div>
              </div>
              <div className="text-center mt-5">
                <i
                  className="uil-check-circle txt_b"
                  style={{ fontSize: "222px" }}
                />
                <h3 className="text-white">Pin created successfully.</h3>

                <h5 className="text-white">Go back to your original tab.</h5>
                <h5 className="text-white">You can close this window.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(SuccessPinCreation);

import react from "react";
import { Logo, Brand } from "../../constant";
import styles from "../../assets/css/OnboardingPersonalDetails.module.css";
import "../../Global.css";
import "../../assets2/css/profile.css";
import { UserContext } from "../../context/UserContext";
import "react-toastify/dist/ReactToastify.css";
import CountryList from './Common/countryList';
import { Country, City, State } from "country-state-city";
import CityList from './Common/cityList';
import StateList from "./Common/stateList";


class UserCompAddress extends react.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      showPageLoader: false,
      file: null,
      progressState: 1,
      showSuceess: false,
      fillForms: { form1: false, form2: false },
      company: {
        address_line1: "",
        address_line2: "",
        city: "",
        zipcode: "",
        state: "",
      },
      country: [],
      state: [],

      dirty: {
        address_line1: false,
        address_line2: false,
        city: false,
        zipcode: false,
        country_id: false,
        state: false,
      },

      errors: {
        address_line1: [],
        address_line2: [],
        city: [],
        zipcode: [],
        country_id: [],
        state: [],
      },

      city: [],
    };
  }
  handleChange = (e) => {
    const { name, value } = e.target;

    let dirty = this.state.dirty;
    dirty[name] = true;

    let company = this.state.company;

    company[name] = value;

    this.setState(
      {
        company: company,
        dirty: dirty,
      },
      this.validate
    );
  };

  blurChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };
  componentDidMount() {
    this.setState({ company: this.props.company });
  }
  validate = () => {
    var validState = /^[A-Za-z'-\s]+$/;
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      errors[control] = [];
      switch (control) {
        case "address_line1":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "address_line2":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "city":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "country_name":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "zipcode":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "state":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          if (this.state.company[control]) {
            if (!validState.test(this.state.company[control])) {
              errors[control].push("Please enter valid State");
            }
          }
          break;

        default:
          break;
      }
    });
    this.setState({ errors });
  };

  isValid = () => {
    let valid = true;

    for (let control in this.state.errors) {
      if (this.state.errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };
  handleCountryChange = (e) => {
    const { name, value } = e.target;

    let dirty = this.state.dirty;
    dirty[name] = true;

    let company = this.state.company;

    company[name] = value;
    const country = Country.getAllCountries().filter((item) => item.name === this.state.company.country_name)
    const state = State.getStatesOfCountry(country[0].isoCode).filter((item) => item.name === this.state.company.state);

    this.setState(
      {
        company: company,
        city: City.getCitiesOfState(country[0]?.isoCode, state[0]?.isoCode),
        state: State.getStatesOfCountry(country[0]?.isoCode),
        dirty: dirty,
      },
      this.validate
    );
  };
  submitData = () => {
    this.validate();
    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });

    if (this.isValid()) {
      this.props.saveCompany();
      //this.props.goToAddressDetails(this.state.company);
    }
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-2 text-center pt-3"></div>
          <div className="col-md-8 text-center pt-3">
            <h3 className="text_color">Company Address That's it!</h3>
            <p className="text_color2 ">
              {Brand} needs your Company Information to offer you trade finance
            </p>
            {/* input box */}
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="  mb-3 text-left mt-4">
                  <label className="form-label text_tra">Address Line 1</label>
                  <textarea
                    rows="4"
                    cols="20"
                    className="form-control input_bg"
                    placeholder="Address"
                    name="address_line1"
                    value={this.state.company.address_line1}
                    onChange={this.handleChange}
                    onBlur={this.blurChange}
                    maxLength={100}
                  />
                  <div className="text-danger">
                    {this.state.errors.address_line1[0] &&
                      this.state.dirty.address_line1
                      ? this.state.errors.address_line1
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
            {/*  */}
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="  mb-3 text-left">
                  <label className="form-label text_tra">Address Line 2</label>
                  <textarea
                    rows="4"
                    cols="20"
                    className="form-control input_bg"
                    placeholder="Address"
                    name="address_line2"
                    value={this.state.company.address_line2}
                    onChange={this.handleChange}
                    onBlur={this.blurChange}
                    maxLength={100}
                  />
                  <div className="text-danger">
                    {this.state.errors.address_line2[0] &&
                      this.state.dirty.address_line2
                      ? this.state.errors.address_line2
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
            {/*  */}

            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="  mb-3 text-left">
                  <label className="form-label text_tra">Country</label>
                  <CountryList handleCountryChange={this.handleCountryChange} />

                  {" "}
                  <div className="text-danger">
                    {this.state.errors.country_id[0] &&
                      this.state.dirty.country_id
                      ? this.state.errors.country_id
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
            {/* */}
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="  mb-3 text-left">

                  <label className="form-label text_tra">State</label>
                  <StateList handleChange={this.handleCountryChange} state={this.state.state} value={this.state.company.state} />
                  <div className="text-danger">
                    {this.state.errors.state[0] && this.state.dirty.state
                      ? this.state.errors.state
                      : ""}
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
            {/*  */}
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6">
                    <div className="  mb-3 text-left">
                      <label className="form-label text_tra">City</label>
                      <CityList handleChange={this.handleCountryChange} city={this.state.city} />
                      <div className="text-danger">
                        {this.state.errors.city[0] && this.state.dirty.city
                          ? this.state.errors.city
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="  mb-3 text-left">
                      <label className="form-label text_tra">Zip Code</label>
                      <input
                        className="form-control input_bg"
                        placeholder="Zipcode"
                        name="zipcode"
                        onChange={this.handleChange}
                        onBlur={this.blurChange}
                        maxLength={10}
                      />
                      <div className="text-danger">
                        {this.state.errors.zipcode[0] &&
                          this.state.dirty.zipcode
                          ? this.state.errors.zipcode
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-2"></div>
            </div>
            {/*  */}
            <div className="row row justify-content-center pt-3">
              <div className="col-md-8 mb-3">
                <button
                  style={{
                    backgroundColor: "rgb(77 181 196)",
                    border: "none",
                    color: "#ffffff",
                  }}
                  className="btn w-100"
                  onClick={this.submitData}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UserCompAddress;

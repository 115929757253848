import React from "react";
import ajax from "../../../utils/ajax";
import { URI } from "../../../constant";
import { ToastContainer, toast } from "react-toastify";
import {
  USER_TYPE,
  STATUSES,
  ADMIN_ALERT_TYPES,
} from "../../../config/string_constants";
import helper from "../../../utils/helper";
import {
  getCompanyUsersAPI,
  createCompanyUser,
} from "../../../api/userService";
export default class Directors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      director: {
        full_name: "",
        email: "",
        phone_number: "",
        user_dialCode: "",
        company_id: "",
        poi_url: "",
        poa_url: "",
        pan_url: "",
        user_type: USER_TYPE.DIRECTOR,
        createdById: sessionStorage.getItem("userId"),
      },
      showDirectorForm:false,
      directorLoader: [],
      director_error: [],
      dirty: {
        full_name: false,
        email: false,
        phone_number: false,
        user_dialCode: false,
        poi_url: false,
        poa_url: false,
      },
      errors: {
        full_name: [],
        email: [],
        phone_number: [],
        user_dialCode: [],
        poi_url: [],
        poa_url: [],
      },
      Directors: [],
    };
  }
  handleDirectorChange = (e) => {
    const { name, value } = e.target;

    let dirty = this.state.dirty;
    dirty[name] = true;
    let director = this.state.director;

    director[name] = value;

    this.setState(
      {
        director: director,
        dirty: dirty,
      },
      this.validate
    );
  };

  validate = () => {
    var errors = this.state.errors;
    var validEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    Object.keys(errors).forEach((control) => {
      errors[control] = [];
      switch (control) {
        case "full_name":
          if (!this.state.director[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "email":
          if (!this.state.director[control]) {
            errors[control].push("This field is required");
          }
          if (this.state.director[control]) {
            if (!validEmail.test(this.state.director[control])) {
              errors[control].push("Please Enter valid Email Id");
            }
          }
          break;
        case "phone_number":
          if (!this.state.director[control]) {
            errors[control].push("This field is required");
          }
          let clength = this.state.director[control].length;
          if (this.state.director[control] && (clength < 6 || clength > 12)) {
            errors[control].push("Number min 6 max 12");
          }
          break;
          break;
        case "user_dialCode":
          if (!this.state.director[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "poi_url":
          if (!this.state.director[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "poa_url":
          if (!this.state.director[control]) {
            errors[control].push("This field is required");
          }
          break;

        default:
          break;
      }
    });
    this.setState({ errors });
  };
  uploadDirectorFile(file, name) {
    let loader = {};
    let error = {};
    loader[name] = true;
    error[name] = "";
    this.setState({ directorLoader: loader });
    const formData = new FormData();
    formData.append("image", file);
    if (file !== undefined) {
      ajax(
        "POST",
        `${URI}/api/v1/image-upload`,
        {},
        { "content-type": "multipart/form-data" },
        formData
      )
        .then((data) => {
          let director = this.state.director;
          director[name] = data.data.imageUrl;
          loader[name] = false;
          this.setState({
            director: director,
            directorLoader: loader,
            director_error: error,
          },this.validate);
        })
        .catch((err) => {});
    }
  }
  isValid = () => {
    let valid = true;

    for (let control in this.state.errors) {
      if (this.state.errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };
  addDirector = async () => {
    var validName = /^[a-zA-Z ]*$/;
    this.validate();
    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });

    if (this.isValid()) {
      let userObject = {
        user_type: USER_TYPE.DIRECTOR,
        email: this.state.director.email,
        phone_number: this.state.director.phone_number,
        user_dialCode: this.state.director.user_dialCode,
        full_name: this.state.director.full_name,
        poa_url: this.state.director.poa_url,
        poi_url: this.state.director.poi_url,
        company_id: this.props.company_id,
        createdById: sessionStorage.getItem("userId"),
        update_type: ADMIN_ALERT_TYPES.KYC,
      };

      let res = await createCompanyUser(userObject);
      if (res.status === 200 || res.status === 201) {
        this.props.getCompanyUsers(this.props.company_id);
        this.setState({
          director: {
            ...this.state.director,
            full_name: "",
            poa_url: "",
            poi_url: "",
            pan_url: "",
            phone_number: "",
            email: "",
            user_dialCode: "",
          },
          showDirectorForm:false
        });
        toast.info("Director Added Successfully");
      } else {
        toast.error("Director Not Added");
      }
    }
  };

  directorFiles = (e) => {
    if (helper.validateFileType(e.target.files)) {
      this.uploadDirectorFile(e.target.files[0], e.target.name);
    } else {
      let loader = {};
      let error = {};
      loader[e.target.name] = false;
      error[e.target.name] =
        "Please Upload file in JPG, PNG, PDF and max size 5mb.";
      this.setState({
        directorLoader: loader,
        director_error: error,
      });
    }
  };
  showMoreDirectors=()=>{
    this.setState({
      showDirectorForm:!this.state.showDirectorForm
    })
  }
  render() {
    return (
      <>
        <div className=" m-3 text-white">
          Directors<span className="ml-1"></span>
        </div>
        {/* table */}

        <div className=" m-3 mo_cont">
          <table className="table table-dark table-hover bor_line">
            <thead>
              <tr className="crd_brd">
                <td>Full Name</td>
                <td>Passport/National ID</td>
                <td><span className="mt-1 link "
                          data-tooltip="Utility bills dated for less than 3 months">
                          POA (Proof of Address){" "}
                          <i className="uil-info-circle text_tra1" />
                        </span></td>
                <td>Contact Number</td>
                <td>Email Address</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {this.props.directorsData.map((director) => (
                <tr className="bg_Table" key={director._id}>
                  <td>
                    <form className="form-inline my-2 my-md-0">
                      {director.full_name}
                    </form>
                  </td>
                  <td>
                    {director.poi_url ? (
                      <a href={director.poi_url}>Download</a>
                    ) : (
                      <h6>--</h6>
                    )}
                  </td>
                  <td>
                    {director.poa_url ? (
                      <a href={director.poa_url}>Download</a>
                    ) : (
                      <h6>--</h6>
                    )}
                  </td>

                  <td>
                    {director.user_dialCode}
                    {director.phone_number}
                  </td>
                  <td> {director.email}</td>
                  <td></td>
                </tr>
              ))}
             {this.state.showDirectorForm?<tr className="bg_Table">
                <td>
                  <input
                    className="form-control input_bg w-50"
                    type="text"
                    name="full_name"
                    value={this.state.director.full_name}
                    placeholder="Full Name"
                    onChange={this.handleDirectorChange}
                    maxLength="20"
                  />
                  <div className="text-danger">
                    {this.state.errors.full_name[0] &&
                    this.state.dirty.full_name
                      ? this.state.errors.full_name
                      : ""}
                  </div>
                </td>
                <td>
                  <label className="td-w mr-1">
                    {this.state.directorLoader.poi_url ? (
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                        }}
                        className="spinner-border btn--icon"
                      ></div>
                    ) : (
                      "Upload"
                    )}
                    <input
                      type="file"
                      name="poi_url"
                      onChange={this.directorFiles}
                    ></input>
                  </label>
                  {this.state.director.poi_url ? (
                    <a href={this.state.director.poi_url}>Download</a>
                  ) : (
                    <h6></h6>
                  )}
                  <div className="text-danger">
                    {this.state.errors.poi_url[0] && this.state.dirty.poi_url
                      ? this.state.errors.poi_url
                      : ""}
                  </div>
                </td>
                <td>
                  <label className="td-w mr-1">
                    {this.state.directorLoader.poa_url ? (
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                        }}
                        className="spinner-border btn--icon"
                      ></div>
                    ) : (
                      "Upload"
                    )}
                    <input
                      type="file"
                      name="poa_url"
                      onChange={this.directorFiles}
                    ></input>
                  </label>
                  {this.state.director.poa_url ? (
                    <a href={this.state.director.poa_url}>Download</a>
                  ) : (
                    <h6></h6>
                  )}
                  <div className="text-danger">
                    {this.state.errors.poa_url[0] && this.state.dirty.poa_url
                      ? this.state.errors.poa_url
                      : ""}
                  </div>
                </td>
                <td>
                  <div className="d-flex">
                    <div className="w-25 mr-2">
                      <select
                        className="form-control  input_bg w-100"
                        type="text"
                        name="user_dialCode"
                        value={this.state.director.user_dialCode}
                        onChange={this.handleDirectorChange}
                      >
                        <option value="">Select dial code</option>
                        {this.props.countryList.map((item) => (
                          <option
                            style={{
                              background: "#212022",
                              color: "grey",
                            }}
                            key={item._id}
                            value={item.dialCode}
                          >
                            {item.dialCode}
                          </option>
                        ))}
                      </select>
                      <div className="text-danger">
                        {this.state.errors.user_dialCode[0] &&
                        this.state.dirty.user_dialCode
                          ? this.state.errors.user_dialCode
                          : ""}
                      </div>
                    </div>
                    <span>
                      <input
                        className="form-control input_bg w-100"
                        type="number"
                        name="phone_number"
                        value={this.state.director.phone_number}
                        placeholder="Contact Number"
                        onChange={this.handleDirectorChange}
                        maxLength="20"
                      />
                      <div className="text-danger">
                        {this.state.errors.phone_number[0] &&
                        this.state.dirty.phone_number
                          ? this.state.errors.phone_number
                          : ""}
                      </div>
                    </span>
                  </div>
                </td>
                <td>
                  <input
                    className="form-control input_bg w-100"
                    type="email"
                    name="email"
                    value={this.state.director.email}
                    placeholder="email"
                    onChange={this.handleDirectorChange}
                    maxLength="25"
                  />
                  <div className="text-danger">
                    {this.state.errors.email[0] && this.state.dirty.email
                      ? this.state.errors.email
                      : ""}
                  </div>
                </td>

                <td>
                  <label className="td-w mr-1">
                    <button className="btn-circle" onClick={this.addDirector}>
                      +
                    </button>
                  </label>
                </td>
              </tr>:""} 
            </tbody>
          </table>
          <div className="my-3">
            <span className="txt_b ml-4 underline curserP" onClick={this.showMoreDirectors}>
              + Add More Directors
            </span>
          </div>
        </div>
      </>
    );
  }
}

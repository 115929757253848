import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getInvoiceChanges } from "../../../services/actions/invoiceAction";

class InvoicesListId extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="invoicelist my-2">
          {this.props.invoices !== undefined
            ? this.props.invoices.map((item, i) => (
                <div className="nav info-nav   flex-column " role="tablist">
                  <div
                    key={i}
                    className="d-flex p-2 my-1 bgc-dark"
                    id="circle1"
                    onClick={() => {
                      this.props.showData(item);
                      this.props.history.push(
                        `/${item.invoiceStatus_id.key}/${item._id}`
                      );

                      this.props.getInvoiceChanges(item);
                    }}
                  >
                    <div
                      className={
                        this.props.selected && this.props.itemId === item._id
                          ? "circle mt-2 ml-2 active"
                          : "circle mt-2 ml-2"
                      }
                      id="circle1"
                    ></div>
                    <a
                      className="nav-link text-white "
                      data-toggle="tab"
                      href="#tabs-1"
                      id="circle1"
                      role="tab"
                    >
                      {item.invoice_id}
                    </a>
                  </div>
                </div>
              ))
            : ""}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceChanges: (data) => dispatch(getInvoiceChanges(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvoicesListId));

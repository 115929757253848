import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import RejectPopup from "./rejectPopup";
import { Link } from "react-router-dom";
import Alert from "../../../assets/images/alert.svg";
import Grayalert from "../../../assets/images/grayalert.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateOffer } from "../../../services/actions/invoiceOfferAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TcPopup from "./tcPopup";
import helper from "../../../utils/helper";
import { URLS } from "../../../config/frontendUrls";

class OfferPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      showKyc: false,
      showReject: false,
      reason: "",
      checked: false,
      showPdf: false,
    };
  }
  onChange = (e) => {
    this.setState({
      reason: e.target.value,
    });
  };
  handleReject = () => {
    this.props.history.push(URLS.CLIENT.INVOICES);
  };

  completeKYC = () => {
    this.props.history.push(`${URLS.CLIENT.PROFILE}/section3`);
  };
  handleCheckBox() {
    this.setState({
      checked: !this.state.checked,
    });
  }

  handleClose = () => {
    this.setState({ showPdf: false });
  };
  sendRequest = () => {
    const data = this.props.invoiceOffer;
    data.regenerate_request = true;

    this.props
      .updateOffer(data)
      .then((response) => {
        this.props.history.push(URLS.CLIENT.INVOICES);
      })
      .catch((err) => {});
  };
  render() {
    return (
      <div>
        <div style={{ fontFamily: "Averta-Semibold,sans-serif" }}>
          <RejectPopup
            show={this.state.modalShow}
            onHide={() => this.setState({ modalShow: false })}
          />
          <Modal
            {...this.props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ backgroundColor: "black" }}
          >
            <div
              style={{
                backgroundColor: "#333333",
                color: "white",
                border: "0",
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title
                  id="contained-modal-title-vcenter"
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  Offer Made
                </Modal.Title>
              </Modal.Header>
              {this.props.invoiceOffer.expired === "Expired" ? (
                <Modal.Body style={{ textAlign: "center" }}>
                  <div
                    style={{
                      margin: "0 auto",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    <p style={{ fontSize: "14px" }}>
                      {" "}
                      Offer expired please ask admin to Re-generate offer{" "}
                    </p>
                  </div>
                  <div style={{ margin: "20px", alignItems: "center" }}>
                    <Button
                      onClick={this.sendRequest}
                      style={{ backgroundColor: "#2DA3B4", margin: "13px" }}
                    >
                      {this.props.invoiceOffer.regenerate_request
                        ? "Request Sent"
                        : "Send Request"}
                    </Button>
                  </div>
                </Modal.Body>
              ) : (
                <Modal.Body style={{ textAlign: "center" }}>
                  <div
                    style={{
                      margin: "0 auto",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    <p style={{ fontSize: "14px" }}>
                      {" "}
                      We are happy to offer you finance for your invoice No.{" "}
                      {this.props.invoiceOffer.invoice_ref_no
                        ? this.props.invoiceOffer.invoice_ref_no
                        : "0"}
                    </p>
                    <div
                      style={{
                        width: "100%",
                        padding: "12px",
                        margin: "auto",
                        alignItems: "center",
                        backgroundColor: "#262626",
                        marginTop: "14px",
                      }}
                      className="row"
                    >
                      <div
                        style={{ color: "#B1B1B1", textAlign: "left" }}
                        className="col-6 offer_made_font"
                      >
                        <p className="pb-2">Invoice Amount </p>
                        <p className="pb-2">Approved Amount </p>
                        <p className="pb-2">
                          Factoring Fee{" "}
                          <img src={Grayalert} className="mx-1" alt="img" />
                          {this.props.invoiceOffer.factoring_fee
                            ? this.props.invoiceOffer.factoring_fee.percentage
                            : ""}
                          %
                        </p>
                        <p className="pb-2">
                          Advance Amount{" "}
                          <img src={Grayalert} className="mx-1" alt="img" />
                          {this.props.invoiceOffer.advance_ratio
                            ? this.props.invoiceOffer.advance_ratio.percentage
                            : ""}
                          %
                        </p>
                        <p className="pb-2">
                          Discounting Fee{" "}
                          <img src={Grayalert} className="mx-1" alt="img" />{" "}
                          {this.props.invoiceOffer.discount
                            ? this.props.invoiceOffer.discount.percentage
                            : ""}
                          %
                        </p>
                        <p className="pb-2">Tenure</p>
                      </div>
                      <div className="col-6 " style={{ textAlign: "right" }}>
                        <p className="pb-2">
                          $
                          {Number(
                            this.props.invoiceOffer.invoice_amount
                              ? this.props.invoiceOffer.invoice_amount
                              : ""
                          )
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </p>
                        <p className="pb-2">
                          $
                          {Number(
                            this.props.invoiceOffer.invoice_limit
                              ? this.props.invoiceOffer.invoice_limit
                              : ""
                          )
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </p>
                        <p className="pb-2">
                          $
                          {Number(
                            this.props.invoiceOffer.factoring_fee
                              ? this.props.invoiceOffer.factoring_fee.amount
                              : ""
                          )
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </p>
                        <p className="pb-2">
                          $
                          {Number(
                            this.props.invoiceOffer.advance_ratio
                              ? this.props.invoiceOffer.advance_ratio.amount
                              : ""
                          )
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </p>
                        <p className="pb-2">
                          $
                          {helper.discountFeeHandler(
                            this.props?.invoiceOffer?.discount?.percentage,
                            this.props?.invoiceOffer?.advance_ratio?.amount,
                            this.props?.invoiceOffer?.tenure
                          )}
                        </p>
                        <p className="pb-2">
                          {this.props.invoiceOffer.tenure
                            ? this.props.invoiceOffer.tenure
                            : "0"}{" "}
                          Days
                        </p>
                      </div>
                    </div>
                  </div>
                  {this.props.softOffer ? (
                    <div>
                      <div>
                        <div className="text-white mt-2">
                          <input
                            className="form-check-input mr-5"
                            type="checkbox"
                            onClick={() => this.handleCheckBox()}
                            style={{ height: "15px", width: "60px" }}
                          />
                          <span className="ml-4">I agree to the</span>{" "}
                          <span
                            className="txt_b curserP"
                            onClick={() => this.setState({ showPdf: true })}
                          >
                            {" "}
                            Terms & Conditions
                          </span>
                        </div>
                      </div>

                      <div style={{ margin: "20px", alignItems: "center" }}>
                        <Button
                          onClick={() => this.setState({ showReject: true })}
                          style={{ backgroundColor: "#E9556E", margin: "13px" }}
                        >
                          Reject Offer
                        </Button>
                        <Button
                          disabled={!this.state.checked}
                          onClick={this.props.postAcceptSoftOffer(
                            this.props.invoiceOffer.invoice_id
                              ? this.props.invoiceOffer.invoice_id
                              : ""
                          )}
                          style={{ backgroundColor: "#2DA3B4", margin: "13px" }}
                        >
                          Accept Offer
                        </Button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </Modal.Body>
              )}
            </div>
          </Modal>
        </div>

        {/* KYC POPUP Accept Offer */}
        <div style={{ fontFamily: "Averta-Semibold,sans-serif" }}>
          <Modal
            show={this.props.kycStatus}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ backgroundColor: "black" }}
          >
            <div
              style={{
                backgroundColor: "#333333",
                color: "white",
                border: "0",
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title
                  id="contained-modal-title-vcenter"
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  Please Complete Documentation
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: "center" }}>
                <div
                  style={{
                    margin: "0 auto",
                    width: "100%",
                    height: "100%",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  <div style={{ paddingTop: "35px" }}>
                    <img src={Alert} className="user-icon" alt="" />
                  </div>
                  Complete Profile Documentation to proceed further. Please do
                  at the earliest.
                </div>
                <div style={{ margin: "20px", alignItems: "center" }}>
                  <Button
                    onClick={this.completeKYC}
                    style={{ backgroundColor: "#2DA3B4" }}
                  >
                    Complete Now
                  </Button>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </div>

        {/* Reject Offer */}
        <div style={{ fontFamily: "Averta-Semibold,sans-serif" }}>
          <Modal
            show={this.state.showReject}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ backgroundColor: "black" }}
          >
            <div
              style={{
                backgroundColor: "#333333",
                color: "white",
                border: "0",
              }}
            >
              <Modal.Header>
                <Modal.Title
                  id="contained-modal-title-vcenter"
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  Reject Offer
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: "center" }}>
                <div
                  style={{
                    margin: "0 auto",
                    width: "100%",
                    height: "100%",
                    fontSize: "13px",
                    lineHeight: "24px",
                  }}
                >
                  <p style={{ textAlign: "left" }}>
                    {" "}
                    Oh no.! can you take a moment to tell us why you want to
                    reject the offer?
                  </p>
                  <p style={{ textAlign: "left" }}>
                    {" "}
                    We would like to get in touch with you to solve your
                    queries.
                  </p>
                  <div style={{}}>
                    <p style={{ textAlign: "left", paddingTop: "12px" }}>
                      Reason Rejection
                    </p>
                    <textarea
                      className="mt-2 text-white"
                      style={{
                        backgroundColor: "#333333",
                        width: "100%",
                        height: "90px",
                      }}
                      value={this.state.reason}
                      onChange={this.onChange}
                      placeholder="write here"
                    ></textarea>
                  </div>
                </div>

                <div style={{ margin: "20px", alignItems: "right" }}>
                  <Button
                    onClick={() =>
                      this.props.postRejectSoftOffer(this.state.reason)
                    }
                    style={{ backgroundColor: "#2DA3B4" }}
                  >
                    Submit
                  </Button>

                  {/* <Button  onClick={()=>{this.props.history.push('/Borrower/invoices')}} style={{backgroundColor:"#2DA3B4"}} >Submit</Button> */}
                  {/* this.props.postRejectSoftOffer(this.state.reason) */}
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </div>

        <TcPopup showPdf={this.state.showPdf} handleClose={this.handleClose} />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  invoice: state.invoice,
  invoiceStatus: state.invoiceStatus,
});

const mapDispatchToProps = (dispatch) => ({
  updateOffer: (data) => dispatch(updateOffer(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OfferPopup));

import React, { useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { UserContext } from "../context/UserContext";
import styles from "../assets/css/LandingPage.module.css";
import LandingImage from "../assets/images/LandingPage/landing.svg";
import {Logo, Brand} from "../constant";
import Logo1 from "../assets/images/Logo.svg";
import { withRouter } from "react-router-dom";
import { URLS } from '../config/frontendUrls';

class Landing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: this.props.page,
    };
  }

  render() {
    return (
      <div className={styles.LandingPage}>
        <div className={styles.Navbar}>
        {this.state.page === "borrower" ? (
          <div className={styles.Logo}>
            <img src={Logo} width='120px' alt='' />
          </div>
        ) :(
          <div className={styles.Logo}>
          <img src={Logo1}  alt='' />
        </div>
        )}
          {this.state.page === "borrower" ? (
            <div className={styles.Nav}>
              <Link
                to='/whitepaper'
                style={{ textDecoration: "none", color: "white" }}
              >
                WhitePaper<span></span>
              </Link>
            </div>
          ) : ""}
        </div>
        <main className={styles.Main}>
          <div className={styles.Intro}>
            <p>connecting crypto to real world</p>
          </div>
          <h1 className={styles.Heading}>
            Shaping the future of Trade Finance
          </h1>

          <p className={styles.Description}>
            {Brand} harnesses the massive liquidity pool of the crypto world
            by tokenizing real-world invoices and bringing them on-chain into
            the DeFi space.
          </p>
          {this.state.page === "borrower" ? (
            <button className={styles.GetStarted}>
              <Link
                to={URLS.CLIENT.LOGIN}
                style={{ textDecoration: "none", color: "white" }}
              >
                Get Started
              </Link>
            </button>
          ) :  <span>
          <button className={styles.GetStarted}>
      <Link
        to='/lender'
        style={{ textDecoration: "none", color: "white" }}
      >
        Lend Now
      </Link>
    </button>
    </span>}
        </main>

        <img className={styles.LandingImage} src={LandingImage} alt='' />
        <footer className={styles.Footer}>
          <a href='https://www.telegram.com'>TELEGRAM</a>
          <a href='https://www.telegram.com'>TWITTER</a>
        </footer>
      </div>
    );
  }
}
export default withRouter(Landing);

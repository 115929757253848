import React from "react";
import Modal from "react-bootstrap/Modal";
import OfflineSignPopup from "./offlineSignPopup";
import Button from "react-bootstrap/Button";
export default function SignPopup(props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div>
      <div style={{ fontFamily: "Averta-Semibold,sans-serif" }}>
        <OfflineSignPopup show={modalShow} onHide={() => setModalShow(false)} />
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ backgroundColor: "black" }}
        >
          <div
            style={{ backgroundColor: "#333333", color: "white", border: "0" }}
          >
            <Modal.Header>
              <Modal.Title
                id="contained-modal-title-vcenter"
                style={{ fontSize: "16px", lineHeight: "24px" }}
              >
                Sign Contract
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: "left" }}>
              <div
                style={{
                  margin: "0 auto",
                  width: "100%",
                  height: "100%",
                  fontSize: "13px",
                  lineHeight: "24px",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    padding: "12px",
                    color: "#8E8E8E",
                  }}
                >
                  Choose the prefered method to sign the contract.
                </p>
                <div style={{ float: "left" }}>
                  <div>
                    {" "}
                    <input
                      style={{ width: "auto" }}
                      type="radio"
                      id=""
                      name="fav_language"
                      value=""
                    />
                     {" "}
                    <label
                      for="html"
                      style={{ fontSize: "14px", lineHeight: "24px" }}
                    >
                      Offline Contract Sign
                    </label>
                    <p
                      style={{
                        paddingLeft: "20px",
                        color: "#8E8E8E",
                        lineHeight: "20px",
                      }}
                    >
                      In Offline, you will have to download the contract
                      document and upload the signed document.
                    </p>
                  </div>
                  <div>
                    {" "}
                    <input
                      style={{ width: "auto", marginTop: "12px" }}
                      type="radio"
                      id=""
                      name="fav_language"
                      value=""
                    />
                     {" "}
                    <label
                      for="css"
                      style={{ fontSize: "14px", lineHeight: "24px" }}
                    >
                      Online Contract Sign
                    </label>
                    <p
                      style={{
                        paddingLeft: "20px",
                        color: "#8E8E8E",
                        lineHeight: "20px",
                      }}
                    >
                      In Online, You will be redirected to DocuSign to sing the
                      document online.
                    </p>
                  </div>
                   
                </div>
              </div>

              <div style={{ margin: "20px", textAlign: "center" }}>
                <Button
                  onClick={() => setModalShow(true)}
                  style={{ backgroundColor: "#2DA3B4", alignSelf: "center" }}
                >
                  Proceed
                </Button>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </div>
  );
}

import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom'
import Download from '../../../assets/images/download.png';
import { URLS } from "../../../config/frontendUrls";

export default function offlineSignPopup(props) {
    return (
        <div>
                <div style={{fontFamily:"Averta-Semibold,sans-serif"}}>
        
        <Modal
 {...props}
 aria-labelledby="contained-modal-title-vcenter"
 centered
style={{backgroundColor:'black'}}  >
 <div style={{backgroundColor:'#333333',color:'white',border:'0'}}> 
     <Modal.Header>

     <Modal.Title id="contained-modal-title-vcenter" style={{fontSize:'16px',lineHeight:'24px'}} >
     Offline Contract Sign 
     </Modal.Title>
     </Modal.Header>
     <Modal.Body style={{}} >
     <div style={{margin:'0 auto',width:'100%',height:'100%',fontSize:'13px',lineHeight:'24px'}}>
       
           <p style={{textAlign:'left',padding:"12px",fontSize:"14px"}}>Download the contract document from here. <span style={{float:"right"}}><img src={Download}/> Download</span></p> 
             <div style={{margin:"24px",padding:"12px",border:"1px solid #595959",textAlign:"center",borderRadius:"6px"}}>
                 <p style={{color:"#B6B6B6"}}>Upload the signed contract document here</p>
                 <button style={{backgroundColor:"#333333",margin:"12px",border:"1px solid #2DA3B4",padding:"10px",borderRadius:"3px",color:"white"}}>Upload</button>
             </div>
                 
         
    </div>
    
   
     <div style={{margin:'20px',textAlign:"center"}}>
         <Link to={URLS.CLIENT.INVOICES}><Button  style={{backgroundColor:"#2DA3B4"}}>Submit</Button></Link>
    </div>
     </Modal.Body>
     </div>
     </Modal>
     </div>
        </div>
    )
}

import * as POOL_CONSTANTS from "../constants/poolConstant";
export const poolReducer = (state = {
    type: '',
    error: '',
    isFetching: false,
   pools:undefined,
   updatePool:undefined,
   deletePool:undefined,
   deletePool:undefined,
}, action) => {
   
    switch (action.type) {
                case POOL_CONSTANTS.HOME_GET_POOL_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    pools: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case POOL_CONSTANTS.HOME_GET_POOL_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    pools: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case POOL_CONSTANTS.HOME_GET_POOL_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });

                case POOL_CONSTANTS.HOME_POST_POOL_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    pools: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case POOL_CONSTANTS.HOME_POST_POOL_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    pool: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case POOL_CONSTANTS.HOME_POST_POOL_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });
                case POOL_CONSTANTS.HOME_UPDATE_POOL_CONSTANTS.REQUEST:
                    return Object.assign({}, state, {
                        isFetching: true,
                        type: action.type,
                        updatePool: undefined,
                        lastUpdated: action.receivedAt,
                    });
        
                case POOL_CONSTANTS.HOME_UPDATE_POOL_CONSTANTS.RECEIVED:
                    return Object.assign({}, state, {
                        isFetching: false,
                        type: action.type,
                        updatePool: action.data,
                        lastUpdated: action.receivedAt,
                    });
        
                case POOL_CONSTANTS.HOME_UPDATE_POOL_CONSTANTS.RECEIVED_ERROR:
                    return Object.assign({}, state, {
                        isFetching: false,
                        type: action.type,
                        error: action.error,
                    });
                    case POOL_CONSTANTS.HOME_DELETE_POOL_CONSTANTS.REQUEST:
                        return Object.assign({}, state, {
                            isFetching: true,
                            type: action.type,
                            deletePool: undefined,
                            lastUpdated: action.receivedAt,
                        });
            
                    case POOL_CONSTANTS.HOME_DELETE_POOL_CONSTANTS.RECEIVED:
                        return Object.assign({}, state, {
                            isFetching: false,
                            type: action.type,
                            deletePool: action.data,
                            lastUpdated: action.receivedAt,
                        });
            
                    case POOL_CONSTANTS.HOME_DELETE_POOL_CONSTANTS.RECEIVED_ERROR:
                        return Object.assign({}, state, {
                            isFetching: false,
                            type: action.type,
                            error: action.error,
                        });
    

        default:
            return state;
    }
};


export default poolReducer;

import React, { useEffect, useState, Component } from "react";
import Sidebar from "./Dashboard/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import MainHeader from "../Lender/Dashboard/mainHeader";
import "../../assets/css/wallet.css";
import BUSD from "../../assets/images/BUSD.png";
import { URI } from "../../constant";
import ajax from "../../utils/ajax";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { getCoins } from "../../services/actions/coinActions";

// react-bootstrap components
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import Withdraw from "../../assets/images/Withdraw.png";
import CreatePinPopup from "./Common/creatPinPopup";
import ForgotPinPopup from "./Common/forgotPinPopup";
import {
  getUserById,
  sendUserPinLink,
  sendUserForgotPinLink,
} from "../../api/userService";
import SecretPinPopup from "./Common/secretPinPopup";
import SuccesstPinPopup from "./Common/successPinPopup";
import { ethers } from "ethers";
class WithdrawCrypto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coin: [],
      createPinShow: false,
      enterPinShow: false,
      forgotPinShow: false,
      transaction: false,
      successFullyWithDrawn: false,
      coinId: this.props.match.params.id,
      selectedCoin: "",
      reward: "",
      userData: {},
      selectedCoin: "",
      toAddress: "",
      validateAddress: false,
      amount: 0,
      isLoading: false,
      userTokenBalance: 0,
      validateAmount: "",
      minAmount: 10,
      faq: [
        {
          title: "How do I withdraw funds ?",
          answer: [
            {
              step: `Step 1: Log in to your account and go to Wallet - Withdraw`,
            },
            {
              step: `Step 2: Make sure the coin you want to withdraw is selected. Please ensure the network in Matic Mainnet (Polygon). Paste the address of the wallet that you plan to transfer the funds too and select withdraw.`,
            },
            {
              step: `Step 3: Once the Withdraw is completed, the Withdraw transaction will be dispalyed in History`,
            },
          ],
        },
        {
          title: "Raise your query to Polytrade team",
          answer: [
            {
              step: `Step 1: Select "Support" on the lower left corner`,
            },
            {
              step: `Step 2: Add your Name , Email Address and Problem Statement. You can attach any document or sheet shot for reference.`,
            },
            {
              step: `Step 3: Once the ticket has been raised you can keep track of the same from your email`,
            },
          ],
        },

        {
          title: "How to create a PIN?",
          answer: [
            {
              step: `Step 1: Select WITHDRAW in the wallet`,
            },
            {
              step: `Step 2: Request for an email to generate the SECRET PIN`,
            },
            {
              step: `Step 3: An email will be sent to your registered email address`,
            },
            {
              step: `Step 4: Click on the link`,
            },
            {
              step: `Step 5: Select your secret question and answer (Case sensitive)`,
            },
            {
              step: `Step 6: Enter your PIN`,
            },
            {
              step: `Step 7: Renter the PIN to confirm`,
            },
            {
              step: `Step 8: That’s is your pin is created`,
            },
          ],
        },

        {
          title: "How to regenerate a new PIN?",
          answer: [
            {
              step: `Step 1: Select Forgot PIN`,
            },
            {
              step: `Step 2: Request for an email to regenerate secret pin`,
            },
            {
              step: `Step 3: An email will be sent to your registered email address`,
            },
            {
              step: `Step 4: Click on the link`,
            },
            {
              step: `Step 5: Choose your PIN to regenerate`,
            },
            {
              step: `Step 6: Renter the PIN to confirm`,
            },
            {
              step: `Step 7: You have regenerated your PIN`,
            },
            {
              step: `Step 8: Login again`,
            },
          ],
        },

        {
          title: "What’s my secret question?",
          answer: [
            {
              step: `We have created a requirement to select a secret question while you create your pin as an additional layer of security for your funds. This secret question will be mandatory for all the users to regenerate a new pin. Please note that in case you forgot the secret PIN you will have to contact support@polytrade.finance to redo the KYC process`,
            }
          ],
        },



      ],
    };
  }
  //Function to get real coin data
  gCoinData = async () => {
    const res = await fetch(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false"
    );
    const realData = await res.json();
    this.setState({
      coin: realData,
    });
  };

  goback = () => {
    this.props.history.push("/lender/wallet");
  };

  //Creat wallet
  createWallet = async () => {
    const userData = await ajax(
      "POST",
      `${URI}/user/wallet/create/${sessionStorage.getItem("userId")}`,
      {}
    );
    sessionStorage.setItem(
      "wallet_Id",
      userData.data.wallet_address ? userData.data.wallet_address : ""
    );
  };

  numberWithCommas = (x) => {
    return Number(x)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  //Fetch token specific balance
  fetchBalance = async () => {
    try {
      let walletId = sessionStorage.getItem("wallet_Id");
      if (walletId) {
        const walletData = await ajax(
          "GET",
          `${URI}/user/wallet/token/${
            this.state.selectedCoin.address
          }/balance/${sessionStorage.getItem("wallet_Id")}`,
          {}
        );
        if (walletData.data.success) {
          this.setState({
            userTokenBalance: walletData.data.data.balance,
          });
        }
      }
    } catch (error) {}
  };

  coinById = async () => {
    const coinData = await ajax("GET", `${URI}/coin/${this.state.coinId}`, {});
    this.setState({ selectedCoin: coinData.data }, () => {
      //To fetch token specific balance
      this.fetchBalance();
    });
  };
  getUserById = async () => {
    const response = await getUserById(sessionStorage.getItem("userId"));
    if (response) {
      this.setState({
        userData: response.data,
      });
    }
  };

  componentDidMount() {
    this.getUserById();
    this.coinById();
    this.gCoinData();
    const wallet_Id = sessionStorage.getItem("wallet_Id");
    if (wallet_Id === "") {
      this.createWallet();
    }
    let selectedElement =
      document.getElementsByClassName(`invoices-menu`)[0].children[0];
    selectedElement.classList.add("act");
  }
  showPinPopUp = () => {
    if (this.state.userData.is_pin_set) {
      if (this.state.amount && this.state.toAddress) {
        this.setState({ enterPinShow: true });
      } else {
        toast.error("Please enter address and amount for withdraw");
      }
    } else {
      this.setState({ createPinShow: true });
    }
  };
  initiateWithDraw = (pin) => {
    if (pin.length === 4) {
      this.setState({
        transaction: true,
      });
      this.postWithdrawWallet(pin);
    } else {
      toast.error("Please enter 4 digit pin");
    }
  };

  forgotPinPopUp = () => {
    this.setState({
      forgotPinShow: true,
      enterPinShow: false,
    });
  };
  sendMailLink = async () => {
    let response = await sendUserPinLink(sessionStorage.getItem("userId"));
    if (response) {
      this.setState({ createPinShow: false });
      toast.info(
        "A link for pin generation has been sent to your registered email"
      );
      setTimeout(() => this.props.history.push("/lender/wallet"), 4000);
    }
  };

  sendForgotMailLink = async (inputs) => {
    let response = await sendUserForgotPinLink(
      sessionStorage.getItem("userId"),
      inputs
    );
    if (response) {
      this.setState({ forgotPinShow: false });
      toast.info(
        "A link for pin generation has been sent to your registered email"
      );
      setTimeout(() => this.props.history.push("/lender/wallet"), 4000);
    }
  };
  postWithdrawWallet = async (pin) => {
    const bodyData = {
      pin: pin,
      amount: this.state.amount,
      type: "WITHDRAWAL",
      address: this.state.toAddress,
      wallet_id: sessionStorage.getItem("wallet_Id"),
      selected_coin: this.state.selectedCoin._id,
      coin_address: this.state.selectedCoin.address,
    };
    const response = await ajax(
      "POST",
      `${URI}/user/wallet/withdraw/${sessionStorage.getItem("userId")}`,
      {},
      {},
      bodyData
    );
    if (response) {
      if (response.data.success) {
        this.setState({
          successFullyWithDrawn: true,
          transaction: false,
          enterPinShow: false,
          amount: 0,
          toAddress: "",
        });
        setTimeout(() => this.props.history.push("/lender/wallet"), 4000);
      }
    } else {
      this.setState({ enterPinShow: true, transaction: false });
    }
  };
  validatWalletAddress = (address) => {
    return ethers.utils.isAddress(address);
  };
  handleChange = (event) => {
    this.setState({
      toAddress: event.target.value,
    });
    if (!this.validatWalletAddress(event.target.value)) {
      this.setState({ validateAddress: true });
    } else {
      this.setState({ validateAddress: false });
    }
  };
  hideCreatePin = async () => {
    this.setState({
      createPinShow: false,
      forgotPinShow: false,
    });
  };
  hideEnterPin = async () => {
    this.setState({
      enterPinShow: false,
    });
  };
  hideSuccessPopup = () => {
    this.setState({
      successFullyWithDrawn: false,
    });
  };
  handleAmountChange = (event) => {
    this.fetchCoinBalance(event.target.value);
  };
  fetchCoinBalance = (amount) => {
    this.setState({
      amount: amount,
    });
    if (parseFloat(amount) > parseFloat(this.state.userTokenBalance)) {
      this.setState({ validateAmount: "MAX" });
    } else if (parseFloat(amount) < parseFloat(this.state.minAmount)) {
      this.setState({ validateAmount: "MIN" });
    } else {
      this.setState({ validateAmount: "" });
    }
  };
  render() {
    return (
      <>
        <div className="main-content main-content-lender">
          <Sidebar />
          {/* <BottomNav/> */}
          <header className="disp-sm-none">
            {/* <div className="d-flex" onClick={this.goback}>
                    <img src={backlogo} className={`${this.state.is_list_active?'tradelogo mr-2':'d-none'}`} />   
                 
                    <h2  className={`${this.state.is_list_active?'font-style-18 ml-0 mt-2':'d-none'}`}  style={{marginLeft:'16px'}}> 
                    wallet
                     </h2>
                    </div> */}
            {/* <h2 className='heading' style={{marginLeft:'16px'}}>
        </h2>  */}
            <div className="mt-4  " style={{ marginLeft: "16px" }}>
              <i
                onClick={this.goback}
                style={{ fontSize: "20px", cursor: "pointer" }}
                className="ml uil-arrow-left"
              >
                {" "}
              </i>
              <span>Wallet</span>
            </div>

            <MainHeader />
          </header>

          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ForgotPinPopup
            show={this.state.forgotPinShow}
            hideReward={this.hideCreatePin}
            sendMailLink={this.sendForgotMailLink}
          />

          <CreatePinPopup
            show={this.state.createPinShow}
            hideReward={this.hideCreatePin}
            sendMailLink={this.sendMailLink}
          />
          <SecretPinPopup
            show={this.state.enterPinShow}
            hideReward={this.hideEnterPin}
            transaction={this.state.transaction}
            forgotPinPopUp={this.forgotPinPopUp}
            initiateWithDraw={this.initiateWithDraw}
          />
          <SuccesstPinPopup
            show={this.state.successFullyWithDrawn}
            hideReward={this.hideSuccessPopup}
          />
          <h2 className="disp-sm-none deposit-crypto">Withdraw Crypto</h2>

          <div
            className="text-white disp-lg-none borderw p-2 mb-4"
            style={{ background: "#262626" }}
          >
            <i
              onClick={this.goback}
              style={{ fontSize: "20px", cursor: "pointer" }}
              className="ml uil-arrow-left"
            >
              {" "}
            </i>
            <span>Withdraw Crypto</span>
          </div>

          <div className="row m-2 ">
            <div className="col-sm-7">
              <div className="container-withdraw pl-2">
                <div className="row">
                  <div className="col-md-10 mb-3  col-sm-11 ml-3 mt-3 text-left">
                    <div className="w-100 pr-4 pl-5 mx-auto ">
                      <div className="mt-2 d-flex justify-content-start">
                        <img
                          alt="img"
                          style={{
                            position: "absolute",
                            margin: "4px 0px 21px -33px",
                            color: "#fff",
                          }}
                          height="20px"
                          src={
                            this.state.selectedCoin.image
                              ? this.state.selectedCoin.image
                              : "https://assets.coingecko.com/coins/images/325/large/Tether-logo.png?1598003707"
                          }
                        ></img>
                        <h5 className="text-white">
                          {this.state.selectedCoin.name}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 mb-3 col-sm-11 ml-3 mt-1 text-left">
                    <label className="select-coin">Address</label>
                    <input
                      maxLength="42"
                      type="text "
                      onChange={this.handleChange}
                      value={this.state.toAddress}
                      className="pl-2 input-style  custom-textbox-2  custom-textbox form-select wit_th custom-textbox"
                      style={{
                        boxSizing: "border-box",
                        borderRadius: "4px",
                        color: "#FFFFFF",
                      }}
                      placeholder="Paste Address here"
                    />
                    {this.state.validateAddress ? (
                      <div className="text-danger">
                        Please Enter Valid Address
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10   mb-3  col-sm-11 ml-3 mt-3 text-left">
                    <label className="select-coin">
                      Network{" "}
                      <i
                        className="uil-info-circle"
                        title="Funds send to wrong chain will be lost"
                      />
                    </label>
                    <select
                      className="form-select wit_th  custom-textbox-2 "
                      name="select_coins"
                      value="Select your network here"
                    >
                      <option>Matic Mainnet</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 mb-2  col-sm-11 ml-3 mt-1 text-left">
                    <div className="col-md-12 p-0">
                      <label className="select-coin ">Withdraw Amount</label>
                      <label className="select-coin float-right">
                        Balance: ${this.state.userTokenBalance}{" "}
                      </label>
                    </div>

                    <span className="search d-flex justify-content-end">
                      <span
                        style={{
                          position: "absolute",
                          margin: "10px 15px 48px auto",
                        }}
                      >
                        <span
                          className="font-style-5 curserP"
                          onClick={() => {
                            this.fetchCoinBalance(this.state.userTokenBalance);
                          }}
                        >
                          MAX
                        </span>
                        <span className="font-style-11">
                          {" "}
                          |{" "}
                          <lable className="text-uppercase">
                            {this.state.selectedCoin
                              ? this.state.selectedCoin.symbol
                              : "USDT"}
                          </lable>
                        </span>
                      </span>

                      <input
                        type="number"
                        onChange={this.handleAmountChange}
                        value={this.state.amount}
                        className="input-style custom-textbox-2 pl-2 form-select wit_th"
                        style={{ color: "#FFFFFF" }}
                        placeholder="Please Enter Amount"
                      />
                    </span>
                    {this.state.validateAmount === "MIN" ? (
                      <div className="text-danger">
                        Withdraw amount is lower than minimum withdrawal amount
                      </div>
                    ) : this.state.validateAmount === "MAX" ? (
                      <div className="text-danger">
                        Withdraw amount is greater than maximum balance amount
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5 d-none mb-2  col-sm-11 ml-3 mt-2 text-left">
                    <div className="expected-text">24h remaining limit</div>
                    <h4 className="h4-text">1000000 BUSD</h4>
                    <div className="col-md-5 mb-1  col-sm-11 ml-3 mt-3 text-left">
                      <div className="expected-text">Transaction fee</div>
                      <h4 className="h4-text">0.06 BUSD</h4>
                    </div>
                  </div>
                  <div className="  mb-2 col-sm-11 ml-3 mt-2 text-left">
                    <div className="expected-text">Minimum withdrawal</div>
                    <h4 className="h4-text">
                      {this.state.minAmount}{" "}
                      <lable className="text-uppercase">
                        {this.state.selectedCoin
                          ? this.state.selectedCoin.symbol
                          : "USDT"}
                      </lable>
                    </h4>
                  </div>
                </div>

                <div className="row d-none">
                  <div className="col-md-5 mb-2 col-sm-11 ml-3 mt-2 text-left">
                    <div className="expected-text">You will get</div>
                    <h4 className="h4-text">0.00 BUSD</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 mb-1 sm-12 ml-3 mt-2">
                    <div style={{ textAlign: "center", marginTop: "12px" }}>
                      <Button
                        disabled={
                          this.state.validateAmount ||
                          this.state.isLoading ||
                          this.state.validateAddress
                        }
                        onClick={this.showPinPopUp}
                        className="btn-block btn-primary btn-lg long-w-10 mb-2  btn btn-lg"
                        style={{ background: "#2DA3B4", borderRadius: "4px" }}
                      >
                        {this.state.isLoading ? (
                          <div
                            className="spinner-border text-white loader-sm"
                            role="status"
                          ></div>
                        ) : (
                          "Withdraw"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 container-withdraw pl-2 ">
              <div className="row  mb-2">
                <h2 className="deposit-faq mt-3"> FAQ</h2>
              </div>

              {this.state.faq.map((data, i) => (
                <div className="row w-100 ml-1 mt-2">
                  <div className="container-faq-box  pr-2 pl-2">
                    <lable
                      className="font-style-15"
                      href={`#${i}`}
                      data-toggle="collapse"
                    >
                      {data.title}
                    </lable>
                    <div
                      id={`${i}`}
                      className="font-style-2 mt-3 mb-3 collapse"
                    >
                      {data.answer.map((data) => (
                        <p>{data.step}</p>
                      ))}
                    </div>
                  </div>
                </div>
              ))}

              <div className="pl-5 d-none" style={{ color: "#4DB5C4" }}>
                View All
              </div>
            </div>
          </div>

          <Container>
            <Row>
              <h2 className="deposit-crypto d-none"> Recent Withdraw</h2>
              <Col sm="12" md="12">
                <Card className="card-plain table-plain-bg d-none">
                  <Card.Body className="table-full-width table-responsive px-0">
                    <table className="border-0">
                      <thead className="disp_non_sm">
                        <tr className="text_tra1 ">
                          <th>Date and Time</th>
                          <th>Actions</th>
                          <th> Description</th>
                          <th>Transaction ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="sec1 mb-2 ">
                          <td>May 24 11.30 AM EST</td>
                          <td>
                            <img
                              alt="img"
                              className="mx-1"
                              src={Withdraw}
                            ></img>{" "}
                            Withdraw
                          </td>
                          <td>
                            <img alt="img" className="mx-1" src={BUSD}></img>
                            <h7 className="text_tra1 mx-1">BUSD 4,365</h7>
                            <h7> </h7>
                          </td>
                          <td className="trans_id text-wrap disp_non_sm">
                            <h7 style={{ color: "#4DB5C4" }}></h7>
                          </td>
                        </tr>
                        <tr className="sec1 mb-2 ">
                          <td>May 24 11.30 AM EST</td>
                          <td>
                            <img
                              alt="img"
                              className="mx-1"
                              src={Withdraw}
                            ></img>{" "}
                            Withdraw
                          </td>
                          <td>
                            <img alt="img" className="mx-1" src={BUSD}></img>
                            <h7 className="text_tra1 mx-1">BUSD 4,365</h7>
                            <h7> </h7>
                          </td>
                          <td className="trans_id text-wrap disp_non_sm">
                            <h7 style={{ color: "#4DB5C4" }}></h7>
                          </td>
                        </tr>
                        <tr className="sec1 mb-2 ">
                          <td>May 24 11.30 AM EST</td>
                          <td>
                            <img img className="mx-1" src={Withdraw}></img>{" "}
                            Withdraw
                          </td>
                          <td>
                            <img className="mx-1" src={BUSD}></img>
                            <h7 className="text_tra1 mx-1">BUSD 4,365</h7>
                            <h7> </h7>
                          </td>
                          <td className="trans_id text-wrap disp_non_sm">
                            <h7 style={{ color: "#4DB5C4" }}></h7>
                          </td>
                        </tr>
                        <tr className="sec1 mb-2 ">
                          <td>May 24 11.30 AM EST</td>
                          <td>
                            <img img className="mx-1" src={Withdraw}></img>{" "}
                            Withdraw
                          </td>
                          <td>
                            <img className="mx-1" src={BUSD}></img>
                            <h7 className="text_tra1 mx-1">BUSD 4,365</h7>
                            <h7> </h7>
                          </td>
                          <td className="trans_id text-wrap disp_non_sm">
                            <h7 style={{ color: "#4DB5C4" }}></h7>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  coin: state.coin,
});
const mapDispatchToProps = (dispatch) => ({
  getCoins: (data) => dispatch(getCoins(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WithdrawCrypto));

import axios from "axios";
import * as REFERRAL_CONSTANTS from "../constants/referralConstants";
import ajax from "../../utils/ajax";

export const requestGetReferralsById = () => ({
  type: REFERRAL_CONSTANTS.HOME_GET_REFERRAL_BY_ID_CONSTANTS.REQUEST,
});

export const recievedGetReferralsByIdResponse = (data) => ({
  type: REFERRAL_CONSTANTS.HOME_GET_REFERRAL_BY_ID_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetReferralsByIdError = (err) => ({
  type: REFERRAL_CONSTANTS.HOME_GET_REFERRAL_BY_ID_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getReferralsById = (id) => {
  let url = REFERRAL_CONSTANTS.HOME_GET_REFERRAL_BY_ID_CONSTANTS.URL + id;

  return (dispatch) => {
    dispatch(requestGetReferralsById());

    ajax("GET", url, {})
      .then((res) => dispatch(recievedGetReferralsByIdResponse(res.data)))
      .catch((err) => dispatch(recievedGetReferralsByIdError(err)));
  };
};

export const requestPostReferral = () => ({
  type: REFERRAL_CONSTANTS.HOME_POST_REFERRAL_CONSTANTS.REQUEST,
});

export const recievedPostReferralResponse = (data) => ({
  type: REFERRAL_CONSTANTS.HOME_POST_REFERRAL_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedPostReferralError = (err) => ({
  type: REFERRAL_CONSTANTS.HOME_POST_REFERRAL_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const postReferral = (data) => {
  let url = REFERRAL_CONSTANTS.HOME_POST_REFERRAL_CONSTANTS.URL + "/";

  return (dispatch) => {
    dispatch(requestPostReferral());

    ajax("POST", url, {}, {}, data)
      .then((res) => dispatch(recievedPostReferralResponse(res.data)))
      .catch((err) => dispatch(recievedPostReferralError(err)));
  };
};

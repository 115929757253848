/**
 * Component for City list
 * @param {*} props 
 * @returns 
 */
export default function CityList(props) {
    return (
        <>
            <select
                className="form-select wit_th"
                name="city"
                onChange={props.handleChange}
                value={props.value}
            >
                <option className="country-state-dropdown">
                    Choose city
                </option>
                {props.city?.map((item, i) => (
                    <option
                        key={item}
                        className="country-state-dropdown"
                        value={item.name}
                    >
                        {item.name}
                    </option>
                ))
                }
            </select>
        </>
    )
}
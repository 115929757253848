import React from "react";
import "../../../assets/css/BuyerProfile.css";
import "react-toastify/dist/ReactToastify.css";
import { USER_TYPE } from "../../../config/string_constants";
import { Country, City, State } from "country-state-city";
import CountryList from '../Common/countryList';
import CityList from '../Common/cityList';
import DialCodeList from "../Common/dialCodeList";
import StateList from "../Common/stateList";

class BuyerCompanyForm extends React.Component {
  updateStart = 0;
  constructor(props) {
    super(props);

    this.state = {
      loadbuyer: true,
      isLoader: false,
      company: {
        company_name: "",
        company_email_id: "",
        company_dialCode: "",
        company_phone_no: "",
        website: "",
        company_landline: "",
        address_line1: "",
        city: "",
        zipcode: "",
        createdById: sessionStorage.getItem("userId"),
        company_type: USER_TYPE.BUYER,
        state: "",
      },

      country: [],
      city: [],
      state: [],
      contact_details: false,
      addSucess: false,
      dirty: {
        company_name: false,
        company_email_id: false,
        company_phone_no: false,
        website: false,
        company_landline: false,
        address_line1: false,
        city: false,
        country_id: false,
        company_dialCode: false,
        state: false,
      },

      errors: {
        company_name: [],
        company_email_id: [],
        company_phone_no: [],
        website: [],
        address_line1: [],
        city: [],
        country_id: [],
        company_dialCode: [],
        state: [],
      },
    };
  }
  handleChange = (e) => {
    const { name, value } = e.target;

    let dirty = this.state.dirty;
    dirty[name] = true;

    let company = this.state.company;
    if (name === "company_phone_no") {
      let cleanText = ("" + value).replace(/\D/g, "");
      company[name] = cleanText;
    } else {
      company[name] = value;
    }
    this.setState(
      {
        company: company,
        dirty: dirty,
      },
      this.validate
    );
  };

  handleCountryChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;

    let company = this.state.company;
    company[name] = value;
    const country = Country.getAllCountries().filter((item) => item.name === this.state.company.country_name);
    const state = State.getStatesOfCountry(country[0].isoCode).filter((item) => item.name === this.state.company.state);
    this.setState(
      {
        company: company,
        city: City.getCitiesOfState(country[0]?.isoCode, state[0]?.isoCode),
        state: State.getStatesOfCountry(country[0]?.isoCode),
        dirty: dirty,
      },
      this.validate
    );
  };
  goToUserDetailsPage = () => {
    this.validate();
    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });

    if (this.isValid()) {
      this.props.goToNextPage(this.state.company);
    }
  };

  blurChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };

  validate = () => {
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      errors[control] = [];

      var validCompanyName = /^[A-Za-z0-9_'-\s]+$/;
      var validEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      var validUrl =
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
      var validState = /^[A-Za-z'-\s]+$/;

      switch (control) {
        case "company_name":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          if (this.state.company[control]) {
            if (!validCompanyName.test(this.state.company[control])) {
              errors[control].push("Please Enter valid Name");
            }
          }
          break;

        case "company_phone_no":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          let comapanyPhoneLength = this.state.company[control].length;
          if (
            this.state.company[control] &&
            (comapanyPhoneLength < 6 || comapanyPhoneLength > 12)
          ) {
            errors[control].push("Number min 6 max 12");
          }
          break;

        case "company_email_id":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          if (this.state.company[control]) {
            if (!validEmail.test(this.state.company[control])) {
              errors[control].push("Please Enter valid Email Id");
            }
          }
          break;

        case "address_line1":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "city":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "company_dialCode":
          if (!this.state.company[control]) {
            errors[control].push("Dial code is required");
          }
          break;
        case "website":
          if (this.state.company[control]) {
            if (!validUrl.test(this.state.company[control])) {
              errors[control].push("Please Enter valid Website");
            }
          }
          break;
        case "state":
          if (!this.state.company[control]) {
            errors[control].push("This field is required");
          }
          if (this.state.company[control]) {
            if (!validState.test(this.state.company[control])) {
              errors[control].push("Please enter valid State");
            }
          }
          break;
        default:
          break;
      }
    });
    this.setState({ errors });
  };

  isValid = () => {
    let valid = true;

    for (let control in this.state.errors) {
      if (this.state.errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };

  componentDidMount() {
    this.setState({ company: this.props.company });
  }

  render() {
    return (
      <main className="main-form">
        <div
          className="mid-details"
          style={{ textAlign: "center", color: "#686868" }}
        >
          <a style={{ color: "#4DB5C4", padding: "12px" }}>
            Buyer Company {">"} <span style={{ paddingLeft: "9px" }}></span>
          </a>

          <a className="curserP">Buyer Contact Details</a>
        </div>

        <div className=" main__company_details1">
          <div className="main__company_details_header">
            <div>Buyer Company</div>
            {/* <p style={{color:"#4DB5C4",fontSize:"12px"}}>Please enter company details of your buyer/client</p> */}
          </div>
          <div className="row p-4">
            <div className="col-sm-6 p-2">
              <label className="label" htmlFor="">
                Buyer Company Name
              </label>
              <input
                className="input"
                name="company_name"
                value={this.state.company.company_name}
                onChange={this.handleChange}
                onBlur={this.blurChange}
                type="text"
                placeholder="Enter Buyer Name"
                maxLength="80"
              />
              <div className="text-danger">
                {this.state.errors.company_name[0] &&
                  this.state.dirty.company_name
                  ? this.state.errors.company_name
                  : ""}
              </div>
            </div>
            <div className="col-sm-6 p-2">
              <label className="label" htmlFor="">
                Buyer Company Email
              </label>
              <input
                type="text"
                className="input"
                placeholder="email@example.com"
                name="company_email_id"
                value={this.state.company.company_email_id}
                onChange={this.handleChange}
                onBlur={this.blurChange}
                maxLength="50"
              />
              <div className="text-danger">
                {this.state.errors.company_email_id[0] &&
                  this.state.dirty.company_email_id
                  ? this.state.errors.company_email_id
                  : ""}
              </div>
            </div>
            <div className="col-12 p-2">
              <label className="label" htmlFor="">
                Buyer Company Address
              </label>
              <input
                className="input"
                name="address_line1"
                value={this.state.company.address_line1}
                onChange={this.handleChange}
                onBlur={this.blurChange}
                type="text"
                placeholder="Buyer Company Address"
                maxLength="50"
              />
              <div className="text-danger">
                {this.state.errors.address_line1[0] &&
                  this.state.dirty.address_line1
                  ? this.state.errors.address_line1
                  : ""}
              </div>
            </div>
            <div className="col-sm-4 p-2">
              <label className="label" htmlFor="">
                Country
              </label>
              <CountryList handleCountryChange={this.handleCountryChange} value={this.state.company.country_name} />
            </div>
            <div className="col-sm-4 p-2">
              <label className="label" htmlFor="">
                State{" "}
              </label>
              <StateList handleChange={this.handleCountryChange} state={this.state.state} value={this.state.company.state} />
              {/* } */}
              <div className="text-danger">
                {this.state.errors.state[0] && this.state.dirty.state
                  ? this.state.errors.state
                  : ""}
              </div>
            </div>
            <div className="col-sm-4 p-2">
              <label className="label" htmlFor="">
                City{" "}
              </label>
              <CityList handleChange={this.handleCountryChange} city={this.state.city} value={this.state.company.city} />
              {/* } */}
              <div className="text-danger">
                {this.state.errors.city[0] && this.state.dirty.city
                  ? this.state.errors.city
                  : ""}
              </div>
            </div>


            <div className="col-sm-6 p-2">
              <label className="label" htmlFor="">
                Contact Number
              </label>
              <div className="d-flex">
                <DialCodeList handleChange={this.handleChange} name="company_dialCode" value={this.state.company.company_dialCode} />

                <input
                  className="input"
                  name="company_phone_no"
                  value={this.state.company.company_phone_no}
                  onChange={this.handleChange}
                  onBlur={this.blurChange}
                  type="text"
                  placeholder="Enter your Contact Number"
                  maxLength="13"
                />
              </div>{" "}
              <span className="text-danger">
                {this.state.errors.company_dialCode[0] &&
                  this.state.dirty.company_dialCode
                  ? this.state.errors.company_dialCode
                  : ""}
              </span>{" "}
              <span className="text-danger">
                {this.state.errors.company_phone_no[0] &&
                  this.state.dirty.company_phone_no
                  ? this.state.errors.company_phone_no
                  : ""}
              </span>
            </div>
            <div className="col-sm-6 p-2">
              <label className="label" htmlFor="">
                Website&nbsp;(Optional)
              </label>
              <input
                className="input"
                name="website"
                value={this.state.company.website}
                onChange={this.handleChange}
                onBlur={this.blurChange}
                type="text"
                placeholder="www.example.com"
              />
              <div className="text-danger">
                {this.state.errors.website[0] && this.state.dirty.website
                  ? this.state.errors.website
                  : ""}
              </div>
            </div>
          </div>
        </div>
        <button
          className="btn w-25"
          onClick={this.goToUserDetailsPage}
          style={{
            backgroundColor: "rgb(77 181 196)",
            color: "#fff",
          }}
        >
          Continue
        </button>
      </main>
    );
  }
}

export default BuyerCompanyForm;

import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Logo from '../../../assets/images/Logo.svg';

export default class sidebar extends Component {
    render() {
        return (
          <div>
            <input type="checkbox" id="nav-toggle" />
            <div className="sidebar">
              <div className="sidebar-brand">
                <h2 className="pt-sidebar-logosize">
                  <img width="172px" height="25px" src={Logo} alt="" />
                </h2>
              </div>
              <div
                style={{ borderTop: "1px solid #A5A5A5" }}
                className="sidebar-menu"
              >
                <ul>
                  <li className="dashboard">
                    <NavLink
                      activeClassName="act"
                      style={{ textDecoration: "none" }}
                      to="/lender-dashboard"
                      exact={true}
                    >
                      <i className="uil uil-create-dashboard" />
                      <span className="dash">Lenders</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="act"
                      style={{ textDecoration: "none" }}
                      to="/pool-creation"
                      exact={true}
                    >
                      <i className="uil uil-users-alt" />
                      <span>Pool Creation</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="act"
                      style={{ textDecoration: "none" }}
                      to="/bonus-apy"
                      exact={true}
                    >
                      <i className="uil uil-medal" />
                      <span>Bonus APY</span>
                    </NavLink>
                  </li>
                  <li className="invoices-menu">
                    <NavLink
                      activeClassName="act"
                      style={{ textDecoration: "none" }}
                      to="/lending-history"
                      exact={true}
                    >
                      <i className="uil uil-receipt-alt" />
                      <span>Lending History</span>
                    </NavLink>
                  </li>
                  <li className="profile-menu">
                    <NavLink
                      activeClassName="act"
                      style={{ textDecoration: "none" }}
                      to="/venly-wallet"
                      exact={true}
                    >
                      <i className="uil uil-wallet" />
                      <span>Venly Wallet</span>
                    </NavLink>
                  </li>
                  <li className="invoices-menu">
                    <NavLink
                      activeClassName="act"
                      style={{ textDecoration: "none" }}
                      to="/rewards"
                      exact={true}
                    >
                      <i className="uil-user-plus" />
                      <span>Rewards</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
    }
}

import axios from "axios";
import { URI } from "../constant";
import ajax from "../utils/ajax";

export let createLendNow = async (data) => {
  try {
    let url = URI + "/lend/create";
    return await ajax("POST", url, {}, {}, data);
  } catch (e) {
    return e;
  }
};
export let getAllLendDetails = (id, index = 1) => {
  let url = URI + "/lend/user-id/" + id + `?page=${index}`;
  const res = ajax("GET", url, {});
  return res;
};
export let getAllLendDetailsAdmin = (id, index=1) => {
  let url = URI + "/lend-admin/user-id/" + id + `?page=${index}`;
  const res = ajax("GET", url, {});
  return res;
};

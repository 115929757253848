import React from "react";
import _isEmpty from "lodash/isEmpty";
import "../../../../src/assets/css/dashboard.css";
import "bootstrap/dist/css/bootstrap.min.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import { logoutUser } from "../../../services/magic";
import { logoutBE } from "../../../api/authenticate";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

class MainHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadData: true,
      username: "",
      userId: "",
      userEmail: sessionStorage.getItem("emailOfUser"),
      profileName: "",
      isLoggedIn: sessionStorage.getItem("isLoggedIn"),
    };
  }

  getProfileName = (name) => {
    var parts = name.split(" ");
    var initials = "";
    for (var i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== "") {
        initials += parts[i][0];
      }
    }
    this.setState({ profileName: initials });
  };

  componentDidMount() {
    this.getProfileName(this.state.userEmail);
  }

  logout = async () => {
    let res = await logoutBE();
    if (res.data.success) {
      toast.info(res.data.message);
      logoutUser(sessionStorage.getItem("emailOfUser"));
      sessionStorage.clear();
      this.props.history.push("/lender/login");
    }
  };

  render() {
    return (
      <div className='d-none d-sm-block'>
        {this.state.isLoggedIn === "true" ? (
          <div className='user-wrapper '>
            <div className='profileInitials d-flex justify-content-center '>
              <lable className='center'>{this.state.profileName}</lable>
            </div>
            <h4 className='user-name mt-2'>
              {this.state.userEmail}
              <OverlayTrigger
                // delay={{ show: 250, hide: 400 }}
                trigger='click'
                placement='bottom'
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Title style={{ backgroundColor: "#333333" }}>
                      <div>
                        <lable className='text-white '>FAQ</lable>
                      </div>
                    </Popover.Title>
                    <Popover.Content style={{ backgroundColor: "#333333" }}>
                      <Button
                        onClick={() => this.logout()}
                        style={{ backgroundColor: "#2DA3B4", margin: "10px" }}
                      >
                        Logout
                      </Button>
                    </Popover.Content>
                  </Popover>
                }
              >
                <Button
                  variant='secondary'
                  style={{ backgroundColor: "transparent", border: "none" }}
                >
                  <i className='uil uil-angle-down' />
                </Button>
              </OverlayTrigger>
            </h4>
          </div>
        ) : (
          <div>
            <button
              className=' mr-4 custom-button btn-sm btn'
              onClick={() => {
                this.props.history.push("/lender/login");
              }}
            >
              Login
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(MainHeader);

import { getPricingData } from "../api/pricingService";
import helper from "../utils/helper";

/**Function to get PricingData Parameters based on
 * final offer Pricing Id
 * @param {*} pricing_id final Offer Tenure
 * @param {*} pricingData stores Pricing Details
 * @returns Object
 */
const getPricingDetails = async (pricing_id, pricingData = []) => {
  const res = await getPricingData(pricing_id);
  const data = helper.isNotEmpty(res.data) ? res.data : [];
  for (let i = 0; i < data.data.length; i++) {
    pricingData = data.data[i];
  }
  return pricingData;
};

/**Function to find offer is send to client
 * @param {*} invoice substatus list
 * @param {*} invoice substatus id
 * @returns {boolean}
 */
const checkOfferSentToClient = (invoiveStatus, invoiveStatusid) => {
  let isOfferSend = false;
  invoiveStatus.subStatus.forEach((element) => {
    if (
      element._id === invoiveStatusid &&
      element.statusName === "Done" &&
      invoiveStatus.key === "final-offer"
    ) {
      isOfferSend = true;
    }
  });
  return isOfferSend;
};

/**Function to find invoice is decline
 * @param {*} invoice substatus list
 * @param {*} invoice substatus id
 * @returns {boolean}
 */
const checkInvoiceDecline = (invoiveStatusList, invoiveStatusid) => {
  let isInvoiceDecline = false;
  invoiveStatusList.forEach((element) => {
    if (element._id === invoiveStatusid && element.statusName !== "Declined") {
      isInvoiceDecline = true;
    }
  });
  return isInvoiceDecline;
};

export const HELPERS = {
  getPricingDetails,
  checkOfferSentToClient,
  checkInvoiceDecline,
};

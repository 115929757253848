import React, { Component } from "react";
import styles from "../../assets/css/SignUp.module.css";
import Logo from "../../assets/images/Logo.svg";
import RightImage from "../../assets/images/SignUpPage/signup.svg";
import axios from "axios";
import { URI } from "../../constant";
import { withRouter } from "react-router-dom";
import preview from "../../assets/images/preview.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CONSTANTS from "../../utils/constant";
class login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: { email: "", password: "", user_type: "ADMIN" },
      type: "password",
    };
  }

  handleShow = () => {
    this.setState({
      type: this.state.type === "text" ? "password" : "text",
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    let user = this.state.user;
    user[name] = value;

    this.setState({ user: user });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await axios.post(`${URI}/auth/login`, this.state.user);
      if (userData.data.success) {
        let userRes = userData.data.data;
        sessionStorage.setItem("emailOfAdmin", userRes.email);
        sessionStorage.setItem("nameOfAdmin", userRes.full_name);
        sessionStorage.setItem("uName", userRes.full_name);
        sessionStorage.setItem(
          "x_access_token",
          userRes.x_access_token ? userRes.x_access_token : ""
        );
        sessionStorage.setItem("user-type", "ADMIN");
        this.props.history.push("/dashboard");
      } else {
        toast.error(CONSTANTS.MESSAGES.UNAUTHORIZED);
      }
    } catch (err) {
      if (err.response.status === CONSTANTS.STATUS.TOO_MANY_REQUESTS) {
        toast.error(CONSTANTS.MESSAGES.TOO_MANY_REQUESTS);
      } else if (err.response.status === CONSTANTS.STATUS.UNAUTHORIZED) {
        toast.error(CONSTANTS.MESSAGES.UNAUTHORIZED);
      } else {
        toast.error(CONSTANTS.MESSAGES.INTERNAL_SERVER_ERROR);
      }
    }
  };

  render() {
    return (
      <div className={styles.SignUp}>
        <img className={styles.Logo} src={Logo} alt="" />

        <div className={styles.Right1}>
          <div className={styles.RightContainer}>
            <img src={RightImage} alt="" />
            <h3 className="mt-2">Shaping the future of Trade Finance</h3>
          </div>
        </div>

        <div className={styles.Left}>
          <div className={styles.LeftContainer}>
            <div style={{ marginBottom: "30px", width: "24rem" }}>
              <h1>Welcome to Polytrade</h1>
              <p style={{ width: "24rem" }}>
                Please sign-In to your account and start the administration
              </p>
            </div>
            <form className={styles.SignUpForm}>
              <label className="text_tra1">Email</label>
              <input
                className="text-white"
                type="text"
                name="email"
                id="email"
                placeholder="Enter your email"
                value={this.state.user.email}
                onChange={this.handleChange}
              />
              <label className="text_tra1">Password</label>
              <div className=" d-flex justify-content-end">
                <img
                  style={{
                    position: "absolute",
                    margin: "16px 9px 7px auto",
                    color: "#fff",
                  }}
                  src={preview}
                  onClick={this.handleShow}
                />
                <input
                  className="text-white"
                  type={this.state.type}
                  name="password"
                  value={this.state.user.password}
                  onChange={this.handleChange}
                  placeholder="Enter your password"
                ></input>
              </div>
              <div
                className="mb-3 d-flex mt-1"
                style={{ color: "white", textAlign: "right" }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  style={{ height: "15px", width: "60px" }}
                />
                <div className="mx-4"> Remember Me </div>
              </div>

              <button className="btn-blue" onClick={this.handleSubmit}>
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(login);

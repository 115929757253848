import React from "react";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import "../../../assets/css/dashboard.css";
import Kycpopup from "./kycpopup";
import {
  getInvoiceDashboard,
  getInvoiceDetailsById,
  updateInvoice,
} from "../../../services/actions/invoiceAction";
import { getActivity } from "../../../services/actions/activityActions";
import { getInvoiceStatus } from "../../../services/actions/invoiceStatusAction";
import {
  getAlertsById,
  updateAlert,
} from "../../../services/actions/alertActions";
import { getEmailUser } from "../../../services/actions/userAction";
import { getCompanyByCompanyId } from "../../../services/actions/companyActions";
import MainHeader from "./mainHeader";
import { withRouter } from "react-router-dom";
import Sidebar from "./Sidebar";
import Bottomnav from "./bottomnav";
import Mob_header from "./mob_header";
import { URI } from "../../../constant";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import poly from "../../../assets/images/POLY.svg";
import ajax from "../../../utils/ajax";
import { ADMIN_ALERT_TYPES } from "../../../config/string_constants";
import { URLS } from "../../../config/frontendUrls";

class DashMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      security: false,
      modal: true,
      polytrade: "",
      modalShow: false,
      alert: false,
      dashboard: {},
      activity: {
        activity_name: "",
        service_name: "",
        createdBy: {
          name: "",
          email: "",
          id: "",
        },
      },
      alertDetails: [],
      invoiceStatus: [],
      clientCompany: [],
      invoicedetails: [],
      company_document: "",
      updateSuccess: false,
      alertSuccess: false,
    };
  }

  gcoindata = async () => {
    try {
      const res = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=polytrade&vs_currencies=USD"
      );
      this.setState({
        polytrade: res.data.polytrade.usd,
      });
    } catch (error) {}
  };
  handlemodel = () => {
    this.setState({ security: false });
    sessionStorage.setItem("security", "no");
  };

  componentDidMount() {
    if (window.innerWidth < 650) {
      this.props.history.replace("/mob-view");
    }
    const val = sessionStorage.getItem("security");
    if (val === "yes") {
      this.setState({ security: true });
    }

    this.gcoindata();
    this.props
      .getEmailUser(sessionStorage.getItem("emailOfUser"))
      .then((result) => {
        sessionStorage.setItem("uName", result[0].full_name);
        sessionStorage.setItem("uEmail", result[0].email);
        sessionStorage.setItem("userId", result[0]._id);
        this.props
          .getAlertsById(result[0]._id)
          .then((alerts) => {
            this.setState({
              alertDetails: alerts,
            });
          })
          .catch((err) => {});
        this.props
          .getActivity(result[0].email)
          .then((activities) => {
            this.setState({
              activity: activities,
            });
          })
          .catch((err) => {});
        this.props
          .getInvoiceDashboard(result[0]._id)
          .then((dashboardcount) => {
            this.setState({
              dashboard: dashboardcount,
            });
          })
          .catch((err) => {});
        this.props
          .getCompanyByCompanyId(result[0].company_id)
          .then((company) => {
            sessionStorage.setItem("uCompany", company._id);
            sessionStorage.setItem("uCompanyName", company.company_name);

            this.setState({
              clientCompany: company,
            });
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }

  fileChange = (e, id, alertId, alert) => {
    this.uploadFile(e.target.files[0], id, alertId, alert);
    let files = e.target.files;

    let reader = new FileReader();
    if (e.target.files[0] !== undefined) {
      reader.readAsDataURL(files[0]);
      reader.onLoad = (e) => {};
    }
  };
  uploadFile(file, id, alertId, alert) {
    const formData = new FormData();
    formData.append("image", file);
    if (file !== undefined) {
      ajax(
        "POST",
        `${URI}/api/v1/image-upload`,
        {},
        { "content-type": "multipart/form-data" },
        formData
      )
        .then((data) => {
          this.props
            .getInvoiceDetailsById(id)
            .then((result) => {
              result.invoice_documents.map((doc) => {
                if (doc.alert_id === alertId) {
                  doc.url = data.data.imageUrl;
                }
              });

              alert.request_documet_state = "Uploaded";

              this.setState(
                {
                  updateSuccess: true,
                },
                () => {
                  result.update_type = ADMIN_ALERT_TYPES.INVOICE_DOCUMENTATION;
                  this.props
                    .updateInvoice(result)
                    .then((data) => {
                      toast.info("Document Uploaded");
                      this.setState(
                        {
                          updateSuccess: false,
                        },
                        () => {
                          this.setState(
                            {
                              alertSuccess: true,
                            },
                            () => {
                              this.props
                                .updateAlert(alert)
                                .then((alert) => {
                                  this.setState({
                                    alertSuccess: false,
                                  });
                                })
                                .catch((err) => {});
                            }
                          );
                        }
                      );
                    })
                    .catch((err) => {});
                }
              );
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    }
  }
  // case(status, subStatus) {
  //   switch (status) {
  //     default: return <p> Alert!! your {status} is {subStatus}, click on view for more details on your invoice</p>
  //   }
  // }
  render() {
    return (
      <div>
        <Sidebar />
        <Bottomnav />
        <div className="main-content">
          <Kycpopup
            show={this.state.modalShow}
            onHide={() => this.setState({ modalShow: false })}
          />
          <Mob_header />
          <header className="header1">
            <h2 className="heading" style={{ marginLeft: "16px" }}>
              Dashboard
            </h2>
            <MainHeader />
          </header>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {/* <div className="error">
            <p>
              Please complete the KYC with the necessary documents to make process
              faster and relaible.
            </p>
            <button onClick={() => this.setState({ modalShow: true })}>Complete Now</button>
          </div> */}
          {/*main content*/}
          <main>
            <div className="cards">
              <div className="card-single">
                <p>
                  <i className="uil uil-receipt-alt" /> TOTAL INVOICES
                </p>
                <h3>{this.state.dashboard.totalInvoices}</h3>
                <small>Submitted</small>
              </div>
              <div className="card-single">
                <p>
                  <i className="uil-dollar-sign" />
                  TOTAL AMOUNT FUNDED
                </p>
                {this.state.dashboard.totalAmountFunded ? (
                  <h3>
                    {"$" +
                      Number(this.state.dashboard.totalAmountFunded)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                  </h3>
                ) : (
                  <h3>$0</h3>
                )}
                <small>For {this.state.dashboard.totalInvoices} Invoice</small>
              </div>
              <div className="card-single">
                <p>
                  <img className="mr-1" src={poly} />
                  TRADE HOLDING
                </p>
                <h3 style={{ paddingBottom: "29px" }}>$0</h3>
                {/* <small>Est. $32445</small> */}
              </div>
              <div className="card-single">
                <p>
                  <img className="mr-1" src={poly} />
                  TRADE PRICE
                </p>
                <h3 style={{ paddingBottom: "29px" }}>
                  ${this.state.polytrade ? this.state.polytrade : ""}
                </h3>
                {/* <small className="small-blue"><i style={{ color: "#52CCB8" }} className="uil uil-arrow-growth" /> +21%</small> */}
              </div>
            </div>
            <div className="info-section w-100">
              <div className="info-one">
                <h3 style={{ fontWeight: "500" }}>
                  <i className="uil uil-bell" /> ALERT
                </h3>
                {this.state.alertDetails === undefined
                  ? ""
                  : this.state.alertDetails.map((alert, i) => (
                      <div key={i} className="info-card">
                        {alert.invoice_id ? (
                          <p className="subtitle">
                            Invoice #{alert.invoice_ref_no}
                          </p>
                        ) : (
                          ""
                        )}
                        <div className="info-subcard">
                          {alert.request_document_name ? (
                            alert.request_document_name.length > 20 ? (
                              <p>
                                {alert.request_document_name} :{" "}
                                {alert.request_document_message}
                              </p>
                            ) : (
                              <p>
                                {alert.request_document_name} :{" "}
                                {alert.request_document_message}
                                <label className="uploadb header-input mx-3">
                                  {alert.request_documet_state}
                                  <input
                                    type="file"
                                    style={{
                                      display:
                                        "btn btn-outline-primary col_erone",
                                    }}
                                    onChange={(e) =>
                                      this.fileChange(
                                        e,
                                        alert.invoice_id,
                                        alert._id,
                                        alert
                                      )
                                    }
                                  />
                                </label>
                              </p>
                            )
                          ) : alert && alert.alert_message ? (
                            <p>{alert.alert_message}</p>
                          ) : (
                            <p className="description">
                              <p>
                                {" "}
                                Alert!! your{" "}
                                {alert.invoice_status_id &&
                                  alert.invoice_status_id.displayName}{" "}
                                is{" "}
                                {alert.invoice_status_id &&
                                  alert.invoice_status_id.subStatus.map(
                                    (status) =>
                                      status._id === alert.invoice_substatus_id
                                        ? status.statusName
                                        : ""
                                  )}
                                , click on view for more details on your invoice
                              </p>
                            </p>
                          )}
                          {alert.invoice_id ? (
                            <button
                              onClick={() => {
                                alert.key &&
                                alert.key == ADMIN_ALERT_TYPES.BANK_ACCOUNT
                                  ? this.props.history.replace(
                                      `${URLS.CLIENT.PROFILE}/section4`
                                    )
                                  : this.props.history.replace(
                                      `${URLS.CLIENT.INVOICES_DETAILS}/${alert.invoice_id}`
                                    );
                              }}
                            >
                              View
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ))}
              </div>
              <div className="info-two">
                <h3 style={{ fontWeight: "500" }}>
                  <i className="uil-arrow-growth font20 mr-2" /> ACTIVITY
                </h3>
                {this.state.activity.length === undefined ? (
                  <div>
                    <p className="text_tra">No Activities Recorded Yet</p>
                  </div>
                ) : (
                  this.state.activity.map((key) => (
                    <div className="info-slip submitted">
                      <p>
                        {key.createdBy.name} {key.activity_name} on <br />
                        {new Date(key.createdAt).getDate()}/
                        {new Date(key.createdAt).getMonth() + 1}/
                        {new Date(key.createdAt).getFullYear()}
                      </p>
                    </div>
                  ))
                )}
              </div>
            </div>
            {/* mobile view start */}
            <div className="d-block d-sm-none mt-4 w-100 ">
              <div
                style={{ borderBottom: "1px solid gray" }}
                className="text_tra1"
              >
                <span
                  onClick={() => {
                    this.setState({ alert: false });
                  }}
                  className={
                    this.state.alert ? "mx-3 px-3" : "mx-3 px-3 txt_b border_b "
                  }
                >
                  Alert
                </span>
                <span
                  onClick={() => {
                    this.setState({ alert: true });
                  }}
                  className={
                    this.state.alert
                      ? "mx-3 px-3  txt_b border_b"
                      : "mx-3 px-3   "
                  }
                >
                  Activity
                </span>
              </div>

              {!this.state.alert ? (
                <div>
                  {this.state.alertDetails === undefined
                    ? ""
                    : this.state.alertDetails.map((alert, i) => (
                        <div key={i} className="info-card mt-4">
                          <p className="subtitle">
                            Invoice #{alert.invoice_ref_no}
                          </p>
                          <div className="info-subcard">
                            {alert.request_document_name ? (
                              <p>
                                {alert.request_document_name} :{" "}
                                {alert.request_document_message}
                                <label className="uploadb header-input mx-3">
                                  {alert.request_documet_state}{" "}
                                  <input
                                    type="file"
                                    style={{
                                      display:
                                        "btn btn-outline-primary col_erone",
                                    }}
                                    onChange={(e) =>
                                      this.fileChange(
                                        e,
                                        alert.invoice_ref_no,
                                        alert._id,
                                        alert
                                      )
                                    }
                                  />
                                </label>
                              </p>
                            ) : alert.alert_message ? (
                              <p>{alert.alert_message}</p>
                            ) : (
                              <p className="description">
                                <p>
                                  {" "}
                                  Alert!! your{" "}
                                  {alert.invoice_status_id &&
                                    alert.invoice_status_id.displayName}{" "}
                                  is{" "}
                                  {alert.invoice_status_id &&
                                    alert.invoice_status_id.subStatus.map(
                                      (status) =>
                                        status._id ===
                                        alert.invoice_substatus_id
                                          ? status.statusName
                                          : ""
                                    )}
                                  , click on view for more details on your
                                  invoice
                                </p>
                              </p>
                            )}

                            <button
                              onClick={() => {
                                alert.key &&
                                alert.key == ADMIN_ALERT_TYPES.BANK_ACCOUNT
                                  ? this.props.history.replace(
                                      `${URLS.CLIENT.PROFILE}/section4`
                                    )
                                  : this.props.history.replace(
                                      `${URLS.CLIENT.INVOICES_DETAILS}/${alert.invoice_id}`
                                    );
                              }}
                            >
                              View
                            </button>
                          </div>
                        </div>
                      ))}
                </div>
              ) : (
                <div>
                  <p>activity</p>
                  {this.state.activity.length === undefined ? (
                    <div>
                      <p className="text_tra">No Activities Recorded Yet</p>
                    </div>
                  ) : (
                    this.state.activity.map((key) => (
                      <div className="info-slip submitted">
                        <p>
                          {key.createdBy.name} {key.activity_name} on <br />
                          {new Date(key.createdAt).getDate()}/
                          {new Date(key.createdAt).getMonth() + 1}/
                          {new Date(key.createdAt).getFullYear()}
                        </p>
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
            {/* mobile view end */}
          </main>
          {/* <Modal
            show={this.state.security}
            onHide={this.handlemodel}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ backgroundColor: "black" }}
          >
            <div
              style={{
                backgroundColor: "#333333",
                color: "white",
                border: "0",
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title
                  id="contained-modal-title-vcenter"
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  Security Info.
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: "center" }}>
                <div
                  className="text-left"
                  style={{ fontSize: "16px", lineHeight: "24px" }}
                >
                  <p>
                    Security related deployments and aspects have not been
                    included as part of the MVP and are currently being
                    developed. Please refrain from entering any sensitive or
                    person identifiable information anywhere in the portal.
                  </p>
                </div>
              </Modal.Body>
            </div>
          </Modal> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  activity: state.activity,
  invoiceStatus: state.invoiceStatus,
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceDashboard: (data) => dispatch(getInvoiceDashboard(data)),
  getActivity: (data) => dispatch(getActivity(data)),
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  getAlertsById: (data) => dispatch(getAlertsById(data)),
  getEmailUser: (data) => dispatch(getEmailUser(data)),
  getCompanyByCompanyId: (data) => dispatch(getCompanyByCompanyId(data)),
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  updateAlert: (data) => dispatch(updateAlert(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashMain));

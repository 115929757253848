import React, { Component } from "react";
import moment from "moment";
import "../../../assets/css/common/fontStyle.css";
import { getCoins } from "../../../services/actions/coinActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MainHeader from "../mainHeader";
import { URI } from "../../../constant";
import axios from "axios";
import { toast } from "react-toastify";
import ajax from "../../../utils/ajax";
import { LEND_NOW_STATUS } from "../../../config/string_constants";
import { getAllLendDetailsAdmin } from "../../../api/lendNowService";
import { USER_TYPE, WALLET_ACTIVATION } from "../../../config/string_constants";
import helper from "../../../utils/helper";
import Paginations from "../../pagination";

class lenderHistorydetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lendingdetails: [],
      lendingData: [],
      paginationItem:[],
      Coins: [],
      selectedCoinPrice: 0,
      searchCoin: "",
      accured_trade: 0,
      userInterestPerDay: 0,
      totalInterest: 0,
      totalInterestInDollars: 0,
    };
  }
  numberWithCommas = (x) => {
    return Number(x)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  ActiveLending = async (index) => {
    try {
      let response = await getAllLendDetailsAdmin(this.props.details._id,index);
      if (response) {
        this.setState({
          lendingData: response.data.data,
          paginationItem:response.data
        });
      }
    } catch (error) {}
  };


  componentDidMount() {
    this.ActiveLending();
  }

  lendingDetails = async (details) => {
    try {
      const selectedCoin = details.pool_id.coin_id.id;
      const coinPrice = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${selectedCoin}&vs_currencies=usd`
      );
      let currentPrice = coinPrice.data[selectedCoin].usd;
      const userInterestPerDay = helper.getPerDayInterest(
        details.user_bonus_rewards,
        details.lock_amount
      );
      const totalInterest = this.numberWithCommas(
        parseFloat(details.accure_interest_per_day) *
          moment(new Date()).diff(moment(details.subscription_date), "days") +
          parseFloat(details.bonus_interest_per_day) *
            moment(new Date()).diff(moment(details.subscription_date), "days") +
          parseFloat(userInterestPerDay) *
            moment(new Date()).diff(moment(details.subscription_date), "days")
      );
      const totalInterestInDollars =
        totalInterest * this.state.selectedCoinPrice;
      this.setState({
        lendingdetails: details,
        selectedCoinPrice: currentPrice,
        userInterestPerDay: userInterestPerDay,
        totalInterest: totalInterest,
        totalInterestInDollars: totalInterestInDollars,
        more: true,
      });
    } catch (error) {}
  };
  render() {
    return (
      <div>
        {/* <Sidebar /> */}
        <div className="main-content">
          <header>
            <div className="mt-4 text-white">
              <i
                onClick={() => this.props.goBack(false)}
                style={{ fontSize: "20px" }}
                className="ml-4 uil-arrow-left"
              >
                {" "}
              </i>
              <span>Lenders</span>
            </div>
            <MainHeader />
          </header>
          <div className=" sec3 d-flex justify-content-between">
            <div>
              <p className="text_tra1">Lender Email</p>
              <h6 className="text-white">{this.props.details.email}</h6>
            </div>
            <div>
              <p className="text_tra1">Total Lending</p>
              <h6 className="text-white">
                {this.props.details.lend_total_count}
              </h6>
            </div>
            <div>
              <p className="text_tra1">Active Lending</p>
              <h6 className="text-white">
                {this.props.details.lend_active_count}
              </h6>
            </div>
            <div>
              <p className="text_tra1">Wallet Activation</p>
              <h6 className="text-white">
                {this.props.details.wallet_activation ===
                WALLET_ACTIVATION.YES ? (
                  <lable className="txt_b">
                    {this.props.details.wallet_activation}
                  </lable>
                ) : (
                  <td className="txt_red">No</td>
                )}
              </h6>
            </div>
          </div>
          <div className="d-flex m-3 w-100 justify-content-between ">
            <span className="mr-auto my-2 mr-4 search d-flex justify-content-end">
              <i
                style={{
                  position: "absolute",
                  margin: "8px 5px 7px auto",
                  color: "#fff",
                }}
                className="uil-search-alt"
              ></i>

              <input
                className="homesearchbox"
                type="text"
                placeholder="Search..."
                name="searchLender"
                value={this.state.searchCoin}
                onChange={(e) => this.setState({ searchCoin: e.target.value })}
              />
            </span>
          </div>
          <div
            className="table-full-width m-3  mt-3 table-responsive px-0"
            style={{ width: "97%" }}
          >
            <table className="border-0">
              <thead className="disp_non_sm">
                <tr className="text_tra1 ">
                  <th className="pl-2">Coin Name</th>
                  <th className="pl-2">APY</th>
                  <th className="pl-2">Total Amount</th>
                  <th className="pl-2">Accrued Interest</th>
                  <th className="pl-2">Bonus Interest</th>
                  <th className="pl-2">Redemption Date</th>

                  <th className="pl-3">Status</th>
                </tr>
              </thead>
              <tbody>
                {this.state.lendingData
                  .filter((data) => {
                    return data.pool_id.coin_id.name
                      ? data.pool_id.coin_id.name
                      : ""
                          .toLowerCase()
                          .includes(this.state.searchCoin.toLowerCase());
                  })
                  .map((index, key) => (
                    <tr key={key} className="sec1">
                      <td className="d-flex border-0 sec-css">
                        <img
                          className="mt-2"
                          src={index.coin_url}
                          height="25px"
                        />
                        <div className="ml-2 ">
                          <div className="font-1">
                            {index.pool_id.coin_id.name}{" "}
                          </div>
                          <div className="font-2 p-0 m-0 text-uppercase ">
                            {index.pool_id.coin_id.symbol}{" "}
                          </div>
                        </div>
                      </td>
                      <td className="sec-css">
                        {" "}
                        <label className="font-style-10 p-0 m-0">
                          {this.numberWithCommas(index.est_apy)}%
                        </label>
                      </td>
                      <td className="text-uppercase">
                        {index.lock_amount} {index.pool_id.coin_id.symbol}
                      </td>
                      <td className="text-uppercase">
                        {this.numberWithCommas(
                          parseFloat(index.accure_interest_per_day) *
                            moment(new Date()).diff(
                              moment(index.subscription_date),
                              "days"
                            )
                        )}{" "}
                        {index.pool_id.coin_id.symbol}
                      </td>

                      <td className="text-uppercase">
                        {this.numberWithCommas(
                          parseFloat(index.bonus_interest_per_day) *
                            moment(new Date()).diff(
                              moment(index.subscription_date),
                              "days"
                            )
                        )}{" "}
                        {index.pool_id.coin_id.symbol}
                      </td>
                      <td>
                        {moment(index.redemption_date).format("DD MMM YYYY")}
                      </td>
                      <td>
                        {" "}
                        <lable
                          className={
                            index.status === LEND_NOW_STATUS.PENDING
                              ? "in-progress p-1 m-1 pl-2 pr-2"
                              : index.status === LEND_NOW_STATUS.ACTIVE
                              ? "sucess p-1 m-1 pl-2 pr-2"
                              : "yet-verify p-1 m-1 pl-2 pr-2"
                          }
                        >
                          {index.status}
                        </lable>{" "}
                      </td>
                      <td
                        className="txt_b curserP"
                        onClick={() => this.lendingDetails(index)}
                      >
                        More
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-end mx-4 mt-2">
                    <Paginations item={this.state.paginationItem} pageClick={this.ActiveLending} />
              </div>
          </div>
          <div
            style={{ overflowY: "auto" }}
            className={`menuNav ${this.state.more ? "showMenu3" : ""}`}
          >
            <div
              style={{ borderBottom: "1px solid gray" }}
              className="d-flex justify-content-between text-white p-3 pl-1"
            >
              <div>
                <h5>Lending Details</h5>
              </div>
              <h5
                style={{ cursor: "pointer" }}
                onClick={() => this.setState({ more: false })}
              >
                <i className="uil-multiply" />
              </h5>
            </div>
            <div className="modal-body" style={{ background: "#333333" }}>
              <div className="row">
                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0">Coin Name</label>
                  <div>
                    <img
                      className="mr-2"
                      src={
                        this.state.lendingdetails.pool_id &&
                        this.state.lendingdetails.pool_id.coin_id.image
                      }
                      width="25px"
                      height="25px"
                    />
                    <label className="m-0 p-0 font-style-14">
                      {this.state.lendingdetails.pool_id &&
                        this.state.lendingdetails.pool_id.coin_id.name}
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0">Pool Name</label>
                  <div>
                    <label className="m-0 p-0 font-style-14">
                      {this.state.lendingdetails.pool_id &&
                        this.state.lendingdetails.pool_id.pool_name}
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0">Total Amount</label>
                  <div>
                    <label className="m-0 p-0 font-style-14 text-uppercase">
                      {this.numberWithCommas(
                        this.state.lendingdetails.lock_amount
                      )}{" "}
                      {this.state.lendingdetails.pool_id &&
                        this.state.lendingdetails.pool_id.coin_id.symbol}
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0">
                    Total Amount in Dollar
                  </label>
                  <div>
                    <label className="m-0 p-0 font-style-14">
                      $
                      {this.numberWithCommas(
                        this.state.lendingdetails.lock_amount *
                          this.state.selectedCoinPrice
                      )}{" "}
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0">
                    Subscription Date
                  </label>
                  <div>
                    <label className="m-0 p-0 font-style-14">
                      {" "}
                      {moment(
                        this.state.lendingdetails.subscription_date
                      ).format("DD MMM  YYYY")}{" "}
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0">
                    Redemption Date
                  </label>
                  <div>
                    <label className="m-0 p-0 font-style-14">
                      {moment(this.state.lendingdetails.redemption_date).format(
                        "DD MMM  YYYY"
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0">
                    Locked Period (Days)
                  </label>
                  <div>
                    <label className="m-0 p-0 font-style-14">
                      {this.state.lendingdetails.duration} Days
                    </label>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0">Standard APY</label>
                  <div>
                    <label className="m-0 p-0 font-style-16">
                      {this.numberWithCommas(this.state.lendingdetails.est_apy)}
                      %
                    </label>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0">
                    Accured Interest
                  </label>
                  <div>
                    <label className="m-0 p-0 font-style-16 text-uppercase">
                      {this.numberWithCommas(
                        parseFloat(
                          this.state.lendingdetails.accure_interest_per_day
                        ) *
                          moment(new Date()).diff(
                            moment(this.state.lendingdetails.subscription_date),
                            "days"
                          )
                      )}{" "}
                      {this.state.lendingdetails.pool_id &&
                        this.state.lendingdetails.pool_id.coin_id.symbol}
                    </label>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0">
                    Interest End Date
                  </label>
                  <div>
                    <label className="m-0 p-0 font-style-14">
                      {moment(
                        this.state.lendingdetails.interest_end_date
                      ).format("DD MMM  YYYY")}{" "}
                    </label>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-4">
                  <label
                    className="font-style-8 m-0 p-0"
                    title="Bonus reward APY will be only issued in TRADE tokens."
                  >
                    Bonus APY <i className="uil-info-circle text_tra1" />
                  </label>
                  <div>
                    <label className="m-0 p-0 font-style-16">
                      {this.numberWithCommas(
                        this.state.lendingdetails.bonus_apy
                      )}
                      %
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0 ">
                    Bonus Interest
                  </label>
                  <div>
                    <label className="m-0 p-0 font-style-16 text-uppercase">
                      {this.numberWithCommas(
                        parseFloat(
                          this.state.lendingdetails.bonus_interest_per_day
                        ) *
                          moment(new Date()).diff(
                            moment(this.state.lendingdetails.subscription_date),
                            "days"
                          )
                      )}{" "}
                      {this.state.lendingdetails.pool_id &&
                        this.state.lendingdetails.pool_id.coin_id.symbol}
                    </label>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0">Current Date</label>
                  <div>
                    <label className="m-0 p-0 font-style-14">
                      {" "}
                      {moment().format("DD MMM  YYYY")}
                    </label>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0">User APY</label>
                  <div>
                    <label className="m-0 p-0 font-style-16">
                      {this.numberWithCommas(
                        this.state.lendingdetails.user_bonus_rewards
                      )}
                      %
                    </label>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0 ">User Interest</label>
                  <div>
                    <label className="m-0 p-0 font-style-16 text-uppercase">
                      {this.numberWithCommas(
                        parseFloat(this.state.userInterestPerDay) *
                          moment(new Date()).diff(
                            moment(this.state.lendingdetails.subscription_date),
                            "days"
                          )
                      )}
                      {this.state.lendingdetails.pool_id &&
                        this.state.lendingdetails.pool_id.coin_id.symbol}
                    </label>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-4 d-none">
                  <label className="font-style-8 m-0 p-0">Accrued Coins</label>
                  <div>
                    <label className="m-0 p-0 font-style-14">0 Trade </label>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0">Accrued Days</label>
                  <div>
                    <label className="m-0 p-0 font-style-14">
                      {moment(new Date()).diff(
                        moment(this.state.lendingdetails.subscription_date),
                        "days"
                      )}{" "}
                      Days
                    </label>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0 ">Total APY</label>
                  <div>
                    <label className="m-0 p-0 font-style-16 text-uppercase">
                      {this.numberWithCommas(
                        this.state.lendingdetails.user_bonus_rewards +
                          this.state.lendingdetails.est_apy +
                          this.state.lendingdetails.bonus_apy
                      )}
                      %
                    </label>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0 ">
                    Total Interest
                  </label>
                  <div>
                    <label className="m-0 p-0 font-style-16 text-uppercase">
                      ${this.state.totalInterestInDollars}
                    </label>
                  </div>
                </div>

                <div className="col-md-4 col-6 mt-4 d-none">
                  <label className="font-style-8 m-0 p-0">Interest Date</label>
                  <div>
                    <label className="m-0 p-0 font-style-14">
                      {" "}
                      {moment(this.state.lendingdetails.interest_date).format(
                        "DD MMM  YYYY"
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-6 mt-4 d-none">
                  <label className="font-style-8 m-0 p-0">
                    Est. Next Distribution Amount
                  </label>
                  <div>
                    <label className="m-0 p-0 font-style-14">
                      {this.state.lendingdetails.est_next_distribution_amount}
                    </label>
                  </div>
                </div>

                <div className="col-md-12 col-12 mt-4">
                  <label className="font-style-8 m-0 p-0">Transaction ID</label>
                  <div>
                    <label className="m-0 p-0 font-style-16">
                      <a
                        target="_blank"
                        href={
                          "https://polygonscan.com/tx/" +
                          this.state.lendingdetails.tax_id
                        } rel="noreferrer"
                      >
                        <span className="txt_g">
                          {this.state.lendingdetails.tax_id}
                        </span>
                      </a>
                    </label>
                  </div>
                </div>
                <div className="col-md-4 col-6 mt-4">
                  <label className="font-style-8 m-0 p-0">Status</label>
                  <div>
                    <label
                      className={
                        this.state.lendingdetails.status ===
                        LEND_NOW_STATUS.PENDING
                          ? "in-progress p-1 m-1 pl-2 pr-2"
                          : this.state.lendingdetails.status ===
                            LEND_NOW_STATUS.ACTIVE
                          ? "sucess p-1 m-1 pl-2 pr-2"
                          : "yet-verify p-1 m-1 pl-2 pr-2"
                      }
                    >
                      {this.state.lendingdetails.status}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  coin: state.coin,
});

const mapDispatchToProps = (dispatch) => ({
  getCoins: (data) => dispatch(getCoins(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(lenderHistorydetails));

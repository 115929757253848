import * as INVOICE_STATUS_CONSTANTS from "../constants/invoiceStatusConstant";

export const invoiceStatusReducer = (state = {
    type: '',
    error: '',
    isFetching: false,
   status:undefined,
}, action) => {
   
    switch (action.type) {
                case INVOICE_STATUS_CONSTANTS.HOME_GET_INVOICE_STATUS_CONSTANTS.REQUEST:
                return Object.assign({}, state, {
                    isFetching: true,
                    type: action.type,
                    status: undefined,
                    lastUpdated: action.receivedAt,
                });
    
            case INVOICE_STATUS_CONSTANTS.HOME_GET_INVOICE_STATUS_CONSTANTS.RECEIVED:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    status: action.data,
                    lastUpdated: action.receivedAt,
                });
    
            case INVOICE_STATUS_CONSTANTS.HOME_GET_INVOICE_STATUS_CONSTANTS.RECEIVED_ERROR:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.type,
                    error: action.error,
                });


        default:
            return state;
    }
};


export default invoiceStatusReducer;

import axios from "axios";
import * as PRICING_CONSTANTS from "../constants/pricingConstant";
import ajax from "../../utils/ajax";

export const requestGetPricing = () => ({
  type: PRICING_CONSTANTS.HOME_GET_PRICING_CONSTANTS.REQUEST,
});

export const recievedGetPricingResponse = (data) => ({
  type: PRICING_CONSTANTS.HOME_GET_PRICING_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetPricingError = (err) => ({
  type: PRICING_CONSTANTS.HOME_GET_PRICING_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getPricingIDList = (amount, tenure) => async (dispatch) => {
  dispatch(requestGetPricing());
  try {
    let url = PRICING_CONSTANTS.HOME_GET_PRICING_CONSTANTS.URL + amount+"/"+tenure;
    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetPricingResponse(res.data));
      return Promise.resolve(res.data);
    } 
  } catch (err) {
    dispatch(recievedGetPricingError(err));
    return Promise.reject(err);
  }
};

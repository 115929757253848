import React, { Component } from "react";
import Sidebar from "./Dashboard/Sidebar";
import { Card, Container, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Mainheader from "./Dashboard/mainHeader";
import { getUserWalletsById } from "../../services/actions/userWalletActions";
import { postActivity } from "../../services/actions/activityActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import Mob_header from "./Dashboard/mob_header";
import Bottomnav from "./Dashboard/bottomnav";
import logo from "../../assets/images/logo.png";
import comingSoon from "../../assets/images/ComingSoon.svg";
import {
  getUserBankAccountsById,
  postUserAccountData,
  updateUserAccountdata,
} from "../../api/bankDetailsService";
import { ADMIN_ALERT_TYPES } from "../../config/string_constants";
class wallet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      comingSoon:true,
      account: {
        user_name: "",
        bank_name: "",
        account_number: "",
        ifsc_code: "",
        createdById: sessionStorage.getItem("userId"),
      },

      dirty: {
        user_name: false,
        bank_name: false,
        account_number: false,
        ifsc_code: false,
      },

      errors: {
        user_name: [],
        bank_name: [],
        account_number: [],
        ifsc_code: [],
      },
      addSucess: false,
      activity: {
        activity_name: "Added An Account",
        service_name: "addAccount",
        createdBy: {
          name: sessionStorage.getItem("uName"),
          email: sessionStorage.getItem("uEmail"),
          id: sessionStorage.getItem("userId"),
        },
      },
      updateActivity: {
        activity_name: "Updated An Account",
        service_name: "updateAccount",
        createdBy: {
          name: sessionStorage.getItem("uName"),
          email: sessionStorage.getItem("uEmail"),
          id: sessionStorage.getItem("userId"),
        },
      },
      accountDetails: [],
      editData: false,
      UserWallets: [],
    };
  }

  add_account = () => {
    this.setState({
      show: true,
    });
  };
  handleClose = () => {
    this.setState({
      show: false,
      editData: false,
    });

    const { account } = this.state;
    account._id = "";
    account.account_number = "";
    account.bank_name = "";
    account.ifsc_code = "";
    account.user_name = "";
    account.dataStatus = "";
    account.account_type = "";
    account.createdById = sessionStorage.getItem("userId");
    this.setState({
      account,
    });
  };
  handleChange = (e) => {
    const { name, value } = e.target;

    let dirty = this.state.dirty;
    dirty[name] = true;

    let account = this.state.account;

    account[name] = value;

    this.setState(
      {
        account: account,
        dirty: dirty,
      },
      this.validate
    );
  };

  blurChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };

  validate = () => {
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      errors[control] = [];
      //  var validPhone = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/
      var validEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      var validUrl =
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
      var validAccount = /^[0-9]*$/;
      var validIfsc = /^[A-Za-z]{4}\d{7}$/;
      var validName = /^[a-zA-Z ]*$/;
      switch (control) {
        case "user_name":
          if (!this.state.account[control]) {
            errors[control].push("Name can't be blank");
          }
          if (this.state.account[control]) {
            if (!validName.test(this.state.account[control])) {
              errors[control].push("Enter valid Name");
            }
          }
          break;
        case "bank_name":
          if (!this.state.account[control]) {
            errors[control].push("This field is required");
          }
          break;
        case "account_number":
          if (!this.state.account[control]) {
            errors[control].push("This field is required ");
          } else if (this.state.account[control].length < 11) {
            errors[control].push("Account number must have 11 digits ");
          } else if (this.state.account[control]) {
            if (!validAccount.test(this.state.account[control])) {
              errors[control].push("Enter valid Account Number");
            }
          }

          break;
        case "ifsc_code":
          if (!this.state.account[control]) {
            errors[control].push("This field is required");
          }
          if (this.state.account[control]) {
            if (!validIfsc.test(this.state.account[control])) {
              errors[control].push("Not a valid IFSC Code");
            }
          }
          break;

        default:
          break;
      }
    });
    this.setState({ errors });
  };
  isValid = () => {
    let valid = true;

    for (let control in this.state.errors) {
      if (this.state.errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };

  saveAccount = async (e) => {
    e.preventDefault();
    this.validate();
    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });

    if (this.isValid()) {
      let account = { ...this.state.account };
      account.update_type = ADMIN_ALERT_TYPES.BANK_ACCOUNT;
      if (this.state.editData === true) {
        try {
          let res = await updateUserAccountdata(account);
          if (res.status === 201 || res.status === 200) {
            toast.info("Account Updated Successfully");
          }
        } catch (err) {}

        this.props.postActivity(this.state.updateActivity);
        this.setState({
          editData: false,
          show: false,
        });
        this.handleClose();
        this.getBankAccountsData();
      } else {
        this.setState({
          addSucess: true,
          show: false,
        });
        try {
          let res = await postUserAccountData(account);

          if (res.status === 201 || res.status === 200) {
            toast.info("Account Added Successfully");
          }
        } catch (err) {}

        this.props.postActivity(this.state.activity);
        this.setState({
          addSucess: false,
        });
        this.handleClose();
        this.getBankAccountsData();
      }
    }
  };

  editAccount = (data) => {
    const { account, account1 } = this.state;
    account._id = data._id;
    account.account_number = data.account_number;
    account.bank_name = data.bank_name;
    account.ifsc_code = data.ifsc_code;
    account.user_name = data.user_name;
    account.createdById = data.createdById;
    account.dataStatus = data.dataStatus;
    account.account_type = data.account_type;
    this.setState({
      account,
      account1,
      show: true,
      editData: true,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_isEmpty(nextProps.userWallet.UserWallets)) {
      this.setState({
        UserWallets: nextProps.userWallet.UserWallets,
      });
    }
  }
  componentDidMount() {
    this.props.getUserWalletsById(sessionStorage.getItem("userId"));
    this.getBankAccountsData();
  }

  getBankAccountsData = async () => {
    let bkData = await getUserBankAccountsById(
      sessionStorage.getItem("userId")
    );
    this.setState({ accountDetails: bkData.data });
  };
  render() {
    return (
      <div>
        <Sidebar />
        <Bottomnav />
        <div className="main-content">
          <Mob_header />
          <header className="header1">
            <h2 className="heading" style={{ marginLeft: "16px" }}>
              Wallet
            </h2>
            <Mainheader />
          </header>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {this.state.comingSoon ?
          <div className="text-white text-center mt-5">
            <img className="comingsoon" src={comingSoon} />
            <h2 className="mt-3">Coming Soon...</h2>
          </div>:
          <div className="tab" role="tabpanel">
            {/* <!-- Nav tabs --> */}
            <ul
              style={{ borderBottom: "1px solid #4d4d4d" }}
              className="nav nav-tabs"
              role="tablist"
            >
              <li role="presentation" className="active pro_mone1 ml-5">
                <a
                  style={{ textDecoration: "none" }}
                  href="#Sections1"
                  aria-controls="home"
                  role="tab"
                  data-toggle="tab"
                >
                  Balance & History
                </a>
              </li>
              <li role="presentation" className="pro_mone2 ml-4">
                <a
                  style={{ textDecoration: "none" }}
                  href="#Sections2"
                  aria-controls="profile"
                  role="tab"
                  data-toggle="tab"
                >
                  Account Details
                </a>
              </li>
            </ul>
            {/* <hr className="hr_one ml-4"></hr> */}

            <div className="tab-content">
              <div role="tabpanel" className="tab-pane  active" id="Sections1">
                <div className="sec m-3">
                  <Row>
                    <Col lg="4" sm="6" className="mt-2">
                      <div>
                        <Card.Body className="bgc-dark">
                          <h4 className="acc-item">Total Amount Received</h4>
                          {this.state.UserWallets === undefined ? (
                            ""
                          ) : (
                            <h4 className="text-white text-center">
                              {"$" +
                                Number(
                                  this.state.UserWallets.reduce(
                                    (sum, i) => sum + i.amount,
                                    0
                                  )
                                )
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                            </h4>
                          )}
                        </Card.Body>
                      </div>
                    </Col>
                    <Col lg="4" sm="6" className="mt-2">
                      <div>
                        <Card.Body className="bgc-dark">
                          <h4 className="acc-item">Total Rewards Received</h4>
                          <h4 className="text-white text-center">
                            {this.state.UserWallets.reduce(
                              (sum, i) => sum + i.reward,
                              0
                            )}{" "}
                            TRADE
                          </h4>
                        </Card.Body>
                      </div>
                    </Col>
                    <Col lg="4" sm="6" className="mt-2">
                      <div style={{ textAlign: "center" }} className=" mt-4">
                        <a
                          target="_blank"
                          href="https://v2.info.uniswap.org/token/0x6e5970dbd6fc7eb1f29c6d2edf2bc4c36124c0c1" rel="noreferrer"
                        >
                          <button className="btn-blue">
                            Buy Trade Token{" "}
                          </button>
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Container fluid>
                  <div className="d-lg-flex flex-sm-coloumn justify-content-between mt-4">
                    <span>
                      <h3 style={{ color: "white" }}>Payment History</h3>
                    </span>
                    <div className="text-white">
                      <span className="p-2 borderx mx-3 pl-5">
                        All <i className="txt_b uil-filter"></i>
                      </span>
                      <span className="p-2 borderx mx-3 pl-5">
                        Date <i className="txt_b uil-calender"></i>
                      </span>
                    </div>
                  </div>
                  <Row>
                    <Col md="12">
                      <Card className="card-plain table-plain-bg">
                        <Card.Body className="table-full-width table-responsive px-0">
                          <table className="border-0">
                            <thead className="disp_non_sm">
                              <tr className="text_tra1 ">
                                <th style={{ width: "360px" }}>
                                  Date and Time
                                </th>
                                <th>Action</th>
                                <th> Description</th>
                                <th>Bank Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.UserWallets === undefined
                                ? ""
                                : this.state.UserWallets.map((item, i) => (
                                    <tr key={i} className="sec1 mb-2 ">
                                      <td>
                                        {moment(item.createdAt).format(
                                          "MMM DD"
                                        )}{" "}
                                        {moment(item.createdAt).format(
                                          "HH:mm a"
                                        )}
                                      </td>
                                      <td className="disp_non_sm">
                                        {" "}
                                        {item.action}
                                      </td>
                                      <td>
                                        ${item.reward}
                                        <img className="mx-1" src={logo}></img>
                                        <h7 className="text_tra1 mx-1">
                                          TRADE
                                        </h7>
                                        <h7>
                                          {" " +
                                            Number(item.amount)
                                              .toFixed(2)
                                              .replace(
                                                /\d(?=(\d{3})+\.)/g,
                                                "$&,"
                                              )}{" "}
                                        </h7>
                                      </td>
                                      <td
                                        className="trans_id text-wrap disp_non_sm"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <p>{item.bankName}</p>
                                      </td>
                                    </tr>
                                  ))}
                            </tbody>
                          </table>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>

              {/* Account tab start*/}
              <div role="tabpanel" className="tab-pane fade" id="Sections2">
                {this.state.accountDetails === null ? (
                  <h3>No accounts Added yet</h3>
                ) : (
                  this.state.accountDetails.map((account, idx) => (
                    <div key={idx} className="sec2">
                      <div>
                        <h5 className="text-white">
                          {account.bank_name}{" "}
                          <span style={{ fontSize: "small" }} className="txt_b">
                            {account.account_type}
                            <i
                              style={{
                                float: "right",
                                fontSize: "large",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.editAccount(account);
                              }}
                              className="txt_b uil-edit"
                            ></i>
                          </span>
                        </h5>
                      </div>
                      <div className="acc_details">
                        <div>
                          <p className="text_tra1">Name</p>
                          <h5 className="text-white">{account.user_name}</h5>
                        </div>
                        <div>
                          <p className="text_tra1">Account Number</p>
                          <h5 className="text-white">
                            {account.account_number}
                          </h5>
                        </div>
                        <div>
                          <p className="text_tra1">IFSC Code</p>
                          <h5 className="text-white">{account.ifsc_code}</h5>
                        </div>
                        <div>
                          <p className="text_tra1">Bank Name</p>
                          <h5 className="text-white">{account.bank_name}</h5>
                        </div>
                      </div>
                    </div>
                  ))
                )}
                <div className="text-center">
                  <button onClick={this.add_account} className="btn-blue">
                    + Add Account
                  </button>
                </div>

                <div
                  className={`menuNav ${this.state.show ? "showMenu1" : ""}`}
                >
                  <div
                    style={{
                      backgroundColor: "#333333",
                      color: "white",
                      border: "0",
                    }}
                  >
                    <div
                      className="d-flex justify-content-between p-3"
                      id="contained-modal-title-vcenter"
                      style={{ fontSize: "16px", lineHeight: "24px" }}
                    >
                      <div>
                        {" "}
                        {this.state.editData
                          ? "Edit Account Details"
                          : "Add Account Details"}{" "}
                      </div>
                      <h5
                        style={{ cursor: "pointer" }}
                        onClick={() => this.setState({ show: false })}
                      >
                        <i className="uil-multiply"></i>
                      </h5>
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <div style={{ margin: "20px" }}>
                        <p className="text_tra1">
                          Please enter your valid bank account details.
                        </p>
                        <div className="row" style={{ margin: "20px 0px" }}>
                          <div className="col-sm-12 my-2">
                            <label
                              className="label"
                              htmlFor=""
                              placeholder="Enter name"
                            >
                              Name
                            </label>
                            <div>
                              {" "}
                              <input
                                type="text"
                                className="input"
                                placeholder="Name"
                                name="user_name"
                                value={this.state.account.user_name}
                                onChange={this.handleChange}
                                onBlur={this.blurChange}
                                maxLength="20"
                              />
                            </div>
                            <div className="text-danger">
                              {this.state.errors.user_name[0] &&
                              this.state.dirty.user_name
                                ? this.state.errors.user_name
                                : ""}
                            </div>
                          </div>
                          <div className="col-sm-12 my-2">
                            <label className="label" htmlFor="">
                              Account Number
                            </label>
                            <div className="d-flex">
                              <input
                                className="input"
                                type="text"
                                placeholder="Enter Account Number"
                                name="account_number"
                                value={this.state.account.account_number}
                                onChange={this.handleChange}
                                onBlur={this.blurChange}
                                minLength="11"
                                maxLength="16"
                              />
                            </div>
                            <div className="text-danger">
                              {this.state.errors.account_number[0] &&
                              this.state.dirty.account_number
                                ? this.state.errors.account_number
                                : ""}
                            </div>
                          </div>
                          <div className="col-sm-12 my-2">
                            <label className="label" htmlFor="">
                              IFSC Code
                            </label>
                            <div>
                              {" "}
                              <input
                                type="text"
                                className="input"
                                placeholder="Enter IFSC code"
                                name="ifsc_code"
                                value={this.state.account.ifsc_code}
                                onChange={this.handleChange}
                                onBlur={this.blurChange}
                              />
                            </div>
                            <div className="text-danger">
                              {this.state.errors.ifsc_code[0] &&
                              this.state.dirty.ifsc_code
                                ? this.state.errors.ifsc_code
                                : ""}
                            </div>
                          </div>
                          <div className="col-sm-12 my-2">
                            <label className="label" htmlFor="">
                              Bank Name
                            </label>
                            <div>
                              {" "}
                              <input
                                type="text"
                                className="input"
                                placeholder="Bank Name"
                                name="bank_name"
                                value={this.state.account.bank_name}
                                onChange={this.handleChange}
                                onBlur={this.blurChange}
                                maxLength="25"
                              />
                            </div>
                            <div className="text-danger">
                              {this.state.errors.bank_name[0] &&
                              this.state.dirty.bank_name
                                ? this.state.errors.bank_name
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ textAlign: "center" }}>
                        <button
                          disabled={
                            this.state.account.user_name.length < 1 ||
                            this.state.account.bank_name.length < 1 ||
                            this.state.account.ifsc_code.length < 11 ||
                            this.state.account.account_number.length < 11
                          }
                          style={{
                            backgroundColor:
                              this.state.account.user_name.length < 1 ||
                              this.state.account.bank_name.length < 1 ||
                              this.state.account.ifsc_code.length < 11 ||
                              this.state.account.account_number.length < 11
                                ? "#808080"
                                : "#4DB5C4",
                            border: "none",
                            color: "white",
                          }}
                          onClick={this.saveAccount}
                          className="cont-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activity: state.activity,
  userWallet: state.userWallet,
});

const mapDispatchToProps = (dispatch) => ({
  postActivity: (data) => dispatch(postActivity(data)),
  getUserWalletsById: (data) => dispatch(getUserWalletsById(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(wallet));

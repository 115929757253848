import React, { Component } from "react";
import styles from "../../../assets/css/OnboardingPersonalDetails.module.css";
import Logo from "../../../assets/images/Logo.svg";
import MainHeader from "../mainHeader";
import Check from "../../../assets/images/check1.png";
import _isEmpty from "lodash/isEmpty";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import preview from "../../../assets/images/preview.png";
import download from "../../../assets/images/download1.png";
import moment from "moment";
import {
  getInvoiceDetailsById,
  getInvoiceChanges,
  getInvoiceCreatedByUser,
} from "../../../services/actions/invoiceAction";
import {
  updateCompany,
  getCompany,
} from "../../../services/actions/companyActions";
import { updateInvoice } from "../../../services/actions/invoiceAction";
import { getInvoiceStatus } from "../../../services/actions/invoiceStatusAction";
import { postAlert } from "../../../services/actions/alertActions";

import Modal from "react-bootstrap/Modal";
import { URI } from "../../../constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InvoiceMoreDetail from "./invoiceMoreDetail";
import Progressbar from "./progressBar";
import InvoicesListId from "./invoicesListId";
import Header from "./header";
import ajax from "../../../utils/ajax";
import AlertPopup from "./alertPopup";
import { COMPONENT_TYPE } from "../../../config/string_constants";
import {
  INVOICE_STATUSES_KEY,
  CLIENT_ALERT_TYPES,
} from "../../../config/string_constants";
import { getOfferById } from "../../../services/actions/invoiceOfferAction";
import { getInvoiceForAdmin } from "../../../api/invoiceService";
import InvoiceData from "./invoiceData";
import ApproveInvoice from "./approveInvoice";
import DeclineInvoice from "./declineInvoice";
class InvoiceDocumentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyId: this.props.match.params.cId,
      InvoiceId: this.props.match.params.id,
      request_doc: false,
      invoiceCompany: [],
      invoicedetails: [],
      country: [],
      invoices: [],
      showPdf: false,
      pdfUrl: null,
      selected: false,
      itemId: "",
      selctedInvoice: {},
      documentAlertData: {
        invoice_id: "",
        invoice_ref_no: "",
        invoice_status_id: "",
        invoice_substatus_id: "",
        client_Id: "",
        request_document_name: "",
        request_document_message: "",
      },
      alertData: {
        invoice_id: "",
        invoice_ref_no: "",
        invoice_status_id: "",
        invoice_substatus_id: "",
        client_Id: "",
      },
      company: [],
      req_document: { name: "", message: "" },
      dirty: { name: "", message: "" },
      errors: { name: [], message: [] },
      screen: INVOICE_STATUSES_KEY.INVOICE_DOCUMENT,
      updateSuccess: false,
      alertSuccess: false,
      createdById: "",
    };
  }

  componentDidMount() {
    this.props
      .getInvoiceDetailsById(this.state.InvoiceId)
      .then((result) => {
        this.props.getInvoiceChanges(result);
        this.setState({
          invoicedetails: result,
          selctedInvoice: result,
          company_id: result.buyer_company._id,
          selected: true,
          itemId: result._id,
          createdById: result.created_by_id,
          invoiceCompany: result.client_company,
        });
        getInvoiceForAdmin(result.created_by_id)
          .then((invoices) => {
            //Get company details of particular invoice

            this.setState({
              invoices: invoices.data,
            });
          })
          .catch((err) => {});

        this.props
          .getOfferById(result._id)
          .then((offer) => {
            this.setState({
              tenure: offer.tenure,
            });
          })
          .catch((err) => {});
      })
      .catch((err) => {});

  }

  showData = (item) => {
    this.setState(
      {
        selected: true,
        InvoiceId: item._id,
        itemId: item._id,
        createdById: item.created_by_id,
      },
      () =>
        this.props
          .getInvoiceDetailsById(item.invoice_id)
          .then((result) => {
            this.setState({
              invoicedetails: result,
              company_id: result.company.company_id,
              selected: true,
              itemId: result._id,
              invoiceCompany: result.client_company,
            });
          })
          .catch((err) => {})
    );
  };
  handleClose = () => {
    this.setState({ showPdf: false });
  };
  onPdfShow = (url) => {
    this.setState({
      pdfUrl: url,
      showPdf: true,
    });
  };
  fileChange = (e, item) => {
    this.uploadFile(e.target.files[0], item);
    let files = e.target.files;

    let reader = new FileReader();
    if (e.target.files[0] !== undefined) {
      reader.readAsDataURL(files[0]);
      reader.onLoad = (e) => {};
    }
  };

  uploadFile(file, item) {
    const formData = new FormData();
    formData.append("image", file);
    if (file !== undefined) {
      ajax(
        "POST",
        `${URI}/api/v1/image-upload`,
        {},
        { "content-type": "multipart/form-data" },
        formData
      )
        .then((data) => {})
        .catch((err) => {});
    }
  }
  handleChange = (e) => {
    const { name, value } = e.target;

    let dirty = this.state.dirty;
    dirty[name] = true;

    let req_document = this.state.req_document;

    req_document[name] = value;
    this.setState(
      {
        req_document: req_document,
      },
      this.validate
    );
  };

  blurChange = (e) => {
    const { name, value } = e.target;
    let dirty = this.state.dirty;
    dirty[name] = true;
    this.setState({
      dirty: dirty,
    });
  };

  validate = () => {
    var errors = this.state.errors;
    Object.keys(errors).forEach((control) => {
      errors[control] = [];
      var validEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      switch (control) {
        case "name":
          if (!this.state.req_document[control]) {
            errors[control].push("Name can't be blank");
          }
          break;

        case "message":
          if (!this.state.req_document[control]) {
            errors[control].push("This field is required");
          }
          break;
        default:
          break;
      }
    });
  };
  isValid = () => {
    let valid = true;

    for (let control in this.state.errors) {
      if (this.state.errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };

  sendRequest = (e) => {
    e.preventDefault();
    this.validate();
    var dirty = this.state.dirty;
    Object.keys(dirty).forEach((control) => {
      dirty[control] = true;
    });
    this.setState({ dirty: dirty });

    this.setState({
      documentAlertData: {
        ...this.state.documentAlertData,
        invoice_id: this.state.invoicedetails._id,
        invoice_ref_no: this.state.invoicedetails.invoice_id,
        client_Id: this.state.invoicedetails.created_by_id,
        invoice_status_id: this.state.invoicedetails.invoice_status_id._id,
        request_document_name: this.state.req_document.name,
        request_document_message: this.state.req_document.message,
        request_documet_state: "Upload",
      },
    });

    if (this.isValid()) {
      this.setState(
        {
          alertSuccess: true,
        },
        () => {
          this.props
            .postAlert(this.state.documentAlertData)
            .then((result) => {
              toast.info("Request Sent");
              document
                .getElementById(COMPONENT_TYPE.INVOICE_DOCUMENTATION)
                .click();
              this.setState(
                {
                  alertSuccess: false,
                  request_doc: false,
                  req_document: {
                    ...this.state.req_document,
                    name: "",
                    message: "",
                  },
                },
                () => {
                  this.props
                    .getInvoiceCreatedByUser(this.state.createdById)
                    .then((invoices) => {
                      //Get company details of particular invoice

                      this.setState({
                        invoices: invoices.data,
                      });
                    })
                    .catch((err) => {});
                }
              );
            })
            .catch((err) => {});
        }
      );
    }
  };

  render() {
    return (
      <div>
        <Header />
        <div className="row m-4">
          <div className="col-3">
            <InvoiceMoreDetail
              invoicedetails={this.state.invoicedetails}
              invoiceCompany={this.state.invoiceCompany}
            />
          </div>
          <div className="col-9 overflow-auto">
            <Progressbar
              invoiceId={this.state.InvoiceId}
              invoiceSelected={this.state.selctedInvoice}
              screen={this.state.screen}
            />
          </div>
        </div>
        <div className="row m-2 my-4">
          {this.state.invoices === undefined ? (
            <div>No data Available </div>
          ) : (
            this.state.invoices
              .filter((item) => {
                return item.invoice_id === this.state.invoicedetails.invoice_id;
              })
              .map((item, index) => (
                <div className="col-6" key={index}>
                  <div className="info-one h-100">
                    <div className="tab-content">
                      <div className="tab-pane active" id="tabs-1" role="tabpanel">
                        <InvoiceData
                          invoices={item}
                          tenure={this.state.tenure}
                          onPdfShow={this.onPdfShow}
                        />
                        <div
                          className="tab-pane active"
                          id="tabs-1"
                          role="tabpanel"
                        >
                          <p className="text_tra1 mt-4 mb-2">
                            Documents Collected
                          </p>
                          {item.invoice_documents.length > 0
                            ? item.invoice_documents.map((doc, i) => (
                                <div
                                  key={i}
                                  className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1"
                                >
                                  <h5>{doc.doc_name} </h5>
                                  <h5 className="text-white">
                                    {doc.url !== null ? (
                                      <span>
                                        <a
                                          onClick={() =>
                                            this.onPdfShow(doc.url)
                                          }
                                        >
                                          <img className="mx-3" src={preview} />
                                        </a>
                                        <a
                                          href={doc.url}
                                          style={{ color: "white" }}
                                        >
                                          {" "}
                                          <img
                                            className="mx-3"
                                            src={download}
                                          />
                                        </a>
                                      </span>
                                    ) : (
                                      <span> Pending </span>
                                    )}
                                  </h5>
                                </div>
                              ))
                            : ""}
                        </div>

                        <>
                          <div className="mt-5 text-center">
                            <DeclineInvoice
                              invoice={item}
                              toastMessage="Invoice Documentation Declined"
                              updateType={
                                CLIENT_ALERT_TYPES.INVOICE_DOCUMENTATION_APPROVED
                              }
                              invoiceStatus={this.props.invoiceStatus}
                              currentStatusKey={
                                INVOICE_STATUSES_KEY.INVOICE_DOCUMENT
                              }
                            />
                              <ApproveInvoice
                              invoice={item}
                              nextStatusRoute="pool-governance"
                              updateType={
                                CLIENT_ALERT_TYPES.INVOICE_DOCUMENTATION_APPROVED
                              }
                              toastMessage="Invoice Documentation Approved"
                              invoiceStatus={this.props.invoiceStatus}
                              nextStatusKey={
                                INVOICE_STATUSES_KEY.POOL_GOVERNANCE
                              }
                              currentStatusKey={
                                INVOICE_STATUSES_KEY.INVOICE_DOCUMENT
                              }
                            />
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              ))
          )}
          <div className="col-6">
            <div className="info-one h-100">
              <p className="text_tra1 mb-2">INVOICES</p>
              <InvoicesListId
                invoices={this.state.invoices}
                selected={this.state.selected}
                showData={this.showData}
                itemId={this.state.itemId}
              />

              <div className="text-center mt-5">
                <span
                  onClick={() => this.setState({ request_doc: true })}
                  data-toggle="modal"
                  data-target="#INVOICE_DOCUMENTATION"
                  className="uploadb"
                >
                  Request Document
                </span>
              </div>
            </div>

            <AlertPopup
              onSubmit={this.sendRequest}
              error={this.state.errors}
              dirty={this.state.dirty}
              reqDocument={this.state.req_document}
              onChange={this.handleChange}
              screen={COMPONENT_TYPE.INVOICE_DOCUMENTATION}
            />
          </div>
        </div>
        <Modal show={this.state.showPdf} onHide={this.handleClose}>
          <div style={{ backgroundColor: "#333333", color: "white" }}>
            {this.state.pdfUrl !== null ? (
              this.state.pdfUrl.split(".").pop() === "pdf" ||
              this.state.pdfUrl.split(".").pop() === "PDF" ? (
                <iframe
                  src={`https://docs.google.com/gview?url=${this.state.pdfUrl}&embedded=true`}
                  style={{ width: "50rem", height: "50rem" }}
                  frameBorder="0"
                ></iframe>
              ) : (
                <>
                  <img
                    src={this.state.pdfUrl}
                    style={{ width: "30rem", height: "30rem" }}
                  />
                </>
              )
            ) : (
              <div></div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  invoiceStatus: state.invoiceStatus.status,
  company: state.company,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceChanges: (data) => dispatch(getInvoiceChanges(data)),
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  updateCompany: (data) => dispatch(updateCompany(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  postAlert: (data) => dispatch(postAlert(data)),
  getCompany: (data) => dispatch(getCompany(data)),
  getInvoiceCreatedByUser: (data) => dispatch(getInvoiceCreatedByUser(data)),
  getOfferById: (data) => dispatch(getOfferById(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvoiceDocumentation));

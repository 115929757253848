import React, { Component } from "react";
import styles from "../../../assets/css/OnboardingPersonalDetails.module.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import preview from "../../../assets/images/preview.png";
import download from "../../../assets/images/download1.png";

import {
  getAllInvoices,
  getInvoiceDetailsById,
  getInvoiceChanges,
} from "../../../services/actions/invoiceAction";
import Header from "./header";
import { getCompanyById } from "../../../services/actions/companyActions";
import { updateInvoice } from "../../../services/actions/invoiceAction";
import { getInvoiceStatus } from "../../../services/actions/invoiceStatusAction";
import { postAlert } from "../../../services/actions/alertActions";
import { getCountry } from "../../../services/actions/countryActions";
import Modal from "react-bootstrap/Modal";
import { URI } from "../../../constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InvoiceMoreDetail from "./invoiceMoreDetail";
import Progressbar from "./progressBar";
import defaultlogo from "../../../assets/images/defaultLogo.png";
import ajax from "../../../utils/ajax";
import {
  INVOICE_STATUSES_KEY,
  CLIENT_ALERT_TYPES,
  USER_TYPE,
  KYC_STATUS,
} from "../../../config/string_constants";
import {
  updateCompanyUserStatus,
  getCompanyUsersAPI,
  updateCompanyUserDocument,
} from "../../../api/userService";
import ApproveInvoice from "./approveInvoice";
import DeclineInvoice from "./declineInvoice";
class KYCProcess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceId: this.props.match.params.id,
      companyDetails: false,
      invoiceCompany: [],
      invoicedetails: [],
      companyId: "",
      country: [],
      companyUsers: [],
      directors: [],
      shareholders: [],
      showPdf: false,
      pdfUrl: null,
      showId: "",
      updateSuccess: false,
      alertSuccess: false,
      selectedUser: {},
      KycDeclineId: "",
      alertData: {
        invoice_id: "",
        invoice_ref_no: "",
        invoice_status_id: "",
        invoice_substatus_id: "",
        client_Id: "",
      },
      // kycstatus: [],
      screen: INVOICE_STATUSES_KEY.KYC_PROCESS,
      showApproveButton: false,
    };
  }
  /**
   * Company users
   * @param {*} companyId
   */
  getCompanyUsers = async (companyId) => {
    let companyUsers = await getCompanyUsersAPI(companyId);
    let approveButton = true;
    if (companyUsers.data.success) {
      let directors = companyUsers.data.data.filter(
        (user) => user.user_type === USER_TYPE.DIRECTOR
      );
      let companyShareholders = companyUsers.data.data.filter(
        (user) => user.user_type === USER_TYPE.SHAREHOLDER
      );
      //Show KYC button
      for (let i = 0; i < companyUsers.data.data.length; i++) {
        if (companyUsers.data.data[i].kyc_status !== KYC_STATUS.VERIFIED) {
          approveButton = false;
          break;
        }
      }
      let selectedUser = {};
      if (companyUsers.data.data.length > 0) {
        selectedUser = companyUsers.data.data[0];
      }
      this.setState({
        companyUsers: companyUsers.data.data,
        directors: directors,
        shareholders: companyShareholders,
        showApproveButton: approveButton,
        selectedUser: selectedUser,
      });
    }
  };

  componentDidMount() {
    this.props
      .getInvoiceDetailsById(this.state.invoiceId)
      .then((result) => {
        this.props.getInvoiceChanges(result);
        this.setState({
          invoicedetails: result,
          invoiceCompany: result.client_company,
        });
        //Get users
        this.getCompanyUsers(result.client_company._id);
      })
      .catch((err) => {});

    this.props
      .getCountry()
      .then((result) => {
        this.setState({
          country: result,
        });
      })
      .catch((err) => {});

  }

  onPdfShow = (url) => {
    this.setState({
      showPdf: true,
      pdfUrl: url,
    });
  };

  handleClose = () => {
    this.setState({ showPdf: false });
  };

  /**
   * Show selected user
   * @param {*} item
   */
  showSelectedUser = (item) => {
    this.setState({
      selectedUser: item,
    });
  };

  /**
   * Function to upload file
   * @param {*} e
   */
  fileChange = (e) => {
    this.uploadFile(e.target.files[0], e.target.name);
    let files = e.target.files;

    let reader = new FileReader();
    if (e.target.files[0] !== undefined) {
      reader.readAsDataURL(files[0]);
      reader.onLoad = (e) => {};
    }
  };

  /**
   * Uploads and updates user
   * @param {*} file
   * @param {*} name
   */

  uploadFile(file, name) {
    const formData = new FormData();
    formData.append("image", file);
    if (file !== undefined) {
      ajax(
        "POST",
        `${URI}/api/v1/image-upload`,
        {},
        { "content-type": "multipart/form-data" },
        formData
      )
        .then((data) => {
          let selectedUser = {
            user_id: this.state.selectedUser._id,
            company_id: this.state.selectedUser.company_id,
            name: name,
            url: data.data.imageUrl,
          };
          this.setState(
            {
              updateSuccess: true,
            },
            () => {
              updateCompanyUserDocument(selectedUser)
                .then((result) => {
                  if (result.data.success) {
                    toast.info("Document Updated");
                    this.setState({
                      updateSuccess: false,
                    });
                  } else {
                    toast.error("Document update failed");
                  }
                })
                .catch((err) => {});
            }
          );
        })
        .catch((err) => {});
    }
  }

  /**
   * Updates company users status
   * @param {*} data
   */

  updateCompanyUserStatus = (data) => {
    let reqData = {
      user_id: data._id,
      company_id: data.company_id,
      status: KYC_STATUS.VERIFIED,
    };
    this.setState(
      {
        updateSuccess: true,
      },
      () => {
        updateCompanyUserStatus(reqData)
          .then((result) => {
            if (result.data.success) {
              toast.info("KYC Verified");
              this.setState({
                updateSuccess: false,
              });
              this.getCompanyUsers(data.company_id);
            }
          })
          .catch((err) => {});
      }
    );
  };

  render() {
    return (
      <div>
        <Header />
        <div className="row m-4">
          <div className="col-3">
            <InvoiceMoreDetail
              invoicedetails={this.state.invoicedetails}
              invoiceCompany={this.state.invoiceCompany}
            />
          </div>
          <div className="col-9 overflow-auto">
            <Progressbar
              invoiceId={this.state.invoiceId}
              screen={this.state.screen}
            />
          </div>
        </div>
        <div className="row m-2 my-4">
          <div className="col-6">
            <div className="info-one h-100">
              <div className="tab-content">
                {this.state.selectedUser === undefined ? (
                  <div className="tab-pane active" id="tabs-1" role="tabpanel">
                    <p className="text_tra1 mb-2">No data Available</p>
                  </div>
                ) : (
                  <div className="tab-pane active" id="tabs-1" role="tabpanel">
                    <p className="text_tra1 mb-2">Documents to upload</p>
                    <div className="mt-4 bgc-dark p-2">
                      <div className="d-flex justify-content-between text_tra1">
                        <h5>Know your Customer </h5>
                        <h5 className="text-white">
                          {
                            <span>
                              {this.state.selectedUser.kyc_status ===
                              KYC_STATUS.VERIFIED ? (
                                <span className="txt_g">
                                  <i className="uil-comment-verify" />
                                  {this.state.selectedUser.kyc_status}
                                </span>
                              ) : this.state.selectedUser.kyc_status ===
                                KYC_STATUS.DECLINED ? (
                                <span className="txt_g">
                                  <i className="uil-comment-verify" />
                                  {this.state.selectedUser.kyc_status}
                                </span>
                              ) : (
                                <span className="txt_y">
                                  <i className="uil-info-circle" />
                                  {this.state.selectedUser.kyc_status}
                                </span>
                              )}
                            </span>
                          }
                        </h5>
                      </div>
                      <div className="row  mt-2 text_tra1">
                        <div className="col-sm-4">
                          <label style={{ display: "block" }}>Full Name</label>
                          {this.state.selectedUser.full_name}
                        </div>
                        <div className="col-sm-4">
                          <label style={{ display: "block" }}>Email</label>
                          {this.state.selectedUser.email}
                        </div>
                        <div className="col-sm-4">
                          <label style={{ display: "block" }}>
                            Phone Number
                          </label>
                          {this.state.selectedUser.user_dialCode}
                          {this.state.selectedUser.phone_number}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                      <h5>
                        <span
                          className="mt-1 link "
                          data-tooltip="Utility bills dated for less than 3 months"
                        >
                          POA (Proof of Address){" "}
                          <i className="uil-info-circle text_tra1" />
                        </span>
                      </h5>
                      <h5 className="text-white">
                        {this.state.selectedUser.poa_url ? (
                          <span>
                            <a
                              onClick={() =>
                                this.onPdfShow(this.state.selectedUser.poa_url)
                              }
                            >
                              <img className="mx-3" src={preview} />
                            </a>
                            <a
                              href={this.state.selectedUser.poa_url}
                              style={{ color: "white" }}
                            >
                              {" "}
                              <img className="mx-3" src={download} />
                            </a>
                            <label className="uploadb header-input mx-3">
                              Reupload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="poa_url"
                                onChange={this.fileChange}
                              />
                            </label>
                          </span>
                        ) : (
                          <span>
                            <label className="uploadb header-input mx-3">
                              Upload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="poa_url"
                                onChange={this.fileChange}
                              />
                            </label>
                            <label>Pending</label>
                          </span>
                        )}
                      </h5>
                    </div>
                    <div className="mt-4 bgc-dark p-2 d-flex justify-content-between text_tra1">
                      <h5>Passport/National ID</h5>
                      <h5 className="text-white">
                        {this.state.selectedUser.poi_url ? (
                          <span>
                            <a
                              onClick={() =>
                                this.onPdfShow(this.state.selectedUser.poi_url)
                              }
                            >
                              <img className="mx-3" src={preview} />
                            </a>
                            <a
                              href={this.state.selectedUser.poi_url}
                              style={{ color: "white" }}
                            >
                              {" "}
                              <img className="mx-3" src={download} />
                            </a>
                            <label className="uploadb header-input mx-3">
                              Reupload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="poi_url"
                                onChange={this.fileChange}
                              />
                            </label>
                          </span>
                        ) : (
                          <span>
                            <label className="uploadb header-input mx-3">
                              Upload{" "}
                              <input
                                type="file"
                                style={{
                                  display: "btn btn-outline-primary col_erone",
                                }}
                                name="poi_url"
                                onChange={this.fileChange}
                              />
                            </label>
                            <label>Pending</label>
                          </span>
                        )}
                      </h5>
                    </div>
                    {/* Show approve button */}
                    {this.state.showApproveButton ? (
                        <>
                          <div className="mt-5 text-center">
                            <DeclineInvoice
                              invoice={this.state.invoicedetails}
                              toastMessage="KYC Declined"
                              updateType={CLIENT_ALERT_TYPES.KYC_APPROVED}
                              invoiceStatus={this.props.invoiceStatus}
                              currentStatusKey={INVOICE_STATUSES_KEY.KYC_PROCESS}
                            />
                            <ApproveInvoice
                              invoice={this.state.invoicedetails}
                              nextStatusRoute="invoice-document"
                              updateType={CLIENT_ALERT_TYPES.KYC_APPROVED}
                              toastMessage="KYC Approved"
                              nextStatusKey={INVOICE_STATUSES_KEY.INVOICE_DOCUMENT}
                              invoiceStatus={this.props.invoiceStatus}
                              currentStatusKey={INVOICE_STATUSES_KEY.KYC_PROCESS}
                            />
                          </div>
                        </>
                      
                    ) : (
                      <div className="mt-5 text-center">
                        <label>
                          <h4>
                            "Please approve directors and shareholders to
                            continue"
                          </h4>
                        </label>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Left user section */}
          <div className="col-6">
            <div className="info-one h-100">
              <p className="text_tra1 mb-2">Directors</p>
              {this.state.directors !== undefined ? (
                this.state.directors.map((item, i) => (
                  <div className="nav info-nav flex-column " role="tablist">
                    <div
                      key={i}
                      className="d-flex  p-2 my-1 bgc-dark"
                      id="circle1"
                      onClick={() => {
                        this.showSelectedUser(item);
                      }}
                    >
                      <div
                        className={
                          this.state.selectedUser &&
                          this.state.selectedUser._id === item._id
                            ? "circle mt-2 ml-2 active"
                            : "circle mt-2 ml-2"
                        }
                        id="circle1"
                      ></div>
                      <a
                        className="nav-link text-white "
                        data-toggle="tab"
                        href="#tabs-1"
                        id="circle1"
                        role="tab"
                      >
                        {item.full_name}
                      </a>

                      <span className="ml-auto">
                        {item.kyc_status == KYC_STATUS.VERIFIED ? (
                          <span className="txt_g">
                            <i className="uil-comment-verify" />
                            {item.kyc_status}
                          </span>
                        ) : item.kyc_status == KYC_STATUS.DECLINED ? (
                          <span className="txt_red">{item.kyc_status}</span>
                        ) : (
                          <span className="txt_y">
                            <i className="uil-info-circle" />
                            <button
                              className="btn-info p-2"
                              onClick={() => this.updateCompanyUserStatus(item)}
                            >
                              Verify
                            </button>
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text_tra1 mb-2">No Directors</p>
              )}

              <br />
              <p className="text_tra1 mb-2">Shareholders</p>

              {this.state.shareholders !== undefined ? (
                this.state.shareholders.map((item, i) => (
                  <div className="nav info-nav flex-column " role="tablist">
                    <div
                      key={i}
                      className="d-flex  p-2 my-1 bgc-dark"
                      id="circle1"
                      onClick={() => {
                        this.showSelectedUser(item);
                      }}
                    >
                      <div
                        className={
                          this.state.selectedUser &&
                          this.state.selectedUser._id === item._id
                            ? "circle mt-2 ml-2 active"
                            : "circle mt-2 ml-2"
                        }
                        id="circle1"
                      ></div>
                      <a
                        className="nav-link text-white "
                        data-toggle="tab"
                        href="#tabs-1"
                        id="circle1"
                        role="tab"
                      >
                        {item.full_name}
                      </a>

                      <span className="ml-auto">
                        {item.kyc_status == KYC_STATUS.VERIFIED ? (
                          <span className="txt_g">
                            <i className="uil-comment-verify" />
                            {item.kyc_status}
                          </span>
                        ) : item.kyc_status == KYC_STATUS.DECLINED ? (
                          <span className="txt_red">{item.kyc_status}</span>
                        ) : (
                          <span className="txt_y">
                            <i className="uil-info-circle" />
                            <button
                              className="btn-info p-2"
                              onClick={() => this.updateCompanyUserStatus(item)}
                            >
                              Verify
                            </button>
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text_tra1 mb-2">No Shareholders</p>
              )}

              <div style={{ marginTop: "8rem" }} className="p-3  bgc-dark">
                <div className="d-flex ">
                  <h5
                    className={
                      !this.state.companyDetails
                        ? "txt_b pb-1 border_b mx-2"
                        : "text_tra1 mx-2"
                    }
                    onClick={() => this.setState({ companyDetails: false })}
                  >
                    Company Details
                  </h5>
                  <h5
                    className={
                      !this.state.companyDetails
                        ? " text_tra1 mx-2"
                        : "txt_b mx-2 pb-1 border_b"
                    }
                    onClick={() => this.setState({ companyDetails: true })}
                  >
                    Company Address
                  </h5>
                </div>

                {!this.state.companyDetails ? (
                  this.state.invoiceCompany ? (
                    <div>
                      <div className="row mt-4  text_tra1">
                        <div className="col-4">
                          Company Name{" "}
                          <h6 className="text-white">
                            {this.state.invoiceCompany.company_name}
                          </h6>
                        </div>
                        <div className="col-4">
                          Company Email
                          <h6 className="text-white">
                            {this.state.invoiceCompany.company_email_id}
                          </h6>
                        </div>
                        <div className="col-4">
                          Company Website
                          <h6 className="text-white">
                            {this.state.invoiceCompany.website}
                          </h6>
                        </div>
                      </div>
                      <div className="row mt-4  text_tra1">
                        <div className="col-4">
                          Phone Number
                          <h6 className="text-white">
                            {this.state.invoiceCompany.company_dialCode}{" "}
                            {this.state.invoiceCompany.company_phone_no}
                          </h6>
                        </div>
                        <div className="col-4">
                          IEC number
                          <h6 className="text-white">
                            {this.state.invoiceCompany.company_iec_number}
                          </h6>
                        </div>
                        <div className="col-4">
                          Logo
                          <h6 className="text-white">
                            <img
                              alt="img"
                              width="50px"
                              height="50px"
                              src={
                                this.state.invoiceCompany.company_logo === ""
                                  ? defaultlogo
                                  : this.state.invoiceCompany.company_logo
                              }
                            />
                          </h6>
                        </div>
                      </div>
                      <div className="row mt-4  text_tra1">
                        <div className="col-4">
                          About Company
                          <h6 className="text-white">
                            {this.state.invoiceCompany.about}
                          </h6>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  <div className="p-3  bgc-dark">
                    <div className="row mt-4  text_tra1">
                      <div className="col-4">
                        Company Address{" "}
                        <h6 className="text-white">
                          {this.state.invoiceCompany.address_line1}
                        </h6>
                      </div>
                      <div className="col-4">
                        City
                        <h6 className="text-white">
                          {this.state.invoiceCompany.city}
                        </h6>
                      </div>
                      <div className="col-4">
                        Country
                        <h6 className="text-white">
                          {this.state.country !== undefined
                            ? this.state.country
                                .filter(
                                  (c) =>
                                    c._id ===
                                    this.state.invoiceCompany.country_id
                                )
                                .map((c) => c.name)
                            : ""}
                        </h6>
                      </div>
                    </div>
                    <div className="row mt-4  text_tra1">
                      <div className="col-4">
                        Zip code
                        <h6 className="text-white">
                          {this.state.invoiceCompany.zipcode}
                        </h6>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showPdf} onHide={this.handleClose}>
          <div style={{ backgroundColor: "#333333", color: "white" }}>
            {this.state.pdfUrl !== null ? (
              this.state.pdfUrl.split(".").pop() === "pdf" ||
              this.state.pdfUrl.split(".").pop() === "PDF" ? (
                <iframe
                  src={`https://docs.google.com/gview?url=${this.state.pdfUrl}&embedded=true`}
                  style={{ width: "50rem", height: "50rem" }}
                  frameBorder="0"
                ></iframe>
              ) : (
                <>
                  <img
                    src={this.state.pdfUrl}
                    style={{ width: "30rem", height: "30rem" }}
                  />
                </>
              )
            ) : (
              <div></div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  invoiceStatus: state.invoiceStatus.status,
  company: state.company,
  country: state.country,
  user: state.user,
  kycStatus: state.kycStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceChanges: (data) => dispatch(getInvoiceChanges(data)),
  getInvoiceDetailsById: (data) => dispatch(getInvoiceDetailsById(data)),
  getInvoiceStatus: (data) => dispatch(getInvoiceStatus(data)),
  getAllInvoices: (data) => dispatch(getAllInvoices(data)),
  getCountry: (data) => dispatch(getCountry(data)),
  getCompanyById: (data) => dispatch(getCompanyById(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  postAlert: (data) => dispatch(postAlert(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(KYCProcess));

import * as COIN_CONSTANTS from "../constants/coinConstant";
import ajax from "../../utils/ajax";

export const requestGetCoin = () => ({
  type: COIN_CONSTANTS.HOME_GET_COIN_CONSTANTS.REQUEST,
});

export const recievedGetCoinResponse = (data) => ({
  type: COIN_CONSTANTS.HOME_GET_COIN_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetCoinError = (err) => ({
  type: COIN_CONSTANTS.HOME_GET_COIN_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const getCoins = () => async (dispatch) => {
  dispatch(requestGetCoin());
  try {
    let url = COIN_CONSTANTS.HOME_GET_COIN_CONSTANTS.URL;

    const res = await ajax("GET", url, {});
    if (res) {
      dispatch(recievedGetCoinResponse(res.data));

      return Promise.resolve(res.data);
    }
  } catch (err) {
    dispatch(recievedGetCoinError(err));
    return Promise.reject(err);
  }
};

import {URI} from '../../constant'

export const HOME_GET_USER_ACCOUNT_BY_ID_CONSTANTS = ({
    URL: URI+"/user-account/",
    REQUEST: 'REQUEST_GET_USER_ACCOUNT_BY_ID_CONSTANTS',
    RECEIVED: 'RECEIVED_GET_USER_ACCOUNT_BY_ID_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_GET_USER_ACCOUNT_BY_ID_CONSTANTS',
  });

  export const HOME_POST_USER_ACCOUNT_CONSTANTS = ({
    URL: URI+"/user-account/",
    REQUEST: 'REQUEST_POST_USER_ACCOUNT_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_POST_USER_ACCOUNT_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_POST_USER_ACCOUNT_DATA_CONSTANTS',
  });
  export const HOME_UPDATE_USER_ACCOUNT_CONSTANTS = ({
    URL: URI+"/user-account/",
    REQUEST: 'REQUEST_UPDATE_USER_ACCOUNT_DATA_CONSTANTS',
    RECEIVED: 'RECEIVED_UPDATE_USER_ACCOUNT_DATA_CONSTANTS',
    RECEIVED_ERROR: 'ERROR_UPDATE_USER_ACCOUNT_DATA_CONSTANTS',
  });
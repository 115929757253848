const UNEXPECTED_ERROR = "Unexpected error occurred at server.";
const INVALID_OBJECT_ID = "Invalid object";
const USER_TYPE = {
  CLIENT: "CLIENT",
  LENDER: "LENDER",
  ADMIN: "ADMIN",
  BUYER: "BUYER",
  DIRECTOR: "DIRECTOR",
  SHAREHOLDER: "SHAREHOLDER",
};
const STATUSES = {
  VERIFIED: "VERIFIED",
  ACTIVE: "ACTIVE",
};
const COMPONENT_TYPE = {
  INVOICE_MORE_DETAIL: "INVOICE_MORE_DETAIL",
  INVOICE_DOCUMENTATION: "INVOICE_DOCUMENTATION",
};
const DATA_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};
const INVOICE_STATUSES = {
  SUBMITTED: "Invoice Submitted",
  ACTIVE: "ACTIVE",
  EXPIRED: "EXPIRED",
  OFFER_MADE: "Offer made",
  COMPANY_DOCUMENTATION: "Company Documentation",
  KYC: "KYC",
  INVOICE_DOCUMENTATION: "Invoice Documentation",
  POOL_GOVERNANCE: "Pool Governance ( Coming Soon )",
  FINAL_OFFER: "Final Offer",
  ARPA: "ARPA",
  FINAL_INVOICE_VERIFICATION: "Final Invoice Verification",
  ADVANCE_FUND_ALLOCATION: "Advance Fund Allocation",
  BUYER_PAYMENT: "Buyer Payment",
  RESERVE_FUND_ALLOCATION: "Reserve Fund Allocation",
  TRADE_CLOSED: "Trade closed",
};
const INVOICE_SUB_STATUSES = {
  DECLINED: "Declined",
  IN_PROGRESS: "In Progress",
  DONE: "Done",
  CREATED: "Created",
  EXPIRED: "Expired",
};
const KYC_STATUS = {
  KYC_STATUS: "Yet to be Verified",
  PENDING: "PENDING",
  NOT_STARTED: "NOT_STARTED",
  VERIFIED: "VERIFIED",
  VALIDATED: "VALIDATED",
  FINAL_REJECTED: "FINAL_REJECTED",
  DECLINED: "DECLINED",
};
const REFERRAL_LINK = {
  REFERRAL_LINK:
    "http://polytrade.s3-website.ap-south-1.amazonaws.com/#/Borrower/?reflink=",
};
const LENDER_V2_LINK = "https://www.polytrade.app/";
const ZENDESK = {
  ZENDESK_LINK:
    "https://static.zdassets.com/ekr/snippet.js?key=6a9a1cd2-8b45-46d7-be80-adab70f04e48",
  ZENDESK_ID: "ze-snippet",
};
const WALLET_ACTIVATION = {
  YES: "YES",
  NO: "NO",
  ACTIVE: "ACTIVE",
  PENDING: "PENDING",
};
const REWARD_STATUS = {
  CLAIM: "CLAIM",
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
  SENT: "SENT",
  SEND_REWARDS: "SEND REWARDS",
};
const ACCOUNT_TYPE = { PRIMARY: "PRIMARY" };
const LEND_NOW_STATUS = {
  ACTIVE: "ACTIVE",
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
  CLOSED: "CLOSED",
  IN_PROGRESS: "IN_PROGRESS",
};
const INVOICE_STATUSES_KEY = {
  OFFER_MADE: "offer-made",
  COMPANY_DOCUMENT: "company-document",
  KYC_PROCESS: "kyc-process",
  INVOICE_DOCUMENT: "invoice-document",
  POOL_GOVERNANCE: "pool-governance",
  FINAL_OFFER: "final-offer",
  ARPA: "arpa",
  INVOICE_VERIFICATION_BY_BUYER: "invoice-verification-by-buyer",
  ADVANCED_FUND_ALLOCATION: "advance-fund-allocation",
  COLLECTION: "collection",
  RESERVE_FUND_ALLOCATION: "reserve-fund-allocation",
  HISTORY_BOOK: "history-book",
  INVOICE_DETAILS: "invoice-details",
};
const ADMIN_ALERT_TYPES = {
  INVOICE_SUBMITED: "INVOICE_SUBMITED",
  OFFER_ACCEPTED: "OFFER_ACCEPTED",
  COMPANY_DOCUMENT: "COMPANY_DOCUMENT",
  KYC: "KYC",
  INVOICE_DOCUMENTATION: "INVOICE_DOCUMENTATION",
  FINAL_OFFER: "FINAL_OFFER",
  ARPA: "ARPA",
  BANK_ACCOUNT: "BANK_ACCOUNT",
};
const CLIENT_ALERT_TYPES = {
  OFFER_MADE: "OFFER_MADE",
  OFFER_CREATED: "OFFER_CREATED",
  COMPANY_DOCUMENTATION_APPROVED: "COMPANY_DOCUMENTATION_APPROVED",
  INVOICE_DOCUMENTATION_APPROVED: "INVOICE_DOCUMENTATION_APPROVED",
  POOL_GOVERNANCE_APPROVED: "POOL_GOVERNANCE_APPROVED",
  ARPA_APPROVED: "ARPA_APPROVED",
  INVOICE_VERIFICATION_BY_BUYER_APPROVED:
    "INVOICE_VERIFICATION_BY_BUYER_APPROVED",
  KYC_APPROVED: "KYC_APPROVED",
  COLLECTION_APPROVED: "COLLECTION_APPROVED",
};
const BACKEND_CONSTANTS = {
  CONTENT_TYPE_JSON: "application/json",
  CONTENT_TYPE_SVG: "image/svg+xml",
  CONTENT_TYPE_XLSX:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  CONTENT_TYPE_CSV: "text/csv; charset=UTF-8",
  AUTHORIZATION_STRING: "Authorization",
  BEARER_STRING: "bearer ", // Space required do not delete space
  TOKEN_STRING: "id_token",
};

const DETAILS = {
  BUYER_DETAILS: "BUYER DETAILS",
  SOFT_OFFER_DETAILS: "SOFT OFFER DETAILS",
  FINAL_OFFER_DETAILS: "FINAL OFFER DETAILS",
  PRICING_DETAILS: "PRICING DETAILS",
  SELLER_DETAILS: "SELLER DETAILS",
};

module.exports = {
  UNEXPECTED_ERROR,
  INVALID_OBJECT_ID,
  DATA_STATUS,
  USER_TYPE,
  STATUSES,
  LENDER_V2_LINK,
  KYC_STATUS,
  REFERRAL_LINK,
  INVOICE_STATUSES,
  INVOICE_SUB_STATUSES,
  WALLET_ACTIVATION,
  REWARD_STATUS,
  ACCOUNT_TYPE,
  LEND_NOW_STATUS,
  ZENDESK,
  COMPONENT_TYPE,
  INVOICE_STATUSES_KEY,
  BACKEND_CONSTANTS,
  ADMIN_ALERT_TYPES,
  CLIENT_ALERT_TYPES,
  DETAILS,
};

import React, { Component } from "react";
import Sidebar from "./Dashboard/Sidebar";
import { Card, Row, Col } from "react-bootstrap";
import ajax from "../../utils/ajax";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import Mainheader from "./Dashboard/mainHeader";
import { getPoolHistoryForAdmin } from "../../api/poolService";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/wallet.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { URI } from "../../constant";
import CONSTANTS from "../../utils/constant";
import tradelogo from "../../assets/images/polytrade.svg";
import dollerbluelogo from "../../assets/images/doller_blue.svg";
import totalclintlogo from "../../assets/images/totalclient.svg";
import bluetradelogo from "../../assets/images/bluepolytrade.svg";
import "../../assets/css/homepage.css";
import "../../assets/css/common/fontStyle.css";
import "../../assets/css/common/button.css";
import helper from "../../utils/helper";

import Kycpopup from "./Common/kycpopup";
import Rewardpopup from "./Common/Reward_soon";
import KycPendingModal from "./Common/kycPending";
import LendNowpopup from "./Common/lendnow";
import ActiveLendingpopup from "./Common/viewMore";
import Bottomnav from "./Dashboard/bottomnav";
import MobHeader from "./Dashboard/MobHeader";
import { getCoins } from "../../services/actions/coinActions";
import { LEND_NOW_STATUS, KYC_STATUS } from "../../config/string_constants";
import { createLendNow, getAllLendDetails } from "../../api/lendNowService";
import { getPoolByUserId } from "../../api/poolService";
import Paginations from "../pagination";
import V2Banner from "./Common/v2Banner";

class home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      poolsHistory: [],
      show: false,
      Lendingpool: [],
      ActiveLendingpool: [],
      paginationItem: [],
      paginationActiveItem: [],
      editData: false,
      UserReward: 0,
      userTokenBalance: "",
      walletLimit: false,
      validateAmount: false,
      validateError: "",
      UserIntrestEarn: 0,
      selectedMonth: "",
      loading: false,
      modalShow: sessionStorage.getItem("ShowKycPopup") === "true",
      modelRewardshow: false,
      modalKycPending: false,
      modelLendNowshow: false,
      modelactiveLendingshow: false,
      reward: 0,
      rewardDaller: 0,
      use_price: 0,
      ActiveLendingDetails: {},
      selectedCoinPrice: 0,
      dashboardData: {},
      dashboardLoader: true,
      lendDetails: {},
      lendNowdata: {},
      searchLending: "",
      ActionToPerform: sessionStorage.getItem("ActionToPerform"),
      isLoggedIn: sessionStorage.getItem("isLoggedIn"),
      kycStatus: sessionStorage.getItem("kycStatus"),
      balance_amount: 0,
      UserBalance: 0,
      amountError: false,
      headerMessage: "",
      mainMessage: "",
      walletData: "",
      walletAddress: sessionStorage.getItem("wallet_Id")
        ? sessionStorage.getItem("wallet_Id")
        : "",
      coinData: {
        apy_month: "",
        percentage: "",
        bonus_apy: "",
        is_active: false,
      },
      disableButton: true,
    };
  }

  /**
   * Function to calculate sum of given array values
   * round off to two decimal
   */

  calclulateSum = (valueArray) => {
    if (valueArray.length > 0) {
      let sum = valueArray.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      return Number(sum).toFixed(2);
    }
    return 0;
  };
  activeLandingPageChange = async (index) => {
    let response = await getAllLendDetails(
      sessionStorage.getItem("userId"),
      index
    );
    if (response) {
      this.setState({
        ActiveLendingpool: response.data.data,
        paginationActiveItem: response.data,
      });
    }
  };
  pageChange = async (index) => {
    let result = await getPoolHistoryForAdmin(index);
    if (result) {
      this.setState({
        poolsHistory: result.data.data,
        paginationItem: result.data,
      });
    }
  };

  numberWithCommas = (x) => {
    return Number(x)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  getPoolHistory = async () => {
    let result = await getPoolHistoryForAdmin();
    if (result) {
      this.setState({
        poolsHistory: result.data.data,
        paginationItem: result.data,
      });
    }
  };

  getDashboardData = async () => {
    try {
      const dashboardResponseData = await ajax(
        "GET",
        `${URI}/lender/dashboard`,
        {
          limit: this.state.limit,
          offset: this.state.offset,
        }
      );

      this.setState({
        dashboardData: dashboardResponseData.data,
        dashboardLoader: false,
      });
    } catch (error) { }
  };

  ActiveLending = async () => {
    try {
      let response = await getAllLendDetails(sessionStorage.getItem("userId"));
      if (response) {
        this.setState({
          ActiveLendingpool: response.data.data,
          paginationActiveItem: response.data,
        });
      }
    } catch (error) { }
  };

  claimReward = async () => {
    this.claimRewards();
  };
  showKycPendgin = async () => {
    this.setState({
      modalKycPending: true,
      headerMessage: "KYC Inprogress",
      mainMessage:
        "Please wait for KYC verification. After Verified you can proceed further.",
      // amount:this.state.reward
    });
  };

  hideReward = async () => {
    this.setState({
      modelRewardshow: false,
      // amount:this.state.reward
    });
  };
  hideKycPending = async () => {
    this.setState({
      modalKycPending: false,
      // amount:this.state.reward
    });
  };

  getCoinGecko = async () => {
    try {
      const poolCoinGecko = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=polytrade&vs_currencies=USD"
      );
      let usdPrice = this.state.reward * poolCoinGecko.data.polytrade.usd;
      this.setState({
        rewardDaller: usdPrice,
        use_price: poolCoinGecko.data.polytrade.usd,
      });
    } catch (error) { }
  };

  /**
   * Realtime value for coins
   */
  getCoinPrice = async () => {
    try {
      const selectedCoin = this.state.ActiveLendingDetails.pool_id.coin_id.id;
      const coinPrice = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${selectedCoin}&vs_currencies=usd`
      );
      let currentPrice = coinPrice.data[selectedCoin].usd;
      this.setState({
        selectedCoinPrice: currentPrice,
      });
    } catch (error) { }
  };

  activeLendNow = async (id, data) => {
    document.getElementById("showViewmoreModal").style.display = "block";
    this.setState(
      {
        modelactiveLendingshow: true,
        ActiveLendingDetails: data,
      },
      () => {
        this.getCoinPrice();
      }
    );
  };

  claimRewards = async () => {
    if (this.state.reward === "0" || this.state.reward === 0) {
      alert("No Reward available");
    } else {
      const rewardData = {};

      // const rewardCreateResponse = await axios.post(`${URI}/Rewards/users/${sessionStorage.getItem("userId")}`, rewardData);
      const rewardCreateResponse = await ajax(
        "POST",
        `${URI}/rewards/users/${sessionStorage.getItem("userId")}`,
        {},
        {},
        rewardData
      );

      this.setState(
        {
          modelRewardshow: true,
          reward: 0,
          rewardDaller: 0,
        },
        () => { }
      );
    }
  };

  claimUserBonusApy = (amt, bonusApy) => {
    if (parseFloat(amt) >= parseFloat(bonusApy.minimum_locked_amount)) {
      const bonus_reward_interest =
        (parseFloat(amt) *
          parseFloat(this.state.lendNowdata.bonus_reward.bonus_apy_percentage) *
          1) /
        100;
      const bonus_reward_interest_perDay = bonus_reward_interest / 365;
      const total_bonus_reward_interest =
        parseFloat(bonus_reward_interest_perDay) *
        parseFloat(this.state.lendNowdata.selected_duration);
      let lendData = { ...this.state.lendNowdata };
      lendData.user_bonus_checked = true;
      lendData.total_bonus_reward_interest = total_bonus_reward_interest;
      this.setState({
        lendNowdata: lendData,
      });
    }
  };

  changeLockAmount = async (e) => {
    let amount = e.target.value;
    this.changeAmount(amount);
  };

  validateAmount = (amount) => {
    if (amount < this.state.lendNowdata.minimum_lock_amount) {
      this.setState({
        validateAmount: true,
        validateError: "Amount is lower than minimum lock amount",
      });
    } else if (amount > this.state.lendNowdata.max_amount) {
      this.setState({
        validateAmount: true,
        validateError: "Amount is greater than pool available Amount",
      });
    } else if (amount > this.state.userTokenBalance) {
      this.setState({
        validateAmount: true,
        validateError: "Amount is greater than available Balance",
      });
    } else {
      this.setState({ validateAmount: false });
    }
  };

  changeAmount = (amount) => {
    this.validateAmount(amount);
    const comuntive_intrest =
      (parseFloat(amount) * parseFloat(this.state.lendNowdata.est_apy) * 1) /
      100;
    const bounsIntrestPerYear =
      (parseFloat(amount) * parseFloat(this.state.lendNowdata.bouns_apy) * 1) /
      100;
    const Accure_intrest_perDay = comuntive_intrest / 365;
    const bouns_intrest_perDay = bounsIntrestPerYear / 365;
    const total_accure_intrest =
      parseFloat(Accure_intrest_perDay) *
      parseFloat(this.state.lendNowdata.selected_duration);
    const total_bouns_intrest =
      parseFloat(bouns_intrest_perDay) *
      parseFloat(this.state.lendNowdata.selected_duration);
    let lendData = { ...this.state.lendNowdata };
    lendData.total_accure_intrest = parseFloat(total_accure_intrest);
    lendData.total_bouns_intrest = parseFloat(total_bouns_intrest);
    lendData.total_intrest =
      parseFloat(total_accure_intrest) + parseFloat(total_bouns_intrest);
    lendData.lock_amount = amount;
    lendData.user_bonus_checked = false;
    lendData.total_bonus_reward_interest = 0;
    this.setState({
      amountError: false,
      lendNowdata: lendData,
    });
  };

  agreementChecked = (e) => {
    let lendData = { ...this.state.lendNowdata };
    lendData.tnc_status = e.target.checked;
    this.setState({
      lendNowdata: lendData,
    });
  };

  postLendNow = async () => {
    let data = {
      amount: parseFloat(this.state.lendNowdata.lock_amount),
      pool_id: this.state.lendNowdata.pool_id,
      duration: this.state.lendNowdata.selected_duration,
      user_id: sessionStorage.getItem("userId"),
      tnc_status: true,
      user_bonus_checked: false,
    };
    if (this.state.lendNowdata.user_bonus_checked) {
      data.user_bonus_checked = this.state.lendNowdata.user_bonus_checked;
    }
    if (this.state.lendNowdata.tnc_status) {
      if (
        parseFloat(this.state.lendNowdata.minimum_lock_amount) <= data.amount &&
        data.amount <=
        parseFloat(
          this.state.lendNowdata.max_amount -
          parseFloat(this.state.lendNowdata.total_deposited_amount)
        )
      ) {
        this.setState({ loading: true }, async () => {
          let lendNowCreateResponse = await createLendNow(data);
          if (
            lendNowCreateResponse &&
            lendNowCreateResponse.data.success === true &&
            (lendNowCreateResponse.status === 200 ||
              lendNowCreateResponse.status === 201)
          ) {
            this.getPoolByUserId();
            toast.info(lendNowCreateResponse.message);
            this.ActiveLending();
          } else if (lendNowCreateResponse) {
            toast.error(lendNowCreateResponse.message);
          }

          this.setState({
            loading: false,
            modelLendNowshow: false,
            lendNowdata: {},
          });
        });
      } else {
        if (
          parseFloat(this.state.lendNowdata.minimum_lock_amount) >= data.amount
        ) {
          toast.error("Amount is lower than minimum deposit.");
        } else {
          toast.error("Amount is higher than available amount for the pool.");
        }
      }
    } else {
      toast.error("Term and conditions not accepted");
    }
  };

  lendNow = async (data) => {
    const coinId = data.coin_id._id;
    const response = await ajax(
      "GET",
      `${URI}/coin/user/status/${coinId}`,
      {},
      {}
    );
    if (this.state.walletData === "" || this.state.walletAddress === "") {
      this.setState({
        modalKycPending: true,
        headerMessage: "Wallet Activation Pending",
        mainMessage: "Please create wallet before Lending",
        // amount:this.state.reward
      });
    } else if (!response.data.approved) {
      this.setState({
        modalKycPending: true,
        walletLimit: true,
        headerMessage: "ALERT",
        mainMessage:
          "Users must approve the coin spending limit to allow fund transfer to the Polytrade Smart contract.",
      });
    } else {
      let selectedMonth = parseInt(data.apy_month);
      const lendData = {
        bonus_reward: data.bonus_apy_data,
        total_deposited_amount: data.total_deposited_amount,
        tnc_status: false,
        apy_data: data.apy_data,
        user_balance:
          parseFloat(this.state.UserBalance) *
          parseFloat(data.coin_id.current_price),
        bouns_apy: parseFloat(data.bonus_apy),
        selected_duration: selectedMonth,
        coin_id: data.coin_id,
        est_apy: parseFloat(data.percentage),
        max_amount: data.max_amount,
        minimum_lock_amount: data.minimum_locked_amount,
        pool_id: data._id,
        duration: selectedMonth,
        lock_amount: 0,
        total_amount: 0,
        accrue_days: 2,
        total_accure_intrest: 0,
        total_bouns_intrest: 0,
        total_bonus_reward_interest: 0,
        total_intrest: 0,
        cumulative_interest: 0,
        accure_interest_per_day: 0,
        redemption_date: moment()
          .add(parseFloat(selectedMonth) + 1, "days")
          .format("D MMM  YYYY, h:mm")
          .toString(),
        status: LEND_NOW_STATUS.PENDING,
        subscription_date: moment().format("D MMM  YYYY, h:mm").toString(),
        interest_end_date: moment()
          .add(selectedMonth, "days")
          .format("D MMM  YYYY, h:mm")
          .toString(),
        interest_date: moment()
          .add(1, "days")
          .format("D MMM  YYYY, h:mm")
          .toString(),
        est_next_distribution_amount: "19.00",
        interest_period: 1,
        balance_amount: 0,
        coins: [],
        lender_address: this.state.walletData
          ? this.state.walletData.address
          : "",
      };
      this.setState({
        modelLendNowshow: true,
        lendNowdata: lendData,
        selectedMonth: selectedMonth,
      });
    }
  };
  getReward = async () => {
    try {
      const rewardData = ajax(
        "GET",
        `${URI}/rewards/users/${sessionStorage.getItem("userId")}`,
        {}
      );

      this.setState({
        reward: rewardData.data[0].reward ? rewardData.data[0].reward : 0,
      });
    } catch (error) { }
  };
  getPoolByUserId = async () => {
    let response = await getPoolByUserId(sessionStorage.getItem("userId"));
    if (response) {
      this.setState({
        Lendingpool: response.data.data,
      });
    }
  };
  componentDidMount() {
    this.getWalletDetails();
    this.getPoolByUserId();
    this.ActiveLending();
    this.getPoolHistory();
    this.getCoinGecko();
    this.getDashboardData();
  }

  tokenBal = (data) => {
    this.setState({ userTokenBalance: data });
  };

  fetchBalance = async () => {
    try {
      const walletData = await ajax(
        "GET",
        `${URI}/user/wallet/balance/${this.state.walletAddress}`,
        {
          limit: this.state.limit,
          offset: this.state.offset,
        }
      );
      if (walletData.data.success) {
        this.setState({
          UserBalance: walletData.data.data.balance
            ? walletData.data.data.balance
            : 0,
        });
      }
    } catch (error) { }
  };

  kycNow = () => {
    this.props.history.push("/lender/profile");
  };
  hidekycNow = () => {
    sessionStorage.setItem("ShowKycPopup", false);
    this.setState({
      modalShow: false,
    });
  };

  ShowKyc = () => {
    this.setState({
      modalShow: true,
    });
  };

  handleSearch = (e) => {
    this.setState({
      searchLending: e.target.value,
    });
  };
  getWalletDetails = async () => {
    try {
      const userData = await ajax(
        "GET",
        `${URI}/user/wallet/${sessionStorage.getItem("userId")}`,
        {}
      );

      const data = userData.data.result ? userData.data.result : {};
      this.setState({
        disableButton: false,
        walletData: data,
      });
    } catch (e) { }
  };

  render() {
    return (
      <>
        <div>
          <Sidebar />
          <Bottomnav />
          <div className="main-content  main-content-lender">
            <MobHeader />
            <header className="disp-sm-none">
              <h2 className="heading" style={{ marginLeft: "10px" }}></h2>
              <Mainheader />
            </header>
            <div className="text-white p-4" style={{ backgroundColor: "#382527" }}>
              <i className="uil-info-circle mx-2" />
              {CONSTANTS.MESSAGES.DISCLAIMER}
            </div>
            <ToastContainer
              position="top-center"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Kycpopup
              hidekycNow={this.hidekycNow}
              kycNow={this.kycNow}
              show={this.state.modalShow}
              onHide={() => this.setState({ modalShow: false })}
            />
            <Rewardpopup
              amount={this.state.reward}
              rewardDaller={this.state.rewardDaller}
              claimRewards={this.claimRewards}
              hideReward={this.hideReward}
              show={this.state.modelRewardshow}
              onHide={() => this.setState({ modelRewardshow: false })}
            />
            <KycPendingModal
              headerMessage={this.state.headerMessage}
              mainMessage={this.state.mainMessage}
              claimRewards={this.claimRewards}
              hideReward={this.hideKycPending}
              walletLimit={this.state.walletLimit}
              show={this.state.modalKycPending}
              onHide={() => this.setState({ modalKycPending: false })}
            />

            {this.state.modelLendNowshow ? (
              <LendNowpopup
                amountError={this.state.amountError}
                changeLockAmount={this.changeLockAmount}
                changeAmount={this.changeAmount}
                tokenBal={this.tokenBal}
                validateAmount={this.state.validateAmount}
                validateError={this.state.validateError}
                postLendNow={this.postLendNow}
                agreementChecked={this.agreementChecked}
                lendNowdata={this.state.lendNowdata}
                loading={this.state.loading}
                claimUserBonusApy={this.claimUserBonusApy}
                show={this.state.modelLendNowshow}
                onHide={() => {
                  this.setState({ modelLendNowshow: false });
                }}
              />
            ) : (
              ""
            )}
            <ActiveLendingpopup
              ActiveLendingDetails={this.state.ActiveLendingDetails}
              show={this.state.modelactiveLendingshow}
              onHide={() => this.setState({ modelactiveLendingshow: false })}
              selectedCoinPrice={this.state.selectedCoinPrice}
            />

            <div className="container-fluid pt-2 pr-4 pl-4 pb-2">
              <div className="d-flex justify-content-between">
                <div className=" headingmob">
                  <h2 className="heading text-white  mt-3">Market Overview</h2>
                </div>
                <div className=" mt-3">
                  {this.state.isLoggedIn === "true" ? (
                    <div className="input-group d-flex justify-content-end ">
                      <div
                        className=" text-right  div-border pt-1 pl-1 d-none"
                        style={{
                          borderBottomLeftRadius: "4px",
                          borderTopLeftRadius: "4px",
                        }}
                      >
                        <label className="font-style-2 mr-2">
                          Available Reward:{" "}
                        </label>
                        <label className="font-style-2 mr-2">
                          {this.numberWithCommas(this.state.reward)}{" "}
                        </label>
                        <img
                          src={tradelogo}
                          className="tradelogo mr-2"
                          alt="tradelogo"
                        />
                      </div>
                      <div className="input-group-append d-none">
                        <div
                          style={{
                            backgroundColor: "#2DA3B4",
                            padding: "4px",
                            color: "white",
                            borderBottomRightRadius: "4px",
                            borderTopRightRadius: "4px",
                          }}
                          onClick={this.claimReward}
                        >
                          Claim
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <a
                    target="_blank"
                    href="https://v2.info.uniswap.org/token/0x6e5970dbd6fc7eb1f29c6d2edf2bc4c36124c0c1"
                    rel="noreferrer"
                  >
                    <button className="btn-blue">Buy TRADE Token</button>
                  </a>
                </div>
              </div>

              <Row className="mt-3">
                <Col lg="4" sm="6" className="mt-2">
                  <div>
                    <Card.Body className="bgc-dark pl-4 ">
                      <div className="row  ml-1 d-flex">
                        <img
                          src={dollerbluelogo}
                          className="tradelogo mr-2"
                          alt="dollarblue"
                        />
                        <h4
                          className="acc-item mt-1 link font-style-3 "
                          data-tooltip="Total value of invoices funded via Polytrade platform (USD)"
                        >
                          Invoices Funded{" "}
                          <i className="uil-info-circle text_tra1 " />
                        </h4>
                      </div>

                      <div className="row ml-1 d-flex  ">
                        <label className="font-style-4 mr-2 custom-lable">
                          {!this.state.dashboardLoader ? (
                            <>
                              <span>$</span>
                              {this.numberWithCommas(
                                this.state.dashboardData.totalBorrowAmount
                              )}
                            </>
                          ) : (
                            <div>
                              <div
                                className="spinner-border text-info ml-5 loader-sm"
                                role="status"
                              ></div>
                              <div className="font-12 text_tra1">
                                Loading data from blockchain{" "}
                              </div>
                            </div>
                          )}
                        </label>
                        <label className="font-style-5 div-align-bottom d-none">
                          +2.22%
                        </label>
                      </div>
                      <div className="row mt-1 d-none  ml-1">
                        <label className="font-style-6 mt-3">
                          Total Invoice Funded
                        </label>
                      </div>
                      <div className="row mt-1 ml-1 d-none">
                        <label className="font-style-7">
                          $
                          {this.numberWithCommas(
                            this.state.dashboardData.totalDepositAmount
                              ? this.state.dashboardData.totalDepositAmount
                              : 0
                          )}
                        </label>
                      </div>
                    </Card.Body>
                  </div>
                </Col>
                <Col lg="4" sm="6" className="mt-2">
                  <div>
                    <Card.Body className="bgc-dark pl-4">
                      <div className="row  ml-1 d-flex">
                        <img
                          src={totalclintlogo}
                          className="tradelogo mr-2"
                          alt="total"
                        />
                        <h4
                          className="acc-item mt-1 link font-style-3 "
                          data-tooltip="Total size of funds available in lenders pool (USD)"
                        >
                          Current Deposit{" "}
                          <i className="uil-info-circle text_tra1" />
                        </h4>
                      </div>
                      <div className="row ml-1 d-flex">
                        <label className="font-style-4 mr-2">
                          {!this.state.dashboardLoader ? (
                            <>
                              <span>$</span>
                              {CONSTANTS.CURRENT_DEPOSIT}
                            </>
                          ) : (
                            <div>
                              <div
                                className="spinner-border text-info ml-5 loader-sm"
                                role="status"
                              ></div>
                              <div className="font-12 text_tra1">
                                Loading data from blockchain{" "}
                              </div>
                            </div>
                          )}
                        </label>
                        <label className="font-style-5 div-align-bottom d-none">
                          +0.91%
                        </label>
                      </div>
                      <div className="row mt-1  d-none ml-1">
                        <label className="font-style-6 mt-3">
                          Total Invoice Submitted
                        </label>
                      </div>
                      <div className="row mt-1 d-none ml-1">
                        <label className="font-style-7">
                          {this.state.dashboardData.totalInvoiceSubmited
                            ? this.state.dashboardData.totalInvoiceSubmited
                            : 0}
                        </label>
                      </div>
                    </Card.Body>
                  </div>
                </Col>

                <Col lg="4" sm="6" className="mt-2">
                  <div>
                    <Card.Body className="bgc-dark pl-4 ">
                      <div className="row  ml-1 d-flex">
                        <img
                          src={bluetradelogo}
                          className="tradelogo mr-2"
                          alt="bluetrade"
                        />
                        <h4
                          className="acc-item link  font-style-3"
                          data-tooltip="Current Price of TRADE. "
                        >
                          Trade Price{" "}
                          <i className="uil-info-circle text_tra1" />
                        </h4>
                      </div>
                      <div className="row ml-1 d-flex">
                        <label className="font-style-4 mr-2">
                          ${this.state.use_price ? this.state.use_price : 0}
                        </label>
                      </div>
                      <div className="row mt-1 d-none  ml-1">
                        <label className="font-style-6 mt-3">
                          Total Interest Earned on deposit
                        </label>
                      </div>
                      <div className="row d-none mt-1 ml-1">
                        <label className="font-style-7 mr-2">
                          $
                          {this.state.dashboardData.totalIntrestEarn
                            ? this.state.dashboardData.totalIntrestEarn
                            : "0"}
                        </label>
                        <label className="font-style-5 div-align-bottom d-none">
                          +5%
                        </label>
                      </div>
                    </Card.Body>
                  </div>
                </Col>
              </Row>
              <Row>
                <h2 className="heading text-white  mt-5">
                  <Col> All Market </Col>
                </h2>
              </Row>
              <Row>
                <Col className="p-0">
                  <div className="tab" role="tabpanel">
                    <ul className="nav  nav-tabs" role="tablist">
                      <li role="presentation" className="active mt-2 pro_mone1">
                        <a
                          style={{ textDecoration: "none" }}
                          href="#Sections1"
                          aria-controls="home"
                          role="tab"
                          data-toggle="tab"
                        >
                          Lend Now
                        </a>
                      </li>
                      <li role="presentation" className="pro_mone2 mt-2">
                        {this.state.isLoggedIn === "true" ? (
                          <a
                            style={{ textDecoration: "none" }}
                            href="#Sections2"
                            aria-controls="profile"
                            role="tab"
                            data-toggle="tab"
                          >
                            Active Lending
                          </a>
                        ) : (
                          <a
                            href
                            style={{ textDecoration: "none" }}
                            onClick={() => {
                              this.setState({
                                modalKycPending: true,
                                headerMessage: "Login",
                                mainMessage:
                                  "Please Login for access the facility.",
                                // amount:this.state.reward
                              });
                            }}
                            aria-controls="profile"
                          >
                            Active Lending
                          </a>
                        )}
                      </li>
                      <li role="presentation" className=" mt-2 pro_mone1">
                        <a
                          style={{ textDecoration: "none" }}
                          href="#Sections3"
                          aria-controls="home"
                          role="tab"
                          data-toggle="tab"
                        >
                          Pool History
                        </a>
                      </li>

                      <div className=" d-flex searchL ">
                        <span className="w-100 justify-content-end   mr-4 search d-flex ">
                          <i
                            style={{
                              position: "absolute",
                              margin: "8px 10px 7px auto",
                              color: "#fff",
                            }}
                            className="uil-search-alt"
                          ></i>

                          <input
                            className="homesearchbox"
                            onChange={this.handleSearch}
                            value={this.state.searchLending}
                            type="text"
                            placeholder="Search coin name...."
                          />
                        </span>
                      </div>
                    </ul>

                    <div className="tab-content">
                      <div
                        role="tabpanel"
                        className="tab-pane  active"
                        id="Sections1"
                      >
                        <div className=" card-plain table-plain-bg">
                          <div className="table-full-width table-responsive px-0">
                            <table className="border-0 custom-table">
                              <thead className="disp_non_sm">
                                <tr className="text_tra1 ">
                                  <th>Coins</th>
                                  <th></th>
                                  <th title="Currently APR. APY will be incorporated when redeployment of funds is enabled in subsequent versions">
                                    APY{" "}
                                    <i className="uil-info-circle text_tra1" />
                                  </th>
                                  {/* <th title="Name of pool.">
                                    Pool Name{" "}
                                    <i className="uil-info-circle text_tra1" />
                                  </th> */}
                                  <th title="Duration when deposits receive rewards. Deposited funds cannot be withdrawn during this duration.">
                                    Duration (Days){" "}
                                    <i className="uil-info-circle text_tra1" />
                                  </th>
                                  <th>Minimum Locked Amount</th>
                                  <th title="Amount of funds deposited to the pool">
                                    Filled Amount{" "}
                                    <i className="uil-info-circle text_tra1" />
                                  </th>
                                  <th title="Maximum size of funds that can be deposited to the pool">
                                    Pool Size{" "}
                                    <i className="uil-info-circle text_tra1" />
                                  </th>
                                  <th>End Date</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* {this.state.Lendingpool.map((coin,index) =>( */}
                                {this.state.Lendingpool.filter((data) => {
                                  //Calculate total percentage
                                  let userApy =
                                    data.bonus_apy_data &&
                                      data.bonus_apy_data.tenure ===
                                      parseInt(data.apy_month)
                                      ? parseFloat(
                                        data.bonus_apy_data
                                          .bonus_apy_percentage
                                      )
                                      : 0;
                                  //Formed array to calculate;
                                  let totalApy = this.calclulateSum([
                                    userApy,
                                    parseFloat(data.bonus_apy),
                                    parseFloat(data.percentage),
                                  ]);
                                  data.total_calculate_apy = totalApy;
                                  if (data.is_active) {
                                    return data.coin_id.name
                                      .toLowerCase()
                                      .includes(
                                        this.state.searchLending.toLowerCase()
                                      );
                                  }
                                }).map((coin, index) => (
                                  <tr className="section mt-1 disp-sm-none">
                                    <td className="d-flex my-4 pt-2 sec-css">
                                      <img
                                        className="mt-2 ml-2"
                                        src={coin.coin_id.image}
                                        width="25px"
                                        height="25px"
                                        alt="imgx"
                                      />
                                      <div className="ml-2">
                                        <div className="font-1">
                                          {coin.coin_id.name}
                                        </div>
                                        <label className="font-2 p-0 m-0  text-uppercase">
                                          {coin.coin_id.symbol}
                                        </label>
                                      </div>
                                    </td>
                                    <td className="sec-css">
                                      <label className="font-style-10 p-0 mt-2 mx-2 font-style-Averta-Semibold">
                                        {coin.total_calculate_apy
                                          ? coin.total_calculate_apy
                                          : 0}
                                        %
                                      </label>
                                    </td>
                                    <td className="sec-css">
                                      <>
                                        <div>
                                          <label
                                            className="font-2 p-0 my-2"
                                            id={`bouus_day_apy${index}`}
                                          >
                                            Standard APY{" "}
                                            <span className="text-white">
                                              {Number(coin.percentage)
                                                .toFixed(2)
                                                .replace(
                                                  /\d(?=(\d{3})+\.)/g,
                                                  "$&,"
                                                )}
                                              %
                                            </span>
                                          </label>
                                        </div>
                                        <div>
                                          <label
                                            className="font-2 p-0  my-2"
                                            id={`bouus_day_apy${index}`}
                                          >
                                            Bonus APY{" "}
                                            <span className="text-white">
                                              {Number(coin.bonus_apy)
                                                .toFixed(2)
                                                .replace(
                                                  /\d(?=(\d{3})+\.)/g,
                                                  "$&,"
                                                )}
                                              %
                                            </span>
                                          </label>
                                        </div>
                                        <div>
                                          {coin.bonus_apy_data ? (
                                            <label
                                              className="font-2 p-0  my-2"
                                              id={`bouus_day_apy${index}`}
                                            >
                                              User Bonus APY{" "}
                                              <span className="text-white">
                                                {Number(
                                                  coin.bonus_apy_data
                                                    .bonus_apy_percentage
                                                )
                                                  .toFixed(2)
                                                  .replace(
                                                    /\d(?=(\d{3})+\.)/g,
                                                    "$&,"
                                                  )}
                                                %
                                              </span>
                                            </label>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </>
                                    </td>

                                    {/* <td className="sec-css">
                                      <div>
                                        <span className="text-white">
                                          {coin.pool_name}
                                        </span>
                                      </div>
                                    </td> */}
                                    <td className="sec-css">
                                      <div>
                                        <span className="text-white mx-5">
                                          {coin.apy_month}
                                        </span>
                                      </div>
                                    </td>

                                    <td className="sec-css">
                                      {" "}
                                      <label className="font-style-11 mx-5 font-style-Averta-Semibold">
                                        {this.numberWithCommas(
                                          coin.minimum_locked_amount
                                        )}{" "}
                                        {coin.coin_id
                                          ? coin.coin_id.symbol.toUpperCase()
                                          : ""}
                                      </label>
                                    </td>
                                    <td className="sec-css">
                                      <>
                                        <label className="font-style-11 pt-3 font-style-Averta-Semibold">
                                          {this.numberWithCommas(
                                            coin.total_deposited_amount
                                          )}{" "}
                                          {coin.coin_id
                                            ? coin.coin_id.symbol.toUpperCase()
                                            : ""}
                                        </label>
                                        <label className="font-style-10 font-style-Averta-Semibold">
                                          {(
                                            (coin.total_deposited_amount /
                                              coin.max_amount) *
                                            100
                                          ).toFixed(2)}
                                          %
                                        </label>
                                      </>
                                    </td>
                                    <td className="sec-css">
                                      {" "}
                                      <label className="font-style-11 p-0 m-0 font-style-Averta-Semibold">
                                        {this.numberWithCommas(coin.max_amount)}{" "}
                                        {coin.coin_id
                                          ? coin.coin_id.symbol.toUpperCase()
                                          : ""}
                                      </label>
                                    </td>
                                    <td className="sec-css">
                                      {" "}
                                      <label className="font-style-11 p-0 m-0 font-style-Averta-Semibold">
                                        {moment(coin.end_date).format(
                                          "DD MMM YYYY"
                                        )}
                                      </label>
                                    </td>
                                    <td className="sec-css">
                                      <button
                                        className="btn-blue w-75 px-3 "
                                        disabled={this.state.disableButton}
                                        onClick={() => {
                                          this.state.isLoggedIn === "true"
                                            ? this.state.ActionToPerform ===
                                              "true"
                                              ? this.lendNow(coin)
                                              : this.state.kycStatus ===
                                                KYC_STATUS.NOT_STARTED
                                                ? this.ShowKyc()
                                                : this.state.kycStatus ===
                                                  KYC_STATUS.PENDING
                                                  ? this.showKycPendgin()
                                                  : this.props.push.history(
                                                    "/lender/profile"
                                                  )
                                            : this.props.history.push(
                                              "/lender/login"
                                            );
                                        }}
                                      >
                                        Lend Now
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {/* Mobile View Start */}
                            <div>
                              {this.state.Lendingpool.filter((data) => {
                                //Calculate total percentage
                                let userApy =
                                  data.bonus_apy_data &&
                                    data.bonus_apy_data.tenure ===
                                    parseInt(data.apy_month)
                                    ? parseFloat(
                                      data.bonus_apy_data.bonus_apy_percentage
                                    )
                                    : 0;
                                //Formed array to calculate;
                                let totalApy = this.calclulateSum([
                                  userApy,
                                  parseFloat(data.bonus_apy),
                                  parseFloat(data.percentage),
                                ]);
                                data.total_calculate_apy = totalApy;
                                if (data.is_active) {
                                  return data.coin_id.name
                                    .toLowerCase()
                                    .includes(
                                      this.state.searchLending.toLowerCase()
                                    );
                                }
                              }).map((coin, index) => (
                                <div className="bgc-dark1 disp-lg-none borderR my-3">
                                  <div className="d-flex justify-content-between p-2 borderw">
                                    <div className="d-flex">
                                      <img
                                        className="mt-2 ml-2"
                                        src={coin.coin_id.image}
                                        width="25px"
                                        height="25px"
                                        alt="img"
                                      />
                                      <div className="ml-2">
                                        <div className="font-1">
                                          {coin.coin_id.name}
                                        </div>
                                        <label className="font-2 p-0 m-0  text-uppercase">
                                          {coin.coin_id.symbol}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="txt_b">
                                      {coin.total_calculate_apy
                                        ? coin.total_calculate_apy
                                        : 0}
                                      % APY
                                    </div>
                                  </div>

                                  <div className="row m-2 ">
                                    <div className=" col-6 font14 pl-1">
                                      <p className="text_tra1">
                                        {" "}
                                        Duration (Days)
                                      </p>
                                      <div className="d-flex">
                                        <label className="font-style-11 p-0 m-0 font-style-Averta-Semibold">
                                          {coin.apy_month}
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-6 font-13">
                                      <p className="text_tra1">
                                        Standard APY{" "}
                                        <span className="font-style-11 font-style-Averta-Semibold">
                                          {coin.percentage}%
                                        </span>
                                      </p>
                                      <p className="text_tra1">
                                        Bonus APY{" "}
                                        <span className="font-style-11 font-style-Averta-Semibold">
                                          {coin.bonus_apy}%
                                        </span>
                                      </p>
                                      {coin.bonus_apy_data ? (
                                        <p className="text_tra1">
                                          User Bonus APY{" "}
                                          <span className="font-style-11 font-style-Averta-Semibold">
                                            {Number(
                                              coin.bonus_apy_data
                                                .bonus_apy_percentage
                                            )
                                              .toFixed(2)
                                              .replace(
                                                /\d(?=(\d{3})+\.)/g,
                                                "$&,"
                                              )}
                                            %
                                          </span>
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="row m-2">
                                    <div className="pl-1 col-6 font14">
                                      <p className="text_tra1"> Pool Size</p>
                                      <div className="d-flex">
                                        <label className="font-style-11 p-0 m-0 font-style-Averta-Semibold">
                                          {this.numberWithCommas(
                                            coin.max_amount
                                          )}{" "}
                                          {coin.coin_id
                                            ? coin.coin_id.symbol.toUpperCase()
                                            : ""}
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-6 font14">
                                      <p className="text_tra1">
                                        {" "}
                                        Filled Amount
                                      </p>

                                      <div className="txt_b">
                                        {this.state.isLoggedIn === "true" ? (
                                          <div>
                                            {" "}
                                            <label className="font-style-11 p-0 m-0 font-style-Averta-Semibold">
                                              {this.numberWithCommas(
                                                coin.total_deposited_amount
                                              )}{" "}
                                              {coin.coin_id
                                                ? coin.coin_id.symbol.toUpperCase()
                                                : ""}
                                            </label>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="txt_b">
                                        {this.state.isLoggedIn === "true" ? (
                                          <div>
                                            {" "}
                                            <label className="font-style-11 p-0 m-0 font-style-Averta-Semibold">
                                              {(
                                                (coin.total_deposited_amount /
                                                  coin.max_amount) *
                                                100
                                              ).toFixed(2)}
                                              {"%"}
                                            </label>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row m-2">
                                    <div className="col-6 font14 pl-1">
                                      <p className="text_tra1">
                                        Minimum Lock Amount
                                      </p>
                                      <div>
                                        <label className="font-style-11 p-0 m-0 font-style-Averta-Semibold ">
                                          {this.numberWithCommas(
                                            coin.minimum_locked_amount
                                          )}{" "}
                                          {coin.coin_id
                                            ? coin.coin_id.symbol.toUpperCase()
                                            : ""}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6 font14">
                                      <p className="text_tra1">End Date</p>
                                      <div className="d-flex">
                                        <label className="font-style-11 p-0 m-0 font-style-Averta-Semibold">
                                          {moment(coin.end_date).format(
                                            "DD MMM YYYY"
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="m-2  text-center">
                                    <button
                                      className="w-100 my-2 btn-blue "
                                      onClick={() => {
                                        this.state.isLoggedIn === "true"
                                          ? this.state.ActionToPerform ===
                                            "true"
                                            ? this.lendNow(coin)
                                            : this.state.kycStatus ===
                                              KYC_STATUS.NOT_STARTED
                                              ? this.ShowKyc()
                                              : this.state.kycStatus ===
                                                KYC_STATUS.PENDING
                                                ? this.showKycPendgin()
                                                : this.props.push.history(
                                                  "/lender/profile"
                                                )
                                          : this.props.history.push(
                                            "/lender/login"
                                          );
                                      }}
                                    >
                                      Lend Now
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>

                            {/* Mobile View End*/}
                          </div>
                        </div>
                      </div>
                      <div
                        role="tabpanel"
                        className="tab-pane fade"
                        id="Sections2"
                      >
                        <div className="card-plain table-plain-bg">
                          <div className="table-full-width table-responsive px-0">
                            <table className="border-0 custom-table">
                              <thead className="disp_non_sm">
                                <tr className="text_tra1 ">
                                  <th>Coins</th>
                                  <th></th>
                                  <th title="Currently APR. APY will be incorporated when redeployment of funds is enabled in subsequent versions">
                                    APY{" "}
                                    <i className="uil-info-circle text_tra1" />
                                  </th>
                                  {/* <th>Pool Name</th> */}
                                  <th>Total Amount</th>
                                  <th>Accrued Days</th>
                                  <th>Accrued Interest</th>
                                  <th>Bonus Interest</th>
                                  <th>Redemption Date</th>

                                  <th>Status</th>
                                  <th></th>
                                </tr>
                              </thead>

                              <tbody className="disp_non_sm">
                                {this.state.ActiveLendingpool.filter((data) => {
                                  return (
                                    data.pool_id.coin_id.name
                                      ? data.pool_id.coin_id.name
                                      : ""
                                  )
                                    .toLowerCase()
                                    .includes(
                                      (this.state.searchLending
                                        ? this.state.searchLending
                                        : ""
                                      ).toLowerCase()
                                    );
                                }).map((data) => (
                                  <tr className="section">
                                    <td className="d-flex sec-css my-4">
                                      <img
                                        className="mt-2"
                                        src={data.pool_id.coin_id.image}
                                        width="25px"
                                        height="25px"
                                        alt="img"
                                      />
                                      <div className="ml-2 ">
                                        <div className="font-1">
                                          {data.pool_id.coin_id.name}{" "}
                                        </div>
                                        <label className="font-2 p-0 m-0  text-uppercase">
                                          {data.pool_id.coin_id.symbol}{" "}
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text_tra1">
                                        <label className="font-style-10 p-0 m-0">
                                          {this.numberWithCommas(
                                            data.est_apy +
                                            data.bonus_apy +
                                            data.user_bonus_rewards
                                          )}{" "}
                                          %
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text_tra1">
                                        Standard APY{" "}
                                        <span className="text-white">
                                          {Number(data.est_apy)
                                            .toFixed(2)
                                            .replace(
                                              /\d(?=(\d{3})+\.)/g,
                                              "$&,"
                                            )}
                                          %
                                        </span>
                                      </div>
                                      <div className="text_tra1 my-3">
                                        Bonus APY{" "}
                                        <span className="text-white">
                                          {Number(data.bonus_apy)
                                            .toFixed(2)
                                            .replace(
                                              /\d(?=(\d{3})+\.)/g,
                                              "$&,"
                                            )}
                                          %
                                        </span>
                                      </div>
                                      {data.user_bonus_rewards !== 0 ? (
                                        <div className="text_tra1">
                                          User APY{" "}
                                          <span className="text-white">
                                            {Number(data.user_bonus_rewards)
                                              .toFixed(2)
                                              .replace(
                                                /\d(?=(\d{3})+\.)/g,
                                                "$&,"
                                              )}
                                            %
                                          </span>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    {/* <td className="sec-css">
                                      <div>
                                        <span className="text-white">
                                          {data.pool_id && data.pool_id.pool_name}
                                        </span>
                                      </div>
                                    </td> */}
                                    <td className="sec-css">
                                      <label className="font-style-11 text-uppercase ">
                                        {this.numberWithCommas(
                                          data.lock_amount
                                        )}{" "}
                                        {data.pool_id.coin_id.symbol}{" "}
                                      </label>
                                    </td>
                                    <td className="sec-css">
                                      {" "}
                                      <label className="font-style-11 p-0 m-0">
                                        {data.duration} days{" "}
                                      </label>
                                    </td>
                                    <td className="sec-css">
                                      {" "}
                                      <label className="font-style-12 p-0 m-0 text-uppercase">
                                        {this.numberWithCommas(
                                            helper.calculateInterest(data.duration, data.accure_interest_per_day)
                                        )}{" "}
                                        {data.pool_id.coin_id.symbol}
                                      </label>
                                    </td>
                                    <td className="sec-css">
                                      {" "}
                                      <label className="font-style-12 p-0 m-0 text-uppercase">
                                        {this.numberWithCommas(
                                          helper.calculateInterest(data.duration, data.bonus_interest_per_day)
                                        )}{" "}
                                        {data.pool_id.coin_id.symbol}
                                      </label>
                                    </td>
                                    <td className="sec-css">
                                      {" "}
                                      <label className="font-style-11 p-0 m-0">
                                        {moment(data.redemption_date).format(
                                          "DD MMM  YYYY"
                                        )}
                                      </label>{" "}
                                    </td>

                                    <td className="sec-css">
                                      {" "}
                                      <label
                                        className= "yet-verify p-1 m-1 pl-2 pr-2"
                                      >
                                        Closed
                                      </label>{" "}
                                    </td>
                                    <td
                                      className="sec-css"
                                      onClick={() => {
                                        this.activeLendNow(data._id, data);
                                      }}
                                    >
                                      {" "}
                                      <label className="font-style-5 p-0 m-0">
                                        More
                                      </label>{" "}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>

                              {/* Mobile View  */}

                              {this.state.ActiveLendingpool.filter((data) => {
                                return (
                                  data.pool_id.coin_id.name
                                    ? data.pool_id.coin_id.name
                                    : ""
                                )
                                  .toLowerCase()
                                  .includes(
                                    (this.state.searchLending
                                      ? this.state.searchLending
                                      : ""
                                    ).toLowerCase()
                                  );
                              }).map((data) => (
                                <div className="bgc-dark1 disp-lg-none borderR my-2">
                                  <div className="d-flex justify-content-between p-2 borderw">
                                    <div className="d-flex">
                                      <img
                                        className="mt-2 ml-2"
                                        src={data.pool_id.coin_id.image}
                                        width="25px"
                                        height="25px"
                                        alt="img"
                                      />
                                      <div className="ml-2">
                                        <div className="font-1">
                                          {data.pool_id.coin_id.name}
                                        </div>
                                        <label className="font-2 p-0 m-0  text-uppercase">
                                          {data.pool_id.coin_id.symbol}
                                        </label>
                                      </div>
                                    </div>

                                    <div>
                                      <label
                                        className={
                                          data.status ===
                                            LEND_NOW_STATUS.PENDING
                                            ? "in-progress p-1 m-1 pl-2 pr-2"
                                            : data.status ===
                                              LEND_NOW_STATUS.ACTIVE
                                              ? "sucess p-1 m-1 pl-2 pr-2"
                                              : "yet-verify p-1 m-1 pl-2 pr-2"
                                        }
                                      >
                                        {data.status}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="row p-2 ">
                                    <div className="col-6 font14">
                                      <p className="text_tra1">Total Amount</p>
                                      <div>
                                        <label className="font-style-11 text-uppercase ">
                                          {this.numberWithCommas(
                                            data.lock_amount
                                          )}{" "}
                                          {data.pool_id.coin_id.symbol}{" "}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6 font-13">
                                      <p className="text_tra1">
                                        Standard APY{" "}
                                        <span className="text-white">
                                          {this.numberWithCommas(data.est_apy)}%
                                        </span>
                                      </p>
                                      <p className="text_tra1">
                                        Bonus APY{" "}
                                        <span className="text-white">
                                          {Number(data.bonus_apy)
                                            .toFixed(2)
                                            .replace(
                                              /\d(?=(\d{3})+\.)/g,
                                              "$&,"
                                            )}
                                          %
                                        </span>
                                      </p>
                                      {data.user_bonus_rewards !== 0 ? (
                                        <p className="text_tra1">
                                          User APY{" "}
                                          <span className="text-white">
                                            {Number(data.user_bonus_rewards)
                                              .toFixed(2)
                                              .replace(
                                                /\d(?=(\d{3})+\.)/g,
                                                "$&,"
                                              )}
                                            %
                                          </span>
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="row p-2 ">
                                    <div className="col-6 font14">
                                      <p className="text_tra1">Accrued Days</p>
                                      <div>
                                        <label className="font-style-12  p-0  text-uppercase">
                                          {data.duration} days{" "}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6 font14">
                                      <p className="text_tra1">
                                        Bonus Interest
                                      </p>

                                      <div>
                                        <label className="font-style-11 text-uppercase p-0 m-0">
                                          {this.numberWithCommas(
                                            helper.calculateInterest(data.duration, data.bonus_interest_per_day)
                                          )}{" "}
                                          {data.pool_id.coin_id.symbol}
                                        </label>{" "}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row p-2 ">
                                    <div className="col-6 font14">
                                      <p className="text_tra1">
                                        Accrued Interest
                                      </p>
                                      <div>
                                        <label className="font-style-12 p-0 m-0 text-uppercase">
                                          {this.numberWithCommas(
                                            helper.calculateInterest(data.duration, data.accure_interest_per_day)
                                          )}{" "}
                                          {data.pool_id.coin_id.symbol}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6 font14">
                                      <p className="text_tra1">
                                        Redemption Date
                                      </p>

                                      <div>
                                        <label className="font-style-11 p-0 m-0">
                                          {moment(data.redemption_date).format(
                                            "DD MMM  YYYY"
                                          )}
                                        </label>{" "}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="m-3 text-center">
                                    <label
                                      className="uploadb w-100 p-2"
                                      onClick={() => {
                                        this.activeLendNow(data._id, data);
                                      }}
                                    >
                                      More Details
                                    </label>
                                  </div>
                                </div>
                              ))}
                              {/* mob view end */}
                            </table>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end mx-4 mt-2">
                          <Paginations
                            item={this.state.paginationActiveItem}
                            pageClick={this.activeLandingPageChange}
                          />
                        </div>
                      </div>
                      <div role="tabpanel" className="tab-pane" id="Sections3">
                        <div className="table-full-width table-responsive px-0">
                          <table className="border-0">
                            <thead className="disp_non_sm">
                              <tr className="text_tra1 ">
                                <th className="pl-2">Coin Name</th>
                                <th></th>
                                <th className="pl-2">APY</th>
                                {/* <th className="pl-2">Pool Name</th> */}
                                <th className="pl-2">Duration (Days)</th>
                                <th className="pl-2">Filled Amount</th>
                                <th className="pl-2">Pool Limit</th>
                                <th className="pl-2">End Date</th>
                                <th className="pl-2">Status</th>
                                <th></th>
                              </tr>
                            </thead>

                            <tbody className="disp_non_sm">
                              {this.state.poolsHistory === undefined ? (
                                <tr>No data available</tr>
                              ) : (
                                this.state.poolsHistory.map((index, key) => (
                                  <tr key={key} className="sec1">
                                    <td className="d-flex sec-css my-4">
                                      <img
                                        className="mt-2"
                                        src={index.coin_id.image}
                                        width="25px"
                                        height="25px"
                                        alt="img"
                                      />
                                      <div className="ml-2 ">
                                        <div className="font-1">
                                          {index.coin_id.name}{" "}
                                        </div>
                                        <label className="font-2 p-0 m-0  text-uppercase">
                                          {index.coin_id.symbol}{" "}
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text_tra1">
                                        <label className="font-style-10 p-0 m-0">
                                          {this.numberWithCommas(
                                            index.bonus_apy + index.percentage
                                          )}{" "}
                                          %
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text_tra1">
                                        Standard APY{" "}
                                        <span className="text-white">
                                          {Number(index.percentage)
                                            .toFixed(2)
                                            .replace(
                                              /\d(?=(\d{3})+\.)/g,
                                              "$&,"
                                            )}
                                          %
                                        </span>
                                      </div>
                                      <div className="text_tra1 my-3">
                                        Bonus APY{" "}
                                        <span className="text-white">
                                          {Number(index.bonus_apy)
                                            .toFixed(2)
                                            .replace(
                                              /\d(?=(\d{3})+\.)/g,
                                              "$&,"
                                            )}
                                          %
                                        </span>
                                      </div>
                                    </td>
                                    {/* <td className="sec-css">
                                      <div>
                                        <span className="text-white">
                                          {index.pool_name}
                                        </span>
                                      </div>
                                    </td> */}
                                    <td>{index.apy_month}</td>

                                    <td>
                                      {index.total_deposited_amount}{" "}
                                      {index.coin_id
                                        ? index.coin_id.symbol.toUpperCase()
                                        : ""}
                                    </td>
                                    <td>
                                      {index.max_amount}{" "}
                                      {index.coin_id
                                        ? index.coin_id.symbol.toUpperCase()
                                        : ""}
                                    </td>
                                    <td>
                                      {" "}
                                      {moment(index.end_date).format(
                                        "DD MMM YYYY"
                                      )}
                                    </td>
                                    <td>Closed</td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                            {/* mob view start */}
                            {this.state.poolsHistory === undefined ? (
                              <tr>No data available</tr>
                            ) : (
                              this.state.poolsHistory.map((index, key) => (
                                <div className="bgc-dark1 disp-lg-none borderR my-2">
                                  <div className="d-flex justify-content-between p-2 borderw">
                                    <div className="d-flex">
                                      <img
                                        className="mt-2 ml-2"
                                        src={index.coin_id.image}
                                        width="25px"
                                        height="25px"
                                        alt="img"
                                      />
                                      <div className="ml-2">
                                        <div className="font-1">
                                          {index.coin_id.name}
                                        </div>
                                        <label className="font-2 p-0 m-0  text-uppercase">
                                          {index.coin_id.symbol}
                                        </label>
                                      </div>
                                    </div>

                                    <div className="mr-3">
                                      <label>Closed</label>
                                    </div>
                                  </div>
                                  <div className="row p-2 ">
                                    <div className="col-6 font14">
                                      <p className="text_tra1">
                                        Duration (Days)
                                      </p>
                                      <div>
                                        <label className="font-style-11 text-uppercase ">
                                          {index.apy_month}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6 font-13">
                                      <p className="text_tra1">
                                        Standard APY{" "}
                                        <span className="text-white">
                                          {Number(index.bonus_apy)
                                            .toFixed(2)
                                            .replace(
                                              /\d(?=(\d{3})+\.)/g,
                                              "$&,"
                                            )}
                                          %
                                        </span>
                                      </p>
                                      <p className="text_tra1">
                                        Bonus APY{" "}
                                        <span className="text-white">
                                          {Number(index.percentage)
                                            .toFixed(2)
                                            .replace(
                                              /\d(?=(\d{3})+\.)/g,
                                              "$&,"
                                            )}
                                          %
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row p-2 ">
                                    <div className="col-6 font14">
                                      <p className="text_tra1">Filled Amount</p>
                                      <div>
                                        <label className="font-style-11  p-0  text-uppercase">
                                          {index.total_deposited_amount}{" "}
                                          {index.coin_id
                                            ? index.coin_id.symbol.toUpperCase()
                                            : ""}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6 font14">
                                      <p className="text_tra1">Pool limit</p>

                                      <div>
                                        <label className="font-style-11 text-uppercase p-0 m-0">
                                          {index.max_amount}{" "}
                                          {index.coin_id
                                            ? index.coin_id.symbol.toUpperCase()
                                            : ""}
                                        </label>{" "}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row p-2 ">
                                    <div className="col-6 font14">
                                      <p className="text_tra1">End Date</p>
                                      <div>
                                        <label className="font-style-11 p-0 m-0 text-uppercase">
                                          {moment(index.end_date).format(
                                            "DD MMM YYYY"
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            )}
                            {/* mob view end */}
                          </table>
                          <div className="d-flex justify-content-end mx-4 mt-2">
                            <Paginations
                              item={this.state.paginationItem}
                              pageClick={this.pageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div style={{ margin: "1px" }}>
                <V2Banner kycMigration />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userAccount: state.userAccount,
  activity: state.activity,
  userWallet: state.userWallet,
});

const mapDispatchToProps = (dispatch) => ({
  getCoins: (data) => dispatch(getCoins(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(home));

import axios from "axios";
import * as USER_WALLET_CONSTANTS from "../constants/userWalletConstant";
import ajax from "../../utils/ajax";

export const requestGetUserWalletsById = () => ({
  type: USER_WALLET_CONSTANTS.HOME_GET_USER_WALLET_BY_ID_CONSTANTS.REQUEST,
});

export const recievedGetUserWalletsByIdResponse = (data) => ({
  type: USER_WALLET_CONSTANTS.HOME_GET_USER_WALLET_BY_ID_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedGetUserWalletsByIdError = (err) => ({
  type: USER_WALLET_CONSTANTS.HOME_GET_USER_WALLET_BY_ID_CONSTANTS
    .RECEIVED_ERROR,
  errorCode: err,
});

export const getUserWalletsById = (id) => {
  let url = USER_WALLET_CONSTANTS.HOME_GET_USER_WALLET_BY_ID_CONSTANTS.URL + id;

  return (dispatch) => {
    dispatch(requestGetUserWalletsById());

    ajax("GET", url, {})
      .then((res) => dispatch(recievedGetUserWalletsByIdResponse(res.data)))
      .catch((err) => dispatch(recievedGetUserWalletsByIdError(err)));
  };
};

export const requestPostUserWallet = () => ({
  type: USER_WALLET_CONSTANTS.HOME_POST_USER_WALLET_CONSTANTS.REQUEST,
});

export const recievedPostUserWalletResponse = (data) => ({
  type: USER_WALLET_CONSTANTS.HOME_POST_USER_WALLET_CONSTANTS.RECEIVED,
  data,
  receivedAt: Date.now(),
});
export const recievedPostUserWalletError = (err) => ({
  type: USER_WALLET_CONSTANTS.HOME_POST_USER_WALLET_CONSTANTS.RECEIVED_ERROR,
  errorCode: err,
});

export const postUserWallet = (data) => {
  let url = USER_WALLET_CONSTANTS.HOME_POST_USER_WALLET_CONSTANTS.URL;

  return (dispatch) => {
    dispatch(requestPostUserWallet());

    ajax("POST", url, {}, {}, data)
      .then((res) => dispatch(recievedPostUserWalletResponse(res.data)))
      .catch((err) => dispatch(recievedPostUserWalletError(err)));
  };
};

import { URI } from "../../constant";

export const HOME_GET_USER_CONSTANTS = {
  URL: URI + "/user",
  REQUEST: "REQUEST_USER_DATA_CONSTANTS",
  RECEIVED: "RECEIVED_USER_DATA_CONSTANTS",
  RECEIVED_ERROR: "ERROR_USER_DATA_CONSTANTS",
};
export const HOME_POST_USER_CONSTANTS = {
  URL: URI + "/user",
  REQUEST: "REQUEST_HOME_POST_USER_CONSTANTS",
  RECEIVED: "RECEIVED_HOME_POST_USER_CONSTANTS",
  RECEIVED_ERROR: "ERROR_HOME_POST_USER_CONSTANTS",
};

export const HOME_UPDATE_USER_CONSTANTS = {
  URL: URI + "/user/",
  REQUEST: "REQUEST_USER_UPDATE_DATA_CONSTANTS",
  RECEIVED: "RECEIVED_USER_UPDATE_DATA_CONSTANTS",
  RECEIVED_ERROR: "RECEIVED_UPDATE_USER_DATA_CONSTANTS",
};

export const HOME_GET_USER_EMAIL_CONSTANTS = {
  URL: URI + "/user/email/",
  REQUEST: "REQUEST_USER_EMAIL_DATA_CONSTANTS",
  RECEIVED: "RECEIVED_USER_EMAIL_DATA_CONSTANTS",
  RECEIVED_ERROR: "ERROR_USER_EMAIL_DATA_CONSTANTS",
};
export const HOME_POST_INVOICE_CONSTANTS = {
  URL: URI + "/invoice",
  REQUEST: "REQUEST_INVOICE_POST_DATA_CONSTANTS",
  RECEIVED: "RECEIVED_INVOICE_POST_DATA_CONSTANTS",
  RECEIVED_ERROR: "RECEIVED_POST_INVOICE_DATA_CONSTANTS",
};

export const HOME_UPDATE_INVOICE_CONSTANTS = {
  URL: URI + "/invoice/",
  REQUEST: "REQUEST_INVOICE_UPDATE_DATA_CONSTANTS",
  RECEIVED: "RECEIVED_INVOICE_UPDATE_DATA_CONSTANTS",
  RECEIVED_ERROR: "RECEIVED_UPDATE_INVOICE_DATA_CONSTANTS",
};

export const GET_ALL_INVOICE_CONSTANTS = {
  URL: URI + "/invoice",
  REQUEST: "REQUEST_GET_ALL_INVOICE_CONSTANTS",
  RECEIVED: "RECEIVED_GET_ALL_INVOICE_CONSTANTS",
  RECEIVED_ERROR: "RECEIVED_GET_ALL_INVOICE_CONSTANTS",
};

export const HOME_DELETE_INVOICE_CONSTANTS = {
  URL: URI + "/invoice",
  REQUEST: "REQUEST_INVOICE_DELETE_DATA_CONSTANTS",
  RECEIVED: "RECEIVED_INVOICE_DELETE_DATA_CONSTANTS",
  RECEIVED_ERROR: "RECEIVED_DELETE_INVOICE_DATA_CONSTANTS",
};

export const GET_DASHBOARD_CONSTANTS = {
  URL: URI + "/invoice/dash/",
  REQUEST: "REQUEST_DASHBOARD_DATA_CONSTANTS",
  RECEIVED: "RECEIVED_DASHBOARD_DATA_CONSTANTS",
  RECEIVED_ERROR: "RECEIVED_DASHBOARD_DATA_CONSTANTS",
};

export const GET_CREATED_BY_USER_INVOICE_CONSTANTS = {
  URL: URI + "/invoice/user/",
  REQUEST: "REQUEST_CREATED_BY_USER_INVOICE_CONSTANTS",
  RECEIVED: "RECEIVED_CREATED_BY_USER_INVOICE_CONSTANTS",
  RECEIVED_ERROR: "RECEIVED_CREATED_BY_USER_INVOICE_CONSTANTS",
};
export const HOME_DELETE_USER_CONSTANTS = {
  URL: URI + "/user/buyer/",
  REQUEST: "REQUEST_HOME_DELETE_USER_CONSTANTS",
  RECEIVED: "RECEIVED_HOME_DELETE_USER_CONSTANTS",
  RECEIVED_ERROR: "ERROR_HOME_DELETE_USER_CONSTANTS",
};

export const HOME_GET_BUYERS_FOR_COMPANY_ID = {
  URL: URI + "/user/buyer/",
  REQUEST: "REQUEST_HOME_GET_BUYERS_FOR_COMPANY_ID",
  RECEIVED: "RECEIVED_HOME_GET_BUYERS_FOR_COMPANY_ID",
  RECEIVED_ERROR: "ERROR_HOME_GET_BUYERS_FOR_COMPANY_ID",
};

export const GET_INVOICE_DETAILS_BY_ID_CONSTANTS = {
  URL: URI + "/invoice/invoice/",
  REQUEST: "REQUEST_GET_INVOICE_DETAILS_BY_ID_CONSTANTS",
  RECEIVED: "RECEIVED_GET_INVOICE_DETAILS_BY_ID_CONSTANTS",
  RECEIVED_ERROR: "RECEIVED_GET_INVOICE_DETAILS_BY_ID_CONSTANTS",
};

export const GET_LENDERS_CONSTANTS = {
  URL: URI + "/user/lenders/",
  REQUEST: "REQUEST_GET_LENDERS_CONSTANTS",
  RECEIVED: "RECEIVED_GET_LENDERS_CONSTANTS",
  RECEIVED_ERROR: "RECEIVED_GET_LENDERS_CONSTANTS",
};
export const GET_LENDER_BY_ID_CONSTANTS = {
  URL: URI + "/user/lender/",
  REQUEST: "REQUEST_GET_LENDER_BY_ID_CONSTANTS",
  RECEIVED: "RECEIVED_GET_LENDER_BY_ID_CONSTANTS",
  RECEIVED_ERROR: "RECEIVED_GET_LENDER_BY_ID_CONSTANTS",
};

export const GET_USER_DETAILS_CONSTANTS = {
  URL: URI + "/user/users",
  REQUEST: "REQUEST_GET_USER_DETAILS_CONSTANTS",
  RECEIVED: "RECEIVED_GET_USER_DETAILS_CONSTANTS",
  RECEIVED_ERROR: "RECEIVED_GET_USER_DETAILS_CONSTANTS",
};
export const HOME_VERIFY_COMPANY_CONSTANTS = {
  URL: URI + "/company/verify/",
  REQUEST: "REQUEST_VERIFY_UPDATE_DATA_CONSTANTS",
  RECEIVED: "RECEIVED_VERIFY_UPDATE_DATA_CONSTANTS",
  RECEIVED_ERROR: "RECEIVED_VERIFY_USER_DATA_CONSTANTS",
};

export const INVOICE_ID_LOCATION = "INVOICE_ID_LOCATION";

export const INVOICE_ID_DATA = "INVOICE_ID_DATA";

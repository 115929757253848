/**
 * Component for State list
 * @param {*} props 
 * @returns 
 */
export default function StateList(props) {
    return (
        <>
            <select
                className="form-select wit_th"
                name="state"
                onChange={props.handleChange}
                value={props.value}
            >
                <option className="country-state-dropdown">
                    Choose State
                </option>
                {props.state?.map((item, i) => (
                    <option
                        key={item}
                        className="country-state-dropdown"
                        value={item.name}
                    >
                        {item.name}
                    </option>
                ))
                }
            </select>
        </>
    )
}